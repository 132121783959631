import { GET_ADMIN_EMPLOYEE_BY_COMPANYEMPLOYEEID, GET_ADMIN_EMPLOYEE_BY_COMPANYEMPLOYEEID_ERROR, GET_ADMIN_EMPLOYEE_BY_COMPANYEMPLOYEEID_SUCCESS, GET_ADMIN_EMPLOYEE_BY_EMPLOYEEID, GET_ADMIN_EMPLOYEE_BY_EMPLOYEEID_ERROR, GET_ADMIN_EMPLOYEE_BY_EMPLOYEEID_SUCCESS, GET_ADMIN_EMPLOYEE_LIST, GET_ADMIN_EMPLOYEE_LIST_BY_COMPANYID, GET_ADMIN_EMPLOYEE_LIST_BY_COMPANYID_ERROR, GET_ADMIN_EMPLOYEE_LIST_BY_COMPANYID_SUCCESS, GET_ADMIN_EMPLOYEE_LIST_ERROR, GET_ADMIN_EMPLOYEE_LIST_SUCCESS, PATCH_ADMIN_EMPLOYEE_TERMINATE, PATCH_ADMIN_EMPLOYEE_TERMINATE_ERROR, PATCH_ADMIN_EMPLOYEE_TERMINATE_SUCCESS, POST_ADMIN_EMPLOYEE, POST_ADMIN_EMPLOYEE_ERROR, POST_ADMIN_EMPLOYEE_SUCCESS } from "../../actions";

export const getAdminEmployeeListAction = (info) => ({
    type: GET_ADMIN_EMPLOYEE_LIST,
    payload: info,
  });
  export const getAdminEmployeeListSuccess = (info) => ({
    type: GET_ADMIN_EMPLOYEE_LIST_SUCCESS,
    payload: info,
  });
  export const getAdminEmployeeListError = (error) => ({
    type: GET_ADMIN_EMPLOYEE_LIST_ERROR,
    payload: error,
  });
  
export const getAdminEmployeeListByCompanyIdAction = (info) => ({
    type: GET_ADMIN_EMPLOYEE_LIST_BY_COMPANYID,
    payload: info,
  });
  export const getAdminEmployeeListByCompanyIdSuccess = (info) => ({
    type: GET_ADMIN_EMPLOYEE_LIST_BY_COMPANYID_SUCCESS,
    payload: info,
  });
  export const getAdminEmployeeListByCompanyIdError = (error) => ({
    type: GET_ADMIN_EMPLOYEE_LIST_BY_COMPANYID_ERROR,
    payload: error,
  });
  export const getAdminEmployeeByEmployeeIdAction = (info) => ({
    type: GET_ADMIN_EMPLOYEE_BY_EMPLOYEEID,
    payload: info,
  });
  export const getAdminEmployeeByEmployeeIdSuccess = (info) => ({
    type: GET_ADMIN_EMPLOYEE_BY_EMPLOYEEID_SUCCESS,
    payload: info,
  });
  export const getAdminEmployeeByEmployeeIdError = (error) => ({
    type: GET_ADMIN_EMPLOYEE_BY_EMPLOYEEID_ERROR,
    payload: error,
  });
  
export const getAdminEmployeeByCompanyEmployeeIdAction = (info) => ({
    type: GET_ADMIN_EMPLOYEE_BY_COMPANYEMPLOYEEID,
    payload: info,
  });
  export const getAdminEmployeeByCompanyEmployeeIdSuccess = (info) => ({
    type: GET_ADMIN_EMPLOYEE_BY_COMPANYEMPLOYEEID_SUCCESS,
    payload: info,
  });
  export const getAdminEmployeeByCompanyEmployeeIdError = (error) => ({
    type: GET_ADMIN_EMPLOYEE_BY_COMPANYEMPLOYEEID_ERROR,
    payload: error,
  });
  export const postAdminEmployeeAction = (info) => ({
    type: POST_ADMIN_EMPLOYEE,
    payload: info,
  });
  export const postAdminEmployeeSuccess = (info) => ({
    type: POST_ADMIN_EMPLOYEE_SUCCESS,
    payload: info,
  });
  export const postAdminEmployeeError = (error) => ({
    type: POST_ADMIN_EMPLOYEE_ERROR,
    payload: error,
  });
  export const patchAdminEmployeeTerminateAction = (info) => ({
    type: PATCH_ADMIN_EMPLOYEE_TERMINATE,
    payload: info,
  });
  export const patchAdminEmployeeTerminateSuccess = (info) => ({
    type: PATCH_ADMIN_EMPLOYEE_TERMINATE_SUCCESS,
    payload: info,
  });
  export const patchAdminEmployeeTerminateError = (error) => ({
    type: PATCH_ADMIN_EMPLOYEE_TERMINATE_ERROR,
    payload: error,
  });