import {
  GET_CITY,
  GET_CITY_ERROR,
  GET_CITY_SUCCESS,
  GET_STATE,
  GET_STATE_ERROR,
  GET_STATE_SUCCESS,

} from "../actions";

export const getStateAction = (info) => ({
  type: GET_STATE,
  payload: info,
});
export const getStateSuccess = (info) => ({
  type: GET_STATE_SUCCESS,
  payload: info,
});
export const getStateError = (error) => ({
  type: GET_STATE_ERROR,
  payload: error,
});
export const getCityAction = (info) => ({
  type: GET_CITY,
  payload: info,
});
export const getCitySuccess = (info) => ({
  type: GET_CITY_SUCCESS,
  payload: info,
});
export const getCityError = (error) => ({
  type: GET_CITY_ERROR,
  payload: error,
});
