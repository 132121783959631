import { Dialog } from "../../../../ui/dialog";
import moment from "moment";
import React, { useState } from "react";
import ImagePopUpDownload from "../../../../components/imagePopUpDownload";
import Image from "../../../../assets/images/bill.png";
import EmiDelete from "./truckEmiDelete";
import { TfiTrash } from "react-icons/tfi";
import { FcImageFile } from "react-icons/fc";

function EmiCard({ data, emiDelete }) {
  const [showEmiDeleteModal, setShowEmiDeleteModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(amount)
      : "₹0.00";
  return (
    <div
      key={data._id}
      className="bg-white p-4 rounded-md shadow-lg mt-4  w-full text-xs"
    >
      <Dialog open={showEmiDeleteModal}>
        <EmiDelete setShowModal={setShowEmiDeleteModal} id={data?._id} />
      </Dialog>
      {data?.emiTransationImage?.length > 0 && (
        <Dialog open={showImageModal}>
          <ImagePopUpDownload
            image={data?.emiTransationImage}
            setShowModal={setShowImageModal}
            imagename={`emi_${data?._id}`}
          />
        </Dialog>
      )}
      <div className=" grid xl:grid-cols-6 lg:grid-cols-4 grid-cols-2 grid-rows-2   lg:gap-x-20 gap-x-9  lg:gap-y-6  gap-y-4">
        <div className="lg:border-r lg:border-r-[#F1F2F6] lg:border-b-0 border-b border-b-[#F1F2F6] row-span-full flex flex-col gap-2 lg:col-span-1 col-span-2 pb-5 lg:text-left text-center  relative">
          <label htmlFor="" className="relative">
            Emi Month
          </label>
          <p className=" textPrimary uppercase ">
            {moment(data.emiPayMonth).format("MMM YYYY")}
          </p>
          {emiDelete === data.emiPayMonth && (
            <div className="flex md:hidden ml-auto absolute z-30 left-0">
              <button
                type="button"
                onClick={() => setShowEmiDeleteModal(true)}
                className="ghostButton text-[#DC1919] font-semibold  flex gap-1 hover:text-red-700 disabled:text-red-200"
              >
                <TfiTrash className="text-xl" />
              </button>
            </div>
          )}
          <div className=" flex flex-col gap-3 md:hidden ml-auto absolute z-30 right-0">
            {data?.emiTransationImage?.length > 0 && (
              <div className="">
                <button
                  type="button"
                  onClick={() => setShowImageModal(true)}
                  className="ghostButton font-semibold flex gap-1 items-center md:mx-0 mx-auto"
                >
                  <FcImageFile className="text-2xl" />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="">
          <label htmlFor="">Emi Amount</label>
          <p className="textSecondary">{formatAmount(data?.emiAmount)}</p>
        </div>
        <div className="">
          <label htmlFor="">Addition Charges</label>
          <p className="textSecondary">{formatAmount(data.additionCharges)}</p>
        </div>
        <div className="">
          <label htmlFor="">Emi payment Type</label>
          <p className="textSecondary">{data.paymentType}</p>
        </div>
        <div className="">
          <label htmlFor="">Emi Amount Left</label>
          <p className="textSecondary">{data?.emiAmountLeft}</p>
        </div>

        <div className=" gap-6 flex-wrap lg:flex hidden">
          {data?.emiTransationImage?.length > 0 && (
            <div className="">
              <Dialog open={showImageModal}>
                <button
                  type="button"
                  onClick={() => setShowImageModal(true)}
                  className="ghostButton font-semibold flex gap-1"
                >
                  <FcImageFile className="text-2xl" /> Bill
                </button>
              </Dialog>
            </div>
          )}
          {emiDelete === data.emiPayMonth && (
            <div className="lg:flex hidden">
              <button
                type="button"
                onClick={() => setShowEmiDeleteModal(true)}
                className="ghostButton text-[#DC1919] font-semibold  flex gap-1 hover:text-red-700 disabled:text-red-200"
              >
                <TfiTrash className="text-xl" /> Delete
              </button>
            </div>
          )}
        </div>
        <div className="">
          <label htmlFor="">Total Cost</label>
          <p className="textSecondary">{formatAmount(data.totalAmountPay)}</p>
        </div>
        <div className="col-span-2">
          <label htmlFor="">Description</label>
          <p className="textSecondary">
            {data?.description ? data?.description : "--"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default EmiCard;
// <div
//   key={data._id}
//   className="my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl"
// >
//   <div className="grid grid-cols-12 gap-x-7">
//     <div className="lg:col-span-2 col-span-6 row-span-1 justify-self-center my-auto lg:row-span-4">
//       <button
//         type="button"
//         className={`  ${
//           data?.emiTransationImage?.url === "" ? "bg-gray-400 " : ""
//         } rounded-full`}
//         onClick={() => setShowImageModal(true)}
//       >
//         <img
//           src={
//             data?.emiTransationImage?.url === ""
//               ? Image
//               : data?.emiTransationImage?.url
//           }
//           alt=""
//           className={`min-h-[100px] max-h-[200px] min-w-[100px] max-w-[200px]`}
//         />
//       </button>
//     </div>
//     {data?.emiTransationImage?.url && (
//       <Dialog open={showImageModal}>
//         <ImagePopUpDownload
//           image={data?.emiTransationImage}
//           setShowModal={setShowImageModal}
//           imagename={`Emi_${data?._id}`}
//         />
//       </Dialog>
//     )}
//     <div className="mb-5 text-center md:text-left lg:col-span-10 col-span-6 row-span-1 self-center lg:self-auto">
//       <p className="text-blue-700 font-semibold text-xl uppercase">
//         Emi for {moment(data.emiPayMonth).format("MMMM YYYY")}
//       </p>
//     </div>
//     <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center">
//       <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center">
//         <div className="md:text-left">
//           <h3 className="textgrey text-xs">Total Pay</h3>
//           <p>₹{data.totalAmountPay}</p>
//         </div>

//         <div>
//           <h3 className="textgrey text-xs md:text-center">Emi Amount</h3>
//           <p className="md:text-center">₹{data?.emiAmount}</p>
//         </div>

//         <div>
//           <h3 className="textgrey text-xs md:text-center">Add-Ons</h3>
//           <p className="md:text-center">₹{data.additionCharges}</p>
//         </div>
//         <div>
//           <h3 className="textgrey text-xs md:text-centre">
//             Addition Charges Reason
//           </h3>
//           <p className="md:text-center">
//             <div className="">{data.additionalChargesReason}</div>
//           </p>
//         </div>
//         <div>
//           <h3 className="textgrey text-xs md:text-left">Payment Type</h3>
//           <p className="md:text-left">
//             <div className="">{data.paymentType}</div>
//           </p>
//         </div>
//         <div>
//           <h3 className="textgrey text-xs md:text-center">
//             Emi Amount Left
//           </h3>
//           <p className="md:text-center">{data?.emiAmountLeft}</p>
//         </div>

//         <div className="col-span-2">
//           <h3 className="textgrey text-xs md:text-center ">Description</h3>
//           <p className="md:text-center">{data?.description}</p>
//         </div>
//         {emiDelete === data.emiPayMonth && (
//           <div className="flex">
//             <Dialog open={showEmiDeleteModal}>
//               <button
//                 onClick={() => setShowEmiDeleteModal(true)}
//                 className="primaryButton bg-red-600 hover:bg-red-800 border-red-600 hover:border-red-800"
//               >
//                 Delete Emi
//               </button>
//               <EmiDelete id={data?._id} setShowModal={setShowEmiDeleteModal} />
//             </Dialog>
//           </div>
//         )}
//       </div>
//     </div>
//   </div>
// </div>
