import React, { useState } from "react";
import Image from "../../../assets/images/semi.png";
import { Link, useNavigate } from "react-router-dom";
import { Dialog } from "../../../ui/dialog";
import ImagePopUpDownload from "../../../components/imagePopUpDownload";
import { Badge } from "../../../ui/badge";
import moment from "moment";
import TruckPurchase from "./truckPurchase";

function TruckListCard({ data, reroute = false }) {
  const navigate = useNavigate();
  const [showImageModal, setShowImageModal] = useState(false);
  const [showModal, setshowModal] = useState(false);


  return (
    <div key={data?._id}>
      <div className="grid grid-cols-12 gap-x-7 my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl">
        <div className="lg:col-span-2 col-span-6 row-span-1 justify-self-center my-auto lg:row-span-3">
          <button
            type="button"
            className="rounded-full bg-gray-400"
            onClick={() =>
              reroute
                ? navigate(`/admin/truck/details/${data._id}`)
                : setShowImageModal(true)
            }
          >
            <img
              src={data?.truckImage ? data?.truckImage : Image}
              alt=""
              className={` ${
                data?.truckImage ? "rounded-full" : ""
              } h-[100px] w-[100px]`}
            />
          </button>
        </div>
        {data?.truckImage && (
          <Dialog open={showImageModal}>
            <ImagePopUpDownload
              image={data?.truckImage}
              setShowModal={setShowImageModal}
              imagename={`truck_${data?._id}`}
            />
          </Dialog>
        )}
        <div className="mb-5 text-center md:text-left lg:col-span-10 col-span-6 row-span-1 self-center lg:self-auto">
          <button
            type="button"
            className="font-bold text-blue-600 text-xl uppercase"
            onClick={reroute ? () => navigate(`details/${data._id}`) : null}
          >
            {data?.truckNumber}
          </button>
          <p className="textgrey text-base">
            {data?.truckCompanyName}, {data?.truckModelName},{" "}
            {data?.typeOfTruck}
          </p>
        </div>
        <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center">
          <div className="md:text-left">
            <h3 className="textgrey text-xs">Company Name</h3>
            <Link to={`/admin/company/${data?.currentCompanyId?._id}`}>
              {data?.currentCompanyId?.companyName}
            </Link>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-center">EMI</h3>
            <p className="flex md:justify-center">
              {data?.isEmi ? (
                <Badge className="bg-green-700 hover:bg-green-900">
                  EMI Ongoing
                </Badge>
              ) : (
                <Badge variant="destructive">No EMI</Badge>
              )}
            </p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-center">EMI End Date</h3>
            <p className="md:text-center">
              {data?.isEmi
                ? moment(data?.emiEndDate).format("DD/MM/YYYY")
                : "N/A"}
            </p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-right">Fitness Till</h3>
            <p className="md:text-right">
              {moment(data?.fitnessTill).format("DD/MM/YYYY")}
            </p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-left">Insurance Till</h3>
            <p className="md:text-left">
              {moment(data?.insuranceTill).format("DD/MM/YYYY")}
            </p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-center">EMI End Date</h3>
            <p className="md:text-center">{data?.chasisNumber}</p>
          </div>
          {data?.currentCompanyId === null &&  (
            <Dialog open={showModal}>
              <button
                type="button"
                className="secondaryButton bg-green-600 hover:bg-green-800 text-white h-full"
                onClick={() => setshowModal(true)}
              >
                Purchase Truck
              </button>
              <TruckPurchase truckId={data?._id} setshowModal={setshowModal} />
            </Dialog>
          )}
        </div>
      </div>
    </div>
  );
}

export default TruckListCard;
