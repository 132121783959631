// reducer.js
import {
  GET_EMI,
  GET_EMI_SUCCESS,
  GET_EMI_ERROR,
  POST_EMI,
  POST_EMI_SUCCESS,
  POST_EMI_ERROR,
  DELETE_EMI,
  DELETE_EMI_SUCCESS,
  DELETE_EMI_ERROR,
  GET_EMI_BY_ID,
  GET_EMI_BY_ID_SUCCESS,
  GET_EMI_BY_ID_ERROR,
} from "../../../actions";

const INIT_STATE = {
  getEmi: [],
  getEmiLoading: false,
  getEmiError: "",
  getEmiById: [],
  getEmiByIdLoading: false,
  getEmiByIdError: "",
  postEmi: {},
  postEmiLoading: false,
  postEmiError: "",
  deleteEmiLoading: false,
  deleteEmi: {},
  deleteEmiError: "",
};

const EmiSection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EMI:
      return { ...state, getEmiLoading: true, getEmi: [] };

    case GET_EMI_SUCCESS:
      return {
        ...state,
        getEmiLoading: false,
        getEmi: action.payload,
        getEmiError: "",
      };

    case GET_EMI_ERROR:
      return {
        ...state,
        getEmiLoading: false,
        getEmiError: action.payload,
      };

    case GET_EMI_BY_ID:
      return { ...state, getEmiByIdLoading: true, getEmiById: [] };

    case GET_EMI_BY_ID_SUCCESS:
      return {
        ...state,
        getEmiByIdLoading: false,
        getEmiById: action.payload,
        getEmiByIdError: "",
      };

    case GET_EMI_BY_ID_ERROR:
      return {
        ...state,
        getEmiByIdLoading: false,
        getEmiByIdError: action.payload,
      };
    
    case POST_EMI:
      return { ...state, postEmiLoading: true, postEmi: {} };

    case POST_EMI_SUCCESS:
      return {
        ...state,
        postEmiLoading: false,
        postEmi: action.payload,
        postEmiError: "",
      };

    case POST_EMI_ERROR:
      return {
        ...state,
        postEmiLoading: false,
        postEmiError: action.payload,
      };


    case DELETE_EMI:
      return { ...state, deleteEmiLoading: true, deleteEmi: {} };

    case DELETE_EMI_SUCCESS:
      return {
        ...state,
        deleteEmiLoading: false,
        deleteEmi: action.payload,
        deleteEmiError: "",
      };

    case DELETE_EMI_ERROR:
      return {
        ...state,
        deleteEmiLoading: false,
        deleteEmiError: action.payload,
      };

    default:
      return state;
  }
};

export default EmiSection;
