import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import api from "../../../auth/api";
import {
  GET_ADMIN_EMPLOYEE_BY_COMPANYEMPLOYEEID,
  GET_ADMIN_EMPLOYEE_BY_EMPLOYEEID,
  GET_ADMIN_EMPLOYEE_LIST,
  GET_ADMIN_EMPLOYEE_LIST_BY_COMPANYID,
  PATCH_ADMIN_EMPLOYEE_TERMINATE,
  POST_ADMIN_EMPLOYEE,
} from "../../actions";
import {
  getAdminEmployeeByCompanyEmployeeIdError,
  getAdminEmployeeByCompanyEmployeeIdSuccess,
  getAdminEmployeeByEmployeeIdError,
  getAdminEmployeeByEmployeeIdSuccess,
  getAdminEmployeeListByCompanyIdError,
  getAdminEmployeeListByCompanyIdSuccess,
  getAdminEmployeeListError,
  getAdminEmployeeListSuccess,
  patchAdminEmployeeTerminateError,
  patchAdminEmployeeTerminateSuccess,
  postAdminEmployeeError,
  postAdminEmployeeSuccess,
} from "./action";

// Async function to handle API call
const getAdminEmployeeListAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/employee/getAll`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getAdminEmployeeListCall(action) {
  try {
    const data = yield call(getAdminEmployeeListAsync, action); // Call async function with action payload
    yield put(getAdminEmployeeListSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getAdminEmployeeListError(error)); // Dispatch error action with error object
  }
}
export function* watchGetAdminEmployeeListData() {
  yield takeEvery(GET_ADMIN_EMPLOYEE_LIST, getAdminEmployeeListCall);
}

// Async function to handle API call
const getAdminEmployeeListByCompanyIdAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/employee/getbyCompanyId/${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getAdminEmployeeListByCompanyIdCall(action) {
  try {
    const data = yield call(
      getAdminEmployeeListByCompanyIdAsync,
      action.payload
    ); // Call async function with action payload
    yield put(getAdminEmployeeListByCompanyIdSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getAdminEmployeeListByCompanyIdError(error)); // Dispatch error action with error object
  }
}
export function* watchGetAdminEmployeeListByCompanyIdData() {
  yield takeEvery(
    GET_ADMIN_EMPLOYEE_LIST_BY_COMPANYID,
    getAdminEmployeeListByCompanyIdCall
  );
}

// Async function to handle API call
const getAdminEmployeeByEmployeeIdAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/employee/getbyId/${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getAdminEmployeeByEmployeeIdCall(action) {
  try {
    const data = yield call(getAdminEmployeeByEmployeeIdAsync, action.payload); // Call async function with action payload
    yield put(getAdminEmployeeByEmployeeIdSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getAdminEmployeeByEmployeeIdError(error)); // Dispatch error action with error object
  }
}
export function* watchGetAdminEmployeeByEmployeeIdData() {
  yield takeEvery(
    GET_ADMIN_EMPLOYEE_BY_EMPLOYEEID,
    getAdminEmployeeByEmployeeIdCall
  );
}

// Async function to handle API call
const getAdminEmployeeByCompanyEmployeeIdAsync = async (payload) => {
  try {
    const response = await api.get(
      `/admin/employee/getbyCompanyEmployeeId/${payload}`
    );
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getAdminEmployeeByCompanyEmployeeIdCall(action) {
  try {
    const data = yield call(getAdminEmployeeByCompanyEmployeeIdAsync, action.payload); // Call async function with action payload
    yield put(getAdminEmployeeByCompanyEmployeeIdSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getAdminEmployeeByCompanyEmployeeIdError(error)); // Dispatch error action with error object
  }
}
export function* watchGetAdminEmployeeByCompanyEmployeeIdData() {
  yield takeEvery(
    GET_ADMIN_EMPLOYEE_BY_COMPANYEMPLOYEEID,
    getAdminEmployeeByCompanyEmployeeIdCall
  );
}

// Async function to handle API call
const postAdminEmployeeAsync = async (payload) => {
  try {
    const response = await api.post(`/admin/employee/addToCompany`, payload);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* postAdminEmployeeCall(action) {
  try {
    const data = yield call(postAdminEmployeeAsync, action.payload); // Call async function with action payload
    yield put(postAdminEmployeeSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postAdminEmployeeError(error)); // Dispatch error action with error object
  }
}
export function* watchPostAdminEmployeeData() {
  yield takeEvery(POST_ADMIN_EMPLOYEE, postAdminEmployeeCall);
}

// Async function to handle API call
const patchAdminEmployeeTerminateAsync = async (payload) => {
  try {
    const response = await api.patch(
      `/admin/employee/terminate/${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* patchAdminEmployeeTerminateCall(action) {
  try {
    const data = yield call(patchAdminEmployeeTerminateAsync, action.payload); // Call async function with action payload
    yield put(patchAdminEmployeeTerminateSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(patchAdminEmployeeTerminateError(error)); // Dispatch error action with error object
  }
}
export function* watchPatchAdminEmployeeTerminateData() {
  yield takeEvery(
    PATCH_ADMIN_EMPLOYEE_TERMINATE,
    patchAdminEmployeeTerminateCall
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAdminEmployeeListData),
    fork(watchGetAdminEmployeeListByCompanyIdData),
    fork(watchGetAdminEmployeeByEmployeeIdData),
    fork(watchGetAdminEmployeeByCompanyEmployeeIdData),
    fork(watchPostAdminEmployeeData),
    fork(watchPatchAdminEmployeeTerminateData),
  ]);
}
