import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { patchResetPasswordAction } from "../redux/auth/action";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import DeviceDetector from "device-detector-js";
import useDeviceInfoAndLocation from "../auth/useDeviceAndLocation";

function ResetPassword() {
  const { token } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deviceInfo, location } = useDeviceInfoAndLocation();

  const {
    patchResetPassword,
    patchResetPasswordLoading,
    patchResetPasswordError,
  } = useSelector((state) => state.Authsection);
  const [loader, setloader] = useState(false);

  useEffect(() => {
    if (loader) {
      handler();
    }
  }, [patchResetPassword]);
  const handler = async () => {
    if (patchResetPassword?.status === 200) {
      toast.success(patchResetPassword.message, { id: "reset-toast" });
      setloader(false);
      navigate(`/login`);
    } else if (patchResetPassword?.status === 400) {
      toast.error(patchResetPassword.message, { id: "reset-toast" });
      setloader(false);
    } else if (patchResetPassword?.status === 500) {
      toast.error(patchResetPassword.error, { id: "reset-toast" });
      setloader(false);
    }
  };
  const initialValues = {
    token: token,
    password: "",
    confirmPassword: "",
    deviceName: deviceInfo.deviceName,
    deviceType: deviceInfo.deviceType,
    latitude: location.latitude,
    longitude: location.longitude,
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required(t("required"))
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        t("passwordValidation")
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("passwordMatch"))
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        t("passwordValidation")
      )
      .required(t("required")),
  });

  const onSubmit = (values) => {
    const payload = {
      token: token,
  ...values
    };
    dispatch(patchResetPasswordAction(payload));
    setloader(true);
    toast.loading("Resending...", { id: "reset-toast" });
  };

  return (
    <div className="w-full p-8 md:col-span-2 bg-gray-100 col-span-5 grid grid-rows-3">
      <h2 className="text-2xl font-bold uppercase ">{t("resetPassword")}</h2>
      {/* <h2 className="text-sm text-gray-400 my-3 ">{t("slogan")}</h2> */}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => (
          <Form className="space-y-8 row-span-2 flex flex-col justify-between  ">
            <div className="flex flex-col gap-10">
              <div>
                <label htmlFor="password" className="label">
                  {t("resetPassword")} <span>*</span>
                </label>
                <Field
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="confirmPassword" className="label">
                  {t("confirmPassword")} <span>*</span>
                </label>
                <Field
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder={t("confirmPassword")}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="error"
                />
              </div>
            </div>

            <button
              type="submit"
              disabled={patchResetPasswordLoading}
              className="primaryButton"
            >
              {t("submit")}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ResetPassword;
