import { getAdminTruckbyIdAction } from "../../../../redux/admin/truck/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../../ui/tabs";
import toast from "react-hot-toast";
import TruckDetailSection from "./truckDetailSection";
import OwnershipList from "./ownershipList";
import TruckListCard from "../truckListCard";

function TruckAdminDetails() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    getAdminTruckbyId,
    getAdminTruckbyIdLoading,
    getAdminTruckbyIdError,
  } = useSelector((state) => state.AdminTruckSection);
  const [data, setData] = useState();
  useEffect(() => {
    dispatch(getAdminTruckbyIdAction(id));
  }, []);
console.log(getAdminTruckbyId)
  useEffect(() => {
    if (getAdminTruckbyId?.status === 200) {
      setData(getAdminTruckbyId?.data);
    }
  }, [getAdminTruckbyId]);
  useEffect(() => {
    if (getAdminTruckbyIdError?.status === 400) {
      toast.error(getAdminTruckbyId?.message);
    } else if (getAdminTruckbyIdError?.status === 404) {
      toast.error(getAdminTruckbyId?.message);
    } else if (getAdminTruckbyIdError?.status === 500) {
      toast.error(getAdminTruckbyId?.error);
    }
  }, [getAdminTruckbyId]);

  return (
    <div>
      <TruckListCard data={data?.truck}  />
      <Tabs defaultValue="Details" color="primary" className="min-w-[400px]">
        <TabsList>
          <TabsTrigger value="Details">Details</TabsTrigger>
          <TabsTrigger value="Ownership">Ownership</TabsTrigger>
        </TabsList>
        <TabsContent value="Details">
          <TruckDetailSection data={data?.truck} />
        </TabsContent>
        <TabsContent value="Ownership">
          <OwnershipList data={data?.truckOwnershipHistory} />
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default TruckAdminDetails;
