import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import api from "../../../auth/api";
import {
  DELETE_ADMIN_TRUCK_SUBSCRIPTION,
  DELETE_ADMIN_TRUCK,
  GET_ADMIN_TRUCK_BY_TRUCKID,
  GET_ADMIN_TRUCK_LIST,
  GET_ADMIN_TRUCK_LIST_COMPANYID,
  GET_ADMIN_TRUCK_SUBSCRIPTION_LIST,
  PATCH_ADMIN_BLOCK_COMPANY_TRUCK,
  POST_ADMIN_TRUCK_PURCHASE,
  POST_ADMIN_TRUCK_SELL,
  POST_ADMIN_TRUCK_SUBSCRIPTION,
  GET_ADMIN_TRUCK_SUBSCRIPTION_DETAILS,
} from "../../actions";
import {
    deleteAdminTruckSubscriptionError,
    deleteAdminTruckSubscriptionSuccess,
  deleteAdminTruckError,
  deleteAdminTruckSuccess,
  getAdminTruckbyCompanyIdError,
  getAdminTruckbyCompanyIdSuccess,
  getAdminTruckbyIdError,
  getAdminTruckbyIdSuccess,
  getAdminTruckListError,
  getAdminTruckListSuccess,
  getAdminTruckSubscriptioListError,
  getAdminTruckSubscriptioListSuccess,
  patchAdminBlockCompanyTruckError,
  patchAdminBlockCompanyTruckSuccess,
  postAdminTruckPurchaseError,
  postAdminTruckPurchaseSuccess,
  postAdminTruckSellError,
  postAdminTruckSellSuccess,
  postAdminTruckSubscriptionError,
  postAdminTruckSubscriptionSuccess,
  getAdminTruckSubscriptionDetailsSuccess,
  getAdminTruckSubscriptionDetailsError,
} from "./action";

// Async function to handle API call
const getAdminTruckListAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/truck/get`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getAdminTruckListCall(action) {
  try {
    const data = yield call(getAdminTruckListAsync, action); // Call async function with action payload
    yield put(getAdminTruckListSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getAdminTruckListError(error)); // Dispatch error action with error object
  }
}
export function* watchGetAdminTruckListData() {
  yield takeEvery(GET_ADMIN_TRUCK_LIST, getAdminTruckListCall);
}

// Async function to handle API call
const getAdminTruckSubscriptionDetailsAsync = async (payload) => {
  try {
    const response = await api.post(`/admin/truck/getSubscriptionDetails`, payload);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getAdminTruckSubscriptionDetailsCall(action) {
  try {
    const data = yield call(getAdminTruckSubscriptionDetailsAsync, action.payload); // Call async function with action payload
    yield put(getAdminTruckSubscriptionDetailsSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getAdminTruckSubscriptionDetailsError(error)); // Dispatch error action with error object
  }
}
export function* watchGetAdminTruckSubscriptionDetailsData() {
  yield takeEvery(GET_ADMIN_TRUCK_SUBSCRIPTION_DETAILS, getAdminTruckSubscriptionDetailsCall);
}

// Async function to handle API call
const getAdminTruckbyIdAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/truck/getByTruckId/${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getAdminTruckbyIdCall(action) {
  try {
    const data = yield call(getAdminTruckbyIdAsync, action.payload); // Call async function with action payload
    yield put(getAdminTruckbyIdSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getAdminTruckbyIdError(error)); // Dispatch error action with error object
  }
}
export function* watchGetAdminTruckbyIdData() {
  yield takeEvery(GET_ADMIN_TRUCK_BY_TRUCKID, getAdminTruckbyIdCall);
}

// Async function to handle API call
const getAdminTruckbyCompanyIdAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/truck/getByCompanyId/${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getAdminTruckbyCompanyIdCall(action) {
  try {
    const data = yield call(getAdminTruckbyCompanyIdAsync, action.payload); // Call async function with action payload
    yield put(getAdminTruckbyCompanyIdSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getAdminTruckbyCompanyIdError(error)); // Dispatch error action with error object
  }
}
export function* watchGetAdminTruckbyCompanyIdData() {
  yield takeEvery(GET_ADMIN_TRUCK_LIST_COMPANYID, getAdminTruckbyCompanyIdCall);
}

// Async function to handle API call
const getAdminTruckSubscriptioListAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/truck/subscription/get/${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getAdminTruckSubscriptioListCall(action) {
  try {
    const data = yield call(getAdminTruckSubscriptioListAsync, action.payload); // Call async function with action payload
    yield put(getAdminTruckSubscriptioListSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getAdminTruckSubscriptioListError(error)); // Dispatch error action with error object
  }
}
export function* watchGetAdminTruckSubscriptioListData() {
  yield takeEvery(GET_ADMIN_TRUCK_SUBSCRIPTION_LIST, getAdminTruckSubscriptioListCall);
}

// Async function to handle API call
const postAdminTruckSubscriptionAsync = async (payload) => {
  try {
    const response = await api.post(
      `/admin/truck/subscription/${payload.id}`,
      payload.body
    );
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* postAdminTruckSubscriptionCall(action) {
  try {
    const data = yield call(postAdminTruckSubscriptionAsync, action.payload); // Call async function with action payload
    yield put(postAdminTruckSubscriptionSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postAdminTruckSubscriptionError(error)); // Dispatch error action with error object
  }
}
export function* watchPostAdminTruckSubscriptionData() {
  yield takeEvery(
    POST_ADMIN_TRUCK_SUBSCRIPTION,
    postAdminTruckSubscriptionCall
  );
}

// Async function to handle API call
const postAdminTruckSellAsync = async (payload) => {
  try {
    const response = await api.post(
      `/admin/truck/sell/${payload.id}`,
      payload.body
    );
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* postAdminTruckSellCall(action) {
  try {
    const data = yield call(postAdminTruckSellAsync, action.payload); // Call async function with action payload
    yield put(postAdminTruckSellSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postAdminTruckSellError(error)); // Dispatch error action with error object
  }
}
export function* watchPostAdminTruckSellData() {
  yield takeEvery(POST_ADMIN_TRUCK_SELL, postAdminTruckSellCall);
}

// Async function to handle API call
const postAdminTruckPurchaseAsync = async (payload) => {
  try {
    const response = await api.post(
      `/admin/truck/purchase/${payload.id}`,
      payload.body
    );
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* postAdminTruckPurchaseCall(action) {
  try {
    const data = yield call(postAdminTruckPurchaseAsync, action.payload); // Call async function with action payload
    yield put(postAdminTruckPurchaseSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postAdminTruckPurchaseError(error)); // Dispatch error action with error object
  }
}
export function* watchPostAdminTruckPurchaseData() {
  yield takeEvery(POST_ADMIN_TRUCK_PURCHASE, postAdminTruckPurchaseCall);
}

// Async function to handle API call
const deleteAdminTruckAsync = async (payload) => {
  try {
    const response = await api.delete(`/admin/truck/delete/${payload.id}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* deleteAdminTruckCall(action) {
  try {
    const data = yield call(deleteAdminTruckAsync, action.payload); // Call async function with action payload
    yield put(deleteAdminTruckSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(deleteAdminTruckError(error)); // Dispatch error action with error object
  }
}
export function* watchDeleteAdminTruckData() {
  yield takeEvery(DELETE_ADMIN_TRUCK, deleteAdminTruckCall);
}

// Async function to handle API call
const deleteAdminTruckSubscriptionAsync = async (payload) => {
  try {
    const response = await api.delete(
      `/admin/truck/subscription/delete/${payload}`
    );
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* deleteAdminTruckSubscriptionCall(action) {
  try {
    const data = yield call(deleteAdminTruckSubscriptionAsync, action.payload); // Call async function with action payload
    yield put(deleteAdminTruckSubscriptionSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(deleteAdminTruckSubscriptionError(error)); // Dispatch error action with error object
  }
}
export function* watchDeleteAdminTruckSubscriptionData() {
  yield takeEvery(DELETE_ADMIN_TRUCK_SUBSCRIPTION, deleteAdminTruckSubscriptionCall);
}

// Async function to handle API call
const patchAdminBlockCompanyTruckAsync = async (payload) => {
  try {
    const response = await api.patch(`/admin/truck/blocked/${payload.id}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* patchAdminBlockCompanyTruckCall(action) {
  try {
    const data = yield call(patchAdminBlockCompanyTruckAsync, action.payload); // Call async function with action payload
    yield put(patchAdminBlockCompanyTruckSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(patchAdminBlockCompanyTruckError(error)); // Dispatch error action with error object
  }
}
export function* watchPatchAdminBlockCompanyTruckData() {
  yield takeEvery(
    PATCH_ADMIN_BLOCK_COMPANY_TRUCK,
    patchAdminBlockCompanyTruckCall
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAdminTruckListData),
    fork(watchGetAdminTruckSubscriptionDetailsData),
    fork(watchGetAdminTruckbyIdData),
    fork(watchGetAdminTruckbyCompanyIdData),
    fork(watchGetAdminTruckSubscriptioListData),
    fork(watchPostAdminTruckSubscriptionData),
    fork(watchPostAdminTruckSellData),
    fork(watchPostAdminTruckPurchaseData),
    fork(watchDeleteAdminTruckData),
    fork(watchDeleteAdminTruckSubscriptionData),
    fork(watchPatchAdminBlockCompanyTruckData),
  ]);
}
