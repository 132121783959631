import { getCompanyListAction } from "../../../redux/admin/company/action";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "../../backdrop";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../ui/tabs";
import CompanyList from "./companyList";

function Company() {
  const dispatch = useDispatch();
  const { getCompanyList, getCompanyListLoading, getCompanyListError } =
    useSelector((state) => state.AdminCompanysection);

  useEffect(() => {
    dispatch(getCompanyListAction());
  }, []);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (getCompanyList?.status === 200) {
      setData(getCompanyList?.data);
    }
  }, [getCompanyList]);
  useEffect(() => {
    if (getCompanyListError?.status === 400) {
      toast.error(getCompanyListError?.message);
    } else if (getCompanyListError?.status === 404) {
      toast.error(getCompanyListError?.message);
    } else if (getCompanyListError?.status === 500) {
      toast.error(getCompanyListError?.error);
    }
  }, [getCompanyListError]);

  return (
    <div>
      {getCompanyListLoading && <Backdrop />}
      <Tabs defaultValue="All" color="primary" className="min-w-[400px]">
        <TabsList>
          <TabsTrigger value="All">All Companies</TabsTrigger>
          <TabsTrigger value="UnBlocked">UnBlocked</TabsTrigger>
          <TabsTrigger value="Blocked">Blocked</TabsTrigger>
          <TabsTrigger value="disabled">disabled</TabsTrigger>
        </TabsList>
        <TabsContent value="All"><CompanyList data={data} /></TabsContent>
        <TabsContent value="UnBlocked"><CompanyList data={data?.filter((item)=> item.userId.blocked === false)} /></TabsContent>
        <TabsContent value="Blocked"><CompanyList data={data?.filter((item)=> item.userId.blocked === true)} /></TabsContent>
        <TabsContent value="disabled"><CompanyList data={data?.filter((item)=> item.userId.disabled === true)} /></TabsContent>
      </Tabs>
    </div>
  );
}

export default Company;
