import api from "../../../auth/api.jsx";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  getSubscriptionDiscountError,
  getSubscriptionDiscountSuccess,
  getSubscriptionKeyError,
  getSubscriptionKeySuccess,
  getSubscriptionListError,
  getSubscriptionListSuccess,
  postSubscriptionCreateOrderError,
  postSubscriptionCreateOrderSuccess,
  postSubscriptionPaymentVerificationError,
  postSubscriptionPaymentVerificationSuccess,
} from "./action.js";
import { GET_SUBSCRIPTION_DISCOUNT, GET_SUBSCRIPTION_KEY, GET_SUBSCRIPTION_LIST, POST_SUBSCRIPTION_CREATE_ORDER, POST_SUBSCRIPTION_PAYMENT_VERIFY } from "../../actions.js";

// Async function to handle API call
const getSubscriptionListAsync = async (payload) => {
  try {
    const response = await api.get(`/company/subscription/getAll`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getSubscriptionListCall(action) {
  try {
    const data = yield call(getSubscriptionListAsync, action); // Call async function with action payload
    yield put(getSubscriptionListSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getSubscriptionListError(error)); // Dispatch error action with error object
  }
}

export function* watchGetSubscriptionListData() {
  yield takeEvery(GET_SUBSCRIPTION_LIST, getSubscriptionListCall);
}

// Async function to handle API call
const getSubscriptionDiscountAsync = async (payload) => {
  try {
    const response = await api.get(`/company/subscription/discount/get`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getSubscriptionDiscountCall(action) {
  try {
    const data = yield call(getSubscriptionDiscountAsync, action); // Call async function with action payload
    yield put(getSubscriptionDiscountSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getSubscriptionDiscountError(error)); // Dispatch error action with error object
  }
}

export function* watchGetSubscriptionDiscountData() {
  yield takeEvery(GET_SUBSCRIPTION_DISCOUNT, getSubscriptionDiscountCall);
}

// Async function to handle API call
const getSubscriptionKeyAsync = async (payload) => {
  try {
    const response = await api.get(`/company/subscription/key/get`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getSubscriptionKeyCall(action) {
  try {
    const data = yield call(getSubscriptionKeyAsync, action); // Call async function with action payload
    yield put(getSubscriptionKeySuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getSubscriptionKeyError(error)); // Dispatch error action with error object
  }
}

export function* watchGetSubscriptionKeyData() {
  yield takeEvery(GET_SUBSCRIPTION_KEY, getSubscriptionKeyCall);
}



const postSubscriptionCreateOrderAsync = async (payload) => {
  try {
    const response = await api.post(`/company/subscription/createOrders`, payload.payload);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* postSubscriptionCreateOrderCall(action) {
  try {
    const data = yield call(postSubscriptionCreateOrderAsync, action); // Call async function with action payload
    yield put(postSubscriptionCreateOrderSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postSubscriptionCreateOrderError(error)); // Dispatch error action with error object
  }
}

export function* watchPostSubscriptionCreateOrderData() {
  yield takeEvery(POST_SUBSCRIPTION_CREATE_ORDER, postSubscriptionCreateOrderCall);
}

const postSubscriptionPaymentVerificationAsync = async (payload) => {
  try {
    const response = await api.post(`/company/subscription/paymentVerify`, payload.payload);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* postSubscriptionPaymentVerificationCall(action) {
  try {
    const data = yield call(postSubscriptionPaymentVerificationAsync, action); // Call async function with action payload
    yield put(postSubscriptionPaymentVerificationSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postSubscriptionPaymentVerificationError(error)); // Dispatch error action with error object
  }
}

export function* watchPostSubscriptionPaymentVerificationData() {
  yield takeEvery(POST_SUBSCRIPTION_PAYMENT_VERIFY, postSubscriptionPaymentVerificationCall);
}

export default function* rootSaga() {
  yield all([fork(watchGetSubscriptionListData)]);
  yield all([fork(watchGetSubscriptionDiscountData)]);
  yield all([fork(watchGetSubscriptionKeyData)]);
  yield all([fork(watchPostSubscriptionCreateOrderData)]);
  yield all([fork(watchPostSubscriptionPaymentVerificationData)]);
}
