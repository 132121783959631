import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Dialog } from "../../../ui/dialog";
import ImagePopUpDownload from "../../imagePopUpDownload";
import Image from "../../../assets/images/office-building.png";
import BlockModel from "./blockModel";
import ForgetPassword from "../../forgetPassword";
import ForgetPasswordModel from "./forgetPasswordModel";

function CompanyListCard({ data, reroute = false }) {
  const navigate = useNavigate();
  const [showImageModal, setShowImageModal] = useState(false);
  const [showBlockedModal, setShowBlockedModal] = useState(false);
  const [showForgetModal, setShowForgetModal] = useState(false);

  return (
    <div
      key={data?._id}
      className="grid grid-cols-12 gap-x-7 my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl"
    >
      <div className="lg:col-span-2 col-span-6 row-span-1 justify-self-center my-auto lg:row-span-3">
        <button
          type="button"
          className="rounded-full "
          onClick={() =>
            reroute
              ? navigate(`${data?._id}`)
              : setShowImageModal(true)
          }
        >
          <img
            src={data?.companyLogo ? data?.companyLogo : Image}
            alt="Company Logo"
            className={` ${
              data?.companyLogo ? "rounded-full" : ""
            }  h-[100px] w-[100px]`}
          />
        </button>
      </div>
      <Dialog open={showImageModal}>
        <ImagePopUpDownload
          image={data?.companyLogo}
          setShowModal={setShowImageModal}
          imagename={`${data?._id}`}
        />
      </Dialog>

      <div className="mb-5 text-center md:text-left lg:col-span-10 col-span-6 row-span-1 self-center lg:self-auto">
        <button
          type="button"
          className="font-bold text-blue-600 text-xl"
          onClick={
            reroute ? () => navigate(`details?companyId=${data?._id}`) : null
          }
        >
          {data?.companyName}
        </button>
        <p className="textgrey text-base">
          {data?.companyOwnerBy}, Established{" "}
          {moment(data?.companyStartDate).format("DD/MM/YYYY")}
        </p>
      </div>

      <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center">
        <div className="md:text-left">
          <h3 className="textgrey text-xs">Phone Number</h3>
          <p>{data?.phoneNumber}</p>
        </div>
        <div className="md:text-left">
          <h3 className="textgrey text-xs">Account Number</h3>
          <p>{data?.accountNumber}</p>
        </div>
        <div>
          <h3 className="textgrey text-xs md:text-center">GST Number</h3>
          <p className="md:text-center">{data?.gstNumber}</p>
        </div>
        <div>
          <h3 className="textgrey text-xs md:text-right">Email</h3>
          <p className="md:text-right">{data?.userId?.email}</p>
        </div>
        <div>
          <Dialog open={showBlockedModal}>
            <button
              onClick={() => setShowBlockedModal(true)}
              className={`primaryButton ${
                !data?.userId?.blocked
                  ? "bg-red-500 hover:bg-red-900"
                  : "hover:bg-green-900 bg-green-500"
              }`}
            >
              {!data?.userId?.blocked ? "Block" : "UnBlock"}
            </button>
              <BlockModel id={data?.userId?._id} setShowModal={setShowBlockedModal} blocked={data?.userId?.blocked} />
          </Dialog>
        </div>
        <div>
          <Dialog open={showForgetModal}>
            <button
              onClick={() => setShowForgetModal(true)}
              className={`primaryButton `}
            >
              Reset User
            </button>
              <ForgetPasswordModel userId={data?.userId?._id} setShowModal={setShowForgetModal} email={data?.userId?.email} />
          </Dialog>
        </div>
        <div>
          
        </div>
      </div>
    </div>
  );
}

export default CompanyListCard;
