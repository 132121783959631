//AUTH
export const POST_LOGIN = "POST_LOGIN";
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";
export const POST_LOGIN_ERROR = "POST_LOGIN_ERROR";
export const POST_FORGET_PASSWORD = "POST_FORGET_PASSWORD";
export const POST_FORGET_PASSWORD_SUCCESS = "POST_FORGET_PASSWORD_SUCCESS";
export const POST_FORGET_PASSWORD_ERROR = "POST_FORGET_PASSWORD_ERROR";
export const POST_REGISTER = "POST_REGISTER";
export const POST_REGISTER_SUCCESS = "POST_REGISTER_SUCCESS";
export const POST_REGISTER_ERROR = "POST_REGISTER_ERROR";
export const POST_OTP = "POST_OTP";
export const POST_OTP_SUCCESS = "POST_OTP_SUCCESS";
export const POST_OTP_ERROR = "POST_OTP_ERROR";
export const POST_RESEND_OTP = "POST_RESEND_OTP";
export const POST_RESEND_OTP_SUCCESS = "POST_RESEND_OTP_SUCCESS";
export const POST_RESEND_OTP_ERROR = "POST_RESEND_OTP_ERROR";

export const PATCH_RESET_PASSWORD = "PATCH_RESET_PASSWORD";
export const PATCH_RESET_PASSWORD_SUCCESS = "PATCH_RESET_PASSWORD_SUCCESS";
export const PATCH_RESET_PASSWORD_ERROR = "PATCH_RESET_PASSWORD_ERROR";


// token
export const SET_TOKEN = "SET_TOKEN";
export const GET_TOKEN = "GET_TOKEN";
export const CLEAR_TOKEN = "CLEAR_TOKEN";
export const SET_ROLE = "SET_ROLE";
export const GET_ROLE = "GET_ROLE";
export const CLEAR_ROLE = "CLEAR_ROLE";


export const LOGOUT = 'LOGOUT'

//Company

export const POST_COMPANY_DETAILS = "POST_COMPANY_DETAILS"
export const POST_COMPANY_DETAILS_SUCCESS = "POST_COMPANY_DETAILS_SUCCESS"
export const POST_COMPANY_DETAILS_ERROR = "POST_COMPANY_DETAILS_ERROR"
export const PATCH_COMPANY_DETAILS = "PATCH_COMPANY_DETAILS"
export const PATCH_COMPANY_DETAILS_SUCCESS = "PATCH_COMPANY_DETAILS_SUCCESS"
export const PATCH_COMPANY_DETAILS_ERROR = "PATCH_COMPANY_DETAILS_ERROR"
export const GET_COMPANY_DETAILS = "GET_COMPANY_DETAILS"
export const GET_COMPANY_DETAILS_SUCCESS = "GET_COMPANY_DETAILS_SUCCESS"
export const GET_COMPANY_DETAILS_ERROR = "GET_COMPANY_DETAILS_ERROR"
export const GET_COMPANY_SEARCH = "GET_COMPANY_SEARCH"
export const GET_COMPANY_SEARCH_SUCCESS = "GET_COMPANY_SEARCH_SUCCESS"
export const GET_COMPANY_SEARCH_ERROR = "GET_COMPANY_SEARCH_ERROR"
//company truck
export const GET_COMPANY_TRUCK_LIST_DROPDOWN = "GET_COMPANY_TRUCK_LIST_DROPDOWN"
export const GET_COMPANY_TRUCK_LIST_DROPDOWN_SUCCESS = "GET_COMPANY_TRUCK_LIST_DROPDOWN_SUCCESS"
export const GET_COMPANY_TRUCK_LIST_DROPDOWN_ERROR = "GET_COMPANY_TRUCK_LIST_DROPDOWN_ERROR"
export const GET_COMPANY_TRUCK_DETAILS = "GET_COMPANY_TRUCK_DETAILS"
export const GET_COMPANY_TRUCK_DETAILS_SUCCESS = "GET_COMPANY_TRUCK_DETAILS_SUCCESS"
export const GET_COMPANY_TRUCK_DETAILS_ERROR = "GET_COMPANY_TRUCK_DETAILS_ERROR"
export const GET_COMPANY_TRUCK_NOTSELL_DETAILS = "GET_COMPANY_TRUCK_NOTSELL_DETAILS"
export const GET_COMPANY_TRUCK_NOTSELL_DETAILS_SUCCESS = "GET_COMPANY_TRUCK_NOTSELL_DETAILS_SUCCESS"
export const GET_COMPANY_TRUCK_NOTSELL_DETAILS_ERROR = "GET_COMPANY_TRUCK_NOTSELL_DETAILS_ERROR"
export const GET_COMPANY_TRUCK_SELL_DETAILS = "GET_COMPANY_TRUCK_SELL_DETAILS"
export const GET_COMPANY_TRUCK_SELL_DETAILS_SUCCESS = "GET_COMPANY_TRUCK_SELL_DETAILS_SUCCESS"
export const GET_COMPANY_TRUCK_SELL_DETAILS_ERROR = "GET_COMPANY_TRUCK_SELL_DETAILS_ERROR"
export const GET_COMPANY_TRUCK_DETAILS_ID = "GET_COMPANY_TRUCK_DETAILS_ID"
export const GET_COMPANY_TRUCK_DETAILS_ID_SUCCESS = "GET_COMPANY_TRUCK_DETAILS_ID_SUCCESS"
export const GET_COMPANY_TRUCK_DETAILS_ID_ERROR = "GET_COMPANY_TRUCK_DETAILS_ID_ERROR"
export const POST_COMPANY_TRUCK_DETAILS = "POST_COMPANY_TRUCK_DETAILS"
export const POST_COMPANY_TRUCK_DETAILS_SUCCESS = "POST_COMPANY_TRUCK_DETAILS_SUCCESS"
export const POST_COMPANY_TRUCK_DETAILS_ERROR = "POST_COMPANY_TRUCK_DETAILS_ERROR"
export const POST_TRUCK_SELL = "POST_TRUCK_SELL"
export const POST_TRUCK_SELL_SUCCESS = "POST_TRUCK_SELL_SUCCESS"
export const POST_TRUCK_SELL_ERROR = "POST_TRUCK_SELL_ERROR"
export const GET_TRUCK_PURCHASE = "GET_TRUCK_PURCHASE"
export const GET_TRUCK_PURCHASE_SUCCESS = "GET_TRUCK_PURCHASE_SUCCESS"
export const GET_TRUCK_PURCHASE_ERROR = "GET_TRUCK_PURCHASE_ERROR"
export const POST_TRUCK_PURCHASE = "POST_TRUCK_PURCHASE"
export const POST_TRUCK_PURCHASE_SUCCESS = "POST_TRUCK_PURCHASE_SUCCESS"
export const POST_TRUCK_PURCHASE_ERROR = "POST_TRUCK_PURCHASE_ERROR"
export const PATCH_COMPANY_TRUCK_DETAILS = "PATCH_COMPANY_TRUCK_DETAILS"
export const PATCH_COMPANY_TRUCK_DETAILS_SUCCESS = "PATCH_COMPANY_TRUCK_DETAILS_SUCCESS"
export const PATCH_COMPANY_TRUCK_DETAILS_ERROR = "PATCH_COMPANY_TRUCK_DETAILS_ERROR"
export const DELETE_COMPANY_TRUCK = "DELETE_COMPANY_TRUCK"
export const DELETE_COMPANY_TRUCK_SUCCESS = "DELETE_COMPANY_TRUCK_SUCCESS"
export const DELETE_COMPANY_TRUCK_ERROR = "DELETE_COMPANY_TRUCK_ERROR"

export const GET_TRUCK_INSURANCE_BYTRUCKID = "GET_TRUCK_INSURANCE_BYTRUCKID"
export const GET_TRUCK_INSURANCE_BYTRUCKID_SUCCESS = "GET_TRUCK_INSURANCE_BYTRUCKID_SUCCESS"
export const GET_TRUCK_INSURANCE_BYTRUCKID_ERROR = "GET_TRUCK_INSURANCE_BYTRUCKID_ERROR"
export const GET_TRUCK_FITNESS_BYTRUCKID = "GET_TRUCK_FITNESS_BYTRUCKID"
export const GET_TRUCK_FITNESS_BYTRUCKID_SUCCESS = "GET_TRUCK_FITNESS_BYTRUCKID_SUCCESS"
export const GET_TRUCK_FITNESS_BYTRUCKID_ERROR = "GET_TRUCK_FITNESS_BYTRUCKID_ERROR"
export const GET_TRUCK_PERMIT_BYTRUCKID = "GET_TRUCK_PERMIT_BYTRUCKID"
export const GET_TRUCK_PERMIT_BYTRUCKID_SUCCESS = "GET_TRUCK_PERMIT_BYTRUCKID_SUCCESS"
export const GET_TRUCK_PERMIT_BYTRUCKID_ERROR = "GET_TRUCK_PERMIT_BYTRUCKID_ERROR"
export const GET_TRUCK_INSURANCE = "GET_TRUCK_INSURANCE"
export const GET_TRUCK_INSURANCE_SUCCESS = "GET_TRUCK_INSURANCE_SUCCESS"
export const GET_TRUCK_INSURANCE_ERROR = "GET_TRUCK_INSURANCE_ERROR"
export const GET_TRUCK_FITNESS = "GET_TRUCK_FITNESS"
export const GET_TRUCK_FITNESS_SUCCESS = "GET_TRUCK_FITNESS_SUCCESS"
export const GET_TRUCK_FITNESS_ERROR = "GET_TRUCK_FITNESS_ERROR"
export const GET_TRUCK_PERMIT = "GET_TRUCK_PERMIT"
export const GET_TRUCK_PERMIT_SUCCESS = "GET_TRUCK_PERMIT_SUCCESS"
export const GET_TRUCK_PERMIT_ERROR = "GET_TRUCK_PERMIT_ERROR"

//alert
export const GET_ALERT_INSURANCE = "GET_ALERT_INSURANCE"
export const GET_ALERT_INSURANCE_SUCCESS = "GET_ALERT_INSURANCE_SUCCESS"
export const GET_ALERT_INSURANCE_ERROR = "GET_ALERT_INSURANCE_ERROR"
export const GET_ALERT_FITNESS = "GET_ALERT_FITNESS"
export const GET_ALERT_FITNESS_SUCCESS = "GET_ALERT_FITNESS_SUCCESS"
export const GET_ALERT_FITNESS_ERROR = "GET_ALERT_FITNESS_ERROR"
export const GET_ALERT_PERMIT = "GET_ALERT_PERMIT"
export const GET_ALERT_PERMIT_SUCCESS = "GET_ALERT_PERMIT_SUCCESS"
export const GET_ALERT_PERMIT_ERROR = "GET_ALERT_PERMIT_ERROR"
export const GET_ALERT_LICENSE = "GET_ALERT_LICENSE"
export const GET_ALERT_LICENSE_SUCCESS = "GET_ALERT_LICENSE_SUCCESS"
export const GET_ALERT_LICENSE_ERROR = "GET_ALERT_LICENSE_ERROR"
export const PATCH_TRUCK_INSURANCE = "PATCH_TRUCK_INSURANCE"
export const PATCH_TRUCK_INSURANCE_SUCCESS = "PATCH_TRUCK_INSURANCE_SUCCESS"
export const PATCH_TRUCK_INSURANCE_ERROR = "PATCH_TRUCK_INSURANCE_ERROR"
export const PATCH_TRUCK_FITNESS = "PATCH_TRUCK_FITNESS"
export const PATCH_TRUCK_FITNESS_SUCCESS = "PATCH_TRUCK_FITNESS_SUCCESS"
export const PATCH_TRUCK_FITNESS_ERROR = "PATCH_TRUCK_FITNESS_ERROR"
export const PATCH_TRUCK_PERMIT = "PATCH_TRUCK_PERMIT"
export const PATCH_TRUCK_PERMIT_SUCCESS = "PATCH_TRUCK_PERMIT_SUCCESS"
export const PATCH_TRUCK_PERMIT_ERROR = "PATCH_TRUCK_PERMIT_ERROR"



//company dashboard
export const GET_COMPANY_DASHBOARD_TRUCK_GRAPH = "GET_COMPANY_DASHBOARD_TRUCK_GRAPH"
export const GET_COMPANY_DASHBOARD_TRUCK_GRAPH_SUCCESS = "GET_COMPANY_DASHBOARD_TRUCK_GRAPH_SUCCESS"
export const GET_COMPANY_DASHBOARD_TRUCK_GRAPH_ERROR = "GET_COMPANY_DASHBOARD_TRUCK_GRAPH_ERROR"
export const GET_COMPANY_DASHBOARD_TRUCK_PIECHART = "GET_COMPANY_DASHBOARD_TRUCK_PIECHART"
export const GET_COMPANY_DASHBOARD_TRUCK_PIECHART_SUCCESS = "GET_COMPANY_DASHBOARD_TRUCK_PIECHART_SUCCESS"
export const GET_COMPANY_DASHBOARD_TRUCK_PIECHART_ERROR = "GET_COMPANY_DASHBOARD_TRUCK_PIECHART_ERROR"
export const GET_COMPANY_DASHBOARD_TRIP_GRAPH = "GET_COMPANY_DASHBOARD_TRIP_GRAPH"
export const GET_COMPANY_DASHBOARD_TRIP_GRAPH_SUCCESS = "GET_COMPANY_DASHBOARD_TRIP_GRAPH_SUCCESS"
export const GET_COMPANY_DASHBOARD_TRIP_GRAPH_ERROR = "GET_COMPANY_DASHBOARD_TRIP_GRAPH_ERROR"
export const GET_COMPANY_DASHBOARD_TRIP_LIST = "GET_COMPANY_DASHBOARD_TRIP_LIST"
export const GET_COMPANY_DASHBOARD_TRIP_LIST_SUCCESS = "GET_COMPANY_DASHBOARD_TRIP_LIST_SUCCESS"
export const GET_COMPANY_DASHBOARD_TRIP_LIST_ERROR = "GET_COMPANY_DASHBOARD_TRIP_LIST_ERROR"
export const GET_COMPANY_DASHBOARD_TRIP_PIECHART = "GET_COMPANY_DASHBOARD_TRIP_PIECHART"
export const GET_COMPANY_DASHBOARD_TRIP_PIECHART_SUCCESS = "GET_COMPANY_DASHBOARD_TRIP_PIECHART_SUCCESS"
export const GET_COMPANY_DASHBOARD_TRIP_PIECHART_ERROR = "GET_COMPANY_DASHBOARD_TRIP_PIECHART_ERROR"
export const GET_COMPANY_DASHBOARD_TRIP_PENDING_AMOUNT = "GET_COMPANY_DASHBOARD_TRIP_PENDING_AMOUNT"
export const GET_COMPANY_DASHBOARD_TRIP_PENDING_AMOUNT_SUCCESS = "GET_COMPANY_DASHBOARD_TRIP_PENDING_AMOUNT_SUCCESS"
export const GET_COMPANY_DASHBOARD_TRIP_PENDING_AMOUNT_ERROR = "GET_COMPANY_DASHBOARD_TRIP_PENDING_AMOUNT_ERROR"
export const GET_COMPANY_DASHBOARD_EMPLOYEE_GRAPH = "GET_COMPANY_DASHBOARD_EMPLOYEE_GRAPH"
export const GET_COMPANY_DASHBOARD_EMPLOYEE_GRAPH_SUCCESS = "GET_COMPANY_DASHBOARD_EMPLOYEE_GRAPH_SUCCESS"
export const GET_COMPANY_DASHBOARD_EMPLOYEE_GRAPH_ERROR = "GET_COMPANY_DASHBOARD_EMPLOYEE_GRAPH_ERROR"
export const GET_COMPANY_DASHBOARD_EXPENSE_GRAPH = "GET_COMPANY_DASHBOARD_EXPENSE_GRAPH"
export const GET_COMPANY_DASHBOARD_EXPENSE_GRAPH_SUCCESS = "GET_COMPANY_DASHBOARD_EXPENSE_GRAPH_SUCCESS"
export const GET_COMPANY_DASHBOARD_EXPENSE_GRAPH_ERROR = "GET_COMPANY_DASHBOARD_EXPENSE_GRAPH_ERROR"
export const GET_COMPANY_DASHBOARD_EXPENSE_PIECHART = "GET_COMPANY_DASHBOARD_EXPENSE_PIECHART"
export const GET_COMPANY_DASHBOARD_EXPENSE_PIECHART_SUCCESS = "GET_COMPANY_DASHBOARD_EXPENSE_PIECHART_SUCCESS"
export const GET_COMPANY_DASHBOARD_EXPENSE_PIECHART_ERROR = "GET_COMPANY_DASHBOARD_EXPENSE_PIECHART_ERROR"

//comapny contact us 
export const GET_COMPANY_CONTACT_DETAILS = "GET_COMPANY_CONTACT_DETAILS"
export const GET_COMPANY_CONTACT_DETAILS_SUCCESS = "GET_COMPANY_CONTACT_DETAILS_SUCCESS"
export const GET_COMPANY_CONTACT_DETAILS_ERROR = "GET_COMPANY_CONTACT_DETAILS_ERROR"
export const POST_COMPANY_CONTACT_DETAILS = "POST_COMPANY_CONTACT_DETAILS"
export const POST_COMPANY_CONTACT_DETAILS_SUCCESS = "POST_COMPANY_CONTACT_DETAILS_SUCCESS"
export const POST_COMPANY_CONTACT_DETAILS_ERROR = "POST_COMPANY_CONTACT_DETAILS_ERROR"
export const PATCH_COMPANY_CONTACT_DETAILS = "PATCH_COMPANY_CONTACT_DETAILS"
export const PATCH_COMPANY_CONTACT_DETAILS_SUCCESS = "PATCH_COMPANY_CONTACT_DETAILS_SUCCESS"
export const PATCH_COMPANY_CONTACT_DETAILS_ERROR = "PATCH_COMPANY_CONTACT_DETAILS_ERROR"

//company Trip
export const GET_COMPANY_TRIP_DETAILS = "GET_COMPANY_TRIP_DETAILS"
export const GET_COMPANY_TRIP_DETAILS_SUCCESS = "GET_COMPANY_TRIP_DETAILS_SUCCESS"
export const GET_COMPANY_TRIP_DETAILS_ERROR = "GET_COMPANY_TRIP_DETAILS_ERROR"
export const GET_COMPANY_TRIP_DETAILS_ID = "GET_COMPANY_TRIP_DETAILS_ID"
export const GET_COMPANY_TRIP_DETAILS_ID_SUCCESS = "GET_COMPANY_TRIP_DETAILS_ID_SUCCESS"
export const GET_COMPANY_TRIP_DETAILS_ID_ERROR = "GET_COMPANY_TRIP_DETAILS_ID_ERROR"
export const GET_COMPANY_TRIP_DETAILS_FILTER = "GET_COMPANY_TRIP_DETAILS_FILTER"
export const GET_COMPANY_TRIP_DETAILS_FILTER_SUCCESS = "GET_COMPANY_TRIP_DETAILS_FILTER_SUCCESS"
export const GET_COMPANY_TRIP_DETAILS_FILTER_ERROR = "GET_COMPANY_TRIP_DETAILS_FILTER_ERROR"
export const GET_COMPANY_TRIP_PLANNED_DETAILS_FILTER = "GET_COMPANY_TRIP_PLANNED_DETAILS_FILTER"
export const GET_COMPANY_TRIP_PLANNED_DETAILS_FILTER_SUCCESS = "GET_COMPANY_TRIP_PLANNED_DETAILS_FILTER_SUCCESS"
export const GET_COMPANY_TRIP_PLANNED_DETAILS_FILTER_ERROR = "GET_COMPANY_TRIP_PLANNED_DETAILS_FILTER_ERROR"
export const GET_COMPANY_TRIP_RUNNING_DETAILS_FILTER = "GET_COMPANY_TRIP_RUNNING_DETAILS_FILTER"
export const GET_COMPANY_TRIP_RUNNING_DETAILS_FILTER_SUCCESS = "GET_COMPANY_TRIP_RUNNING_DETAILS_FILTER_SUCCESS"
export const GET_COMPANY_TRIP_RUNNING_DETAILS_FILTER_ERROR = "GET_COMPANY_TRIP_RUNNING_DETAILS_FILTER_ERROR"
export const GET_COMPANY_TRIP_END_DETAILS_FILTER = "GET_COMPANY_TRIP_END_DETAILS_FILTER"
export const GET_COMPANY_TRIP_END_DETAILS_FILTER_SUCCESS = "GET_COMPANY_TRIP_END_DETAILS_FILTER_SUCCESS"
export const GET_COMPANY_TRIP_END_DETAILS_FILTER_ERROR = "GET_COMPANY_TRIP_END_DETAILS_FILTER_ERROR"
export const GET_COMPANY_TRIP_COMPLETE_DETAILS_FILTER = "GET_COMPANY_TRIP_COMPLETE_DETAILS_FILTER"
export const GET_COMPANY_TRIP_COMPLETE_DETAILS_FILTER_SUCCESS = "GET_COMPANY_TRIP_COMPLETE_DETAILS_FILTER_SUCCESS"
export const GET_COMPANY_TRIP_COMPLETE_DETAILS_FILTER_ERROR = "GET_COMPANY_TRIP_COMPLETE_DETAILS_FILTER_ERROR"

export const POST_COMPANY_TRIP_DETAILS = "POST_COMPANY_TRIP_DETAILS"
export const POST_COMPANY_TRIP_DETAILS_SUCCESS = "POST_COMPANY_TRIP_DETAILS_SUCCESS"
export const POST_COMPANY_TRIP_DETAILS_ERROR = "POST_COMPANY_TRIP_DETAILS_ERROR"
export const PATCH_COMPANY_TRIP_DETAILS = "PATCH_COMPANY_TRIP_DETAILS"
export const PATCH_COMPANY_TRIP_DETAILS_SUCCESS = "PATCH_COMPANY_TRIP_DETAILS_SUCCESS"
export const PATCH_COMPANY_TRIP_DETAILS_ERROR = "PATCH_COMPANY_TRIP_DETAILS_ERROR"
export const PATCH_COMPANY_TRIP_PAYMENT = "PATCH_COMPANY_TRIP_PAYMENT"
export const PATCH_COMPANY_TRIP_PAYMENT_SUCCESS = "PATCH_COMPANY_TRIP_PAYMENT_SUCCESS"
export const PATCH_COMPANY_TRIP_PAYMENT_ERROR = "PATCH_COMPANY_TRIP_PAYMENT_ERROR"
export const DELETE_COMPANY_TRIP = "DELETE_COMPANY_TRIP"
export const DELETE_COMPANY_TRIP_SUCCESS = "DELETE_COMPANY_TRIP_SUCCESS"
export const DELETE_COMPANY_TRIP_ERROR = "DELETE_COMPANY_TRIP_ERROR"

//company Employee
export const POST_COMPANY_EMPLOYEE_DETAILS = "POST_COMPANY_EMPLOYEE_DETAILS"
export const POST_COMPANY_EMPLOYEE_DETAILS_SUCCESS = "POST_COMPANY_EMPLOYEE_DETAILS_SUCCESS"
export const POST_COMPANY_EMPLOYEE_DETAILS_ERROR = "POST_COMPANY_EMPLOYEE_DETAILS_ERROR"
export const POST_EMPLOYEE_FIND = "POST_EMPLOYEE_FIND_DETAILS"
export const POST_EMPLOYEE_FIND_SUCCESS = "POST_EMPLOYEE_FIND_DETAILS_SUCCESS"
export const POST_EMPLOYEE_FIND_ERROR = "POST_EMPLOYEE_FIND_DETAILS_ERROR"
export const PATCH_COMPANY_EMPLOYEE_SALARY = "POST_COMPANY_EMPLOYEE_SALARY"
export const PATCH_COMPANY_EMPLOYEE_SALARY_SUCCESS = "POST_COMPANY_EMPLOYEE_SALARY_SUCCESS"
export const PATCH_COMPANY_EMPLOYEE_SALARY_ERROR = "POST_COMPANY_EMPLOYEE_SALARY_ERROR"
export const PATCH_COMPANY_EMPLOYEE_DESIGNATION_DETAILS = "POST_COMPANY_EMPLOYEE_DESIGNATION_DETAILS"
export const PATCH_COMPANY_EMPLOYEE_DESIGNATION_DETAILS_SUCCESS = "POST_COMPANY_EMPLOYEE_DESIGNATION_DETAILS_SUCCESS"
export const PATCH_COMPANY_EMPLOYEE_DESIGNATION_DETAILS_ERROR = "POST_COMPANY_EMPLOYEE_DESIGNATION_DETAILS_ERROR"
export const PATCH_COMPANY_EMPLOYEE_TERMINATION_DETAILS = "POST_COMPANY_EMPLOYEE_TERMINATION_DETAILS"
export const PATCH_COMPANY_EMPLOYEE_TERMINATION_DETAILS_SUCCESS = "POST_COMPANY_EMPLOYEE_TERMINATION_DETAILS_SUCCESS"
export const PATCH_COMPANY_EMPLOYEE_TERMINATION_DETAILS_ERROR = "POST_COMPANY_EMPLOYEE_TERMINATION_DETAILS_ERROR"
export const DELETE_COMPANY_EMPLOYEE = "DELETE_COMPANY_EMPLOYEE"
export const DELETE_COMPANY_EMPLOYEE_SUCCESS = "DELETE_COMPANY_EMPLOYEE_SUCCESS"
export const DELETE_COMPANY_EMPLOYEE_ERROR = "DELETE_COMPANY_EMPLOYEE_ERROR"
export const GET_COMPANY_EMPLOYEE_DETAILS = "GET_COMPANY_EMPLOYEE_DETAILS"
export const GET_COMPANY_EMPLOYEE_DETAILS_SUCCESS = "GET_COMPANY_EMPLOYEE_DETAILS_SUCCESS"
export const GET_COMPANY_EMPLOYEE_DETAILS_ERROR = "GET_COMPANY_EMPLOYEE_DETAILS_ERROR"
export const GET_COMPANY_EMPLOYEE_DETAILS_ID = "GET_COMPANY_EMPLOYEE_DETAILS_ID"
export const GET_COMPANY_EMPLOYEE_DETAILS_ID_SUCCESS = "GET_COMPANY_EMPLOYEE_DETAILS_ID_SUCCESS"
export const GET_COMPANY_EMPLOYEE_DETAILS_ID_ERROR = "GET_COMPANY_EMPLOYEE_DETAILS_ID_ERROR"
export const PATCH_COMPANY_EMPLOYEE_DETAILS = "PATCH_COMPANY_EMPLOYEE_DETAILS"
export const PATCH_COMPANY_EMPLOYEE_DETAILS_SUCCESS = "PATCH_COMPANY_EMPLOYEE_DETAILS_SUCCESS"
export const PATCH_COMPANY_EMPLOYEE_DETAILS_ERROR = "PATCH_COMPANY_EMPLOYEE_DETAILS_ERROR"


export const POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS = "POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS"
export const POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS_SUCCESS = "POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS_SUCCESS"
export const POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS_ERROR = "POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS_ERROR"
export const PATCH_COMPANY_EMPLOYEE_PERSONAL_DETAILS = "POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS"
export const PATCH_COMPANY_EMPLOYEE_PERSONAL_DETAILS_SUCCESS = "POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS_SUCCESS"
export const PATCH_COMPANY_EMPLOYEE_PERSONAL_DETAILS_ERROR = "POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS_ERROR"
export const POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS = "POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS"
export const POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_SUCCESS = "POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_SUCCESS"
export const POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_ERROR = "POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_ERROR"
export const PATCH_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS = "POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS"
export const PATCH_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_SUCCESS = "POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_SUCCESS"
export const PATCH_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_ERROR = "POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_ERROR"
export const POST_COMPANY_EMPLOYEE_FAMILY_DETAILS = "POST_COMPANY_EMPLOYEE_FAMILY_DETAILS"
export const POST_COMPANY_EMPLOYEE_FAMILY_DETAILS_SUCCESS = "POST_COMPANY_EMPLOYEE_FAMILY_DETAILS_SUCCESS"
export const POST_COMPANY_EMPLOYEE_FAMILY_DETAILS_ERROR = "POST_COMPANY_EMPLOYEE_FAMILY_DETAILS_ERROR"
export const PATCH_COMPANY_EMPLOYEE_FAMILY_DETAILS = "PATCH_COMPANY_EMPLOYEE_FAMILY_DETAILS"
export const PATCH_COMPANY_EMPLOYEE_FAMILY_DETAILS_SUCCESS = "PATCH_COMPANY_EMPLOYEE_FAMILY_DETAILS_SUCCESS"
export const PATCH_COMPANY_EMPLOYEE_FAMILY_DETAILS_ERROR = "PATCH_COMPANY_EMPLOYEE_FAMILY_DETAILS_ERROR"
export const POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS = "POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS"
export const POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_SUCCESS = "POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_SUCCESS"
export const POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_ERROR = "POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_ERROR"
export const PATCH_COMPANY_EMPLOYEE_EXPERINCE_DETAILS = "POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS"
export const PATCH_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_SUCCESS = "POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_SUCCESS"
export const PATCH_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_ERROR = "POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_ERROR"
export const POST_COMPANY_EMPLOYEE_LICENSE_DETAILS = "POST_COMPANY_EMPLOYEE_LICENSE_DETAILS"
export const POST_COMPANY_EMPLOYEE_LICENSE_DETAILS_SUCCESS = "POST_COMPANY_EMPLOYEE_LICENSE_DETAILS_SUCCESS"
export const POST_COMPANY_EMPLOYEE_LICENSE_DETAILS_ERROR = "POST_COMPANY_EMPLOYEE_LICENSE_DETAILS_ERROR"
export const PATCH_COMPANY_EMPLOYEE_LICENSE_DETAILS = "PATCH_COMPANY_EMPLOYEE_LICENSE_DETAILS"
export const PATCH_COMPANY_EMPLOYEE_LICENSE_DETAILS_SUCCESS = "PATCH_COMPANY_EMPLOYEE_LICENSE_DETAILS_SUCCESS"
export const PATCH_COMPANY_EMPLOYEE_LICENSE_DETAILS_ERROR = "PATCH_COMPANY_EMPLOYEE_LICENSE_DETAILS_ERROR"

export const GET_COMPANY_EMPLOYEE_FILTER_LIST = "GET_COMPANY_EMPLOYEE_FILTER_LIST"
export const GET_COMPANY_EMPLOYEE_FILTER_LIST_SUCCESS = "GET_COMPANY_EMPLOYEE_FILTER_LIST_SUCCESS"
export const GET_COMPANY_EMPLOYEE_FILTER_LIST_ERROR = "GET_COMPANY_EMPLOYEE_FILTER_LIST_ERROR"

export const GET_COMPANY_EMPLOYEE_DRIVER = "GET_COMPANY_EMPLOYEE_DRIVER"
export const GET_COMPANY_EMPLOYEE_DRIVER_SUCCESS = "GET_COMPANY_EMPLOYEE_DRIVER_SUCCESS"
export const GET_COMPANY_EMPLOYEE_DRIVER_ERROR = "GET_COMPANY_EMPLOYEE_DRIVER_ERROR"
export const GET_COMPANY_EMPLOYEE_HELPER = "GET_COMPANY_EMPLOYEE_HELPER"
export const GET_COMPANY_EMPLOYEE_HELPER_SUCCESS = "GET_COMPANY_EMPLOYEE_HELPER_SUCCESS"
export const GET_COMPANY_EMPLOYEE_HELPER_ERROR = "GET_COMPANY_EMPLOYEE_HELPER_ERROR"
export const GET_EXPIRE_LICENSE = "GET_EXPIRE_LICENSE"
export const GET_EXPIRE_LICENSE_SUCCESS = "GET_EXPIRE_LICENSE_SUCCESS"
export const GET_EXPIRE_LICENSE_ERROR = "GET_EXPIRE_LICENSE_ERROR"

//salary pay 
export const GET_SALARY = "GET_SALARY";
export const GET_SALARY_SUCCESS = "GET_SALARY_SUCCESS";
export const GET_SALARY_ERROR = "GET_SALARY_ERROR";
export const GET_SALARY_BY_USER = "GET_SALARY_BY_USER";
export const GET_SALARY_BY_USER_SUCCESS = "GET_SALARY_BY_USER_SUCCESS";
export const GET_SALARY_BY_USER_ERROR = "GET_SALARY_BY_USER_ERROR";
export const GET_SALARY_BY_ID = "GET_SALARY_BY_ID";
export const GET_SALARY_BY_ID_SUCCESS = "GET_SALARY_BY_ID_SUCCESS";
export const GET_SALARY_BY_ID_ERROR = "GET_SALARY_BY_ID_ERROR";
export const POST_SALARY = "POST_SALARY";
export const POST_SALARY_SUCCESS = "POST_SALARY_SUCCESS";
export const POST_SALARY_ERROR = "POST_SALARY_ERROR";
export const PATCH_SALARY = "PATCH_SALARY";
export const PATCH_SALARY_SUCCESS = "PATCH_SALARY_SUCCESS";
export const PATCH_SALARY_ERROR = "PATCH_SALARY_ERROR";
export const DELETE_SALARY = "DELETE_SALARY";
export const DELETE_SALARY_SUCCESS = "DELETE_SALARY_SUCCESS";
export const DELETE_SALARY_ERROR = "DELETE_SALARY_ERROR";


//emi pay 
export const GET_EMI = "GET_EMI";
export const GET_EMI_SUCCESS = "GET_EMI_SUCCESS";
export const GET_EMI_ERROR = "GET_EMI_ERROR";
export const GET_EMI_BY_ID = "GET_EMI_BY_ID";
export const GET_EMI_BY_ID_SUCCESS = "GET_EMI_BY_ID_SUCCESS";
export const GET_EMI_BY_ID_ERROR = "GET_EMI_BY_ID_ERROR";
export const POST_EMI = "POST_EMI";
export const POST_EMI_SUCCESS = "POST_EMI_SUCCESS";
export const POST_EMI_ERROR = "POST_EMI_ERROR";
export const DELETE_EMI = "DELETE_EMI";
export const DELETE_EMI_SUCCESS = "DELETE_EMI_SUCCESS";
export const DELETE_EMI_ERROR = "DELETE_EMI_ERROR";


// fuel
export const GET_COMPANY_FUEL = "GET_COMPANY_FUEL"
export const GET_COMPANY_FUEL_SUCCESS = "GET_COMPANY_FUEL_SUCCESS"
export const GET_COMPANY_FUEL_ERROR = "GET_COMPANY_FUEL_ERROR"
export const GET_COMPANY_FUEL_ID = "GET_COMPANY_FUEL_ID"
export const GET_COMPANY_FUEL_ID_SUCCESS = "GET_COMPANY_FUEL_ID_SUCCESS"
export const GET_COMPANY_FUEL_ID_ERROR = "GET_COMPANY_FUEL_ID_ERROR"
export const GET_COMPANY_FUEL_FILTER = "GET_COMPANY_FUEL_FILTER"
export const GET_COMPANY_FUEL_FILTER_SUCCESS = "GET_COMPANY_FUEL_FILTER_SUCCESS"
export const GET_COMPANY_FUEL_FILTER_ERROR = "GET_COMPANY_FUEL_FILTER_ERROR"
export const GET_COMPANY_FUEL_NAME_LIST = "GET_COMPANY_FUEL_NAME_LIST"
export const GET_COMPANY_FUEL_NAME_LIST_SUCCESS = "GET_COMPANY_FUEL_NAME_LIST_SUCCESS"
export const GET_COMPANY_FUEL_NAME_LIST_ERROR = "GET_COMPANY_FUEL_NAME_LIST_ERROR"
export const POST_COMPANY_FUEL = "POST_COMPANY_FUEL"
export const POST_COMPANY_FUEL_SUCCESS = "POST_COMPANY_FUEL_SUCCESS"
export const POST_COMPANY_FUEL_ERROR = "POST_COMPANY_FUEL_ERROR"
export const PATCH_COMPANY_FUEL = "PATCH_COMPANY_FUEL"
export const PATCH_COMPANY_FUEL_SUCCESS = "PATCH_COMPANY_FUEL_SUCCESS"
export const PATCH_COMPANY_FUEL_ERROR = "PATCH_COMPANY_FUEL_ERROR"
export const DELETE_COMPANY_FUEL = "DELETE_COMPANY_FUEL"
export const DELETE_COMPANY_FUEL_SUCCESS = "DELETE_COMPANY_FUEL_SUCCESS"
export const DELETE_COMPANY_FUEL_ERROR = "DELETE_COMPANY_FUEL_ERROR"


// client
export const GET_CLIENT = "GET_CLIENT"
export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS"
export const GET_CLIENT_ERROR = "GET_CLIENT_ERROR"
export const GET_CLIENT_ID = "GET_CLIENT_ID"
export const GET_CLIENT_ID_SUCCESS = "GET_CLIENT_ID_SUCCESS"
export const GET_CLIENT_ID_ERROR = "GET_CLIENT_ID_ERROR"
export const POST_CLIENT = "POST_CLIENT"
export const POST_CLIENT_SUCCESS = "POST_CLIENT_SUCCESS"
export const POST_CLIENT_ERROR = "POST_CLIENT_ERROR"
export const PATCH_CLIENT = "PATCH_CLIENT"
export const PATCH_CLIENT_SUCCESS = "PATCH_CLIENT_SUCCESS"
export const PATCH_CLIENT_ERROR = "PATCH_CLIENT_ERROR"
export const DELETE_CLIENT = "DELETE_CLIENT"
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS"
export const DELETE_CLIENT_ERROR = "DELETE_CLIENT_ERROR"



// toll tax
export const GET_COMPANY_TOLLTAX = "GET_COMPANY_TOLLTAX"
export const GET_COMPANY_TOLLTAX_SUCCESS = "GET_COMPANY_TOLLTAX_SUCCESS"
export const GET_COMPANY_TOLLTAX_ERROR = "GET_COMPANY_TOLLTAX_ERROR"
export const GET_COMPANY_TOLLTAX_ID = "GET_COMPANY_TOLLTAX_ID"
export const GET_COMPANY_TOLLTAX_ID_SUCCESS = "GET_COMPANY_TOLLTAX_ID_SUCCESS"
export const GET_COMPANY_TOLLTAX_ID_ERROR = "GET_COMPANY_TOLLTAX_ID_ERROR"
export const GET_COMPANY_TOLLTAX_FILTER = "GET_COMPANY_TOLLTAX_FILTER"
export const GET_COMPANY_TOLLTAX_FILTER_SUCCESS = "GET_COMPANY_TOLLTAX_FILTER_SUCCESS"
export const GET_COMPANY_TOLLTAX_FILTER_ERROR = "GET_COMPANY_TOLLTAX_FILTER_ERROR"
export const POST_COMPANY_TOLLTAX = "POST_COMPANY_TOLLTAX"
export const POST_COMPANY_TOLLTAX_SUCCESS = "POST_COMPANY_TOLLTAX_SUCCESS"
export const POST_COMPANY_TOLLTAX_ERROR = "POST_COMPANY_TOLLTAX_ERROR"
export const PATCH_COMPANY_TOLLTAX = "PATCH_COMPANY_TOLLTAX"
export const PATCH_COMPANY_TOLLTAX_SUCCESS = "PATCH_COMPANY_TOLLTAX_SUCCESS"
export const PATCH_COMPANY_TOLLTAX_ERROR = "PATCH_COMPANY_TOLLTAX_ERROR"
export const DELETE_COMPANY_TOLLTAX = "DELETE_COMPANY_TOLLTAX"
export const DELETE_COMPANY_TOLLTAX_SUCCESS = "DELETE_COMPANY_TOLLTAX_SUCCESS"
export const DELETE_COMPANY_TOLLTAX_ERROR = "DELETE_COMPANY_TOLLTAX_ERROR"
// truck Service
export const GET_COMPANY_SERVICE = "GET_COMPANY_SERVICE"
export const GET_COMPANY_SERVICE_SUCCESS = "GET_COMPANY_SERVICE_SUCCESS"
export const GET_COMPANY_SERVICE_ERROR = "GET_COMPANY_SERVICE_ERROR"
export const GET_COMPANY_SERVICE_ID = "GET_COMPANY_SERVICE_ID"
export const GET_COMPANY_SERVICE_ID_SUCCESS = "GET_COMPANY_SERVICE_ID_SUCCESS"
export const GET_COMPANY_SERVICE_ID_ERROR = "GET_COMPANY_SERVICE_ID_ERROR"
export const GET_COMPANY_SERVICE_FILTER = "GET_COMPANY_SERVICE_FILTER"
export const GET_COMPANY_SERVICE_FILTER_SUCCESS = "GET_COMPANY_SERVICE_FILTER_SUCCESS"
export const GET_COMPANY_SERVICE_FILTER_ERROR = "GET_COMPANY_SERVICE_FILTER_ERROR"
export const POST_COMPANY_SERVICE = "POST_COMPANY_SERVICE"
export const POST_COMPANY_SERVICE_SUCCESS = "POST_COMPANY_SERVICE_SUCCESS"
export const POST_COMPANY_SERVICE_ERROR = "POST_COMPANY_SERVICE_ERROR"
export const PATCH_COMPANY_SERVICE = "PATCH_COMPANY_SERVICE"
export const PATCH_COMPANY_SERVICE_SUCCESS = "PATCH_COMPANY_SERVICE_SUCCESS"
export const PATCH_COMPANY_SERVICE_ERROR = "PATCH_COMPANY_SERVICE_ERROR"
export const DELETE_COMPANY_SERVICE = "DELETE_COMPANY_SERVICE"
export const DELETE_COMPANY_SERVICE_SUCCESS = "DELETE_COMPANY_SERVICE_SUCCESS"
export const DELETE_COMPANY_SERVICE_ERROR = "DELETE_COMPANY_SERVICE_ERROR"

// extra expense trip
export const GET_EXPENSE_TRIP = "GET_EXPENSE_TRIP";
export const GET_EXPENSE_TRIP_SUCCESS = "GET_EXPENSE_TRIP_SUCCESS";
export const GET_EXPENSE_TRIP_ERROR = "GET_EXPENSE_TRIP_ERROR";
export const GET_EXPENSE_TRIP_BY_TRIP = "GET_EXPENSE_TRIP_BY_TRIP";
export const GET_EXPENSE_TRIP_BY_TRIP_SUCCESS = "GET_EXPENSE_TRIP_BY_TRIP_SUCCESS";
export const GET_EXPENSE_TRIP_BY_TRIP_ERROR = "GET_EXPENSE_TRIP_BY_TRIP_ERROR";
export const GET_EXPENSE_TRIP_BY_ID = "GET_EXPENSE_TRIP_BY_ID";
export const GET_EXPENSE_TRIP_BY_ID_SUCCESS = "GET_EXPENSE_TRIP_BY_ID_SUCCESS";
export const GET_EXPENSE_TRIP_BY_ID_ERROR = "GET_EXPENSE_TRIP_BY_ID_ERROR";
export const POST_EXPENSE_TRIP = "POST_EXPENSE_TRIP";
export const POST_EXPENSE_TRIP_SUCCESS = "POST_EXPENSE_TRIP_SUCCESS";
export const POST_EXPENSE_TRIP_ERROR = "POST_EXPENSE_TRIP_ERROR";
export const PATCH_EXPENSE_TRIP = "PATCH_EXPENSE_TRIP";
export const PATCH_EXPENSE_TRIP_SUCCESS = "PATCH_EXPENSE_TRIP_SUCCESS";
export const PATCH_EXPENSE_TRIP_ERROR = "PATCH_EXPENSE_TRIP_ERROR";
export const DELETE_EXPENSE_TRIP = "DELETE_EXPENSE_TRIP";
export const DELETE_EXPENSE_TRIP_SUCCESS = "DELETE_EXPENSE_TRIP_SUCCESS";
export const DELETE_EXPENSE_TRIP_ERROR = "DELETE_EXPENSE_TRIP_ERROR";
export const GET_EXPENSE_TRIP_FILTER = "GET_EXPENSE_TRIP_FILTER";
export const GET_EXPENSE_TRIP_FILTER_SUCCESS = "GET_EXPENSE_TRIP_FILTER_SUCCESS";
export const GET_EXPENSE_TRIP_FILTER_ERROR = "GET_EXPENSE_TRIP_FILTER_ERROR";

// extra expense truck
export const GET_DEF = "GET_DEF";
export const GET_DEF_SUCCESS = "GET_DEF_SUCCESS";
export const GET_DEF_ERROR = "GET_DEF_ERROR";
export const GET_DEF_BY_TRUCK = "GET_DEF_BY_TRUCK";
export const GET_DEF_BY_TRUCK_SUCCESS = "GET_DEF_BY_TRUCK_SUCCESS";
export const GET_DEF_BY_TRUCK_ERROR = "GET_DEF_BY_TRUCK_ERROR";
export const GET_DEF_BY_ID = "GET_DEF_BY_ID";
export const GET_DEF_BY_ID_SUCCESS = "GET_DEF_BY_ID_SUCCESS";
export const GET_DEF_BY_ID_ERROR = "GET_DEF_BY_ID_ERROR";
export const POST_DEF = "POST_DEF";
export const POST_DEF_SUCCESS = "POST_DEF_SUCCESS";
export const POST_DEF_ERROR = "POST_DEF_ERROR";
export const PATCH_DEF = "PATCH_DEF";
export const PATCH_DEF_SUCCESS = "PATCH_DEF_SUCCESS";
export const PATCH_DEF_ERROR = "PATCH_DEF_ERROR";
export const DELETE_DEF = "DELETE_DEF";
export const DELETE_DEF_SUCCESS = "DELETE_DEF_SUCCESS";
export const DELETE_DEF_ERROR = "DELETE_DEF_ERROR";
export const GET_DEF_FILTER = "GET_EXPENSE_FILTER_TRUCK";
export const GET_DEF_FILTER_SUCCESS = "GET_DEF_FILTER_SUCCESS";
export const GET_DEF_FILTER_ERROR = "GET_DEF_FILTER_ERROR";


// subscription 

export const GET_SUBSCRIPTION_LIST = "GET_SUBSCRIPTION_LIST";
export const GET_SUBSCRIPTION_LIST_SUCCESS = "GET_SUBSCRIPTION_LIST_SUCCESS";
export const GET_SUBSCRIPTION_LIST_ERROR = "GET_SUBSCRIPTION_LIST_ERROR";
export const GET_SUBSCRIPTION_DISCOUNT = "GET_SUBSCRIPTION_DISCOUNT";
export const GET_SUBSCRIPTION_DISCOUNT_SUCCESS = "GET_SUBSCRIPTION_DISCOUNT_SUCCESS";
export const GET_SUBSCRIPTION_DISCOUNT_ERROR = "GET_SUBSCRIPTION_DISCOUNT_ERROR";
export const GET_SUBSCRIPTION_KEY = "GET_SUBSCRIPTION_KEY";
export const GET_SUBSCRIPTION_KEY_SUCCESS = "GET_SUBSCRIPTION_KEY_SUCCESS";
export const GET_SUBSCRIPTION_KEY_ERROR = "GET_SUBSCRIPTION_KEY_ERROR";
export const POST_SUBSCRIPTION_CREATE_ORDER = "POST_SUBSCRIPTION_CREATE_ORDER";
export const POST_SUBSCRIPTION_CREATE_ORDER_SUCCESS = "POST_SUBSCRIPTION_CREATE_ORDER_SUCCESS";
export const POST_SUBSCRIPTION_CREATE_ORDER_ERROR = "POST_SUBSCRIPTION_CREATE_ORDER_ERROR";
export const POST_SUBSCRIPTION_PAYMENT_VERIFY = "POST_SUBSCRIPTION_PAYMENT_VERIFY";
export const POST_SUBSCRIPTION_PAYMENT_VERIFY_SUCCESS = "POST_SUBSCRIPTION_PAYMENT_VERIFY_SUCCESS";
export const POST_SUBSCRIPTION_PAYMENT_VERIFY_ERROR = "POST_SUBSCRIPTION_PAYMENT_VERIFY_ERROR";



// stats
export const GET_COMPANY_STATS = "GET_COMPANY_STATS"
export const GET_COMPANY_STATS_SUCCESS = "GET_COMPANY_STATS_SUCCESS"
export const GET_COMPANY_STATS_ERROR = "GET_COMPANY_STATS_ERROR"
export const GET_TRUCK_STATS = "GET_TRUCK_STATS"
export const GET_TRUCK_STATS_SUCCESS = "GET_TRUCK_STATS_SUCCESS"
export const GET_TRUCK_STATS_ERROR = "GET_TRUCK_STATS_ERROR"
export const GET_TRUCK_BY_TRUCKID_STATS = "GET_TRUCK_BY_TRUCKID_STATS"
export const GET_TRUCK_BY_TRUCKID_STATS_SUCCESS = "GET_TRUCK_BY_TRUCKID_STATS_SUCCESS"
export const GET_TRUCK_BY_TRUCKID_STATS_ERROR = "GET_TRUCK_BY_TRUCKID_STATS_ERROR"
export const GET_TRIP_STATS = "GET_TRIP_STATS"
export const GET_TRIP_STATS_SUCCESS = "GET_TRIP_STATS_SUCCESS"
export const GET_TRIP_STATS_ERROR = "GET_TRIP_STATS_ERROR"
export const GET_TRIP_BY_TRIPID_STATS = "GET_TRIP_BY_TRIPID_STATS"
export const GET_TRIP_BY_TRIPID_STATS_SUCCESS = "GET_TRIP_BY_TRIPID_STATS_SUCCESS"
export const GET_TRIP_BY_TRIPID_STATS_ERROR = "GET_TRIP_BY_TRIPID_STATS_ERROR"
export const GET_FUEL_STATS = "GET_FUEL_STATS"
export const GET_FUEL_STATS_SUCCESS = "GET_FUEL_STATS_SUCCESS"
export const GET_FUEL_STATS_ERROR = "GET_FUEL_STATS_ERROR"
export const GET_INSURANCE_STATS = "GET_INSURANCE_STATS"
export const GET_INSURANCE_STATS_SUCCESS = "GET_INSURANCE_STATS_SUCCESS"
export const GET_INSURANCE_STATS_ERROR = "GET_INSURANCE_STATS_ERROR"
export const GET_FITNESS_STATS = "GET_FITNESS_STATS"
export const GET_FITNESS_STATS_SUCCESS = "GET_FITNESS_STATS_SUCCESS"
export const GET_FITNESS_STATS_ERROR = "GET_FITNESS_STATS_ERROR"
export const GET_PERMIT_STATS = "GET_PERMIT_STATS"
export const GET_PERMIT_STATS_SUCCESS = "GET_PERMIT_STATS_SUCCESS"
export const GET_PERMIT_STATS_ERROR = "GET_PERMIT_STATS_ERROR"
export const GET_TOLL_STATS = "GET_TOLL_STATS"
export const GET_TOLL_STATS_SUCCESS = "GET_TOLL_STATS_SUCCESS"
export const GET_TOLL_STATS_ERROR = "GET_TOLL_STATS_ERROR"
export const GET_EXTRA_EXPENSE_STATS = "GET_EXTRA_EXPENSE_STATS"
export const GET_EXTRA_EXPENSE_STATS_SUCCESS = "GET_EXTRA_EXPENSE_STATS_SUCCESS"
export const GET_EXTRA_EXPENSE_STATS_ERROR = "GET_EXTRA_EXPENSE_STATS_ERROR"
export const GET_SERVICE_STATS = "GET_SERVICE_STATS"
export const GET_SERVICE_STATS_SUCCESS = "GET_SERVICE_STATS_SUCCESS"
export const GET_SERVICE_STATS_ERROR = "GET_SERVICE_STATS_ERROR"
export const GET_DEF_STATS = "GET_DEF_STATS"
export const GET_DEF_STATS_SUCCESS = "GET_DEF_STATS_SUCCESS"
export const GET_DEF_STATS_ERROR = "GET_DEF_STATS_ERROR"
export const GET_EMI_STATS = "GET_EMI_STATS"
export const GET_EMI_STATS_SUCCESS = "GET_EMI_STATS_SUCCESS"
export const GET_EMI_STATS_ERROR = "GET_EMI_STATS_ERROR"
export const GET_SALARY_STATS = "GET_SALARY_STATS"
export const GET_SALARY_STATS_SUCCESS = "GET_SALARY_STATS_SUCCESS"
export const GET_SALARY_STATS_ERROR = "GET_SALARY_STATS_ERROR"













//admin section 

//company
export const GET_COMPANY_LIST = "GET_COMPANY_LIST"
export const GET_COMPANY_LIST_SUCCESS = "GET_COMPANY_LIST_SUCCESS"
export const GET_COMPANY_LIST_ERROR = "GET_COMPANY_LIST_ERROR"
export const GET_COMPANY_TRUCK_BY_COMPANYTRUCKID = "GET_COMPANY_TRUCK_BY_COMPANYTRUCKID"
export const GET_COMPANY_TRUCK_BY_COMPANYTRUCKID_SUCCESS = "GET_COMPANY_TRUCK_BY_COMPANYTRUCKID_SUCCESS"
export const GET_COMPANY_TRUCK_BY_COMPANYTRUCKID_ERROR = "GET_COMPANY_TRUCK_BY_COMPANYTRUCKID_ERROR"
export const GET_COMPANY_BY_COMPANYID = "GET_COMPANY_BY_COMPANYID"
export const GET_COMPANY_BY_COMPANYID_SUCCESS = "GET_COMPANY_BY_COMPANYID_SUCCESS"
export const GET_COMPANY_BY_COMPANYID_ERROR = "GET_COMPANY_BY_COMPANYID_ERROR"
export const GET_COMPANY_LOGIN_STAMPS = "GET_COMPANY_LOGIN_STAMPS"
export const GET_COMPANY_LOGIN_STAMPS_SUCCESS = "GET_COMPANY_LOGIN_STAMPS_SUCCESS"
export const GET_COMPANY_LOGIN_STAMPS_ERROR = "GET_COMPANY_LOGIN_STAMPS_ERROR"
export const POST_BLOCKED = "POST_BLOCKED"
export const POST_BLOCKED_SUCCESS = "POST_BLOCKED_SUCCESS"
export const POST_BLOCKED_ERROR = "POST_BLOCKED_ERROR"
export const POST_ADMIN_FORGET_PASSWORD = "POST_ADMIN_FORGET_PASSWORD"
export const POST_ADMIN_FORGET_PASSWORD_SUCCESS = "POST_ADMIN_FORGET_PASSWORD_SUCCESS"
export const POST_ADMIN_FORGET_PASSWORD_ERROR = "POST_ADMIN_FORGET_PASSWORD_ERROR"

//truck
export const GET_ADMIN_TRUCK_LIST = "GET_ADMIN_TRUCK_LIST"
export const GET_ADMIN_TRUCK_LIST_SUCCESS = "GET_ADMIN_TRUCK_LIST_SUCCESS"
export const GET_ADMIN_TRUCK_LIST_ERROR = "GET_ADMIN_TRUCK_LIST_ERROR"
export const GET_ADMIN_TRUCK_SUBSCRIPTION_DETAILS = "GET_ADMIN_TRUCK_SUBSCRIPTION_DETAILS"
export const GET_ADMIN_TRUCK_SUBSCRIPTION_DETAILS_SUCCESS = "GET_ADMIN_TRUCK_SUBSCRIPTION_DETAILS_SUCCESS"
export const GET_ADMIN_TRUCK_SUBSCRIPTION_DETAILS_ERROR = "GET_ADMIN_TRUCK_SUBSCRIPTION_DETAILS_ERROR"
export const GET_ADMIN_TRUCK_LIST_COMPANYID = "GET_ADMIN_TRUCK_LIST_COMPANYID"
export const GET_ADMIN_TRUCK_LIST_COMPANYID_SUCCESS = "GET_ADMIN_TRUCK_LIST_COMPANYID_SUCCESS"
export const GET_ADMIN_TRUCK_LIST_COMPANYID_ERROR = "GET_ADMIN_TRUCK_LIST_COMPANYID_ERROR"
export const GET_ADMIN_TRUCK_BY_TRUCKID = "GET_ADMIN_TRUCK_BY_TRUCKID"
export const GET_ADMIN_TRUCK_BY_TRUCKID_SUCCESS = "GET_ADMIN_TRUCK_BY_TRUCKID_SUCCESS"
export const GET_ADMIN_TRUCK_BY_TRUCKID_ERROR = "GET_ADMIN_TRUCK_BY_TRUCKID_ERROR"
export const GET_ADMIN_TRUCK_SUBSCRIPTION_LIST = "GET_ADMIN_TRUCK_SUBSCRIPTION_LIST"
export const GET_ADMIN_TRUCK_SUBSCRIPTION_LIST_SUCCESS = "GET_ADMIN_TRUCK_SUBSCRIPTION_LIST_SUCCESS"
export const GET_ADMIN_TRUCK_SUBSCRIPTION_LIST_ERROR = "GET_ADMIN_TRUCK_SUBSCRIPTION_LIST_ERROR"
export const POST_ADMIN_TRUCK_SUBSCRIPTION = "POST_ADMIN_TRUCK_SUBSCRIPTION"
export const POST_ADMIN_TRUCK_SUBSCRIPTION_SUCCESS = "POST_ADMIN_TRUCK_SUBSCRIPTION_SUCCESS"
export const POST_ADMIN_TRUCK_SUBSCRIPTION_ERROR = "POST_ADMIN_TRUCK_SUBSCRIPTION_ERROR"
export const POST_ADMIN_TRUCK_SELL = "POST_ADMIN_TRUCK_SELL"
export const POST_ADMIN_TRUCK_SELL_SUCCESS = "POST_ADMIN_TRUCK_SELL_SUCCESS"
export const POST_ADMIN_TRUCK_SELL_ERROR = "POST_ADMIN_TRUCK_SELL_ERROR"
export const POST_ADMIN_TRUCK_PURCHASE = "POST_ADMIN_TRUCK_PURCHASE"
export const POST_ADMIN_TRUCK_PURCHASE_SUCCESS = "POST_ADMIN_TRUCK_PURCHASE_SUCCESS"
export const POST_ADMIN_TRUCK_PURCHASE_ERROR = "POST_ADMIN_TRUCK_PURCHASE_ERROR"
export const DELETE_ADMIN_TRUCK = "DELETE_ADMIN_TRUCK"
export const DELETE_ADMIN_TRUCK_SUCCESS = "DELETE_ADMIN_TRUCK_SUCCESS"
export const DELETE_ADMIN_TRUCK_ERROR = "DELETE_ADMIN_TRUCK_ERROR"
export const DELETE_ADMIN_TRUCK_SUBSCRIPTION = "DELETE_ADMIN_TRUCK_SUBSCRIPTION"
export const DELETE_ADMIN_TRUCK_SUBSCRIPTION_SUCCESS = "DELETE_ADMIN_TRUCK_SUBSCRIPTION_SUCCESS"
export const DELETE_ADMIN_TRUCK_SUBSCRIPTION_ERROR = "DELETE_ADMIN_TRUCK_SUBSCRIPTION_ERROR"
export const PATCH_ADMIN_BLOCK_COMPANY_TRUCK = "PATCH_ADMIN_BLOCK_COMPANY_TRUCK"
export const PATCH_ADMIN_BLOCK_COMPANY_TRUCK_SUCCESS = "PATCH_ADMIN_BLOCK_COMPANY_TRUCK_SUCCESS"
export const PATCH_ADMIN_BLOCK_COMPANY_TRUCK_ERROR = "PATCH_ADMIN_BLOCK_COMPANY_TRUCK_ERROR"

//subscription
export const GET_ADMIN_SUBSCRIPTION_LIST = "GET_ADMIN_SUBSCRIPTION_LIST"
export const GET_ADMIN_SUBSCRIPTION_LIST_SUCCESS = "GET_ADMIN_SUBSCRIPTION_LIST_SUCCESS"
export const GET_ADMIN_SUBSCRIPTION_LIST_ERROR = "GET_ADMIN_SUBSCRIPTION_LIST_ERROR"
export const GET_ADMIN_DISCOUNT_LIST = "GET_ADMIN_DISCOUNT_LIST"
export const GET_ADMIN_DISCOUNT_LIST_SUCCESS = "GET_ADMIN_DISCOUNT_LIST_SUCCESS"
export const GET_ADMIN_DISCOUNT_LIST_ERROR = "GET_ADMIN_DISCOUNT_LIST_ERROR"
export const GET_ADMIN_SUBSCRIPTION_BY_ID = "GET_ADMIN_SUBSCRIPTION_BY_ID"
export const GET_ADMIN_SUBSCRIPTION_BY_ID_SUCCESS = "GET_ADMIN_SUBSCRIPTION_BY_ID_SUCCESS"
export const GET_ADMIN_SUBSCRIPTION_BY_ID_ERROR = "GET_ADMIN_SUBSCRIPTION_BY_ID_ERROR"
export const GET_ADMIN_DISCOUNT_BY_ID = "GET_ADMIN_DISCOUNT_BY_ID"
export const GET_ADMIN_DISCOUNT_BY_ID_SUCCESS = "GET_ADMIN_DISCOUNT_BY_ID_SUCCESS"
export const GET_ADMIN_DISCOUNT_BY_ID_ERROR = "GET_ADMIN_DISCOUNT_BY_ID_ERROR"
export const POST_ADMIN_SUBSCRIPTION = "POST_ADMIN_SUBSCRIPTION"
export const POST_ADMIN_SUBSCRIPTION_SUCCESS = "POST_ADMIN_SUBSCRIPTION_SUCCESS"
export const POST_ADMIN_SUBSCRIPTION_ERROR = "POST_ADMIN_SUBSCRIPTION_ERROR"
export const POST_ADMIN_DISCOUNT = "POST_ADMIN_DISCOUNT"
export const POST_ADMIN_DISCOUNT_SUCCESS = "POST_ADMIN_DISCOUNT_SUCCESS"
export const POST_ADMIN_DISCOUNT_ERROR = "POST_ADMIN_DISCOUNT_ERROR"
export const PATCH_ADMIN_SUBSCRIPTION = "PATCH_ADMIN_SUBSCRIPTION"
export const PATCH_ADMIN_SUBSCRIPTION_SUCCESS = "PATCH_ADMIN_SUBSCRIPTION_SUCCESS"
export const PATCH_ADMIN_SUBSCRIPTION_ERROR = "PATCH_ADMIN_SUBSCRIPTION_ERROR"
export const PATCH_ADMIN_DISCOUNT = "PATCH_ADMIN_DISCOUNT"
export const PATCH_ADMIN_DISCOUNT_SUCCESS = "PATCH_ADMIN_DISCOUNT_SUCCESS"
export const PATCH_ADMIN_DISCOUNT_ERROR = "PATCH_ADMIN_DISCOUNT_ERROR"
export const DELETE_ADMIN_SUBSCRIPTION = "DELETE_ADMIN_SUBSCRIPTION"
export const DELETE_ADMIN_SUBSCRIPTION_SUCCESS = "DELETE_ADMIN_SUBSCRIPTION_SUCCESS"
export const DELETE_ADMIN_SUBSCRIPTION_ERROR = "DELETE_ADMIN_SUBSCRIPTION_ERROR"
export const DELETE_ADMIN_DISCOUNT = "DELETE_ADMIN_DISCOUNT"
export const DELETE_ADMIN_DISCOUNT_SUCCESS = "DELETE_ADMIN_DISCOUNT_SUCCESS"
export const DELETE_ADMIN_DISCOUNT_ERROR = "DELETE_ADMIN_DISCOUNT_ERROR"



//employee

export const GET_ADMIN_EMPLOYEE_LIST = "GET_ADMIN_EMPLOYEE_LIST"
export const GET_ADMIN_EMPLOYEE_LIST_SUCCESS = "GET_ADMIN_EMPLOYEE_LIST_SUCCESS"
export const GET_ADMIN_EMPLOYEE_LIST_ERROR = "GET_ADMIN_EMPLOYEE_LIST_ERROR"
export const GET_ADMIN_EMPLOYEE_LIST_BY_COMPANYID = "GET_ADMIN_EMPLOYEE_LIST_BY_COMPANYID"
export const GET_ADMIN_EMPLOYEE_LIST_BY_COMPANYID_SUCCESS = "GET_ADMIN_EMPLOYEE_LIST_BY_COMPANYID_SUCCESS"
export const GET_ADMIN_EMPLOYEE_LIST_BY_COMPANYID_ERROR = "GET_ADMIN_EMPLOYEE_LIST_BY_COMPANYID_ERROR"
export const GET_ADMIN_EMPLOYEE_BY_EMPLOYEEID = "GET_ADMIN_EMPLOYEE_BY_EMPLOYEEID"
export const GET_ADMIN_EMPLOYEE_BY_EMPLOYEEID_SUCCESS = "GET_ADMIN_EMPLOYEE_BY_EMPLOYEEID_SUCCESS"
export const GET_ADMIN_EMPLOYEE_BY_EMPLOYEEID_ERROR = "GET_ADMIN_EMPLOYEE_BY_EMPLOYEEID_ERROR"
export const GET_ADMIN_EMPLOYEE_BY_COMPANYEMPLOYEEID = "GET_ADMIN_EMPLOYEE_BY_COMPANYEMPLOYEEID"
export const GET_ADMIN_EMPLOYEE_BY_COMPANYEMPLOYEEID_SUCCESS = "GET_ADMIN_EMPLOYEE_BY_COMPANYEMPLOYEEID_SUCCESS"
export const GET_ADMIN_EMPLOYEE_BY_COMPANYEMPLOYEEID_ERROR = "GET_ADMIN_EMPLOYEE_BY_COMPANYEMPLOYEEID_ERROR"
export const POST_ADMIN_EMPLOYEE = "POST_ADMIN_EMPLOYEE"
export const POST_ADMIN_EMPLOYEE_SUCCESS = "POST_ADMIN_EMPLOYEE_SUCCESS"
export const POST_ADMIN_EMPLOYEE_ERROR = "POST_ADMIN_EMPLOYEE_ERROR"
export const PATCH_ADMIN_EMPLOYEE_TERMINATE = "PATCH_ADMIN_EMPLOYEE_TERMINATE"
export const PATCH_ADMIN_EMPLOYEE_TERMINATE_SUCCESS = "PATCH_ADMIN_EMPLOYEE_TERMINATE_SUCCESS"
export const PATCH_ADMIN_EMPLOYEE_TERMINATE_ERROR = "PATCH_ADMIN_EMPLOYEE_TERMINATE_ERROR"

// admin expenses
export const GET_ADMIN_EXPENSES_LIST = "GET_ADMIN_EXPENSES_LIST"
export const GET_ADMIN_EXPENSES_LIST_SUCCESS = "GET_ADMIN_EXPENSES_LIST_SUCCESS"
export const GET_ADMIN_EXPENSES_LIST_ERROR = "GET_ADMIN_EXPENSES_LIST_ERROR"
export const GET_ADMIN_EXPENSE_BY_ID = "GET_ADMIN_EXPENSE_BY_ID"
export const GET_ADMIN_EXPENSE_BY_ID_SUCCESS = "GET_ADMIN_EXPENSE_BY_ID_SUCCESS"
export const GET_ADMIN_EXPENSE_BY_ID_ERROR = "GET_ADMIN_EXPENSE_BY_ID_ERROR"
export const POST_ADMIN_EXPENSE = "POST_ADMIN_EXPENSE"
export const POST_ADMIN_EXPENSE_SUCCESS = "POST_ADMIN_EXPENSE_SUCCESS"
export const POST_ADMIN_EXPENSE_ERROR = "POST_ADMIN_EXPENSE_ERROR"
export const PATCH_ADMIN_EXPENSE = "PATCH_ADMIN_EXPENSE"
export const PATCH_ADMIN_EXPENSE_SUCCESS = "PATCH_ADMIN_EXPENSE_SUCCESS"
export const PATCH_ADMIN_EXPENSE_ERROR = "PATCH_ADMIN_EXPENSE_ERROR"
export const DELETE_ADMIN_EXPENSE = "DELETE_ADMIN_EXPENSE"
export const DELETE_ADMIN_EXPENSE_SUCCESS = "DELETE_ADMIN_EXPENSE_SUCCESS"
export const DELETE_ADMIN_EXPENSE_ERROR = "DELETE_ADMIN_EXPENSE_ERROR"

//admin dashboard

export const GET_ADMIN_DASHBOARD_SUBSCRIPTION_PF = "GET_ADMIN_DASHBOARD_SUBSCRIPTION_PF"
export const GET_ADMIN_DASHBOARD_SUBSCRIPTION_PF_SUCCESS = "GET_ADMIN_DASHBOARD_SUBSCRIPTION_PF_SUCCESS"
export const GET_ADMIN_DASHBOARD_SUBSCRIPTION_PF_ERROR = "GET_ADMIN_DASHBOARD_SUBSCRIPTION_PF_ERROR"
export const GET_ADMIN_DASHBOARD_TRUCK_GRAPH = "GET_ADMIN_DASHBOARD_TRUCK_GRAPH"
export const GET_ADMIN_DASHBOARD_TRUCK_GRAPH_SUCCESS = "GET_ADMIN_DASHBOARD_TRUCK_GRAPH_SUCCESS"
export const GET_ADMIN_DASHBOARD_TRUCK_GRAPH_ERROR = "GET_ADMIN_DASHBOARD_TRUCK_GRAPH_ERROR"
export const GET_ADMIN_DASHBOARD_COMPANY_FILTER = "GET_ADMIN_DASHBOARD_COMPANY_FILTER"
export const GET_ADMIN_DASHBOARD_COMPANY_FILTER_SUCCESS = "GET_ADMIN_DASHBOARD_COMPANY_FILTER_SUCCESS"
export const GET_ADMIN_DASHBOARD_COMPANY_FILTER_ERROR = "GET_ADMIN_DASHBOARD_COMPANY_FILTER_ERROR"
export const GET_ADMIN_DASHBOARD_COMPANY_GRAPH = "GET_ADMIN_DASHBOARD_COMPANY_GRAPH"
export const GET_ADMIN_DASHBOARD_COMPANY_GRAPH_SUCCESS = "GET_ADMIN_DASHBOARD_COMPANY_GRAPH_SUCCESS"
export const GET_ADMIN_DASHBOARD_COMPANY_GRAPH_ERROR = "GET_ADMIN_DASHBOARD_COMPANY_GRAPH_ERROR"
export const GET_ADMIN_DASHBOARD_EMPLOYEE_FILTER = "GET_ADMIN_DASHBOARD_EMPLOYEE_FILTER"
export const GET_ADMIN_DASHBOARD_EMPLOYEE_FILTER_SUCCESS = "GET_ADMIN_DASHBOARD_EMPLOYEE_FILTER_SUCCESS"
export const GET_ADMIN_DASHBOARD_EMPLOYEE_FILTER_ERROR = "GET_ADMIN_DASHBOARD_EMPLOYEE_FILTER_ERROR"
export const GET_ADMIN_DASHBOARD_EMPLOYEE_GRAPH = "GET_ADMIN_DASHBOARD_EMPLOYEE_GRAPH"
export const GET_ADMIN_DASHBOARD_EMPLOYEE_GRAPH_SUCCESS = "GET_ADMIN_DASHBOARD_EMPLOYEE_GRAPH_SUCCESS"
export const GET_ADMIN_DASHBOARD_EMPLOYEE_GRAPH_ERROR = "GET_ADMIN_DASHBOARD_EMPLOYEE_GRAPH_ERROR"
export const GET_ADMIN_DASHBOARD_EXPENSES_FILTER = "GET_ADMIN_DASHBOARD_EXPENSES_FILTER"
export const GET_ADMIN_DASHBOARD_EXPENSES_FILTER_SUCCESS = "GET_ADMIN_DASHBOARD_EXPENSES_FILTER_SUCCESS"
export const GET_ADMIN_DASHBOARD_EXPENSES_FILTER_ERROR = "GET_ADMIN_DASHBOARD_EXPENSES_FILTER_ERROR"
export const GET_ADMIN_DASHBOARD_EXPENSES_GRAPH = "GET_ADMIN_DASHBOARD_EXPENSES_GRAPH"
export const GET_ADMIN_DASHBOARD_EXPENSES_GRAPH_SUCCESS = "GET_ADMIN_DASHBOARD_EXPENSES_GRAPH_SUCCESS"
export const GET_ADMIN_DASHBOARD_EXPENSES_GRAPH_ERROR = "GET_ADMIN_DASHBOARD_EXPENSES_GRAPH_ERROR"
export const GET_ADMIN_DASHBOARD_PROFIT_LOSS = "GET_ADMIN_DASHBOARD_PROFIT_LOSS"
export const GET_ADMIN_DASHBOARD_PROFIT_LOSS_SUCCESS = "GET_ADMIN_DASHBOARD_PROFIT_LOSS_SUCCESS"
export const GET_ADMIN_DASHBOARD_PROFIT_LOSS_ERROR = "GET_ADMIN_DASHBOARD_PROFIT_LOSS_ERROR"
export const GET_ADMIN_DASHBOARD_SUBSCRIPTION_GRAPH = "GET_ADMIN_DASHBOARD_SUBSCRIPTION_GRAPH"
export const GET_ADMIN_DASHBOARD_SUBSCRIPTION_GRAPH_SUCCESS = "GET_ADMIN_DASHBOARD_SUBSCRIPTION_GRAPH_SUCCESS"
export const GET_ADMIN_DASHBOARD_SUBSCRIPTION_GRAPH_ERROR = "GET_ADMIN_DASHBOARD_SUBSCRIPTION_GRAPH_ERROR"







//address

export const GET_STATE = "GET_STATE"
export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS"
export const GET_STATE_ERROR = "GET_STATE_ERROR"
export const GET_CITY = "GET_CITY"
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS"
export const GET_CITY_ERROR = "GET_CITY_ERROR"






// export * from "./action";
