import { Switch } from "../../../ui/switch";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAdminDiscountByIdAction, patchAdminDiscountAction, postAdminDiscountAction } from "../../../redux/admin/subscription/action";
import DatePicker from "../../datepicker/datePicker";
import { getCompanySearchAction } from "../../../redux/company/action";
import Backdrop from "../../backdrop";
function AddDiscount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    postAdminDiscount,
    postAdminDiscountLoading,
    postAdminDiscountError,
    patchAdminDiscount,
    patchAdminDiscountLoading,
    patchAdminDiscountError,
    getAdminDiscountById,
    getAdminDiscountByIdLoading,
    getAdminDiscountByIdError,
  } = useSelector((state) => state.AdminSubscriptionSection);
  const { getCompanySearch, getCompanySearchLoading, getCompanySearchError } =
    useSelector((state) => state.Companysection);
  const [loading, setLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [companyError, setCompanyError] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [patchLoading, setPatchLoading] = useState(false);
  const [edit, setEdit] = useState(null);
  const [editLoading, setEditLoading] = useState(false);
  useEffect(() => {
    const id = searchParams.get("id");
    if (id !== null) {
      dispatch(getAdminDiscountByIdAction(id));
      setEditLoading(true)
    }
    if(id=== null){
      setEdit(null)
    }
  }, []);
  useEffect(() => {
    if (postLoading) {
      if (postAdminDiscount.status === 200) {
        toast.success(postAdminDiscount.message, {
          id: "subscriptionToast",
        });
        navigate(-1);
      }
    }
  }, [postAdminDiscount]);
  useEffect(() => {
    if (postLoading) {
      if (postAdminDiscountError.status === 400) {
        toast.error(postAdminDiscountError.message, {
          id: "subscriptionToast",
        });
      } else if (postAdminDiscountError.status === 404) {
        toast.error(postAdminDiscountError.message, {
          id: "subscriptionToast",
        });
      } else if (postAdminDiscountError.status === 500) {
        toast.error(postAdminDiscountError.error, {
          id: "subscriptionToast",
        });
      }
    }
  }, [postAdminDiscountError]);
  useEffect(() => {
    if (loading && !getCompanySearchLoading) {
      if (getCompanySearch.status === 200) {
        setLoading(false);
        setCompanyName(getCompanySearch?.data?.companyName);
      }
    }
  }, [loading, getCompanySearch]);
  useEffect(() => {
    if (loading && !getCompanySearchLoading) {
      if (getCompanySearchError.status === 400) {
        setLoading(false);
        setCompanyError(getCompanySearchError?.message);
      } else if (getCompanySearchError.status === 404) {
        setLoading(false);
        setCompanyError(getCompanySearchError?.message);
      } else if (getCompanySearchError.status === 500) {
        setLoading(false);
        setCompanyError("Company Not Found");
      }
    }
  }, [loading, getCompanySearchError]);
  
  useEffect(() => {
    if (patchLoading) {
      if (patchAdminDiscount.status === 200) {
        toast.success(patchAdminDiscount.message, {
          id: "subscriptionUpdateToast",
        });
        setPatchLoading(false)
        navigate(-1);
      }
    }
  }, [patchAdminDiscount]);
  useEffect(() => {
    if (patchLoading) {
      if (patchAdminDiscountError.status === 400) {
        toast.error(patchAdminDiscountError.message, {
          id: "subscriptionUpdateToast",
        });
        setPatchLoading(false)
      } else if (patchAdminDiscountError.status === 404) {
        toast.error(patchAdminDiscountError.message, {
          id: "subscriptionUpdateToast",
        });
        setPatchLoading(false)
      } else if (patchAdminDiscountError.status === 500) {
        toast.error(patchAdminDiscountError.error, {
          id: "subscriptionUpdateToast",
        });
        setPatchLoading(false)
      }
    }
  }, [patchAdminDiscountError]);
  
  useEffect(() => {
    if(editLoading){

      if (getAdminDiscountById.status === 200) {
        setEdit(getAdminDiscountById?.data);
      }
    }
  }, [getAdminDiscountById]);
  useEffect(() => {
    if (getAdminDiscountByIdError.status === 400) {
      toast.error(getAdminDiscountByIdError.message);
    } else if (getAdminDiscountByIdError.status === 404) {
      toast.error(getAdminDiscountByIdError.message);
    } else if (getAdminDiscountByIdError.status === 500) {
      toast.error(getAdminDiscountByIdError.error);
    }
  }, [getAdminDiscountByIdError]);
  const initialValues = {
    condition: edit?.condition || "general",
    extraDiscountName: edit?.extraDiscountName || "",
    extraDiscountPercent: edit?.extraDiscountPercent || null,
    validFrom: edit?.validFrom ? new Date(edit?.validFrom) : "",
    validTo: edit?.validTo ? new Date(edit?.validTo) : "",
    minValue: edit?.minValue || null,
    maxValue: edit?.maxValue || null,
  };
  const validationSchema = Yup.object({
    condition: Yup.string().required("Condition is required"),
    extraDiscountName: Yup.string()
      .required("Discount name is required")
      .min(3, "Must be at least 3 characters"),
    extraDiscountPercent: Yup.number()
      .required("Discount percent is required")
      .min(0, "Discount percent cannot be less than 0")
      .max(100, "Discount percent cannot be more than 100"),
    minValue: Yup.number().when("condition", {
      is: (condition) => condition === "loyalty" || condition === "truckCount",
      then: () =>
        Yup.number()
          .required("Min value is required")
          .min(0, "Min value cannot be less than 0"),
      otherwise: () => Yup.number().nullable(),
    }),
    maxValue: Yup.number().when("condition", {
      is: (condition) => condition === "loyalty" || condition === "truckCount",
      then: () =>
        Yup.number()
          .required("Max value is required")
          .min(0, "Max value cannot be less than 0"),
      otherwise: () => Yup.number().nullable(),
    }),
    userId: Yup.string().when("condition", {
      is: "userSpecific",
      then: () =>
        Yup.string()
          .required("User ID is required")
          .length(24, "User ID must be 24 characters"),
      otherwise: () => Yup.string().nullable(),
    }),
  });

  const handleCompany = (value) => {
    if (value?.length === 24) {
      dispatch(getCompanySearchAction(value));
      setLoading(true);
    } else {
      setCompanyError(null);
      setCompanyName(null);
    }
  };
  const onSubmit = (values) => {
    dispatch(postAdminDiscountAction(values));
    setPostLoading(true);
    toast.loading("Discount Data Saving...", { id: "subscriptionToast" });
  };
  const onUpdate = (values) => {
    const id = searchParams.get("id");
    const payload = {
      id: id,
      body: values,
    };
    dispatch(patchAdminDiscountAction(payload));
    setPatchLoading(true);
    toast.loading("Discount Data Updating...", {
      id: "subscriptionUpdateToast",
    });
  };

  return (
    <div>
      {(getCompanySearchLoading || getAdminDiscountByIdLoading)  && <Backdrop />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={edit ? onUpdate : onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values, errors }) => (
          <Form>
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 ">
              <div>
                <label htmlFor="condition">
                  Condition<span>*</span>
                </label>
                <Field
                  as="select"
                  id="condition"
                  name="condition"
                  className=""
                  placeholder="Condition"
                >
                  {/* <option value="default">Default</option> */}
                  <option value="general">General</option>
                  <option value="userSpecific">User Specific</option>
                  <option value="loyalty">Loyalty</option>
                  <option value="truckCount">Truck Count</option>
                </Field>
                <ErrorMessage
                  name="condition"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div>
                <label htmlFor="extraDiscountName">
                  Extra Discount Name<span>*</span>
                </label>
                <Field
                  type="text"
                  id="extraDiscountName"
                  name="extraDiscountName"
                  className=""
                  placeholder="Extra Discount Name"
                />
                <ErrorMessage
                  name="extraDiscountName"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div>
                <label htmlFor="extraDiscountPercent">
                  Extra Discount Percent<span>*</span>
                </label>
                <Field
                  type="number"
                  id="extraDiscountPercent"
                  name="extraDiscountPercent"
                  className=""
                  placeholder="Extra Discount Percent"
                />
                <ErrorMessage
                  name="extraDiscountPercent"
                  component="div"
                  className="text-red-500"
                />
              </div>
       
                  <div>
                    <label htmlFor="validFrom">Start From</label>
                    <DatePicker
                      name="validFrom"
                      id="validFrom"
                      minDate={new Date()}
                      startDate={new Date()}
                      endDate={null}
                      selectsStart
                      placeHolder="Select Date Range"
                    />
                  </div>
                  <div>
                    <label htmlFor="validTo">End To</label>
                    <DatePicker
                      name="validTo"
                      id="validTo"
                      startDate={new Date()}
                      endDate={null}
                      selectsEnd
                      minDate={values.validFrom}
                      placeHolder="Select Date Range"
                    />
                  </div>
              

              {(values.condition === "loyalty" ||
                values.condition === "truckCount") && (
                <>
                  <div>
                    <label htmlFor="minValue">
                      Min Value<span>*</span>
                    </label>
                    <Field
                      type="number"
                      id="minValue"
                      name="minValue"
                      className=""
                      placeholder="Min Value"
                    />
                    <ErrorMessage
                      name="minValue"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div>
                    <label htmlFor="maxValue">
                      Max Value<span>*</span>
                    </label>
                    <Field
                      type="number"
                      id="maxValue"
                      name="maxValue"
                      className=""
                      placeholder="Max Value"
                    />
                    <ErrorMessage
                      name="maxValue"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </>
              )}
              {values.condition === "userSpecific" && (
                <div>
                  <label htmlFor="userId">
                    Company ID (Find Id in Company Details)
                    <span>*</span>
                  </label>
                  <Field
                    type="text"
                    id="userId"
                    name="userId"
                    maxlength={24}
                    className=""
                    onChange={(e) => {
                      setFieldValue("userId", e.target.value);
                      handleCompany(e.target.value);
                    }}
                    placeholder="Company ID"
                  />
                  <ErrorMessage
                    name="userId"
                    component="div"
                    className="error"
                  />
                  {companyError && <div className="error">{companyError}</div>}
                  {companyName && (
                    <div className="flex text-sm gap-1">
                      Company Name is
                      <div className="text-green-600 text-sm uppercase font-bold">
                        {" "}
                        {companyName}
                      </div>
                    </div>
                  )}
                </div>
              )}
              <button
                type="submit"
                className="primaryButton h-fit mt-auto"
                disabled={postAdminDiscountLoading || patchAdminDiscountLoading}
              >
                {edit ? "Update" : "Submit"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddDiscount;
