import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "../../backdrop";
import { Chart } from "react-google-charts";
import { getCompanyDashboardTruckPiechartAction } from "../../../redux/company/dashboard/action";
import { getCompanyTruckListDropdownAction } from "../../../redux/company/truck/action";

function TruckPiechart() {
  const dispatch = useDispatch();
  const {
    getCompanyDashboardTruckPiechart,
  } = useSelector((state) => state.CompanyDashboardSection);
  const { getCompanyTruckListDropdown, getCompanyTruckListDropdownLoading } = useSelector(
    (state) => state.Companytrucksection
  );
  const [year, setYear] = useState("2024");
  const [truckData, setTruckData] = useState([]);
  const [truck, setTruck] = useState("");
  const [data, setData] = useState([
    ["Category", "Amount"], // Initialize with headers
  ]);

  useEffect(() => {
    dispatch(getCompanyTruckListDropdownAction(""));
  }, [dispatch]);

  useEffect(() => {
    if (getCompanyTruckListDropdown?.status === 200) {
      setTruckData(getCompanyTruckListDropdown?.trucks);
    }
  }, [getCompanyTruckListDropdown]);

  // Fetch and set data when component mounts or year/truck changes
  useEffect(() => {
    const loadTruckData = () => {
      const queryParams = {
        truckId: truck,
        startDate: "",
        endDate: "",
        year: year,
      };

      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");

      dispatch(getCompanyDashboardTruckPiechartAction(queryString));
    };

    loadTruckData();
  }, [dispatch, year, truck]);

  // Update chart data when API response changes
  useEffect(() => {
    if (getCompanyDashboardTruckPiechart?.status === 200) {
      const chartData = [
        ["Category", "Amount"],
        ...getCompanyDashboardTruckPiechart?.data?.chart?.map((item) => [
          item.label,
          item.total,
        ]),
      ];
      setData(chartData.length > 1 ? chartData : [["Category", "Amount"], ["No Data", 1]]); // Add fallback when no data
    }
  }, [getCompanyDashboardTruckPiechart]);

  // Google Charts options
  const options = {
    is3D: false,
    pieHole: 0.4,
    legend: {
      position: "top",
      alignment: "center",
      textStyle: {
        color: "#233238",
        fontSize: 10,
      },
}
  };

  return (
    <div className="bg-white p-4 h-full order-5 xl:col-span-1 lg:col-span-1 col-span-2">
      <div className="flex justify-between">
        <div className="text-xl xl:text-2xl font-bold mb-4">Truck Expenses</div>
        <div className="flex flex-col xl:flex-row gap-4">
          <select
            value={year}
            className="border border-gray-200  h-fit text-xs min-h-fit"
            onChange={(e) => setYear(e.target.value)}
          >
            <option value="">All</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
          </select>
          <select
            id="truckId"
            name="truckId"
            value={truck}
            onChange={(e) => setTruck(e.target.value)}
            className="border border-gray-200  h-fit text-xs min-h-fit"
            placeholder="Truck Number"
          >
            <option value="">Select Truck</option>
            {truckData?.map((item, index) => (
              <option key={index} value={item._id}>
                {item.truckNumber}
              </option>
            ))}
          </select>
        </div>
      </div>

      <Chart
        chartType="PieChart"
        width="100%"
        height={300}
        data={data}
        options={options}
      />
    </div>
  );
}

export default TruckPiechart;
