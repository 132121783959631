// sagas.js
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import api from "../../../../auth/api";
import {
  GET_SALARY,
  GET_SALARY_BY_USER,
  POST_SALARY,
  PATCH_SALARY,
  DELETE_SALARY,
  GET_SALARY_BY_ID,
} from "../../../actions";
import {
  getSalarySuccess,
  getSalaryError,
  getSalaryByUserSuccess,
  getSalaryByUserError,
  postSalarySuccess,
  postSalaryError,
  patchSalarySuccess,
  patchSalaryError,
  deleteSalarySuccess,
  deleteSalaryError,
  getSalaryByIdSuccess,
  getSalaryByIdError,
} from "./action";

const getSalaryAsync = async () => {
  try {
    const response = await api.get("/company/salary/get");
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* getSalary() {
  try {
    const data = yield call(getSalaryAsync);
    yield put(getSalarySuccess(data));
  } catch (error) {
    yield put(getSalaryError(error));
  }
}

export function* watchGetSalary() {
  yield takeEvery(GET_SALARY, getSalary);
}

const getSalaryByUserAsync = async (userId) => {
  try {
    const response = await api.get(`/company/salary/get/${userId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* getSalaryByUser(action) {
  try {
    const data = yield call(getSalaryByUserAsync, action.payload);
    yield put(getSalaryByUserSuccess(data));
  } catch (error) {
    yield put(getSalaryByUserError(error));
  }
}

export function* watchGetSalaryByUser() {
  yield takeEvery(GET_SALARY_BY_USER, getSalaryByUser);
}


const getSalaryByIdAsync = async (userId) => {
  try {
    const response = await api.get(`/company/salary/getById/${userId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* getSalaryById(action) {
  try {
    const data = yield call(getSalaryByIdAsync, action.payload);
    yield put(getSalaryByIdSuccess(data));
  } catch (error) {
    yield put(getSalaryByIdError(error));
  }
}

export function* watchGetSalaryById() {
  yield takeEvery(GET_SALARY_BY_ID, getSalaryById);
}

const postSalaryAsync = async (data) => {
  try {
    const response = await api.post("/company/salary/add", data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* postSalary(action) {
  try {
    const data = yield call(postSalaryAsync, action.payload);
    yield put(postSalarySuccess(data));
  } catch (error) {
    yield put(postSalaryError(error));
  }
}

export function* watchPostSalary() {
  yield takeEvery(POST_SALARY, postSalary);
}

const patchSalaryAsync = async (payload) => {
  console.log(payload)
  try {
    const response = await api.patch(`/company/salary/update/${payload.payload.id}`, payload.payload.body);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* patchSalary(action) {
  try {
    const data = yield call(patchSalaryAsync, action);
    yield put(patchSalarySuccess(data));
  } catch (error) {
    yield put(patchSalaryError(error));
  }
}

export function* watchPatchSalary() {
  yield takeEvery(PATCH_SALARY, patchSalary);
}

const deleteSalaryAsync = async (id) => {
  try {
    const response = await api.delete(`/company/salary/delete/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* deleteSalary(action) {
  try {
    const data = yield call(deleteSalaryAsync, action.payload);
    yield put(deleteSalarySuccess(data));
  } catch (error) {
    yield put(deleteSalaryError(error));
  }
}

export function* watchDeleteSalary() {
  yield takeEvery(DELETE_SALARY, deleteSalary);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetSalary),
    fork(watchGetSalaryByUser),
    fork(watchGetSalaryById),
    fork(watchPostSalary),
    fork(watchPatchSalary),
    fork(watchDeleteSalary),
  ]);
}
