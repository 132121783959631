import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_COMPANY_BY_COMPANYID,
  GET_COMPANY_LIST,
  GET_COMPANY_LOGIN_STAMPS,
  GET_COMPANY_TRUCK_BY_COMPANYTRUCKID,
  POST_ADMIN_FORGET_PASSWORD,
  POST_BLOCKED,
} from "../../actions";
import {
  getCompanyByCompanyIdError,
  getCompanyByCompanyIdSuccess,
  getCompanyListError,
  getCompanyListSuccess,
  getCompanyLoginStampError,
  getCompanyLoginStampSuccess,
  getCompanyTruckByCompanyTruckIdError,
  getCompanyTruckByCompanyTruckIdSuccess,
  postAdminForgetPasswordError,
  postAdminForgetPasswordSuccess,
  postBlockedError,
  postBlockedSuccess,
} from "./action";
import api from "../../../auth/api";

// Async function to handle API call
const getCompanyListAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/company/get`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyListCall(action) {
  try {
    const data = yield call(getCompanyListAsync, action); // Call async function with action payload
    yield put(getCompanyListSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyListError(error)); // Dispatch error action with error object
  }
}
export function* watchGetCompanyListData() {
  yield takeEvery(GET_COMPANY_LIST, getCompanyListCall);
}

// Async function to handle API call
const getCompanyTruckByCompanyTruckIdAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/company/getTruck/${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyTruckByCompanyTruckIdCall(action) {
  try {
    const data = yield call(
      getCompanyTruckByCompanyTruckIdAsync,
      action.payload
    ); // Call async function with action payload
    yield put(getCompanyTruckByCompanyTruckIdSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyTruckByCompanyTruckIdError(error)); // Dispatch error action with error object
  }
}
export function* watchGetCompanyTruckByCompanyIdData() {
  yield takeEvery(
    GET_COMPANY_TRUCK_BY_COMPANYTRUCKID,
    getCompanyTruckByCompanyTruckIdCall
  );
}

// Async function to handle API call
const getCompanyByCompanyIdAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/company/get/${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyByCompanyIdCall(action) {
  try {
    const data = yield call(getCompanyByCompanyIdAsync, action.payload); // Call async function with action payload
    yield put(getCompanyByCompanyIdSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyByCompanyIdError(error)); // Dispatch error action with error object
  }
}
export function* watchGetCompanyByCompanyIdData() {
  yield takeEvery(GET_COMPANY_BY_COMPANYID, getCompanyByCompanyIdCall);
}

// Async function to handle API call
const getCompanyLoginStampAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/company/get/loginStamp/${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyLoginStampCall(action) {
  try {
    const data = yield call(getCompanyLoginStampAsync, action.payload); // Call async function with action payload
    yield put(getCompanyLoginStampSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyLoginStampError(error)); // Dispatch error action with error object
  }
}
export function* watchGetCompanyLoginStampData() {
  yield takeEvery(GET_COMPANY_LOGIN_STAMPS, getCompanyLoginStampCall);
}

// Async function to handle API call
const postBlockedAsync = async (payload) => {
  try {
    const response = await api.post(
      `/admin/company/blocked/${payload.id}`,
      payload.body
    );
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* postBlockedCall(action) {
  try {
    const data = yield call(postBlockedAsync, action.payload); // Call async function with action payload
    yield put(postBlockedSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postBlockedError(error)); // Dispatch error action with error object
  }
}
export function* watchPostBlockedData() {
  yield takeEvery(POST_BLOCKED, postBlockedCall);
}

// Async function to handle API call
const postAdminForgetPasswordAsync = async (payload) => {
  try {
    console.log(payload);
    const response = await api.post(`/admin/company/forget-password`, payload);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* postAdminForgetPasswordCall(action) {
  try {
    const data = yield call(postAdminForgetPasswordAsync, action.payload); // Call async function with action payload
    yield put(postAdminForgetPasswordSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postAdminForgetPasswordError(error)); // Dispatch error action with error object
  }
}
export function* watchPostAdminForgetPasswordData() {
  yield takeEvery(POST_ADMIN_FORGET_PASSWORD, postAdminForgetPasswordCall);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetCompanyListData),
    fork(watchGetCompanyTruckByCompanyIdData),
    fork(watchGetCompanyByCompanyIdData),
    fork(watchGetCompanyLoginStampData),
    fork(watchPostBlockedData),
    fork(watchPostAdminForgetPasswordData),
  ]);
}
