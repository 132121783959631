import { Checkbox } from "../../../ui/checkbox";
import {
  getAdminDashboardSubscriptionFilterAction,
  getAdminDashboardTruckGraphAction,
} from "../../../redux/admin/dashboard/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "react-apexcharts";

function TruckCard() {
  const dispatch = useDispatch();
  const {
    getAdminDashboardTruckGraph,
    getAdminDashboardTruckGraphLoading,
    getAdminDashboardTruckGraphError,
  } = useSelector((state) => state.AdminDashboardSection);
  // Local state to store filter values
  const [isRunningTruck, setIsRunningTruck] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [year, setYear] = useState("2024");
  const [data, setData] = useState(0);

  useEffect(() => {
    if (getAdminDashboardTruckGraph?.status === 200) {
      setData(getAdminDashboardTruckGraph.data);
    }
  }, [getAdminDashboardTruckGraph]);

  // Load truck data when component mounts or state changes
  useEffect(() => {
    initialLoad(isRunningTruck, startDate, endDate, year);
  }, [isRunningTruck, startDate, endDate, year]); // Add dependencies to re-run on changes

  const initialLoad = (isRunningTruckValue, startDateValue, endDateValue, yearValue) => {
    const queryParams = {
      isRunningTruck: isRunningTruckValue,
      startDate: startDateValue,
      endDate: endDateValue,
      year: yearValue
    };

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    // Dispatch action to get truck count
    dispatch(getAdminDashboardTruckGraphAction(queryString));
  };

  const handleCheckboxChange = () => {
    // Toggle isRunningTruck state
    setIsRunningTruck((prev) => !prev);
  };

  const chartData = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false
        }
      },
      xaxis: {
        categories: data?.graph?.map((item) => item.period),
      },
      stroke: {
        curve: "smooth",
      },
      colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800']

    },

    series: [
      {
        name: "Total Cost",
        data: data?.graph?.map((item) => item.trucksAdded),
      },
    ],
  };

  return (
    <div className="bg-white p-4 h-fit ">
      <div className="flex justify-between">
        <div className="text-4xl font-bold mb-4">Trucks</div>
        <div className="flex gap-4">
          <div className="flex gap-1 my-auto">
            {/* Pass handleCheckboxChange as a function reference */}
            <Checkbox
              checked={isRunningTruck}
              onCheckedChange={handleCheckboxChange}
              id="running"
            />
            <label htmlFor="running">Running</label>
          </div>
          <div>
            {/* Pass handleCheckboxChange as a function reference */}
           <select name="" id="" value={year} className="border border-gray-200 " onChange={(e)=>setYear(e.target.value)}>
            <option value="">All</option>
            <option value="2024">2024</option>
            <option value="2023">2023</option>
            <option value="2022">2022</option>
           </select>
          </div>
        </div>
      </div>
      <div className="text-3xl font-bold mb-4">{data.count}<label> Total Trucks {isRunningTruck ? "Running" : "Available"}</label></div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={200}
        // width="500"
      />
    </div>
  );
}

export default TruckCard;
