import { getCompanyTruckListDropdownAction } from "../../../redux/company/truck/action";
import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "../../datepicker/datePicker";
import { Field, Form, Formik } from "formik";
import { FiDownload } from "react-icons/fi";
import * as Yup from "yup";
import { useSearchParams } from "react-router-dom"; // Import useSearchParams
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import moment from "moment";

function FilterSection({ data }) {
  const dispatch = useDispatch();
  const { getCompanyTruckListDropdown, getCompanyTruckListDropdownLoading } = useSelector(
    (state) => state.Companytrucksection
  );

  const [truck, setTruck] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams(); // Search params hook

  useEffect(() => {
    dispatch(getCompanyTruckListDropdownAction(""));
  }, []);

  useEffect(() => {
    if (getCompanyTruckListDropdown?.status === 200) {
      setTruck(getCompanyTruckListDropdown?.trucks);
    }
  }, [getCompanyTruckListDropdown]);

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    allTruck: Yup.boolean(),
    truckId: Yup.string().when("allTruck", {
      is: false, // When allTruck is unchecked (false)
      then: () =>
        Yup.string().required(
          "Truck ID is required when 'All Truck' is unchecked"
        ),
    }),
    startDate: Yup.date().nullable(),
    endDate: Yup.date().nullable(),
  });

  console.log(searchParams.get("startDate"));
  // Initialize form values from search params
  const initialValues = {
    allTruck: searchParams.get("allTruck") === "true",
    truckId: searchParams.get("truckId") || "",
    startDate:
      searchParams.get("startDate") !== ""
        ? new Date(searchParams.get("startDate"))
        : "",
    endDate:
      searchParams.get("startDate") !== ""
        ? new Date(searchParams.get("endDate"))
        : "",
  };

  const handleFilterSubmit = (values) => {
    // Update search params based on form values
    const params = {
      allTruck: true,
      truckId: "",
      startDate: "",
      endDate: "",
    };
    if (values.allTruck !== true) params.allTruck = values.allTruck;
    if (values.allTruck !== true) {
      params.truckId = values.truckId;
    } else {
      params.truckId = "";
    }
    if (values.startDate) params.startDate = values.startDate;
    if (values.endDate) params.endDate = values.endDate;

    setSearchParams(params);

    // Dispatch or other action to filter data based on values
  };

  const handleReset = () => {
    // const params = {
    //     allTruck: true,
    //     truckId: "",
    //     startDate: "",
    //     endDate: "",
    // };
    // setSearchParams(params);
  };
  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 2,
        }).format(amount)
      : "₹0.00";

  const handleExport = () => {
    //       if (!data || !data.expenses) {
    //         console.error("Data or data.expenses is undefined");
    //         return;
    //       }
    //       const combinedData = [
    //         ...data.expenses.fuel?.details?.map(item => ({
    //           'Fuel Bill No': item?.billNumber || "-",
    //           'Fuel Fill Date': item?.fuelFillDate ? moment(item?.fuelFillDate).format("DD / MMM / YYYY") : "-",
    //           'Pump Name': item?.pumpName || "-",
    //           'Fuel Quantity': item?.quantity ? item?.quantity + "Ltr" : "-",
    //           'Fuel Truck Number': item?.truckId ? item?.truckId.truckNumber : "-",
    //           'Fuel Trip': item?.tripId ? `${item?.tripId?.tokenNumber} (${item?.tripId?.startLocation}-${item?.tripId?.endLocation})` : "-",
    //           'Fuel Cost': item?.cost ? formatAmount(item?.cost) : "-"
    //         })) || [],
    //         {},
    //         ...data.expenses.toll?.details?.map((item) => ({
    //           'Toll Bill No': item.billNumber || "-",
    //           'Toll Date': item.taxPayDate ? moment(item.taxPayDate).format("DD / MMM / YYYY") : "-",
    //           'Toll Name': item.tollName || "-",
    //           'Type Of Payment': item.typeOfPayment || "-",
    //           'Toll Truck Number': item.truckId ? item.truckId.truckNumber : "-",
    //           'Toll Trip': item?.tripId ? `${item?.tripId?.tokenNumber} (${item?.tripId?.startLocation}-${item?.tripId?.endLocation})` : "-",
    //           'Toll Cost': formatAmount(item.cost) || "-"
    //         })) || [],
    //         {},
    //         ...data.expenses.service?.details?.map((item) => ({
    //           'Service Bill No': item.billNumber || "-",
    //           'Service Date': item.serviceDate ? moment(item.serviceDate).format("DD / MMM / YYYY") : "-",
    //           'Service Center Name': item.serviceCentreName || "-",
    //           'Parts': item.partsChange ? item?.partsChange?.map(part => part.partName).join(', ') : "-",
    //           'Service odoMeterReading': item.odoMeterReading ? item.odoMeterReading : "-",
    //           'Service Truck Number': item.truckId ? item.truckId.truckNumber : "-",
    //           'Service Cost': formatAmount(item.serviceCost) || "-"
    //         })) || [],
    //         {},
    //         // ...data.expenses.extra?.details?.map((item) => ({ ...item, category: "Extra" })) || [],
    //         // ...data.expenses.salary?.details?.map((item) => ({ ...item, category: "Salary" })) || [],
    //         // ...data.expenses.emi?.details?.map((item) => ({ ...item, category: "EMI" })) || [],
    //         // ...data.expenses.def?.details?.map((item) => ({ ...item, category: "Def" })) || [],
    //         // ...data.expenses.subscription?.details?.map((item) => ({
    //         //   ...item,
    //         //   category: "Subscription",
    //         // })) || [],
    //       ];
    //       // Convert to worksheet
    //       const ws = XLSX.utils.json_to_sheet(combinedData);
    //       // Create a new workbook and append the worksheet
    //       const wb = XLSX.utils.book_new();
    //       XLSX.utils.book_append_sheet(wb, ws, "Combined Data");
    //       // Generate Excel file and trigger download
    //       const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    //       const file = new Blob([excelBuffer], {
    //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //       });
    //       saveAs(file, "combined_data.xlsx");
  };

  return (
    <div className="flex justify-between bg-white shadow-lg px-6 py-2.5 mt-2 rounded-sm">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFilterSubmit}
        onReset={handleReset}
        enableReinitialize
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form className="flex gap-4">
            <div className="text-[#64709B] text-sm my-auto whitespace-nowrap">
              Filter By:
            </div>
            <div className="bg-white flex gap-2 border border-[#D9D9D9] px-2.5 py-1 rounded max-h-[30px]">
              <div>
                <label
                htmlFor="truck"
                className="text-[#64709B] text-sm my-auto whitespace-nowrap"
                >
                All Truck
              </label>
              <Field
                name="allTruck"
                type="checkbox"
                className="invisible"
                id="truck"
                />
                </div>
              <label
                htmlFor="truck"
                className="border-2 border-[#303750] rounded-sm h-4 w-4 my-auto"
              >
                {values.allTruck ? (
                  <FaCheck className="text-[10px] m-auto mt-0.5 text-[#1890FF]" />
                ) : (
                  ""
                )}
              </label>
            </div>
            {!values.allTruck && (
              <Field
                as="select"
                name="truckId"
                className="bg-white flex gap-2 border border-[#D9D9D9] px-2.5 py-1 rounded text-sm min-h-[30px] h-[30px] w-fit"
                disabled={values.allTruck} // Disable truck selection if "allTruck" is checked
              >
                <option value="">Truck Number</option>
                {truck?.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.truckNumber}
                  </option>
                ))}
              </Field>
            )}
            {/* Display validation error if truckId is required */}
            {errors.truckId && touched.truckId && (
              <div className="text-red-500 text-xs">{errors.truckId}</div>
            )}
            <div>
              <DatePicker
                name="startDate"
                id="startDate"
                className="bg-white flex gap-2 border border-[#D9D9D9] px-2.5 py-1 rounded text-sm text-center min-h-[30px] h-[30px] w-fit"
                startDate={new Date()}
                endDate={null}
                selectsStart
                placeHolder="Start Date"
              />
            </div>
            <div>
              <DatePicker
                name="endDate"
                id="endDate"
                className="bg-white flex gap-2 border border-[#D9D9D9] px-2.5 py-1 rounded text-sm text-center min-h-[30px] h-[30px] w-fit"
                startDate={new Date()}
                endDate={null}
                selectsEnd
                minDate={values.startDate}
                placeHolder="End Date"
              />
            </div>
            <button type="reset" className="ghostButton text-sm capitalize">
              Reset
            </button>
            <button
              type="submit"
              className="primaryButton px-4 py-1 text-sm capitalize"
            >
              Apply
            </button>
          </Form>
        )}
      </Formik>
      {/* <button
        className="primaryButton px-4 py-1 text-sm capitalize flex gap-2 w-fit"
        onClick={() => handleExport()}
      >
        <FiDownload className="text-lg" />
        Export Excel
      </button> */}
    </div>
  );
}

export default FilterSection;
