import { getCompanyTruckDetailsIdAction } from "../../../redux/company/truck/action";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Backdrop from "../../backdrop";
import TruckTabDetails from "./truckTabDetails";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../ui/tabs";
import { PiPaintBucketFill, PiTruck } from "react-icons/pi";
import { FaRoute } from "react-icons/fa";
import { BsFuelPumpFill } from "react-icons/bs";
import { TbCheckupList } from "react-icons/tb";
import { CiReceipt } from "react-icons/ci";
import { getCompanyTripDetailsFilterAction } from "../../../redux/company/trip/action";
import TruckListCard from "./truckListCard";
import TripDetails from "../trip/tripList";
import FuelList from "../fuel/fuelList";
import TollTaxList from "../tollTax/tollTaxList";
import ServiceList from "./service/serviceList";
import DEFList from "./def/DEFList";

function TruckDetails() {
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const {
    getCompanyTruckDetailsId,
    getCompanyTruckDetailsIdLoading,
    getCompanyTruckDetailsIdError,
  } = useSelector((state) => state.Companytrucksection);
  const {
    getCompanyTripDetailsFilter,
    getCompanyTripDetailsFilterLoading,
    getCompanyTripDetailsFilterError,
  } = useSelector((state) => state.CompanyTripsection);
  const { getDEFFilter, getDEFFilterLoading, getDEFFilterError } = useSelector(
    (state) => state.DEFSection
  );
  const {
    getCompanyFuelFilter,
    getCompanyFuelFilterLoading,
    getCompanyFuelFilterError,
  } = useSelector((state) => state.Fuelsection);
  const {
    getCompanyTollTaxFilter,
    getCompanyTollTaxFilterLoading,
    getCompanyTollTaxFilterError,
  } = useSelector((state) => state.TollTaxsection);
  const {
    getCompanyServiceFilter,
    getCompanyServiceFilterLoading,
    getCompanyServiceFilterError,
  } = useSelector((state) => state.Servicesection);

  const [data, setData] = useState([]);
  const [tripData, setTripData] = useState([]);
  const [fuelData, setFuelData] = useState([]);
  const [tollTaxData, setTollTaxData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [extraExpense, setExtraExpense] = useState([]);
  const [dropdown, setDropdown] = useState("Truck");

  useEffect(() => {
    const edit = searchParams.get("truckId") === null;
    const id = searchParams.get("truckId");
    if (!edit) {
      dispatch(getCompanyTruckDetailsIdAction(id));
      const payload = {
        truckId: id,
      };
      // dispatch(getCompanyTripDetailsFilterAction(payload));
    }
  }, [dispatch]);
  useEffect(() => {
    if (getCompanyTruckDetailsId?.status === 200) {
      setData(getCompanyTruckDetailsId.truck);
    }
  }, [getCompanyTruckDetailsId]);
  useEffect(() => {
    if (getCompanyTruckDetailsIdError?.status === 404) {
      toast.error(getCompanyTruckDetailsIdError.message, {
        id: "truck-toast",
      });
    } else if (getCompanyTruckDetailsIdError?.status === 400) {
      toast.error(getCompanyTruckDetailsIdError.message, {
        id: "truck-toast",
      });
    } else if (getCompanyTruckDetailsIdError?.status === 500) {
      toast.error(getCompanyTruckDetailsIdError.error, {
        id: "truck-toast",
      });
    }
  }, [getCompanyTruckDetailsIdError]);
  useEffect(() => {
    if (getCompanyTripDetailsFilter?.status === 200) {
      setTripData(getCompanyTripDetailsFilter);
    }
  }, [getCompanyTripDetailsFilter]);
  useEffect(() => {
    if (getCompanyTripDetailsFilterError?.status === 404) {
      toast.error(getCompanyTripDetailsFilterError.message, {
        id: "truck-toast",
      });
    } else if (getCompanyTripDetailsFilterError?.status === 400) {
      toast.error(getCompanyTripDetailsFilterError.message, {
        id: "truck-toast",
      });
    } else if (getCompanyTripDetailsFilterError?.status === 500) {
      toast.error(getCompanyTripDetailsFilterError.error, {
        id: "truck-toast",
      });
    }
  }, [getCompanyTripDetailsFilterError]);
  useEffect(() => {
    if (getCompanyFuelFilter?.status === 200) {
      setFuelData(getCompanyFuelFilter.data);
    }
  }, [getCompanyFuelFilter]);
  useEffect(() => {
    if (getCompanyFuelFilterError?.status === 404) {
      toast.error(getCompanyFuelFilterError.message, {
        id: "fuel-toast",
      });
    } else if (getCompanyFuelFilterError?.status === 400) {
      toast.error(getCompanyFuelFilterError.message, {
        id: "fuel-toast",
      });
    } else if (getCompanyFuelFilterError?.status === 500) {
      toast.error(getCompanyFuelFilterError.error, {
        id: "fuel-toast",
      });
    }
  }, [getCompanyFuelFilterError]);
  useEffect(() => {
    if (getCompanyTollTaxFilter?.status === 200) {
      setTollTaxData(getCompanyTollTaxFilter.data);
    }
  }, [getCompanyTollTaxFilter]);
  useEffect(() => {
    if (getCompanyTollTaxFilterError?.status === 404) {
      toast.error(getCompanyTollTaxFilterError.message, {
        id: "toll-toast",
      });
    } else if (getCompanyTollTaxFilterError?.status === 400) {
      toast.error(getCompanyTollTaxFilterError.message, {
        id: "toll-toast",
      });
    } else if (getCompanyTollTaxFilterError?.status === 500) {
      toast.error(getCompanyTollTaxFilterError.error, {
        id: "toll-toast",
      });
    }
  }, [getCompanyTollTaxFilterError]);
  useEffect(() => {
    if (getCompanyServiceFilter?.status === 200) {
      setServiceData(getCompanyServiceFilter.data);
    }
  }, [getCompanyServiceFilter]);
  useEffect(() => {
    if (getCompanyServiceFilterError?.status === 404) {
      toast.error(getCompanyServiceFilterError.message, {
        id: "service-toast",
      });
    } else if (getCompanyServiceFilterError?.status === 400) {
      toast.error(getCompanyServiceFilterError.message, {
        id: "service-toast",
      });
    } else if (getCompanyServiceFilterError?.status === 500) {
      toast.error(getCompanyServiceFilterError.error, {
        id: "service-toast",
      });
    }
  }, [getCompanyServiceFilterError]);
  useEffect(() => {
    if (getDEFFilter?.status === 200) {
      setExtraExpense(getDEFFilter.data);
    }
  }, [getDEFFilter]);
  useEffect(() => {
    if (getDEFFilterError?.status === 404) {
      toast.error(getDEFFilterError.message, {
        id: "service-toast",
      });
    } else if (getDEFFilterError?.status === 400) {
      toast.error(getDEFFilterError.message, {
        id: "service-toast",
      });
    } else if (getDEFFilterError?.status === 500) {
      toast.error(getDEFFilterError.error, {
        id: "service-toast",
      });
    }
  }, [getDEFFilterError]);
  const handleDropdownChange = (event) => {
    handleTab(event.target.value);
  };
  const handleTab = (value) => {
    setDropdown(value);
    searchParams.set("tab", value);
    setSearchParams(searchParams);
  };
  const dropdownOptions = [
    { value: "Truck", label: "Truck" },
    { value: "Trips", label: "Trips" },
    { value: "Service", label: "Service" },
    { value: "Fuel", label: "Fuel" },
    { value: "Toll", label: "Toll" },
    { value: "DEF", label: "DEF" },
  ].filter(Boolean); // Filter out any false values
  return (
    <div>
      {(getCompanyTruckDetailsIdLoading ||
        getCompanyTripDetailsFilterLoading ||
        getDEFFilterLoading ||
        getCompanyFuelFilterLoading ||
        getCompanyTollTaxFilterLoading ||
        getCompanyServiceFilterLoading) && <Backdrop />}
      <TruckListCard data={data} />
      <div className="block lg:hidden ">
        <select
          onChange={handleDropdownChange}
          value={searchParams.get("tab") || "All"}
          className="secondaryButton w-full mt-4 "
        >
          {dropdownOptions.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <Tabs
        defaultValue={searchParams.get("tab") || dropdown}
        value={searchParams.get("tab") || dropdown}
        className="mt-4"
      >
        <TabsList className="hidden w-fit lg:block">
          <TabsTrigger onClick={() => handleTab("Truck")} value="Truck">
            <PiTruck className="lg:mr-1 mr-0.5 lg:text-xl " /> Truck
          </TabsTrigger>
          <TabsTrigger onClick={() => handleTab("Trips")} value="Trips">
            <FaRoute className="lg:mr-1 mr-0.5 lg:text-xl " /> Trips
          </TabsTrigger>
          <TabsTrigger onClick={() => handleTab("Service")} value="Service">
            <TbCheckupList className="lg:mr-1 mr-0.5 lg:text-xl " /> Service
          </TabsTrigger>
          <TabsTrigger onClick={() => handleTab("Fuel")} value="Fuel">
            <BsFuelPumpFill className="lg:mr-1 mr-0.5 lg:text-xl " /> Fuel
          </TabsTrigger>
          <TabsTrigger onClick={() => handleTab("Toll")} value="Toll">
            <CiReceipt className="lg:mr-1 mr-0.5 lg:text-xl " /> Toll Tax
          </TabsTrigger>
          <TabsTrigger onClick={() => handleTab("DEF")} value="DEF">
            <PiPaintBucketFill className="lg:mr-1 mr-0.5 lg:text-xl " /> DEF
          </TabsTrigger>
        </TabsList>
        <TabsContent value="Truck" className="w-full">
          <TruckTabDetails data={data} />
        </TabsContent>
        <TabsContent value="Trips">
          <TripDetails data={tripData} />
        </TabsContent>
        <TabsContent value="Fuel">
          <FuelList data={fuelData} isSell={data?.isSell} />
        </TabsContent>
        <TabsContent value="Service">
          <ServiceList data={serviceData} isSell={data?.isSell} />
        </TabsContent>
        <TabsContent value="Toll">
          <TollTaxList data={tollTaxData} isSell={data?.isSell} />
        </TabsContent>
        <TabsContent value="DEF">
          <DEFList data={extraExpense} isSell={data?.isSell} />
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default TruckDetails;
