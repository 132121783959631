import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import api from "../../../auth/api";
import {
    getCompanyDashboardEmployeeGraphError,
    getCompanyDashboardEmployeeGraphSuccess,
    getCompanyDashboardExpenseGraphError,
    getCompanyDashboardExpenseGraphSuccess,
    getCompanyDashboardExpensePiechartError,
    getCompanyDashboardExpensePiechartSuccess,
    getCompanyDashboardTripGraphError,
    getCompanyDashboardTripGraphSuccess,
    getCompanyDashboardTripListError,
    getCompanyDashboardTripListSuccess,
    getCompanyDashboardTripPendingAmountError,
    getCompanyDashboardTripPendingAmountSuccess,
    getCompanyDashboardTripPiechartError,
  getCompanyDashboardTripPiechartSuccess,
  getCompanyDashboardTruckGraphError,
  getCompanyDashboardTruckGraphSuccess,
  getCompanyDashboardTruckPiechartError,
  getCompanyDashboardTruckPiechartSuccess,
} from "./action";
import {
    GET_COMPANY_DASHBOARD_EMPLOYEE_GRAPH,
    GET_COMPANY_DASHBOARD_EXPENSE_GRAPH,
    GET_COMPANY_DASHBOARD_EXPENSE_PIECHART,
    GET_COMPANY_DASHBOARD_TRIP_GRAPH,
    GET_COMPANY_DASHBOARD_TRIP_LIST,
    GET_COMPANY_DASHBOARD_TRIP_PENDING_AMOUNT,
    GET_COMPANY_DASHBOARD_TRIP_PIECHART,
  GET_COMPANY_DASHBOARD_TRUCK_GRAPH,
  GET_COMPANY_DASHBOARD_TRUCK_PIECHART,
} from "../../actions";

// Async function to handle API call
const getCompanyDashboardTruckGraphAsync = async (payload) => {
  try {
    const response = await api.get(`/company/dashboard/truck/graph?${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyDashboardTruckGraphCall(action) {
  try {
    const data = yield call(getCompanyDashboardTruckGraphAsync, action.payload); // Call async function with action payload
    yield put(getCompanyDashboardTruckGraphSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyDashboardTruckGraphError(error)); // Dispatch error action with error object
  }
}
export function* watchGetCompanyDashboardTruckGraphData() {
  yield takeEvery(
    GET_COMPANY_DASHBOARD_TRUCK_GRAPH,
    getCompanyDashboardTruckGraphCall
  );
}

// Async function to handle API call
const getCompanyDashboardTruckPiechartAsync = async (payload) => {
  try {
    const response = await api.get(
      `/company/dashboard/truck/piechart?${payload}`
    );
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyDashboardTruckPiechartCall(action) {
  try {
    const data = yield call(
      getCompanyDashboardTruckPiechartAsync,
      action.payload
    ); // Call async function with action payload
    yield put(getCompanyDashboardTruckPiechartSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyDashboardTruckPiechartError(error)); // Dispatch error action with error object
  }
}
export function* watchGetCompanyDashboardTruckPiechartData() {
  yield takeEvery(
    GET_COMPANY_DASHBOARD_TRUCK_PIECHART,
    getCompanyDashboardTruckPiechartCall
  );
}

// Async function to handle API call
const getCompanyDashboardTripGraphAsync = async (payload) => {
    try {
      const response = await api.get(`/company/dashboard/trip/graph?${payload}`);
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* getCompanyDashboardTripGraphCall(action) {
    try {
      const data = yield call(getCompanyDashboardTripGraphAsync, action.payload); // Call async function with action payload
      yield put(getCompanyDashboardTripGraphSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(getCompanyDashboardTripGraphError(error)); // Dispatch error action with error object
    }
  }
  export function* watchGetCompanyDashboardTripGraphData() {
    yield takeEvery(
      GET_COMPANY_DASHBOARD_TRIP_GRAPH,
      getCompanyDashboardTripGraphCall
    );
  }

  // Async function to handle API call
const getCompanyDashboardTripListAsync = async (payload) => {
    try {
      const response = await api.get(`/company/dashboard/trip/list?${payload}`);
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* getCompanyDashboardTripListCall(action) {
    try {
      const data = yield call(getCompanyDashboardTripListAsync, action.payload); // Call async function with action payload
      yield put(getCompanyDashboardTripListSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(getCompanyDashboardTripListError(error)); // Dispatch error action with error object
    }
  }
  export function* watchGetCompanyDashboardTripListData() {
    yield takeEvery(
      GET_COMPANY_DASHBOARD_TRIP_LIST,
      getCompanyDashboardTripListCall
    );
  }

// Async function to handle API call
const getCompanyDashboardTripPiechartAsync = async (payload) => {
    try {
      const response = await api.get(`/company/dashboard/trip/piechart?${payload}`);
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* getCompanyDashboardTripPiechartCall(action) {
    try {
      const data = yield call(getCompanyDashboardTripPiechartAsync, action.payload); // Call async function with action payload
      yield put(getCompanyDashboardTripPiechartSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(getCompanyDashboardTripPiechartError(error)); // Dispatch error action with error object
    }
  }
  export function* watchGetCompanyDashboardTripPiechartData() {
    yield takeEvery(
      GET_COMPANY_DASHBOARD_TRIP_PIECHART,
      getCompanyDashboardTripPiechartCall
    );
  }
  
// Async function to handle API call
const getCompanyDashboardTripPendingAmountAsync = async (payload) => {
    try {
      const response = await api.get(`/company/dashboard/trip/pending-amount?${payload}`);
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* getCompanyDashboardTripPendingAmountCall(action) {
    try {
      const data = yield call(getCompanyDashboardTripPendingAmountAsync, action.payload); // Call async function with action payload
      yield put(getCompanyDashboardTripPendingAmountSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(getCompanyDashboardTripPendingAmountError(error)); // Dispatch error action with error object
    }
  }
  export function* watchGetCompanyDashboardTripPendingAmountData() {
    yield takeEvery(
      GET_COMPANY_DASHBOARD_TRIP_PENDING_AMOUNT,
      getCompanyDashboardTripPendingAmountCall
    );
  }

  // Async function to handle API call
const getCompanyDashboardEmployeeGraphAsync = async (payload) => {
    try {
      const response = await api.get(`/company/dashboard/employee/graph?${payload}`);
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* getCompanyDashboardEmployeeGraphCall(action) {
    try {
      const data = yield call(getCompanyDashboardEmployeeGraphAsync, action.payload); // Call async function with action payload
      yield put(getCompanyDashboardEmployeeGraphSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(getCompanyDashboardEmployeeGraphError(error)); // Dispatch error action with error object
    }
  }
  export function* watchGetCompanyDashboardEmployeeGraphData() {
    yield takeEvery(
      GET_COMPANY_DASHBOARD_EMPLOYEE_GRAPH,
      getCompanyDashboardEmployeeGraphCall
    );
  }

  // Async function to handle API call
const getCompanyDashboardExpenseGraphAsync = async (payload) => {
    try {
      const response = await api.get(`/company/dashboard/expense/graph?${payload}`);
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* getCompanyDashboardExpenseGraphCall(action) {
    try {
      const data = yield call(getCompanyDashboardExpenseGraphAsync, action.payload); // Call async function with action payload
      yield put(getCompanyDashboardExpenseGraphSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(getCompanyDashboardExpenseGraphError(error)); // Dispatch error action with error object
    }
  }
  export function* watchGetCompanyDashboardExpenseGraphData() {
    yield takeEvery(
      GET_COMPANY_DASHBOARD_EXPENSE_GRAPH,
      getCompanyDashboardExpenseGraphCall
    );
  }

  // Async function to handle API call
const getCompanyDashboardExpensePiechartAsync = async (payload) => {
    try {
      const response = await api.get(`/company/dashboard/expense/piechart?${payload}`);
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* getCompanyDashboardExpensePiechartCall(action) {
    try {
      const data = yield call(getCompanyDashboardExpensePiechartAsync, action.payload); // Call async function with action payload
      yield put(getCompanyDashboardExpensePiechartSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(getCompanyDashboardExpensePiechartError(error)); // Dispatch error action with error object
    }
  }
  export function* watchGetCompanyDashboardExpensePiechartData() {
    yield takeEvery(
      GET_COMPANY_DASHBOARD_EXPENSE_PIECHART,
      getCompanyDashboardExpensePiechartCall
    );
  }
  
  
export default function* rootSaga() {
  yield all([
    fork(watchGetCompanyDashboardTruckGraphData),
    fork(watchGetCompanyDashboardTruckPiechartData),
    fork(watchGetCompanyDashboardTripPiechartData),
    fork(watchGetCompanyDashboardTripListData),
    fork(watchGetCompanyDashboardTripGraphData),
    fork(watchGetCompanyDashboardTripPendingAmountData),
    fork(watchGetCompanyDashboardEmployeeGraphData),
    fork(watchGetCompanyDashboardExpenseGraphData),
    fork(watchGetCompanyDashboardExpensePiechartData),

  ]);
}
