// actions.js
import {
    GET_EXPENSE_TRIP,
    GET_EXPENSE_TRIP_SUCCESS,
    GET_EXPENSE_TRIP_ERROR,
    GET_EXPENSE_TRIP_BY_TRIP,
    GET_EXPENSE_TRIP_BY_TRIP_SUCCESS,
    GET_EXPENSE_TRIP_BY_TRIP_ERROR,
    GET_EXPENSE_TRIP_BY_ID,
    GET_EXPENSE_TRIP_BY_ID_SUCCESS,
    GET_EXPENSE_TRIP_BY_ID_ERROR,
    POST_EXPENSE_TRIP,
    POST_EXPENSE_TRIP_SUCCESS,
    POST_EXPENSE_TRIP_ERROR,
    PATCH_EXPENSE_TRIP,
    PATCH_EXPENSE_TRIP_SUCCESS,
    PATCH_EXPENSE_TRIP_ERROR,
    DELETE_EXPENSE_TRIP,
    DELETE_EXPENSE_TRIP_SUCCESS,
    DELETE_EXPENSE_TRIP_ERROR,
    GET_EXPENSE_TRIP_FILTER,
    GET_EXPENSE_TRIP_FILTER_SUCCESS,
    GET_EXPENSE_TRIP_FILTER_ERROR,
  } from "../../../actions";
  
  export const getExpenseTripAction = () => ({
    type: GET_EXPENSE_TRIP,
  });
  
  export const getExpenseTripSuccess = (data) => ({
    type: GET_EXPENSE_TRIP_SUCCESS,
    payload: data,
  });
  
  export const getExpenseTripError = (error) => ({
    type: GET_EXPENSE_TRIP_ERROR,
    payload: error,
  });
  
  export const getExpenseTripByTripAction = (tripId) => ({
    type: GET_EXPENSE_TRIP_BY_TRIP,
    payload: tripId,
  });
  
  export const getExpenseTripByTripSuccess = (data) => ({
    type: GET_EXPENSE_TRIP_BY_TRIP_SUCCESS,
    payload: data,
  });
  
  export const getExpenseTripByTripError = (error) => ({
    type: GET_EXPENSE_TRIP_BY_TRIP_ERROR,
    payload: error,
  });
  
  export const getExpenseTripByIdAction = (id) => ({
    type: GET_EXPENSE_TRIP_BY_ID,
    payload: id,
  });
  
  export const getExpenseTripByIdSuccess = (data) => ({
    type: GET_EXPENSE_TRIP_BY_ID_SUCCESS,
    payload: data,
  });
  
  export const getExpenseTripByIdError = (error) => ({
    type: GET_EXPENSE_TRIP_BY_ID_ERROR,
    payload: error,
  });
  
  export const postExpenseTripAction = (data) => ({
    type: POST_EXPENSE_TRIP,
    payload: data,
  });
  
  export const postExpenseTripSuccess = (data) => ({
    type: POST_EXPENSE_TRIP_SUCCESS,
    payload: data,
  });
  
  export const postExpenseTripError = (error) => ({
    type: POST_EXPENSE_TRIP_ERROR,
    payload: error,
  });
  
  export const patchExpenseTripAction = (id, data) => ({
    type: PATCH_EXPENSE_TRIP,
    payload: { id, data },
  });
  
  export const patchExpenseTripSuccess = (data) => ({
    type: PATCH_EXPENSE_TRIP_SUCCESS,
    payload: data,
  });
  
  export const patchExpenseTripError = (error) => ({
    type: PATCH_EXPENSE_TRIP_ERROR,
    payload: error,
  });
  
  export const deleteExpenseTripAction = (id) => ({
    type: DELETE_EXPENSE_TRIP,
    payload: id,
  });
  
  export const deleteExpenseTripSuccess = (data) => ({
    type: DELETE_EXPENSE_TRIP_SUCCESS,
    payload: data,
  });
  
  export const deleteExpenseTripError = (error) => ({
    type: DELETE_EXPENSE_TRIP_ERROR,
    payload: error,
  });
  
  export const getExtraExpenseTripFilterAction = (filterData) => ({
    type: GET_EXPENSE_TRIP_FILTER,
    payload: filterData,
  });
  
  export const getExtraExpenseTripFilterSuccess = (data) => ({
    type: GET_EXPENSE_TRIP_FILTER_SUCCESS,
    payload: data,
  });
  
  export const getExtraExpenseTripFilterError = (error) => ({
    type: GET_EXPENSE_TRIP_FILTER_ERROR,
    payload: error,
  });
  