import { getAdminEmployeeByCompanyEmployeeIdAction } from '../../../../redux/admin/employee/action'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import EmployeeListCard from './employeeListCard'
import toast from 'react-hot-toast'
import PersonalDetails from './employeePersonalDetails'
import FamilyDetails from '../../employee/familyDetails'

function EmployeeDetailsSection() {
    const dispatch = useDispatch()
    const {companyEmployee} = useParams()
    const {
        getAdminEmployeeByCompanyEmployeeId,
        getAdminEmployeeByCompanyEmployeeIdLoading,
        getAdminEmployeeByCompanyEmployeeIdError,
      } = useSelector((state) => state.AdminEmployeeSection);
    const [data, setData] = useState({})
    useEffect(() => {
        dispatch(getAdminEmployeeByCompanyEmployeeIdAction(companyEmployee))
    }, [])

    useEffect(() => {
        if (getAdminEmployeeByCompanyEmployeeId?.status === 200) {
          setData(getAdminEmployeeByCompanyEmployeeId?.data);
        }
      }, [getAdminEmployeeByCompanyEmployeeId]);
      useEffect(() => {
        if (getAdminEmployeeByCompanyEmployeeIdError?.status === 400) {
          toast.error(getAdminEmployeeByCompanyEmployeeIdError?.message);
        } else if (getAdminEmployeeByCompanyEmployeeIdError?.status === 404) {
          toast.error(getAdminEmployeeByCompanyEmployeeIdError?.message);
        } else if (getAdminEmployeeByCompanyEmployeeIdError?.status === 500) {
          toast.error(getAdminEmployeeByCompanyEmployeeIdError?.error);
        }
      }, [getAdminEmployeeByCompanyEmployeeIdError]);
    
  return (
    <div>
        <EmployeeListCard data={data.employees} />
        <div className="flex flex-col gap-10">
        <PersonalDetails data={data.employees} />
        <FamilyDetails data={data?.family} />
      </div>
    </div>
  )
}

export default EmployeeDetailsSection
