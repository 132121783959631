import ImagePopUpDownload from "../../../components/imagePopUpDownload";
import { Dialog } from "../../../ui/dialog";
import React, { useState } from "react";
import Image from "../../../assets/images/user.png";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Terminate from "./terminate";

function EmployeeListCard({ data, reroute = false }) {
  const navigate = useNavigate();
  const [showImageModal, setShowImageModal] = useState(false);
  const [showTerminateModal, setShowTerminateModal] = useState(false);

  return (
    <div key={data?._id}>
      <div className="grid grid-cols-12 gap-x-7 my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl">
        <div className="lg:col-span-2 col-span-6 row-span-1 justify-self-center my-auto lg:row-span-3">
          <button
            type="button"
            className="rounded-full "
            onClick={() => reroute && navigate(`details/${data?._id}`)}
          >
            <img
              src={data?.employeeImage || Image}
              alt=""
              className={`${
                data?.employeeImage ? "rounded-full" : ""
              } h-[100px] w-[100px]`}
            />
          </button>
        </div>
        {data?.employeeImage && (
          <Dialog open={showImageModal}>
            <ImagePopUpDownload
              image={data?.employeeImage}
              setShowModal={setShowImageModal}
              imagename={`employee_${data?._id}`}
            />
          </Dialog>
        )}
        <div className="mb-5 text-center md:text-left lg:col-span-10 col-span-6 row-span-1 self-center lg:self-auto">
          <button
            type="button"
            className="font-bold text-blue-600 text-xl"
            onClick={() => reroute && navigate(`details/${data?._id}`)}
          >
            {data?.firstName} {data?.lastName}
          </button>
        </div>
        <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center">
          <div className="md:text-left">
            <h3 className="textgrey text-xs">Email</h3>
            <Link to={`mailto:${data?.contactEmail}`}>{data?.contactEmail}</Link>
          </div>
          <div className="md:text-left">
            <h3 className="textgrey text-xs">Phone Number</h3>
            <p>{data?.phoneNumber}</p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-center">Blood Group</h3>
            <p className="md:text-center">{data?.bloodGroup}</p>
          </div>
          

          <div className="md:text-right">
            <h3 className="textgrey text-xs">Working Currently</h3>
            <p>{data?.isWorking ? "YES" : "No"}</p>
          </div>

          {data?.isWorking ? (
            <>
              <Dialog open={showTerminateModal}>
                <button
                  onClick={() => setShowTerminateModal(true)}
                  className="primaryButton bg-red-600 hover:bg-red-800"
                >
                  Terminate Employee
                </button>
                <Terminate
                  employeeId={data?._id}
                  setShowModal={setShowTerminateModal}
                />
              </Dialog>
            </>
          ) : (
            <>
              <Link
                to={`/admin/employee/addToCompany/${data?._id}`}
                className="primaryButton text-center"
              >
                Add To Company
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmployeeListCard;
