import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../ui/dialog";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import DatePicker from "../../../datepicker/datePicker";
import * as Yup from "yup";
import { Switch } from "../../../../ui/switch";
import { useDispatch, useSelector } from "react-redux";
import { getCompanySearchAction } from "../../../../redux/company/action";
import toast from "react-hot-toast";
import {
  getAdminTruckbyCompanyIdAction,
  postAdminTruckSellAction,
} from "../../../../redux/admin/truck/action";
import { useParams } from "react-router-dom";

function TruckSell({ truckId, setshowModal }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { getCompanySearch, getCompanySearchLoading, getCompanySearchError } =
    useSelector((state) => state.Companysection);
  const {
    postAdminTruckSell,
    postAdminTruckSellLoading,
    postAdminTruckSellError,
  } = useSelector((state) => state.AdminTruckSection);

  const [loading, setLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [companyError, setCompanyError] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const validationSchema = Yup.object({
    sellDate: Yup.date()
      .required("Sell date is required")
      .max(new Date(), "Sell date cannot be in the future"),
    isCompany: Yup.boolean(),
    soldToCompanyId: Yup.string().when("isCompany", {
      is: true,
      then: () =>
        Yup.string()
          .length(24, "Company ID must be exactly 24 characters")
          .required("Company ID is required"),
    }),
  });
  useEffect(() => {
    if (loading && !getCompanySearchLoading) {
      if (getCompanySearch.status === 200) {
        setLoading(false);
        setCompanyName(getCompanySearch?.data?.companyName);
      }
    }
  }, [loading, getCompanySearch]);
  useEffect(() => {
    if (loading && !getCompanySearchLoading) {
      if (getCompanySearchError.status === 400) {
        setLoading(false);
        setCompanyError(getCompanySearchError?.message);
      } else if (getCompanySearchError.status === 404) {
        setLoading(false);
        setCompanyError(getCompanySearchError?.message);
      } else if (getCompanySearchError.status === 500) {
        setLoading(false);
        setCompanyError("Company Not Found");
      }
    }
  }, [loading, getCompanySearchError]);

  useEffect(() => {
    if (postLoading) {
      if (postAdminTruckSell.status === 200) {
        toast.success(postAdminTruckSell.message, { id: "sellingToast" });
        dispatch(getAdminTruckbyCompanyIdAction(id));
        setPostLoading(false);
        setshowModal(false);
      }
    }
  }, [postAdminTruckSell]);
  useEffect(() => {
    if (postLoading) {
      if (postAdminTruckSellError.status === 400) {
        setPostLoading(false);
        toast.error(postAdminTruckSellError.message, { id: "sellingToast" });
      } else if (postAdminTruckSellError.status === 404) {
        setPostLoading(false);
        toast.error(postAdminTruckSellError.message, { id: "sellingToast" });
      } else if (postAdminTruckSellError.status === 500) {
        setPostLoading(false);
        toast.error(postAdminTruckSellError.error, { id: "sellingToast" });
      }
    }
  }, [postAdminTruckSellError]);

  const handleCompany = (value) => {
    if (value?.length === 24) {
      dispatch(getCompanySearchAction(value));
      setLoading(true);
    } else {
      setCompanyError(null);
      setCompanyName(null);
    }
  };

  const onSubmit = (values) => {
    const payload = {
      id: truckId,
      body: values,
    };
    dispatch(postAdminTruckSellAction(payload));
    setPostLoading(true);
    toast.loading("Selling Data Loading...", { id: "sellingToast" });
  };
  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle>Sell Truck Details</DialogTitle>
      </DialogHeader>
      <div className="text-xl mt-5 font-semibold">
        Are you sure you want to{" "}
        <span className="text-red-500 font-bold">Delete</span> this Service?
      </div>
      <Formik
        initialValues={{
          sellDate: "",
          isCompany: false,
          soldToCompanyId: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, errors }) => (
          <Form className="">
            <div className="mb-4">
              <div>
                <label htmlFor="sellDate">Truck Sell Date</label>
                <div className="w-full">
                  <DatePicker
                    id="sellDate"
                    maxDate={new Date()}
                    name="sellDate"
                    errorName={errors.sellDate}
                    className=""
                    placeHolder="Truck Sell Date"
                  />
                </div>
              </div>
              <div className="mt-auto">
                <label htmlFor="isCompany">
                  Purchase Company Is on TruckiIng
                </label>
                <div className="my-auto flex items-center gap-4 font-bold h-10">
                  <Switch
                    checked={values.isCompany}
                    id="isCompany"
                    onCheckedChange={(value) =>
                      setFieldValue("isCompany", value)
                    }
                  />
                  {values.isCompany ? "Yes" : "No"}
                </div>
              </div>
              {values.isCompany && (
                <div>
                  <label htmlFor="soldToCompanyId">
                    Purchase Company ID (Find Id in Company Details)
                    <span>*</span>
                  </label>
                  <Field
                    type="text"
                    id="soldToCompanyId"
                    name="soldToCompanyId"
                    maxlength={24}
                    className=""
                    onChange={(e) => {
                      setFieldValue("soldToCompanyId", e.target.value);
                      handleCompany(e.target.value);
                    }}
                    placeholder="Company ID"
                  />
                  <ErrorMessage
                    name="soldToCompanyId"
                    component="div"
                    className="error"
                  />
                  {companyError && <div className="error">{companyError}</div>}
                  {companyName && (
                    <div className="flex text-sm gap-1">
                      Company Name is
                      <div className="text-green-600 text-sm uppercase font-bold">
                        {" "}
                        {companyName}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <DialogFooter>
              <button
                className="secondaryButton w-fit"
                onClick={() => setshowModal(false)}
                disabled={postAdminTruckSellLoading || getCompanySearchLoading}
                type="button"
              >
                Close
              </button>
              <button
                className="primaryButton bg-red-600 hover:bg-red-800 disabled:bg-red-300 w-fit"
                type="submit"
                disabled={
                  values.isCompany
                    ? companyError !== null ||
                      values.soldToCompanyId.length < 24 ||
                      getCompanySearchLoading
                    : postAdminTruckSellLoading
                }
              >
                Sell
              </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default TruckSell;
