import { getCompanyTollTaxFilterAction } from "../../../redux/company/tollTax/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { FaFilter } from "react-icons/fa";
import { Field, Form, Formik } from "formik";
import { getCompanyTruckDetailsAction } from "../../../redux/company/truck/action";
import { getCompanyTripDetailsFilterAction } from "../../../redux/company/trip/action";
import TollTaxListCard from "./tollTaxListCard";
import TollFilter from "./tollFilter";
import { Dialog } from "../../../ui/dialog";

function TollTaxList({ data, isSell }) {
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [truckParams, setTruckParams] = useState(null);
  const [tripParams, setTripParams] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  useEffect(() => {
      if (!(data?.length > 0)) {
          handleClearFilters();
        }
    }, [dispatch]);
    useEffect(() => {
        dispatch(getCompanyTruckDetailsAction())
    }, []);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleFilterSubmit = (values) => {
    const payload = {
      ...Object.fromEntries(
        Object.entries(values).filter(([_, v]) => v !== "")
      ), // Only send filled filters
    };
    dispatch(getCompanyTollTaxFilterAction(payload));
    setShowFilters(false)

  };
  const handleClearFilters = () => {
    const TruckId = searchParams.get("truckId");
    const TripId = searchParams.get("tripId");
    setTruckParams(TruckId);
    setTripParams(TripId)
    const payload = {
      truckId: TruckId,
      tripId: TripId,
    };
    dispatch(getCompanyTollTaxFilterAction(payload));
    setShowFilters(false)

  };

  const filteredData = data?.tollTax?.filter(
    (item) =>
      item.cost.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.billNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.tollName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div>
      <div className="flex justify-end">
        <Link
          to={`/company/tollTax/addTollTax?${
            searchParams.get("tripId")
              ? `tripId=${searchParams.get("tripId")}`
              : `truckId=${searchParams.get("truckId")}`
          }`}
          className="primaryButton md:w-fit ml-auto text-sm mb-2"
        >
          Add Toll Tax
        </Link>
      </div>
      <div className="mb-4 gap-8 flex justify-between items-center">
        <input
          placeholder="Search"
          value={searchQuery}
          onChange={handleSearch}
          className="max-w-sm shadow-md mb-2"
        />
        <button onClick={() => setShowFilters(!showFilters)} className="flex gap-1 items-center">
        <FaFilter className="text-gray-600" /> Filter
        </button>
      </div>
      {showFilters && (
        <Formik
          initialValues={{
            truckId: truckParams || "",
            tripId: tripParams || "",
            startDate: "",
          }}
          onSubmit={handleFilterSubmit}
        >
          {({ values, setFieldValue, resetForm, handleSubmit }) => (
            <Form>
              <Dialog open={showFilters}>
                <TollFilter
                  handleSubmit={handleSubmit}
                  handleClearFilters={handleClearFilters}
                  values={values}
                  setShowFilters={setShowFilters}
                  resetForm={resetForm}
                />
              </Dialog>
            </Form>
          )}
        </Formik>
      )}
      <div className="grid grid-cols-2">
      <div className="md:text-left">
            <h3 className="textgrey">Total Toll Tax</h3>
            <p className="font-bold">{data?.tollTax?.length}</p>
          </div>
      <div className="md:text-right">
            <h3 className="textgrey">Total Amount</h3>
            <p className="font-bold">Rs.{data?.totalAmount}</p>
          </div>
      </div>
      {filteredData?.length > 0 ? (
        filteredData?.map((item) => (
          <TollTaxListCard key={item._id} data={item} reroute={true} />
        ))
      ) : (
        <p>No TollTax found.</p>
      )}
    </div>
  );
}

export default TollTaxList;
