import {
  GET_COMPANY_DETAILS,
  GET_COMPANY_DETAILS_ERROR,
  GET_COMPANY_DETAILS_SUCCESS,
  GET_COMPANY_SEARCH,
  GET_COMPANY_SEARCH_ERROR,
  GET_COMPANY_SEARCH_SUCCESS,
  PATCH_COMPANY_DETAILS,
  PATCH_COMPANY_DETAILS_ERROR,
  PATCH_COMPANY_DETAILS_SUCCESS,
  POST_COMPANY_DETAILS,
  POST_COMPANY_DETAILS_ERROR,
  POST_COMPANY_DETAILS_SUCCESS,
} from "../actions";

export const postCompanyDetailsAction = (info) => ({
  type: POST_COMPANY_DETAILS,
  payload: info,
});
export const postCompanyDetailsSuccess = (info) => ({
  type: POST_COMPANY_DETAILS_SUCCESS,
  payload: info,
});
export const postCompanyDetailsError = (error) => ({
  type: POST_COMPANY_DETAILS_ERROR,
  payload: error,
});
export const patchCompanyDetailsAction = (info) => ({
  type: PATCH_COMPANY_DETAILS,
  payload: info,
});
export const patchCompanyDetailsSuccess = (info) => ({
  type: PATCH_COMPANY_DETAILS_SUCCESS,
  payload: info,
});
export const patchCompanyDetailsError = (error) => ({
  type: PATCH_COMPANY_DETAILS_ERROR,
  payload: error,
});
export const getCompanyDetailsAction = (info) => ({
  type: GET_COMPANY_DETAILS,
  payload: info,
});
export const getCompanyDetailsSuccess = (info) => ({
  type: GET_COMPANY_DETAILS_SUCCESS,
  payload: info,
});
export const getCompanyDetailsError = (error) => ({
  type: GET_COMPANY_DETAILS_ERROR,
  payload: error,
});
export const getCompanySearchAction = (info) => ({
  type: GET_COMPANY_SEARCH,
  payload: info,
});
export const getCompanySearchSuccess = (info) => ({
  type: GET_COMPANY_SEARCH_SUCCESS,
  payload: info,
});
export const getCompanySearchError = (error) => ({
  type: GET_COMPANY_SEARCH_ERROR,
  payload: error,
});
