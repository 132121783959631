import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import TruckListCard from "./truckListCard";
import { getCompanyTruckNotSellDetailsAction } from "../../../redux/company/truck/action";
import { useDispatch } from "react-redux";
import Pagination from "../../pagination";

function TruckNotSellList({ data }) {
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [pageNo, setPageNo] = useState(data.currentPage || 1);
  const [perPage, setPerPage] = useState(data?.limit || 10);
  const [totalPages, setTotalPages] = useState(data?.totalPages || 0);
  const [total, setTotal] = useState(data?.totalTrucks || 0);
  const [running, setRunning] = useState("");
  useEffect(() => {
    const payload = `page=${pageNo}&limit=${perPage}&searchTerm=${searchQuery}&runningStatus=${running}`;
    dispatch(getCompanyTruckNotSellDetailsAction(payload));
  }, [pageNo, perPage, searchQuery, running]);

  useEffect(() => {
    setTotalPages(data?.totalPages)
  }, [data?.totalPages])
  useEffect(() => {
    setTotal(data?.totalTrucks)
  }, [data?.totalTrucks])
  

  // Handle search input change
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle pagination controls
  const onNext = () => {
    if (pageNo < totalPages) {
      setPageNo(pageNo + 1);
    }
  };

  const onPrevious = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };

  const handleSetPage = (page) => {
    setPageNo(page);
  };

  const handlePerPage = (value) => {
    setPerPage(parseInt(value));
    setPageNo(1); // Reset page number to 1 when changing perPage
  };


  return (
    <div>
      <div className="mb-4 flex justify-between flex-wrap">
        <input
          type="search"
          placeholder="Search by Truck Number"
          value={searchQuery}
          onChange={handleSearch}
          className="max-w-sm shadow-md "
        />
        <select
          type="search"
          placeholder="Search by Truck Number"
          value={running}
          onChange={(e)=>setRunning(e.target.value)}
          className="max-w-sm shadow-md "
        >
          <option value="">All</option>
          <option value="Available">Available</option>
          <option value="Running">Running</option>
        </select>
      </div>
      <div className="flex flex-col gap-2 ">
        {data?.trucks?.length > 0 ? (
          data?.trucks?.map((item, index) => (
            <TruckListCard data={item} reroute={true} />
          ))
        ) : (
          <p>No trucks found.</p>
        )}
      </div>
       {data?.totalPages > 1 && 
       <Pagination
       currentPage={pageNo}
        onNext={onNext}
        onPrevious={onPrevious}
        from={pageNo * perPage - perPage + 1}
        to={pageNo * perPage}
        totalItems={total}
        perPage={perPage}
        totalPages={totalPages}
        setPage={handleSetPage}
        setperPage={handlePerPage}
        />
      }
    </div>
  );
}

export default TruckNotSellList;
