import {
    GET_COMPANY_STATS,
    GET_COMPANY_STATS_SUCCESS,
    GET_COMPANY_STATS_ERROR,
    GET_TRUCK_STATS,
    GET_TRUCK_STATS_SUCCESS,
    GET_TRUCK_STATS_ERROR,
    GET_TRUCK_BY_TRUCKID_STATS,
    GET_TRUCK_BY_TRUCKID_STATS_SUCCESS,
    GET_TRUCK_BY_TRUCKID_STATS_ERROR,
    GET_TRIP_STATS,
    GET_TRIP_STATS_SUCCESS,
    GET_TRIP_STATS_ERROR,
    GET_TRIP_BY_TRIPID_STATS,
    GET_TRIP_BY_TRIPID_STATS_SUCCESS,
    GET_TRIP_BY_TRIPID_STATS_ERROR,
    GET_FUEL_STATS,
    GET_FUEL_STATS_SUCCESS,
    GET_FUEL_STATS_ERROR,
    GET_TOLL_STATS,
    GET_TOLL_STATS_SUCCESS,
    GET_TOLL_STATS_ERROR,
    GET_EXTRA_EXPENSE_STATS,
    GET_EXTRA_EXPENSE_STATS_SUCCESS,
    GET_EXTRA_EXPENSE_STATS_ERROR,
    GET_SERVICE_STATS,
    GET_SERVICE_STATS_SUCCESS,
    GET_SERVICE_STATS_ERROR,
    GET_DEF_STATS,
    GET_DEF_STATS_SUCCESS,
    GET_DEF_STATS_ERROR,
    GET_EMI_STATS,
    GET_EMI_STATS_SUCCESS,
    GET_EMI_STATS_ERROR,
    GET_SALARY_STATS,
    GET_SALARY_STATS_SUCCESS,
    GET_SALARY_STATS_ERROR,
    GET_INSURANCE_STATS,
    GET_INSURANCE_STATS_SUCCESS,
    GET_INSURANCE_STATS_ERROR,
    GET_FITNESS_STATS,
    GET_FITNESS_STATS_SUCCESS,
    GET_FITNESS_STATS_ERROR,
    GET_PERMIT_STATS,
    GET_PERMIT_STATS_SUCCESS,
    GET_PERMIT_STATS_ERROR,
  } from "../../actions";
  
  const INIT_STATE = {
    companyStats: {},
    companyStatsLoading: false,
    companyStatsError: "",
    truckStats: {},
    truckStatsLoading: false,
    truckStatsError: "",
    truckByTruckIdStats: {},
    truckByTruckIdStatsLoading: false,
    truckByTruckIdStatsError: "",
    tripStats: {},
    tripStatsLoading: false,
    tripStatsError: "",
    tripByTripIdStats: {},
    tripByTripIdStatsLoading: false,
    tripByTripIdStatsError: "",
    insuranceStats: {},
    insuranceStatsLoading: false,
    insuranceStatsError: "",
    fitnessStats: {},
    fitnessStatsLoading: false,
    fitnessStatsError: "",
    permitStats: {},
    permitStatsLoading: false,
    permitStatsError: "",
    fuelStats: {},
    fuelStatsLoading: false,
    fuelStatsError: "",
    tollStats: {},
    tollStatsLoading: false,
    tollStatsError: "",
    extraExpenseStats: {},
    extraExpenseStatsLoading: false,
    extraExpenseStatsError: "",
    serviceStats: {},
    serviceStatsLoading: false,
    serviceStatsError: "",
    defStats: {},
    defStatsLoading: false,
    defStatsError: "",
    emiStats: {},
    emiStatsLoading: false,
    emiStatsError: "",
    salaryStats: {},
    salaryStatsLoading: false,
    salaryStatsError: "",
  };
  
  const StatsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_COMPANY_STATS:
        return {
          ...state,
          companyStats: {},
          companyStatsLoading: true,
        };
      case GET_COMPANY_STATS_SUCCESS:
        return {
          ...state,
          companyStats: action.payload,
          companyStatsLoading: false,
          companyStatsError: "",
        };
      case GET_COMPANY_STATS_ERROR:
        return {
          ...state,
          companyStatsLoading: false,
          companyStatsError: action.payload,
        };
  
      case GET_TRUCK_STATS:
        return {
          ...state,
          truckStats: {},
          truckStatsLoading: true,
        };
      case GET_TRUCK_STATS_SUCCESS:
        return {
          ...state,
          truckStats: action.payload,
          truckStatsLoading: false,
          truckStatsError: "",
        };
      case GET_TRUCK_STATS_ERROR:
        return {
          ...state,
          truckStatsLoading: false,
          truckStatsError: action.payload,
        };
  
      case GET_TRUCK_BY_TRUCKID_STATS:
        return {
          ...state,
          truckByTruckIdStats: {},
          truckByTruckIdStatsLoading: true,
        };
      case GET_TRUCK_BY_TRUCKID_STATS_SUCCESS:
        return {
          ...state,
          truckByTruckIdStats: action.payload,
          truckByTruckIdStatsLoading: false,
          truckByTruckIdStatsError: "",
        };
      case GET_TRUCK_BY_TRUCKID_STATS_ERROR:
        return {
          ...state,
          truckByTruckIdStatsLoading: false,
          truckByTruckIdStatsError: action.payload,
        };
  
      case GET_TRIP_STATS:
        return {
          ...state,
          tripStats: {},
          tripStatsLoading: true,
        };
      case GET_TRIP_STATS_SUCCESS:
        return {
          ...state,
          tripStats: action.payload,
          tripStatsLoading: false,
          tripStatsError: "",
        };
      case GET_TRIP_STATS_ERROR:
        return {
          ...state,
          tripStatsLoading: false,
          tripStatsError: action.payload,
        };
  
      case GET_TRIP_BY_TRIPID_STATS:
        return {
          ...state,
          tripByTripIdStats: {},
          tripByTripIdStatsLoading: true,
        };
      case GET_TRIP_BY_TRIPID_STATS_SUCCESS:
        return {
          ...state,
          tripByTripIdStats: action.payload,
          tripByTripIdStatsLoading: false,
          tripByTripIdStatsError: "",
        };
      case GET_TRIP_BY_TRIPID_STATS_ERROR:
        return {
          ...state,
          tripByTripIdStatsLoading: false,
          tripByTripIdStatsError: action.payload,
        };
  
      case GET_INSURANCE_STATS:
        return {
          ...state,
          insuranceStats: {},
          insuranceStatsLoading: true,
        };
      case GET_INSURANCE_STATS_SUCCESS:
        return {
          ...state,
          insuranceStats: action.payload,
          insuranceStatsLoading: false,
          insuranceStatsError: "",
        };
      case GET_INSURANCE_STATS_ERROR:
        return {
          ...state,
          insuranceStatsLoading: false,
          insuranceStatsError: action.payload,
        };
      case GET_FITNESS_STATS:
        return {
          ...state,
          fitnessStats: {},
          fitnessStatsLoading: true,
        };
      case GET_FITNESS_STATS_SUCCESS:
        return {
          ...state,
          fitnessStats: action.payload,
          fitnessStatsLoading: false,
          fitnessStatsError: "",
        };
      case GET_FITNESS_STATS_ERROR:
        return {
          ...state,
          fitnessStatsLoading: false,
          fitnessStatsError: action.payload,
        };
      case GET_PERMIT_STATS:
        return {
          ...state,
          permitStats: {},
          permitStatsLoading: true,
        };
      case GET_PERMIT_STATS_SUCCESS:
        return {
          ...state,
          permitStats: action.payload,
          permitStatsLoading: false,
          permitStatsError: "",
        };
      case GET_PERMIT_STATS_ERROR:
        return {
          ...state,
          permitStatsLoading: false,
          permitStatsError: action.payload,
        };
      case GET_FUEL_STATS:
        return {
          ...state,
          fuelStats: {},
          fuelStatsLoading: true,
        };
      case GET_FUEL_STATS_SUCCESS:
        return {
          ...state,
          fuelStats: action.payload,
          fuelStatsLoading: false,
          fuelStatsError: "",
        };
      case GET_FUEL_STATS_ERROR:
        return {
          ...state,
          fuelStatsLoading: false,
          fuelStatsError: action.payload,
        };
  
      case GET_TOLL_STATS:
        return {
          ...state,
          tollStats: {},
          tollStatsLoading: true,
        };
      case GET_TOLL_STATS_SUCCESS:
        return {
          ...state,
          tollStats: action.payload,
          tollStatsLoading: false,
          tollStatsError: "",
        };
      case GET_TOLL_STATS_ERROR:
        return {
          ...state,
          tollStatsLoading: false,
          tollStatsError: action.payload,
        };
  
      case GET_EXTRA_EXPENSE_STATS:
        return {
          ...state,
          extraExpenseStats: {},
          extraExpenseStatsLoading: true,
        };
      case GET_EXTRA_EXPENSE_STATS_SUCCESS:
        return {
          ...state,
          extraExpenseStats: action.payload,
          extraExpenseStatsLoading: false,
          extraExpenseStatsError: "",
        };
      case GET_EXTRA_EXPENSE_STATS_ERROR:
        return {
          ...state,
          extraExpenseStatsLoading: false,
          extraExpenseStatsError: action.payload,
        };
  
      case GET_SERVICE_STATS:
        return {
          ...state,
          serviceStats: {},
          serviceStatsLoading: true,
        };
      case GET_SERVICE_STATS_SUCCESS:
        return {
          ...state,
          serviceStats: action.payload,
          serviceStatsLoading: false,
          serviceStatsError: "",
        };
      case GET_SERVICE_STATS_ERROR:
        return {
          ...state,
          serviceStatsLoading: false,
          serviceStatsError: action.payload,
        };
  
      case GET_DEF_STATS:
        return {
          ...state,
          defStats: {},
          defStatsLoading: true,
        };
      case GET_DEF_STATS_SUCCESS:
        return {
          ...state,
          defStats: action.payload,
          defStatsLoading: false,
          defStatsError: "",
        };
      case GET_DEF_STATS_ERROR:
        return {
          ...state,
          defStatsLoading: false,
          defStatsError: action.payload,
        };
  
      case GET_EMI_STATS:
        return {
          ...state,
          emiStats: {},
          emiStatsLoading: true,
        };
      case GET_EMI_STATS_SUCCESS:
        return {
          ...state,
          emiStats: action.payload,
          emiStatsLoading: false,
          emiStatsError: "",
        };
      case GET_EMI_STATS_ERROR:
        return {
          ...state,
          emiStatsLoading: false,
          emiStatsError: action.payload,
        };
  
      case GET_SALARY_STATS:
        return {
          ...state,
          salaryStats: {},
          salaryStatsLoading: true,
        };
      case GET_SALARY_STATS_SUCCESS:
        return {
          ...state,
          salaryStats: action.payload,
          salaryStatsLoading: false,
          salaryStatsError: "",
        };
      case GET_SALARY_STATS_ERROR:
        return {
          ...state,
          salaryStatsLoading: false,
          salaryStatsError: action.payload,
        };
  
      default:
        return { ...state };
    }
  };
  
  export default StatsReducer;
  