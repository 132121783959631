import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../ui/dialog";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubscriptionKeyAction,
  getSubscriptionListAction,
  postSubscriptionCreateOrderAction,
  postSubscriptionPaymentVerificationAction,
} from "../../../../redux/company/subscription/action";
import toast from "react-hot-toast";
import Backdrop from "../../../../components/backdrop";
import {
  getCompanyTruckDetailsAction,
  getCompanyTruckDetailsIdAction,
} from "../../../../redux/company/truck/action";

function SubscriptionList({ truckId, setShowModal }) {
  const dispatch = useDispatch();
  const {
    getSubscriptionList,
    getSubscriptionListLoading,
    getSubscriptionListError,
    getSubscriptionKey,
    getSubscriptionKeyLoading,
    getSubscriptionKeyError,
    postSubscriptionCreateOrder,
    postSubscriptionCreateOrderLoading,
    postSubscriptionCreateOrderError,
    postSubscriptionPaymentVerification,
    postSubscriptionPaymentVerificationLoading,
    postSubscriptionPaymentVerificationError,
  } = useSelector((state) => state.SubscriptionSection);
  const SubscriptionSchema = Yup.object().shape({
    subscription: Yup.string().required("Please select a subscription plan"),
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyLoading, setKeyLoading] = useState(false);
  const [verficationLoading, setVerficationLoading] = useState(false);
  const [key, setKey] = useState(null);

  useEffect(() => {
    dispatch(getSubscriptionListAction());
  }, []);
  useEffect(() => {
    if (getSubscriptionList.status === 200) {
      setData(getSubscriptionList.data);
    }
  }, [getSubscriptionList]);
  useEffect(() => {
    if (loading && key) {
      if (postSubscriptionCreateOrder.status === 200) {
        setLoading(false);
        setShowModal(false)
        handlePayment(postSubscriptionCreateOrder.data);
      }
    }
  }, [postSubscriptionCreateOrder]);
  useEffect(() => {
    if (loading) {
      if (postSubscriptionCreateOrderError.status === 400) {
        setLoading(false);
        toast.error(postSubscriptionCreateOrderError.message);
      } else if (postSubscriptionCreateOrderError.status === 500) {
        setLoading(false);
        toast.error(postSubscriptionCreateOrderError.error);
      }
    }
  }, [postSubscriptionCreateOrderError]);
  useEffect(() => {
    if (keyLoading) {
      if (getSubscriptionKey.status === 200) {
        setKeyLoading(false);
        setKey(getSubscriptionKey.data);
      }
    }
  }, [getSubscriptionKey]);
  useEffect(() => {
    if (keyLoading) {
      if (getSubscriptionKeyError.status === 400) {
        setLoading(false);
        toast.error(getSubscriptionKeyError.message);
      } else if (getSubscriptionKeyError.status === 500) {
        setLoading(false);
        toast.error(getSubscriptionKeyError.error);
      }
    }
  }, [getSubscriptionKeyError]);
  useEffect(() => {
    if (verficationLoading) {
      if (postSubscriptionPaymentVerification.status === 200) {
        setVerficationLoading(false);
        toast.success(postSubscriptionPaymentVerification.message);
        setShowModal(false);
        dispatch(getCompanyTruckDetailsIdAction(truckId));
        dispatch(getCompanyTruckDetailsAction());
      }
    }
  }, [postSubscriptionPaymentVerification]);
  useEffect(() => {
    if (verficationLoading) {
      if (postSubscriptionPaymentVerificationError.status === 400) {
        setLoading(false);
        toast.error(postSubscriptionPaymentVerificationError.message);
      } else if (postSubscriptionPaymentVerificationError.status === 500) {
        setLoading(false);
        toast.error(postSubscriptionPaymentVerificationError.error);
      }
    }
  }, [postSubscriptionPaymentVerificationError]);

  const handlePayment = async (data) => {
    var options = {
      key: key.key_id, // Enter the Key ID generated from the Dashboard
      amount: data?.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "TRUCKIING", //your business name
      description: "Truckiing subscription payment",
      image: "",
      order_id: data?.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response) {
        console.log(response)
        const payload = {
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
          razorpay_payment_id: response.razorpay_payment_id,
          subscriptioDetailsId: data?.receipt,
        };
        setVerficationLoading(true);
        dispatch(postSubscriptionPaymentVerificationAction(payload));
      },
      notes: data.notes,
      theme: {
        color: "#3399cc",
      },
    };
    console.log(options)
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const onSubmit = (values) => {
    const payload = {
      truckId: truckId,
      subscriptionId: values.subscription,
    };
    dispatch(postSubscriptionCreateOrderAction(payload));
    dispatch(getSubscriptionKeyAction());
    setLoading(true);
    setKeyLoading(true);
  };

  return (
    <DialogContent className="bg-gray-100 w-fit">
      {(getSubscriptionListLoading ||
        postSubscriptionCreateOrderLoading ||
        getSubscriptionKeyLoading) && <Backdrop />}
      <DialogHeader>
        <DialogTitle>Truck Subscription</DialogTitle>
      </DialogHeader>
      <div className="text-sm mt-5 font-semibold">
        Please select the subscription which suits you best.
      </div>
      <Formik
        initialValues={{ subscription: "" }}
        validationSchema={SubscriptionSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form className="flex flex-col space-y-4">
            {data.map((subscription) => (
              <label
                key={subscription._id}
                htmlFor={subscription._id}
                onClick={() => setFieldValue("subscription", subscription._id)}
                className={`border border-gray-300 p-4 rounded-lg flex justify-between items-center cursor-pointer hover:border-blue-600 ${
                  values.subscription === subscription._id
                    ? "border-green-600"
                    : ""
                }`}
              >
                <Field
                  checked={values.subscription === subscription._id}
                  type="radio"
                  name="subscription"
                  id={subscription._id}
                  className="sr-only"
                />
                <div className="flex items-center">
                  <span className="w-5 h-5 border border-gray-400 rounded-full flex justify-center items-center mr-4 p-1">
                    {values.subscription === subscription._id && (
                      <span className="w-full h-full rounded-full bg-white ring-4 ring-green-700"></span>
                    )}
                  </span>
                  <div className="flex flex-col">
                    <div className="text-lg text-black font-semibold whitespace-nowrap">
                      {subscription.name} - <label className="text-xs">
                         {subscription.months}Months
                        </label>
                    </div>
                    <div className="text-lg text-black font-semibold"></div>
                    <div className="text-gray-500">{`Rs.${subscription.costPerMonth}/mo`}</div>
                  </div>
                </div>
                <div className="text-right">
                  <div className="text-lg text-black font-semibold">{`Rs.${subscription.finalCost.toFixed(
                    2
                  )}`}</div>
                  {subscription.finalCost !== subscription.defaultCost && (
                    <div className="text-sm text-gray-500 block line-through">{`$${subscription.defaultCost.toFixed(
                      2
                    )}`}</div>
                  )}
                  {(subscription.isDiscount ||
                    subscription?.isExtraDiscount) && (
                    <span className="text-xs bg-green-200 w-fit  text-green-700 rounded-full px-2 py-1 mt-1 inline-block">
                      Save{" "}
                      {subscription.isDiscount && `${subscription.discountPercent}%`}
                      {subscription.isDiscount &&
                        `(${subscription.discountName})`}
                      {subscription.isDiscount &&
                        subscription?.isExtraDiscount &&
                        "+"}
                        {subscription?.extraDiscount?.map((item, index)=> 
                        <>
                        {item?.extraDiscountPercent}%
                        ({item?.extraDiscountName})
                        {index !== subscription?.extraDiscount?.length -1 && "+"}
                        </>
                        )}
                    </span>
                  )}
                </div>
              </label>
            ))}

            {errors.subscription && touched.subscription ? (
              <div className="text-red-500 text-sm">{errors.subscription}</div>
            ) : null}

            <DialogFooter>
              <button
                className="ghostButton w-fit"
                onClick={() => setShowModal(false)}
                type="button"
              >
                Close
              </button>

              <button
                className="primaryButton  w-fit"
                type="submit"
                disabled={
                  getSubscriptionListLoading ||
                  postSubscriptionCreateOrderLoading ||
                  postSubscriptionPaymentVerificationLoading ||
                  getSubscriptionKeyLoading
                }
              >
                Subscribe
              </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default SubscriptionList;
