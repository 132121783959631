const {
  GET_CLIENT,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_ERROR,
  GET_CLIENT_ID,
  GET_CLIENT_ID_SUCCESS,
  GET_CLIENT_ID_ERROR,
  POST_CLIENT,
  POST_CLIENT_SUCCESS,
  POST_CLIENT_ERROR,
  PATCH_CLIENT,
  PATCH_CLIENT_SUCCESS,
  PATCH_CLIENT_ERROR,
  DELETE_CLIENT,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_ERROR,
} = require("../../actions");

const INIT_STATE = {
  getClient: {},
  getClientLoading: false,
  getClientError: "",
  getClientId: {},
  getClientIdLoading: false,
  getClientIdError: "",
  postClient: {},
  postClientLoading: false,
  postClientError: "",
  patchClient: {},
  patchClientLoading: false,
  patchClientError: "",
  deleteClient: {},
  deleteClientLoading: false,
  deleteClientError: "",
};

const Clientsection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLIENT:
      return {
        ...state,
        getClient: {},
        getClientLoading: true,
      };
    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        getClient: action.payload,
        getClientLoading: false,
        getClientError: "",
      };

    case GET_CLIENT_ERROR:
      return {
        ...state,
        getClientLoading: false,
        getClientError: action.payload,
      };
    case GET_CLIENT_ID:
      return {
        ...state,
        getClientId: {},
        getClientIdLoading: true,
      };
    case GET_CLIENT_ID_SUCCESS:
      return {
        ...state,
        getClientId: action.payload,
        getClientIdLoading: false,
        getClientIdError: "",
      };

    case GET_CLIENT_ID_ERROR:
      return {
        ...state,
        getClientIdLoading: false,
        getClientIdError: action.payload,
      };
      case POST_CLIENT:
      return {
        ...state,
        postClient: {},
        postClientLoading: true,
      };
    case POST_CLIENT_SUCCESS:
      return {
        ...state,
        postClient: action.payload,
        postClientLoading: false,
        postClientError: "",
      };

    case POST_CLIENT_ERROR:
      return {
        ...state,
        postClientLoading: false,
        postClientError: action.payload,
      };
    case PATCH_CLIENT:
      return {
        ...state,
        patchClient: {},
        patchClientLoading: true,
      };
    case PATCH_CLIENT_SUCCESS:
      return {
        ...state,
        patchClient: action.payload,
        patchClientLoading: false,
        patchClientError: "",
      };

    case PATCH_CLIENT_ERROR:
      return {
        ...state,
        patchClientLoading: false,
        patchClientError: action.payload,
      };
    case DELETE_CLIENT:
      return {
        ...state,
        deleteClient: {},
        deleteClientLoading: true,
      };
    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        deleteClient: action.payload,
        deleteClientLoading: false,
        deleteClientError: "",
      };

    case DELETE_CLIENT_ERROR:
      return {
        ...state,
        deleteClientLoading: false,
        deleteClientError: action.payload,
      };
    default:
      return { ...state };
  }
};

export default Clientsection;
