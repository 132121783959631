import { DELETE_ADMIN_TRUCK_SUBSCRIPTION, DELETE_ADMIN_TRUCK_SUBSCRIPTION_ERROR, DELETE_ADMIN_TRUCK_SUBSCRIPTION_SUCCESS, DELETE_ADMIN_TRUCK, DELETE_ADMIN_TRUCK_ERROR, DELETE_ADMIN_TRUCK_SUCCESS, GET_ADMIN_TRUCK_BY_TRUCKID, GET_ADMIN_TRUCK_BY_TRUCKID_ERROR, GET_ADMIN_TRUCK_BY_TRUCKID_SUCCESS, GET_ADMIN_TRUCK_LIST, GET_ADMIN_TRUCK_LIST_COMPANYID, GET_ADMIN_TRUCK_LIST_COMPANYID_ERROR, GET_ADMIN_TRUCK_LIST_COMPANYID_SUCCESS, GET_ADMIN_TRUCK_LIST_ERROR, GET_ADMIN_TRUCK_LIST_SUCCESS, GET_ADMIN_TRUCK_SUBSCRIPTION_LIST, GET_ADMIN_TRUCK_SUBSCRIPTION_LIST_ERROR, GET_ADMIN_TRUCK_SUBSCRIPTION_LIST_SUCCESS, PATCH_ADMIN_BLOCK_COMPANY_TRUCK, PATCH_ADMIN_BLOCK_COMPANY_TRUCK_ERROR, PATCH_ADMIN_BLOCK_COMPANY_TRUCK_SUCCESS, POST_ADMIN_TRUCK_PURCHASE, POST_ADMIN_TRUCK_PURCHASE_ERROR, POST_ADMIN_TRUCK_PURCHASE_SUCCESS, POST_ADMIN_TRUCK_SELL, POST_ADMIN_TRUCK_SELL_ERROR, POST_ADMIN_TRUCK_SELL_SUCCESS, POST_ADMIN_TRUCK_SUBSCRIPTION, POST_ADMIN_TRUCK_SUBSCRIPTION_ERROR, POST_ADMIN_TRUCK_SUBSCRIPTION_SUCCESS, GET_ADMIN_TRUCK_SUBSCRIPTION_DETAILS, GET_ADMIN_TRUCK_SUBSCRIPTION_DETAILS_SUCCESS, GET_ADMIN_TRUCK_SUBSCRIPTION_DETAILS_ERROR } from "../../actions";

export const getAdminTruckListAction = (info) => ({
  type: GET_ADMIN_TRUCK_LIST,
  payload: info,
});
export const getAdminTruckListSuccess = (info) => ({
  type: GET_ADMIN_TRUCK_LIST_SUCCESS,
  payload: info,
});
export const getAdminTruckListError = (error) => ({
  type: GET_ADMIN_TRUCK_LIST_ERROR,
  payload: error,
});
export const getAdminTruckSubscriptionDetailsAction = (info) => ({
  type: GET_ADMIN_TRUCK_SUBSCRIPTION_DETAILS,
  payload: info,
});
export const getAdminTruckSubscriptionDetailsSuccess = (info) => ({
  type: GET_ADMIN_TRUCK_SUBSCRIPTION_DETAILS_SUCCESS,
  payload: info,
});
export const getAdminTruckSubscriptionDetailsError = (error) => ({
  type: GET_ADMIN_TRUCK_SUBSCRIPTION_DETAILS_ERROR,
  payload: error,
});
export const getAdminTruckbyIdAction = (info) => ({
  type: GET_ADMIN_TRUCK_BY_TRUCKID,
  payload: info,
});
export const getAdminTruckbyIdSuccess = (info) => ({
  type: GET_ADMIN_TRUCK_BY_TRUCKID_SUCCESS,
  payload: info,
});
export const getAdminTruckbyIdError = (error) => ({
  type: GET_ADMIN_TRUCK_BY_TRUCKID_ERROR,
  payload: error,
});
export const getAdminTruckbyCompanyIdAction = (info) => ({
  type: GET_ADMIN_TRUCK_LIST_COMPANYID,
  payload: info,
});
export const getAdminTruckbyCompanyIdSuccess = (info) => ({
  type: GET_ADMIN_TRUCK_LIST_COMPANYID_SUCCESS,
  payload: info,
});
export const getAdminTruckbyCompanyIdError = (error) => ({
  type: GET_ADMIN_TRUCK_LIST_COMPANYID_ERROR,
  payload: error,
});
export const getAdminTruckSubscriptioListAction = (info) => ({
  type: GET_ADMIN_TRUCK_SUBSCRIPTION_LIST,
  payload: info,
});
export const getAdminTruckSubscriptioListSuccess = (info) => ({
  type: GET_ADMIN_TRUCK_SUBSCRIPTION_LIST_SUCCESS,
  payload: info,
});
export const getAdminTruckSubscriptioListError = (error) => ({
  type: GET_ADMIN_TRUCK_SUBSCRIPTION_LIST_ERROR,
  payload: error,
});
export const postAdminTruckSubscriptionAction = (info) => ({
  type: POST_ADMIN_TRUCK_SUBSCRIPTION,
  payload: info,
});
export const postAdminTruckSubscriptionSuccess = (info) => ({
  type: POST_ADMIN_TRUCK_SUBSCRIPTION_SUCCESS,
  payload: info,
});
export const postAdminTruckSubscriptionError = (error) => ({
  type: POST_ADMIN_TRUCK_SUBSCRIPTION_ERROR,
  payload: error,
});
export const postAdminTruckSellAction = (info) => ({
  type: POST_ADMIN_TRUCK_SELL,
  payload: info,
});
export const postAdminTruckSellSuccess = (info) => ({
  type: POST_ADMIN_TRUCK_SELL_SUCCESS,
  payload: info,
});
export const postAdminTruckSellError = (error) => ({
  type: POST_ADMIN_TRUCK_SELL_ERROR,
  payload: error,
});
export const postAdminTruckPurchaseAction = (info) => ({
  type: POST_ADMIN_TRUCK_PURCHASE,
  payload: info,
});
export const postAdminTruckPurchaseSuccess = (info) => ({
  type: POST_ADMIN_TRUCK_PURCHASE_SUCCESS,
  payload: info,
});
export const postAdminTruckPurchaseError = (error) => ({
  type: POST_ADMIN_TRUCK_PURCHASE_ERROR,
  payload: error,
});
export const deleteAdminTruckAction = (info) => ({
  type: DELETE_ADMIN_TRUCK,
  payload: info,
});
export const deleteAdminTruckSuccess = (info) => ({
  type: DELETE_ADMIN_TRUCK_SUCCESS,
  payload: info,
});
export const deleteAdminTruckError = (error) => ({
  type: DELETE_ADMIN_TRUCK_ERROR,
  payload: error,
});
export const deleteAdminTruckSubscriptionAction = (info) => ({
  type: DELETE_ADMIN_TRUCK_SUBSCRIPTION,
  payload: info,
});
export const deleteAdminTruckSubscriptionSuccess = (info) => ({
  type: DELETE_ADMIN_TRUCK_SUBSCRIPTION_SUCCESS,
  payload: info,
});
export const deleteAdminTruckSubscriptionError = (error) => ({
  type: DELETE_ADMIN_TRUCK_SUBSCRIPTION_ERROR,
  payload: error,
});
export const patchAdminBlockCompanyTruckAction = (info) => ({
  type: PATCH_ADMIN_BLOCK_COMPANY_TRUCK,
  payload: info,
});
export const patchAdminBlockCompanyTruckSuccess = (info) => ({
  type: PATCH_ADMIN_BLOCK_COMPANY_TRUCK_SUCCESS,
  payload: info,
});
export const patchAdminBlockCompanyTruckError = (error) => ({
  type: PATCH_ADMIN_BLOCK_COMPANY_TRUCK_ERROR,
  payload: error,
});
