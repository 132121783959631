import { getAdminTruckSubscriptioListAction } from "../../../redux/admin/truck/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import SubscriptionList from "./subscriptionList";
import toast from "react-hot-toast";
import Backdrop from "../../backdrop";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../ui/tabs";

function Subscription() {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const {
    getAdminTruckSubscriptioList,
    getAdminTruckSubscriptioListLoading,
    getAdminTruckSubscriptioListError,
  } = useSelector((state) => state.AdminTruckSection);

  let [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("truckId");
  const companyTruckId = searchParams.get("companyTruckId");

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getAdminTruckSubscriptioListAction(companyTruckId));
  }, []);

  useEffect(() => {
    if (getAdminTruckSubscriptioList?.status === 200) {
      setData(getAdminTruckSubscriptioList?.data);
    }
  }, [getAdminTruckSubscriptioList]);

  useEffect(() => {
    if (getAdminTruckSubscriptioListError?.status === 400) {
      toast.error(getAdminTruckSubscriptioListError.message);
    } else if (getAdminTruckSubscriptioListError?.status === 404) {
      toast.error(getAdminTruckSubscriptioListError.message);
    } else if (getAdminTruckSubscriptioListError?.status === 500) {
      toast.error(getAdminTruckSubscriptioListError.error);
    }
  }, [getAdminTruckSubscriptioListError]);
  return (
    <div>
      {getAdminTruckSubscriptioListLoading && <Backdrop />}
      <div className="flex justify-end">
        <button
          type="button"
          className="primaryButton w-fit flex gap-2 mb-5 "
          onClick={() => navigate(`/admin/truck/subscription/addSubscription?truckId=${id}&companyTruckId=${companyTruckId}`)}
        >
          Subscription
        </button>
      </div>
      <Tabs defaultValue="All" color="primary" className="min-w-[400px]">
        <TabsList>
          <TabsTrigger value="All">All Trucks</TabsTrigger>
          <TabsTrigger value="Success">Success</TabsTrigger>
          <TabsTrigger value="Failed">Failed</TabsTrigger>
          <TabsTrigger value="pending">Pending</TabsTrigger>
        </TabsList>
        <TabsContent value="All">
          <SubscriptionList data={data} />
        </TabsContent>
        <TabsContent value="Success">
          <SubscriptionList data={data?.filter((item)=> item.status === "Success")} />
        </TabsContent>
        <TabsContent value="Failed">
          <SubscriptionList data={data?.filter((item)=> item.status === "Failed")} />
        </TabsContent>
        <TabsContent value="pending">
          <SubscriptionList data={data?.filter((item)=> item.status === "Pending")} />
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default Subscription;
