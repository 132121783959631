import React from "react";
import { Link, useParams } from "react-router-dom";

function FamilyDetails({ data }) {

  const {id} = useParams()
  return (
    <div className="bg-white p-4 rounded-md shadow-lg grid grid-cols-4 gap-5 w-full">
      <div className="col-span-4 flex justify-between">
        <div className="text-2xl font-extrabold">Family Details</div>
        <Link to={`/company/employee/addEmployee/familyDetails?userId=${id}`} className="primaryButton bg-yellow-600 hover:bg-yellow-800 w-fit h-fit">{data?.length>0 ? "Edit" : "Add"} Family Details</Link>
      </div>
      {data?.map((item) => (
        <>
          <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
            <label htmlFor="">Name</label>
            <p className="">{item?.name}</p>
          </div>
          <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
            <label htmlFor="">Relation</label>
            <p className="">{item?.relation}</p>
          </div>
          <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
            <label htmlFor="">Address</label>
            <p className="">{item?.address}</p>
          </div>
          <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
            <label htmlFor="">City</label>
            <p className="">{item?.cityName}</p>
          </div>
          <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
            <label htmlFor="">State</label>
            <p className="">{item?.stateName}</p>
          </div>
          <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
            <label htmlFor="">Country</label>
            <p className="">{item?.country}</p>
          </div>
          <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
            <label htmlFor="">PostCode</label>
            <p className="">{item?.zipCode}</p>
          </div>
          <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
            <label htmlFor="">Contact Number</label>
            <p className="">{item?.contactNumber}</p>
          </div>
          <div className="col-span-4 ">
            <hr />
          </div>
        </>
      ))}
    </div>
  );
}

export default FamilyDetails;
