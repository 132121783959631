import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Backdrop from "../../backdrop";
import { FaRoute } from "react-icons/fa";
import TripList from "./tripList";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../ui/tabs";
import TripListPlanned from "./tripListPlanned";
import TripListRunning from "./tripListRunning";
import TripListEnd from "./tripListEnd";
import TripListCompleted from "./tripListCompleted";

function Trip() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    getCompanyTripDetailsFilter,
    getCompanyTripPlannedDetailsFilter,
    getCompanyTripRunningDetailsFilter,
    getCompanyTripEndDetailsFilter,
    getCompanyTripCompletedDetailsFilter,
    getCompanyTripDetailsFilterLoading,
    getCompanyTripPlannedDetailsFilterLoading,
    getCompanyTripRunningDetailsFilterLoading,
    getCompanyTripEndDetailsFilterLoading,
    getCompanyTripCompletedDetailsFilterLoading,
    getCompanyTripDetailsFilterError,
  } = useSelector((state) => state.CompanyTripsection);

  console.log(getCompanyTripPlannedDetailsFilter);
  const [data, setData] = useState([]);
  const [plannedData, setPlannedData] = useState([]);
  const [runningData, setRunningData] = useState([]);
  const [endData, setEndData] = useState([]);
  const [completedData, setCompletedData] = useState([]);
  const [dropdown, setDropdown] = useState("All");

  useEffect(() => {
    if (getCompanyTripPlannedDetailsFilter?.status === 200) {
      setPlannedData(getCompanyTripPlannedDetailsFilter);
    }
  }, [getCompanyTripPlannedDetailsFilter]);
  useEffect(() => {
    if (getCompanyTripRunningDetailsFilter?.status === 200) {
      setRunningData(getCompanyTripRunningDetailsFilter);
    }
  }, [getCompanyTripRunningDetailsFilter]);
  useEffect(() => {
    if (getCompanyTripEndDetailsFilter?.status === 200) {
      setEndData(getCompanyTripEndDetailsFilter);
    }
  }, [getCompanyTripEndDetailsFilter]);
  useEffect(() => {
    if (getCompanyTripCompletedDetailsFilter?.status === 200) {
      setCompletedData(getCompanyTripCompletedDetailsFilter);
    }
  }, [getCompanyTripCompletedDetailsFilter]);
  useEffect(() => {
    if (getCompanyTripDetailsFilter?.status === 200) {
      setData(getCompanyTripDetailsFilter);
    }
  }, [getCompanyTripDetailsFilter]);
  useEffect(() => {
    if (getCompanyTripDetailsFilterError?.status === 404) {
      toast.error(getCompanyTripDetailsFilterError.message, {
        id: "truck-toast",
      });
    } else if (getCompanyTripDetailsFilterError?.status === 400) {
      toast.error(getCompanyTripDetailsFilterError.message, {
        id: "truck-toast",
      });
    } else if (getCompanyTripDetailsFilterError?.status === 500) {
      toast.error(getCompanyTripDetailsFilterError.error, {
        id: "truck-toast",
      });
    }
  }, [getCompanyTripDetailsFilterError]);

  const handleTab = (value) => {
    setDropdown(value);
    searchParams.set("tab", value);
    setSearchParams(searchParams);
  };

  // Dropdown data for small screens
  const dropdownOptions = [
    { value: "All", label: "All Trucks" },
    { value: "Planned", label: "Planned" },
    { value: "Running", label: "Running Trip" },
    { value: "End", label: "Trip End" },
    { value: "Complete", label: "Complete" },
  ].filter(Boolean); // Filter out any false values

  const handleDropdownChange = (event) => {
    handleTab(event.target.value);
  };
  return (
    <div>
      {(getCompanyTripDetailsFilterLoading ||
        getCompanyTripPlannedDetailsFilterLoading ||
        getCompanyTripRunningDetailsFilterLoading ||
        getCompanyTripEndDetailsFilterLoading ||
        getCompanyTripCompletedDetailsFilterLoading) && <Backdrop />}
      <div className="flex justify-end">
        <button
          type="button"
          className="primaryButton w-fit flex gap-2 mb-5 "
          onClick={() => navigate("/company/trip/addTrip")}
        >
          Add New Trip <FaRoute className="text-2xl" />
        </button>
      </div>
      {/* Dropdown for small screens */}
      <div className="block lg:hidden mb-5">
        <select
          onChange={handleDropdownChange}
          value={searchParams.get("tab") || "All"}
          className="primarySelect w-full"
        >
          {dropdownOptions.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      {/* Tabs for larger screens */}
      <Tabs
        defaultValue={searchParams.get("tab") || dropdown}
        value={searchParams.get("tab") || dropdown}
      >
        <TabsList className="hidden w-fit lg:block">
          <TabsTrigger onClick={() => handleTab("All")} value="All">
            All Trips
          </TabsTrigger>
          <TabsTrigger onClick={() => handleTab("Planned")} value="Planned">
            Planned
          </TabsTrigger>
          <TabsTrigger onClick={() => handleTab("Running")} value="Running">
            Running Trip
          </TabsTrigger>
          <TabsTrigger onClick={() => handleTab("End")} value="End">
            Trip End
          </TabsTrigger>
          <TabsTrigger onClick={() => handleTab("Complete")} value="Complete">
            Complete
          </TabsTrigger>
        </TabsList>
        <TabsContent value="All">
          <TripList data={data} />
        </TabsContent>
        <TabsContent value="Planned">
          <TripListPlanned data={plannedData} />
        </TabsContent>
        <TabsContent value="Running">
          <TripListRunning data={runningData} />
        </TabsContent>
        <TabsContent value="End">
          <TripListEnd data={endData} />
        </TabsContent>
        <TabsContent value="Complete">
          <TripListCompleted data={completedData} />
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default Trip;
