import React, { useEffect, useState } from "react";
import PaginationPackage from "../../paginationPackage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import usePagination from "headless-pagination-react";
import {
  getEmiStatsAction,
  getExtraExpenseStatsAction,
  getFitnessStatsAction,
  getInsuranceStatsAction,
  getPermitStatsAction,
  getSalaryStatsAction,
} from "../../../redux/company/finance/action";
import { FiDownload } from "react-icons/fi";
import { AiOutlineCloseSquare } from "react-icons/ai";
import moment from "moment";
import { getCompanyEmployeeDetailsAction } from "../../../redux/company/employee/action";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const convertFilterToQueryString = (filter) => {
  return Object.keys(filter)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(filter[key])}`
    )
    .join("&");
};

function SalarySection({ show, setShow }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams(); // Search params hook

  const { salaryStats } = useSelector((state) => state.StatsReducer);
  const {
    getCompanyEmployeeDetails,
    getCompanyEmployeeDetailsLoading,
    getCompanyEmployeeDetailsError,
  } = useSelector((state) => state.CompanyEmployeesection);

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(1000);
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeId, setEmployeeId] = useState(false);
  const [maxLinks, setMaxLinks] = useState(10);
  const [perPage, setperPage] = useState(5);
  const { links, from, to, setPage, onNext, onPrevious, page } = usePagination({
    totalItems,
    perPage,
    maxLinks,
  });
  useEffect(() => {
    dispatch(getCompanyEmployeeDetailsAction());
  }, []);

  useEffect(() => {
    if (getCompanyEmployeeDetails?.status === 200) {
      setEmployeeList(getCompanyEmployeeDetails?.data);
    }
  }, [getCompanyEmployeeDetails]);
  useEffect(() => {
    if (salaryStats?.status === 200) {
      setData(salaryStats?.data);
      setTotalItems(salaryStats?.data?.salary?.length);
    }
  }, [salaryStats]);

  useEffect(() => {
    const filter = {
      startDate: "",
      endDate: "",
      employeeId: "",
    };
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    const truckId = searchParams.get("truckId");
    const isTruck = searchParams.get("allTruck") === "true";

    // Add date filters if they are present
    if (startDate) {
      filter.startDate = new Date(startDate).toISOString(); // Convert date to ISO string
    }
    if (endDate) {
      filter.endDate = new Date(endDate).toISOString(); // Convert date to ISO string
    }

    // Add truckId if it's not 'allTruck'
    if (!isTruck && truckId) {
      filter.truckId = truckId;
    }
    if (employeeId) {
      filter.employeeId = employeeId;
    }
    // Convert filter object to query string
    const queryString = convertFilterToQueryString(filter);

    dispatch(getSalaryStatsAction(queryString));
  }, [searchParams, employeeId]);

  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 2,
        }).format(amount)
      : "₹0.00";

      const handleExport = () => {
        if (!data || !data?.salary) {
          console.error("Data or salary is undefined");
          return;
        }
    
        // Flatten Extra Expenses data
        const combinedData = [
          ...( data.salary.map((item) => ({
            "Salary Month": item?.salaryMonth
              ? moment(item?.salaryMonth).format("MMM YYYY")
              : "-",
            "Employee Name": item?.companyEmployeeId?.employeeId
              ? `${item?.companyEmployeeId?.firstName} ${item?.companyEmployeeId?.lastName}`
              : "-",
            "Salary Amount and Add/Remove Amount": item?.salary
              ? item?.addOns !== 0
                  ? formatAmount(item?.salary) +
                    " " +
                    (item?.addOns > 0 ? "+" : "") +
                    " " + // Show "+" only if addOns is positive
                    formatAmount(item?.addOns)
                  : formatAmount(item?.salary) // Just show salary if addOns is 0
                : "-",
            Cost: item?.totalPay ? formatAmount(item?.totalPay) : "-",
          })) || []),
        ];
    
        // Find last `expenseDate` if startDate is not provided
        const lastExpenseDate = data?.salary?.length
         ? moment(
             data.salary[data.salary.length - 1]?.salaryMonth || new Date()
           ).format("MMM/YYYY")
         : "";
    
        // Determine the start and end date for the file name
        const startDate = searchParams.get("startDate")
          ? moment(searchParams.get("startDate")).format("DD/MMM/YYYY")
          : lastExpenseDate;
        const endDate = searchParams.get("endDate")
          ? moment(searchParams.get("endDate")).format("DD/MMM/YYYY")
          : "";
    
        // Convert to worksheet
        const ws = XLSX.utils.json_to_sheet(combinedData);
    
        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Extra Expenses Data");
    
        // Generate Excel file and trigger download
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const file = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
    
        saveAs(
          file,
          `salary_${startDate}-${endDate ? endDate : moment(new Date()).format("MMM/YYYY")}.xlsx`
        );
      };
    
  return (
    <>
      <div className=" bg-white mt-6 shadow-lg rounded-sm relative">
        <div className="p-4 text-[#64709B] font-semibold flex justify-between">
          <div className="cursor-pointer" onClick={() => setShow("salary")}>
            Salary
          </div>
          <div>
            <select
              name="companyEmoplyeeId"
              className="bg-white flex gap-2 border border-[#D9D9D9] px-2.5 py-1 rounded text-sm min-h-[30px] h-[30px] w-fit"
              value={employeeId} // Disable truck selection if "allTruck" is checked
              onChange={(e) => setEmployeeId(e.target.value)}
            >
              <option value="">Employee List</option>
              {employeeList?.map((item) => (
                <option key={item} value={item?._id}>
                  {item?.firstName} {item?.lastName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="w-full h-0.5 bg-[#F1F2F6]" />
        <div className="p-4 text-[#303750] text-2xl font-bold flex gap-2">
          {formatAmount(data?.totalsalaryCost)}{" "}
          <div className="text-base font-medium mt-auto">INR</div>
        </div>
        {show == "salary" && <div className="arrow-down"></div>}
      </div>
      {show == "salary" && (
        <div className="col-span-4 order-last">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
              <thead className="ltr:text-left rtl:text-right">
                <tr>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-left">
                    Salary Months
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Employee Name
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Salary Amount and Add/Remove Amount
                  </th>

                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Cost
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium  justify-end flex gap-3">
                    <FiDownload className="text-lg ghostButton cursor-pointer" onClick={()=>handleExport()} />
                    <AiOutlineCloseSquare
                      onClick={() => setShow("")}
                      className="text-red-500 text-xl cursor-pointer"
                    />
                  </th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200">
                {data?.salary?.slice(from - 1, to)?.map((item) => (
                  <tr>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 ">
                      {item?.salaryMonth
                        ? moment(item?.salaryMonth).format("MMM / YYYY")
                        : "-"}
                    </td>
                    <td
                      className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center uppercase"
                      onClick={() =>
                        navigate(
                          "/company/employee/details/" +
                            item?.companyEmployeeId?._id
                        )
                      }
                    >
                      {item?.companyEmployeeId?.employeeId
                        ? item?.companyEmployeeId?.firstName +
                          " " +
                          item?.companyEmployeeId?.lastName
                        : "-"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center">
                      {item?.salary
                        ? item?.addOns !== 0
                          ? formatAmount(item?.salary) +
                            " " +
                            (item?.addOns > 0 ? "+" : "") +
                            " " + // Show "+" only if addOns is positive
                            formatAmount(item?.addOns)
                          : formatAmount(item?.salary) // Just show salary if addOns is 0
                        : "-"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center">
                      {item?.totalPay ? formatAmount(item?.totalPay) : "-"}
                    </td>
                  </tr>
                ))}

                <tr>
                  <td colspan="8">
                    <PaginationPackage
                      links={links}
                      onNext={onNext}
                      onPrevious={onPrevious}
                      from={from}
                      to={to}
                      totalItems={totalItems}
                      perPage={perPage}
                      totalPages={page}
                      setPage={setPage}
                      setperPage={setperPage}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default SalarySection;
