import { getAdminTruckListAction } from "../../../redux/admin/truck/action";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../ui/tabs";
import TruckList from "./truckList";
import Backdrop from "../../backdrop";

function TruckAdmin() {
  const dispatch = useDispatch();

  const {
    getAdminTruckList,
    getAdminTruckListLoading,
    getAdminTruckListError,
  } = useSelector((state) => state.AdminTruckSection);
  const [data, setData] = useState([]);
  useEffect(() => {
    dispatch(getAdminTruckListAction());
  }, []);

  useEffect(() => {
    if (getAdminTruckList?.status === 200) {
      setData(getAdminTruckList?.data);
    }
  }, [getAdminTruckList]);
  useEffect(() => {
    if (getAdminTruckListError?.status === 400) {
      toast.error(getAdminTruckListError?.message);
    } else if (getAdminTruckListError?.status === 404) {
      toast.error(getAdminTruckListError?.message);
    } else if (getAdminTruckListError?.status === 500) {
      toast.error(getAdminTruckListError?.error);
    }
  }, [getAdminTruckListError]);

  return (
    <div>
      {getAdminTruckListLoading && <Backdrop />}
      <div className="flex justify-end">
       
      </div>
      <Tabs defaultValue="All" color="primary" className="min-w-[400px]">
        <TabsList>
          <TabsTrigger value="All">All Trucks</TabsTrigger>
          <TabsTrigger value="company">In Company</TabsTrigger>
          <TabsTrigger value="sell">UnPurchased</TabsTrigger>
        </TabsList>
        <TabsContent value="All">
          <TruckList data={data} />
        </TabsContent>
        <TabsContent value="company">
          <TruckList data={data.filter((item) => item.currentCompanyId !== null)} />
        </TabsContent>
        <TabsContent value="sell">
          <TruckList data={data.filter((item) => item.currentCompanyId === null)} />
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default TruckAdmin;
