import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../ui/dialog";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import * as Yup from "yup";
import {
  deleteSalaryAction,
  getSalaryByUserAction,
} from "../../../../redux/company/employee/salary/action";
import { useDispatch, useSelector } from "react-redux";

function EmployeeSalaryDelete({ setShowModal, id }) {
  const dispatch = useDispatch();
  const { deleteSalary, deleteSalaryLoading, deleteSalaryError } = useSelector(
    (state) => state.SalarySection
  );
  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object({
    deleteValue: Yup.string()
      .required("This field is required")
      .oneOf(["SALARY"], 'You must type "SALARY" to confirm'),
  });
  useEffect(() => {
    if (loading) {
      if (deleteSalary?.status === 200) {
        toast.success(deleteSalary.message, {
          id: "deletesalary-toast",
        });
        const id = searchParams.get("employeeId");
        dispatch(getSalaryByUserAction(id));
        setLoading(false);
        setShowModal(false);
      }
    }
  }, [deleteSalary, loading]);
  useEffect(() => {
    if (loading) {
      if (deleteSalaryError?.status === 404) {
        toast.error(deleteSalaryError.message, {
          id: "deletesalary-toast",
        });
        setLoading(false);
      } else if (deleteSalaryError?.status === 400) {
        toast.error(deleteSalaryError.message, {
          id: "deletesalary-toast",
        });
        setLoading(false);
      } else if (deleteSalaryError?.status === 500) {
        toast.error(deleteSalaryError.error, {
          id: "deletesalary-toast",
        });
        setLoading(false);
      }
    }
  }, [deleteSalaryError, loading]);
  const onSubmit = (values) => {
    if (values.deleteValue === "SALARY") {
      dispatch(deleteSalaryAction(id));
      toast.loading("Deleting Salary...", { id: "deletesalary-toast" });
      setLoading(true);
    }
  };
  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle>Delete Salary</DialogTitle>
      </DialogHeader>
      <div className="text-xl mt-5 font-semibold">
        Are you sure you want to{" "}
        <span className="text-red-500 font-bold">Delete</span> this SALARY?
      </div>
      <Formik
        initialValues={{ deleteValue: "" }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="">
            <div className="mb-4">
              <label htmlFor="deleteValue" className="text-gray-700">
                To confirm, type <span className="font-bold">"SALARY"</span> in
                Capital Letter the box below*
              </label>
              <Field
                type="text"
                id="deleteValue"
                name="deleteValue"
                placeholder="SALARY"
              />
              <ErrorMessage
                name="deleteValue"
                component="div"
                className="text-red-600"
              />
            </div>
            <DialogFooter>
              <button
                className="secondaryButton"
                onClick={() => setShowModal(false)}
                type="button"
                disabled={deleteSalaryLoading}
              >
                Close
              </button>
              <button
                className="primaryButton bg-red-600 hover:bg-red-800 w-fit"
                type="submit"
                disabled={deleteSalaryLoading}
              >
                Delete
              </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default EmployeeSalaryDelete;
