import React, { useEffect, useState } from "react";
import SubscriptionListcard from "./subscriptionListcard";

function SubscriptionList({ data }) {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = data?.filter(
    (item) =>
      item?.companyTruckId?.truckNumber
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      item?.transitionNo?.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div>
      <div className="mb-4">
        <input
          placeholder="Search by Truck Number or Type"
          value={searchQuery}
          onChange={handleSearch}
          className="max-w-sm shadow-md "
        />
      </div>
      {filteredData?.length > 0 ? (
        filteredData?.map((item, index) => (
          <SubscriptionListcard data={item} reroute={true} />
        ))
      ) : (
        <p>No Subscription found.</p>
      )}
    </div>
  );
}

export default SubscriptionList;
