import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteCookies, getCookies, useCustomCookies } from "../auth/auth";
import { Admin, Company, Employee } from "../data/data";
import { NavLink, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { LuLogOut } from "react-icons/lu";
import logo from "../assets/images/logo2.png";


const Sidebar = ({ isOpen, toggleSidebar }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    handler();
  }, []);

  const handler = async () => {
    const type = await getCookies("userType");
    if (type === "company") {
      setUserType(Company);
    } else if (type === "admin") {
      setUserType(Admin);
    } else if (type === "employee") {
      setUserType(Employee);
    }
  };

  const handleLogout = async () => {
    const token = await deleteCookies("token");
    const userType = await deleteCookies("userType");

    if (token.status === true && userType.status === true) {
      navigate("/login");
    }
  };

  return (
    <>
      <div
        className={`transition-width duration-300 bg-[#1F263E] h-full fixed md:relative top-0 md:h-full z-50 md:z-0 ${
          isOpen ? "md:w-64 w-full z-[100]" : "w-20 md:w-20"
        } ${isOpen ? "block" : "hidden md:block"}`}
      >
        <div className="flex justify-end pr-10 mt-5 md:hidden">
          <button onClick={toggleSidebar} className=" ">
            {isOpen ? (
              <FaTimes className="text-white text-2xl" />
            ) : (
              <FaBars className="text-white text-2xl" />
            )}
          </button>
        </div>
        <div className="flex flex-col h-full space-y-6 m">
          <div className="flex items-center mt-5">
            {isOpen ?
            <h1 className="text-lg mx-auto font-bold logoFont    text-[#EFD098] ">
             TRUCKING
            </h1>
            : <span className="icon-trucking text-4xl text-black   mx-auto" style={{filter: "contrast(0.1)"}} /> }
          </div>
          <div className={` ${isOpen ? "mx-6" : ""} bg-[#F8F9FB] h-[0.5px] `} />

          {userType?.map((item) => (
            <NavLink key={item.href} to={item.href}>
              {({ isActive, isPending, isTransitioning }) => (
                <div
                  className={` ${
                    isActive
                      ? "text-white  bg-[#9CA5B2] py-2.5 px-6 "
                      : "text-white px-6"
                  } flex gap-4 w-full outline-none focus:border-0 focus:shadow-none `}
                >
                  <div
                    className={` ${
                      isActive ? "text-gray-800" : ""
                    } my-auto text-2xl outline-none`}
                  >
                    {item.icon}
                  </div>
                  {isOpen && (
                    <span className="my-auto transition-opacity duration-300 opacity-100 outline-none">
                      {item.name}
                    </span>
                  )}
                </div>
              )}
            </NavLink>
          ))}
          <div className="h-full flex">
            <button
              className="primaryButton flex gap-4 px-6 mt-auto mb-20"
              type="button"
              onClick={() => handleLogout()}
            >
              <LuLogOut className="text-2xl" title="Logout" />
              {isOpen && "LOGOUT"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
