import { combineReducers } from "redux";
import Authsection from "./auth/reducer";
import Companysection from "./company/reducer";
import AddressSection from "./address/reducer";
import CompanyTripsection from "./company/trip/reducer";
import CompanyEmployeesection from "./company/employee/reducer";
import Companytrucksection from "./company/truck/reducer";
import Fuelsection from "./company/fuel/reducer";
import TollTaxsection from "./company/tollTax/reducer";
import Servicesection from "./company/service/reducer";
import SalarySection from "./company/employee/salary/reducer";
import DEFSection from "./company/truck/def/reducer";
import ExpenseTripSection from "./company/trip/extraExpense/reducer";
import Clientsection from "./company/client/reducer";
import SubscriptionSection from "./company/subscription/reducer";
import EmiSection from "./company/truck/emi/reducer";
import StatsReducer from "./company/finance/reducer";
import AdminCompanysection from "./admin/company/reducer";
import AdminTruckSection from "./admin/truck/reducer";
import AdminSubscriptionSection from "./admin/subscription/reducer";
import AdminEmployeeSection from "./admin/employee/reducer";
import AdminExpenseSection from "./admin/expenses/reducer";
import AdminDashboardSection from "./admin/dashboard/reducer";
import CompanyAlertSection from "./company/alert/reducer";
import CompanyDashboardSection from "./company/dashboard/reducer";

const reducers = combineReducers({

  Authsection,
  Companysection,
  CompanyDashboardSection,
  AddressSection,
  Companytrucksection,
  CompanyAlertSection,
  CompanyTripsection,
  CompanyEmployeesection,
  Fuelsection,
  TollTaxsection,
  Servicesection,
  SalarySection,
  EmiSection,
  DEFSection,
  ExpenseTripSection,
  Clientsection,
  SubscriptionSection,
  StatsReducer,
  AdminCompanysection,
  AdminTruckSection,
  AdminSubscriptionSection,
  AdminEmployeeSection,
  AdminExpenseSection,
  AdminDashboardSection,
});

export default reducers;
