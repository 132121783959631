import React, { useState } from "react";
import SubscriptionListCard from "./subscriptionListCard";
import { Link } from "react-router-dom";

function SubscriptionList({ data }) {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = data?.filter(
    (item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.months.includes(searchQuery.toLowerCase()) ||
      item.discountPercent.includes(searchQuery.toLowerCase()) ||
      item.costPerMonth.includes(searchQuery.toLowerCase())
  );
  return (
    <div>
      <div className="mb-4 flex justify-between">
        <input
          placeholder="Search by Truck Number or Type"
          value={searchQuery}
          onChange={handleSearch}
          className="max-w-sm shadow-md "
        />
        <Link to={"addSubscription"} className="primaryButton w-fit">
          Add Subscription
        </Link>
      </div>
      {filteredData?.length > 0 ? (
        filteredData?.map((item, index) => (
          <SubscriptionListCard data={item} reroute={true} />
        ))
      ) : (
        <p>No Ownership found.</p>
      )}
    </div>
  );
}

export default SubscriptionList;
