import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Backdrop from "../../backdrop";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { getCompanyTruckListDropdownAction } from "../../../redux/company/truck/action";
import DatePicker from "../../datepicker/datePicker";
import {
  getCompanyTripDetailsIdAction,
  patchCompanyTripDetailsAction,
  postCompanyTripDetailsAction,
} from "../../../redux/company/trip/action";
import {
  getCompanyEmployeeDriverAction,
  getCompanyEmployeeHelperAction,
} from "../../../redux/company/employee/action";
import FileUpload from "../../fileupload/fileUpload";
import { getClientAction } from "../../../redux/company/client/action";
import FileMultiUpload from "../../fileupload/fileMuiltipleUpload";

function AddTrip() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getCompanyTruckListDropdown, getCompanyTruckListDropdownLoading } = useSelector(
    (state) => state.Companytrucksection
  );
  const { getClient, getClientLoading } = useSelector(
    (state) => state.Clientsection
  );

  const {
    getCompanyEmployeeDriver,
    getCompanyEmployeeHelper,
    getCompanyEmployeeHelperActionLoading,
    getCompanyEmployeeDriverLoading,
  } = useSelector((state) => state.CompanyEmployeesection);
  const {
    postCompanyTripDetails,
    patchCompanyTripDetails,
    getCompanyTripDetailsId,
    getCompanyTripDetailsIdLoading,
    postCompanyTripDetailsLoading,
    patchCompanyTripDetailsLoading,
    postCompanyTripDetailsError,
    patchCompanyTripDetailsError,
    getCompanyTripDetailsIdError,
  } = useSelector((state) => state.CompanyTripsection);
  let [searchParams, setSearchParams] = useSearchParams();
  const [truckNumber, setTruckNumber] = useState("");
  const [data, setData] = useState();
  const [truckData, setTruckData] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [helperData, setHelperData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [Planned, setPlanned] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [client, setClient] = useState("");
  const [additionalClients, setAdditionalClients] = useState(""); // New state for additional clients

  useEffect(() => {
    const edit = searchParams.get("id") === null;
    const id = searchParams.get("id");
    const status = searchParams.get("status");
    const truckId = searchParams.get("truckId");
    const clientId = searchParams.get("clientId");
    if (truckId !== null) {
      setTruckNumber(truckId);
    }
    if (clientId !== null) {
      setClient(clientId);
    }
    if (!edit) {
      dispatch(getCompanyTripDetailsIdAction(id));
    }
    if (status === "Planned") {
      setPlanned(true);
    }
  }, [searchParams]);
  useEffect(() => {
    dispatch(getClientAction());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getCompanyTruckListDropdownAction("isSell=false"));
    dispatch(getCompanyEmployeeDriverAction());
    dispatch(getCompanyEmployeeHelperAction());
  }, []);
  useEffect(() => {
    if (getCompanyTruckListDropdown?.status === 200) {
      setTruckData(
        getCompanyTruckListDropdown?.trucks
      );
    }
  }, [getCompanyTruckListDropdown]);
  useEffect(() => {
    if (getClient?.status === 200) {
      setClientData(getClient?.data);
    }
  }, [getClient]);
  useEffect(() => {
    const edit = searchParams.get("id") === null;

    if (!edit) {
      if (getCompanyTripDetailsId?.status === 200) {
        if (getCompanyTripDetailsId?.data?._id !== null) {
          setData(getCompanyTripDetailsId?.data);
        }
      }
      if (getCompanyTripDetailsIdError === 400) {
        toast.error(getCompanyTripDetailsIdError?.message);
      } else if (getCompanyTripDetailsIdError === 404) {
        toast.error(getCompanyTripDetailsIdError.message);
      } else if (getCompanyTripDetailsIdError === 500) {
        toast.error(getCompanyTripDetailsIdError.error);
      }
    }
  }, [getCompanyTripDetailsId, getCompanyTripDetailsIdError]);
  useEffect(() => {
    if (getCompanyEmployeeDriver?.status === 200) {
      setDriverData(getCompanyEmployeeDriver?.data);
    }
  }, [getCompanyEmployeeDriver]);
  useEffect(() => {
    if (getCompanyEmployeeHelper?.status === 200) {
      const mergeData = [...getCompanyEmployeeHelper?.data, ...driverData]
      setHelperData(mergeData);
    }
  }, [getCompanyEmployeeHelper]);
  useEffect(() => {
    if (!loader) {
      if (postCompanyTripDetails?.status === 200) {
        toast.success(postCompanyTripDetails.message, {
          id: "addDetails-toast",
        });
        setLoader(true);
        navigate(-1);
      }
    }
  }, [postCompanyTripDetails]);
  useEffect(() => {
    if (!loader) {
      if (postCompanyTripDetailsError?.status === 404) {
        toast.error(postCompanyTripDetailsError.message, {
          id: "addDetails-toast",
        });
        setLoader(true);
      } else if (postCompanyTripDetailsError?.status === 400) {
        toast.error(postCompanyTripDetailsError.message, {
          id: "addDetails-toast",
        });
        setLoader(true);
      } else if (postCompanyTripDetailsError?.status === 500) {
        toast.error(postCompanyTripDetailsError.error, {
          id: "addDetails-toast",
        });
        setLoader(true);
      }
    }
  }, [postCompanyTripDetailsError]);
  useEffect(() => {
    if (!loader) {
      if (patchCompanyTripDetails?.status === 200) {
        toast.success(patchCompanyTripDetails.message, {
          id: "updateDetails-toast",
        });
        setLoader(true);
        navigate(-1);
      }
    }
  }, [patchCompanyTripDetails]);
  useEffect(() => {
    if (!loader) {
      if (patchCompanyTripDetailsError?.status === 404) {
        toast.error(patchCompanyTripDetailsError.message, {
          id: "updateDetails-toast",
        });
      } else if (patchCompanyTripDetailsError?.status === 400) {
        toast.error(patchCompanyTripDetailsError.message, {
          id: "updateDetails-toast",
        });
        setLoader(true);
      } else if (patchCompanyTripDetailsError?.status === 500) {
        toast.error(patchCompanyTripDetailsError.error, {
          id: "updateDetails-toast",
        });
        setLoader(true);
      }
    }
  }, [patchCompanyTripDetailsError]);

  const weightType = [
    {
      name: "Ton",
    },
    {
      name: "Kg",
    },
    {
      name: "Litre",
    },
    {
      name: "Pieces/Boxs",
    },
  ];

  const initialValues = {
    truckId: truckNumber || data?.truckId._id || "",
    clientId: client || data?.clientId?._id || "",
    startDate: data?.startDate ? new Date(data?.startDate) : "",
    tokenNumber: data?.tokenNumber || "",
    tokenImage: data?.tokenImage || [],
    endDate: data?.endDate ? new Date(data?.endDate) : "",
    startLocation: data?.startLocation || "",
    endLocation: data?.endLocation || "",
    productType: data?.productType || "",
    startingLoad: data?.startingLoad || null,
    endingLoad: data?.endingLoad || null,
    distanceTravelled: data?.distanceTravelled || "",
    driverId: data?.driverId?._id || "",
    helperId: data?.helperId || null,
    weightType: data?.weightType || "Ton",
    ratePerWeight: data?.ratePerWeight || null,
    isAdvancePayment: data?.isAdvancePayment || null,
    advancePaymentNumber: data?.advancePaymentNumber || "",
    advancePaymentDate: data?.advancePaymentDate ? new Date(data?.advancePaymentDate) : "",
    advancePaymentCost: data?.advancePaymentCost || null
  };
  const validationSchema = Yup.object({
    truckId: Yup.string().required("This Field is Required"),
    startDate: Yup.date().required("This Field is Required"),
    tokenNumber: Yup.string().required("This Field is Required"),
    endDate: Yup.date().nullable(),
    startLocation: Yup.string().required("This Field is Required"),
    endLocation: Yup.string().required("This Field is Required"),
    productType: Yup.string().required("This Field is Required"),
    startingLoad: Yup.number().nullable(),
    endingLoad: Yup.number().nullable(),
    distanceTravelled: Yup.number().required("This Field is Required"),
    driverId: Yup.string().required("This Field is Required"),
    ratePerWeight: Yup.number().required("This Field is Required"),
  });
  const onSubmit = async (values) => {
    dispatch(postCompanyTripDetailsAction(values));
    setLoader(false);
    toast.loading("Saving Details...", { id: "addDetails-toast" });
  };
  const onUpdate = async (values) => {
    const payload = {
      id: data._id,
      body: values,
    };
    dispatch(patchCompanyTripDetailsAction(payload));
    setLoader(false);
    toast.loading("Updating Details...", { id: "updateDetails-toast" });
  };
  return (
    <div className="">
      {(getCompanyTruckListDropdownLoading ||
        getCompanyEmployeeHelperActionLoading ||
        getCompanyEmployeeDriverLoading ||
        getCompanyTripDetailsIdLoading ||
        getClientLoading) && <Backdrop />}
      <h2 className="text-2xl font-bold mb-4">Add Trip Details</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={data?._id ? onUpdate : onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values, errors }) => (
          <Form>
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 ">
              <div>
                <label htmlFor="truckId">
                  Truck Number <span>*</span>
                </label>
                <Field
                  as="select"
                  id="truckId"
                  name="truckId"
                  autoFocus
                  disabled={truckNumber}
                  className=""
                  placeholder="Truck Number"
                >
                  <option value=""></option>
                  {truckData?.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.truckNumber}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="truckId"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="clientId">Client Company Name (Optional)</label>
                <Field
                  as="select"
                  id="clientId"
                  name="clientId"
                  className=""
                  disabled={client}
                  placeholder="Client Company Name"
                >
                  <option value=""></option>
                  {clientData?.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                  <option value="Other">Other / Multiple</option>
                </Field>
                <ErrorMessage
                  name="clientId"
                  component="div"
                  className="error"
                />
              </div>
              {values.clientId === "Other" && (
                <div>
                  <label htmlFor="additionalClients">Client Name</label>
                  <Field
                    name="additionalClients"
                    placeholder={`name1, name2, .......  (add "," between Names)`}
                  />
                  <ErrorMessage name="additionalClients" component="div" />
                </div>
              )}
              <div>
                <label htmlFor="tokenNumber">
                  Token Number <span>*</span>
                </label>
                <Field
                  type="text"
                  id="tokenNumber"
                  name="tokenNumber"
                  className=""
                  disabled={Planned}
                  placeholder="Token Number"
                />
                <ErrorMessage
                  name="tokenNumber"
                  component="div"
                  className="error"
                />
              </div>

              <div className=" lg:col-span-3 sm:col-span-2 col-span-1">
                <label htmlFor="tokenImage">
                  Token Image <span>*</span>
                  <FileMultiUpload
                    setFieldValue={setFieldValue}
                    name="tokenImage"
                    values={values.tokenImage}
                  />
                </label>
              </div>
              <div>
                <label htmlFor="startDate">
                  Start Date <span>*</span>
                </label>
                <DatePicker
                  name="startDate"
                  id="startDate"
                  placeHolder="Select Date Range"
                />
                <ErrorMessage
                  name="startDate"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="endDate">End Date</label>
                <DatePicker
                  name="endDate"
                  id="endDate"
                  minDate={values.startDate}
                  placeHolder="Select Date Range"
                />
                <ErrorMessage
                  name="endDate"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="startLocation">
                  Start Location <span>*</span>
                </label>
                <Field
                  type="text"
                  id="startLocation"
                  name="startLocation"
                  className=""
                  placeholder="Start Location"
                />
                <ErrorMessage
                  name="startLocation"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="endLocation">
                  End Location <span>*</span>
                </label>
                <Field
                  type="text"
                  id="endLocation"
                  name="endLocation"
                  className=""
                  placeholder="End Location"
                />
                <ErrorMessage
                  name="endLocation"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="distanceTravelled">Distance Travelled</label>
                <Field
                  type="number"
                  id="distanceTravelled"
                  name="distanceTravelled"
                  className=""
                  placeholder="Distance Travelled"
                />
                <ErrorMessage
                  name="distanceTravelled"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="productType">
                  Product Type <span>*</span>
                </label>
                <Field
                  type="text"
                  id="productType"
                  name="productType"
                  className=""
                  placeholder="Product Type"
                />
                <ErrorMessage
                  name="productType"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="weightType">
                  Weight Type <span>*</span>
                </label>
                <Field
                  as="select"
                  id="weightType"
                  name="weightType"
                  autoFocus
                  className=""
                  placeholder="Weight Type"
                >
                  <option value=""></option>
                  {weightType?.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="truckId"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="startingLoad">Starting Load</label>
                <Field
                  type="number"
                  id="startingLoad"
                  name="startingLoad"
                  className=""
                  placeholder="Starting Load"
                />
                <ErrorMessage
                  name="startingLoad"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="endingLoad">Ending Load</label>
                <Field
                  type="number"
                  id="endingLoad"
                  name="endingLoad"
                  className=""
                  placeholder="Ending Load"
                />
                <ErrorMessage
                  name="endingLoad"
                  component="div"
                  className="error"
                />
              </div>

              <div>
                <label htmlFor="driverId">
                  Driver <span>*</span>
                </label>
                <Field
                  as="select"
                  id="driverId"
                  name="driverId"
                  className=""
                  placeholder="Driver"
                >
                  <option value="">Select</option>
                  {driverData?.map((item) => (
                    <option value={item._id}>
                      {item?.firstName}{" "}
                      {item?.lastName}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="driverId"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="helperId">Helper ID</label>
                <Field
                  as="select"
                  id="helperId"
                  name="helperId"
                  className=""
                  placeholder="Helper ID"
                >
                  <option value="" >Select</option>
                  {helperData?.filter(item => item._id !== values?.driverId)?.map((item) => (
                    <option value={item._id}>
                      {item?.firstName}{" "}
                      {item?.lastName}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="helperId"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="ratePerWeight">Rate Per Weight</label>
                <Field
                  type="number"
                  id="ratePerWeight"
                  name="ratePerWeight"
                  className=""
                  placeholder="Total Payment"
                />
                <ErrorMessage
                  name="ratePerWeight"
                  component="div"
                  className="error"
                />
              </div>
              <div className="mt-auto lg:col-span-3 sm:col-span-2 col-span-1">
                <label
                  htmlFor="isAdvancePayment"
                  className="text-black text-base font-bold"
                >
                  Advance Payment By party
                </label>
                <div className="flex gap-2 mt-3">
                  <div>

                  <label
                    htmlFor="isAdvancePaymentTrue"
                    className={`${
                      values.isAdvancePayment
                      ? "ring-2 ring-green-400"
                      : "border border-gray-300"
                    } bg-white rounded-xl  cursor-pointer hover:bg-green-100 text-black py-2.5 px-4`}
                    >
                    Yes
                  </label>
                  <Field
                    type="radio"
                    name="isAdvancePayment"
                    value={true}
                    id="isAdvancePaymentTrue"
                    className="invisible"
                    onChange={() => setFieldValue("isAdvancePayment", true)}
                    />
                    </div>
                    <div>
                  <label
                    htmlFor="isAdvancePaymentFalse"
                    className={`${
                      values.isAdvancePayment === false
                      ? "ring-2 ring-red-400"
                      : "border border-gray-300"
                    } bg-white rounded-xl  cursor-pointer hover:bg-red-100 text-black py-2.5 px-4`}
                    >
                    No
                  </label>
                  <Field
                    type="radio"
                    name="isAdvancePayment"
                    value={false}
                    id="isAdvancePaymentFalse"
                    className="invisible"
                    onChange={() => setFieldValue("isAdvancePayment", false)}
                    />
                    </div>
                </div>
              </div> 
              {values.isAdvancePayment && (
                <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 lg:col-span-3 sm:col-span-2 col-span-1">
                  <div className="">
                    <label htmlFor="advancePaymentNumber">Advance Payment Transaction Number</label>
                    <div className="w-full">
                      <Field
                        id="advancePaymentNumber"
                        type="text"
                        name="advancePaymentNumber"
                        className=""
                        placeHolder="Advance Payment Number"
                      />
                      <ErrorMessage
                        name="advancePaymentNumber"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="advancePaymentDate">Advance Payment Date</label>
                    <div className="w-full">
                      <DatePicker
                        id="advancePaymentDate"
                        name="advancePaymentDate"
                        maxDate={new Date()}
                        errorName={errors.advancePaymentTill}
                        className=""
                        placeHolder="Advance Payment Date"
                      />
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="advancePaymentCost">Advance Payment Amount</label>
                    <div className="w-full">
                      <Field
                        id="advancePaymentCost"
                        type="number"
                        name="advancePaymentCost"
                        className=""
                        placeHolder="Advance Payment Amount"
                      />
                      <ErrorMessage
                        name="advancePaymentCost"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  
                </div>
              )}     
              <button
                type="submit"
                disabled={postCompanyTripDetailsLoading || patchCompanyTripDetailsLoading}
                className="primaryButton h-fit mt-auto"
              >
                {data?._id ? "Update" : "Save"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddTrip;
