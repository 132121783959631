import { Dialog } from "../../../ui/dialog";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertInsuranceModal from "./alertInsuranceModal";
import AlertFitnessModal from "./alertFitnessModal";
import AlertPermitModal from "./alertPermitModal";
import {
  getEmployeeAlertLicenseAction,
  getTruckAlertFitnessAction,
  getTruckAlertInsuranceAction,
  getTruckAlertPermitAction,
} from "../../../redux/company/alert/action";
import { getCookies, setCookies } from "../../../auth/auth";
import AlertLicenseModal from "./alertLicenseModal";

function Alert() {
  const dispatch = useDispatch();
  const {
    getTruckAlertInsurance,
    getTruckAlertFitness,
    getTruckAlertPermit,
    getEmployeeAlertLicense,
  } = useSelector((state) => state.CompanyAlertSection);

  const [showInsuranceModal, setShowInsuranceModal] = useState(false);
  const [loaderInsurance, setLoaderInsurance] = useState(false);
  const [loaderFitness, setLoaderFitness] = useState(false);
  const [loaderPermit, setLoaderPermit] = useState(false);
  const [loaderLicense, setLoaderLicense] = useState(false);
  const [showFitnessModal, setShowFitnessModal] = useState(false);
  const [showPermitModal, setShowPermitModal] = useState(false);
  const [showLicenseModal, setShowLicenseModal] = useState(false);
  const [insuranceData, setInsuranceData] = useState();
  const [fitnessData, setFitnessData] = useState();
  const [permitData, setPermitData] = useState();
  const [licenseData, setLicenseData] = useState();

  const checkAndFetchData = async () => {
    // Check lastFetchInsurance
    const lastFetchInsurance = await getCookies("lastFetchInsurance");
    if (!(lastFetchInsurance === true)) {
      // Fetch Insurance data
      dispatch(getTruckAlertInsuranceAction());
      setLoaderInsurance(true);
      return;
    }

    // Check lastFetchFitness
    const lastFetchFitness = await getCookies("lastFetchFitness");
    if (!(lastFetchFitness === true)) {
      // Fetch Fitness data
      dispatch(getTruckAlertFitnessAction());
      setLoaderFitness(true);
      return;
    }

    // Check lastFetchPermit
    const lastFetchPermit = await getCookies("lastFetchPermit");
    if (!(lastFetchPermit === true)) {
      // Fetch Permit data
      dispatch(getTruckAlertPermitAction());
      setLoaderPermit(true);
      return;
    }

    // Check lastFetchLicense
    const lastFetchLicense = await getCookies("lastFetchLicense");
    if (!(lastFetchLicense === true)) {
      // Fetch License data
      dispatch(getEmployeeAlertLicenseAction());
      setLoaderLicense(true);
      return;
    }
  };

  useEffect(() => {
    const timer = setTimeout(async () => {
      checkAndFetchData(); // Start checking and fetching data after 1 minute
    }, 6000); // 1 minute delay

    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, []);

  // Show modals based on the fetched data
  useEffect(() => {
    const handleInsurance = async () => {
      if (
        getTruckAlertInsurance?.status === 200 &&
        getTruckAlertInsurance?.data?.length > 0 &&
        loaderInsurance === true
      ) {
        setLoaderInsurance(false);
        setInsuranceData(getTruckAlertInsurance?.data);
        setShowInsuranceModal(true);
      } else if (
        getTruckAlertInsurance?.status === 200 &&
        getTruckAlertInsurance?.data?.length === 0
      ) {
        await setCookies("lastFetchInsurance", true, 1);
        checkAndFetchData();
      }
    };
    handleInsurance();
  }, [getTruckAlertInsurance]);

  useEffect(() => {
    const handleFitness = async () => {
      if (
        getTruckAlertFitness?.status === 200 &&
        getTruckAlertFitness?.data?.length > 0 &&
        loaderFitness === true
      ) {
        setLoaderFitness(false);
        setFitnessData(getTruckAlertFitness?.data);
        setShowFitnessModal(true);
      } else if (
        getTruckAlertFitness?.status === 200 &&
        getTruckAlertFitness?.data?.length === 0
      ) {
        await setCookies("lastFetchFitness", true, 1);
        checkAndFetchData();
      }
    };
    handleFitness();
  }, [getTruckAlertFitness]);

  useEffect(() => {
    const handlePermit = async () => {
      if (
        getTruckAlertPermit?.status === 200 &&
        getTruckAlertPermit?.data?.length > 0 &&
        loaderPermit === true
      ) {
        setLoaderPermit(false);
        setPermitData(getTruckAlertPermit?.data);
        setShowPermitModal(true);
      } else if (
        getTruckAlertPermit?.status === 200 &&
        getTruckAlertPermit?.data?.length === 0
      ) {
        await setCookies("lastFetchPermit", true, 1); // Set permit as fetched
        checkAndFetchData();
      }
    };
    handlePermit();
  }, [getTruckAlertPermit]);

  useEffect(() => {
    const handleLicense = async () => {
      if (
        getEmployeeAlertLicense?.status === 200 &&
        getEmployeeAlertLicense?.data?.length > 0 &&
        loaderLicense === true
      ) {
        setLoaderLicense(false);
        setLicenseData(getEmployeeAlertLicense?.data);
        setShowLicenseModal(true);
      } else if (
        getEmployeeAlertLicense?.status === 200 &&
        getEmployeeAlertLicense?.data?.length === 0
      ) {
        await setCookies("lastFetchLicense", true, 1); // Set license as fetched
      }
    };
    handleLicense();
  }, [getEmployeeAlertLicense]);

  // Handle modal close and save the action to cookies to avoid repeated alerts
  const handleModalClose = async (alertType) => {
    if (alertType === "insurance") {
      setShowInsuranceModal(false);
      await setCookies("lastFetchInsurance", true, 1); // Set insurance as fetched
      setTimeout(async () => {
        checkAndFetchData(); // Start checking and fetching data after 1 minute
      }, 3000);
    } else if (alertType === "fitness") {
      setShowFitnessModal(false);
      await setCookies("lastFetchFitness", true, 1); // Set fitness as fetched
      setTimeout(async () => {
        checkAndFetchData(); // Start checking and fetching data after 1 minute
      }, 3000);
    } else if (alertType === "permit") {
      setShowPermitModal(false);
      await setCookies("lastFetchPermit", true, 1); // Set permit as fetched
      setTimeout(async () => {
        checkAndFetchData(); // Start checking and fetching data after 1 minute
      }, 3000);
    } else if (alertType === "license") {
      setShowLicenseModal(false);
      await setCookies("lastFetchLicense", true, 1); // Set license as fetched
    }
  };

  return (
    <div>
      {/* Insurance Modal */}
      <Dialog open={showInsuranceModal}>
        <AlertInsuranceModal
          data={insuranceData}
          setShowModal={() => handleModalClose("insurance")}
        />
      </Dialog>

      {/* Fitness Modal */}
      <Dialog open={showFitnessModal}>
        <AlertFitnessModal
          data={fitnessData}
          setShowModal={() => handleModalClose("fitness")}
        />
      </Dialog>

      {/* Permit Modal */}
      <Dialog open={showPermitModal}>
        <AlertPermitModal
          data={permitData}
          setShowModal={() => handleModalClose("permit")}
        />
      </Dialog>

      {/* License Modal */}
      <Dialog open={showLicenseModal}>
        <AlertLicenseModal
          data={licenseData}
          setShowModal={() => handleModalClose("license")}
        />
      </Dialog>
    </div>
  );
}

export default Alert;
