import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import DatePicker from "../../datepicker/datePicker";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getCompanySearchAction } from "../../../redux/company/action";
import toast from "react-hot-toast";
import {
  getAdminTruckbyCompanyIdAction,
  postAdminTruckPurchaseAction,
} from "../../../redux/admin/truck/action";
import { useParams } from "react-router-dom";

function TruckPurchase({ truckId, setshowModal }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { getCompanySearch, getCompanySearchLoading, getCompanySearchError } =
    useSelector((state) => state.Companysection);
  const {
    postAdminTruckPurchase,
    postAdminTruckPurchaseLoading,
    postAdminTruckPurchaseError,
  } = useSelector((state) => state.AdminTruckSection);

  const [loading, setLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [companyError, setCompanyError] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const validationSchema = Yup.object({
    purchaseDate: Yup.date()
      .required("Sell date is required")
      .max(new Date(), "Sell date cannot be in the future"),
    isCompany: Yup.boolean(),
    purchaseByCompanyId: Yup.string().required("Company ID is required"),
  });
  useEffect(() => {
    if (loading && !getCompanySearchLoading) {
      if (getCompanySearch.status === 200) {
        setLoading(false);
        setCompanyName(getCompanySearch?.data?.companyName);
      }
    }
  }, [loading, getCompanySearch]);
  useEffect(() => {
    if (loading && !getCompanySearchLoading) {
      if (getCompanySearchError.status === 400) {
        setLoading(false);
        setCompanyError(getCompanySearchError?.message);
      } else if (getCompanySearchError.status === 404) {
        setLoading(false);
        setCompanyError(getCompanySearchError?.message);
      } else if (getCompanySearchError.status === 500) {
        setLoading(false);
        setCompanyError("Company Not Found");
      }
    }
  }, [loading, getCompanySearchError]);

  useEffect(() => {
    if (postLoading) {
      if (postAdminTruckPurchase.status === 200) {
        toast.success(postAdminTruckPurchase.message, { id: "sellingToast" });
        dispatch(getAdminTruckbyCompanyIdAction(id));
        setPostLoading(false);
        setshowModal(false);
      }
    }
  }, [postAdminTruckPurchase]);
  useEffect(() => {
    if (postLoading) {
      if (postAdminTruckPurchaseError.status === 400) {
        setPostLoading(false);
        toast.error(postAdminTruckPurchaseError.message, { id: "sellingToast" });
      } else if (postAdminTruckPurchaseError.status === 404) {
        setPostLoading(false);
        toast.error(postAdminTruckPurchaseError.message, { id: "sellingToast" });
      } else if (postAdminTruckPurchaseError.status === 500) {
        setPostLoading(false);
        toast.error(postAdminTruckPurchaseError.error, { id: "sellingToast" });
      }
    }
  }, [postAdminTruckPurchaseError]);

  const handleCompany = (value) => {
    if (value?.length === 24) {
      dispatch(getCompanySearchAction(value));
      setLoading(true);
    } else {
      setCompanyError(null);
      setCompanyName(null);
    }
  };

  const onSubmit = (values) => {
    const payload = {
      id: truckId,
      body: values,
    };
    dispatch(postAdminTruckPurchaseAction(payload));
    setPostLoading(true);
    toast.loading("Selling Data Loading...", { id: "sellingToast" });
  };
  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle>Purchase Truck Details</DialogTitle>
      </DialogHeader>
      <div className="text-xl mt-5 font-semibold">
        Are you sure you want to{" "}
        <span className="text-red-500 font-bold">Delete</span> this Service?
      </div>
      <Formik
        initialValues={{
          purchaseDate: "",
          isCompany: false,
          purchaseByCompanyId: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, errors }) => (
          <Form className="">
            <div className="mb-4">
              <div>
                <label htmlFor="purchaseDate">Truck Purchase Date</label>
                <div className="w-full">
                  <DatePicker
                    id="purchaseDate"
                    maxDate={new Date()}
                    name="purchaseDate"
                    errorName={errors.purchaseDate}
                    className=""
                    placeHolder="Truck Purchase Date"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="purchaseByCompanyId">
                  Purchase Company ID (Find Id in Company Details)
                  <span>*</span>
                </label>
                <Field
                  type="text"
                  id="purchaseByCompanyId"
                  name="purchaseByCompanyId"
                  maxlength={24}
                  className=""
                  onChange={(e) => {
                    setFieldValue("purchaseByCompanyId", e.target.value);
                    handleCompany(e.target.value);
                  }}
                  placeholder="Company ID"
                />
                <ErrorMessage
                  name="purchaseByCompanyId"
                  component="div"
                  className="error"
                />
                {companyError && <div className="error">{companyError}</div>}
                {companyName && (
                  <div className="flex text-sm gap-1">
                    Company Name is
                    <div className="text-green-600 text-sm uppercase font-bold">
                      {" "}
                      {companyName}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <DialogFooter>
              <button
                className="secondaryButton w-fit"
                onClick={() => setshowModal(false)}
                disabled={postAdminTruckPurchaseLoading || getCompanySearchLoading}
                type="button"
              >
                Close
              </button>
              <button
                className="primaryButton bg-green-600 hover:bg-green-800 disabled:bg-green-300 w-fit"
                type="submit"
                disabled={
                  companyError !== null ||
                  values.purchaseByCompanyId.length < 24 ||
                  getCompanySearchLoading
                }
              >
                Purchase
              </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default TruckPurchase;
