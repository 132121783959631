import React, { useState } from 'react'
import CompnayListCard from './compnayListCard';

function CompanyList({data}) {
    const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = data?.filter(
    (item) =>
      item.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.gstNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.companyOwnerBy.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.phoneNumber.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.userId.email.toLowerCase().includes(searchQuery.toLowerCase()) 
  );

  return (
    <div>
      <div className="mb-4">
        <input
          placeholder="Search by Truck Number or Type"
          value={searchQuery}
          onChange={handleSearch}
          className="max-w-sm shadow-md "
        />
      </div>
      {filteredData?.length > 0 ? (
        filteredData?.map((item, index) => <CompnayListCard data={item} reroute={true} />)
      ) : (
        <p>No Companies found.</p>
      )}
    </div>
  )
}

export default CompanyList
