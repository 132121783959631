import Backdrop from "../../../../components/backdrop";
import {
  getCityAction,
  getStateAction,
} from "../../../../redux/address/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

function CompanyTabDetails({ data }) {
  const dispatch = useDispatch();
  const { getState, getStateLoading, getCity, getCityLoading } = useSelector(
    (state) => state.AddressSection
  );

  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  useEffect(() => {
    
    dispatch(getStateAction());
    cityHandler(data?.state);
  }, [data]);

  useEffect(() => {
    if (getState?.status === 200) {
      if (data?.state) {
        setState(
          getState?.data?.filter((item) => item.id == data?.state)[0].name
        );
      }
    }
  }, [getState]);
  useEffect(() => {
    if (getCity?.status === 200) {
      if (data?.city) {
        setCity(getCity?.data.filter((item) => item.id == data?.city)[0].name);
      }
    }
  }, [getCity]);
  const cityHandler = (value) => {
    dispatch(getCityAction(value));
  };

  return (
    <div>
      {(getCityLoading || getStateLoading) && <Backdrop />}
      <div className="bg-white p-4 rounded-md shadow-lg grid md:grid-cols-4 gap-5 w-full">
        <div className="">
          <label htmlFor="" className="">
            User Id
          </label>
          <p className="">{data?.userId?._id}</p>
        </div>
        <div className="">
          <label htmlFor="" className="">
            User Email
          </label>
          <p className="">{data?.userId?.email}</p>
        </div>
        <div className="">
          <label htmlFor="" className="">
            User Created Date
          </label>
          <p className="">
            {moment(data?.userId?.userCreatedDate).format("DD/MMM/YYYY")}
          </p>
        </div>
        <div className="">
          <label htmlFor="" className="">
            User Type
          </label>
          <p className="">{data?.userId?.userType}</p>
        </div>
        <div className="">
          <label htmlFor="" className="">
            Company Id
          </label>
          <p className="">{data?._id}</p>
        </div>
        <div className="">
          <label htmlFor="" className="">
            Company Name
          </label>
          <p className="">{data?.companyName}</p>
        </div>
        <div className="">
          <label htmlFor="" className="">
            Company Owner Name
          </label>
          <p className="">{data?.companyOwnerBy}</p>
        </div>
        <div className="">
          <label htmlFor="" className="">
            Company Account Number
          </label>
          <p className="">{data?.accountNumber}</p>
        </div>
        <div className="">
          <label htmlFor="" className="">
            Company Phone Number
          </label>
          <p className="">{data?.phoneNumber}</p>
        </div>
        <div className="">
          <label htmlFor="" className="">
            Company Alternate Phone Number
          </label>
          <p className="">{data?.alternateMobileNumber}</p>
        </div>
        <div className="">
          <label htmlFor="" className="">
            Company GST Number
          </label>
          <p className="">{data?.gstNumber}</p>
        </div>
        <div className="">
          <label htmlFor="" className="">
            Company Address
          </label>
          <p className="">{data?.address}</p>
        </div>
        <div className="">
          <label htmlFor="" className="">
            Company City
          </label>
          <p className="">{city}</p>
        </div>
        <div className="">
          <label htmlFor="" className="">
            Company State
          </label>
          <p className="">{data?.country}</p>
        </div>

        <div className="">
          <label htmlFor="" className="">
            Company Start Date
          </label>
          <p className="">
            {moment(data?.companyStartDate).format("DD/MMM/YYYY")}
          </p>
        </div>
        <div className="">
          <label htmlFor="" className="">
            Company Created Date
          </label>
          <p className="">
            {moment(data?.createdAt).format("DD/MMM/YYYY")}
          </p>
        </div>
        <div className="">
          <label htmlFor="" className="">
            Company Updated Date
          </label>
          <p className="">
            {moment(data?.updatedAt).format("DD/MMM/YYYY")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CompanyTabDetails;
