import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import DatePicker from "../../datepicker/datePicker";
import FileMultiUpload from "../../fileupload/fileMuiltipleUpload";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { getTruckInsuranceAction, getTruckInsuranceByTruckIdAction, patchTruckInsuranceAction } from "../../../redux/company/truck/action";

function UpdateInsurance({ truckId, setShowModal, truckNumber }) {
  const dispatch = useDispatch();
  const {
    patchTruckInsurance,
    patchTruckInsuranceLoading,
    patchTruckInsuranceError,
  } = useSelector((state) => state.Companytrucksection);

  const [loader, setLoader] = useState(false);
  const initialValues = {
    insuranceNumber: "",
    insuranceCost: null,
    insuranceCompanyName: "",
    insuranceImage: [],
    insuranceTill: "",
  };

  const validationSchema = Yup.object({
    insuranceNumber: Yup.string().required("This is required"),
    insuranceCost: Yup.number().required("This is required"),
    insuranceCompanyName: Yup.string().required("This is required"),
    insuranceTill: Yup.date().required("This is required"),
  });

  useEffect(() => {
    if (loader) {
      if (patchTruckInsurance?.status === 200) {
        toast.success(patchTruckInsurance?.data, { id: "insurance_toast" });
        setLoader(false);
        dispatch(getTruckInsuranceAction());
        dispatch(getTruckInsuranceByTruckIdAction(truckId));
        setShowModal(false);
      }
    }
  }, [patchTruckInsurance]);
  useEffect(() => {
    if (loader) {
      if (patchTruckInsuranceError?.status === 400) {
        toast.error(patchTruckInsuranceError?.data, { id: "insurance_toast" });
        setLoader(false);
      }
      if (patchTruckInsuranceError?.status === 404) {
        toast.error(patchTruckInsuranceError?.data, { id: "insurance_toast" });
        setLoader(false);
      }
      if (patchTruckInsuranceError?.status === 500) {
        toast.error(patchTruckInsuranceError?.error, { id: "insurance_toast" });
        setLoader(false);
      }
    }
  }, [patchTruckInsuranceError]);

  const onSubmit = async (values) => {
    console.log(values)
    const payload = {
      id: truckId,
      body: values,
    };
    dispatch(patchTruckInsuranceAction(payload));
    toast.loading("Saving....", { id: "insurance_toast" });
    setLoader(true);
  };
  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle className="flex gap-1 ">
          Update insurance of{" "}
          <div className="uppercase font-black underline">{truckNumber}</div>
        </DialogTitle>
      </DialogHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, isSubmitting, errors }) => (
          <Form>
            <div className="grid grid-cols-2 gap-4 overflow-y-auto max-h-[60vh] py-4 ">
              <div className="">
                {console.log(errors)}
                <label htmlFor="insuranceNumber">Insurance Policy Number</label>
                <div className="w-full">
                  <Field
                    id="insuranceNumber"
                    type="text"
                    name="insuranceNumber"
                    className=""
                    placeHolder="Insurance Policy Number"
                  />
                  <ErrorMessage
                    name="insuranceNumber"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="">
                <label htmlFor="insuranceCompanyName">
                  Insurance Company Name
                </label>
                <div className="w-full">
                  <Field
                    id="insuranceCompanyName"
                    type="text"
                    name="insuranceCompanyName"
                    className=""
                    placeHolder="Insurance Company Name"
                  />
                  <ErrorMessage
                    name="insuranceCompanyName"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="">
                <label htmlFor="insuranceTill">Insurance End Date</label>
                <div className="w-full">
                  <DatePicker
                    id="insuranceTill"
                    name="insuranceTill"
                    className=""
                    placeHolder="Insurance End Date"
                  />
                  <ErrorMessage
                    name="insuranceTill"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="">
                <label htmlFor="insuranceCost">Insurance Cost</label>
                <div className="w-full">
                  <Field
                    id="insuranceCost"
                    type="number"
                    name="insuranceCost"
                    className=""
                    placeHolder="Insurance Cost"
                  />
                  <ErrorMessage
                    name="insuranceCost"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className=" col-span-2">
                <label
                  htmlFor="insuranceImage"
                  className="text-black text-sm font-bold"
                >
                  Insurance Image
                </label>
                <FileMultiUpload
                  setFieldValue={setFieldValue}
                  values={values.insuranceImage}
                  name="insuranceImage" // disabled={readOnly} values={values}
                />
              </div>
            </div>
            <DialogFooter className="md:gap-5 col-span-2">
              <button
                className="ghostButton md:w-fit capitalize"
                type="button"
                onClick={() => setShowModal(false)}
                disabled={patchTruckInsuranceLoading}
              >
                Close
              </button>

              <button
                type="submit"
                className="primaryButton md:w-fit capitalize"
                disabled={patchTruckInsuranceLoading}
              >
                Save
              </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default UpdateInsurance;
