import React from "react";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";
import DatePicker from "../../datepicker/datePicker";
import { Field, Form } from "formik";
import { IoClose } from "react-icons/io5";


function TripFilter({
  truckParams,
  drivers,
  helpers,
  handleClearFilters,
  truck,
  values,
  handleSubmit,
  setShowFilters,
  resetForm
}) {

  const handleClear = () => {
    resetForm()
    handleClearFilters()
  }
  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle>
          <div className="flex justify-between">
            <div>Trip Filter</div>
            <button onClick={()=>setShowFilters(false)}><IoClose /></button>
          </div>
        </DialogTitle>
      </DialogHeader>
      <div className="grid grid-cols-2 gap-4 overflow-y-auto max-h-[60vh] py-4">
        <div>
          <label htmlFor="startLocation">Start Location</label>
          <Field
            name="startLocation"
            id="startLocation"
            placeholder="Filter by Start Location"
            className="shadow-md"
          />
        </div>
        <div>
          <label htmlFor="endLocation">End Location</label>
          <Field
            name="endLocation"
            id="endLocation"
            placeholder="Filter by End Location"
            className="shadow-md"
          />
        </div>
        {/* {!truckParams && (
          <div>
            <label htmlFor="endLocation">Truck</label>
            <Field name="truckId" as="select" className="shadow-md">
              <option value="">Filter by Truck</option>
              {truck?.map((item) => (
                <option value={item._id}>{item.truckNumber}</option>
              ))}
            </Field>
          </div>
        )} */}
        <div>
          <label htmlFor="driverId">Driver</label>
          <Field
            name="driverId"
            id="driverId"
            as="select"
            className="shadow-md"
          >
            <option value="">Filter by Driver</option>
            {drivers.map((driver) => (
              <option key={driver._id} value={driver._id}>
                {driver.firstName} {driver.lastName}
              </option>
            ))}
          </Field>
        </div>
        <div>
          <label htmlFor="helperId">Helper</label>
          <Field
            name="helperId"
            id="helperId"
            as="select"
            className="shadow-md"
          >
            <option value="">Filter by Helper</option>
            {helpers?.map((helper) => (
              <option key={helper._id} value={helper._id}>
                {helper.firstName}
                {helper.lastName}
              </option>
            ))}
          </Field>
        </div>
        <div>
          <div>
            <label htmlFor="startDate">Date From</label>
            <DatePicker
              name="startDate"
              id="startDate"
              startDate={new Date()}
              endDate={null}
              selectsStart
              placeHolder="Select Date Range"
            />
          </div>
        </div>
        <div>
          <label htmlFor="endDate">Date To</label>
          <DatePicker
            name="endDate"
            id="endDate"
            startDate={new Date()}
            endDate={null}
            selectsEnd
            minDate={values.startDate}
            placeHolder="Select Date Range"
          />
        </div>
      </div>
      <DialogFooter>
        <button type="submit" className="primaryButton" onClick={handleSubmit}>
          Apply Filters
        </button>
        <button
          type="button"
          className="ghostButton"
          onClick={handleClear}
        >
          Clear Filters
        </button>
      </DialogFooter>
    </DialogContent>
  );
}

export default TripFilter;
