// sagas.js
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import api from "../../../../auth/api";
import {
  GET_EMI,
  POST_EMI,
  DELETE_EMI,
  GET_EMI_BY_ID,
} from "../../../actions";
import {
  getEmiSuccess,
  getEmiError,
  postEmiSuccess,
  postEmiError,
  deleteEmiSuccess,
  deleteEmiError,
  getEmiByIdSuccess,
  getEmiByIdError,
} from "./action";

const getEmiAsync = async (payload) => {
  try {
    const response = await api.get(`/company/truck/emi/get/${payload.payload}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* getEmi(action) {
  try {
    const data = yield call(getEmiAsync, action);
    yield put(getEmiSuccess(data));
  } catch (error) {
    yield put(getEmiError(error));
  }
}

export function* watchGetEmi() {
  yield takeEvery(GET_EMI, getEmi);
}


const getEmiByIdAsync = async (userId) => {
  try {
    const response = await api.get(`/company/truck/emi/getById/${userId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* getEmiById(action) {
  try {
    const data = yield call(getEmiByIdAsync, action.payload);
    yield put(getEmiByIdSuccess(data));
  } catch (error) {
    yield put(getEmiByIdError(error));
  }
}

export function* watchGetEmiById() {
  yield takeEvery(GET_EMI_BY_ID, getEmiById);
}

const postEmiAsync = async (data) => {
  try {
    const response = await api.post("/company/truck/emi/add", data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* postEmi(action) {
  try {
    const data = yield call(postEmiAsync, action.payload);
    yield put(postEmiSuccess(data));
  } catch (error) {
    yield put(postEmiError(error));
  }
}

export function* watchPostEmi() {
  yield takeEvery(POST_EMI, postEmi);
}


const deleteEmiAsync = async (id) => {
  try {
    const response = await api.delete(`/company/truck/emi/delete/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* deleteEmi(action) {
  try {
    const data = yield call(deleteEmiAsync, action.payload);
    yield put(deleteEmiSuccess(data));
  } catch (error) {
    yield put(deleteEmiError(error));
  }
}

export function* watchDeleteEmi() {
  yield takeEvery(DELETE_EMI, deleteEmi);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetEmi),
    fork(watchGetEmiById),
    fork(watchPostEmi),
    fork(watchDeleteEmi),
  ]);
}
