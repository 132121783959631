import api from "../../../auth/api.jsx";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  DELETE_COMPANY_SERVICE,
  GET_COMPANY_SERVICE,
  GET_COMPANY_SERVICE_FILTER,
  GET_COMPANY_SERVICE_ID,
  PATCH_COMPANY_SERVICE,
  POST_COMPANY_SERVICE,
} from "../../actions.js";
import {
  deleteCompanyServiceError,
  deleteCompanyServiceSuccess,
  getCompanyServiceError,
  getCompanyServiceFilterError,
  getCompanyServiceFilterSuccess,
  getCompanyServiceIdError,
  getCompanyServiceIdSuccess,
  getCompanyServiceSuccess,
  patchCompanyServiceError,
  patchCompanyServiceSuccess,
  postCompanyServiceError,
  postCompanyServiceSuccess,
} from "./action.js";



// Async function to handle API call
const getCompanyServiceAsync = async (payload) => {
  try {
    const response = await api.get(`/company/service/getAll`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyServiceCall(action) {
  try {
    const data = yield call(getCompanyServiceAsync, action); // Call async function with action payload
    yield put(getCompanyServiceSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyServiceError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyServiceData() {
  yield takeEvery(GET_COMPANY_SERVICE, getCompanyServiceCall);
}
// Async function to handle API call
const getCompanyServiceIdAsync = async (payload) => {
  try {
    const response = await api.get(`/company/service/${payload.payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
   
    throw error.response.data; // Throw other errors to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyServiceIdCall(action) {
  try {
    const data = yield call(getCompanyServiceIdAsync, action); // Call async function with action payload
    yield put(getCompanyServiceIdSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyServiceIdError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyServiceIdData() {
  yield takeEvery(GET_COMPANY_SERVICE_ID, getCompanyServiceIdCall);
}

// Async function to handle API call
const getCompanyServiceFilterAsync = async (payload) => {
  try {
    const response = await api.post(`/company/service/filter`, (payload.payload));
    return response.data; // Return the data from the API response
  } catch (error) {
   
    throw error.response.data; // Throw other errors to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyServiceFilterCall(action) {
  try {
    const data = yield call(getCompanyServiceFilterAsync, action); // Call async function with action payload
    yield put(getCompanyServiceFilterSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyServiceFilterError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyServiceFilterData() {
  yield takeEvery(GET_COMPANY_SERVICE_FILTER, getCompanyServiceFilterCall);
}


// Async function to handle API call
const postCompanyServiceAsync = async (payload) => {
  try {
    const response = await api.post(`/company/service/add`, payload.payload);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* postCompanyServiceCall(action) {
  try {
    const data = yield call(postCompanyServiceAsync, action); // Call async function with action payload
    yield put(postCompanyServiceSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postCompanyServiceError(error)); // Dispatch error action with error object
  }
}
export function* watchPostCompanyServiceData() {
  yield takeEvery(POST_COMPANY_SERVICE, postCompanyServiceCall);
}
// Async function to handle API call
const patchCompanyServiceAsync = async (payload) => {
  try {
    const response = await api.patch(
      `/company/service/update/${payload.payload.id}`,
      payload.payload.body
    );
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* patchCompanyServiceCall(action) {
  try {
    const data = yield call(patchCompanyServiceAsync, action); // Call async function with action payload
    yield put(patchCompanyServiceSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(patchCompanyServiceError(error)); // Dispatch error action with error object
  }
}
export function* watchPatchCompanyServiceData() {
  yield takeEvery(PATCH_COMPANY_SERVICE, patchCompanyServiceCall);
}
// Async function to handle API call
const deleteCompanyServiceAsync = async (payload) => {
  try {
    console.log(payload);
    const response = await api.delete(
      `/company/service/${payload.payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* deleteCompanyServiceCall(action) {
  try {
    const data = yield call(deleteCompanyServiceAsync, action); // Call async function with action payload
    yield put(deleteCompanyServiceSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(deleteCompanyServiceError(error)); // Dispatch error action with error object
  }
}
export function* watchDeleteCompanyServiceData() {
  yield takeEvery(DELETE_COMPANY_SERVICE, deleteCompanyServiceCall);
}

export default function* rootSaga() {
  yield all([

    fork(watchGetCompanyServiceData),
    fork(watchGetCompanyServiceIdData),
    fork(watchGetCompanyServiceFilterData),
    fork(watchPostCompanyServiceData),
    fork(watchPatchCompanyServiceData),
    fork(watchDeleteCompanyServiceData),
  ]);
}
