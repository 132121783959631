import React, { useState } from "react";
import { Link } from "react-router-dom";
import DiscountDelete from "./discountDelete";
import { Dialog } from "../../../ui/dialog";
import moment from "moment";

function DiscountListCard({ data }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <div
      key={data?._id}
      className="my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl"
    >
      <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between self-center">
        <div className="col-span-2 md:col-span-4 text-xl text-blue-600 font-semibold">
          {data?.extraDiscountName}
        </div>
        <div className="md:text-left">
          <h3 className="textgrey text-xs">Discount Percentage</h3>
          <p>{data?.extraDiscountPercent}%</p>
        </div>
        <div className="md:text-left">
          <h3 className="textgrey text-xs">Condition</h3>
          <p>{data?.condition}</p>
        </div>
        <div className="md:text-left">
          <h3 className="textgrey text-xs">Validation</h3>
          <p>{data?.minValue}-{data?.maxValue}</p>
        </div>
        <div className="md:text-left">
          <h3 className="textgrey text-xs">Dates</h3>
          <p>{moment(data?.validFrom).format("DD / MMM / YYYY")} - {moment(data?.validTo).format("DD / MMM / YYYY")}</p>
        </div>
        {/* <div> */}
        <Link
          to={`addDiscount?id=${data?._id}`}
          className="primaryButton bg-yellow-600 hover:bg-yellow-800 text-center"
        >
          Edit Discount
        </Link>
        {/* </div> */}
        <div>
          <Dialog open={showModal}>
            <button
              onClick={() => setShowModal(true)}
              className={`primaryButton bg-red-500 hover:bg-red-800 `}
            >
              Delete
            </button>
            <DiscountDelete id={data._id} setShowModal={setShowModal} />
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default DiscountListCard;
