import { Dialog } from "../../../../ui/dialog";
import moment from "moment";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import EmployeeSalaryDelete from "./employeeSalaryDelete";
import { TfiTrash } from "react-icons/tfi";
import { FaRegEdit } from "react-icons/fa";

function EmployeeSalaryCard({ data, payButton }) {
  const [showSalaryDeleteModal, setShowSalaryDeleteModal] = useState(false);
  const navigate = useNavigate();
  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(amount)
      : "₹0.00";
  return (
    <div
      key={data._id}
      className="bg-white p-4 rounded-md shadow-lg mt-4  w-full text-xs"
    >
      <Dialog open={showSalaryDeleteModal}>
        <EmployeeSalaryDelete
          id={data?._id}
          setShowModal={setShowSalaryDeleteModal}
        />
      </Dialog>
      <div className=" grid xl:grid-cols-6 lg:grid-cols-4 grid-cols-2 grid-rows-2  lg:gap-x-20 gap-x-9  lg:gap-y-6  gap-y-4">
        <div className="lg:border-r lg:border-r-[#F1F2F6] lg:border-b-0 border-b border-b-[#F1F2F6] row-span-full flex flex-col gap-2 lg:col-span-1 col-span-2 pb-5 lg:text-left text-center  relative">
          <label htmlFor="">Salary Month / Year</label>

          <p className=" textPrimary uppercase ">
            {moment(data.salaryMonth).format("MMM / YYYY")}
          </p>

          <div className="flex md:hidden ml-auto absolute z-30 left-0">
            <button
              type="button"
              onClick={() => setShowSalaryDeleteModal(true)}
              className="ghostButton text-[#DC1919] font-semibold  flex gap-1 hover:text-red-700 disabled:text-red-200"
            >
              <TfiTrash className="text-xl" />
            </button>
          </div>
          <div className=" flex flex-col gap-3 md:hidden ml-auto absolute z-30 right-0">
            <button
              type="button"
              className="ghostButton font-semibold  flex ml-auto"
              onClick={() =>
                navigate(
                  `addSalary?id=${data._id}&employeeId=${data?.employeeId}`
                )
              }
            >
              <FaRegEdit className="text-xl" />
            </button>
          </div>
        </div>
        <div>
          <label htmlFor="">Total Pay</label>
          <p className="textSecondary">{formatAmount(data?.totalPay)}</p>
        </div>
        <div>
          <label htmlFor="">Extra Pay/ Deduction</label>
          <p className="textSecondary">{formatAmount(data?.addOns)}</p>
        </div>
        <div>
          <label htmlFor="">Payment Date</label>
          <p className="textSecondary">
            {moment(data.paymentDate).format("DD/MMM/YYYY")}
          </p>
        </div>
        <div className=" gap-6 flex-wrap lg:flex hidden md:col-start-6">
          <div className="  ">
            <button
              type="button"
              className="ghostButton font-semibold  flex gap-1"
              onClick={() =>
                navigate(`addSalary?id=${data._id}&employeeId=${data?.employeeId}`)
              }
            >
              <FaRegEdit className="text-xl" />
              Edit
            </button>
          </div>

          <div className="lg:flex hidden">
            <button
              type="button"
              onClick={() => setShowSalaryDeleteModal(true)}
              className="ghostButton text-[#DC1919] font-semibold  flex gap-1 hover:text-red-700 disabled:text-red-200"
            >
              <TfiTrash className="text-xl" /> Delete
            </button>
          </div>
        </div>
        <div className="col-span-2">
          <label htmlFor="">Description</label>
          <p className="textSecondary">
            {data?.description ? data?.description : "--"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default EmployeeSalaryCard;
// <div key={data._id}>
//       <div className="grid grid-cols-12 gap-x-7 my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl">
//         <div className="mb-5 text-center md:text-left lg:col-span-10 col-span-6 row-span-1 self-center lg:self-auto">
//           <p className="text-blue-700 font-semibold text-xl uppercase">
//             Salary for {moment(data.salaryMonth).format("MMMM YYYY")}
//           </p>
//         </div>

//         <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center">
//           <div className="md:text-left">
//             <h3 className="textgrey text-xs">Total Pay</h3>
//             <p>₹{data.totalPay}</p>
//           </div>
//           <div>
//             <h3 className="textgrey text-xs md:text-center">Add-Ons</h3>
//             <p className="md:text-center">₹{data.addOns}</p>
//           </div>
//           <div>
//             <h3 className="textgrey text-xs md:text-center">
//               Payment Date
//             </h3>
//             <p className="md:text-center">
//               {moment(data.paymentDate).format("DD/MMM/YYYY")}
//             </p>
//           </div>
//           <div>
//             <h3 className="textgrey text-xs md:text-right">Payment Type</h3>
//             <p className="md:text-right">
//               <div className="">{data.paymentType}</div>
//             </p>
//           </div>
//             <div className="flex">
//               <Link
//                 to={`addSalary?id=${data._id}&employeeId=${data?.employeeId}`}
//                 className="primaryButton bg-yellow-600 hover:bg-yellow-800 w-full text-center"
//               >
//                 Edit Salary
//               </Link>
//             </div>
//           {/* {payButton && ( */}
//             {/* <div className="flex"> */}
//               <Dialog open={showSalaryDeleteModal}>
//                 <button
//                   onClick={() => setShowSalaryDeleteModal(true)}
//                   className="primaryButton bg-red-600 hover:bg-red-800"
//                 >
//                   Delete Salary
//                 </button>
//                   <EmployeeSalaryDelete
//                     id={data?._id}
//                     setShowModal={setShowSalaryDeleteModal}
//                   />
//               </Dialog>
//             {/* </div> */}
//           {/* )} */}
//         </div>
//       </div>
//     </div>
