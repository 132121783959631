import { GET_COMPANY_BY_COMPANYID, GET_COMPANY_BY_COMPANYID_ERROR, GET_COMPANY_BY_COMPANYID_SUCCESS, GET_COMPANY_LIST, GET_COMPANY_LIST_ERROR, GET_COMPANY_LIST_SUCCESS, GET_COMPANY_LOGIN_STAMPS, GET_COMPANY_LOGIN_STAMPS_ERROR, GET_COMPANY_LOGIN_STAMPS_SUCCESS, GET_COMPANY_TRUCK_BY_COMPANYTRUCKID, GET_COMPANY_TRUCK_BY_COMPANYTRUCKID_ERROR, GET_COMPANY_TRUCK_BY_COMPANYTRUCKID_SUCCESS, POST_ADMIN_FORGET_PASSWORD, POST_ADMIN_FORGET_PASSWORD_ERROR, POST_ADMIN_FORGET_PASSWORD_SUCCESS, POST_BLOCKED, POST_BLOCKED_ERROR, POST_BLOCKED_SUCCESS } from "../../actions";

export const getCompanyListAction = (info) => ({
    type: GET_COMPANY_LIST,
    payload: info,
  });
  export const getCompanyListSuccess = (info) => ({
    type: GET_COMPANY_LIST_SUCCESS,
    payload: info,
  });
  export const getCompanyListError = (error) => ({
    type: GET_COMPANY_LIST_ERROR,
    payload: error,
  });
export const getCompanyTruckByCompanyTruckIdAction = (info) => ({
    type: GET_COMPANY_TRUCK_BY_COMPANYTRUCKID,
    payload: info,
  });
  export const getCompanyTruckByCompanyTruckIdSuccess = (info) => ({
    type: GET_COMPANY_TRUCK_BY_COMPANYTRUCKID_SUCCESS,
    payload: info,
  });
  export const getCompanyTruckByCompanyTruckIdError = (error) => ({
    type: GET_COMPANY_TRUCK_BY_COMPANYTRUCKID_ERROR,
    payload: error,
  });
export const getCompanyByCompanyIdAction = (info) => ({
    type: GET_COMPANY_BY_COMPANYID,
    payload: info,
  });
  export const getCompanyByCompanyIdSuccess = (info) => ({
    type: GET_COMPANY_BY_COMPANYID_SUCCESS,
    payload: info,
  });
  export const getCompanyByCompanyIdError = (error) => ({
    type: GET_COMPANY_BY_COMPANYID_ERROR,
    payload: error,
  });
export const getCompanyLoginStampAction = (info) => ({
    type: GET_COMPANY_LOGIN_STAMPS,
    payload: info,
  });
  export const getCompanyLoginStampSuccess = (info) => ({
    type: GET_COMPANY_LOGIN_STAMPS_SUCCESS,
    payload: info,
  });
  export const getCompanyLoginStampError = (error) => ({
    type: GET_COMPANY_LOGIN_STAMPS_ERROR,
    payload: error,
  });
export const postBlockedAction = (info) => ({
    type: POST_BLOCKED,
    payload: info,
  });
  export const postBlockedSuccess = (info) => ({
    type: POST_BLOCKED_SUCCESS,
    payload: info,
  });
  export const postBlockedError = (error) => ({
    type: POST_BLOCKED_ERROR,
    payload: error,
  });
export const postAdminForgetPasswordAction = (info) => ({
    type: POST_ADMIN_FORGET_PASSWORD,
    payload: info,
  });
  export const postAdminForgetPasswordSuccess = (info) => ({
    type: POST_ADMIN_FORGET_PASSWORD_SUCCESS,
    payload: info,
  });
  export const postAdminForgetPasswordError = (error) => ({
    type: POST_ADMIN_FORGET_PASSWORD_ERROR,
    payload: error,
  });