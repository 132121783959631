import React, { useEffect, useState } from "react";
import PaginationPackage from "../../paginationPackage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import usePagination from "headless-pagination-react";
import { getInsuranceStatsAction } from "../../../redux/company/finance/action";
import { FiDownload } from "react-icons/fi";
import { AiOutlineCloseSquare } from "react-icons/ai";
import moment from "moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const convertFilterToQueryString = (filter) => {
  return Object.keys(filter)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(filter[key])}`
    )
    .join("&");
};

function InsuranceSection({ show, setShow }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams(); // Search params hook

  const { insuranceStats } = useSelector((state) => state.StatsReducer);

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(1000);
  const [maxLinks, setMaxLinks] = useState(10);
  const [perPage, setperPage] = useState(5);
  const { links, from, to, setPage, onNext, onPrevious, page } = usePagination({
    totalItems,
    perPage,
    maxLinks,
  });

  useEffect(() => {
    if (insuranceStats?.status === 200) {
      setData(insuranceStats?.data);
      setTotalItems(insuranceStats?.data?.insurances?.length);
    }
  }, [insuranceStats]);

  useEffect(() => {
    const filter = {
      startDate: "",
      endDate: "",
    };
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    const truckId = searchParams.get("truckId");
    const isTruck = searchParams.get("allTruck") === "true";

    // Add date filters if they are present
    if (startDate) {
      filter.startDate = new Date(startDate).toISOString(); // Convert date to ISO string
    }
    if (endDate) {
      filter.endDate = new Date(endDate).toISOString(); // Convert date to ISO string
    }

    // Add truckId if it's not 'allTruck'
    if (!isTruck && truckId) {
      filter.truckId = truckId;
    }
    // Convert filter object to query string
    const queryString = convertFilterToQueryString(filter);

    dispatch(getInsuranceStatsAction(queryString));
  }, [searchParams]);

  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 2,
        }).format(amount)
      : "₹0.00";
      const handleExport = () => {
        if (!data || !data?.insurances) {
          console.error("Data or insurances is undefined");
          return;
        }
      
        // Flatten insurance data
        const combinedData = [
          ...(data?.insurances?.map((item) => ({
            "Insurance Policy No": item?.insuranceNumber || "-",
            "Insurance Till Date": item?.insuranceTill
              ? moment(item?.insuranceTill).format("DD / MMM / YYYY")
              : "-",
            "Insurance Company Name": item?.insuranceCompanyName || "-",
            "Truck Number": item?.truckId ? item?.truckId.truckNumber : "-",
            "Insurance Cost": item?.insuranceCost ? formatAmount(item?.insuranceCost) : "-",
          })) || []),
        ];
      
        // Find last `insuranceTill` if startDate is not provided
        const lastInsuranceTillDate = data?.insurances?.length
          ? moment(
              data.insurances[data.insurances.length - 1]?.insuranceTill || new Date()
            ).format("DD/MMM/YYYY")
          : "";
      
        // Determine the start and end date for the file name
        const startDate = searchParams.get("startDate")
          ? moment(searchParams.get("startDate")).format("DD/MMM/YYYY")
          : lastInsuranceTillDate;
        const endDate = searchParams.get("endDate")
          ? moment(searchParams.get("endDate")).format("DD/MMM/YYYY")
          : "";
      
        // Convert to worksheet
        const ws = XLSX.utils.json_to_sheet(combinedData);
      
        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Insurance Data");
      
        // Generate Excel file and trigger download
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const file = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
      
        // Use start and end date for file name, defaulting to lastInsuranceTillDate if needed
        saveAs(
          file,
          `insurance_${startDate}-${
            endDate ? endDate : moment(new Date()).format("DD/MMM/YYYY")
          }.xlsx`
        );
      };
      
  return (
    <>
      <div className=" bg-white mt-6 shadow-lg rounded-sm relative">
        <div className="p-4 text-[#64709B] font-semibold flex justify-between">
          <div className="cursor-pointer" onClick={() => setShow("insurance")}>
            Insurance
          </div>
        </div>
        <div className="w-full h-0.5 bg-[#F1F2F6]" />
        <div className="p-4 text-[#303750] text-2xl font-bold flex gap-2">
          {formatAmount(data?.totalInsuranceCost)}{" "}
          <div className="text-base font-medium mt-auto">INR</div>
        </div>
        {show == "insurance" && <div className="arrow-down"></div>}
      </div>
      {show == "insurance" && (
        <div className="col-span-4 order-last">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
              <thead className="ltr:text-left rtl:text-right">
                <tr>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-left">
                    Insurance Policy No
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Insurance Till
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Insurance Company Name
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Truck Number
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Cost
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium  justify-end flex gap-3">
                    <FiDownload className="text-lg ghostButton cursor-pointer"  onClick={() => handleExport()} />
                    <AiOutlineCloseSquare
                      onClick={() => setShow("")}
                      className="text-red-500 text-xl cursor-pointer"
                    />
                  </th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200">
                {data?.insurances?.slice(from - 1, to)?.map((item) => (
                  <tr>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                      {item.insuranceNumber ? item.insuranceNumber : "-"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center">
                      {item.insuranceTill
                        ? moment(item.insuranceTill).format("DD / MMM / YYYY")
                        : "-"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center">
                      {item.insuranceCompanyName
                        ? item.insuranceCompanyName
                        : "-"}
                    </td>
                    <td
                      className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center uppercase"
                    >
                      {item.truckId ? item.truckId.truckNumber : "-"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center">
                      {item.insuranceCost
                        ? formatAmount(item.insuranceCost)
                        : "-"}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colspan="8">
                    <PaginationPackage
                      links={links}
                      onNext={onNext}
                      onPrevious={onPrevious}
                      from={from}
                      to={to}
                      totalItems={totalItems}
                      perPage={perPage}
                      totalPages={page}
                      setPage={setPage}
                      setperPage={setperPage}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default InsuranceSection;
