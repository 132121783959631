import { DELETE_ADMIN_EXPENSE, DELETE_ADMIN_EXPENSE_ERROR, DELETE_ADMIN_EXPENSE_SUCCESS, GET_ADMIN_EXPENSE_BY_ID, GET_ADMIN_EXPENSE_BY_ID_ERROR, GET_ADMIN_EXPENSE_BY_ID_SUCCESS, GET_ADMIN_EXPENSES_LIST, GET_ADMIN_EXPENSES_LIST_ERROR, GET_ADMIN_EXPENSES_LIST_SUCCESS, PATCH_ADMIN_EXPENSE, PATCH_ADMIN_EXPENSE_ERROR, PATCH_ADMIN_EXPENSE_SUCCESS, POST_ADMIN_EXPENSE, POST_ADMIN_EXPENSE_ERROR, POST_ADMIN_EXPENSE_SUCCESS } from "../../actions";

const INIT_STATE = {
  getAdminExpensesList: {},
  getAdminExpensesListLoading: false,
  getAdminExpensesListError: "",
  getAdminExpenseById: {},
  getAdminExpenseByIdLoading: false,
  getAdminExpenseByIdError: "",
  postAdminExpense: {},
  postAdminExpenseLoading: false,
  postAdminExpenseError: "",
  patchAdminExpense: {},
  patchAdminExpenseLoading: false,
  patchAdminExpenseError: "",
  deleteAdminExpense: {},
  deleteAdminExpenseLoading: false,
  deleteAdminExpenseError: "",
};

const AdminExpenseSection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ADMIN_EXPENSES_LIST:
      return {
        ...state,
        getAdminExpensesList: {},
        getAdminExpensesListLoading: true,
      };
    case GET_ADMIN_EXPENSES_LIST_SUCCESS:
      return {
        ...state,
        getAdminExpensesList: action.payload,
        getAdminExpensesListLoading: false,
        getAdminExpensesListError: "",
      };

    case GET_ADMIN_EXPENSES_LIST_ERROR:
      return {
        ...state,
        getAdminExpensesListLoading: false,
        getAdminExpensesListError: action.payload,
      };
    case GET_ADMIN_EXPENSE_BY_ID:
      return {
        ...state,
        getAdminExpenseById: {},
        getAdminExpenseByIdLoading: true,
      };
    case GET_ADMIN_EXPENSE_BY_ID_SUCCESS:
      return {
        ...state,
        getAdminExpenseById: action.payload,
        getAdminExpenseByIdLoading: false,
        getAdminExpenseByIdError: "",
      };

    case GET_ADMIN_EXPENSE_BY_ID_ERROR:
      return {
        ...state,
        getAdminExpenseByIdLoading: false,
        getAdminExpenseByIdError: action.payload,
      };
    case POST_ADMIN_EXPENSE:
      return {
        ...state,
        postAdminExpense: {},
        postAdminExpenseLoading: true,
      };
    case POST_ADMIN_EXPENSE_SUCCESS:
      return {
        ...state,
        postAdminExpense: action.payload,
        postAdminExpenseLoading: false,
        postAdminExpenseError: "",
      };

    case POST_ADMIN_EXPENSE_ERROR:
      return {
        ...state,
        postAdminExpenseLoading: false,
        postAdminExpenseError: action.payload,
      };
    case PATCH_ADMIN_EXPENSE:
      return {
        ...state,
        patchAdminExpense: {},
        patchAdminExpenseLoading: true,
      };
    case PATCH_ADMIN_EXPENSE_SUCCESS:
      return {
        ...state,
        patchAdminExpense: action.payload,
        patchAdminExpenseLoading: false,
        patchAdminExpenseError: "",
      };

    case PATCH_ADMIN_EXPENSE_ERROR:
      return {
        ...state,
        patchAdminExpenseLoading: false,
        patchAdminExpenseError: action.payload,
      };
    case DELETE_ADMIN_EXPENSE:
      return {
        ...state,
        deleteAdminExpense: {},
        deleteAdminExpenseLoading: true,
      };
    case DELETE_ADMIN_EXPENSE_SUCCESS:
      return {
        ...state,
        deleteAdminExpense: action.payload,
        deleteAdminExpenseLoading: false,
        deleteAdminExpenseError: "",
      };

    case DELETE_ADMIN_EXPENSE_ERROR:
      return {
        ...state,
        deleteAdminExpenseLoading: false,
        deleteAdminExpenseError: action.payload,
      };
    default:
      return { ...state };
  }
};

export default AdminExpenseSection;
