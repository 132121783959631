// Backdrop.js
import React from 'react';
import Loader from './loader';

const Backdrop = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <Loader />
    </div>
  );
};

export default Backdrop;
