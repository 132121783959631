import { GET_ADMIN_EMPLOYEE_BY_COMPANYEMPLOYEEID, GET_ADMIN_EMPLOYEE_BY_COMPANYEMPLOYEEID_ERROR, GET_ADMIN_EMPLOYEE_BY_COMPANYEMPLOYEEID_SUCCESS, GET_ADMIN_EMPLOYEE_BY_EMPLOYEEID, GET_ADMIN_EMPLOYEE_BY_EMPLOYEEID_ERROR, GET_ADMIN_EMPLOYEE_BY_EMPLOYEEID_SUCCESS, GET_ADMIN_EMPLOYEE_LIST, GET_ADMIN_EMPLOYEE_LIST_BY_COMPANYID, GET_ADMIN_EMPLOYEE_LIST_BY_COMPANYID_ERROR, GET_ADMIN_EMPLOYEE_LIST_BY_COMPANYID_SUCCESS, GET_ADMIN_EMPLOYEE_LIST_ERROR, GET_ADMIN_EMPLOYEE_LIST_SUCCESS, PATCH_ADMIN_EMPLOYEE_TERMINATE, PATCH_ADMIN_EMPLOYEE_TERMINATE_ERROR, PATCH_ADMIN_EMPLOYEE_TERMINATE_SUCCESS, POST_ADMIN_EMPLOYEE, POST_ADMIN_EMPLOYEE_ERROR, POST_ADMIN_EMPLOYEE_SUCCESS } from "../../actions";

const INIT_STATE = {
  getAdminEmployeeList: {},
  getAdminEmployeeListLoading: false,
  getAdminEmployeeListError: "",
  getAdminEmployeeListByCompanyId: {},
  getAdminEmployeeListByCompanyIdLoading: false,
  getAdminEmployeeListByCompanyIdError: "",
  getAdminEmployeeByEmployeeId: {},
  getAdminEmployeeByEmployeeIdLoading: false,
  getAdminEmployeeByEmployeeIdError: "",
  getAdminEmployeeByCompanyEmployeeId: {},
  getAdminEmployeeByCompanyEmployeeIdLoading: false,
  getAdminEmployeeByCompanyEmployeeIdError: "",
  postAdminEmployee: {},
  postAdminEmployeeLoading: false,
  postAdminEmployeeError: "",
  patchAdminEmployeeTerminate: {},
  patchAdminEmployeeTerminateLoading: false,
  patchAdminEmployeeTerminateError: "",
};

const AdminEmployeeSection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ADMIN_EMPLOYEE_LIST:
      return {
        ...state,
        getAdminEmployeeList: {},
        getAdminEmployeeListLoading: true,
      };
    case GET_ADMIN_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        getAdminEmployeeList: action.payload,
        getAdminEmployeeListLoading: false,
        getAdminEmployeeListError: "",
      };

    case GET_ADMIN_EMPLOYEE_LIST_ERROR:
      return {
        ...state,
        getAdminEmployeeListLoading: false,
        getAdminEmployeeListError: action.payload,
      };
    case GET_ADMIN_EMPLOYEE_LIST_BY_COMPANYID:
      return {
        ...state,
        getAdminEmployeeListByCompanyId: {},
        getAdminEmployeeListByCompanyIdLoading: true,
      };
    case GET_ADMIN_EMPLOYEE_LIST_BY_COMPANYID_SUCCESS:
      return {
        ...state,
        getAdminEmployeeListByCompanyId: action.payload,
        getAdminEmployeeListByCompanyIdLoading: false,
        getAdminEmployeeListByCompanyIdError: "",
      };

    case GET_ADMIN_EMPLOYEE_LIST_BY_COMPANYID_ERROR:
      return {
        ...state,
        getAdminEmployeeListByCompanyIdLoading: false,
        getAdminEmployeeListByCompanyIdError: action.payload,
      };
    case GET_ADMIN_EMPLOYEE_BY_EMPLOYEEID:
      return {
        ...state,
        getAdminEmployeeByEmployeeId: {},
        getAdminEmployeeByEmployeeIdLoading: true,
      };
    case GET_ADMIN_EMPLOYEE_BY_EMPLOYEEID_SUCCESS:
      return {
        ...state,
        getAdminEmployeeByEmployeeId: action.payload,
        getAdminEmployeeByEmployeeIdLoading: false,
        getAdminEmployeeByEmployeeIdError: "",
      };

    case GET_ADMIN_EMPLOYEE_BY_EMPLOYEEID_ERROR:
      return {
        ...state,
        getAdminEmployeeByEmployeeIdLoading: false,
        getAdminEmployeeByEmployeeIdError: action.payload,
      };
    case GET_ADMIN_EMPLOYEE_BY_COMPANYEMPLOYEEID:
      return {
        ...state,
        getAdminEmployeeByCompanyEmployeeId: {},
        getAdminEmployeeByCompanyEmployeeIdLoading: true,
      };
    case GET_ADMIN_EMPLOYEE_BY_COMPANYEMPLOYEEID_SUCCESS:
      return {
        ...state,
        getAdminEmployeeByCompanyEmployeeId: action.payload,
        getAdminEmployeeByCompanyEmployeeIdLoading: false,
        getAdminEmployeeByCompanyEmployeeIdError: "",
      };

    case GET_ADMIN_EMPLOYEE_BY_COMPANYEMPLOYEEID_ERROR:
      return {
        ...state,
        getAdminEmployeeByCompanyEmployeeIdLoading: false,
        getAdminEmployeeByCompanyEmployeeIdError: action.payload,
      };
    case POST_ADMIN_EMPLOYEE:
      return {
        ...state,
        postAdminEmployee: {},
        postAdminEmployeeLoading: true,
      };
    case POST_ADMIN_EMPLOYEE_SUCCESS:
      return {
        ...state,
        postAdminEmployee: action.payload,
        postAdminEmployeeLoading: false,
        postAdminEmployeeError: "",
      };

    case POST_ADMIN_EMPLOYEE_ERROR:
      return {
        ...state,
        postAdminEmployeeLoading: false,
        postAdminEmployeeError: action.payload,
      };
      case PATCH_ADMIN_EMPLOYEE_TERMINATE:
        return {
          ...state,
          patchAdminEmployeeTerminate: {},
          patchAdminEmployeeTerminateLoading: true,
        };
      case PATCH_ADMIN_EMPLOYEE_TERMINATE_SUCCESS:
        return {
          ...state,
          patchAdminEmployeeTerminate: action.payload,
          patchAdminEmployeeTerminateLoading: false,
          patchAdminEmployeeTerminateError: "",
        };
  
      case PATCH_ADMIN_EMPLOYEE_TERMINATE_ERROR:
        return {
          ...state,
          patchAdminEmployeeTerminateLoading: false,
          patchAdminEmployeeTerminateError: action.payload,
        };
    default:
      return { ...state };
  }
};

export default AdminEmployeeSection;
