import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getExtraExpenseTripFilterAction } from "../../../../redux/company/trip/extraExpense/action";
import DatePicker from "../../../../components/datepicker/datePicker";
import ExtraExpenseTripListCard from "./extraExpenseTripListCard";
import { Dialog } from "../../../../ui/dialog";
import DefFilter from "../../Truck/def/defFilter";

function ExtraExpenseTripList({ data }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  useEffect(() => {
    // if (!(data?.length > 0)) {
    handleClearFilters();
    // }
  }, [dispatch]);
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleFilterSubmit = (values) => {
    const payload = {
      ...Object.fromEntries(
        Object.entries(values).filter(([_, v]) => v !== "")
      ), // Only send filled filters
    };
    dispatch(getExtraExpenseTripFilterAction(payload));
    setShowFilters(false);
  };

  const handleClearFilters = () => {
    const TripId = searchParams.get("tripId");
    const payload = {
      tripId: TripId,
    };
    dispatch(getExtraExpenseTripFilterAction(payload));
    setShowFilters(false);
  };
  const filteredData = data?.expenseRecords?.filter(
    (item) =>
      item.cost.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.billNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.expenseName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div className="flex flex-col">
      <button
        onClick={() =>
          navigate(
            `/company/trip/addExtraExpense?tripId=${searchParams.get("tripId")}`
          )
        }
        className="primaryButton md:w-fit ml-auto text-sm mb-2"
      >
        Add Extra Expense
      </button>
      <div className="mb-4 gap-8 flex justify-between items-center">
        <input
          placeholder="Search"
          value={searchQuery}
          onChange={handleSearch}
          className="max-w-sm shadow-md mb-2"
        />
        <button onClick={() => setShowFilters(!showFilters)}className="flex gap-1 items-center">
        <FaFilter className="text-gray-600" /> Filter
        </button>
      </div>
      {showFilters && (
        <Formik
          initialValues={{
            startDate: "",
            endDate: "",
          }}
          onSubmit={handleFilterSubmit}
        >
          {({ values, setFieldValue, resetForm, handleSubmit }) => (
            <Form>
              <Dialog open={showFilters}>
                <DefFilter
                  handleSubmit={handleSubmit}
                  handleClearFilters={handleClearFilters}
                  values={values}
                  setShowFilters={setShowFilters}
                  resetForm={resetForm}
                />
              </Dialog>
            </Form>
          )}
        </Formik>
      )}
      <div className="grid md:grid-cols-2">
        <div className="md:text-left">
          {/* <h3 className="textgrey">Total Liters</h3>
          <p className="font-bold">{data?.totalLiter} Liter</p> */}
        </div>
        <div className="md:text-right">
          <h3 className="textgrey">Total Amount</h3>
          <p className="font-bold">Rs.{data?.totalAmount}</p>
        </div>
      </div>
      {filteredData?.length > 0 ? (
        filteredData?.map((item) => (
          <ExtraExpenseTripListCard key={item._id} data={item} reroute={true} />
        ))
      ) : (
        <p>No Extra Expense found.</p>
      )}
    </div>
  );
}

export default ExtraExpenseTripList;
