import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ClientListCard from "./clientListCard";

function ClientList({ data }) {
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = data?.filter(
    (item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.ownerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.phoneNumber.toString().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <div className="mb-4 gap-8 flex justify-between items-center">
        <input
          placeholder="Search"
          value={searchQuery}
          onChange={handleSearch}
          className="max-w-sm shadow-md mb-2"
        />
      </div>
      {filteredData?.length > 0 ? (
        filteredData?.map((item) => (
          <ClientListCard key={item._id} data={item} reroute={true} />
        ))
      ) : (
        <p>No Clients found.</p>
      )}
    </div>
  );
}

export default ClientList;
