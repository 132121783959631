const {
  POST_COMPANY_DETAILS,
  POST_COMPANY_DETAILS_SUCCESS,
  POST_COMPANY_DETAILS_ERROR,
  GET_COMPANY_DETAILS,
  GET_COMPANY_DETAILS_SUCCESS,
  GET_COMPANY_DETAILS_ERROR,
  PATCH_COMPANY_DETAILS,
  PATCH_COMPANY_DETAILS_SUCCESS,
  PATCH_COMPANY_DETAILS_ERROR,
  GET_COMPANY_SEARCH,
  GET_COMPANY_SEARCH_SUCCESS,
  GET_COMPANY_SEARCH_ERROR,
} = require("../actions");

const INIT_STATE = {
  postCompanyDetails: {},
  postCompanyDetailsLoading: false,
  postCompanyDetailsError: "",
  patchCompanyDetails: {},
  patchCompanyDetailsLoading: false,
  patchCompanyDetailsError: "",
  getCompanyDetails: {},
  getCompanyDetailsLoading: false,
  getCompanyDetailsError: "",
  getCompanySearch: {},
  getCompanySearchLoading: false,
  getCompanySearchError: "",
};

const Companysection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_COMPANY_DETAILS:
      return {
        ...state,
        postCompanyDetails: {},
        postCompanyDetailsLoading: true,
      };
    case POST_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        postCompanyDetails: action.payload,
        postCompanyDetailsLoading: false,
        postCompanyDetailsError: "",
      };

    case POST_COMPANY_DETAILS_ERROR:
      return {
        ...state,
        postCompanyDetailsLoading: false,
        postCompanyDetailsError: action.payload,
      };
    case PATCH_COMPANY_DETAILS:
      return {
        ...state,
        patchCompanyDetails: {},
        patchCompanyDetailsLoading: true,
      };
    case PATCH_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        patchCompanyDetails: action.payload,
        patchCompanyDetailsLoading: false,
        patchCompanyDetailsError: "",
      };

    case PATCH_COMPANY_DETAILS_ERROR:
      return {
        ...state,
        patchCompanyDetailsLoading: false,
        patchCompanyDetailsError: action.payload,
      };
    case GET_COMPANY_DETAILS:
      return {
        ...state,
        getCompanyDetails: {},
        getCompanyDetailsLoading: true,
      };
    case GET_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        getCompanyDetails: action.payload,
        getCompanyDetailsLoading: false,
        getCompanyDetailsError: "",
      };

    case GET_COMPANY_DETAILS_ERROR:
      return {
        ...state,
        getCompanyDetailsLoading: false,
        getCompanyDetailsError: action.payload,
      };
    case GET_COMPANY_SEARCH:
      return {
        ...state,
        getCompanySearch: {},
        getCompanySearchLoading: true,
      };
    case GET_COMPANY_SEARCH_SUCCESS:
      return {
        ...state,
        getCompanySearch: action.payload,
        getCompanySearchLoading: false,
        getCompanySearchError: "",
      };

    case GET_COMPANY_SEARCH_ERROR:
      return {
        ...state,
        getCompanySearchLoading: false,
        getCompanySearchError: action.payload,
      };
    default:
      return { ...state };
  }
};

export default Companysection;
