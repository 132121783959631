import {
  DELETE_COMPANY_TOLLTAX,
  DELETE_COMPANY_TOLLTAX_ERROR,
  DELETE_COMPANY_TOLLTAX_SUCCESS,
  GET_COMPANY_TOLLTAX,
  GET_COMPANY_TOLLTAX_ERROR,
  GET_COMPANY_TOLLTAX_FILTER,
  GET_COMPANY_TOLLTAX_FILTER_ERROR,
  GET_COMPANY_TOLLTAX_FILTER_SUCCESS,
  GET_COMPANY_TOLLTAX_ID,
  GET_COMPANY_TOLLTAX_ID_ERROR,
  GET_COMPANY_TOLLTAX_ID_SUCCESS,
  GET_COMPANY_TOLLTAX_SUCCESS,
  PATCH_COMPANY_TOLLTAX,
  PATCH_COMPANY_TOLLTAX_ERROR,
  PATCH_COMPANY_TOLLTAX_SUCCESS,
  POST_COMPANY_TOLLTAX,
  POST_COMPANY_TOLLTAX_ERROR,
  POST_COMPANY_TOLLTAX_SUCCESS,
} from "../../actions";

//truck
export const getCompanyTollTaxAction = (info) => ({
  type: GET_COMPANY_TOLLTAX,
  payload: info,
});
export const getCompanyTollTaxSuccess = (info) => ({
  type: GET_COMPANY_TOLLTAX_SUCCESS,
  payload: info,
});
export const getCompanyTollTaxError = (error) => ({
  type: GET_COMPANY_TOLLTAX_ERROR,
  payload: error,
});
export const getCompanyTollTaxIdAction = (info) => ({
  type: GET_COMPANY_TOLLTAX_ID,
  payload: info,
});
export const getCompanyTollTaxIdSuccess = (info) => ({
  type: GET_COMPANY_TOLLTAX_ID_SUCCESS,
  payload: info,
});
export const getCompanyTollTaxIdError = (error) => ({
  type: GET_COMPANY_TOLLTAX_ID_ERROR,
  payload: error,
});
export const getCompanyTollTaxFilterAction = (info) => ({
  type: GET_COMPANY_TOLLTAX_FILTER,
  payload: info,
});
export const getCompanyTollTaxFilterSuccess = (info) => ({
  type: GET_COMPANY_TOLLTAX_FILTER_SUCCESS,
  payload: info,
});
export const getCompanyTollTaxFilterError = (error) => ({
  type: GET_COMPANY_TOLLTAX_FILTER_ERROR,
  payload: error,
});
export const postCompanyTollTaxAction = (info) => ({
  type: POST_COMPANY_TOLLTAX,
  payload: info,
});
export const postCompanyTollTaxSuccess = (info) => ({
  type: POST_COMPANY_TOLLTAX_SUCCESS,
  payload: info,
});
export const postCompanyTollTaxError = (error) => ({
  type: POST_COMPANY_TOLLTAX_ERROR,
  payload: error,
});
export const patchCompanyTollTaxAction = (info) => ({
  type: PATCH_COMPANY_TOLLTAX,
  payload: info,
});
export const patchCompanyTollTaxSuccess = (info) => ({
  type: PATCH_COMPANY_TOLLTAX_SUCCESS,
  payload: info,
});
export const patchCompanyTollTaxError = (error) => ({
  type: PATCH_COMPANY_TOLLTAX_ERROR,
  payload: error,
});
export const deleteCompanyTollTaxAction = (info) => ({
  type: DELETE_COMPANY_TOLLTAX,
  payload: info,
});
export const deleteCompanyTollTaxSuccess = (info) => ({
  type: DELETE_COMPANY_TOLLTAX_SUCCESS,
  payload: info,
});
export const deleteCompanyTollTaxError = (error) => ({
  type: DELETE_COMPANY_TOLLTAX_ERROR,
  payload: error,
});
