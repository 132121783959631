import { getCompanyFuelNameListAction } from "../../../redux/company/fuel/action";
import {
  getFuelStatsAction,
  getTollStatsAction,
} from "../../../redux/company/finance/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import usePagination from "headless-pagination-react";
import PaginationPackage from "../../paginationPackage";
import { FiDownload } from "react-icons/fi";
import { AiOutlineCloseSquare } from "react-icons/ai";
import moment from "moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const convertFilterToQueryString = (filter) => {
  return Object.keys(filter)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(filter[key])}`
    )
    .join("&");
};

function TollStats({ show, setShow }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(); // Search params hook

  const { tollStats } = useSelector((state) => state.StatsReducer);

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(1000);
  const [maxLinks, setMaxLinks] = useState(10);
  const [perPage, setperPage] = useState(5);
  const { links, from, to, setPage, onNext, onPrevious, page } = usePagination({
    totalItems,
    perPage,
    maxLinks,
  });
  useEffect(() => {
    if (tollStats?.status === 200) {
      setData(tollStats?.data);
      setTotalItems(tollStats?.data?.tolls?.length);
    }
  }, [tollStats]);

  useEffect(() => {
    const filter = {
      startDate: "",
      endDate: "",
    };
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    const truckId = searchParams.get("truckId");
    const isTruck = searchParams.get("allTruck") === "true";

    // Add date filters if they are present
    if (startDate) {
      filter.startDate = new Date(startDate).toISOString(); // Convert date to ISO string
    }
    if (endDate) {
      filter.endDate = new Date(endDate).toISOString(); // Convert date to ISO string
    }

    // Add truckId if it's not 'allTruck'
    if (!isTruck && truckId) {
      filter.truckId = truckId;
    }
    // Convert filter object to query string
    const queryString = convertFilterToQueryString(filter);

    dispatch(getTollStatsAction(queryString));
  }, [searchParams]);

  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 2,
        }).format(amount)
      : "₹0.00";

  const handleExport = () => {
    if (!data || !data?.tolls) {
      console.error("Data or fuels is undefined");
      return;
    }

    // Flatten fuel data
    const combinedData = [
      ...(data.tolls?.map((item) => ({
        "Toll Bill No": item.billNumber || "-",
        "Toll Date": item.taxPayDate
          ? moment(item.taxPayDate).format("DD / MMM / YYYY")
          : "-",
        "Toll Name": item.tollName || "-",
        "Type Of Payment": item.typeOfPayment || "-",
        "Toll Truck Number": item.truckId ? item.truckId.truckNumber : "-",
        "Toll Trip": item?.tripId
          ? `${item?.tripId?.tokenNumber} (${item?.tripId?.startLocation}-${item?.tripId?.endLocation})`
          : "-",
        "Toll Cost": formatAmount(item.cost) || "-",
      })) || []),
    ];

    // Find last `fuelFillDate` if startDate is not provided
    const lastFuelFillDate = data?.tolls?.length
      ? moment(
          data.tolls[data.tolls.length - 1]?.taxPayDate || new Date()
        ).format("DD/MMM/YYYY")
      : "";

    // Determine the start and end date for the file name
    const startDate = searchParams.get("startDate")
      ? moment(searchParams.get("startDate")).format("DD/MMM/YYYY")
      : lastFuelFillDate;
    const endDate = searchParams.get("endDate")
      ? moment(searchParams.get("endDate")).format("DD/MMM/YYYY")
      : "";

    // Convert to worksheet
    const ws = XLSX.utils.json_to_sheet(combinedData);

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Combined Data");

    // Generate Excel file and trigger download
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const file = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Use start and end date for file name, defaulting to lastFuelFillDate if needed
    saveAs(
      file,
      `toll_${startDate}-${
        endDate ? endDate : moment(new Date()).format("DD/MMM/YYYY")
      }.xlsx`
    );
  };
  return (
    <>
      <div className=" bg-white mt-6 shadow-lg rounded-sm relative">
        <div className="p-4 text-[#64709B] font-semibold flex justify-between">
          <div className="cursor-pointer" onClick={() => setShow("toll")}>
            Toll Tax
          </div>
        </div>
        <div className="w-full h-0.5 bg-[#F1F2F6]" />
        <div className="p-4 text-[#303750] text-2xl font-bold flex gap-2">
          {formatAmount(data?.totalTollCost)}{" "}
          <div className="text-base font-medium mt-auto">INR</div>
        </div>
        {show == "toll" && <div className="arrow-down"></div>}
      </div>

      {show == "toll" && (
        <div className="col-span-4 order-last">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
              <thead className="ltr:text-left rtl:text-right">
                <tr>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-left">
                    Bill No
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Date
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Toll Name
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Type Of Payment
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Truck Number
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Trip
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Cost
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium  justify-end flex gap-3">
                    <FiDownload className="text-lg ghostButton cursor-pointer" onClick={() => handleExport()} />
                    <AiOutlineCloseSquare
                      onClick={() => setShow("")}
                      className="text-red-500 text-xl cursor-pointer"
                    />
                  </th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200">
                {data?.tolls?.slice(from - 1, to)?.map((item) => (
                  <tr>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                      {item.billNumber ? item.billNumber : "-"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center">
                      {item.taxPayDate
                        ? moment(item.taxPayDate).format("DD / MMM / YYYY")
                        : "-"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center">
                      {item.tollName ? item.tollName : "-"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center">
                      {item.typeOfPayment ? item.typeOfPayment : "-"}
                    </td>
                    <td
                      className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center uppercase cursor-pointer"
                      onClick={() =>
                        navigate(
                          "/company/truck/details?truckId=" + item.truckId._id
                        )
                      }
                    >
                      {item.truckId ? item.truckId.truckNumber : "-"}
                    </td>
                    <td
                      className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center uppercase cursor-pointer"
                      onClick={() =>
                        navigate(
                          "/company/trip/details?tripId=" +
                            item.tripId._id +
                            "&truckId=" +
                            item.tripId.truckId
                        )
                      }
                    >
                      {item.tripId ? item.tripId.tokenNumber : "-"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center">
                      {item.cost ? formatAmount(item.cost) : "-"}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colspan="8">
                    <PaginationPackage
                      links={links}
                      onNext={onNext}
                      onPrevious={onPrevious}
                      from={from}
                      to={to}
                      totalItems={totalItems}
                      perPage={perPage}
                      totalPages={page}
                      setPage={setPage}
                      setperPage={setperPage}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default TollStats;
