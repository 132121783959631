import React, { useState } from "react";
import DiscountListCard from "./discountListCard";
import { Link } from "react-router-dom";

function DiscountList({ data }) {
  const [searchQuery, setSearchQuery] = useState("");
console.log(data)
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = data?.filter(
    (item) =>
      item?.extraDiscountName
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      item.extraDiscountPercent.includes(searchQuery.toLowerCase()) ||
      item.condition.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div>
      <div className="mb-4 flex justify-between">
        <input
          placeholder="Search by Truck Number or Type"
          value={searchQuery}
          onChange={handleSearch}
          className="max-w-sm shadow-md "
        />
        <Link to={"addDiscount"} className="primaryButton w-fit">
          Add Discount
        </Link>
      </div>
      {filteredData?.length > 0 ? (
        filteredData?.map((item, index) => (
          <DiscountListCard data={item} reroute={true} />
        ))
      ) : (
        <p>No Ownership found.</p>
      )}
    </div>
  );
}

export default DiscountList;
