import React, { useState } from "react";
import { Dialog } from "../../../../ui/dialog";
import Image from "../../../../assets/images/office-building.png";
import moment from "moment";
import ImagePopUpDownload from "../../../../components/imagePopUpDownload";
import { Link } from "react-router-dom";

function OwnershipCard({ data, reroute = false }) {
  const [showImageModal, setShowImageModal] = useState(false);

  return (
    <div
      key={data?._id}
      className="my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl"
    >
      <div className="grid grid-cols-12 gap-x-7">
        <div className="lg:col-span-2 col-span-6 justify-self-center my-auto">
          <button
            type="button"
            className="rounded-full bg-gray-400"
            onClick={() => setShowImageModal(true)}
          >
            <img
              src={data?.companyId?.companyLogo ? data?.companyId?.companyLogo : Image} 
              alt="Company Logo"
              className={` ${
                data?.companyId?.companyLogo ? "rounded-full" : ""
              }  h-[100px] w-[100px]`}
            />
          </button>
        </div>
        {data?.companyId?.companyLogo && (
          <Dialog open={showImageModal}>
            <ImagePopUpDownload
              image={data?.companyId?.companyLogo}
              setShowModal={setShowImageModal}
              imagename={`company_${data?._id}`}
            />
          </Dialog>
        )}
        <div className="mb-5 text-center md:text-left lg:col-span-10 col-span-6">
          <Link
            to={`/admin/company/${data?.companyId?._id}`}
            className="font-bold text-xl uppercase text-blue-500"
          >
            {data?.companyId?.companyName}
          </Link>
          <p className="textgrey text-base">
            {moment(data?.purchaseDate).format("DD/MM/YYYY")} -{" "}
            {data?.sellDate
              ? moment(data?.sellDate).format("DD/MM/YYYY")
              : "Present"}
          </p>
          {data?.soldToCompanyId ? (
            <Link
              to={`/admin/company/${data?.soldToCompanyId?._id}`}
              className="textgrey text-base"
            >
              Sell To: {data?.soldToCompanyId?.companyName}
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default OwnershipCard;
