import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import './i18n';
import { Provider } from "react-redux";
import ConfigureStore from "./redux/store";
import Loader from "./components/loader";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={ConfigureStore()}>
    <Suspense Suspense fallback={<Loader/>}>
      <App />
    </Suspense>
  </Provider>
);
