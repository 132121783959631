import Dashboard from "../assets/images/dashboard.png";
import Truck from "../assets/images/semi.png";
import Trip from "../assets/images/delivery.png"
import EmployeeImage from "../assets/images/employees.png"
import Finances from "../assets/images/budget.png"
import Details from "../assets/images/business-card.png"
import Client from "../assets/images/handshake.png"
import CompanyImage from "../assets/images/office-building.png"
import subscription from "../assets/images/subscription.png"
import Contact from "../assets/images/chatbot.png"


export const Admin = [ {
    name: "Dashboard",
    href: "/admin/dashboard",
    icon: <img src={Dashboard} className="h-7 w-7" />
},
{
    name: "Truck",
    href: "/admin/truck",
    icon: <img src={Truck} className="h-8 w-8" />
},
{
    name: "Company",
    href: "/admin/company",
    icon: <img src={CompanyImage} className="h-8 w-8" />
},
{
    name: "Employee",
    href: "/admin/employee",
    icon: <img src={EmployeeImage} className="h-8 w-8" />
},
{
    name: "Subscription",
    href: "/admin/subscription",
    icon: <img src={subscription} className="h-8 w-8" />
},
{
    name: "Stats",
    href: "/admin/expenses",
    icon: <img src={Finances} className="h-8 w-8" />
},
{
    name: "Details",
    href: "/admin/details",
    icon: <img src={Details} className="h-8 w-8" />
}]
export const Employee = [{}]
export const Company = [
    {
        name: "Dashboard",
        href: "/company/dashboard",
        icon: <img src={Dashboard} className="h-7 w-7" />
    },
    {
        name: "Truck",
        href: "/company/truck",
        icon: <img src={Truck} className="h-8 w-8" />
    },
    {
        name: "Trips",
        href: "/company/trip",
        icon: <img src={Trip} className="h-8 w-8" />
    },
    {
        name: "Client",
        href: "/company/client",
        icon: <img src={Client} className="h-8 w-8" />
    },
    {
        name: "Employee",
        href: "/company/employee",
        icon: <img src={EmployeeImage} className="h-8 w-8" />
    },
    {
        name: "Stats",
        href: "/company/Stats?allTruck=true&truckId=&startDate=&endDate=",
        icon: <img src={Finances} className="h-8 w-8" />
    },
    {
        name: "Details",
        href: "/company/details",
        icon: <img src={Details} className="h-8 w-8" />
    },
    // {
    //     name: "Contact Us",
    //     href: "/company/contactUs",
    //     icon: <img src={Contact} className="h-8 w-8" />

    // }
]