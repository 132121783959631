import { getCompanyLoginStampAction } from "../../../../redux/admin/company/action";
import getAddressFromCoordinates from "../../../../auth/getAddressFromCoordinates";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

function LoginDetails({ data }) {
  
  const [loactionData, setLoactionData] = useState("");

  useEffect(() => {
    handleLocation(data?.location);
  }, [data]);

  const handleLocation = async (values) => {
    const location = await getAddressFromCoordinates(
      values.latitude,
      values.longitude
    );
    setLoactionData(location?.address);
  };
  

  return (
    <div>
      <div className="bg-white p-4 rounded-md shadow-lg grid md:grid-cols-4 gap-5 w-full">
        <div className="">
          <label htmlFor="" className="">
            Login Date and Time
          </label>
          <p className="">
            {moment(data?.timestamp).format("DD/MMM/YYYY hh:mm:ss")}
          </p>
        </div>
        <div className="">
          <label htmlFor="" className="">
            Device
          </label>
          <p className="">
            {data?.device?.deviceName}, {data?.device?.deviceType}
          </p>
        </div>
        <div className="">
          <label htmlFor="" className="">
            Location
          </label>
          <p className="">{loactionData}</p>
        </div>
      </div>
    </div>
  );
}

export default LoginDetails;
