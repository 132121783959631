import {
  getAdminSubscriptionByIdAction,
  patchAdminSubscriptionAction,
  postAdminSubscriptionAction,
} from "../../../redux/admin/subscription/action";
import { Switch } from "../../../ui/switch";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import Backdrop from "../../backdrop";

function AddSubscription() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    postAdminSubscription,
    postAdminSubscriptionLoading,
    postAdminSubscriptionError,
    patchAdminSubscription,
    patchAdminSubscriptionLoading,
    patchAdminSubscriptionError,
    getAdminSubscriptionById,
    getAdminSubscriptionByIdLoading,
    getAdminSubscriptionByIdError,
  } = useSelector((state) => state.AdminSubscriptionSection);
  const [postLoading, setPostLoading] = useState(false);
  const [patchLoading, setPatchLoading] = useState(false);
  const [edit, setEdit] = useState(null);
  const [editLoading, setEditLoading] = useState(false);
  useEffect(() => {
    const id = searchParams.get("id");
    if (id !== null) {
      dispatch(getAdminSubscriptionByIdAction(id));
      setEditLoading(true)
    }
    if(id=== null){
      setEdit(null)
    }
  }, []);

  useEffect(() => {
    if (postLoading) {
      if (postAdminSubscription.status === 200) {
        toast.success(postAdminSubscription.message, {
          id: "subscriptionToast",
        });
        setPostLoading(false)
        navigate(-1);
      }
    }
  }, [postAdminSubscription]);
  useEffect(() => {
    if (postLoading) {
      if (postAdminSubscriptionError.status === 400) {
        toast.error(postAdminSubscriptionError.message, {
          id: "subscriptionToast",
        });
        setPostLoading(false)
      } else if (postAdminSubscriptionError.status === 404) {
        toast.error(postAdminSubscriptionError.message, {
          id: "subscriptionToast",
        });
        setPostLoading(false)
      } else if (postAdminSubscriptionError.status === 500) {
        toast.error(postAdminSubscriptionError.error, {
          id: "subscriptionToast",
        });
        setPostLoading(false)
      }
    }
  }, [postAdminSubscriptionError]);
  
  useEffect(() => {
    if (patchLoading) {
      if (patchAdminSubscription.status === 200) {
        toast.success(patchAdminSubscription.message, {
          id: "subscriptionUpdateToast",
        });
        setPatchLoading(false)
        navigate(-1);
      }
    }
  }, [patchAdminSubscription]);
  useEffect(() => {
    if (patchLoading) {
      if (patchAdminSubscriptionError.status === 400) {
        toast.error(patchAdminSubscriptionError.message, {
          id: "subscriptionUpdateToast",
        });
        setPatchLoading(false)
      } else if (patchAdminSubscriptionError.status === 404) {
        toast.error(patchAdminSubscriptionError.message, {
          id: "subscriptionUpdateToast",
        });
        setPatchLoading(false)
      } else if (patchAdminSubscriptionError.status === 500) {
        toast.error(patchAdminSubscriptionError.error, {
          id: "subscriptionUpdateToast",
        });
        setPatchLoading(false)
      }
    }
  }, [patchAdminSubscriptionError]);
  
  useEffect(() => {
    if(editLoading){

      if (getAdminSubscriptionById.status === 200) {
        setEdit(getAdminSubscriptionById?.data);
      }
    }
  }, [getAdminSubscriptionById]);
  useEffect(() => {
    if (getAdminSubscriptionByIdError.status === 400) {
      toast.error(getAdminSubscriptionByIdError.message);
    } else if (getAdminSubscriptionByIdError.status === 404) {
      toast.error(getAdminSubscriptionByIdError.message);
    } else if (getAdminSubscriptionByIdError.status === 500) {
      toast.error(getAdminSubscriptionByIdError.error);
    }
  }, [getAdminSubscriptionByIdError]);
  const initialValues = {
    name: edit?.name || "",
    costPerMonth: edit?.costPerMonth || null,
    months: edit?.months || null,
    isDiscount: edit?.isDiscount || false,
    discountPercent: edit?.discountPercent || null,
    discountName: edit?.discountName || "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    costPerMonth: Yup.number()
      .required("Cost per month is required")
      .positive("Cost per month must be a positive number"),
    months: Yup.number()
      .required("Number of months is required")
      .positive("Months must be a positive number")
      .integer("Months must be a whole number"),
    isDiscount: Yup.boolean(),
    discountPercent: Yup.number()
      .when("isDiscount", {
        is: true,
        then: () =>
          Yup.number()
            .required("Discount percent is required")
            .min(1, "Discount percent must be at least 1")
            .max(100, "Discount percent can't exceed 100"),
        otherwise: () => Yup.number().notRequired(),
      })
      .nullable(),
    discountName: Yup.string().when("isDiscount", {
      is: true,
      then: () => Yup.string().required("Discount name is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
  });
  const onSubmit = (values) => {
    dispatch(postAdminSubscriptionAction(values));
    setPostLoading(true);
    toast.loading("Subscription Data Saving...", { id: "subscriptionToast" });
  };
  const onUpdate = (values) => {
    const id = searchParams.get("id");
    const payload = {
      id: id,
      body: values,
    };
    dispatch(patchAdminSubscriptionAction(payload));
    setPatchLoading(true);
    toast.loading("Subscription Data Updating...", {
      id: "subscriptionUpdateToast",
    });
  };

  const calculate = (values) => {
    const defaultCost = values?.months * values?.costPerMonth || 0;
    const discount = values.isDiscount
      ? (values.discountPercent / 100) * defaultCost
      : 0;
    const finalCost = defaultCost - discount;
    return finalCost;
  };
  return (
    <div>
      {getAdminSubscriptionByIdLoading && <Backdrop/>}
      {" "}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={edit ? onUpdate : onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values, errors }) => (
          <Form>
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 ">
              <div>
                <label htmlFor="name">
                  Name<span>*</span>
                </label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  className=""
                  placeholder="Name"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div>
                <label htmlFor="costPerMonth">
                  Cost Per Month<span>*</span>
                </label>
                <Field
                  type="number"
                  id="costPerMonth"
                  name="costPerMonth"
                  className=""
                  placeholder="Cost Per Month"
                />
                <ErrorMessage
                  name="costPerMonth"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div>
                <label htmlFor="months">
                  Months<span>*</span>
                </label>
                <Field
                  type="number"
                  id="months"
                  name="months"
                  className=""
                  placeholder="Month"
                />
                <ErrorMessage
                  name="months"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="mt-auto">
                <label htmlFor="isDiscount">Discount</label>
                <div className="my-auto flex items-center gap-4 font-bold h-10">
                  <Switch
                    checked={values.isDiscount}
                    id="isDiscount"
                    onCheckedChange={(value) =>
                      setFieldValue("isDiscount", value)
                    }
                  />
                  {values.isDiscount ? "Yes" : "No"}
                </div>
              </div>
              {values.isDiscount && (
                <>
                  <div>
                    <label htmlFor="discountName">
                      Name<span>*</span>
                    </label>
                    <Field
                      type="text"
                      id="discountName"
                      name="discountName"
                      className=""
                      placeholder="Discount Name"
                    />
                    <ErrorMessage
                      name="discountName"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div>
                    <label htmlFor="discountPercent">
                      Discount Percent<span>*</span>
                    </label>
                    <Field
                      type="number"
                      id="discountPercent"
                      name="discountPercent"
                      className=""
                      placeholder="Discount Percent"
                    />
                    <ErrorMessage
                      name="discountPercent"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </>
              )}
              <button
                type="submit"
                className="primaryButton h-fit mt-auto"
                disabled={
                  postAdminSubscriptionLoading || patchAdminSubscriptionLoading
                }
              >
                {edit ? "Update" : "Submit"} {calculate(values)}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddSubscription;
