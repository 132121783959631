import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import Backdrop from "../../../../components/backdrop";
import toast from "react-hot-toast";
import { getEmiAction } from "../../../../redux/company/truck/emi/action";
import EmiTruckDetailsCard from "./emiTruckDetailsCard";
import EmiCard from "./truckEmiCard";

function TruckEmiList() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { getEmi, getEmiLoading, getEmiError } = useSelector(
    (state) => state.EmiSection
  );
  const id = searchParams.get("truckId");
console.log(getEmi)
  const [datas, setData] = useState([]);
  useEffect(() => {
    dispatch(getEmiAction(id));
  }, []);
  useEffect(() => {
    if (getEmi?.status === 200) {
        setData(getEmi?.data);
    }
  }, [getEmi]);
  return (
    <div>
      {getEmiLoading && <Backdrop />}
      <div className="flex justify-end gap-5">
        {datas?.truckData?.isEmi && (
          <Link
            to={`addEmi?truckId=${searchParams.get("truckId")}`}
            className="primaryButton w-fit bg-green-600 hover:bg-green-800"
          >
            Pay Emi
          </Link>
        )}
      </div>
      <EmiTruckDetailsCard data={datas?.truckData} />
      {datas?.emiData?.length > 0 && datas?.emiData?.map((data) => (
        <EmiCard data={data}  emiDelete={ datas?.emiData?.length > 0 ? datas?.emiData[0]?.emiPayMonth: ""}  />
      ))}
    </div>
  );
}

export default TruckEmiList;
