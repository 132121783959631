import React, { useState } from "react";
import Image from "../../../../assets/images/budget.png";
import { Dialog, DialogTrigger } from "../../../../ui/dialog";
import DeleteExpenseTruck from "./DEFDelete";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ImagePopUpDownload from "../../../../components/imagePopUpDownload";
import DEFDelete from "./DEFDelete";
import { FcImageFile } from "react-icons/fc";
import { TfiTrash } from "react-icons/tfi";
import { FaRegEdit } from "react-icons/fa";

function DEFListCard({ data }) {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(amount)
      : "₹0.00";
  return (
    <div
      key={data._id}
      className="bg-white p-4 rounded-md shadow-lg mt-4  w-full text-xs"
    >
      <Dialog open={showDeleteModal}>
        <DEFDelete setShowModal={setShowDeleteModal} id={data?._id} />
      </Dialog>
      <div className=" grid xl:grid-cols-6 lg:grid-cols-4 grid-cols-2 xl:grid-rows-2 lg:grid-rows-4  lg:gap-x-20 gap-x-9  lg:gap-y-6  gap-y-4">
        <div className="lg:border-r lg:border-r-[#F1F2F6] lg:border-b-0 border-b border-b-[#F1F2F6] row-span-full flex flex-col gap-2 lg:col-span-1 col-span-2 pb-5 lg:text-left text-center  relative">
          <label htmlFor="" className="relative">
            Truck Number
          </label>
          <p
            className=" textPrimary uppercase "
            onClick={() =>
              navigate("/company/truck/details?truckId=" + data?.truckId?._id)
            }
          >
            {data?.truckId?.truckNumber}
          </p>
          {data?.billImage?.url && (
            <div className="">
              <Dialog open={showImageModal}>
                <button
                  type="button"
                  onClick={() => setShowImageModal(true)}
                  className="ghostButton font-semibold flex gap-1 items-center md:mx-0 mx-auto"
                >
                  <FcImageFile className="text-2xl" /> Image
                </button>
                <ImagePopUpDownload
                  image={data?.billImage}
                  setShowModal={setShowImageModal}
                  imagename={`toll_${data?._id}`}
                />
              </Dialog>
            </div>
          )}

          <div className="flex md:hidden ml-auto absolute z-30 left-0">
            <button
              type="button"
              onClick={() => setShowDeleteModal(true)}
              className="ghostButton text-[#DC1919] font-semibold  flex gap-1 hover:text-red-700 disabled:text-red-200"
            >
              <TfiTrash className="text-xl" />
            </button>
          </div>
          <div className=" flex md:hidden ml-auto absolute z-30 right-0">
            <button
              type="button"
              className="ghostButton font-semibold  flex gap-1"
              onClick={() =>
                navigate(`/company/truck/addDef?id=${data?._id}`)
              }
            >
              <FaRegEdit className="text-xl" />
            </button>
          </div>
        </div>
        <div className="">
          <label htmlFor="">Brand Name</label>
          <p className="textSecondary">{data?.defCompanyName}</p>
        </div>
        <div className="">
          <label htmlFor="">Date</label>
          <p className="textSecondary">
            {moment(data?.date).format("DD / MMM / YYYY")}
          </p>
        </div>
        <div className="">
          <label htmlFor="">Liter</label>
          <p className="textSecondary">{data?.quantity} Ltr</p>
        </div>
        <div className="">
          <label htmlFor="">Total Cost</label>
          <p className="textSecondary">{formatAmount(data?.cost)}</p>
        </div>
        <div className=" gap-6 flex-wrap lg:flex hidden">
          <div className="  ">
            <button
              type="button"
              className="ghostButton font-semibold  flex gap-1"
              onClick={() =>
                navigate(`/company/truck/addDef?id=${data?._id}`)
              }
            >
              <FaRegEdit className="text-xl" />
              Edit
            </button>
          </div>
          <div className="lg:flex hidden">
            <button
              type="button"
              onClick={() => setShowDeleteModal(true)}
              className="ghostButton text-[#DC1919] font-semibold  flex gap-1 hover:text-red-700 disabled:text-red-200"
            >
              <TfiTrash className="text-xl" /> Delete
            </button>
          </div>
        </div>
        <div className="">
          <label htmlFor="">Bill No</label>
          <p className="textSecondary">{data?.billNumber} Ltr</p>
        </div>
      </div>
    </div>
  );
}

export default DEFListCard;
// <div key={data._id}>
//   <div className="grid grid-cols-12 gap-x-7 my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl">
//     <div className="lg:col-span-2 col-span-6 row-span-1 justify-self-center my-auto lg:row-span-3">
//       <button type="button" className="rounded-full bg-gray-400" onClick={()=>setShowImageModal(true)}>
//         <img
//           src={data?.billImage === "" ? Image : data?.billImage}
//           alt=""
//           className={` ${
//             data?.billImage === "" ? "" : "rounded-full"
//           }  h-[100px] w-[100px]`}
//         />
//       </button>
//     </div>
//     {data?.billImage &&
//     <Dialog open={showImageModal}>
//         <ImagePopUpDownload image={data?.billImage} setShowModal={setShowImageModal} imagename={`def_${data?._id}`} />
//       </Dialog>
//     }
//     <div className="mb-5 text-center md:text-left lg:col-span-10 col-span-6 row-span-1 self-center lg:self-auto">
//       <button type="button" className="font-bold text-blue-600 text-xl">
//         {data?.defCompanyName}, {data?.billNumber}
//       </button>
//       <p className="textgrey text-base uppercase">
//         {moment(data.date).format("DD/MMM/YYYY")}
//       </p>
//     </div>
//     <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center">
//       <div className="md:text-left">
//         <h3 className="textgrey text-xs">Cost</h3>
//         <p>Rs.{data?.cost}</p>
//       </div>
//       <div className="md:text-left">
//         <h3 className="textgrey text-xs">Liter</h3>
//         <p>Rs.{data?.quantity}</p>
//       </div>
//       <div className="col-span-2">
//         <h3 className="textgrey text-xs md:text-left">Description</h3>
//         <p>{data?.description}</p>
//       </div>

//       <div className="my-auto ">
//         <button
//           type="button"
//           className="secondaryButton h-full"
//           onClick={() => navigate(`/company/truck/addExtraExpense?id=${data?._id}`)}
//         >
//           Edit Expense Details
//         </button>
//       </div>
//       <div className="my-auto col-span-1">
//         <Dialog open={showDeleteModal}>
//           <DialogTrigger asChild>
//             <button
//               type="button"
//               onClick={() => setShowDeleteModal(true)}
//               className="primaryButton bg-red-600 hover:bg-red-800 md:px-4 px-2"
//             >
//               Delete
//             </button>
//           </DialogTrigger>
//           <DeleteExpenseTruck setShowModal={setShowDeleteModal} id={data?._id} />
//         </Dialog>
//       </div>
//     </div>
//   </div>
// </div>
