import React, { useEffect, useState } from "react";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../ui/dialog";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { deleteDEFAction, getDEFFilterAction } from "../../../../redux/company/truck/def/action";


function DEFDelete({ id, setShowModal }) {
  const dispatch = useDispatch()
  const {
    deleteDEF,
    deleteDEFLoading,
    deleteDEFError,
  } = useSelector((state) => state.DEFSection);
  let [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object({
    deleteValue: Yup.string()
      .required("This field is required")
      .oneOf(["DEF"], 'You must type "DEF" to confirm'),
  });
  useEffect(() => {
    if (loading) {
      if (deleteDEF?.status === 200) {
        toast.success(deleteDEF.message, {
          id: "deleteFuel-toast",
        });
        const id = searchParams.get("truckId");
        const payload = {
          truckId: id,
        };
        dispatch(getDEFFilterAction(payload));
        setLoading(false);
        setShowModal(false);
      } else if (deleteDEFError?.status === 404) {
        toast.error(deleteDEFError.message, {
          id: "deleteFuel-toast",
        });
        setLoading(false);
      } else if (deleteDEFError?.status === 400) {
        toast.error(deleteDEFError.message, {
          id: "deleteFuel-toast",
        });
        setShowModal(false);
        setLoading(false);
      } else if (deleteDEFError?.status === 500) {
        toast.error(deleteDEFError.error, {
          id: "deleteFuel-toast",
        });
        setLoading(false);
      }
    }
  }, [deleteDEF, deleteDEFError, loading]);

  const onSubmit = (values) => {
    if (values.deleteValue === "DEF") {
      dispatch(deleteDEFAction(id))
      toast.loading("Deleting Fuel...", { id: "deleteFuel-toast" });
      setLoading(true);
    }
  };

  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle>Delete DEF</DialogTitle>
      </DialogHeader>
        <div className="text-xl mt-5 font-semibold">
          Are you sure you want to{" "}
          <span className="text-red-500 font-bold">Delete</span> this DEF?
        </div>
      <Formik
        initialValues={{ deleteValue: "" }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="">
            <div className="mb-4">
              <label htmlFor="deleteValue" className="text-gray-700">
                To confirm, type <span className="font-bold">"DEF"</span> in Capital Letter
                the box below*
              </label>
              <Field
                type="text"
                id="deleteValue"
                name="deleteValue"
                placeholder="DEF"
              />
              <ErrorMessage
                name="deleteValue"
                component="div"
                className="text-red-600"
                />
            </div>
            <DialogFooter>
              <button
                className="ghostButton"
                onClick={() => setShowModal(false)}
                disabled={deleteDEFLoading}
                type="button"
              >
                Close
              </button>
              <button
                className="primaryButton bg-red-600 hover:bg-red-800 w-fit"
                type="submit"
                disabled={deleteDEFLoading}
              >
                Delete
              </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default DEFDelete;
