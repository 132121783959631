import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import DatePicker from "../../datepicker/datePicker";
import FileMultiUpload from "../../fileupload/fileMuiltipleUpload";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { getTruckPermitByTruckIdAction,  getTruckPermitAction,
  patchTruckPermitAction, } from "../../../redux/company/truck/action";

function UpdatePermit({ truckId, setShowModal, truckNumber }) {
  const dispatch = useDispatch();
  const {
    patchTruckPermit,
    patchTruckPermitLoading,
    patchTruckPermitError,
  } = useSelector((state) => state.Companytrucksection);

  const [loader, setLoader] = useState(false);
  const initialValues = {
    permitNumber: "",
    permitCost: null,
    permitImage: [],
    permitTill: "",
  };

  const validationSchema = Yup.object({
    permitNumber: Yup.string().required("This is required"),
    permitCost: Yup.number().required("This is required"),
    permitTill: Yup.date().required("This is required"),
  });

  useEffect(() => {
    if (loader) {
      if (patchTruckPermit?.status === 200) {
        toast.success(patchTruckPermit?.data, { id: "permit_toast" });
        setLoader(false);
        dispatch(getTruckPermitAction());
        dispatch(getTruckPermitByTruckIdAction(truckId));
        setShowModal(false);
      }
    }
  }, [patchTruckPermit]);
  useEffect(() => {
    if (loader) {
      if (patchTruckPermitError?.status === 400) {
        toast.error(patchTruckPermitError?.data, { id: "permit_toast" });
        setLoader(false);
      }
      if (patchTruckPermitError?.status === 404) {
        toast.error(patchTruckPermitError?.data, { id: "permit_toast" });
        setLoader(false);
      }
      if (patchTruckPermitError?.status === 500) {
        toast.error(patchTruckPermitError?.error, { id: "permit_toast" });
        setLoader(false);
      }
    }
  }, [patchTruckPermitError]);

  const onSubmit = async (values) => {
    console.log(values);
    const payload = {
      id: truckId,
      body: values,
    };
    dispatch(patchTruckPermitAction(payload));
    toast.loading("Saving....", { id: "permit_toast" });
    setLoader(true);
  };
  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle className="flex gap-1 ">
          Update Permit of{" "}
          <div className="uppercase font-black underline">{truckNumber}</div>
        </DialogTitle>
      </DialogHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, isSubmitting, errors }) => (
          <Form>
            <div className="grid grid-cols-2 gap-4 overflow-y-auto max-h-[60vh] py-4 ">
              <div className="">
                <label htmlFor="permitNumber">Permit Policy Number</label>
                <div className="w-full">
                  <Field
                    id="permitNumber"
                    type="text"
                    name="permitNumber"
                    className=""
                    placeHolder="Permit Policy Number"
                  />
                  <ErrorMessage
                    name="permitNumber"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="">
                <label htmlFor="permitTill">Permit End Date</label>
                <div className="w-full">
                  <DatePicker
                    id="permitTill"
                    name="permitTill"
                    className=""
                    placeHolder="Permit End Date"
                  />
                  <ErrorMessage
                    name="permitTill"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="">
                <label htmlFor="permitCost">Permit Cost</label>
                <div className="w-full">
                  <Field
                    id="permitCost"
                    type="number"
                    name="permitCost"
                    className=""
                    placeHolder="Permit Cost"
                  />
                  <ErrorMessage
                    name="permitCost"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className=" col-span-2">
                <label
                  htmlFor="permitImage"
                  className="text-black text-sm font-bold"
                >
                  Permit Image
                </label>
                <FileMultiUpload
                  setFieldValue={setFieldValue}
                  values={values.permitImage}
                  name="permitImage" // disabled={readOnly} values={values}
                />
              </div>
            </div>
            <DialogFooter className="md:gap-5 col-span-2">
              <button
                className="ghostButton md:w-fit capitalize"
                type="button"
                onClick={() => setShowModal(false)}
                disabled={patchTruckPermitLoading}
              >
                Close
              </button>

              <button
                type="submit"
                className="primaryButton md:w-fit capitalize"
                disabled={patchTruckPermitLoading}
              >
                Save
              </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default UpdatePermit;
