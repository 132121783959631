import React, { useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogTrigger } from "../../../ui/dialog";
import DeleteClient from "./deleteClient";
import { Badge } from "../../../ui/badge";
import { TfiTrash } from "react-icons/tfi";
import { FaRegEdit } from "react-icons/fa";

function ClientListCard({ data, reroute = false, state, city }) {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(amount)
      : "₹0.00";
  return (
    <div
      key={data._id}
      className="bg-white p-4 rounded-md shadow-lg mt-4  w-full text-xs"
    >
      <Dialog open={showDeleteModal}>
        <DeleteClient setShowModal={setShowDeleteModal} id={data?._id} />
      </Dialog>
      <div className=" grid xl:grid-cols-6 lg:grid-cols-4 grid-cols-2 grid-rows-2  lg:gap-x-20 gap-x-9  lg:gap-y-6  gap-y-4">
        <div
          className="lg:border-r lg:border-r-[#F1F2F6] lg:border-b-0 border-b border-b-[#F1F2F6] row-span-full flex flex-col gap-2 lg:col-span-1 col-span-2 pb-5 lg:text-left text-center  relative"
          onClick={
            reroute ? () => navigate(`details?clientId=${data?._id}`) : null
          }
        >
          <label htmlFor="">Company (Owner Name)</label>

          <p className=" textPrimary uppercase ">
            {data?.name} ({data?.ownerName})
          </p>

          <div className="flex md:hidden ml-auto absolute z-30 left-0">
            <button
              type="button"
              onClick={() => setShowDeleteModal(true)}
              className="ghostButton text-[#DC1919] font-semibold  flex gap-1 hover:text-red-700 disabled:text-red-200"
            >
              <TfiTrash className="text-xl" />
            </button>
          </div>
          <div className=" flex flex-col gap-3 md:hidden ml-auto absolute z-30 right-0">
            <button
              type="button"
              className="ghostButton font-semibold  flex ml-auto"
              onClick={() =>
                navigate(`/company/client/addClient?id=${data?._id}`)
              }
            >
              <FaRegEdit className="text-xl" />
            </button>
          </div>
        </div>

        <div className="">
          <label htmlFor="">Phone No</label>
          <p className="textSecondary">{data?.phoneNumber}</p>
        </div>
        <div className="">
          <label htmlFor="">GST No</label>
          <p className="textSecondary">{data?.gstNumber}</p>
        </div>
        <div className="">
          <label htmlFor="">Total payment</label>
          <p className="textSecondary">{formatAmount(data?.totalpayment)}</p>
        </div>
        <div className="">
          <label htmlFor="">Pending payment</label>
          <p className="textSecondary">{formatAmount(data?.pendingAmount)}</p>
        </div>
        <div className=" gap-6 flex-wrap lg:flex hidden">
          <div className="  ">
            <button
              type="button"
              className="ghostButton font-semibold  flex gap-1"
              onClick={() =>
                navigate(`/company/client/addClient?id=${data?._id}`)
              }
            >
              <FaRegEdit className="text-xl" />
              Edit
            </button>
          </div>

          <div className="lg:flex hidden">
            <button
              type="button"
              onClick={() => setShowDeleteModal(true)}
              className="ghostButton text-[#DC1919] font-semibold  flex gap-1 hover:text-red-700 disabled:text-red-200"
            >
              <TfiTrash className="text-xl" /> Delete
            </button>
          </div>
        </div>
        {(state && city) &&
        <div className="col-span-2">
          <label htmlFor="">Address</label>
          <p className="textSecondary">
            {data?.address}, {city}, {state}, {data?.country}, {data.zipCode}
          </p>
        </div>
        }
        <div className="col-span-2">
          <label htmlFor="">Description</label>
          <p className="textSecondary">
            {data?.description ? data?.description : "--"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ClientListCard;
// <div key={data._id}>
//   <div className="grid grid-cols-4 gap-x-7 my-10 md:py-2 sm:py-5 py-2 md:px-10 px-5 bg-white rounded-lg shadow-2xl">
//     <div className=" col-span-4 row-span-1 self-center lg:self-auto mb-5">
//       <button
//         type="button"
//         className="font-bold text-blue-600 text-xl"
//         onClick={
//           reroute
//             ? () =>
//                 navigate(
//                   `details?clientId=${data?._id}`
//                 )
//             : null
//         }
//       >
//         {data?.name}, {data?.ownerName}
//       </button>

//     </div>
//     <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-4 self-center">
//       <div className="md:text-left">
//         <h3 className="textgrey text-xs">Phone Number</h3>
//         <p>{data?.phoneNumber}</p>
//       </div>
//       <div className="md:text-left">
//         <h3 className="textgrey text-xs">GST Number</h3>
//         <p>{data?.gstNumber}</p>
//       </div>
//       <div>
//         <h3 className="textgrey text-xs md:text-left ">Total Payment</h3>
//         <p className="flex md:justify-start">Rs. {data?.totalpayment}</p>
//       </div>

//       <div>
//         <h3 className="textgrey text-xs md:text-left">Pending Amount</h3>
//         <p>Rs. {data?.pendingAmount}</p>
//       </div>
//       <div className="col-span-2">
//         <h3 className="textgrey text-xs md:text-left">Description</h3>
//         <p>{data?.description}</p>
//       </div>
//       {!data?.disabled && (
//         <>
//           <div className="my-auto ">
//             <button
//               type="button"
//               className="secondaryButton h-full"
//               onClick={() =>
//                 navigate(`/company/client/addClient?id=${data?._id}`)
//               }
//             >
//               Edit Client Details
//             </button>
//           </div>
//           <div className="my-auto col-span-1">
//             <Dialog open={showDeleteModal}>
//               <DialogTrigger asChild>
//                 <button
//                   type="button"
//                   onClick={() => setShowDeleteModal(true)}
//                   className="primaryButton bg-red-600 hover:bg-red-800 md:px-4 px-2"
//                 >
//                   Delete
//                 </button>
//               </DialogTrigger>
//               <DeleteClient
//                 setShowModal={setShowDeleteModal}
//                 id={data?._id}
//               />
//             </Dialog>
//           </div>
//         </>
//       )}
//     </div>
//   </div>
// </div>
