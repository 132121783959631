import { GET_ADMIN_DASHBOARD_COMPANY_FILTER, GET_ADMIN_DASHBOARD_COMPANY_FILTER_ERROR, GET_ADMIN_DASHBOARD_COMPANY_FILTER_SUCCESS, GET_ADMIN_DASHBOARD_COMPANY_GRAPH, GET_ADMIN_DASHBOARD_COMPANY_GRAPH_ERROR, GET_ADMIN_DASHBOARD_COMPANY_GRAPH_SUCCESS, GET_ADMIN_DASHBOARD_EMPLOYEE_FILTER, GET_ADMIN_DASHBOARD_EMPLOYEE_FILTER_ERROR, GET_ADMIN_DASHBOARD_EMPLOYEE_FILTER_SUCCESS, GET_ADMIN_DASHBOARD_EMPLOYEE_GRAPH, GET_ADMIN_DASHBOARD_EMPLOYEE_GRAPH_ERROR, GET_ADMIN_DASHBOARD_EMPLOYEE_GRAPH_SUCCESS, GET_ADMIN_DASHBOARD_EXPENSES_FILTER, GET_ADMIN_DASHBOARD_EXPENSES_FILTER_ERROR, GET_ADMIN_DASHBOARD_EXPENSES_FILTER_SUCCESS, GET_ADMIN_DASHBOARD_EXPENSES_GRAPH, GET_ADMIN_DASHBOARD_EXPENSES_GRAPH_ERROR, GET_ADMIN_DASHBOARD_EXPENSES_GRAPH_SUCCESS, GET_ADMIN_DASHBOARD_PROFIT_LOSS, GET_ADMIN_DASHBOARD_PROFIT_LOSS_ERROR, GET_ADMIN_DASHBOARD_PROFIT_LOSS_SUCCESS, GET_ADMIN_DASHBOARD_SUBSCRIPTION_GRAPH, GET_ADMIN_DASHBOARD_SUBSCRIPTION_GRAPH_ERROR, GET_ADMIN_DASHBOARD_SUBSCRIPTION_GRAPH_SUCCESS, GET_ADMIN_DASHBOARD_SUBSCRIPTION_PF, GET_ADMIN_DASHBOARD_SUBSCRIPTION_PF_ERROR, GET_ADMIN_DASHBOARD_SUBSCRIPTION_PF_SUCCESS, GET_ADMIN_DASHBOARD_TRUCK_GRAPH, GET_ADMIN_DASHBOARD_TRUCK_GRAPH_ERROR, GET_ADMIN_DASHBOARD_TRUCK_GRAPH_SUCCESS } from "../../actions";

const INIT_STATE = {
  getAdminDashboardSubscriptionGraphPF: {},
  getAdminDashboardSubscriptionGraphPFLoading: false,
  getAdminDashboardSubscriptionGraphPFError: "",
  getAdminDashboardTruckGraph: {},
  getAdminDashboardTruckGraphLoading: false,
  getAdminDashboardTruckGraphError: "",
  getAdminDasboardCompanyFilter: {},
  getAdminDasboardCompanyFilterLoading: false,
  getAdminDasboardCompanyFilterError: "",
  getAdminDasboardCompanyGraph: {},
  getAdminDasboardCompanyGraphLoading: false,
  getAdminDasboardCompanyGraphError: "",
  getAdminDashboardEmployeeFilter: {},
  getAdminDashboardEmployeeFilterLoading: false,
  getAdminDashboardEmployeeFilterError: "",
  getAdminDashboardEmployeeGraph: {},
  getAdminDashboardEmployeeGraphLoading: false,
  getAdminDashboardEmployeeGraphError: "",
  getAdminDashboardExpensesFilter: {},
  getAdminDashboardExpensesFilterLoading: false,
  getAdminDashboardExpensesFilterError: "",
  getAdminDashboardExpensesGraph: {},
  getAdminDashboardExpensesGraphLoading: false,
  getAdminDashboardExpensesGraphError: "",
  getAdminDashboardProfiltLoss: {},
  getAdminDashboardProfiltLossLoading: false,
  getAdminDashboardProfiltLossError: "",
  getAdminDashboardSubscriptionGraph: {},
  getAdminDashboardSubscriptionGraphLoading: false,
  getAdminDashboardSubscriptionGraphError: "",
};

const AdminDashboardSection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ADMIN_DASHBOARD_SUBSCRIPTION_PF:
      return {
        ...state,
        getAdminDashboardSubscriptionGraphPF: {},
        getAdminDashboardSubscriptionGraphPFLoading: true,
      };
    case GET_ADMIN_DASHBOARD_SUBSCRIPTION_PF_SUCCESS:
      return {
        ...state,
        getAdminDashboardSubscriptionGraphPF: action.payload,
        getAdminDashboardSubscriptionGraphPFLoading: false,
        getAdminDashboardSubscriptionGraphPFError: "",
      };

    case GET_ADMIN_DASHBOARD_SUBSCRIPTION_PF_ERROR:
      return {
        ...state,
        getAdminDashboardSubscriptionGraphPFLoading: false,
        getAdminDashboardSubscriptionGraphPFError: action.payload,
      };
    case GET_ADMIN_DASHBOARD_TRUCK_GRAPH:
      return {
        ...state,
        getAdminDashboardTruckGraph: {},
        getAdminDashboardTruckGraphLoading: true,
      };
    case GET_ADMIN_DASHBOARD_TRUCK_GRAPH_SUCCESS:
      return {
        ...state,
        getAdminDashboardTruckGraph: action.payload,
        getAdminDashboardTruckGraphLoading: false,
        getAdminDashboardTruckGraphError: "",
      };

    case GET_ADMIN_DASHBOARD_TRUCK_GRAPH_ERROR:
      return {
        ...state,
        getAdminDashboardTruckGraphLoading: false,
        getAdminDashboardTruckGraphError: action.payload,
      };
    case GET_ADMIN_DASHBOARD_COMPANY_FILTER:
      return {
        ...state,
        getAdminDasboardCompanyFilter: {},
        getAdminDasboardCompanyFilterLoading: true,
      };
    case GET_ADMIN_DASHBOARD_COMPANY_FILTER_SUCCESS:
      return {
        ...state,
        getAdminDasboardCompanyFilter: action.payload,
        getAdminDasboardCompanyFilterLoading: false,
        getAdminDasboardCompanyFilterError: "",
      };

    case GET_ADMIN_DASHBOARD_COMPANY_FILTER_ERROR:
      return {
        ...state,
        getAdminDasboardCompanyFilterLoading: false,
        getAdminDasboardCompanyFilterError: action.payload,
      };
    case GET_ADMIN_DASHBOARD_COMPANY_GRAPH:
      return {
        ...state,
        getAdminDasboardCompanyGraph: {},
        getAdminDasboardCompanyGraphLoading: true,
      };
    case GET_ADMIN_DASHBOARD_COMPANY_GRAPH_SUCCESS:
      return {
        ...state,
        getAdminDasboardCompanyGraph: action.payload,
        getAdminDasboardCompanyGraphLoading: false,
        getAdminDasboardCompanyGraphError: "",
      };

    case GET_ADMIN_DASHBOARD_COMPANY_GRAPH_ERROR:
      return {
        ...state,
        getAdminDasboardCompanyGraphLoading: false,
        getAdminDasboardCompanyGraphError: action.payload,
      };
    case GET_ADMIN_DASHBOARD_EMPLOYEE_FILTER:
      return {
        ...state,
        getAdminDashboardEmployeeFilter: {},
        getAdminDashboardEmployeeFilterLoading: true,
      };
    case GET_ADMIN_DASHBOARD_EMPLOYEE_FILTER_SUCCESS:
      return {
        ...state,
        getAdminDashboardEmployeeFilter: action.payload,
        getAdminDashboardEmployeeFilterLoading: false,
        getAdminDashboardEmployeeFilterError: "",
      };

    case GET_ADMIN_DASHBOARD_EMPLOYEE_FILTER_ERROR:
      return {
        ...state,
        getAdminDashboardEmployeeFilterLoading: false,
        getAdminDashboardEmployeeFilterError: action.payload,
      };
    case GET_ADMIN_DASHBOARD_EMPLOYEE_GRAPH:
      return {
        ...state,
        getAdminDashboardEmployeeGraph: {},
        getAdminDashboardEmployeeGraphLoading: true,
      };
    case GET_ADMIN_DASHBOARD_EMPLOYEE_GRAPH_SUCCESS:
      return {
        ...state,
        getAdminDashboardEmployeeGraph: action.payload,
        getAdminDashboardEmployeeGraphLoading: false,
        getAdminDashboardEmployeeGraphError: "",
      };

    case GET_ADMIN_DASHBOARD_EMPLOYEE_GRAPH_ERROR:
      return {
        ...state,
        getAdminDashboardEmployeeGraphLoading: false,
        getAdminDashboardEmployeeGraphError: action.payload,
      };
    case GET_ADMIN_DASHBOARD_EXPENSES_FILTER:
      return {
        ...state,
        getAdminDashboardExpensesFilter: {},
        getAdminDashboardExpensesFilterLoading: true,
      };
    case GET_ADMIN_DASHBOARD_EXPENSES_FILTER_SUCCESS:
      return {
        ...state,
        getAdminDashboardExpensesFilter: action.payload,
        getAdminDashboardExpensesFilterLoading: false,
        getAdminDashboardExpensesFilterError: "",
      };

    case GET_ADMIN_DASHBOARD_EXPENSES_FILTER_ERROR:
      return {
        ...state,
        getAdminDashboardExpensesFilterLoading: false,
        getAdminDashboardExpensesFilterError: action.payload,
      };
    case GET_ADMIN_DASHBOARD_EXPENSES_GRAPH:
      return {
        ...state,
        getAdminDashboardExpensesGraph: {},
        getAdminDashboardExpensesGraphLoading: true,
      };
    case GET_ADMIN_DASHBOARD_EXPENSES_GRAPH_SUCCESS:
      return {
        ...state,
        getAdminDashboardExpensesGraph: action.payload,
        getAdminDashboardExpensesGraphLoading: false,
        getAdminDashboardExpensesGraphError: "",
      };

    case GET_ADMIN_DASHBOARD_EXPENSES_GRAPH_ERROR:
      return {
        ...state,
        getAdminDashboardExpensesGraphLoading: false,
        getAdminDashboardExpensesGraphError: action.payload,
      };
    case GET_ADMIN_DASHBOARD_PROFIT_LOSS:
      return {
        ...state,
        getAdminDashboardProfiltLoss: {},
        getAdminDashboardProfiltLossLoading: true,
      };
    case GET_ADMIN_DASHBOARD_PROFIT_LOSS_SUCCESS:
      return {
        ...state,
        getAdminDashboardProfiltLoss: action.payload,
        getAdminDashboardProfiltLossLoading: false,
        getAdminDashboardProfiltLossError: "",
      };

    case GET_ADMIN_DASHBOARD_PROFIT_LOSS_ERROR:
      return {
        ...state,
        getAdminDashboardProfiltLossLoading: false,
        getAdminDashboardProfiltLossError: action.payload,
      };
      case GET_ADMIN_DASHBOARD_SUBSCRIPTION_GRAPH:
        return {
          ...state,
          getAdminDashboardSubscriptionGraph: {},
          getAdminDashboardSubscriptionGraphLoading: true,
        };
      case GET_ADMIN_DASHBOARD_SUBSCRIPTION_GRAPH_SUCCESS:
        return {
          ...state,
          getAdminDashboardSubscriptionGraph: action.payload,
          getAdminDashboardSubscriptionGraphLoading: false,
          getAdminDashboardSubscriptionGraphError: "",
        };
  
      case GET_ADMIN_DASHBOARD_SUBSCRIPTION_GRAPH_ERROR:
        return {
          ...state,
          getAdminDashboardSubscriptionGraphLoading: false,
          getAdminDashboardSubscriptionGraphError: action.payload,
        };
    default:
      return { ...state };
  }
};

export default AdminDashboardSection;
