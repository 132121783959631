import React, { useState } from "react";
import { DialogContent, DialogFooter } from "../ui/dialog";
import { saveAs } from "file-saver";
import { motion } from "framer-motion";
import { FaArrowLeft, FaArrowRight, FaTimes, FaDownload } from "react-icons/fa";

function ImagePopUpDownload({ image, setShowModal, imagename, isArray = false }) {
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current image index

  // Function to handle image download
  const handleDownload = () => {
    const currentImage = isArray ? image[currentIndex]?.url : image?.url;
    saveAs(currentImage, `${imagename}_image.jpg`);
  };

  // Function to handle "Next" button click
  const handleNext = () => {
    if (currentIndex < image?.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Function to handle "Previous" button click
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <DialogContent className="bg-gray-100">
      <div className="relative h-full w-full flex flex-col justify-center items-center">
        {/* Navigation and Close Buttons at the top */}
        <div className="absolute top-0 w-full flex justify-between p-4 items-center z-20">
          {/* Previous Button */}
        {isArray && <> <button
            className="text--gray-800 hover:text-gray-600 "
            onClick={handlePrevious}
            disabled={currentIndex === 0}
          >
            <FaArrowLeft size={20} />
          </button>

          <button
            className="text--gray-800 hover:text-gray-600"
            onClick={()=>handleNext()}
            disabled={currentIndex === image?.length - 1}
          >
            <FaArrowRight size={20} />
          </button></>}
        </div>

        {/* Image Slider with Framer Motion */}
        <motion.div
          key={currentIndex} // Ensures Framer Motion recognizes index changes
          className="w-full flex justify-center items-center"
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={{ duration: 0.5 }}
        >
          {isArray ? (
            <motion.img
              src={image[currentIndex]?.url}
              alt="Preview"
              className="h-full w-full object-contain"
            />
          ) : (
            <img src={image?.url} alt="Preview" className="h-full w-full object-contain" />
          )}
        </motion.div>

          </div>
        {/* Dialog Footer for Download Button */}
        <DialogFooter>
        <button
          className="ghostButton"
          onClick={() => setShowModal(false)}
          type="button"
        >
          Close
        </button>
          <button
            className="primaryButton w-fit flex"
            onClick={handleDownload}
            type="button"
          >
            <FaDownload className="mr-2" /> Download
          </button>
        </DialogFooter>
    </DialogContent>
  );
}

export default ImagePopUpDownload;
