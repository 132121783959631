import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyTripDetailsFilterAction,
  getCompanyTripDetailsIdAction,
  patchCompanyTripPaymentAction,
} from "../../../redux/company/trip/action";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";
import { useSearchParams } from "react-router-dom";
import { getCompanyDetailsAction } from "../../../redux/company/action";
import FileUpload from "../../fileupload/fileUpload";
import { getCompanyTruckDetailsIdAction } from "../../../redux/company/truck/action";

function Payment({ data, setShowModal, showModal }) {
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();
  const { patchCompanyTripPayment, patchCompanyTripPaymentError } = useSelector(
    (state) => state.CompanyTripsection
  );
  const { getCompanyDetails } = useSelector((state) => state.Companysection);

  const [loading, setLoading] = useState(false);
  const [totalPayment, setTotalPayment] = useState(0);
  const [accountNumber, setAccountNumber] = useState("");

  useEffect(() => {
    if (showModal) {
      dispatch(getCompanyDetailsAction());
    }
  }, [showModal, dispatch]);

  useEffect(() => {
    if (getCompanyDetails?.status === 200) {
      setAccountNumber(getCompanyDetails?.data?.company.accountNumber || "");
    }
  }, [getCompanyDetails]);

  useEffect(() => {
    let paymentAmount = data?.endingLoad * data?.ratePerWeight || 0;
    setTotalPayment(paymentAmount);
  }, [data]);

  const initialValues = {
    paymentAccount: data?.accountNumber || accountNumber || "",
    paymentType: data?.paymentType || "Net Banking",
    paymentDate: data?.paymentDate || new Date().toISOString().slice(0, 10), // Format as YYYY-MM-DD
    transitionNumber: data?.transitionNumber || "",
    checkImage: data?.checkImage || "",
    checkNumber: data?.checkNumber || "",
    gstOnPayment: data?.gstOnPayment || 0,
    anyDeduction: data?.anyDeduction || 0,
  };

  const validationSchema = Yup.object({
    paymentType: Yup.string().required("Payment Type is required"),
    paymentDate: Yup.date().required("Payment Date is required"),
    paymentAccount: Yup.string().when("paymentType", {
      is: (val) => val !== "Cash",
      then: () => Yup.string().required("Account Number is required"),
    }),
    checkNumber: Yup.string().when("paymentType", {
      is: "Check",
      then: () => Yup.string().required("Check Number is required"),
    }),
    checkImage: Yup.string().when("paymentType", {
      is: "Check",
      then: () => Yup.string().required("Check Image is required"),
    }),
  });

  const onSubmit = (values) => {
    const gstAmount = (totalPayment * values.gstOnPayment) / 100;
    const amountAfterGST = totalPayment - gstAmount;
    const finalAmount = amountAfterGST - (values.anyDeduction || 0);

    let payload = {
      id: data._id,
      body: {
        totalPayment: totalPayment ,
        paymentType: values.paymentType,
        paymentDate: values.paymentDate,
        transitionNumber: values.transitionNumber,
        finalPayment: finalAmount,
        gstOnPayment: values.gstOnPayment,
        anyDeduction: values.anyDeduction,
      },
    };

    if (values.paymentType !== "Cash") {
      payload.body.paymentAccount = values.paymentAccount;
    }
    if (values.paymentType === "Check") {
      payload.body.checkNumber = values.checkNumber;
      payload.body.checkImage = values.checkImage;
    }

    dispatch(patchCompanyTripPaymentAction(payload));
    toast.loading("Updating Trip Details...", { id: "updateDetails-toast" });
    setLoading(true);
  };

  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(amount)
      : "₹0.00";

  useEffect(() => {
    if (loading) {
      if (patchCompanyTripPayment?.status === 200) {
        toast.success(patchCompanyTripPayment.message, {
          id: "updateDetails-toast",
        });
        const truckId = searchParams.get("truckId");
        const tripId = searchParams.get("tripId");
        const payload = { truckId };
        if (tripId) {
          dispatch(getCompanyTripDetailsIdAction(tripId));
          dispatch(getCompanyTruckDetailsIdAction(truckId));
        } else {
          dispatch(getCompanyTripDetailsFilterAction(payload));
        }
        setLoading(false);
        setShowModal(false);
      }
    }
  }, [loading, patchCompanyTripPayment, dispatch, searchParams, setShowModal]);

  useEffect(() => {
    if (loading && patchCompanyTripPaymentError) {
      toast.error(patchCompanyTripPaymentError.message || "An error occurred", {
        id: "updateDetails-toast",
      });
      setLoading(false);
    }
  }, [loading, patchCompanyTripPaymentError]);

  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle>Payment Details</DialogTitle>
      </DialogHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values }) => {
          const gstAmount = (totalPayment * values.gstOnPayment) / 100;
          const amountAfterGST = totalPayment - gstAmount;
          const finalAmount = amountAfterGST - (values.anyDeduction || 0);

          return (
            <Form className="grid grid-cols-2 gap-4">
              <div className="mb-4">
                <label>Total Payment</label>
                <input type="text" value={formatAmount(totalPayment)} disabled />
              </div>

              {data?.isAdvancePayment && (
                <div className="mb-4">
                  <label>Advance Payment</label>
                  <input
                    type="text"
                    value={formatAmount(data?.advancePaymentCost)}
                    disabled
                  />
                </div>
              )}

              <div className="mb-4">
                <label htmlFor="gstOnPayment">GST Percentage</label>
                <Field
                  type="number"
                  id="gstOnPayment"
                  name="gstOnPayment"
                  placeholder="Enter GST in %"
                />
                <ErrorMessage name="gstOnPayment" component="div" className="error" />
              </div>

              <div>
                <label>Any Deduction</label>
                <Field type="number" name="anyDeduction" />
                <ErrorMessage name="anyDeduction" component="div" className="error" />
              </div>

              <div className="mb-4">
                <label>Incoming Payment (After GST and Deductions)</label>
                <input type="text" value={data?.isAdvancePayment  ? formatAmount(finalAmount-data?.advancePaymentCost) :  formatAmount(finalAmount)} disabled />
              </div>
              <div className="mb-4">
                <label>Final Payment Amount (After GST and Deductions)</label>
                <input type="text" value={formatAmount(finalAmount)} disabled />
              </div>

              <div className="mb-4">
                <label htmlFor="paymentType">Payment Type</label>
                <Field as="select" id="paymentType" name="paymentType">
                  <option value="Net Banking">Net Banking</option>
                  <option value="Cash">Cash</option>
                  <option value="Check">Check</option>
                  <option value="UPI">UPI</option>
                </Field>
                <ErrorMessage name="paymentType" component="div" className="error" />
              </div>

              {values.paymentType !== "Cash" && values.paymentType !== "Check" && (
                <div className="mb-4">
                  <label htmlFor="transitionNumber">Transaction Number</label>
                  <Field
                    type="text"
                    id="transitionNumber"
                    name="transitionNumber"
                    placeholder="Transaction Number"
                  />
                  <ErrorMessage name="transitionNumber" component="div" className="error" />
                </div>
              )}

              {values.paymentType !== "Cash" && (
                <div className="mb-4">
                  <label htmlFor="paymentAccount">Account Number</label>
                  <Field
                    type="text"
                    id="paymentAccount"
                    name="paymentAccount"
                    placeholder="Account Number"
                  />
                  <ErrorMessage name="paymentAccount" component="div" className="error" />
                </div>
              )}

              {values.paymentType === "Check" && (
                <>
                  <div className="mb-4">
                    <label htmlFor="checkNumber">Check Number</label>
                    <Field
                      type="text"
                      id="checkNumber"
                      name="checkNumber"
                      placeholder="Check Number"
                    />
                    <ErrorMessage name="checkNumber" component="div" className="error" />
                  </div>
                  <div className="mb-4">
                    <label>Check Image</label>
                    <FileUpload
                      name="checkImage"
                      onUpload={(url) => setFieldValue("checkImage", url)}
                    />
                    <ErrorMessage name="checkImage" component="div" className="error" />
                  </div>
                </>
              )}

              <DialogFooter className="col-span-2 mt-4">
                <button
                  type="button"
                  disabled={loading}
                  className="ghostButton"
                >
                 Close
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="primaryButton w-fit"
                >
                  {loading ? "Saving..." : "Save Payment"} {data?.isAdvancePayment  ? formatAmount(finalAmount-data?.advancePaymentCost) :  formatAmount(finalAmount)}
                </button>
              </DialogFooter>
            </Form>
          );
        }}
      </Formik>
    </DialogContent>
  );
}

export default Payment;
