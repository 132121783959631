import { getCompanyTruckListDropdownAction } from "../../../../redux/company/truck/action";
import {
  getDEFByIdAction,
  patchDEFAction,
  postDEFAction,
} from "../../../../redux/company/truck/def/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import DatePicker from "../../../datepicker/datePicker";
import FileUpload from "../../../fileupload/fileUpload";
import Backdrop from "../../../backdrop";

function AddDEF() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const { getCompanyTruckListDropdown, getCompanyTruckListDropdownLoading } = useSelector(
    (state) => state.Companytrucksection
  );
  const {
    getDEFById,
    getDEFByIdLoading,
    getDEFByIdError,
    patchDEF,
    patchDEFLoading,
    patchDEFError,
    postDEF,
    postDEFLoading,
    postDEFError,
  } = useSelector((state) => state.DEFSection);

  const [truckNumber, setTruckNumber] = useState("");
  const [truckData, setTruckData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    const edit = searchParams.get("id") === null;
    const id = searchParams.get("id");
    const truckId = searchParams.get("truckId");
    if (truckId !== null) {
      setTruckNumber(truckId);
    }
    if (!edit) {
      dispatch(getDEFByIdAction(id));
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(getCompanyTruckListDropdownAction(""));
  }, []);
  useEffect(() => {
    if (getCompanyTruckListDropdown?.status === 200) {
      setTruckData(getCompanyTruckListDropdown?.trucks);
    }
  }, [getCompanyTruckListDropdown]);
  useEffect(() => {
    const edit = searchParams.get("id") === null;

    if (!edit) {
      if (getDEFById?.status === 200) {
        if (getDEFById?.data?._id !== null) {
          setData(getDEFById?.data);
        }
      }
      if (getDEFByIdError === 400) {
        toast.error(getDEFByIdError?.message);
      } else if (getDEFByIdError === 404) {
        toast.error(getDEFByIdError.message);
      } else if (getDEFByIdError === 500) {
        toast.error(getDEFByIdError.error);
      }
    }
  }, [getDEFById, getDEFByIdError]);

  useEffect(() => {
    if (loader) {
      if (postDEF?.status === 200) {
        toast.success(postDEF.message, {
          id: "addDetails-toast",
        });
        setLoader(false);
        navigate(-1);
      } else if (postDEFError?.status === 404) {
        toast.error(postDEFError.message, {
          id: "addDetails-toast",
        });
        setLoader(false);
      } else if (postDEFError?.status === 400) {
        toast.error(postDEFError.message, {
          id: "addDetails-toast",
        });
        setLoader(false);
      } else if (postDEFError?.status === 500) {
        toast.error(postDEFError.error, {
          id: "addDetails-toast",
        });
        setLoader(false);
      }
    }
  }, [postDEF, postDEFError]);
  useEffect(() => {
    if (loader) {
      if (patchDEF?.status === 200) {
        toast.success(patchDEF.message, {
          id: "updateDetails-toast",
        });
        setLoader(false);
        navigate(-1);
      } else if (patchDEFError?.status === 404) {
        toast.error(patchDEFError.message, {
          id: "updateDetails-toast",
        });
      } else if (patchDEFError?.status === 400) {
        toast.error(patchDEFError.message, {
          id: "updateDetails-toast",
        });
        setLoader(false);
      } else if (patchDEFError?.status === 500) {
        toast.error(patchDEFError.error, {
          id: "updateDetails-toast",
        });
        setLoader(false);
      }
    }
  }, [patchDEF, patchDEFError]);

  const initialValues = {
    truckId: truckNumber || data?.truckId || "",
    date: data?.date ? new Date(data?.date) : "",
    billNumber: data?.billNumber || null,
    quantity: data?.quantity || null,
    defCompanyName: data?.defCompanyName || "",
    billImage: data?.billImage || "",
    cost: data?.cost || null,
    description: data?.description || "",
  };
  const validationSchema = Yup.object({
    truckId: Yup.string().required("Truck ID is required"),
    date: Yup.date().required("Fill Date is required"),
    billNumber: Yup.string().required("Bill number is required"),
    defCompanyName: Yup.string().required("Name is required"),
    quantity: Yup.number().required("Quantity is required"),
    cost: Yup.number().required("Total cost is required"),
    description: Yup.string(),
  });

  const onSubmit = (values) => {
    dispatch(postDEFAction(values));
    setLoader(true);
    toast.loading("Adding Expense...", { id: "addDetails-toast" });
  };

  const onUpdate = (values) => {
    const payload = { id: data._id, body: values };
    dispatch(patchDEFAction(payload));
    setLoader(true);
    toast.loading("Updating Expense...", { id: "updateDetails-toast" });
  };
  return (
    <div>
      {(getDEFByIdLoading || getCompanyTruckListDropdownLoading) && <Backdrop />}
      <h2 className="text-2xl font-bold mb-4">Add Expense</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={data?._id ? onUpdate : onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values, errors }) => (
          <Form>
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10">
              <div>
                <label htmlFor="truckId">
                  Truck ID<span>*</span>
                </label>
                <Field
                  as="select"
                  id="truckId"
                  name="truckId"
                  className=""
                  disabled={truckNumber}
                >
                  <option value=""></option>
                  {truckData.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.truckNumber}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="truckId"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="defCompanyName">
                  DEF Company Name<span>*</span>
                </label>
                <Field
                  type="text"
                  id="defCompanyName"
                  placeHolder="Name"
                  name="defCompanyName"
                  className=""
                />

                <ErrorMessage
                  name="defCompanyName"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="date">
                  Fill Date<span>*</span>
                </label>

                <DatePicker
                  placeHolder="DD / MMM / YYYY"
                  name="date"
                  maxDate={new Date()}
                />
                <ErrorMessage name="date" component="div" className="error" />
              </div>

              <div>
                <label htmlFor="billNumber">
                  Bill No<span>*</span>
                </label>
                <Field
                  type="text"
                  placeHolder="Service Bill No"
                  id="billNumber"
                  name="billNumber"
                  className=""
                />
                <ErrorMessage
                  name="billNumber"
                  component="div"
                  className="error"
                />
              </div>

             

              <div>
                <label htmlFor="quantity">
                  Liter Fill<span>*</span>
                </label>
                <Field
                  type="number"
                  id="quantity"
                  name="quantity"
                  className=""
                />
                <ErrorMessage
                  name="quantity"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="cost">
                  Total Cost<span>*</span>
                </label>
                <Field type="number" id="cost" name="cost" className="" />
                <ErrorMessage name="cost" component="div" className="error" />
              </div>
              <div className="lg:col-span-3 sm:col-span-2 col-span-1">
                <label htmlFor="billImage">Bill Image</label>
                <FileUpload
                  setFieldValue={setFieldValue}
                  name="billImage"
                  values={values}
                />
                <ErrorMessage
                  name="billImage"
                  component="div"
                  className="error"
                />
              </div>
              <div className="md:col-span-2 col-span-1">
                <label htmlFor="description">Description</label>
                <Field
                  as="textarea"
                  rows="4"
                  id="description"
                  name="description"
                  className=""
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="error"
                />
              </div>

              <div className="flex">
                <button
                  type="submit"
                  className="primaryButton mt-auto"
                  disabled={patchDEFLoading || postDEFLoading}
                >
                  {data?._id ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddDEF;
