// reducer.js
import {
  GET_SALARY,
  GET_SALARY_SUCCESS,
  GET_SALARY_ERROR,
  GET_SALARY_BY_USER,
  GET_SALARY_BY_USER_SUCCESS,
  GET_SALARY_BY_USER_ERROR,
  POST_SALARY,
  POST_SALARY_SUCCESS,
  POST_SALARY_ERROR,
  PATCH_SALARY,
  PATCH_SALARY_SUCCESS,
  PATCH_SALARY_ERROR,
  DELETE_SALARY,
  DELETE_SALARY_SUCCESS,
  DELETE_SALARY_ERROR,
  GET_SALARY_BY_ID,
  GET_SALARY_BY_ID_SUCCESS,
  GET_SALARY_BY_ID_ERROR,
} from "../../../actions";

const INIT_STATE = {
  getSalary: [],
  getSalaryLoading: false,
  getSalaryError: "",
  getSalaryById: [],
  getSalaryByIdLoading: false,
  getSalaryByIdError: "",
  getSalaryByUser: [],
  getSalaryByUserLoading: false,
  getSalaryByUserError: "",
  postSalary: {},
  postSalaryLoading: false,
  postSalaryError: "",
  patchSalary: {},
  patchSalaryLoading: false,
  patchSalaryError: "",
  deleteSalaryLoading: false,
  deleteSalary: {},
  deleteSalaryError: "",
};

const SalarySection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SALARY:
      return { ...state, getSalaryLoading: true, getSalary: [] };

    case GET_SALARY_SUCCESS:
      return {
        ...state,
        getSalaryLoading: false,
        getSalary: action.payload,
        getSalaryError: "",
      };

    case GET_SALARY_ERROR:
      return {
        ...state,
        getSalaryLoading: false,
        getSalaryError: action.payload,
      };

    case GET_SALARY_BY_ID:
      return { ...state, getSalaryByIdLoading: true, getSalaryById: [] };

    case GET_SALARY_BY_ID_SUCCESS:
      return {
        ...state,
        getSalaryByIdLoading: false,
        getSalaryById: action.payload,
        getSalaryByIdError: "",
      };

    case GET_SALARY_BY_ID_ERROR:
      return {
        ...state,
        getSalaryByIdLoading: false,
        getSalaryByIdError: action.payload,
      };
    case GET_SALARY_BY_USER:
      return { ...state, getSalaryByUserLoading: true, getSalaryByUser: [] };

    case GET_SALARY_BY_USER_SUCCESS:
      return {
        ...state,
        getSalaryByUserLoading: false,
        getSalaryByUser: action.payload,
        getSalaryByUserError: "",
      };

    case GET_SALARY_BY_USER_ERROR:
      return {
        ...state,
        getSalaryByUserLoading: false,
        getSalaryByUserError: action.payload,
      };

    case POST_SALARY:
      return { ...state, postSalaryLoading: true, postSalary: {} };

    case POST_SALARY_SUCCESS:
      return {
        ...state,
        postSalaryLoading: false,
        postSalary: action.payload,
        postSalaryError: "",
      };

    case POST_SALARY_ERROR:
      return {
        ...state,
        postSalaryLoading: false,
        postSalaryError: action.payload,
      };

    case PATCH_SALARY:
      return { ...state, patchSalaryLoading: true, patchSalary: {} };

    case PATCH_SALARY_SUCCESS:
      return {
        ...state,
        patchSalaryLoading: false,
        patchSalary: action.payload,
      };

    case PATCH_SALARY_ERROR:
      return {
        ...state,
        patchSalaryLoading: false,
        patchSalaryError: action.payload,
      };

    case DELETE_SALARY:
      return { ...state, deleteSalaryLoading: true, deleteSalary: {} };

    case DELETE_SALARY_SUCCESS:
      return {
        ...state,
        deleteSalaryLoading: false,
        deleteSalary: action.payload,
        deleteSalaryError: "",
      };

    case DELETE_SALARY_ERROR:
      return {
        ...state,
        deleteSalaryLoading: false,
        deleteSalaryError: action.payload,
      };

    default:
      return state;
  }
};

export default SalarySection;
