import { getCompanyTruckListDropdownAction } from "../../../redux/company/truck/action";
import {
  getCompanyTripDetailsAction,
  getCompanyTripDetailsIdAction,
} from "../../../redux/company/trip/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import Backdrop from "../../backdrop";
import { ErrorMessage, Field, Form, Formik } from "formik";
import DatePicker from "../../datepicker/datePicker";
import FileUpload from "../../fileupload/fileUpload";
import toast from "react-hot-toast";
import {
  getCompanyTollTaxIdAction,
  patchCompanyTollTaxAction,
  postCompanyTollTaxAction,
} from "../../../redux/company/tollTax/action";
import FileMultiUpload from "../../fileupload/fileMuiltipleUpload";

function AddTollTax() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const {
    getCompanyTripDetails,
    getCompanyTripDetailsLoading,
    getCompanyTripDetailsError,
    getCompanyTripDetailsId,
    getCompanyTripDetailsIdLoading,
    getCompanyTripDetailsIdError,
  } = useSelector((state) => state.CompanyTripsection);
  const { getCompanyTruckListDropdown, getCompanyTruckListDropdownLoading } = useSelector(
    (state) => state.Companytrucksection
  );
  const {
    postCompanyTollTax,
    postCompanyTollTaxLoading,
    postCompanyTollTaxError,
    patchCompanyTollTax,
    patchCompanyTollTaxLoading,
    patchCompanyTollTaxError,
    getCompanyTollTaxId,
    getCompanyTollTaxIdLoading,
    getCompanyTollTaxIdError,
  } = useSelector((state) => state.TollTaxsection);

  const [tripData, setTripData] = useState(null);
  const [truckData, setTruckData] = useState(null);
  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [edit, setEdit] = useState(null);

  useEffect(() => {
    const tripId = searchParams.get("tripId");
    if (tripId !== null) {
      dispatch(getCompanyTripDetailsIdAction(tripId));
    }
  }, [searchParams]);
  useEffect(() => {
    const id = searchParams.get("id");
    if (id !== null) {
      dispatch(getCompanyTollTaxIdAction(id));
    }
  }, [searchParams]);
  useEffect(() => {
    dispatch(getCompanyTripDetailsAction("?completedOnly=true"));
    dispatch(getCompanyTruckListDropdownAction("isSell=false"));
  }, []);
  useEffect(() => {
    const tripId = searchParams.get("tripId");
    if (getCompanyTripDetails?.status === 200) {
      setTripData(getCompanyTripDetails.data);
      setData(
        getCompanyTripDetails?.data?.filter((item) => item._id === tripId)[0]
      );
    }
  }, [getCompanyTripDetails]);
  useEffect(() => {
    if (getCompanyTruckListDropdown?.status === 200) {
      const truckFilter = getCompanyTruckListDropdown?.trucks

      setTruckData(truckFilter);
      const truckId = searchParams.get("truckId");
      if (truckId !== null) {
        setData({
          truckId: truckId,
        });
      }
    }
  }, [getCompanyTruckListDropdown]);
  useEffect(() => {
    const edit = searchParams.get("id") === null;

    if (!edit) {
      if (getCompanyTollTaxId?.status === 200) {
        if (getCompanyTollTaxId?.data?._id !== null) {
          setEdit(getCompanyTollTaxId?.data);
        }
      }
      if (getCompanyTollTaxIdError === 400) {
        toast.error(getCompanyTollTaxIdError?.message);
      } else if (getCompanyTollTaxIdError === 404) {
        toast.error(getCompanyTollTaxIdError.message);
      } else if (getCompanyTollTaxIdError === 500) {
        toast.error(getCompanyTollTaxIdError.error);
      }
    }
  }, [getCompanyTollTaxId, getCompanyTollTaxIdError]);

  useEffect(() => {
    if (loader) {
      if (postCompanyTollTax?.status === 200) {
        toast.success(postCompanyTollTax?.message, { id: "postTollTax" });
        setLoader(false);
        navigate(-1);
      }
     
    }
  }, [postCompanyTollTax]);
  useEffect(() => {
    if (loader) {
      if (postCompanyTollTaxError?.status === 400) {
        toast.error(postCompanyTollTaxError?.message, { id: "postTollTax" });
        setLoader(false);
      } else if (postCompanyTollTaxError?.status === 404) {
        toast.error(postCompanyTollTaxError.message, { id: "postTollTax" });
        setLoader(false);
      } else if (postCompanyTollTaxError?.status === 500) {
        toast.error(postCompanyTollTaxError.error, { id: "postTollTax" });
        setLoader(false);
      }
    }
  }, [postCompanyTollTaxError]);

  useEffect(() => {
    if (loader) {
      if (patchCompanyTollTax?.status === 200) {
        toast.success(patchCompanyTollTax?.message, { id: "postTollTax" });
        setLoader(false);
        navigate(-1);
      }
    }
  }, [patchCompanyTollTax]);
  useEffect(() => {
    if (loader) {
      if (patchCompanyTollTaxError?.status === 400) {
        toast.error(patchCompanyTollTaxError?.message, { id: "postTollTax" });
        setLoader(false);
      } else if (patchCompanyTollTaxError?.status === 404) {
        toast.error(patchCompanyTollTaxError.message, { id: "postTollTax" });
        setLoader(false);
      } else if (patchCompanyTollTaxError?.status === 500) {
        toast.error(patchCompanyTollTaxError.error, { id: "postTollTax" });
        setLoader(false);
      }
     
    }
  }, [patchCompanyTollTaxError]);

  const initialValues = {
    truckId: data?.truckId || edit?.truckId || "",
    tripId: data?._id || edit?.tripId || "",
    tollName: edit?.tollName || "",
    cost: edit?.cost || "",
    taxPayDate: edit?.taxPayDate ? new Date(edit?.taxPayDate) : "",
    description: edit?.description || "",
    billImage: edit?.billImage || [],
    billNumber: edit?.billNumber || "",
    typeOfToll: edit?.typeOfToll || "Single trip",
    typeOfPayment: edit?.typeOfPayment || "Fast Tag",
  };
  const validationSchema = Yup.object().shape({
    truckId: Yup.string().required("Truck is required"),
    tripId: Yup.string(),
    tollName: Yup.string().required("Toll Name is required"),
    cost: Yup.number().required("Cost is required"),
    taxPayDate: Yup.date().required("TollTax Fill Date is required"),
    description: Yup.string(),
    billNumber: Yup.string(),
    typeOfToll: Yup.string().required("This is required"),
  });

  const onsubmit = (values) => {
    dispatch(postCompanyTollTaxAction(values));
    setLoader(true);
    toast.loading("Saving TollTax Details...", { id: "postTollTax" });
  };
  const onUpdate = (values) => {
    const payload = {
      id: edit._id,
      body: values,
    };
    dispatch(patchCompanyTollTaxAction(payload));
    toast.loading("Update TollTax Details...", { id: "postTollTax" });
    setLoader(true);
  };
  return (
    <div>
      {(getCompanyTruckListDropdownLoading ||
        getCompanyTripDetailsLoading ||
        getCompanyTripDetailsIdLoading) && <Backdrop />}
      <h2 className="text-2xl font-bold mb-4">
        {edit?._id ? "Update" : "Add"} Toll Tax Details
      </h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={edit?._id ? onUpdate : onsubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 ">
              <div>
                <label htmlFor="tripId">
                  Trip <span>*</span>
                </label>
                <Field as="select" name="tripId" disabled={searchParams.get("tripId") !== null}>
                  <option value="">Select Trip</option>
                  {tripData &&
                    tripData.map((trip) => (
                      <option key={trip._id} value={trip._id}>
                        {trip.tokenNumber} ({trip?.startLocation} -{" "}
                        {trip?.endLocation})
                      </option>
                    ))}
                </Field>
                <ErrorMessage name="tripId" component="div" className="error" />
              </div>
              <div>
                <label htmlFor="truckId">
                  Truck <span>*</span>
                </label>
                <Field as="select" name="truckId" disabled={true}>
                  <option value="">Select Truck</option>
                  {truckData &&
                    truckData.map((truck) => (
                      <option key={truck._id} value={truck._id}>
                        {truck.truckNumber}
                      </option>
                    ))}
                </Field>
                <ErrorMessage
                  name="truckId"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="taxPayDate">
                  TollTax Fill Date <span>*</span>
                </label>
                <DatePicker
                  placeHolder="DD / MMM / YYYY"
                  name="taxPayDate"
                  maxDate={new Date()}
                />
                <ErrorMessage
                  name="taxPayDate"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="tollName">
                  Toll Tax Name <span>*</span>
                </label>
                <Field
                  type="text"
                  name="tollName"
                  placeHolder="Toll Tax Name"
                />
                <ErrorMessage
                  name="tollName"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="cost">
                  Toll Tax Amount <span>*</span>
                </label>
                <Field type="number" name="cost" placeHolder="Total Amount" />
                <ErrorMessage name="cost" component="div" className="error" />
              </div>
              <div>
                <label htmlFor="typeOfToll">
                  Type Of Toll Reciept <span>*</span>
                </label>
                <Field as="select" name="typeOfToll">
                  <option value="Single trip">Single trip</option>
                  <option value="Round trip">Round trip</option>
                </Field>
                <ErrorMessage
                  name="typeOfToll"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="typeOfPayment">
                  Type Of Payment <span>*</span>
                </label>
                <Field as="select" name="typeOfPayment">
                  <option value="Fast Tag">Fast Tag</option>
                  <option value="Cash">Cash</option>
                </Field>
                <ErrorMessage
                  name="typeOfPayment"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="billNumber">Bill Number</label>
                <Field
                  type="text"
                  name="billNumber"
                  placeHolder="Bill Number"
                />
                <ErrorMessage
                  name="billNumber"
                  component="div"
                  className="error"
                />
              </div>
              <div className="lg:col-span-3 sm:col-span-2 col-span-1">
                <label htmlFor="billImage">Bill Image</label>
                <FileMultiUpload
                  setFieldValue={setFieldValue}
                  name="billImage"
                  values={values.billImage}
                />
                <ErrorMessage
                  name="billImage"
                  component="div"
                  className="error"
                />
              </div>
              <div className="md:col-span-2 col-span-1">
                <label htmlFor="description">Description</label>
                <Field
                  as="textarea"
                  rows="4"
                  name="description"
                  placeHolder="description"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="error"
                />
              </div>

              <button
                type="submit"
                disabled={postCompanyTollTaxLoading || patchCompanyTollTaxLoading || getCompanyTollTaxIdLoading}
                className="primaryButton h-fit mt-auto col-start-1"
              >
                {edit?._id ? "Update" : "Save"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddTollTax;
