import { useDispatch, useSelector } from "react-redux";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../ui/tabs";
import React, { useEffect, useState } from "react";
import { getAdminEmployeeListAction } from "../../../redux/admin/employee/action";
import toast from "react-hot-toast";
import EmployeeList from "./employeeList";
import Backdrop from "../../backdrop";

function AdminEmployees() {
  const dispatch = useDispatch();
  const {
    getAdminEmployeeList,
    getAdminEmployeeListLoading,
    getAdminEmployeeListError,
  } = useSelector((state) => state.AdminEmployeeSection);

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getAdminEmployeeListAction());
  }, []);

  useEffect(() => {
    if (getAdminEmployeeList?.status === 200) {
      setData(getAdminEmployeeList?.data);
    }
  }, [getAdminEmployeeList]);
  useEffect(() => {
    if (
      getAdminEmployeeListError?.status === 400 ||
      getAdminEmployeeListError?.status === 404
    ) {
      toast.error(getAdminEmployeeListError.message);
    } else if (getAdminEmployeeListError?.status === 500) {
      toast.error(getAdminEmployeeListError?.error);
    }
  }, [getAdminEmployeeListError]);
  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Employees ({data?.length})</h2>

      {getAdminEmployeeListLoading && <Backdrop />}
      <Tabs defaultValue="All" color="primary" className="min-w-[400px]">
        <TabsList>
          <TabsTrigger value="All">All Employees</TabsTrigger>
          <TabsTrigger value="working">Working</TabsTrigger>
          <TabsTrigger value="terminated">Not Working</TabsTrigger>
        </TabsList>
        <TabsContent value="All">
          <EmployeeList data={data} reroute={true} />
        </TabsContent>
        <TabsContent value="working">
          <EmployeeList
            data={data.filter((item) => item.isWorking === true)}
            reroute={true}
          />
        </TabsContent>
        <TabsContent value="terminated">
          <EmployeeList
            data={data.filter((item) => item.isWorking === false)}
            reroute={true}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default AdminEmployees;
