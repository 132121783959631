import {
  DELETE_COMPANY_SERVICE,
  DELETE_COMPANY_SERVICE_ERROR,
  DELETE_COMPANY_SERVICE_SUCCESS,
  GET_COMPANY_SERVICE,
  GET_COMPANY_SERVICE_ERROR,
  GET_COMPANY_SERVICE_FILTER,
  GET_COMPANY_SERVICE_FILTER_ERROR,
  GET_COMPANY_SERVICE_FILTER_SUCCESS,
  GET_COMPANY_SERVICE_ID,
  GET_COMPANY_SERVICE_ID_ERROR,
  GET_COMPANY_SERVICE_ID_SUCCESS,
  GET_COMPANY_SERVICE_SUCCESS,
  PATCH_COMPANY_SERVICE,
  PATCH_COMPANY_SERVICE_ERROR,
  PATCH_COMPANY_SERVICE_SUCCESS,
  POST_COMPANY_SERVICE,
  POST_COMPANY_SERVICE_ERROR,
  POST_COMPANY_SERVICE_SUCCESS,
} from "../../actions";

//truck
export const getCompanyServiceAction = (info) => ({
  type: GET_COMPANY_SERVICE,
  payload: info,
});
export const getCompanyServiceSuccess = (info) => ({
  type: GET_COMPANY_SERVICE_SUCCESS,
  payload: info,
});
export const getCompanyServiceError = (error) => ({
  type: GET_COMPANY_SERVICE_ERROR,
  payload: error,
});
export const getCompanyServiceIdAction = (info) => ({
  type: GET_COMPANY_SERVICE_ID,
  payload: info,
});
export const getCompanyServiceIdSuccess = (info) => ({
  type: GET_COMPANY_SERVICE_ID_SUCCESS,
  payload: info,
});
export const getCompanyServiceIdError = (error) => ({
  type: GET_COMPANY_SERVICE_ID_ERROR,
  payload: error,
});
export const getCompanyServiceFilterAction = (info) => ({
  type: GET_COMPANY_SERVICE_FILTER,
  payload: info,
});
export const getCompanyServiceFilterSuccess = (info) => ({
  type: GET_COMPANY_SERVICE_FILTER_SUCCESS,
  payload: info,
});
export const getCompanyServiceFilterError = (error) => ({
  type: GET_COMPANY_SERVICE_FILTER_ERROR,
  payload: error,
});
export const postCompanyServiceAction = (info) => ({
  type: POST_COMPANY_SERVICE,
  payload: info,
});
export const postCompanyServiceSuccess = (info) => ({
  type: POST_COMPANY_SERVICE_SUCCESS,
  payload: info,
});
export const postCompanyServiceError = (error) => ({
  type: POST_COMPANY_SERVICE_ERROR,
  payload: error,
});
export const patchCompanyServiceAction = (info) => ({
  type: PATCH_COMPANY_SERVICE,
  payload: info,
});
export const patchCompanyServiceSuccess = (info) => ({
  type: PATCH_COMPANY_SERVICE_SUCCESS,
  payload: info,
});
export const patchCompanyServiceError = (error) => ({
  type: PATCH_COMPANY_SERVICE_ERROR,
  payload: error,
});
export const deleteCompanyServiceAction = (info) => ({
  type: DELETE_COMPANY_SERVICE,
  payload: info,
});
export const deleteCompanyServiceSuccess = (info) => ({
  type: DELETE_COMPANY_SERVICE_SUCCESS,
  payload: info,
});
export const deleteCompanyServiceError = (error) => ({
  type: DELETE_COMPANY_SERVICE_ERROR,
  payload: error,
});
