import { Badge } from "../../../../ui/badge";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

function TruckDetailSection({ data }) {
  const date = moment(data?.subscribeTill).diff(moment(new Date()), "days");

  return (
    <div className="bg-white p-4 rounded-md shadow-lg grid grid-cols-4 gap-5 w-full">
      <div className="col-span-4">
        <label htmlFor="" className="font-bold">
          Truck Number
        </label>
        <p className="font-bold">{data?.truckNumber}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck Company Name</label>
        <p className="">{data?.truckCompanyName}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck Model Name</label>
        <p className="">{data?.truckModelName}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck Chassis Number</label>
        <p className="">{data?.chasisNumber}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck New Purchase Date</label>
        <p className="">{moment(data?.purchaseDate).format("DD/MMM/YYYY")}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck Purchase Date</label>
        <p className="">{moment(data?.purchaseDate).format("DD/MMM/YYYY")}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck Type</label>
        <p className="">{data?.typeOfTruck}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Fitness Valid Till</label>
        <p className="">{moment(data?.fitnessTill).format("DD/MMM/YYYY")}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Insurance Valid Till</label>
        <p className="">{moment(data?.insuranceTill).format("DD/MMM/YYYY")}</p>
      </div>
      {data?.currentCompanyId !== null && (
        <>
          <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
            <label htmlFor="">Current Company Name</label>
            <div>
              <Link
                to={`/admin/company/${data?.currentCompanyId?._id}`}
                className=""
              >
                {data?.currentCompanyId?.companyName}
              </Link>
            </div>
          </div>
          <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
            <label htmlFor="">Company Owner</label>
            <p className="">{data?.currentCompanyId?.companyOwnerBy}</p>
          </div>
          <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
            <label htmlFor="">Company Phone Number</label>
            <p className="">{data?.currentCompanyId?.phoneNumber}</p>
          </div>
        </>
      )}
    </div>
  );
}

export default TruckDetailSection;
