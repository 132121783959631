import api from "../../../auth/api.jsx";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  DELETE_COMPANY_FUEL,
  GET_COMPANY_FUEL,
  GET_COMPANY_FUEL_FILTER,
  GET_COMPANY_FUEL_ID,
  GET_COMPANY_FUEL_NAME_LIST,
  PATCH_COMPANY_FUEL,
  POST_COMPANY_FUEL,
} from "../../actions.js";
import {
  deleteCompanyFuelError,
  deleteCompanyFuelSuccess,
  getCompanyFuelError,
  getCompanyFuelFilterError,
  getCompanyFuelFilterSuccess,
  getCompanyFuelIdError,
  getCompanyFuelIdSuccess,
  getCompanyFuelNameListError,
  getCompanyFuelNameListSuccess,
  getCompanyFuelSuccess,
  patchCompanyFuelError,
  patchCompanyFuelSuccess,
  postCompanyFuelError,
  postCompanyFuelSuccess,
} from "./action.js";



// Async function to handle API call
const getCompanyFuelAsync = async (payload) => {
  try {
    const response = await api.get(`/company/truck/getAll`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyFuelCall(action) {
  try {
    const data = yield call(getCompanyFuelAsync, action); // Call async function with action payload
    yield put(getCompanyFuelSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyFuelError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyFuelData() {
  yield takeEvery(GET_COMPANY_FUEL, getCompanyFuelCall);
}
// Async function to handle API call
const getCompanyFuelIdAsync = async (payload) => {
  try {
    const response = await api.get(`/company/fuel/${payload.payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
   
    throw error.response.data; // Throw other errors to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyFuelIdCall(action) {
  try {
    const data = yield call(getCompanyFuelIdAsync, action); // Call async function with action payload
    yield put(getCompanyFuelIdSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyFuelIdError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyFuelIdData() {
  yield takeEvery(GET_COMPANY_FUEL_ID, getCompanyFuelIdCall);
}

const getCompanyFuelNameListAsync = async (payload) => {
  try {
    const response = await api.get(`/company/financials/pumpNames`);
    return response.data; // Return the data from the API response
  } catch (error) {
   
    throw error.response.data; // Throw other errors to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyFuelNameListCall(action) {
  try {
    const data = yield call(getCompanyFuelNameListAsync, action); // Call async function with action payload
    yield put(getCompanyFuelNameListSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyFuelNameListError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyFuelNameListData() {
  yield takeEvery(GET_COMPANY_FUEL_NAME_LIST, getCompanyFuelNameListCall);
}

// Async function to handle API call
const getCompanyFuelFilterAsync = async (payload) => {
  try {
    const response = await api.post(`/company/fuel/filter`, (payload.payload));
    return response.data; // Return the data from the API response
  } catch (error) {
   
    throw error.response.data; // Throw other errors to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyFuelFilterCall(action) {
  try {
    const data = yield call(getCompanyFuelFilterAsync, action); // Call async function with action payload
    yield put(getCompanyFuelFilterSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyFuelFilterError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyFuelFilterData() {
  yield takeEvery(GET_COMPANY_FUEL_FILTER, getCompanyFuelFilterCall);
}


// Async function to handle API call
const postCompanyFuelAsync = async (payload) => {
  try {
    const response = await api.post(`/company/fuel/add`, payload.payload);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* postCompanyFuelCall(action) {
  try {
    const data = yield call(postCompanyFuelAsync, action); // Call async function with action payload
    yield put(postCompanyFuelSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postCompanyFuelError(error)); // Dispatch error action with error object
  }
}
export function* watchPostCompanyFuelData() {
  yield takeEvery(POST_COMPANY_FUEL, postCompanyFuelCall);
}
// Async function to handle API call
const patchCompanyFuelAsync = async (payload) => {
  try {
    const response = await api.patch(
      `/company/fuel/update/${payload.payload.id}`,
      payload.payload.body
    );
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* patchCompanyFuelCall(action) {
  try {
    const data = yield call(patchCompanyFuelAsync, action); // Call async function with action payload
    yield put(patchCompanyFuelSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(patchCompanyFuelError(error)); // Dispatch error action with error object
  }
}
export function* watchPatchCompanyFuelData() {
  yield takeEvery(PATCH_COMPANY_FUEL, patchCompanyFuelCall);
}
// Async function to handle API call
const deleteCompanyFuelAsync = async (payload) => {
  try {
    console.log(payload);
    const response = await api.delete(
      `/company/fuel/${payload.payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* deleteCompanyFuelCall(action) {
  try {
    const data = yield call(deleteCompanyFuelAsync, action); // Call async function with action payload
    yield put(deleteCompanyFuelSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(deleteCompanyFuelError(error)); // Dispatch error action with error object
  }
}
export function* watchDeleteCompanyFuelData() {
  yield takeEvery(DELETE_COMPANY_FUEL, deleteCompanyFuelCall);
}

export default function* rootSaga() {
  yield all([

    fork(watchGetCompanyFuelData),
    fork(watchGetCompanyFuelIdData),
    fork(watchGetCompanyFuelFilterData),
    fork(watchGetCompanyFuelNameListData),
    fork(watchPostCompanyFuelData),
    fork(watchPatchCompanyFuelData),
    fork(watchDeleteCompanyFuelData),
  ]);
}
