import {
    GET_EMI,
    GET_EMI_SUCCESS,
    GET_EMI_ERROR,
    GET_EMI_BY_ID,
    GET_EMI_BY_ID_SUCCESS,
    GET_EMI_BY_ID_ERROR,
    POST_EMI,
    POST_EMI_SUCCESS,
    POST_EMI_ERROR,
    DELETE_EMI,
    DELETE_EMI_SUCCESS,
    DELETE_EMI_ERROR,
  } from "../../../actions";
  
  export const getEmiAction = (data) => ({
    type: GET_EMI,
    payload: data,
  });
  
  export const getEmiSuccess = (data) => ({
    type: GET_EMI_SUCCESS,
    payload: data,
  });
  
  export const getEmiError = (error) => ({
    type: GET_EMI_ERROR,
    payload: error,
  });
  

  export const getEmiByIdAction = (userId) => ({
    type: GET_EMI_BY_ID,
    payload: userId,
  });
  
  export const getEmiByIdSuccess = (data) => ({
    type: GET_EMI_BY_ID_SUCCESS,
    payload: data,
  });
  
  export const getEmiByIdError = (error) => ({
    type: GET_EMI_BY_ID_ERROR,
    payload: error,
  });
  
  export const postEmiAction = (data) => ({
    type: POST_EMI,
    payload: data,
  });
  
  export const postEmiSuccess = (data) => ({
    type: POST_EMI_SUCCESS,
    payload: data,
  });
  
  export const postEmiError = (error) => ({
    type: POST_EMI_ERROR,
    payload: error,
  });
  
  export const deleteEmiAction = (id) => ({
    type: DELETE_EMI,
    payload: id,
  });
  
  export const deleteEmiSuccess = (data) => ({
    type: DELETE_EMI_SUCCESS,
    payload: data,
  });
  
  export const deleteEmiError = (error) => ({
    type: DELETE_EMI_ERROR,
    payload: error,
  });
  