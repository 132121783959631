import api from "../../../auth/api.jsx";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  DELETE_COMPANY_TOLLTAX,
  GET_COMPANY_TOLLTAX,
  GET_COMPANY_TOLLTAX_FILTER,
  GET_COMPANY_TOLLTAX_ID,
  PATCH_COMPANY_TOLLTAX,
  POST_COMPANY_TOLLTAX,
} from "../../actions.js";
import {
  deleteCompanyTollTaxError,
  deleteCompanyTollTaxSuccess,
  getCompanyTollTaxError,
  getCompanyTollTaxFilterError,
  getCompanyTollTaxFilterSuccess,
  getCompanyTollTaxIdError,
  getCompanyTollTaxIdSuccess,
  getCompanyTollTaxSuccess,
  patchCompanyTollTaxError,
  patchCompanyTollTaxSuccess,
  postCompanyTollTaxError,
  postCompanyTollTaxSuccess,
} from "./action.js";



// Async function to handle API call
const getCompanyTollTaxAsync = async (payload) => {
  try {
    const response = await api.get(`/company/tollTax/getAll`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyTollTaxCall(action) {
  try {
    const data = yield call(getCompanyTollTaxAsync, action); // Call async function with action payload
    yield put(getCompanyTollTaxSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyTollTaxError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyTollTaxData() {
  yield takeEvery(GET_COMPANY_TOLLTAX, getCompanyTollTaxCall);
}
// Async function to handle API call
const getCompanyTollTaxIdAsync = async (payload) => {
  try {
    const response = await api.get(`/company/tollTax/${payload.payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
   
    throw error.response.data; // Throw other errors to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyTollTaxIdCall(action) {
  try {
    const data = yield call(getCompanyTollTaxIdAsync, action); // Call async function with action payload
    yield put(getCompanyTollTaxIdSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyTollTaxIdError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyTollTaxIdData() {
  yield takeEvery(GET_COMPANY_TOLLTAX_ID, getCompanyTollTaxIdCall);
}

// Async function to handle API call
const getCompanyTollTaxFilterAsync = async (payload) => {
  try {
    const response = await api.post(`/company/tollTax/filter`, (payload.payload));
    return response.data; // Return the data from the API response
  } catch (error) {
   
    throw error.response.data; // Throw other errors to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyTollTaxFilterCall(action) {
  try {
    const data = yield call(getCompanyTollTaxFilterAsync, action); // Call async function with action payload
    yield put(getCompanyTollTaxFilterSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyTollTaxFilterError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyTollTaxFilterData() {
  yield takeEvery(GET_COMPANY_TOLLTAX_FILTER, getCompanyTollTaxFilterCall);
}


// Async function to handle API call
const postCompanyTollTaxAsync = async (payload) => {
  try {
    const response = await api.post(`/company/tollTax/add`, payload.payload);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* postCompanyTollTaxCall(action) {
  try {
    const data = yield call(postCompanyTollTaxAsync, action); // Call async function with action payload
    yield put(postCompanyTollTaxSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postCompanyTollTaxError(error)); // Dispatch error action with error object
  }
}
export function* watchPostCompanyTollTaxData() {
  yield takeEvery(POST_COMPANY_TOLLTAX, postCompanyTollTaxCall);
}
// Async function to handle API call
const patchCompanyTollTaxAsync = async (payload) => {
  try {
    const response = await api.patch(
      `/company/tollTax/update/${payload.payload.id}`,
      payload.payload.body
    );
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* patchCompanyTollTaxCall(action) {
  try {
    const data = yield call(patchCompanyTollTaxAsync, action); // Call async function with action payload
    yield put(patchCompanyTollTaxSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(patchCompanyTollTaxError(error)); // Dispatch error action with error object
  }
}
export function* watchPatchCompanyTollTaxData() {
  yield takeEvery(PATCH_COMPANY_TOLLTAX, patchCompanyTollTaxCall);
}
// Async function to handle API call
const deleteCompanyTollTaxAsync = async (payload) => {
  try {
    const response = await api.delete(
      `/company/tollTax/${payload.payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* deleteCompanyTollTaxCall(action) {
  try {
    const data = yield call(deleteCompanyTollTaxAsync, action); // Call async function with action payload
    yield put(deleteCompanyTollTaxSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(deleteCompanyTollTaxError(error)); // Dispatch error action with error object
  }
}
export function* watchDeleteCompanyTollTaxData() {
  yield takeEvery(DELETE_COMPANY_TOLLTAX, deleteCompanyTollTaxCall);
}

export default function* rootSaga() {
  yield all([

    fork(watchGetCompanyTollTaxData),
    fork(watchGetCompanyTollTaxIdData),
    fork(watchGetCompanyTollTaxFilterData),
    fork(watchPostCompanyTollTaxData),
    fork(watchPatchCompanyTollTaxData),
    fork(watchDeleteCompanyTollTaxData),
  ]);
}
