import React, { useState } from "react";
import Image from "../../../assets/images/trip.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Badge } from "../../../ui/badge";
import EndTrip from "./endTrip";
import { Dialog, DialogTrigger } from "../../../ui/dialog";
import Payment from "./payment";
import DeleteTrip from "./deleteTrip";
import { BsFuelPumpFill } from "react-icons/bs";
import { CiReceipt } from "react-icons/ci";
import ImagePopUpDownload from "../../imagePopUpDownload";
import { FaRegEdit } from "react-icons/fa";
import { TfiTrash } from "react-icons/tfi";
import { FcImageFile } from "react-icons/fc";
import { FiDownload } from "react-icons/fi";

function TripListCard({ data, reroute = false }) {
  const navigate = useNavigate();
  const [showEndModal, setShowEndModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(amount)
      : "₹0.00";
  return (
    <div key={data?._id}>
      <Dialog open={showDeleteModal}>
        <DeleteTrip setShowModal={setShowDeleteModal} id={data?._id} />
      </Dialog>
      <div className="grid grid-cols-12 lg:grid-rows-2 gap-x-6 gap-y-3 mb-2  lg:p-4 p-2 bg-white rounded-lg shadow-md">
        <div className="row-span-2 lg:col-span-1 justify-center col-span-12 flex relative">
          <button
            type="button"
            className="w-fit  mx-auto flex justify-center relative"
            onClick={() =>
              reroute
                ? navigate(
                    `/company/trip/details?tripId=${data?._id}&truckId=${data?.truckId?._id}`
                  )
                : ""
            }
          >
            <div className="p-5 bg-[#50C98F] rounded-md w-fit">
              <img src={Image} alt="" className={` h-10 w-10 `} />
            </div>
          </button>
          <div className="flex flex-col gap-5 lg:hidden ml-auto absolute z-30 left-0">
            {data?.status === "Planned" &&
            <button
            type="button"
            onClick={() => setShowDeleteModal(true)}
            disabled={!(data?.status === "Planned")}
            className="ghostButton text-[#DC1919] font-semibold  flex gap-1 hover:text-red-700 disabled:text-red-200"
            >
              <TfiTrash className="text-xl" />
            </button>
            }
            {(data?.status === "Complete" || data?.status === "Trip End") && (
              <button className="text-lg ghostButton flex gap-1 ml-auto">
                <FiDownload className="" />
              </button>
            )}
          </div>
          <div className=" flex flex-col gap-5 lg:hidden ml-auto absolute z-30 right-0">
            {(data?.status === "Planned" ||
              data?.status === "Trip Started") && (
              <button
                type="button"
                className="ghostButton font-semibold ml-auto flex gap-1"
                onClick={() =>
                  navigate(
                    `/company/trip/addtrip?truckId=${data?.truckId?._id}&id=${data?._id}&edit=true`
                  )
                }
                disabled={
                  !(
                    data?.status === "Planned" ||
                    data?.status === "Trip Started"
                  )
                }
              >
                <FaRegEdit className="text-xl" />
              </button>
            )}
            {data?.tokenImage?.length > 0 && (
              <button
                type="button"
                onClick={() => setShowImageModal(true)}
                className="ghostButton font-semibold flex gap-1 "
                disabled={!(data?.tokenImage?.length > 0)}
              >
                <FcImageFile className="text-2xl" />
              </button>
            )}
          </div>
        </div>
        {data?.tokenImage?.length > 0 && (
          <Dialog open={showImageModal}>
            <ImagePopUpDownload
              isArray={true}
              image={data?.tokenImage}
              setShowModal={setShowImageModal}
              imagename={`token_${data?._id}`}
            />
          </Dialog>
        )}
        <div className="lg:col-span-2 col-span-12 flex flex-col gap-1 row-span-2">
          <button
            type="button"
            className="textPrimary lg:text-left text-center w-fit lg:mx-0 mx-auto "
            onClick={
              reroute ? () => navigate(`details?truckId=${data?._id}`) : null
            }
          >
            {data?.tokenNumber}
          </button>
          <p className="textSecondary mt-0 lg:text-left text-center capitalize">
            {data?.productType},{" "}
            {moment(data?.startDate).format("DD / MMM / YYYY")} -
            {data?.endDate ? moment(data?.endDate).format("DD / MMM / YYYY") : ""}
          </p>
          <p className="textSecondary mt-0 lg:text-left text-center capitalize">
            Client:{" "}
            {data?.isClientId 
              ? data?.clientId?.name + `(${data?.clientId?.ownerName})`
              : data?.clientNames ?   data?.clientNames : ""}
          </p>
          {data?.status === "Planned" && (
            <div
              className={`flex gap-2 w-full lg:justify-start justify-center text-[#04C3CA]`}
            >
              <p>
                <div
                  className={`px-1.5 py-0.5 rounded-full border  text-[10px] font-medium border-[#04C3CA] `}
                >
                  Trip Planned
                </div>
              </p>
            </div>
          )}
          {data?.status === "Trip Started" && (
            <div
              className={`flex gap-2 w-full lg:justify-start justify-center text-[#CA8A04]`}
            >
              <p>
                <div
                  className={`px-1.5 py-0.5 rounded-full border  text-[10px] font-medium border-[#CA8A04] `}
                >
                  Trip Started
                </div>
              </p>
            </div>
          )}
          {data?.status === "Trip Ended" && (
            <div
              className={`flex gap-2 w-full lg:justify-start justify-center text-[#DC1919]`}
            >
              <p>
                <div
                  className={`px-1.5 py-0.5 rounded-full border  text-[10px] font-medium border-[#DC1919] `}
                >
                  Trip Ended
                </div>
              </p>
            </div>
          )}
          {data?.status === "Completed" && (
            <div
              className={`flex gap-2 w-full lg:justify-start justify-center text-[#15803D]`}
            >
              <p>
                <div
                  className={`px-1.5 py-0.5 rounded-full border  text-[10px] font-medium border-[#15803D] `}
                >
                  Completed
                </div>
              </p>
            </div>
          )}
        </div>
        <div className="text-xs text-[#64709B] lg:col-span-2 col-span-6">
          <h3 className=" ">Driver</h3>
          <p className="textSecondary">
            {data?.driverId?.firstName} {data?.driverId?.lastName}
          </p>
        </div>
        <div className="text-xs text-[#64709B] lg:col-span-2 col-span-6">
          <h3 className=" ">Start Load</h3>
          <p className="textSecondary">
            {data?.startingLoad} {data?.weigthType}
          </p>
        </div>
        <div className="text-xs text-[#64709B] lg:col-span-2 col-span-6">
          <h3 className=" ">Trip From - To</h3>
          <p className="textSecondary">
            {data?.startLocation} - {data?.endLocation} (
            {data?.distanceTravelled}km)
          </p>
        </div>
        <div className="text-xs text-[#64709B] lg:col-span-1 col-span-6">
          <h3 className=" ">Load Rate</h3>
          <p className="textSecondary">{formatAmount(data?.ratePerWeight)}</p>
        </div>
        <div className=" gap-3 flex-wrap lg:flex hidden col-span-2 justify-end">
          {data?.tokenImage?.length > 0 && (
            <button
              type="button"
              onClick={() => setShowImageModal(true)}
              className="ghostButton font-semibold flex gap-1 "
              disabled={!(data?.tokenImage?.length > 0)}
            >
              <FcImageFile className="text-2xl" /> Image
            </button>
          )}
          {data?.status === "Planned" && (
            <div>
              <button
                type="button"
                onClick={() => setShowDeleteModal(true)}
                disabled={!(data?.status === "Planned")}
                className="ghostButton text-[#DC1919] font-semibold text-sm  flex gap-1 hover:text-red-700 disabled:text-red-200"
              >
                <TfiTrash className="text-xl" /> Delete
              </button>
            </div>
          )}
          {(data?.status === "Planned" || data?.status === "Trip Started") && (
            <div className=" ">
              <button
                type="button"
                className="ghostButton font-semibold flex gap-1 "
                onClick={() =>
                  navigate(
                    `/company/trip/addtrip?truckId=${data?.truckId?._id}&id=${data?._id}&edit=true`
                  )
                }
                disabled={
                  !(
                    data?.status === "Planned" ||
                    data?.status === "Trip Started"
                  )
                }
              >
                <FaRegEdit className="text-xl" />
                Edit
              </button>
            </div>
          )}
          {(data?.status === "Complete" || data?.status === "Trip End") && (
            <button className="text-lg ghostButton flex gap-1 ml-auto">
              <FiDownload className="" />
            </button>
          )}
        </div>
        <div className="text-xs text-[#64709B] lg:col-span-2 col-span-6">
          <h3 className=" ">Helper</h3>
          <p className="textSecondary">
            {data?.helperId
              ? data?.helperId?.firstName + " " + data?.helperId?.lastName
              : "--"}
          </p>
        </div>
        <div className="text-xs text-[#64709B] lg:col-span-1 col-span-6">
          <h3 className=" ">End Load</h3>
          <p className="textSecondary">
            {data?.endingLoad
              ? data?.endingLoad + " " + data?.weigthType
              : "--"}
          </p>
        </div>

        <div className="lg:col-start-7 col-start-1 lg:col-span-2 mt-auto col-span-12  lg:row-start-2">
          <button
            onClick={() => navigate(`/company/trip/addExtraExpense?tripId=${data?._id}`)}
            className="secondaryButton w-full text-center h-fit text-sm ml-auto"
          >
            Extra Expense
          </button>
        </div>
        {data?.status !== "Completed" && data?.status !== "Planned" && (
          <>
            <div className="lg:col-start-9 col-start-1 lg:col-span-1 mt-auto col-span-6  lg:row-start-2">
              <button
                onClick={() =>
                  navigate(`/company/fuel/addFuel?tripId=${data?._id}`)
                }
                disabled={
                  !(data?.status !== "Completed" && data?.status !== "Planned")
                }
                className="secondaryButton w-full text-center h-fit text-sm ml-auto"
              >
                Add Fuel
              </button>
            </div>
            <div className="lg:col-start-10 col-start-7 lg:col-span-1 mt-auto col-span-6  lg:row-start-2">
              <button
                onClick={() =>
                  navigate(`/company/tollTax/addTollTax?tripId=${data?._id}`)
                }
                disabled={
                  !(data?.status !== "Completed" && data?.status !== "Planned")
                }
                className="secondaryButton w-full text-center h-fit text-sm ml-auto"
              >
                Add Toll
              </button>
            </div>
          </>
        )}
        {data?.status === "Planned" && (
          <>
            <div className=" mt-auto lg:col-start-11 col-start-1 lg:col-span-2 col-span-12 ml-auto w-full lg:row-start-2">
              <button
                type="button"
                className="primaryButton text-sm w-full"
                onClick={() =>
                  navigate(
                    `/company/trip/addtrip?id=${data?._id}&status=${data?.status}`
                  )
                }
              >
                Start Trip
              </button>
            </div>
          </>
        )}
        {data?.status === "Trip Started" && (
          <div className=" mt-auto lg:col-start-11 col-start-1 lg:col-span-2 col-span-12 ml-auto w-full lg:row-start-2">
            <Dialog open={showEndModal}>
              <DialogTrigger asChild>
                <button
                  type="button"
                  className="primaryButton text-sm w-full"
                  onClick={() => setShowEndModal(true)}
                >
                  Trip Complete
                </button>
              </DialogTrigger>
              <EndTrip data={data} setShowModal={setShowEndModal} />
            </Dialog>
          </div>
        )}
        {data?.status === "Trip Ended" && (
          <div className=" mt-auto lg:col-start-11 col-start-1 lg:col-span-2 col-span-12 ml-auto w-full lg:row-start-2">
            <Dialog open={showPaymentModal}>
              <DialogTrigger asChild>
                <button
                  type="button"
                  className="primaryButton text-sm w-full"
                  onClick={() => setShowPaymentModal(true)}
                >
                  Payment Done
                </button>
              </DialogTrigger>
              <Payment
                data={data}
                setShowModal={setShowPaymentModal}
                showModal={showPaymentModal}
              />
            </Dialog>
          </div>
        )}
      </div>
    </div>
  );
}

export default TripListCard;
