import { Dialog } from "../../../ui/dialog";
import moment from "moment";
import React, { useState } from "react";
import { FcImageFile } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import ImagePopUpDownload from "../../imagePopUpDownload";

function TripTabDetails({ data }) {
  const navigate = useNavigate();
  const [showImageModal, setShowImageModal] = useState(false);

  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(amount)
      : "₹0.00";
  return (
    <div className="bg-white p-4 rounded-md shadow-lg grid xl:grid-cols-6 lg:grid-cols-4 grid-cols-2 xl:grid-rows-7 grid-rows-9 lg:gap-x-20 gap-x-9  lg:gap-y-10 gap-y-4 w-full text-xs">
      {data?.tokenImage?.length > 0 && (
        <Dialog open={showImageModal}>
          <ImagePopUpDownload
            isArray={true}
            image={data?.tokenImage}
            setShowModal={setShowImageModal}
            imagename={`token_${data?._id}`}
          />
        </Dialog>
      )}
      <div className="lg:border-r lg:border-r-[#F1F2F6]  lg:border-b-0 border-b border-b-[#F1F2F6] row-span-full flex flex-col gap-2 lg:col-span-1 col-span-2 pb-5 lg:text-left text-center  ">
        <div>
          <label htmlFor="" className="">
            Token Number
          </label>
          <p className=" mt-2 textPrimary uppercase ">{data?.tokenNumber}</p>
        </div>
        <div>
          <label htmlFor="" className="">
            Truck Number
          </label>
          <p className="textSecondary uppercase cursor-pointer " onClick={()=>navigate("/company/truck/details?truckId="+ data?.truckId?._id)}>{data?.truckNumber}</p>
          <p className="textSecondary uppercase ">{data?.productType}</p>
        </div>
        {data?.status === "Planned" && (
          <div
            className={`flex gap-2 w-full md:justify-start justify-center text-[#04C3CA]`}
          >
            <p>
              <div
                className={`px-1.5 py-0.5 rounded-full border  text-[10px] font-medium border-[#04C3CA] `}
              >
                Trip Planned
              </div>
            </p>
          </div>
        )}
        {data?.status === "Trip Started" && (
          <div
            className={`flex gap-2 w-full md:justify-start justify-center text-[#CA8A04]`}
          >
            <p>
              <div
                className={`px-1.5 py-0.5 rounded-full border  text-[10px] font-medium border-[#CA8A04] `}
              >
                Trip Started
              </div>
            </p>
          </div>
        )}
        {data?.status === "Trip Ended" && (
          <div
            className={`flex gap-2 w-full md:justify-start justify-center text-[#DC1919]`}
          >
            <p>
              <div
                className={`px-1.5 py-0.5 rounded-full border  text-[10px] font-medium border-[#DC1919] `}
              >
                Trip Ended
              </div>
            </p>
          </div>
        )}
        {data?.status === "Completed" && (
          <div
            className={`flex gap-2 w-full md:justify-start justify-center text-[#15803D]`}
          >
            <p>
              <div
                className={`px-1.5 py-0.5 rounded-full border  text-[10px] font-medium border-[#15803D] `}
              >
                Completed
              </div>
            </p>
          </div>
        )}
      </div>
      <div className="">
        <label htmlFor="">Trip Start Date</label>
        <p className="textSecondary">
          {moment(data?.startDate).format("DD / MMM / YYYY")}
        </p>
      </div>
      <div className="">
        <label htmlFor="">Trip End Date</label>
        <p className="textSecondary">
          {data?.endDate
            ? moment(data?.endDate).format("DD / MMM / YYYY")
            : "--"}
        </p>
      </div>
      <div className="">
        <label htmlFor="">Trip Start Location</label>
        <p className="textSecondary">
          {data?.startLocation ? data?.startLocation : "--"}
        </p>
      </div>
      <div className="">
        <label htmlFor="">Trip End Location</label>
        <p className="textSecondary">
          {data?.endLocation ? data?.endLocation : "--"}
        </p>
      </div>
      <div className="">
        <label htmlFor="">Trip Total KM Run</label>
        <p className="textSecondary">
          {data?.distanceTravelled ? data?.distanceTravelled : "--"} KM
        </p>
      </div>
      <div className="">
        <label htmlFor="">Trip Start Load</label>
        <p className="textSecondary">
          {data?.startingLoad ? data?.startingLoad : "--"}{" "}
          {data?.startingLoad && data?.weigthType}
        </p>
      </div>
      <div className="">
        <label htmlFor="">Trip End Load</label>
        <p className="textSecondary">
          {data?.endingLoad ? data?.endingLoad : "--"}{" "}
          {data?.endingLoad && data?.weigthType}
        </p>
      </div>
      <div className="">
        <label htmlFor="">Trip Rate Per {data?.weigthType}</label>
        <p className="textSecondary">
          {data?.ratePerWeight ? formatAmount(data?.ratePerWeight) : "--"}{" "}
        </p>
      </div>
      <div
        className="cursor-pointer"
        onClick={() =>
          navigate("/company/employee/details/" + data?.driverId?._id)
        }
      >
        <label htmlFor="">Trip Driver Name</label>
        <p className="textSecondary">
          {data?.driverId
            ? data?.driverId?.firstName + " " + data?.driverId?.lastName
            : "--"}{" "}
        </p>
      </div>
      <div
        className="cursor-pointer"
        onClick={() =>
          navigate("/company/employee/details/" + data?.helperId?._id)
        }
      >
        <label htmlFor="">Trip Driver Name</label>
        <p className="textSecondary">
          {data?.helperId
            ? data?.helperId?.firstName + " " + data?.helperId?.lastName
            : "--"}{" "}
        </p>
      </div>
      {data?.isClientId ? (
        <div
          className="cursor-pointer"
          onClick={() =>
            navigate("/client/details?clientId=" + data?.clientId?._id)
          }
        >
          <label htmlFor="">Trip Client</label>
          <p className="textSecondary">
            {data?.clientId
              ? data?.clientId?.name + "(" + data?.clientId?.ownerName + ")"
              : "--"}{" "}
          </p>
        </div>
      ) : (
        <div className="">
          <label htmlFor="">Trip Client Name</label>
          <p className="textSecondary">
            {data?.clientNames !== "" ? data?.clientNames : "--"}{" "}
          </p>
        </div>
      )}
      {data?.tokenImage?.length > 0 && (
        
        <button
          type="button"
          onClick={() => setShowImageModal(true)}
          className="ghostButton font-semibold gap-1 xl:col-start-6 capitalize items-center hidden md:flex"
          disabled={!(data?.tokenImage?.length > 0)}
        >
          <FcImageFile className="text-2xl" /> Token Image
        </button>
      )}

      <div>
        <label htmlFor="">Advance Payment</label>
        <div
          className={`px-1.5 py-0.5 rounded-full border w-fit text-[10px] font-medium ${
            data?.isAdvancePayment
              ? "border-[#15803D] text-[#15803D] "
              : "border-[#DC1919] text-[#DC1919]"
          }`}
        >
          {data?.isAdvancePayment ? "YES" : "NO"}
        </div>
      </div>
      <div>
        <label htmlFor="">Advance Payment Date </label>
        <p className="textSecondary">
          {data?.advancePaymentDate
            ? moment(data?.advancePaymentDate).format("DD / MM / YYYY")
            : "--"}
        </p>
      </div>
      <div className="xl:col-span-2">
        <label htmlFor="">Advance Payment Transaction Number</label>
        <p className="textSecondary">
          {data?.advancePaymentNumber ? data?.advancePaymentNumber : "--"}
        </p>
      </div>
      <div>
        <label htmlFor="">Advance Amount Paid</label>
        <p className="textSecondary">
          {data?.advancePaymentCost ? data?.advancePaymentCost : "--"}
        </p>
      </div>
      <div className=" xl:col-span-5 lg:col-span-3 col-span-2 flex gap-2 items-end">
        <div className="whitespace-nowrap text-[#262626] font-semibold">
          Payment Details
        </div>
        {data.paymentStatus === "Pending" && (
          <div
            className={`flex gap-2 w-full md:justify-start justify-center
             text-[#DC1919]
              `}
          >
            <p>
              <div
                className={`px-1.5 py-0.5 rounded-full border  text-[10px] font-medium border-[#DC1919] text-[#DC1919]`}
              >
                {data.paymentStatus}
              </div>
            </p>
          </div>
        )}
        {data.paymentStatus === "Paid" && (
          <div
            className={`flex gap-2 w-full md:justify-start justify-center
             text-[#15803D]
              `}
          >
            <p>
              <div
                className={`px-1.5 py-0.5 rounded-full border  text-[10px] font-medium border-[#15803D] text-[#15803D]`}
              >
                {data.paymentStatus}
              </div>
            </p>
          </div>
        )}
        {data.paymentStatus === "Partial" && (
          <div
            className={`flex gap-2 w-full md:justify-start justify-center
             text-[#CA8A04]
              `}
          >
            <p>
              <div
                className={`px-1.5 py-0.5 rounded-full border  text-[10px] font-medium border-[#CA8A04] text-[#CA8A04]`}
              >
                {data.paymentStatus}
              </div>
            </p>
          </div>
        )}
      </div>
      <div className="">
        <label htmlFor="">Payment Type</label>
        <p className="textSecondary">
          {data?.paymentType ? data?.paymentType : "--"}
        </p>
      </div>
      {data?.paymentType === "Check" && (
        <div className="">
          <label htmlFor="">Check Number</label>
          <p className="textSecondary">
            {data?.checkNumber ? data?.checkNumber : "--"}
          </p>
        </div>
      )}
      {data?.paymentType === "Check" && (
        <>
          <div className="">
            <label htmlFor="">Payment Account Number</label>
            <p className="textSecondary">
              {data?.paymentAccount ? data?.paymentAccount : "--"}
            </p>
          </div>

          <div className="">
            <label htmlFor="">Transition Number</label>
            <p className="textSecondary">
              {data?.transitionNumber ? data?.transitionNumber : "--"}
            </p>
          </div>
        </>
      )}
      <div className="">
        <label htmlFor="" className="">
          Payment In{" "}
          <span className="text-[#64709B] text-[10px] whitespace-nowrap">
            (Without Deductions)
          </span>
        </label>
        <p className="textSecondary">
          {data?.totalPayment ? formatAmount(data?.totalPayment) : "--"}
        </p>
      </div>
      <div className="">
        <label htmlFor="">Any Deduction On Payment</label>
        <p className="textSecondary">
          {data?.anyDeduction ? formatAmount(data?.anyDeduction) : "--"}
        </p>
      </div>
      <div className="">
        <label htmlFor="">GST (in %)</label>
        <p className="textSecondary">
          {data?.gstOnPayment ? data?.gstOnPayment : "0"} %
        </p>
      </div>
      {data?.isAdvancePayment && (
        <div className="">
          <label htmlFor="">Final Payment</label>
          <p className="textSecondary">
            {data?.finalPayment ? formatAmount(data?.finalPayment) : "--"}
          </p>
        </div>
      )}
      <div className="">
        <label htmlFor="">
          Total Payment{" "}
          {data?.isAdvancePayment && (
            <span className="text-[#64709B] text-[10px] xl:whitespace-nowrap">
              (Include Advance Payment and Deductions)
            </span>
          )}
        </label>
        <p className="textSecondary text-[#15803D]">
          {data?.finalPayment
            ? formatAmount(
                data?.isAdvancePayment
                  ? data?.finalPayment + data?.advancePaymentCost
                  : data?.finalPayment
              )
            : "--"}
        </p>
      </div>
    </div>
  );
}

export default TripTabDetails;
