import Backdrop from "../../../../components/backdrop";
import { getTruckFitnessByTruckIdAction } from "../../../../redux/company/truck/action";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { Dialog } from "../../../../ui/dialog";
import UpdateFitness from "../updateFitness";
import FitnessListCard from "./fitnessListCard";

function Fitness() {
  const { truckId } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const {
    getTruckFitnessByTruckId,
    getTruckFitnessByTruckIdLoading,
    getTruckFitnessByTruckIdError,
  } = useSelector((state) => state.Companytrucksection);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getTruckFitnessByTruckIdAction(truckId));
  }, [truckId]);

  useEffect(() => {
    if (getTruckFitnessByTruckId?.status === 200) {
      setData(getTruckFitnessByTruckId?.data);
    }
  }, [getTruckFitnessByTruckId]);
  useEffect(() => {
    if (getTruckFitnessByTruckIdError?.status === 400) {
      toast.error(getTruckFitnessByTruckIdError?.message);
    }
    if (getTruckFitnessByTruckIdError?.status === 404) {
      toast.error(getTruckFitnessByTruckIdError?.message);
    }
    if (getTruckFitnessByTruckIdError?.status === 500) {
      toast.error(getTruckFitnessByTruckIdError?.error);
    }
  }, [getTruckFitnessByTruckId]);

  const [showFitnessModal, setshowFitnessModal] = useState(false);

  return (
    <div>
      {getTruckFitnessByTruckIdLoading && <Backdrop />}
      <div className="flex justify-end gap-5">
        <Dialog open={showFitnessModal}>
          <button
            type="button"
            className="primaryButton w-fit  h-full"
            onClick={() => setshowFitnessModal(true)}
          >
            Add Fitness
          </button>
          <UpdateFitness
            truckId={truckId}
            setShowModal={setshowFitnessModal}
            truckNumber={searchParams.get("truckNumber")}
          />
        </Dialog>
      </div>
      {data.length > 0 &&
        data.map((item) => (
          <FitnessListCard
            data={item}
          />
        ))}
    </div>
  );
}

export default Fitness;
