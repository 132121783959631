import { getCompanyEmployeeDetailsAction, patchCompanyEmployeeSalaryAction } from "../../../../redux/company/employee/action";
import DatePicker from "../../../../components/datepicker/datePicker";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../ui/dialog";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import toast from "react-hot-toast";

function UpdateSalary({ data, setShowModal }) {
  const dispatch = useDispatch();
  const {
    patchCompanyEmployeeSalary,
    patchCompanyEmployeeSalaryLoading,
    patchCompanyEmployeeSalaryError,
  } = useSelector((state) => state.CompanyEmployeesection);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (loader) {
      if (patchCompanyEmployeeSalary?.status === 200) {
        toast.success(patchCompanyEmployeeSalary.message, {
          id: "patch-salary-toast",
        });
        setLoader(false);
        const payload = {
            id: data?._id,
          };
          dispatch(getCompanyEmployeeDetailsAction(payload));
        setShowModal(false);
      }
    }
  }, [patchCompanyEmployeeSalary]);
  useEffect(() => {
    if (loader) {
      if (
        patchCompanyEmployeeSalaryError?.status === 400 ||
        patchCompanyEmployeeSalaryError?.status === 404
      ) {
        toast.error(patchCompanyEmployeeSalaryError.message, {
          id: "patch-salary-toast",
        });
        setLoader(false);
      } else if (patchCompanyEmployeeSalaryError?.status === 500) {
        toast.error(patchCompanyEmployeeSalaryError?.error, {
          id: "patch-salary-toast",
        });
        setLoader(false);
      }
    }
  }, [patchCompanyEmployeeSalaryError]);

  const validationSchema = Yup.object({
    salary: Yup.number().required("This Field is Required"),
    updateAt: Yup.string().required("This Field is Required"),
  });
  const onSubmit = (values) => {
    const payload = {
      id: data._id,
      body: values,
    };
    dispatch(patchCompanyEmployeeSalaryAction(payload));
    setLoader(true);
    toast.loading("Saving New Salary....", { id: "patch-salary-toast" });
  };
  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle>Update Salary Detail</DialogTitle>
      </DialogHeader>
      <div className="text-xl mt-5 font-semibold">
        Are you sure you want to <span className="">Update</span> this SALARY
        Details?
      </div>
      <Formik
        initialValues={{ salary: data?.salary || "", updateAt: "" }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="">
            <div className="mb-4 ">
              <label htmlFor="salary">New Salary *</label>
              <Field
                type="number"
                id="salary"
                name="salary"
                className=""
                placeholder="New Salary"
              />
              <ErrorMessage name="salary" component="div" className="error" />
            </div>
            <div className="mb-4">
              <label htmlFor="updateAt">When Increase *</label>
              <DatePicker id="updateAt" name="updateAt" />
              <ErrorMessage name="updateAt" component="div" className="error" />
            </div>
            <DialogFooter className="md:gap-5 ">
              <button
                className="ghostButton md:w-fit capitalize"
                type="button"
                onClick={() => setShowModal(false)}
                disabled={patchCompanyEmployeeSalaryLoading}
              >
                Close
              </button>

              <button
                type="submit"
                className="primaryButton md:w-fit capitalize"
                disabled={patchCompanyEmployeeSalaryLoading}
              >
                Update Salary
              </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default UpdateSalary;
