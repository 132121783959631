import React, { useState } from "react";
import Image from "../../../../assets/images/semi.png";
import { Link, useNavigate } from "react-router-dom";
import { Dialog } from "../../../../ui/dialog";
import ImagePopUpDownload from "../../../../components/imagePopUpDownload";
import { Badge } from "../../../../ui/badge";
import moment from "moment";
import TruckSell from "./truckSell";
import TruckPurchase from "../../truck/truckPurchase";

function TruckListCard({ data, reroute = false }) {
  const navigate = useNavigate();
  const [showImageModal, setShowImageModal] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [showPurchaseModal, setshowPurchaseModal] = useState(false);

  return (
    <div key={data?._id}>
      <div className="grid grid-cols-12 gap-x-7 my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl">
        <div className="lg:col-span-2 col-span-6 row-span-1 justify-self-center my-auto lg:row-span-3">
          <button
            type="button"
            className="rounded-full bg-gray-400"
            onClick={() =>
              reroute
                ? navigate(`/admin/company/truck/details/${data._id}`)
                : setShowImageModal(true)
            }
          >
            <img
              src={
                data?.truckId?.truckImage ? data?.truckId?.truckImage : Image
              }
              alt=""
              className={` ${
                data?.truckId?.truckImage ? "rounded-full" : ""
              }  h-[100px] w-[100px]`}
            />
          </button>
        </div>
        {data?.truckId?.truckImage && (
          <Dialog open={showImageModal}>
            <ImagePopUpDownload
              image={data?.truckId?.truckImage}
              setShowModal={setShowImageModal}
              imagename={`truck_${data?.truckId?._id}`}
            />
          </Dialog>
        )}
        <div className="mb-5 text-center md:text-left lg:col-span-10 col-span-6 row-span-1 self-center lg:self-auto">
          <button
            type="button"
            className="font-bold text-blue-600 text-xl"
            onClick={
              reroute
                ? () => navigate(`/admin/truck/details/${data._id}`)
                : null
            }
          >
            {data?.truckNumber}
          </button>
          <p className="textgrey text-base">
            {data?.truckId?.truckCompanyName}, {data?.truckId?.truckModelName},{" "}
            {data?.truckId?.typeOfTruck}{" "}
          </p>
        </div>
        <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center">
          <div className="md:text-left">
            <h3 className="textgrey text-xs">Running In</h3>
            <p>{data?.usedfor}</p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-center ">Subscription</h3>
            <p className="flex md:justify-center">
              {data?.isSubscribe ? (
                <Badge className="bg-green-700 hover:bg-green-900">
                  Subscribed
                </Badge>
              ) : (
                <Badge variant="destructive">UnSubscribed</Badge>
              )}
            </p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-center">
              Subscription End
            </h3>

            <p className="md:text-center">
              {data?.isSubscribe
                ? moment(data?.subscribeTill).format("DD/MM/YYYY") +
                  moment(data?.subscribeTill).fromNow()
                : "Please Subscribe"}
            </p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-right">Subscription End</h3>
            <p className="md:text-right">
              {moment(data?.subscribeTill).diff(moment(new Date()), "days")}{" "}
              Days Left
            </p>
          </div>

          {data?.isSell ? (
            <>
              <div>
                <h3 className="textgrey text-xs  ">Truck Selled</h3>
                <p className="flex ">
                  <Badge variant="destructive">Sell</Badge>
                </p>
              </div>
              <div className="md:text-center">
                <h3 className="textgrey text-xs  ">Truck Sell Date</h3>

                <p className="">
                  {moment(data?.sellDate).format("DD/MMM/YYYY")}
                </p>
              </div>
            </>
          ) : (
            <>
              <Link
                className="primaryButton bg-green-600 hover:bg-green-800 md:px-4 px-2 text-center"
                to={`/admin/truck/subscription?truckId=${data?.truckId._id}&companyTruckId=${data?._id}`}
              >
                View Subscription
              </Link>
              <Dialog open={showModal}>
                <button
                  type="button"
                  className="secondaryButton bg-red-600 hover:bg-red-800 text-white h-full"
                  onClick={() => setshowModal(true)}
                >
                  Sell Truck
                </button>
                <TruckSell truckId={data?._id} setshowModal={setshowModal} />
              </Dialog>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TruckListCard;
