// actions.js
import {
    GET_DEF,
    GET_DEF_SUCCESS,
    GET_DEF_ERROR,
    GET_DEF_BY_TRUCK,
    GET_DEF_BY_TRUCK_SUCCESS,
    GET_DEF_BY_TRUCK_ERROR,
    GET_DEF_BY_ID,
    GET_DEF_BY_ID_SUCCESS,
    GET_DEF_BY_ID_ERROR,
    POST_DEF,
    POST_DEF_SUCCESS,
    POST_DEF_ERROR,
    PATCH_DEF,
    PATCH_DEF_SUCCESS,
    PATCH_DEF_ERROR,
    DELETE_DEF,
    DELETE_DEF_SUCCESS,
    DELETE_DEF_ERROR,
    GET_DEF_FILTER,
    GET_DEF_FILTER_SUCCESS,
    GET_DEF_FILTER_ERROR,
  } from "../../../actions";
  
  export const getDEFAction = () => ({
    type: GET_DEF,
  });
  
  export const getDEFSuccess = (data) => ({
    type: GET_DEF_SUCCESS,
    payload: data,
  });
  
  export const getDEFError = (error) => ({
    type: GET_DEF_ERROR,
    payload: error,
  });
  
  export const getDEFByTruckAction = (tripId) => ({
    type: GET_DEF_BY_TRUCK,
    payload: tripId,
  });
  
  export const getDEFByTruckSuccess = (data) => ({
    type: GET_DEF_BY_TRUCK_SUCCESS,
    payload: data,
  });
  
  export const getDEFByTruckError = (error) => ({
    type: GET_DEF_BY_TRUCK_ERROR,
    payload: error,
  });
  
  export const getDEFByIdAction = (id) => ({
    type: GET_DEF_BY_ID,
    payload: id,
  });
  
  export const getDEFByIdSuccess = (data) => ({
    type: GET_DEF_BY_ID_SUCCESS,
    payload: data,
  });
  
  export const getDEFByIdError = (error) => ({
    type: GET_DEF_BY_ID_ERROR,
    payload: error,
  });
  
  export const postDEFAction = (data) => ({
    type: POST_DEF,
    payload: data,
  });
  
  export const postDEFSuccess = (data) => ({
    type: POST_DEF_SUCCESS,
    payload: data,
  });
  
  export const postDEFError = (error) => ({
    type: POST_DEF_ERROR,
    payload: error,
  });
  
  export const patchDEFAction = (data) => ({
    type: PATCH_DEF,
    payload: data,
  });
  
  export const patchDEFSuccess = (data) => ({
    type: PATCH_DEF_SUCCESS,
    payload: data,
  });
  
  export const patchDEFError = (error) => ({
    type: PATCH_DEF_ERROR,
    payload: error,
  });
  
  export const deleteDEFAction = (id) => ({
    type: DELETE_DEF,
    payload: id,
  });
  
  export const deleteDEFSuccess = (data) => ({
    type: DELETE_DEF_SUCCESS,
    payload: data,
  });
  
  export const deleteDEFError = (error) => ({
    type: DELETE_DEF_ERROR,
    payload: error,
  });
  
  export const getDEFFilterAction = (filterData) => ({
    type: GET_DEF_FILTER,
    payload: filterData,
  });
  
  export const getDEFFilterSuccess = (data) => ({
    type: GET_DEF_FILTER_SUCCESS,
    payload: data,
  });
  
  export const getDEFFilterError = (error) => ({
    type: GET_DEF_FILTER_ERROR,
    payload: error,
  });
  