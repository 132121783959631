import { getCompanyDashboardTripPendingAmountAction } from "../../../redux/company/dashboard/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

function PendingAmount() {
  const dispatch = useDispatch();
  const {
    getCompanyDashboardTripPendingAmount,
  } = useSelector((state) => state.CompanyDashboardSection);
  const [year, setYear] = useState("");
  const [data, setData] = useState(0);
  // Fetch and set data when component mounts or year/truck changes
  useEffect(() => {
    const loadTruckData = () => {
      const queryParams = {
        startDate: "",
        endDate: "",
        year: year,
      };

      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");

      dispatch(getCompanyDashboardTripPendingAmountAction(queryString));
    };

    loadTruckData();
  }, [dispatch, year]);
  useEffect(() => {
    if (getCompanyDashboardTripPendingAmount?.status === 200) {
      setData(getCompanyDashboardTripPendingAmount.data);
    }
  }, []);
  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 2,
        }).format(amount)
      : "₹0.00";
  return (
    <div className="bg-white p-4 lg:h-full h-fit xl:order-3 order-4 xl:col-span-1 lg:col-span-1 col-span-2 ">
      <div className="flex justify-between">
        <div className="lg:text-2xl text-xl font-bold mb-4">Pending Amount</div>
        <div className="flex gap-4">
          <select
            value={year}
            className="border border-gray-200  h-fit text-xs min-h-fit"
            onChange={(e) => setYear(e.target.value)}
          >
            <option value="">All</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
          </select>
        </div>
      </div>
      <div className="m-auto text-4xl text-center content-center h-full font-black text-red-500">
        {formatAmount(data)}
      </div>
    </div>
  );
}

export default PendingAmount;
