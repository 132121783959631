import React, { useEffect, useState } from "react";
import TripListCard from "./tripListCard";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../ui/tabs";
import { PiTruck } from "react-icons/pi";
import { FaRoute } from "react-icons/fa";
import { BsFuelPumpFill } from "react-icons/bs";
import { TbCheckupList } from "react-icons/tb";
import { CiReceipt } from "react-icons/ci";
import TruckTabDetails from "../Truck/truckTabDetails";
import { getCompanyTruckDetailsIdAction } from "../../../redux/company/truck/action";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyTripDetailsIdAction } from "../../../redux/company/trip/action";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import FuelList from "../fuel/fuelList";
import Backdrop from "../../backdrop";
import TripTabDetails from "./tripTabDetails";
import TollTaxList from "../tollTax/tollTaxList";
import ExtraExpenseTripList from "./extraExpense/extraExpenseTripList";

function TripDetails() {
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const {
    getCompanyTruckDetailsId,
    getCompanyTruckDetailsIdLoading,
    getCompanyTruckDetailsIdError,
  } = useSelector((state) => state.Companytrucksection);
  const {
    getCompanyTripDetailsId,
    getCompanyTripDetailsIdLoading,
    getCompanyTripDetailsIdError,
  } = useSelector((state) => state.CompanyTripsection);
  const {
    getExtraExpenseTripFilter,
    getExtraExpenseTripFilterLoading,
    getExtraExpenseTripFilterError,
  } = useSelector((state) => state.ExpenseTripSection);
  const {
    getCompanyFuelFilter,
    getCompanyFuelFilterLoading,
    getCompanyFuelFilterError,
  } = useSelector((state) => state.Fuelsection);
  const {
    getCompanyTollTaxFilter,
    getCompanyTollTaxFilterLoading,
    getCompanyTollTaxFilterError,
  } = useSelector((state) => state.TollTaxsection);

  const [data, setData] = useState([]);
  const [truckData, setTruckData] = useState([]);
  const [fuelData, setFuelData] = useState([]);
  const [tollTaxData, setTollTaxData] = useState([]);
  const [extraExpense, setExtraExpense] = useState([]);
  const [dropdown, setDropdown] = useState("Trips");


  useEffect(() => {
    const id = searchParams.get("tripId");
    const truckid = searchParams.get("truckId");
    dispatch(getCompanyTripDetailsIdAction(id));
    dispatch(getCompanyTruckDetailsIdAction(truckid));
  }, [dispatch]);
  useEffect(() => {
    if (getCompanyTruckDetailsId?.status === 200) {
      setTruckData(getCompanyTruckDetailsId.truck);
    }
  }, [getCompanyTruckDetailsId]);
  useEffect(() => {
    if (getCompanyTruckDetailsIdError?.status === 404) {
      toast.error(getCompanyTruckDetailsIdError.message, {
        id: "truck-toast",
      });
    } else if (getCompanyTruckDetailsIdError?.status === 400) {
      toast.error(getCompanyTruckDetailsIdError.message, {
        id: "truck-toast",
      });
    } else if (getCompanyTruckDetailsIdError?.status === 500) {
      toast.error(getCompanyTruckDetailsIdError.error, {
        id: "truck-toast",
      });
    }
  }, [getCompanyTruckDetailsIdError]);
  useEffect(() => {
    if (getCompanyTripDetailsId?.status === 200) {
      setData(getCompanyTripDetailsId.data);
    }
  }, [getCompanyTripDetailsId]);
  useEffect(() => {
    if (getCompanyTripDetailsIdError?.status === 404) {
      toast.error(getCompanyTripDetailsIdError.message, {
        id: "trip-toast",
      });
    } else if (getCompanyTripDetailsIdError?.status === 400) {
      toast.error(getCompanyTripDetailsIdError.message, {
        id: "trip-toast",
      });
    } else if (getCompanyTripDetailsIdError?.status === 500) {
      toast.error(getCompanyTripDetailsIdError.error, {
        id: "trip-toast",
      });
    }
  }, [getCompanyTripDetailsIdError]);
  useEffect(() => {
    if (getCompanyFuelFilter?.status === 200) {
      setFuelData(getCompanyFuelFilter.data);
    }
  }, [getCompanyFuelFilter]);
  useEffect(() => {
    if (getCompanyFuelFilterError?.status === 404) {
      toast.error(getCompanyFuelFilterError.message, {
        id: "fuel-toast",
      });
    } else if (getCompanyFuelFilterError?.status === 400) {
      toast.error(getCompanyFuelFilterError.message, {
        id: "fuel-toast",
      });
    } else if (getCompanyFuelFilterError?.status === 500) {
      toast.error(getCompanyFuelFilterError.error, {
        id: "fuel-toast",
      });
    }
  }, [getCompanyFuelFilterError]);
  useEffect(() => {
    if (getCompanyTollTaxFilter?.status === 200) {
      setTollTaxData(getCompanyTollTaxFilter.data);
    }
  }, [getCompanyTollTaxFilter]);
  useEffect(() => {
    if (getCompanyTollTaxFilterError?.status === 404) {
      toast.error(getCompanyTollTaxFilterError.message, {
        id: "toll-toast",
      });
    } else if (getCompanyTollTaxFilterError?.status === 400) {
      toast.error(getCompanyTollTaxFilterError.message, {
        id: "toll-toast",
      });
    } else if (getCompanyTollTaxFilterError?.status === 500) {
      toast.error(getCompanyTollTaxFilterError.error, {
        id: "toll-toast",
      });
    }
  }, [getCompanyTollTaxFilterError]);
  useEffect(() => {
    if (getExtraExpenseTripFilter?.status === 200) {
      setExtraExpense(getExtraExpenseTripFilter.data);
    }
  }, [getExtraExpenseTripFilter]);
  useEffect(() => {
    if (getExtraExpenseTripFilterError?.status === 404) {
      toast.error(getExtraExpenseTripFilterError.message, {
        id: "service-toast",
      });
    } else if (getExtraExpenseTripFilterError?.status === 400) {
      toast.error(getExtraExpenseTripFilterError.message, {
        id: "service-toast",
      });
    } else if (getExtraExpenseTripFilterError?.status === 500) {
      toast.error(getExtraExpenseTripFilterError.error, {
        id: "service-toast",
      });
    }
  }, [getExtraExpenseTripFilterError]);

  
  const handleTab = (value) => {
    setDropdown(value)
    searchParams.set("tab", value);
    setSearchParams(searchParams);
  };

  // Dropdown data for small screens
  const dropdownOptions = [
    { value: "Trips", label: "Trips" },
    { value: "Truck", label: "Truck" },
    { value: "Fuel", label: "Fuel" },
    { value: "Toll", label: "Toll" },
    { value: "Extra Expences", label: "Extra Expences" },
  ].filter(Boolean); // Filter out any false values

  const handleDropdownChange = (event) => {
    handleTab(event.target.value);
  };

  return (
    <div>
      {(getCompanyTruckDetailsIdLoading ||
        getCompanyTripDetailsIdLoading ||
        getExtraExpenseTripFilterLoading ||
        getCompanyFuelFilterLoading ||
        getCompanyTollTaxFilterLoading) && <Backdrop />}
      <TripListCard data={data} />
      {/* Dropdown for small screens */}
      <div className="block md:hidden mb-5">
        <select
          onChange={handleDropdownChange}
          value={searchParams.get("tab") || "All"}
          className="primarySelect w-full"
        >
          {dropdownOptions.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      {/* Tabs for larger screens */}
      <Tabs   defaultValue={searchParams.get("tab") || dropdown}
        value={searchParams.get("tab") || dropdown}
        className="mt-4"
        >
        <TabsList className="hidden w-fit md:block">
          <TabsTrigger onClick={() => handleTab("Trips")} value="Trips">
            <FaRoute className="md:mr-1 mr-0.5 md:text-xl " /> Trips
          </TabsTrigger>
          <TabsTrigger onClick={() => handleTab("Truck")} value="Truck">
            <PiTruck className="md:mr-1 mr-0.5 md:text-xl " /> Truck
          </TabsTrigger>
          <TabsTrigger onClick={() => handleTab("Fuel")} value="Fuel">
            <BsFuelPumpFill className="md:mr-1 mr-0.5 md:text-xl " /> Fuel
          </TabsTrigger>
          <TabsTrigger onClick={() => handleTab("Toll")} value="Toll">
            <CiReceipt className="md:mr-1 mr-0.5 md:text-xl " /> Toll Tax
          </TabsTrigger>
          <TabsTrigger onClick={() => handleTab("Extra Expences")} value="Extra Expences">
            <TbCheckupList className="md:mr-1 mr-0.5 md:text-xl " /> Other
            Expences
          </TabsTrigger>
        </TabsList>
        <TabsContent value="Trips">
          <TripTabDetails data={data} />
        </TabsContent>
        <TabsContent value="Truck" className="w-full">
          <TruckTabDetails data={truckData} />
        </TabsContent>
        <TabsContent value="Fuel">
          <FuelList data={fuelData} />
        </TabsContent>
        <TabsContent value="Toll">
          <TollTaxList data={tollTaxData} />
        </TabsContent>
        <TabsContent value="Extra Expences">
          <ExtraExpenseTripList data={extraExpense} />
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default TripDetails;
