// src/components/Login.jsx
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import DeviceDetector from "device-detector-js";
import loginImage from "../assets/images/login.jpeg"; // adjust the path as necessary
import { useDispatch, useSelector } from "react-redux";
import { postLoginAction } from "../redux/auth/action";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { getCookies, setCookies } from "../auth/auth";
import useDeviceInfoAndLocation from "../auth/useDeviceAndLocation";

const Login = () => {
  const { t } = useTranslation();

  const { postLogin, postLoginLoading, postLoginError } = useSelector(
    (state) => state.Authsection
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deviceInfo, location } = useDeviceInfoAndLocation();

  const [loader, setloader] = useState(false);
  useEffect(() => {
    if (loader) {
      handler();
    }
  }, [postLogin]);

  const handler = async () => {
    if (postLogin.status === 200) {
      toast.success(postLogin.message, { id: "login-toast" });
      setloader(false);
      const token = await setCookies("token", postLogin.token);
      const userType = await setCookies("userType", postLogin.data.userType);
      if (token.status === true && userType.status === true) {
        navigate(`/${postLogin.data.userType}/dashboard`);
      }
    } else if (postLogin.status === 400) {
      toast.error(postLogin.message, { id: "login-toast" });
    } else if (postLogin.status === 500) {
      toast.error(postLogin.error, { id: "login-toast" });
    }
  };

  useEffect(() => {
    initialLoad();
  }, [navigate]);

  const initialLoad = async () => {
    const token = await getCookies("token");
    const userType = await getCookies("userType");
    if (token && userType && loader === false) {
      navigate(`/${userType}/dashboard`);
    }
  };
  const initialValues = {
    email: "",
    password: "",
    deviceName: deviceInfo.deviceName,
    deviceType: deviceInfo.deviceType,
    latitude: location.latitude,
    longitude: location.longitude,
  };

  const validationSchema = Yup.object({
    email: Yup.string().email(t("invalidEmail")).required(t("required")),
    password: Yup.string().required(t("required")),
  });

  const onSubmit = (values) => {
    values.email = values.email.toLowerCase()
    const formData = {
      ...values,
      deviceName: deviceInfo.deviceName,
      deviceType: deviceInfo.deviceType,
      latitude: location.latitude,
      longitude: location.longitude,
    };
    dispatch(postLoginAction(formData));
    setloader(true);
    toast.loading("Logging In...", { id: "login-toast" });
  };

  return (
    <div className="w-full p-8 md:col-span-2 bg-gray-100 col-span-5 flex flex-col justify-between">
      <h2 className="text-2xl font-bold uppercase ">{t("login")}</h2>
      <h2 className="text-lg text-gray-400  ">{t("slogan")}</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => (
          <Form className="space-y-8 ">
            <div>
              <label htmlFor="email">
                {t("email")} <span>*</span>
              </label>
              <Field
                type="email"
                id="email"
                name="email"
                autoFocus
                placeholder={t("email")}
              />
              <ErrorMessage
                name="email"
                component="div"
                className="error"
              />
            </div>
            <div>
              <label htmlFor="password">
                {t("password")} <span>*</span>
              </label>
              <Field
                type="password"
                id="password"
                name="password"
                placeholder={t("password")}
              />
              <ErrorMessage
                name="password"
                component="div"
                className="error"
              />
              <Link
                to={"/forgetPassword"}
                className="ghostButton capitalize text-sm mt-2 flex justify-end "
              >
                {t("forgetPassword")}
              </Link>
            </div>

            <button
              type="submit"
              disabled={postLoginLoading}
              className="primaryButton"
            >
              {t("login")}
            </button>
          </Form>
        )}
      </Formik>
      <p className="text-center">
        <button
          onClick={() => navigate("/signUp")}
          className="ghostButton"
          disabled={postLoginLoading}
        >
          {t("createAccount")}
        </button>
      </p>
    </div>
  );
};

export default Login;
