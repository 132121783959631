import api from "../../auth/api.jsx";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_COMPANY_DETAILS,
  GET_COMPANY_SEARCH,
  PATCH_COMPANY_DETAILS,
  POST_COMPANY_DETAILS,
} from "../actions.js";
import {
  getCompanyDetailsError,
  getCompanyDetailsSuccess,
  getCompanySearchError,
  getCompanySearchSuccess,
  patchCompanyDetailsError,
  patchCompanyDetailsSuccess,
  postCompanyDetailsError,
  postCompanyDetailsSuccess,
} from "./action.js";

// Async function to handle API call
const postCompanyDetailsAsync = async (payload) => {
  try {
    const response = await api.post(`/company/add`, payload.payload);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* postCompanyDetailsCall(action) {
  try {
    const data = yield call(postCompanyDetailsAsync, action); // Call async function with action payload
    yield put(postCompanyDetailsSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postCompanyDetailsError(error)); // Dispatch error action with error object
  }
}
export function* watchPostCompanyDetailsData() {
  yield takeEvery(POST_COMPANY_DETAILS, postCompanyDetailsCall);
}
// Async function to handle API call
const patchCompanyDetailsAsync = async (payload) => {
  try {
    console.log(payload);
    const response = await api.patch(
      `/company/update/${payload.payload.id}`,
      payload.payload.body
    );
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* patchCompanyDetailsCall(action) {
  try {
    const data = yield call(patchCompanyDetailsAsync, action); // Call async function with action payload
    yield put(patchCompanyDetailsSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(patchCompanyDetailsError(error)); // Dispatch error action with error object
  }
}
export function* watchPatchCompanyDetailsData() {
  yield takeEvery(PATCH_COMPANY_DETAILS, patchCompanyDetailsCall);
}

// Async function to handle API call
const getCompanyDetailsAsync = async (payload) => {
  try {
    const response = await api.get(`/company/get`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyDetailsCall(action) {
  try {
    const data = yield call(getCompanyDetailsAsync, action); // Call async function with action payload
    yield put(getCompanyDetailsSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyDetailsError(error)); // Dispatch error action with error object
  }
}
export function* watchGetCompanyDetailsData() {
  yield takeEvery(GET_COMPANY_DETAILS, getCompanyDetailsCall);
}

// Async function to handle API call
const getCompanySearchAsync = async (payload) => {
  try {
    const response = await api.get(`/company/get/${payload.payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanySearchCall(action) {
  try {
    const data = yield call(getCompanySearchAsync, action); // Call async function with action payload
    yield put(getCompanySearchSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanySearchError(error)); // Dispatch error action with error object
  }
}
export function* watchGetCompanySearchData() {
  yield takeEvery(GET_COMPANY_SEARCH, getCompanySearchCall);
}

export default function* rootSaga() {
  yield all([
    fork(watchPostCompanyDetailsData),
    fork(watchGetCompanyDetailsData),
    fork(watchGetCompanySearchData),
    fork(watchPatchCompanyDetailsData),
  ]);
}
