import { Field } from "formik";
import React from "react";
import Select from "react-select";
import "../assets/css/reactSelect.css";

function ReactSelectComponent(props) {
  const { name, errorName, options, ...rest } = props;
  return (
    <>
      <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <>
              <Select
                {...field}
                id={name}
                isMulti
                options={options}
                onChange={(val) => {
                  setFieldValue(name, val);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
                {...rest}
              />
            </>
          );
        }}
      </Field>
    </>
  );
}

export default ReactSelectComponent;
