import { Checkbox } from "../../../ui/checkbox";
import { getAdminDashboardEmployeeGraphAction } from "../../../redux/admin/dashboard/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "react-apexcharts";

function EmployeeCard() {
  const dispatch = useDispatch();
  const {
    getAdminDashboardEmployeeGraph,
    getAdminDashboardEmployeeGraphLoading,
    getAdminDashboardEmployeeGraphError,
  } = useSelector((state) => state.AdminDashboardSection);
  // Local state to store filter values
  const [isWorking, setIsWorking] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [year, setYear] = useState("2024");
  const [data, setData] = useState(0);

  useEffect(() => {
    if (getAdminDashboardEmployeeGraph?.status === 200) {
      setData(getAdminDashboardEmployeeGraph.data);
    }
  }, [getAdminDashboardEmployeeGraph]);

  // Load truck data when component mounts or state changes
  useEffect(() => {
    initialLoad(isWorking, startDate, endDate, year);
  }, [isWorking, startDate, endDate, year]); // Add dependencies to re-run on changes

  const initialLoad = (isWorkingvalue, startDateValue, endDateValue, yearValue) => {
    const queryParams = {
      isWorking: isWorkingvalue === true ? true : "",
      startDate: startDateValue,
      endDate: endDateValue,
      year: yearValue,
    };

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    // Dispatch action to get truck count
    dispatch(getAdminDashboardEmployeeGraphAction(queryString));
  };

  const handleCheckboxChange = () => {
    // Toggle isRunningTruck state
    setIsWorking((prev) => !prev);
  };

  const chartData = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: data?.graph?.map((item) => item.period),
      },
      stroke: {
        curve: "smooth",
      },
      colors: ["#FF9800"],
    },

    series: [
      {
        name: "Total Cost",
        data: data?.graph?.map((item) => item.employeesAdded),
      },
    ],
  };

  return (
    <div className="bg-white p-4 h-fit">
      <div className="flex justify-between">
        <div className="text-4xl font-bold mb-4">Employees</div>
        <div className="flex gap-4">
          <div className="flex gap-1 my-auto">
            <Checkbox
              checked={isWorking}
              onCheckedChange={handleCheckboxChange}
              id="running"
            />
            <label htmlFor="running">Employees</label>
          </div>
          <div>
            {/* Pass handleCheckboxChange as a function reference */}
            <select
              name=""
              id=""
              value={year}
              className="border border-gray-200 "
              onChange={(e) => setYear(e.target.value)}
            >
              <option value="">All</option>
              <option value="2024">2024</option>
              <option value="2023">2023</option>
              <option value="2022">2022</option>
            </select>
          </div>
        </div>
      </div>
      <div className="text-3xl font-bold mb-4">
        {data.count}
        <label> Total Employees {isWorking ? "Working" : "Available"}</label>
      </div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={200}

        // width="500"
      />
    </div>
  );
}

export default EmployeeCard;
