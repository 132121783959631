import api from "../../../auth/api.jsx";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  getCompanyStatsError,
  getCompanyStatsSuccess,
  getTruckStatsError,
  getTruckStatsSuccess,
  getTruckByTruckIdStatsError,
  getTruckByTruckIdStatsSuccess,
  getTripStatsError,
  getTripStatsSuccess,
  getTripByTripIdStatsError,
  getTripByTripIdStatsSuccess,
  getFuelStatsError,
  getFuelStatsSuccess,
  getTollStatsError,
  getTollStatsSuccess,
  getExtraExpenseStatsError,
  getExtraExpenseStatsSuccess,
  getServiceStatsError,
  getServiceStatsSuccess,
  getDefStatsError,
  getDefStatsSuccess,
  getEmiStatsError,
  getEmiStatsSuccess,
  getSalaryStatsError,
  getSalaryStatsSuccess,
  getInsuranceStatsSuccess,
  getInsuranceStatsError,
  getFitnessStatsSuccess,
  getFitnessStatsError,
  getPermitStatsSuccess,
  getPermitStatsError,
} from "./action.js";
import {
  GET_COMPANY_STATS,
  GET_TRUCK_STATS,
  GET_TRUCK_BY_TRUCKID_STATS,
  GET_TRIP_STATS,
  GET_TRIP_BY_TRIPID_STATS,
  GET_FUEL_STATS,
  GET_TOLL_STATS,
  GET_EXTRA_EXPENSE_STATS,
  GET_SERVICE_STATS,
  GET_DEF_STATS,
  GET_EMI_STATS,
  GET_SALARY_STATS,
  GET_INSURANCE_STATS,
  GET_FITNESS_STATS,
  GET_PERMIT_STATS,
} from "../../actions.js";

// Async functions to handle API calls
const getCompanyStatsAsync = async (payload) => {
  try {
    const response = await api.get(`/company/financials/company?${payload}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getTruckStatsAsync = async (payload) => {
  try {
    const response = await api.post(`/company/financials/trucks`, payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getTruckByTruckIdStatsAsync = async (payload) => {
  try {
    const response = await api.get(`/company/financials/truck?${payload}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getTripStatsAsync = async (payload) => {
  try {
    const response = await api.post(`/company/financials/trips`, payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getTripByTripIdStatsAsync = async (payload) => {
  try {
    const response = await api.get(`/company/financials/trips/${payload.id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getInsuranceStatsAsync = async (payload) => {
  try {
    const response = await api.get(`/company/financials/insurance?${payload}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getFitnessStatsAsync = async (payload) => {
  try {
    const response = await api.get(`/company/financials/fitness?${payload}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getPermitStatsAsync = async (payload) => {
  try {
    const response = await api.get(`/company/financials/permit?${payload}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getFuelStatsAsync = async (payload) => {
  try {
    const response = await api.get(`/company/financials/fuel?${payload}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getTollStatsAsync = async (payload) => {
  try {
    const response = await api.get(`/company/financials/toll?${payload}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getExtraExpenseStatsAsync = async (payload) => {
  try {
    const response = await api.get(`/company/financials/extra-expenses?${payload}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getServiceStatsAsync = async (payload) => {
  try {
    const response = await api.get(`/company/financials/service?${payload}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getDefStatsAsync = async (payload) => {
  try {
    const response = await api.get(`/company/financials/def?${payload}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getEmiStatsAsync = async (payload) => {
  try {
    const response = await api.get(`/company/financials/emi?${payload}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getSalaryStatsAsync = async (payload) => {
  try {
    const response = await api.get(`/company/financials/salary?${payload}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Saga functions
function* getCompanyStatsCall(action) {
  try {
    const data = yield call(getCompanyStatsAsync, action.payload);
    yield put(getCompanyStatsSuccess(data));
  } catch (error) {
    yield put(getCompanyStatsError(error));
  }
}

function* getTruckStatsCall(action) {
  try {
    const data = yield call(getTruckStatsAsync, action.payload);
    yield put(getTruckStatsSuccess(data));
  } catch (error) {
    yield put(getTruckStatsError(error));
  }
}

function* getTruckByTruckIdStatsCall(action) {
  try {
    const data = yield call(getTruckByTruckIdStatsAsync, action.payload);
    yield put(getTruckByTruckIdStatsSuccess(data));
  } catch (error) {
    yield put(getTruckByTruckIdStatsError(error));
  }
}

function* getTripStatsCall() {
  try {
    const data = yield call(getTripStatsAsync);
    yield put(getTripStatsSuccess(data));
  } catch (error) {
    yield put(getTripStatsError(error));
  }
}

function* getTripByTripIdStatsCall(action) {
  try {
    const data = yield call(getTripByTripIdStatsAsync, action.payload);
    yield put(getTripByTripIdStatsSuccess(data));
  } catch (error) {
    yield put(getTripByTripIdStatsError(error));
  }
}

function* getInsuranceStatsCall(action) {
  try {
    const data = yield call(getInsuranceStatsAsync, action.payload);
    yield put(getInsuranceStatsSuccess(data));
  } catch (error) {
    yield put(getInsuranceStatsError(error));
  }
}
function* getFitnessStatsCall(action) {
  try {
    const data = yield call(getFitnessStatsAsync, action.payload);
    yield put(getFitnessStatsSuccess(data));
  } catch (error) {
    yield put(getFitnessStatsError(error));
  }
}
function* getPermitStatsCall(action) {
  try {
    const data = yield call(getPermitStatsAsync, action.payload);
    yield put(getPermitStatsSuccess(data));
  } catch (error) {
    yield put(getPermitStatsError(error));
  }
}
function* getFuelStatsCall(action) {
  try {
    const data = yield call(getFuelStatsAsync, action.payload);
    yield put(getFuelStatsSuccess(data));
  } catch (error) {
    yield put(getFuelStatsError(error));
  }
}

function* getTollStatsCall(action) {
  try {
    const data = yield call(getTollStatsAsync, action.payload);
    yield put(getTollStatsSuccess(data));
  } catch (error) {
    yield put(getTollStatsError(error));
  }
}

function* getExtraExpenseStatsCall(action) {
  try {
    const data = yield call(getExtraExpenseStatsAsync, action.payload);
    yield put(getExtraExpenseStatsSuccess(data));
  } catch (error) {
    yield put(getExtraExpenseStatsError(error));
  }
}

function* getServiceStatsCall(action) {
  try {
    const data = yield call(getServiceStatsAsync, action.payload);
    yield put(getServiceStatsSuccess(data));
  } catch (error) {
    yield put(getServiceStatsError(error));
  }
}

function* getDefStatsCall(action) {
  try {
    const data = yield call(getDefStatsAsync, action.payload);
    yield put(getDefStatsSuccess(data));
  } catch (error) {
    yield put(getDefStatsError(error));
  }
}

function* getEmiStatsCall(action) {
  try {
    const data = yield call(getEmiStatsAsync, action.payload);
    yield put(getEmiStatsSuccess(data));
  } catch (error) {
    yield put(getEmiStatsError(error));
  }
}

function* getSalaryStatsCall(action) {
  try {
    const data = yield call(getSalaryStatsAsync, action.payload);
    yield put(getSalaryStatsSuccess(data));
  } catch (error) {
    yield put(getSalaryStatsError(error));
  }
}

// Watcher sagas
export function* watchGetCompanyStats() {
  yield takeEvery(GET_COMPANY_STATS, getCompanyStatsCall);
}

export function* watchGetTruckStats() {
  yield takeEvery(GET_TRUCK_STATS, getTruckStatsCall);
}

export function* watchGetTruckByTruckIdStats() {
  yield takeEvery(GET_TRUCK_BY_TRUCKID_STATS, getTruckByTruckIdStatsCall);
}

export function* watchGetTripStats() {
  yield takeEvery(GET_TRIP_STATS, getTripStatsCall);
}

export function* watchGetTripByTripIdStats() {
  yield takeEvery(GET_TRIP_BY_TRIPID_STATS, getTripByTripIdStatsCall);
}

export function* watchGetInsuranceStats() {
  yield takeEvery(GET_INSURANCE_STATS, getInsuranceStatsCall);
}

export function* watchGetFitnessStats() {
  yield takeEvery(GET_FITNESS_STATS, getFitnessStatsCall);
}

export function* watchGetPermitStats() {
  yield takeEvery(GET_PERMIT_STATS, getPermitStatsCall);
}

export function* watchGetFuelStats() {
  yield takeEvery(GET_FUEL_STATS, getFuelStatsCall);
}

export function* watchGetTollStats() {
  yield takeEvery(GET_TOLL_STATS, getTollStatsCall);
}

export function* watchGetExtraExpenseStats() {
  yield takeEvery(GET_EXTRA_EXPENSE_STATS, getExtraExpenseStatsCall);
}

export function* watchGetServiceStats() {
  yield takeEvery(GET_SERVICE_STATS, getServiceStatsCall);
}

export function* watchGetDefStats() {
  yield takeEvery(GET_DEF_STATS, getDefStatsCall);
}

export function* watchGetEmiStats() {
  yield takeEvery(GET_EMI_STATS, getEmiStatsCall);
}

export function* watchGetSalaryStats() {
  yield takeEvery(GET_SALARY_STATS, getSalaryStatsCall);
}

// Root saga
export default function* rootSaga() {
  yield all([
    fork(watchGetCompanyStats),
    fork(watchGetTruckStats),
    fork(watchGetTruckByTruckIdStats),
    fork(watchGetTripStats),
    fork(watchGetTripByTripIdStats),
    fork(watchGetInsuranceStats),
    fork(watchGetFitnessStats),
    fork(watchGetPermitStats),
    fork(watchGetFuelStats),
    fork(watchGetTollStats),
    fork(watchGetExtraExpenseStats),
    fork(watchGetServiceStats),
    fork(watchGetDefStats),
    fork(watchGetEmiStats),
    fork(watchGetSalaryStats),
  ]);
}
