import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import api from "../../../auth/api";
import { DELETE_ADMIN_EXPENSE, GET_ADMIN_EXPENSE_BY_ID, GET_ADMIN_EXPENSES_LIST, PATCH_ADMIN_EXPENSE, POST_ADMIN_EXPENSE } from "../../actions";
import { deleteAdminExpenseError, deleteAdminExpenseSuccess, getAdminExpenseByIdError, getAdminExpenseByIdSuccess, getAdminExpensesListError, getAdminExpensesListSuccess, patchAdminExpenseError, patchAdminExpenseSuccess, postAdminExpenseError, postAdminExpenseSuccess } from "./action";


// Async function to handle API call
const getAdminExpensesListAsync = async (payload) => {
    try {
      const response = await api.get(`/admin/expense/get`);
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* getAdminExpensesListCall(action) {
    try {
      const data = yield call(getAdminExpensesListAsync, action); // Call async function with action payload
      yield put(getAdminExpensesListSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(getAdminExpensesListError(error)); // Dispatch error action with error object
    }
  }
  export function* watchGetAdminExpensesListData() {
    yield takeEvery(GET_ADMIN_EXPENSES_LIST, getAdminExpensesListCall);
  }

// Async function to handle API call
const getAdminExpenseByIdAsync = async (payload) => {
    try {
      const response = await api.get(`/admin/expense/getById/${payload}`);
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* getAdminExpenseByIdCall(action) {
    try {
      const data = yield call(getAdminExpenseByIdAsync, action.payload); // Call async function with action payload
      yield put(getAdminExpenseByIdSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(getAdminExpenseByIdError(error)); // Dispatch error action with error object
    }
  }
  export function* watchGetAdminExpenseByIdData() {
    yield takeEvery(GET_ADMIN_EXPENSE_BY_ID, getAdminExpenseByIdCall);
  }
  // Async function to handle API call
const postAdminExpenseAsync = async (payload) => {
    try {
      const response = await api.post(`/admin/expense/add`, payload);
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* postAdminExpenseCall(action) {
    try {
      const data = yield call(postAdminExpenseAsync, action.payload); // Call async function with action payload
      yield put(postAdminExpenseSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(postAdminExpenseError(error)); // Dispatch error action with error object
    }
  }
  export function* watchPostAdminExpenseData() {
    yield takeEvery(POST_ADMIN_EXPENSE, postAdminExpenseCall);
  }
  
  // Async function to handle API call
  const patchAdminExpenseAsync = async (payload) => {
    try {
      const response = await api.patch(
        `/admin/expense/update/${payload.id}`,
        payload.body
      );
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* patchAdminExpenseCall(action) {
    try {
      const data = yield call(patchAdminExpenseAsync, action.payload); // Call async function with action payload
      yield put(patchAdminExpenseSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(patchAdminExpenseError(error)); // Dispatch error action with error object
    }
  }
  export function* watchPatchAdminExpenseData() {
    yield takeEvery(
      PATCH_ADMIN_EXPENSE,
      patchAdminExpenseCall
    );
  }
  
  // Async function to handle API call
  const deleteAdminExpenseAsync = async (payload) => {
    try {
      const response = await api.delete(
        `/admin/expense/${payload}`);
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* deleteAdminExpenseCall(action) {
    try {
      const data = yield call(deleteAdminExpenseAsync, action.payload); // Call async function with action payload
      yield put(deleteAdminExpenseSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(deleteAdminExpenseError(error)); // Dispatch error action with error object
    }
  }
  export function* watchDeleteAdminExpenseData() {
    yield takeEvery(
      DELETE_ADMIN_EXPENSE,
      deleteAdminExpenseCall
    );
  }
  
  
export default function* rootSaga() {
    yield all([
      fork(watchGetAdminExpensesListData),
      fork(watchGetAdminExpenseByIdData),
      fork(watchPostAdminExpenseData),
      fork(watchPatchAdminExpenseData),
      fork(watchDeleteAdminExpenseData),
    ]);
  }
  