import React, { useState } from "react";
import Image from "../../../assets/images/gas-pump.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogTrigger } from "../../../ui/dialog";
import DeleteFuel from "./deleteFuel";
import { Badge } from "../../../ui/badge";
import ImagePopUpDownload from "../../imagePopUpDownload";
import { FcImageFile } from "react-icons/fc";
import { TfiTrash } from "react-icons/tfi";
import { FaRegEdit } from "react-icons/fa";

function FuelListCard({ data, reroute = false }) {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(amount)
      : "₹0.00";
  return (
    <div
      className="bg-white p-4 rounded-md shadow-lg mt-4  w-full text-xs"
      key={data?.id}
    >
      <Dialog open={showDeleteModal}>
        <DeleteFuel setShowModal={setShowDeleteModal} id={data?._id} />
      </Dialog>
      <div className=" grid xl:grid-cols-6 lg:grid-cols-4 grid-cols-2 xl:grid-rows-2 lg:grid-rows-4  lg:gap-x-20 gap-x-9  lg:gap-y-6  gap-y-4">
        <div className="lg:border-r lg:border-r-[#F1F2F6] lg:border-b-0 border-b border-b-[#F1F2F6] row-span-full flex flex-col gap-2 lg:col-span-1 col-span-2 pb-5 lg:text-left text-center  relative">
          <label htmlFor="" className="relative">
            Truck Number
          </label>
          <p
            className=" textPrimary uppercase "
            onClick={() =>
              navigate("/company/truck/details?truckId=" + data?.truckId?._id)
            }
          >
            {data?.truckId?.truckNumber}
          </p>
          <div
            className={`flex w-full md:justify-start justify-center gap-2  ${
              data.byParty ? "text-[#15803D] " : "text-[#DC1919]"
            }`}
          >
            <p className="">
              <div
                className={`px-1.5 py-0.5 rounded-full border  text-[10px] font-medium ${
                  data.byParty ? "border-[#15803D] " : "border-[#DC1919]"
                }`}
              >
                {data.byParty ? "Pay By Client" : "Pay by Company"}
              </div>
            </p>
          </div>
          {data?.billImage?.length > 0 && (
            <div className="">
              <Dialog open={showImageModal}>
                <button
                  type="button"
                  onClick={() => setShowImageModal(true)}
                  className="ghostButton font-semibold flex gap-1 items-center md:mx-0 mx-auto"
                >
                  <FcImageFile className="text-2xl" /> Image
                </button>
                <ImagePopUpDownload
                  isArray={true}
                  image={data?.billImage}
                  setShowModal={setShowImageModal}
                  imagename={`fuel_${data?._id}`}
                />
              </Dialog>
            </div>
          )}

          <div className="flex md:hidden ml-auto absolute z-30 left-0">
            <button
              type="button"
              onClick={() => setShowDeleteModal(true)}
              className="ghostButton text-[#DC1919] font-semibold  flex gap-1 hover:text-red-700 disabled:text-red-200"
            >
              <TfiTrash className="text-xl" />
            </button>
          </div>
          <div className=" flex md:hidden ml-auto absolute z-30 right-0">
            <button
              type="button"
              className="ghostButton font-semibold  flex gap-1"
              onClick={() => navigate(`/company/fuel/addFuel?id=${data?._id}`)}
            >
              <FaRegEdit className="text-xl" />
            </button>
          </div>
        </div>
        <div className="">
          <label htmlFor="">Fuel Pump Name</label>
          <p className="textSecondary">{data?.pumpName}</p>
        </div>

        <div className="">
          <label htmlFor="">Fuel Filling Date</label>
          <p className="textSecondary">
            {moment(data?.fuelFillDate).format("DD / MMM / YYYY")}
          </p>
        </div>
        <div className="">
          <label htmlFor="">Liter</label>
          <p className="textSecondary">{data?.quantity} Ltr</p>
        </div>
        <div className="">
          <label htmlFor="">Per Liter Cost</label>
          <p className="textSecondary">{formatAmount(data?.perLiterCost)}</p>
        </div>
        <div className=" gap-6 flex-wrap lg:flex hidden">
          <div className="  ">
            <button
              type="button"
              className="ghostButton font-semibold  flex gap-1"
              onClick={() => navigate(`/company/fuel/addFuel?id=${data?._id}`)}
            >
              <FaRegEdit className="text-xl" />
              Edit
            </button>
          </div>
          <div className="lg:flex hidden">
            <button
              type="button"
              onClick={() => setShowDeleteModal(true)}
              className="ghostButton text-[#DC1919] font-semibold  flex gap-1 hover:text-red-700 disabled:text-red-200"
            >
              <TfiTrash className="text-xl" /> Delete
            </button>
          </div>
        </div>

        <div className="">
          <label htmlFor="">Fuel Bill No</label>
          <p className="textSecondary">{data?.billNumber}</p>
        </div>
        <div
          className="cursor-pointer"
          onClick={() =>
            navigate(
              "/company/trip/details?tripId=" +
                data?.tripId?._id +
                "&truckId=" +
                data?.truckId?._id
            )
          }
        >
          <label htmlFor="">Trip</label>
          <p className="textSecondary">
            {data?.tripId ? data?.tripId?.tokenNumber : "--"}
          </p>
          <p className="textSecondary">
            (
            {data?.tripId &&
              data?.tripId.startLocation + "-" + data?.tripId?.endLocation}
            )
          </p>
        </div>
        <div className="">
          <label htmlFor="">Total Fuel Cost</label>
          <p className="textSecondary">{formatAmount(data?.cost)}</p>
        </div>
        <div className="col-span-2">
          <label htmlFor="">Description</label>
          <p className="textSecondary">{data?.description}</p>
        </div>
      </div>
    </div>
  );
}

export default FuelListCard;
