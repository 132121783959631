// src/components/Login.jsx
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { postRegisterAction } from "../redux/auth/action";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import useDeviceInfoAndLocation from "../auth/useDeviceAndLocation";
import PDF from "../assets/pdf/Terms and Conditions.pdf";
import { Checkbox } from "../ui/checkbox";

const SignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { postRegister, postRegisterLoading, postRegisterError } = useSelector(
    (state) => state.Authsection
  );

  const [loader, setloader] = useState(false);
  useEffect(() => {
    if (loader) {
      handler();
    }
  }, [postRegister]);
  const handler = async () => {
    if (postRegister.status === 200) {
      toast.success(postRegister.message, { id: "register-toast" });
      navigate(`/verify-otp/${postRegister.email}`);
    } else if (postRegister.status === 400) {
      toast.error(postRegister.message, { id: "register-toast" });
    } else if (postRegister.status === 500) {
      toast.error(postRegister.error, { id: "register-toast" });
    }
  };
  const dispatch = useDispatch();
  const { deviceInfo, location } = useDeviceInfoAndLocation();

  const initialValues = {
    userType: "company",
    email: "",
    password: "",
    confirmPassword: "",
    terms: false,
    deviceName: deviceInfo.deviceName,
    deviceType: deviceInfo.deviceType,
    latitude: location.latitude,
    longitude: location.longitude,
  };

  const validationSchema = Yup.object({
    userType: Yup.string().required("Required"),
    email: Yup.string().email(t("invalidEmail")).required(t("required")),
    terms: Yup.boolean().required(t("required")),
    password: Yup.string()
      .required(t("required"))
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        t("passwordValidation")
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("passwordMatch"))
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        t("passwordValidation")
      )
      .required(t("required")),
  });

  const onSubmit = (values) => {
    values.email = values.email.toLowerCase()
    const formData = {
      ...values,
      deviceName: deviceInfo.deviceName,
      deviceType: deviceInfo.deviceType,
      latitude: location.latitude,
      longitude: location.longitude,
    };
    dispatch(postRegisterAction(formData));
    setloader(true);
    toast.loading("Registering...", { id: "register-toast" });
  };

  return (
    <div className="w-full p-8 md:col-span-2 bg-gray-100 col-span-5 flex flex-col justify-between">
      <h2 className="text-2xl font-bold uppercase ">{t("registration")}</h2>
      <h2 className="text-sm text-gray-400 my-3 ">{t("slogan")}</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values }) => (
          <Form className="space-y-4 ">
            <div>
              <label htmlFor="userType" className="label">
                {t("registrationAs")} <span>*</span>
              </label>
              <Field
                as="select"
                id="userType"
                name="userType"
                disabled
                placeholder="Company/Employee"
                className="w-full px-4 py-2 border  rounded-lg focus:outline-none "
              >
                <option value="" disabled className="text-gray-400">
                  {t("registrationAs")}
                </option>
                <option value="company">{t("company")}</option>
                <option value="employee">{t("employee")}</option>
              </Field>
              <ErrorMessage name="userType" component="div" className="error" />
            </div>
            <div>
              <label htmlFor="email" className="label">
                {t("email")} <span>*</span>
              </label>
              <Field
                type="email"
                id="email"
                name="email"
                autoFocus
                placeholder={t("email")}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none "
              />
              <ErrorMessage name="email" component="div" className="error" />
            </div>
            <div>
              <label htmlFor="password" className="label">
                {t("password")} <span>*</span>
              </label>
              <Field
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
              />
              <ErrorMessage name="password" component="div" className="error" />
            </div>
            <div>
              <label htmlFor="confirmPassword" className="label">
                {t("confirmPassword")} <span>*</span>
              </label>
              <Field
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                placeholder={t("confirmPassword")}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
              />
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="error"
              />
            </div>
            <div className=" text-xs flex justify-between gap-2 mx-2">
              <div className="flex gap-2  w-1/2">

              <Checkbox
                onClick={() => setFieldValue("terms", !values?.terms)}
                checked={values?.terms}
                className="my-auto"
                />{" "}
              By clicking you agree to the
                </div>
              <a href={PDF} default="_blank" className="text-blue-500 my-auto">
                Terms & Conditions
              </a>
            </div>

            <button
              type="submit"
              disabled={postRegisterLoading || !values?.terms}
              className="primaryButton"
            >
              {t("registration")}
            </button>
          </Form>
        )}
      </Formik>
      <p className="text-center mt-4">
        {t("alreadyMember")}?{" "}
        <button
          type="button"
          onClick={() => navigate("/login")}
          className="ghostButton"
        >
          {t("login")}
        </button>
      </p>
    </div>
  );
};

export default SignUp;
