import moment from "moment";
import React from "react";

function EmiTruckDetailsCard({ data }) {
  return (
    <div
      key={data?._id}
      className="my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl"
    >
      <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between self-center">
        <div className="col-span-2 md:col-span-4 text-xl text-blue-600 font-semibold">{data?.truckNumber}</div>
        <div className="md:text-left">
          <h3 className="textgrey text-xs">Emi Company</h3>
          <p>{data?.emiCompanyName}</p>
        </div>
        <div className="md:text-left">
          <h3 className="textgrey text-xs">Emi Amount Left</h3>
          <p>{data?.emiLeft}</p>
        </div>
        <div className="md:text-left">
          <h3 className="textgrey text-xs">Emi Start Date</h3>
          <p>{moment(data?.emiStartDate).format("DD/MMM/YYYY")}</p>
        </div>
        <div className="md:text-left">
          <h3 className="textgrey text-xs">Emi Start End</h3>
          <p>{moment(data?.emiEndDate).format("DD/MMM/YYYY")}</p>
        </div>
        <div className="md:text-left">
          <h3 className="textgrey text-xs">Months Left</h3>
          <p>{moment(data?.emiEndDate).diff(moment(new Date()), "M")} Months Left</p>
        </div>
      </div>
    </div>
  );
}

export default EmiTruckDetailsCard;


