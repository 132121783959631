import api from "../../../auth/api";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  DELETE_COMPANY_TRIP,
  GET_COMPANY_TRIP_COMPLETE_DETAILS_FILTER,
  GET_COMPANY_TRIP_DETAILS,
  GET_COMPANY_TRIP_DETAILS_FILTER,
  GET_COMPANY_TRIP_DETAILS_ID,
  GET_COMPANY_TRIP_END_DETAILS_FILTER,
  GET_COMPANY_TRIP_PLANNED_DETAILS_FILTER,
  GET_COMPANY_TRIP_RUNNING_DETAILS_FILTER,
  PATCH_COMPANY_TRIP_DETAILS,
  PATCH_COMPANY_TRIP_PAYMENT,
  POST_COMPANY_TRIP_DETAILS,
} from "../../actions";
import {
  deleteCompanyTripError,
  deleteCompanyTripSuccess,
  getCompanyTripCompletedDetailsFilterError,
  getCompanyTripCompletedDetailsFilterSuccess,
  getCompanyTripDetailsError,
  getCompanyTripDetailsFilterError,
  getCompanyTripDetailsFilterSuccess,
  getCompanyTripDetailsIdError,
  getCompanyTripDetailsIdSuccess,
  getCompanyTripDetailsSuccess,
  getCompanyTripEndDetailsFilterError,
  getCompanyTripEndDetailsFilterSuccess,
  getCompanyTripPlannedDetailsFilterError,
  getCompanyTripPlannedDetailsFilterSuccess,
  getCompanyTripRunningDetailsFilterError,
  getCompanyTripRunningDetailsFilterSuccess,
  patchCompanyTripDetailsError,
  patchCompanyTripDetailsSuccess,
  patchCompanyTripPaymentError,
  patchCompanyTripPaymentSuccess,
  postCompanyTripDetailsError,
  postCompanyTripDetailsSuccess,
} from "./action";



// Async function to handle API call
const getCompanyTripDetailsAsync = async (payload) => {
  try {
    const response = await api.get(`/company/trip/getAll`+ payload);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyTripDetailsCall(action) {
  try {
    const data = yield call(getCompanyTripDetailsAsync, action.payload); // Call async function with action payload
    yield put(getCompanyTripDetailsSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyTripDetailsError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyTripDetailsData() {
  yield takeEvery(GET_COMPANY_TRIP_DETAILS, getCompanyTripDetailsCall);
}
// Async function to handle API call
const getCompanyTripDetailsIdAsync = async (payload) => {
  try {
    const response = await api.get(`/company/trip/${payload.payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw other errors to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyTripDetailsIdCall(action) {
  try {
    const data = yield call(getCompanyTripDetailsIdAsync, action); // Call async function with action payload
    yield put(getCompanyTripDetailsIdSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyTripDetailsIdError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyTripDetailsIdData() {
  yield takeEvery(GET_COMPANY_TRIP_DETAILS_ID, getCompanyTripDetailsIdCall);
}
// Async function to handle API call
const getCompanyTripDetailsFilterAsync = async (payload) => {
  try {
    const response = await api.post(`/company/trip/trips`, (payload));
    return response.data; // Return the data from the API response
  } catch (error) {
   
    throw error.response.data; // Throw other errors to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyTripDetailsFilterCall(action) {
  try {
    const data = yield call(getCompanyTripDetailsFilterAsync, action.payload); // Call async function with action payload
    yield put(getCompanyTripDetailsFilterSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyTripDetailsFilterError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyTripDetailsFilterData() {
  yield takeEvery(GET_COMPANY_TRIP_DETAILS_FILTER, getCompanyTripDetailsFilterCall);
}

// Saga function to handle action
function* getCompanyTripPlannedDetailsFilterCall(action) {
  try {
    const finalPayload = {
      ...action.payload,
      status: "Planned"
    }
    const data = yield call(getCompanyTripDetailsFilterAsync, finalPayload); // Call async function with action payload
    yield put(getCompanyTripPlannedDetailsFilterSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyTripPlannedDetailsFilterError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyTripPlannedDetailsFilterData() {
  yield takeEvery(GET_COMPANY_TRIP_PLANNED_DETAILS_FILTER, getCompanyTripPlannedDetailsFilterCall);
}

// Saga function to handle action
function* getCompanyTripRunningDetailsFilterCall(action) {
  try {
    const finalPayload = {
      ...action.payload,
      status: "Trip Started"
    }
    const data = yield call(getCompanyTripDetailsFilterAsync, finalPayload); // Call async function with action payload
    yield put(getCompanyTripRunningDetailsFilterSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyTripRunningDetailsFilterError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyTripRunningDetailsFilterData() {
  yield takeEvery(GET_COMPANY_TRIP_RUNNING_DETAILS_FILTER, getCompanyTripRunningDetailsFilterCall);
}

// Saga function to handle action
function* getCompanyTripEndDetailsFilterCall(action) {
  try {
    const finalPayload = {
      ...action.payload,
      status: "Trip Ended"
    }
    const data = yield call(getCompanyTripDetailsFilterAsync, finalPayload); // Call async function with action payload
    yield put(getCompanyTripEndDetailsFilterSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyTripEndDetailsFilterError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyTripEndDetailsFilterData() {
  yield takeEvery(GET_COMPANY_TRIP_END_DETAILS_FILTER, getCompanyTripEndDetailsFilterCall);
}

// Saga function to handle action
function* getCompanyTripCompletedDetailsFilterCall(action) {
  try {
    const finalPayload = {
      ...action.payload,
      status: "Completed"
    }
    const data = yield call(getCompanyTripDetailsFilterAsync, finalPayload); // Call async function with action payload
    yield put(getCompanyTripCompletedDetailsFilterSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyTripCompletedDetailsFilterError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyTripCompletedDetailsFilterData() {
  yield takeEvery(GET_COMPANY_TRIP_COMPLETE_DETAILS_FILTER, getCompanyTripCompletedDetailsFilterCall);
}

// Async function to handle API call
const postCompanyTripDetailsAsync = async (payload) => {
  try {
    const response = await api.post(`/company/trip/add`, payload.payload);
    return response.data; // Return the data from the API response
  } catch (error) {
console.log(error)
    throw error?.response?.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* postCompanyTripDetailsCall(action) {
  try {
    const data = yield call(postCompanyTripDetailsAsync, action); // Call async function with action payload
    yield put(postCompanyTripDetailsSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postCompanyTripDetailsError(error)); // Dispatch error action with error object
  }
}
export function* watchPostCompanyTripDetailsData() {
  yield takeEvery(POST_COMPANY_TRIP_DETAILS, postCompanyTripDetailsCall);
}
// Async function to handle API call
const patchCompanyTripDetailsAsync = async (payload) => {
  try {
    console.log(payload);
    const response = await api.patch(
      `/company/trip/update/${payload.payload.id}`,
      payload.payload.body
    );
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* patchCompanyTripDetailsCall(action) {
  try {
    const data = yield call(patchCompanyTripDetailsAsync, action); // Call async function with action payload
    yield put(patchCompanyTripDetailsSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(patchCompanyTripDetailsError(error)); // Dispatch error action with error object
  }
}
export function* watchPatchCompanyTripDetailsData() {
  yield takeEvery(PATCH_COMPANY_TRIP_DETAILS, patchCompanyTripDetailsCall);
}
// Async function to handle API call
const patchCompanyTripPaymentAsync = async (payload) => {
  try {
    const response = await api.patch(
      `/company/trip/update-payment/${payload.payload.id}`,
      payload.payload.body
    );
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* patchCompanyTripPaymentCall(action) {
  try {
    const data = yield call(patchCompanyTripPaymentAsync, action); // Call async function with action payload
    yield put(patchCompanyTripPaymentSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(patchCompanyTripPaymentError(error)); // Dispatch error action with error object
  }
}
export function* watchPatchCompanyTripPaymentData() {
  yield takeEvery(PATCH_COMPANY_TRIP_PAYMENT, patchCompanyTripPaymentCall);
}
// Async function to handle API call
const deleteCompanyTripAsync = async (payload) => {
  try {
    const response = await api.delete(
      `/company/trip/delete/${payload.payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* deleteCompanyTripCall(action) {
  try {
    const data = yield call(deleteCompanyTripAsync, action); // Call async function with action payload
    yield put(deleteCompanyTripSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(deleteCompanyTripError(error)); // Dispatch error action with error object
  }
}
export function* watchDeleteCompanyTripData() {
  yield takeEvery(DELETE_COMPANY_TRIP, deleteCompanyTripCall);
}

export default function* rootSaga() {
  yield all([

    fork(watchGetCompanyTripDetailsData),
    fork(watchGetCompanyTripDetailsIdData),
    fork(watchGetCompanyTripDetailsFilterData),
    fork(watchGetCompanyTripPlannedDetailsFilterData),
    fork(watchGetCompanyTripRunningDetailsFilterData),
    fork(watchGetCompanyTripEndDetailsFilterData),
    fork(watchGetCompanyTripCompletedDetailsFilterData),
    fork(watchPostCompanyTripDetailsData),
    fork(watchPatchCompanyTripDetailsData),
    fork(watchPatchCompanyTripPaymentData),
    fork(watchDeleteCompanyTripData),
  ]);
}
