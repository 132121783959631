import Backdrop from "../../../../components/backdrop";
import { getCompanyByCompanyIdAction } from "../../../../redux/admin/company/action";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CompanyListCard from "../compnayListCard";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../../ui/tabs";
import CompanyTabDetails from "./companyTabDetails";
import LoginDetails from "./loginDetails";
import LoginStamp from "./loginStamp";
import {
  getAdminTruckbyCompanyIdAction,
  getAdminTruckListAction,
} from "../../../../redux/admin/truck/action";
import TruckList from "./truckList";
import { getAdminEmployeeListByCompanyIdAction } from "../../../../redux/admin/employee/action";
import EmployeeList from "./employeeList";

function CompanyDetails() {
  const dispatch = useDispatch();
  const {
    getCompanyByCompanyId,
    getCompanyByCompanyIdLoading,
    getCompanyByCompanyIdError,
  } = useSelector((state) => state.AdminCompanysection);
  const {
    getAdminTruckbyCompanyId,
    getAdminTruckbyCompanyIdLoading,
    getAdminTruckbyCompanyIdError,
  } = useSelector((state) => state.AdminTruckSection);
  const {
    getAdminEmployeeListByCompanyId,
    getAdminEmployeeListByCompanyIdLoading,
    getAdminEmployeeListByCompanyIdError,
  } = useSelector((state) => state.AdminEmployeeSection);
  const { id } = useParams();
  useEffect(() => {
    dispatch(getCompanyByCompanyIdAction(id));
    dispatch(getAdminTruckbyCompanyIdAction(id));
    dispatch(getAdminEmployeeListByCompanyIdAction(id))
  }, []);
  const [data, setData] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [employees, setEmployees] = useState([]);
  useEffect(() => {
    if (getAdminTruckbyCompanyId?.status === 200) {
      const sortTruck = getAdminTruckbyCompanyId?.trucks?.sort((a, b)=> a.isSell - b.isSell)
      setTrucks(sortTruck);
    }
  }, [getAdminTruckbyCompanyId]);
  useEffect(() => {
    if (getAdminTruckbyCompanyIdError?.status === 400) {
      toast.error(getAdminTruckbyCompanyIdError?.message);
    } else if (getAdminTruckbyCompanyIdError?.status === 404) {
      toast.error(getAdminTruckbyCompanyIdError?.message);
    } else if (getAdminTruckbyCompanyIdError?.status === 500) {
      toast.error(getAdminTruckbyCompanyIdError?.error);
    }
  }, [getAdminTruckbyCompanyIdError]);
  useEffect(() => {
    if (getAdminEmployeeListByCompanyId?.status === 200) {
      const sortEmployee = getAdminEmployeeListByCompanyId?.data?.sort((a, b)=> b.isWorking - a.isWorking)
      setEmployees(sortEmployee);
    }
  }, [getAdminEmployeeListByCompanyId]);
  useEffect(() => {
    if (getAdminEmployeeListByCompanyIdError?.status === 400) {
      toast.error(getAdminEmployeeListByCompanyIdError?.message);
    } else if (getAdminEmployeeListByCompanyIdError?.status === 404) {
      toast.error(getAdminEmployeeListByCompanyIdError?.message);
    } else if (getAdminEmployeeListByCompanyIdError?.status === 500) {
      toast.error(getAdminEmployeeListByCompanyIdError?.error);
    }
  }, [getAdminEmployeeListByCompanyIdError]);
  useEffect(() => {
    if (getCompanyByCompanyId?.status === 200) {
      setData(getCompanyByCompanyId?.data);
    }
  }, [getCompanyByCompanyId]);
  useEffect(() => {
    if (getCompanyByCompanyIdError?.status === 400) {
      toast.error(getCompanyByCompanyIdError?.message);
    } else if (getCompanyByCompanyIdError?.status === 404) {
      toast.error(getCompanyByCompanyIdError?.message);
    } else if (getCompanyByCompanyIdError?.status === 500) {
      toast.error(getCompanyByCompanyIdError?.error);
    }
  }, [getCompanyByCompanyIdError]);

  return (
    <div>
      {(getCompanyByCompanyIdLoading || getAdminTruckbyCompanyIdLoading) && (
        <Backdrop />
      )}
      <CompanyListCard data={data} />
      <Tabs defaultValue="Company" color="primary" className="">
        <TabsList className="">
          <TabsTrigger value="Company">Company</TabsTrigger>
          <TabsTrigger value="Login Details">Login Details</TabsTrigger>
          <TabsTrigger value="Trucks">Trucks</TabsTrigger>
          <TabsTrigger value="Employees">Employees</TabsTrigger>
        </TabsList>
        <TabsContent value="Company" className="w-full">
          <CompanyTabDetails data={data} />
        </TabsContent>
        <TabsContent value="Login Details" className="w-full">
          <LoginStamp />
        </TabsContent>
        <TabsContent value="Trucks" className="w-full">
          <TruckList data={trucks} />
        </TabsContent>
        <TabsContent value="Employees" className="w-full">
          <EmployeeList data={employees} />
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default CompanyDetails;
