import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Layout from "../layout/layout";
import { getCookies } from "./auth";

export const ProtectedRoute = ({ userType }) => {

  const navigate = useNavigate();
  useEffect(() => {
    AuthHandler();
  }, [navigate]);

  const AuthHandler = async () => {
    if (await isAuthenticated()) {
      navigate("/login");
    } else if (await isRole()) {
      navigate(-1);
    }
  };
  const isAuthenticated = async () => {
    const token = await getCookies("token");
    return  token === undefined;
  };

  const isRole = async () => {
    const user = await getCookies("userType");
    return user !== userType;
  };
  return (
    <Layout>
      <Outlet />
     </Layout>
  );
};

