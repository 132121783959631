import Backdrop from "../../../../components/backdrop";
import { getTruckPermitByTruckIdAction } from "../../../../redux/company/truck/action";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { Dialog } from "../../../../ui/dialog";
import UpdatePermit from "../updatePermit";
import PermitListCard from "./permitListCard";

function Permit() {
  const { truckId } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const {
    getTruckPermitByTruckId,
    getTruckPermitByTruckIdLoading,
    getTruckPermitByTruckIdError,
  } = useSelector((state) => state.Companytrucksection);
  const [data, setData] = useState([]);

  useEffect(() => {
    console.log("object")
    dispatch(getTruckPermitByTruckIdAction(truckId));
  }, [truckId]);

  useEffect(() => {
    if (getTruckPermitByTruckId?.status === 200) {
      setData(getTruckPermitByTruckId?.data);
    }
  }, [getTruckPermitByTruckId]);
  useEffect(() => {
    if (getTruckPermitByTruckIdError?.status === 400) {
      toast.error(getTruckPermitByTruckIdError?.message);
    }
    if (getTruckPermitByTruckIdError?.status === 404) {
      toast.error(getTruckPermitByTruckIdError?.message);
    }
    if (getTruckPermitByTruckIdError?.status === 500) {
      toast.error(getTruckPermitByTruckIdError?.error);
    }
  }, [getTruckPermitByTruckId]);

  const [showPermitModal, setshowPermitModal] = useState(false);

  return (
    <div>
      {getTruckPermitByTruckIdLoading && <Backdrop />}
      <div className="flex justify-end gap-5">
        <Dialog open={showPermitModal}>
          <button
            type="button"
            className="primaryButton w-fit  h-full"
            onClick={() => setshowPermitModal(true)}
          >
            Add Permit
          </button>
          <UpdatePermit
            truckId={truckId}
            setShowModal={setshowPermitModal}
            truckNumber={searchParams.get("truckNumber")}
          />
        </Dialog>
      </div>
      {data.length > 0 &&
        data.map((item) => (
          <PermitListCard
            data={item}
          />
        ))}
    </div>
  );
}

export default Permit;
