import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

function EmployeeDetailsSection({ data }) {
  const sortedPreviousSalaries = data?.companyEmployees?.previousSalaries.sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
  );
  const sortedPreviousDesignation =
    data?.companyEmployees?.previousDesignation.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );
  return (
    <div className="bg-white p-4 rounded-md shadow-lg grid grid-cols-4 gap-5 w-full">
      <div className="col-span-4 text-2xl font-extrabold">Details</div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Current Company</label>
        <div className="uppercase">
          <Link
            to={`/admin/company/${data?.companyEmployees?.companyId?._id}`}
            className=""
          >
            {data?.companyEmployees?.companyId?.companyName}
          </Link>
        </div>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Name</label>
        <p className="">
          {data?.employee?.firstName} {data?.employee?.lastName}
        </p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Gender</label>
        <p className="">{data?.employee?.gender}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Date of Birth</label>
        <p className="">
          {moment(data?.employee?.dateOfBirth).format("DD/MM/YYYY")}
        </p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4">
        <label>Phone Number</label>
        <p>{data?.employee?.phoneNumber}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4">
        <label>Blood Group</label>
        <p className="">{data?.employee?.bloodGroup}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4">
        <label>Address</label>
        <p>{data?.employee?.address}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4">
        <label>City</label>
        <p className="">{data?.employee?.cityName}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4">
        <label>State</label>
        <p className="">{data?.employee?.stateName}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4">
        <label>Pin Code</label>
        <p className="">{data?.employee?.zipCode}</p>
      </div>

      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Designation</label>
        <p className="">{data?.companyEmployees?.designation?.toUpperCase()}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Joining Date</label>
        <p className="">
          {moment(data?.companyEmployees?.joiningDate).format("DD/MMM/YYYY")}
        </p>
      </div>
      {data?.leavingDate && (
        <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
          <label htmlFor="">Leaving Date</label>
          <p className="">
            {moment(data?.companyEmployees?.leavingDate).format("DD/MMM/YYYY")}
          </p>
        </div>
      )}
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Working Type</label>
        <p className="">{data?.companyEmployees?.employeeType}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Current Salary</label>
        <p className="">{data?.companyEmployees?.salary}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Current Working Status</label>
        <p className="">{data?.isWorking ? "Working" : "Leave"}</p>
      </div>
      {data?.companyEmployees !== null && (
        <div className="col-span-4 text-xl font-bold">
          Salary Increment Details
        </div>
      )}
      <div className="col-span-4 grid grid-cols-2">
        {sortedPreviousSalaries?.map((item) => (
          <>
            <div className="lg:col-span-1 col-span-2  ">
              <label htmlFor="">Salary</label>
              <p className="">{item?.salary}</p>
            </div>
            <div className="lg:col-span-1 col-span-2  ">
              <label htmlFor="">Salary Update Date</label>
              <p className="">
                {moment(item?.updatedAt).format("DD/MMM/YYYY")}
              </p>
            </div>
          </>
        ))}
      </div>
      {data?.companyEmployees !== null && (
        <div className="col-span-4 text-xl font-bold">Designation Details</div>
      )}
      <div className="col-span-4 grid grid-cols-2">
        {sortedPreviousDesignation?.map((item) => (
          <>
            <div className="lg:col-span-1 col-span-2  ">
              <label htmlFor="">Salary</label>
              <p className="">{item?.designation}</p>
            </div>
            <div className="lg:col-span-1 col-span-2  ">
              <label htmlFor="">Salary Update Date</label>
              <p className="">
                {moment(item?.updatedAt).format("DD/MMM/YYYY")}
              </p>
            </div>
          </>
        ))}
      </div>
    </div>
  );
}

export default EmployeeDetailsSection;
