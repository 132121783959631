import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import api from "../../../auth/api";
import {
  GET_ADMIN_DASHBOARD_COMPANY_FILTER,
  GET_ADMIN_DASHBOARD_COMPANY_GRAPH,
  GET_ADMIN_DASHBOARD_EMPLOYEE_FILTER,
  GET_ADMIN_DASHBOARD_EMPLOYEE_GRAPH,
  GET_ADMIN_DASHBOARD_EXPENSES_FILTER,
  GET_ADMIN_DASHBOARD_EXPENSES_GRAPH,
  GET_ADMIN_DASHBOARD_PROFIT_LOSS,
  GET_ADMIN_DASHBOARD_SUBSCRIPTION_GRAPH,
  GET_ADMIN_DASHBOARD_SUBSCRIPTION_PF,
  GET_ADMIN_DASHBOARD_TRUCK_GRAPH,
} from "../../actions";
import {
  getAdminDasboardCompanyFilterError,
  getAdminDasboardCompanyFilterSuccess,
  getAdminDasboardCompanyGraphError,
  getAdminDasboardCompanyGraphSuccess,
  getAdminDashboardEmployeeFilterError,
  getAdminDashboardEmployeeFilterSuccess,
  getAdminDashboardEmployeeGraphError,
  getAdminDashboardEmployeeGraphSuccess,
  getAdminDashboardExpensesFilterError,
  getAdminDashboardExpensesFilterSuccess,
  getAdminDashboardExpensesGraphError,
  getAdminDashboardExpensesGraphSuccess,
  getAdminDashboardProfiltLossError,
  getAdminDashboardProfiltLossSuccess,
  getAdminDashboardSubscriptionGraphError,
  getAdminDashboardSubscriptionGraphSuccess,
  getAdminDashboardSubscriptionGraphPFError,
  getAdminDashboardSubscriptionGraphPFSuccess,
  getAdminDashboardTruckGraphError,
  getAdminDashboardTruckGraphSuccess,
} from "./action";

// Async function to handle API call
const getAdminDashboardSubscriptionGraphPFAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/dashboard/subscriptions/graph-data?${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getAdminDashboardSubscriptionGraphPFCall(action) {
  try {
    const data = yield call(getAdminDashboardSubscriptionGraphPFAsync, action.payload); // Call async function with action payload
    yield put(getAdminDashboardSubscriptionGraphPFSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getAdminDashboardSubscriptionGraphPFError(error)); // Dispatch error action with error object
  }
}
export function* watchGetAdminDashboardTruckFilterData() {
  yield takeEvery(
    GET_ADMIN_DASHBOARD_SUBSCRIPTION_PF,
    getAdminDashboardSubscriptionGraphPFCall
  );
}

// Async function to handle API call
const getAdminDashboardTruckGraphAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/dashboard/trucks/graph-data?${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getAdminDashboardTruckGraphCall(action) {
  try {
    const data = yield call(getAdminDashboardTruckGraphAsync, action.payload); // Call async function with action payload
    yield put(getAdminDashboardTruckGraphSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getAdminDashboardTruckGraphError(error)); // Dispatch error action with error object
  }
}
export function* watchGetAdminDashboardTruckGraphData() {
  yield takeEvery(
    GET_ADMIN_DASHBOARD_TRUCK_GRAPH,
    getAdminDashboardTruckGraphCall
  );
}

// Async function to handle API call
const getAdminDasboardCompanyFilterAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/dashboard/companies/graph-data/states?${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getAdminDasboardCompanyFilterCall(action) {
  try {
    const data = yield call(getAdminDasboardCompanyFilterAsync, action.payload); // Call async function with action payload
    yield put(getAdminDasboardCompanyFilterSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getAdminDasboardCompanyFilterError(error)); // Dispatch error action with error object
  }
}
export function* watchGetAdminDasboardCompanyFilterData() {
  yield takeEvery(
    GET_ADMIN_DASHBOARD_COMPANY_FILTER,
    getAdminDasboardCompanyFilterCall
  );
}

// Async function to handle API call
const getAdminDasboardCompanyGraphAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/dashboard/companies/graph-data?${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getAdminDasboardCompanyGraphCall(action) {
  try {
    const data = yield call(getAdminDasboardCompanyGraphAsync, action.payload); // Call async function with action payload
    yield put(getAdminDasboardCompanyGraphSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getAdminDasboardCompanyGraphError(error)); // Dispatch error action with error object
  }
}
export function* watchGetAdminDasboardCompanyGraphData() {
  yield takeEvery(
    GET_ADMIN_DASHBOARD_COMPANY_GRAPH,
    getAdminDasboardCompanyGraphCall
  );
}

// Async function to handle API call
const getAdminDashboardEmployeeFilterAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/dashboard/employees/filter?${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getAdminDashboardEmployeeFilterCall(action) {
  try {
    const data = yield call(getAdminDashboardEmployeeFilterAsync, action.payload); // Call async function with action payload
    yield put(getAdminDashboardEmployeeFilterSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getAdminDashboardEmployeeFilterError(error)); // Dispatch error action with error object
  }
}
export function* watchGetAdminDashboardEmployeeFilterData() {
  yield takeEvery(
    GET_ADMIN_DASHBOARD_EMPLOYEE_FILTER,
    getAdminDashboardEmployeeFilterCall
  );
}

// Async function to handle API call
const getAdminDashboardEmployeeGraphAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/dashboard/employees/graph-data?${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getAdminDashboardEmployeeGraphCall(action) {
  try {
    const data = yield call(getAdminDashboardEmployeeGraphAsync, action.payload); // Call async function with action payload
    yield put(getAdminDashboardEmployeeGraphSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getAdminDashboardEmployeeGraphError(error)); // Dispatch error action with error object
  }
}
export function* watchGetAdminDashboardEmployeeGraphData() {
  yield takeEvery(
    GET_ADMIN_DASHBOARD_EMPLOYEE_GRAPH,
    getAdminDashboardEmployeeGraphCall
  );
}

// Async function to handle API call
const getAdminDashboardExpensesFilterAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/dashboard/expenses/cost-summary?${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getAdminDashboardExpensesFilterCall(action) {
  try {
    const data = yield call(getAdminDashboardExpensesFilterAsync, action.payload); // Call async function with action payload
    yield put(getAdminDashboardExpensesFilterSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getAdminDashboardExpensesFilterError(error)); // Dispatch error action with error object
  }
}
export function* watchGetAdminDashboardExpensesFilterData() {
  yield takeEvery(
    GET_ADMIN_DASHBOARD_EXPENSES_FILTER,
    getAdminDashboardExpensesFilterCall
  );
}

// Async function to handle API call
const getAdminDashboardExpensesGraphAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/dashboard/expenses/graph?${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getAdminDashboardExpensesGraphCall(action) {
  try {
    const data = yield call(getAdminDashboardExpensesGraphAsync, action.payload); // Call async function with action payload
    yield put(getAdminDashboardExpensesGraphSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getAdminDashboardExpensesGraphError(error)); // Dispatch error action with error object
  }
}
export function* watchGetAdminDashboardExpensesGraphData() {
  yield takeEvery(
    GET_ADMIN_DASHBOARD_EXPENSES_GRAPH,
    getAdminDashboardExpensesGraphCall
  );
}

// Async function to handle API call
const getAdminDashboardProfiltLossAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/dashboard/profit-loss?${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getAdminDashboardProfiltLossCall(action) {
  try {
    const data = yield call(getAdminDashboardProfiltLossAsync, action.payload); // Call async function with action payload
    yield put(getAdminDashboardProfiltLossSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getAdminDashboardProfiltLossError(error)); // Dispatch error action with error object
  }
}
export function* watchGetAdminDashboardProfiltLossData() {
  yield takeEvery(
    GET_ADMIN_DASHBOARD_PROFIT_LOSS,
    getAdminDashboardProfiltLossCall
  );
}

// Async function to handle API call
const getAdminDashboardSubscriptionGraphAsync = async (payload) => {
  try {
    const response = await api.get(`/admin/dashboard/subscriptions/graph-data?${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getAdminDashboardSubscriptionGraphCall(action) {
  try {
    const data = yield call(getAdminDashboardSubscriptionGraphAsync, action.payload); // Call async function with action payload
    yield put(getAdminDashboardSubscriptionGraphSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getAdminDashboardSubscriptionGraphError(error)); // Dispatch error action with error object
  }
}
export function* watchGetAdminDashboardSubscriptionGraphData() {
  yield takeEvery(
    GET_ADMIN_DASHBOARD_SUBSCRIPTION_GRAPH,
    getAdminDashboardSubscriptionGraphCall
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAdminDashboardTruckFilterData),
    fork(watchGetAdminDashboardTruckGraphData),
    fork(watchGetAdminDasboardCompanyFilterData),
    fork(watchGetAdminDasboardCompanyGraphData),
    fork(watchGetAdminDashboardEmployeeFilterData),
    fork(watchGetAdminDashboardEmployeeGraphData),
    fork(watchGetAdminDashboardExpensesFilterData),
    fork(watchGetAdminDashboardExpensesGraphData),
    fork(watchGetAdminDashboardProfiltLossData),
    fork(watchGetAdminDashboardSubscriptionGraphData),
  ]);
}
