import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Chart } from "react-google-charts";
import { getCompanyDashboardExpensePiechartAction } from "../../../redux/company/dashboard/action";

function ExpensePieChart() {
    const dispatch = useDispatch();
    const {
      getCompanyDashboardExpensePiechart,
    } = useSelector((state) => state.CompanyDashboardSection);

    const [year, setYear] = useState("2024");
    const [data, setData] = useState([
      ["Category", "Amount"], // Initialize with headers
    ]);
  
    // Fetch and set data when component mounts or year/truck changes
    useEffect(() => {
      const loadTruckData = () => {
        const queryParams = {
          startDate: "",
          endDate: "",
          year: year,
        };
  
        const queryString = Object.entries(queryParams)
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join("&");
  
        dispatch(getCompanyDashboardExpensePiechartAction(queryString));
      };
  
      loadTruckData();
    }, [dispatch, year]);
  
    // Update chart data when API response changes
    useEffect(() => {
      if (getCompanyDashboardExpensePiechart?.status === 200) {
        const chartData = [
          ["Category", "Amount"],
          ...getCompanyDashboardExpensePiechart?.data?.map((item) => [
            item.category,
            item.total,
          ]),
        ];
        setData(chartData.length > 1 ? chartData : [["Category", "Amount"], ["No Data", 1]]); // Add fallback when no data
      }
    }, [getCompanyDashboardExpensePiechart]);
  
    // Google Charts options
    const options = {
      is3D: false,
      pieHole: 0.4,
      legend: {
        position: "top",
        // alignment: "center",
        textStyle: {
          color: "#233238",
          fontSize: 10,
        },
  }
    };
  return (
    <div className="bg-white p-4 h-full xl:order-4 order-2 xl:col-span-1 lg:col-span-1 col-span-2">
    <div className="flex justify-between">
      <div className="text-3xl font-bold mb-4">Expenses</div>
      <div className="flex gap-4">
        <select
          value={year}
          className="border border-gray-200  h-fit text-xs min-h-fit"
          onChange={(e) => setYear(e.target.value)}
        >
          <option value="">All</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
          <option value="2026">2026</option>
        </select>
      </div>
    </div>

    <Chart
      chartType="PieChart"
      width="100%"
      height={300}
      data={data}
      options={options}
    />
  </div>
  )
}

export default ExpensePieChart
