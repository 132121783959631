import { getCityAction, getStateAction } from "../../../redux/address/action";
import { getClientIdAction } from "../../../redux/company/client/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import ClientDetailCard from "./clientDetailCard";
import Backdrop from "../../backdrop";
import TripList from "../trip/tripList";
import ClientListCard from "./clientListCard";

function ClientDetails() {
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const { getClientId, getClientIdLoading, getClientIdError } = useSelector(
    (state) => state.Clientsection
  );
  const { getState, getStateLoading, getCity, getCityLoading } = useSelector(
    (state) => state.AddressSection
  );
  const {
    getCompanyTripDetailsFilter,
    getCompanyTripDetailsFilterLoading,
    getCompanyTripDetailsFilterError,
  } = useSelector((state) => state.CompanyTripsection);
  const [data, setData] = useState();
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [tripData, setTripData] = useState([]);

  useEffect(() => {
    const id = searchParams.get("clientId");
    if (id !== null) {
      dispatch(getClientIdAction(id));
    }
  }, [searchParams]);

  useEffect(() => {
    const edit = searchParams.get("clientId") === null;

    if (!edit) {
      if (getClientId?.status === 200) {
        setData(getClientId?.data);
        dispatch(getStateAction());
        handleCity(getClientId?.data?.state);
      }
      if (getClientIdError === 400) {
        toast.error(getClientIdError?.message);
      } else if (getClientIdError === 404) {
        toast.error(getClientIdError.message);
      } else if (getClientIdError === 500) {
        toast.error(getClientIdError.error);
      }
    }
  }, [getClientId, getClientIdError]);
  useEffect(() => {
    if (getState?.status === 200) {
      const stateName = getState?.data?.filter(
        (item) => item.id == getClientId?.data?.state
      );
      if (stateName?.length > 0) {
        setState(stateName[0].name);
      }
    }
  }, [getState]);
  useEffect(() => {
    if (getCity?.status === 200) {
      const cityName = getCity?.data?.filter(
        (item) => item.id == getClientId?.data?.city
      );
      if (cityName?.length > 0) {
        setCity(cityName[0].name);
      }
    }
  }, [getCity]);
  useEffect(() => {
    if (getCompanyTripDetailsFilter?.status === 200) {
      setTripData(getCompanyTripDetailsFilter);
    }
  }, [getCompanyTripDetailsFilter]);
  useEffect(() => {
    if (getCompanyTripDetailsFilterError?.status === 404) {
      toast.error(getCompanyTripDetailsFilterError.message, {
        id: "truck-toast",
      });
    } else if (getCompanyTripDetailsFilterError?.status === 400) {
      toast.error(getCompanyTripDetailsFilterError.message, {
        id: "truck-toast",
      });
    } else if (getCompanyTripDetailsFilterError?.status === 500) {
      toast.error(getCompanyTripDetailsFilterError.error, {
        id: "truck-toast",
      });
    }
  }, [getCompanyTripDetailsFilterError]);

  const handleCity = (id) => {
    dispatch(getCityAction(id));
  };

  return (
    <div>
      {getClientIdLoading ||
        getStateLoading ||
        (getCityLoading && <Backdrop />)}
      {data && <ClientListCard data={data} state={state} city={city} />}
      <div className="flex flex-col gap-10 mt-10 ">
        <div className="text-3xl font-bold uppercase">Trips</div>
        <div className="flex justify-end">
          <Link
            to={`/company/trip/addTrip?clientId=${data?._id}`}
            className="primaryButton w-fit"
          >
            Add Trip
          </Link>
        </div>
        <TripList data={tripData} />
      </div>
    </div>
  );
}

export default ClientDetails;
