// src/components/LanguageSwitcher.jsx
import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const changeLanguagehandler = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div className="language-switcher">
      <select
        name="language"
        className="w-fit bg-white text-black"
        id="language-select"
        value={i18n.language}
        onChange={changeLanguagehandler}
      >
        <option value="en">English</option>
        {/* <option value="hi">Hindi</option> */}
      </select>
    </div>
  );
};

export default LanguageSwitcher;
