import { Checkbox } from "../../../ui/checkbox";
import { getAdminDashboardSubscriptionGraphAction } from "../../../redux/admin/dashboard/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { getCompanyListAction } from "../../../redux/admin/company/action";

function SubscriptionCard() {
  const dispatch = useDispatch();
  const {
    getAdminDashboardSubscriptionGraph,
    getAdminDashboardSubscriptionGraphLoading,
    getAdminDashboardSubscriptionGraphError,
  } = useSelector((state) => state.AdminDashboardSection);
  const {
    getCompanyList,
    getCompanyListLoading,
    getCompanyListError,
  } = useSelector((state) => state.AdminCompanysection);
  // Local state to store filter values
  const [companyList, setCompanyList] = useState([]);
  const [company, setCompany] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [year, setYear] = useState("2024");
  const [data, setData] = useState({});

  useEffect(() => {
   dispatch(getCompanyListAction())
  }, [])
  

  useEffect(() => {
    if (getAdminDashboardSubscriptionGraph?.status === 200) {
      setData(getAdminDashboardSubscriptionGraph.data);
    }
  }, [getAdminDashboardSubscriptionGraph]);

  useEffect(() => {
    if (getCompanyList?.status === 200) {
      setCompanyList(getCompanyList.data);
    }
  }, [getCompanyList]);
console.log(getAdminDashboardSubscriptionGraph)
  // Load truck data when component mounts or state changes
  useEffect(() => {
    initialLoad(startDate, endDate, year, company);
  }, [startDate, endDate, year, company]); // Add dependencies to re-run on changes

  const initialLoad = (startDateValue, endDateValue, yearValue, companyValue) => {
    const queryParams = {
      startDate: startDateValue,
      endDate: endDateValue,
      year: yearValue,
      companyId: companyValue
    };

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    // Dispatch action to get truck count
    dispatch(getAdminDashboardSubscriptionGraphAction(queryString));
  };

  const chartData = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: data?.graph?.map((item) => item.period),
      },
      stroke: {
        curve: "smooth",
      },
      colors: ["#2E93fA", "#66DA26", "#546E7A", "#E91E63", "#FF9800"],
    },

    series: [
      {
        name: "Total Cost",
        data: data?.graph?.map((item) => item.totalCost),
      },
    ],
  };

  return (
    <div className="bg-white p-4 h-full col-span-2">
      <div className="flex justify-between">
        <div className="text-4xl font-bold mb-4">Total Revenue</div>
        <div className="flex gap-4">
          <div>
            {/* Pass handleCheckboxChange as a function reference */}
            <select
              name=""
              id=""
              value={company}
              className="border border-gray-200 "
              onChange={(e) => setCompany(e.target.value)}
            >
              <option value="">All</option>
            {companyList?.map((item)=>
              <option value={item._id}>{item.companyName}</option>
            )}
            </select>
          </div>
          <div>
            {/* Pass handleCheckboxChange as a function reference */}
            <select
              name=""
              id=""
              value={year}
              className="border border-gray-200 "
              onChange={(e) => setYear(e.target.value)}
            >
              <option value="">All</option>
              <option value="2024">2024</option>
              <option value="2023">2023</option>
              <option value="2022">2022</option>
            </select>
          </div>
        </div>
      </div>
      <div className="text-3xl font-bold mb-4">
        {data.count}
        <label>INR</label>
      </div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={200}

        // width="500"
      />
    </div>
  );
}

export default SubscriptionCard;
