import {
  GET_COMPANY_DASHBOARD_EMPLOYEE_GRAPH,
  GET_COMPANY_DASHBOARD_EMPLOYEE_GRAPH_ERROR,
  GET_COMPANY_DASHBOARD_EMPLOYEE_GRAPH_SUCCESS,
  GET_COMPANY_DASHBOARD_EXPENSE_GRAPH,
  GET_COMPANY_DASHBOARD_EXPENSE_GRAPH_ERROR,
  GET_COMPANY_DASHBOARD_EXPENSE_GRAPH_SUCCESS,
  GET_COMPANY_DASHBOARD_EXPENSE_PIECHART,
  GET_COMPANY_DASHBOARD_EXPENSE_PIECHART_ERROR,
  GET_COMPANY_DASHBOARD_EXPENSE_PIECHART_SUCCESS,
  GET_COMPANY_DASHBOARD_TRIP_GRAPH,
  GET_COMPANY_DASHBOARD_TRIP_GRAPH_ERROR,
  GET_COMPANY_DASHBOARD_TRIP_GRAPH_SUCCESS,
  GET_COMPANY_DASHBOARD_TRIP_LIST,
  GET_COMPANY_DASHBOARD_TRIP_LIST_ERROR,
  GET_COMPANY_DASHBOARD_TRIP_LIST_SUCCESS,
  GET_COMPANY_DASHBOARD_TRIP_PENDING_AMOUNT,
  GET_COMPANY_DASHBOARD_TRIP_PENDING_AMOUNT_ERROR,
  GET_COMPANY_DASHBOARD_TRIP_PENDING_AMOUNT_SUCCESS,
  GET_COMPANY_DASHBOARD_TRIP_PIECHART,
  GET_COMPANY_DASHBOARD_TRIP_PIECHART_ERROR,
  GET_COMPANY_DASHBOARD_TRIP_PIECHART_SUCCESS,
  GET_COMPANY_DASHBOARD_TRUCK_GRAPH,
  GET_COMPANY_DASHBOARD_TRUCK_GRAPH_ERROR,
  GET_COMPANY_DASHBOARD_TRUCK_GRAPH_SUCCESS,
  GET_COMPANY_DASHBOARD_TRUCK_PIECHART,
  GET_COMPANY_DASHBOARD_TRUCK_PIECHART_ERROR,
  GET_COMPANY_DASHBOARD_TRUCK_PIECHART_SUCCESS,
} from "../../actions";

const INIT_STATE = {
  getCompanyDashboardTruckGraph: {},
  getCompanyDashboardTruckGraphLoading: false,
  getCompanyDashboardTruckGraphError: "",
  getCompanyDashboardTruckPiechart: {},
  getCompanyDashboardTruckPiechartLoading: false,
  getCompanyDashboardTruckPiechartError: "",
  getCompanyDashboardTripGraph: {},
  getCompanyDashboardTripGraphLoading: false,
  getCompanyDashboardTripGraphError: "",
  getCompanyDashboardTripList: {},
  getCompanyDashboardTripListLoading: false,
  getCompanyDashboardTripListError: "",
  getCompanyDashboardTripPiechart: {},
  getCompanyDashboardTripPiechartLoading: false,
  getCompanyDashboardTripPiechartError: "",
  getCompanyDashboardTripPendingAmount: {},
  getCompanyDashboardTripPendingAmountLoading: false,
  getCompanyDashboardTripPendingAmountError: "",
  getCompanyDashboardEmployeeGraph: {},
  getCompanyDashboardEmployeeGraphLoading: false,
  getCompanyDashboardEmployeeGraphError: "",
  getCompanyDashboardExpenseGraph: {},
  getCompanyDashboardExpenseGraphLoading: false,
  getCompanyDashboardExpenseGraphError: "",
  getCompanyDashboardExpensePiechart: {},
  getCompanyDashboardExpensePiechartLoading: false,
  getCompanyDashboardExpensePiechartError: "",
};

const CompanyDashboardSection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_DASHBOARD_TRUCK_GRAPH:
      return {
        ...state,
        getCompanyDashboardTruckGraph: {},
        getCompanyDashboardTruckGraphLoading: true,
      };
    case GET_COMPANY_DASHBOARD_TRUCK_GRAPH_SUCCESS:
      return {
        ...state,
        getCompanyDashboardTruckGraph: action.payload,
        getCompanyDashboardTruckGraphLoading: false,
        getCompanyDashboardTruckGraphError: "",
      };

    case GET_COMPANY_DASHBOARD_TRUCK_GRAPH_ERROR:
      return {
        ...state,
        getCompanyDashboardTruckGraphLoading: false,
        getCompanyDashboardTruckGraphError: action.payload,
      };
    case GET_COMPANY_DASHBOARD_TRUCK_PIECHART:
      return {
        ...state,
        getCompanyDashboardTruckPiechart: {},
        getCompanyDashboardTruckPiechartLoading: true,
      };
    case GET_COMPANY_DASHBOARD_TRUCK_PIECHART_SUCCESS:
      return {
        ...state,
        getCompanyDashboardTruckPiechart: action.payload,
        getCompanyDashboardTruckPiechartLoading: false,
        getCompanyDashboardTruckPiechartError: "",
      };

    case GET_COMPANY_DASHBOARD_TRUCK_PIECHART_ERROR:
      return {
        ...state,
        getCompanyDashboardTruckPiechartLoading: false,
        getCompanyDashboardTruckPiechartError: action.payload,
      };
    case GET_COMPANY_DASHBOARD_TRIP_GRAPH:
      return {
        ...state,
        getCompanyDashboardTripGraph: {},
        getCompanyDashboardTripGraphLoading: true,
      };
    case GET_COMPANY_DASHBOARD_TRIP_GRAPH_SUCCESS:
      return {
        ...state,
        getCompanyDashboardTripGraph: action.payload,
        getCompanyDashboardTripGraphLoading: false,
        getCompanyDashboardTripGraphError: "",
      };

    case GET_COMPANY_DASHBOARD_TRIP_GRAPH_ERROR:
      return {
        ...state,
        getCompanyDashboardTripGraphLoading: false,
        getCompanyDashboardTripGraphError: action.payload,
      };
    case GET_COMPANY_DASHBOARD_TRIP_LIST:
      return {
        ...state,
        getCompanyDashboardTripList: {},
        getCompanyDashboardTripListLoading: true,
      };
    case GET_COMPANY_DASHBOARD_TRIP_LIST_SUCCESS:
      return {
        ...state,
        getCompanyDashboardTripList: action.payload,
        getCompanyDashboardTripListLoading: false,
        getCompanyDashboardTripListError: "",
      };

    case GET_COMPANY_DASHBOARD_TRIP_LIST_ERROR:
      return {
        ...state,
        getCompanyDashboardTripListLoading: false,
        getCompanyDashboardTripListError: action.payload,
      };
    case GET_COMPANY_DASHBOARD_TRIP_PIECHART:
      return {
        ...state,
        getCompanyDashboardTripPiechart: {},
        getCompanyDashboardTripPiechartLoading: true,
      };
    case GET_COMPANY_DASHBOARD_TRIP_PIECHART_SUCCESS:
      return {
        ...state,
        getCompanyDashboardTripPiechart: action.payload,
        getCompanyDashboardTripPiechartLoading: false,
        getCompanyDashboardTripPiechartError: "",
      };

    case GET_COMPANY_DASHBOARD_TRIP_PIECHART_ERROR:
      return {
        ...state,
        getCompanyDashboardTripPiechartLoading: false,
        getCompanyDashboardTripPiechartError: action.payload,
      };
    case GET_COMPANY_DASHBOARD_TRIP_PENDING_AMOUNT:
      return {
        ...state,
        getCompanyDashboardTripPendingAmount: {},
        getCompanyDashboardTripPendingAmountLoading: true,
      };
    case GET_COMPANY_DASHBOARD_TRIP_PENDING_AMOUNT_SUCCESS:
      return {
        ...state,
        getCompanyDashboardTripPendingAmount: action.payload,
        getCompanyDashboardTripPendingAmountLoading: false,
        getCompanyDashboardTripPendingAmountError: "",
      };

    case GET_COMPANY_DASHBOARD_TRIP_PENDING_AMOUNT_ERROR:
      return {
        ...state,
        getCompanyDashboardTripPendingAmountLoading: false,
        getCompanyDashboardTripPendingAmountError: action.payload,
      };
    case GET_COMPANY_DASHBOARD_EMPLOYEE_GRAPH:
      return {
        ...state,
        getCompanyDashboardEmployeeGraph: {},
        getCompanyDashboardEmployeeGraphLoading: true,
      };
    case GET_COMPANY_DASHBOARD_EMPLOYEE_GRAPH_SUCCESS:
      return {
        ...state,
        getCompanyDashboardEmployeeGraph: action.payload,
        getCompanyDashboardEmployeeGraphLoading: false,
        getCompanyDashboardEmployeeGraphError: "",
      };

    case GET_COMPANY_DASHBOARD_EMPLOYEE_GRAPH_ERROR:
      return {
        ...state,
        getCompanyDashboardEmployeeGraphLoading: false,
        getCompanyDashboardEmployeeGraphError: action.payload,
      };
    case GET_COMPANY_DASHBOARD_EXPENSE_GRAPH:
      return {
        ...state,
        getCompanyDashboardExpenseGraph: {},
        getCompanyDashboardExpenseGraphLoading: true,
      };
    case GET_COMPANY_DASHBOARD_EXPENSE_GRAPH_SUCCESS:
      return {
        ...state,
        getCompanyDashboardExpenseGraph: action.payload,
        getCompanyDashboardExpenseGraphLoading: false,
        getCompanyDashboardExpenseGraphError: "",
      };

    case GET_COMPANY_DASHBOARD_EXPENSE_GRAPH_ERROR:
      return {
        ...state,
        getCompanyDashboardExpenseGraphLoading: false,
        getCompanyDashboardExpenseGraphError: action.payload,
      };
    case GET_COMPANY_DASHBOARD_EXPENSE_PIECHART:
      return {
        ...state,
        getCompanyDashboardExpensePiechart: {},
        getCompanyDashboardExpensePiechartLoading: true,
      };
    case GET_COMPANY_DASHBOARD_EXPENSE_PIECHART_SUCCESS:
      return {
        ...state,
        getCompanyDashboardExpensePiechart: action.payload,
        getCompanyDashboardExpensePiechartLoading: false,
        getCompanyDashboardExpensePiechartError: "",
      };

    case GET_COMPANY_DASHBOARD_EXPENSE_PIECHART_ERROR:
      return {
        ...state,
        getCompanyDashboardExpensePiechartLoading: false,
        getCompanyDashboardExpensePiechartError: action.payload,
      };
    default:
      return { ...state };
  }
};

export default CompanyDashboardSection;
