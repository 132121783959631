import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import DatePicker from "../../datepicker/datePicker";
import FileMultiUpload from "../../fileupload/fileMuiltipleUpload";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { getTruckFitnessByTruckIdAction,  getTruckFitnessAction,
  patchTruckFitnessAction, } from "../../../redux/company/truck/action";

function UpdateFitness({ truckId, setShowModal, truckNumber }) {
  const dispatch = useDispatch();
  const {
    patchTruckFitness,
    patchTruckFitnessLoading,
    patchTruckFitnessError,
  } = useSelector((state) => state.Companytrucksection);

  const [loader, setLoader] = useState(false);
  const initialValues = {
    fitnessNumber: "",
    fitnessCost: null,
    fitnessImage: [],
    fitnessTill: "",
  };

  const validationSchema = Yup.object({
    fitnessNumber: Yup.string().required("This is required"),
    fitnessCost: Yup.number().required("This is required"),
    fitnessTill: Yup.date().required("This is required"),
  });

  useEffect(() => {
    if (loader) {
      if (patchTruckFitness?.status === 200) {
        toast.success(patchTruckFitness?.data, { id: "fitness_toast" });
        setLoader(false);
        dispatch(getTruckFitnessAction());
        dispatch(getTruckFitnessByTruckIdAction(truckId));
        setShowModal(false);
      }
    }
  }, [patchTruckFitness]);
  useEffect(() => {
    if (loader) {
      if (patchTruckFitnessError?.status === 400) {
        toast.error(patchTruckFitnessError?.data, { id: "fitness_toast" });
        setLoader(false);
      }
      if (patchTruckFitnessError?.status === 404) {
        toast.error(patchTruckFitnessError?.data, { id: "fitness_toast" });
        setLoader(false);
      }
      if (patchTruckFitnessError?.status === 500) {
        toast.error(patchTruckFitnessError?.error, { id: "fitness_toast" });
        setLoader(false);
      }
    }
  }, [patchTruckFitnessError]);

  const onSubmit = async (values) => {
    console.log(values);
    const payload = {
      id: truckId,
      body: values,
    };
    dispatch(patchTruckFitnessAction(payload));
    toast.loading("Saving....", { id: "fitness_toast" });
    setLoader(true);
  };
  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle className="flex gap-1 ">
          Update Fitness of{" "}
          <div className="uppercase font-black underline">{truckNumber}</div>
        </DialogTitle>
      </DialogHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, isSubmitting, errors }) => (
          <Form>
            <div className="grid grid-cols-2 gap-4 overflow-y-auto max-h-[60vh] py-4 ">
              <div className="">
                <label htmlFor="fitnessNumber">Fitness Policy Number</label>
                <div className="w-full">
                  <Field
                    id="fitnessNumber"
                    type="text"
                    name="fitnessNumber"
                    className=""
                    placeHolder="Fitness Policy Number"
                  />
                  <ErrorMessage
                    name="fitnessNumber"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="">
                <label htmlFor="fitnessTill">Fitness End Date</label>
                <div className="w-full">
                  <DatePicker
                    id="fitnessTill"
                    name="fitnessTill"
                    className=""
                    placeHolder="Fitness End Date"
                  />
                  <ErrorMessage
                    name="fitnessTill"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="">
                <label htmlFor="fitnessCost">Fitness Cost</label>
                <div className="w-full">
                  <Field
                    id="fitnessCost"
                    type="number"
                    name="fitnessCost"
                    className=""
                    placeHolder="Fitness Cost"
                  />
                  <ErrorMessage
                    name="fitnessCost"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className=" col-span-2">
                <label
                  htmlFor="fitnessImage"
                  className="text-black text-sm font-bold"
                >
                  Fitness Image
                </label>
                <FileMultiUpload
                  setFieldValue={setFieldValue}
                  values={values.fitnessImage}
                  name="fitnessImage" // disabled={readOnly} values={values}
                />
              </div>
            </div>
            <DialogFooter className="md:gap-5 col-span-2">
              <button
                className="ghostButton md:w-fit capitalize"
                type="button"
                onClick={() => setShowModal(false)}
                disabled={patchTruckFitnessLoading}
              >
                Close
              </button>

              <button
                type="submit"
                className="primaryButton md:w-fit capitalize"
                disabled={patchTruckFitnessLoading}
              >
                Save
              </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default UpdateFitness;
