import Backdrop from "../../../../components/backdrop";
import Pagination from "../../../../components/pagination";
import { getCompanyLoginStampAction } from "../../../../redux/admin/company/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import LoginDetails from "./loginDetails";

function LoginStamp() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    getCompanyLoginStamp,
    getCompanyLoginStampLoading,
    getCompanyLoginStampError,
  } = useSelector((state) => state.AdminCompanysection);
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  useEffect(() => {
    handleGetData(pageNo, perPage);
  }, [pageNo, perPage]);
  useEffect(() => {
    if (getCompanyLoginStamp?.status === 200) {
      setTotalPages(getCompanyLoginStamp?.pagination?.totalPages);
      setTotal(getCompanyLoginStamp?.pagination?.total);
      setPageNo(getCompanyLoginStamp?.pagination?.page);
      setData(getCompanyLoginStamp?.data);
    }
  }, [getCompanyLoginStamp]);
  useEffect(() => {
    if (getCompanyLoginStampError?.status === 400) {
      toast.error(getCompanyLoginStampError?.message);
    } else if (getCompanyLoginStampError?.status === 404) {
      toast.error(getCompanyLoginStampError?.message);
    } else if (getCompanyLoginStampError?.status === 500) {
      toast.error(getCompanyLoginStampError?.error);
    }
  }, [getCompanyLoginStampError]);
  const handleGetData = (pageNo, perPage) => {
    // &startDate={filter}&endDate=2024-07-31
    const payload = `${id}?page=${pageNo}&limit=${perPage}`;
    dispatch(getCompanyLoginStampAction(payload));
  };

  const onNext = () => {
    if (pageNo < totalPages) {
      setPageNo(pageNo + 1);
    }
  };

  const onPrevious = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };

  const handleSetPage = (page) => {
    setPageNo(page);
  };

  const handlePerPage = (value) => {
    setPerPage(parseInt(value));
    setPageNo(1);
  };
  return (
    <div>
      {getCompanyLoginStampLoading && <Backdrop />}
      {data?.map((item) => (
        <LoginDetails data={item} />
      ))}
      <Pagination
        currentPage={pageNo}
        onNext={onNext}
        onPrevious={onPrevious}
        from={pageNo * perPage - perPage + 1}
        to={pageNo * perPage}
        totalItems={total}
        perPage={perPage}
        totalPages={totalPages}
        setPage={handleSetPage}
        setperPage={handlePerPage}
      />
    </div>
  );
}

export default LoginStamp;
