const {
  GET_COMPANY_SERVICE,
  GET_COMPANY_SERVICE_SUCCESS,
  GET_COMPANY_SERVICE_ERROR,
  GET_COMPANY_SERVICE_ID,
  GET_COMPANY_SERVICE_ID_SUCCESS,
  GET_COMPANY_SERVICE_ID_ERROR,
  POST_COMPANY_SERVICE,
  POST_COMPANY_SERVICE_SUCCESS,
  POST_COMPANY_SERVICE_ERROR,
  PATCH_COMPANY_SERVICE,
  PATCH_COMPANY_SERVICE_SUCCESS,
  PATCH_COMPANY_SERVICE_ERROR,
  DELETE_COMPANY_SERVICE,
  DELETE_COMPANY_SERVICE_SUCCESS,
  DELETE_COMPANY_SERVICE_ERROR,
  GET_COMPANY_SERVICE_FILTER,
  GET_COMPANY_SERVICE_FILTER_SUCCESS,
  GET_COMPANY_SERVICE_FILTER_ERROR,
} = require("../../actions");

const INIT_STATE = {
  getCompanyService: {},
  getCompanyServiceLoading: false,
  getCompanyServiceError: "",
  getCompanyServiceId: {},
  getCompanyServiceIdLoading: false,
  getCompanyServiceIdError: "",
  getCompanyServiceFilter: {},
  getCompanyServiceFilterLoading: false,
  getCompanyServiceFilterError: "",
  postCompanyService: {},
  postCompanyServiceLoading: false,
  postCompanyServiceError: "",
  patchCompanyService: {},
  patchCompanyServiceLoading: false,
  patchCompanyServiceError: "",
  deleteCompanyService: {},
  deleteCompanyServiceLoading: false,
  deleteCompanyServiceError: "",
};

const Servicesection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_SERVICE:
      return {
        ...state,
        getCompanyService: {},
        getCompanyServiceLoading: true,
      };
    case GET_COMPANY_SERVICE_SUCCESS:
      return {
        ...state,
        getCompanyService: action.payload,
        getCompanyServiceLoading: false,
        getCompanyServiceError: "",
      };

    case GET_COMPANY_SERVICE_ERROR:
      return {
        ...state,
        getCompanyServiceLoading: false,
        getCompanyServiceError: action.payload,
      };
    case GET_COMPANY_SERVICE_ID:
      return {
        ...state,
        getCompanyServiceId: {},
        getCompanyServiceIdLoading: true,
      };
    case GET_COMPANY_SERVICE_ID_SUCCESS:
      return {
        ...state,
        getCompanyServiceId: action.payload,
        getCompanyServiceIdLoading: false,
        getCompanyServiceIdError: "",
      };

    case GET_COMPANY_SERVICE_ID_ERROR:
      return {
        ...state,
        getCompanyServiceIdLoading: false,
        getCompanyServiceIdError: action.payload,
      };
      case GET_COMPANY_SERVICE_FILTER:
      return {
        ...state,
        getCompanyServiceFilter: {},
        getCompanyServiceFilterLoading: true,
      };
    case GET_COMPANY_SERVICE_FILTER_SUCCESS:
      return {
        ...state,
        getCompanyServiceFilter: action.payload,
        getCompanyServiceFilterLoading: false,
        getCompanyServiceFilterError: "",
      };

    case GET_COMPANY_SERVICE_FILTER_ERROR:
      return {
        ...state,
        getCompanyServiceFilterLoading: false,
        getCompanyServiceFilterError: action.payload,
      };
      case POST_COMPANY_SERVICE:
      return {
        ...state,
        postCompanyService: {},
        postCompanyServiceLoading: true,
      };
    case POST_COMPANY_SERVICE_SUCCESS:
      return {
        ...state,
        postCompanyService: action.payload,
        postCompanyServiceLoading: false,
        postCompanyServiceError: "",
      };

    case POST_COMPANY_SERVICE_ERROR:
      return {
        ...state,
        postCompanyServiceLoading: false,
        postCompanyServiceError: action.payload,
      };
    case PATCH_COMPANY_SERVICE:
      return {
        ...state,
        patchCompanyService: {},
        patchCompanyServiceLoading: true,
      };
    case PATCH_COMPANY_SERVICE_SUCCESS:
      return {
        ...state,
        patchCompanyService: action.payload,
        patchCompanyServiceLoading: false,
        patchCompanyServiceError: "",
      };

    case PATCH_COMPANY_SERVICE_ERROR:
      return {
        ...state,
        patchCompanyServiceLoading: false,
        patchCompanyServiceError: action.payload,
      };
    case DELETE_COMPANY_SERVICE:
      return {
        ...state,
        deleteCompanyService: {},
        deleteCompanyServiceLoading: true,
      };
    case DELETE_COMPANY_SERVICE_SUCCESS:
      return {
        ...state,
        deleteCompanyService: action.payload,
        deleteCompanyServiceLoading: false,
        deleteCompanyServiceError: "",
      };

    case DELETE_COMPANY_SERVICE_ERROR:
      return {
        ...state,
        deleteCompanyServiceLoading: false,
        deleteCompanyServiceError: action.payload,
      };
    default:
      return { ...state };
  }
};

export default Servicesection;
