const {
  GET_COMPANY_TRUCK_DETAILS,
  GET_COMPANY_TRUCK_DETAILS_SUCCESS,
  GET_COMPANY_TRUCK_DETAILS_ERROR,
  GET_COMPANY_TRUCK_DETAILS_ID,
  GET_COMPANY_TRUCK_DETAILS_ID_SUCCESS,
  GET_COMPANY_TRUCK_DETAILS_ID_ERROR,
  POST_COMPANY_TRUCK_DETAILS,
  POST_COMPANY_TRUCK_DETAILS_SUCCESS,
  POST_COMPANY_TRUCK_DETAILS_ERROR,
  PATCH_COMPANY_TRUCK_DETAILS,
  PATCH_COMPANY_TRUCK_DETAILS_SUCCESS,
  PATCH_COMPANY_TRUCK_DETAILS_ERROR,
  DELETE_COMPANY_TRUCK,
  DELETE_COMPANY_TRUCK_SUCCESS,
  DELETE_COMPANY_TRUCK_ERROR,
  POST_TRUCK_SELL,
  POST_TRUCK_SELL_SUCCESS,
  POST_TRUCK_SELL_ERROR,
  POST_TRUCK_PURCHASE,
  POST_TRUCK_PURCHASE_SUCCESS,
  POST_TRUCK_PURCHASE_ERROR,
  GET_TRUCK_PURCHASE,
  GET_TRUCK_PURCHASE_SUCCESS,
  GET_TRUCK_PURCHASE_ERROR,
  GET_TRUCK_PERMIT_BYTRUCKID,
  GET_TRUCK_PERMIT_BYTRUCKID_ERROR,
  GET_TRUCK_PERMIT_BYTRUCKID_SUCCESS,
  GET_TRUCK_INSURANCE_BYTRUCKID,
  GET_TRUCK_INSURANCE_BYTRUCKID_SUCCESS,
  GET_TRUCK_INSURANCE_BYTRUCKID_ERROR,
  GET_TRUCK_FITNESS_BYTRUCKID,
  GET_TRUCK_FITNESS_BYTRUCKID_SUCCESS,
  GET_TRUCK_FITNESS_BYTRUCKID_ERROR,
  GET_TRUCK_INSURANCE,
  GET_TRUCK_INSURANCE_SUCCESS,
  GET_TRUCK_INSURANCE_ERROR,
  GET_TRUCK_FITNESS,
  GET_TRUCK_FITNESS_SUCCESS,
  GET_TRUCK_FITNESS_ERROR,
  GET_TRUCK_PERMIT,
  GET_TRUCK_PERMIT_SUCCESS,
  GET_TRUCK_PERMIT_ERROR,
  PATCH_TRUCK_FITNESS,
  PATCH_TRUCK_FITNESS_ERROR,
  PATCH_TRUCK_FITNESS_SUCCESS,
  PATCH_TRUCK_INSURANCE,
  PATCH_TRUCK_INSURANCE_ERROR,
  PATCH_TRUCK_INSURANCE_SUCCESS,
  PATCH_TRUCK_PERMIT,
  PATCH_TRUCK_PERMIT_ERROR,
  PATCH_TRUCK_PERMIT_SUCCESS,
  GET_COMPANY_TRUCK_NOTSELL_DETAILS,
  GET_COMPANY_TRUCK_NOTSELL_DETAILS_ERROR,
  GET_COMPANY_TRUCK_NOTSELL_DETAILS_SUCCESS,
  GET_COMPANY_TRUCK_SELL_DETAILS,
  GET_COMPANY_TRUCK_SELL_DETAILS_SUCCESS,
  GET_COMPANY_TRUCK_SELL_DETAILS_ERROR,
  GET_COMPANY_TRUCK_LIST_DROPDOWN,
  GET_COMPANY_TRUCK_LIST_DROPDOWN_SUCCESS,
  GET_COMPANY_TRUCK_LIST_DROPDOWN_ERROR,
} = require("../../actions");

const INIT_STATE = {
  getCompanyTruckDetails: {},
  getCompanyTruckDetailsLoading: false,
  getCompanyTruckDetailsError: "",
  getCompanyTruckListDropdown: {},
  getCompanyTruckListDropdownLoading: false,
  getCompanyTruckListDropdownError: "",
  getCompanyTruckNotSellDetails: {},
  getCompanyTruckNotSellDetailsLoading: false,
  getCompanyTruckNotSellDetailsError: "",
  getCompanyTruckSellDetails: {},
  getCompanyTruckSellDetailsLoading: false,
  getCompanyTruckSellDetailsError: "",
  getCompanyTruckDetailsId: {},
  getCompanyTruckDetailsIdLoading: false,
  getCompanyTruckDetailsIdError: "",
  postCompanyTruckDetails: {},
  postCompanyTruckDetailsLoading: false,
  postCompanyTruckDetailsError: "",
  postTruckSell: {},
  postTruckSellLoading: false,
  postTruckSellError: "",
  postTruckPurchase: {},
  postTruckPurchaseLoading: false,
  postTruckPurchaseError: "",
  getTruckPurchase: {},
  getTruckPurchaseLoading: false,
  getTruckPurchaseError: "",
  getTruckInsuranceByTruckId: {},
  getTruckInsuranceByTruckIdLoading: false,
  getTruckInsuranceByTruckIdError: "",
  getTruckFitnessByTruckId: {},
  getTruckFitnessByTruckIdLoading: false,
  getTruckFitnessByTruckIdError: "",
  getTruckPermitByTruckId: {},
  getTruckPermitByTruckIdLoading: false,
  getTruckPermitByTruckIdError: "",
  getTruckInsurance: {},
  getTruckInsuranceLoading: false,
  getTruckInsuranceError: "",
  getTruckFitness: {},
  getTruckFitnessLoading: false,
  getTruckFitnessError: "",
  getTruckPermit: {},
  getTruckPermitLoading: false,
  getTruckPermitError: "",
  patchCompanyTruckDetails: {},
  patchCompanyTruckDetailsLoading: false,
  patchCompanyTruckDetailsError: "",
  deleteCompanyTruck: {},
  deleteCompanyTruckLoading: false,
  deleteCompanyTruckError: "",
  patchTruckInsurance: {},
  patchTruckInsuranceLoading: false,
  patchTruckInsuranceError: "",
  patchTruckFitness: {},
  patchTruckFitnessLoading: false,
  patchTruckFitnessError: "",
  patchTruckPermit: {},
  patchTruckPermitLoading: false,
  patchTruckPermitError: "",
};

const Companytrucksection = (state = INIT_STATE, action) => {
  console.log(action)
  switch (action.type) {
    case GET_COMPANY_TRUCK_LIST_DROPDOWN:
      return {
        ...state,
        getCompanyTruckListDropdown: {},
        getCompanyTruckListDropdownLoading: true,
      };
    case GET_COMPANY_TRUCK_LIST_DROPDOWN_SUCCESS:
      return {
        ...state,
        getCompanyTruckListDropdown: action.payload,
        getCompanyTruckListDropdownLoading: false,
        getCompanyTruckListDropdownError: "",
      };

    case GET_COMPANY_TRUCK_LIST_DROPDOWN_ERROR:
      return {
        ...state,
        getCompanyTruckListDropdownLoading: false,
        getCompanyTruckListDropdownError: action.payload,
      };
    case GET_COMPANY_TRUCK_DETAILS:
      return {
        ...state,
        getCompanyTruckDetails: {},
        getCompanyTruckDetailsLoading: true,
      };
    case GET_COMPANY_TRUCK_DETAILS_SUCCESS:
      return {
        ...state,
        getCompanyTruckDetails: action.payload,
        getCompanyTruckDetailsLoading: false,
        getCompanyTruckDetailsError: "",
      };

    case GET_COMPANY_TRUCK_DETAILS_ERROR:
      return {
        ...state,
        getCompanyTruckDetailsLoading: false,
        getCompanyTruckDetailsError: action.payload,
      };
    case GET_COMPANY_TRUCK_NOTSELL_DETAILS:
      return {
        ...state,
        getCompanyTruckNotSellDetails: {},
        getCompanyTruckNotSellDetailsLoading: true,
      };
    case GET_COMPANY_TRUCK_NOTSELL_DETAILS_SUCCESS:
      return {
        ...state,
        getCompanyTruckNotSellDetails: action.payload,
        getCompanyTruckNotSellDetailsLoading: false,
        getCompanyTruckNotSellDetailsError: "",
      };

    case GET_COMPANY_TRUCK_NOTSELL_DETAILS_ERROR:
      return {
        ...state,
        getCompanyTruckNotSellDetailsLoading: false,
        getCompanyTruckNotSellDetailsError: action.payload,
      };
    case GET_COMPANY_TRUCK_SELL_DETAILS:
      return {
        ...state,
        getCompanyTruckSellDetails: {},
        getCompanyTruckSellDetailsLoading: true,
      };
    case GET_COMPANY_TRUCK_SELL_DETAILS_SUCCESS:
      return {
        ...state,
        getCompanyTruckSellDetails: action.payload,
        getCompanyTruckSellDetailsLoading: false,
        getCompanyTruckSellDetailsError: "",
      };

    case GET_COMPANY_TRUCK_SELL_DETAILS_ERROR:
      return {
        ...state,
        getCompanyTruckSellDetailsLoading: false,
        getCompanyTruckSellDetailsError: action.payload,
      };
    case GET_COMPANY_TRUCK_DETAILS_ID:
      return {
        ...state,
        getCompanyTruckDetailsId: {},
        getCompanyTruckDetailsIdLoading: true,
      };
    case GET_COMPANY_TRUCK_DETAILS_ID_SUCCESS:
      return {
        ...state,
        getCompanyTruckDetailsId: action.payload,
        getCompanyTruckDetailsIdLoading: false,
        getCompanyTruckDetailsIdError: "",
      };

    case GET_COMPANY_TRUCK_DETAILS_ID_ERROR:
      return {
        ...state,
        getCompanyTruckDetailsIdLoading: false,
        getCompanyTruckDetailsIdError: action.payload,
      };
    case POST_COMPANY_TRUCK_DETAILS:
      return {
        ...state,
        postCompanyTruckDetails: {},
        postCompanyTruckDetailsLoading: true,
      };
    case POST_COMPANY_TRUCK_DETAILS_SUCCESS:
      return {
        ...state,
        postCompanyTruckDetails: action.payload,
        postCompanyTruckDetailsLoading: false,
        postCompanyTruckDetailsError: "",
      };

    case POST_COMPANY_TRUCK_DETAILS_ERROR:
      return {
        ...state,
        postCompanyTruckDetailsLoading: false,
        postCompanyTruckDetailsError: action.payload,
      };
    case POST_TRUCK_SELL:
      return {
        ...state,
        postTruckSell: {},
        postTruckSellLoading: true,
      };
    case POST_TRUCK_SELL_SUCCESS:
      return {
        ...state,
        postTruckSell: action.payload,
        postTruckSellLoading: false,
        postTruckSellError: "",
      };

    case POST_TRUCK_SELL_ERROR:
      return {
        ...state,
        postTruckSellLoading: false,
        postTruckSellError: action.payload,
      };
    case POST_TRUCK_PURCHASE:
      return {
        ...state,
        postTruckPurchase: {},
        postTruckPurchaseLoading: true,
      };
    case POST_TRUCK_PURCHASE_SUCCESS:
      return {
        ...state,
        postTruckPurchase: action.payload,
        postTruckPurchaseLoading: false,
        postTruckPurchaseError: "",
      };

    case POST_TRUCK_PURCHASE_ERROR:
      return {
        ...state,
        postTruckPurchaseLoading: false,
        postTruckPurchaseError: action.payload,
      };
    case GET_TRUCK_PURCHASE:
      return {
        ...state,
        getTruckPurchase: {},
        getTruckPurchaseLoading: true,
      };
    case GET_TRUCK_PURCHASE_SUCCESS:
      return {
        ...state,
        getTruckPurchase: action.payload,
        getTruckPurchaseLoading: false,
        getTruckPurchaseError: "",
      };

    case GET_TRUCK_PURCHASE_ERROR:
      return {
        ...state,
        getTruckPurchaseLoading: false,
        getTruckPurchaseError: action.payload,
      };

    case GET_TRUCK_INSURANCE_BYTRUCKID:
      return {
        ...state,
        getTruckInsuranceByTruckId: {},
        getTruckInsuranceByTruckIdLoading: true,
      };
    case GET_TRUCK_INSURANCE_BYTRUCKID_SUCCESS:
      return {
        ...state,
        getTruckInsuranceByTruckId: action.payload,
        getTruckInsuranceByTruckIdLoading: false,
        getTruckInsuranceByTruckIdError: "",
      };

    case GET_TRUCK_INSURANCE_BYTRUCKID_ERROR:
      return {
        ...state,
        getTruckInsuranceByTruckIdLoading: false,
        getTruckInsuranceByTruckIdError: action.payload,
      };
    case GET_TRUCK_FITNESS_BYTRUCKID:
      return {
        ...state,
        getTruckFitnessByTruckId: {},
        getTruckFitnessByTruckIdLoading: true,
      };
    case GET_TRUCK_FITNESS_BYTRUCKID_SUCCESS:
      return {
        ...state,
        getTruckFitnessByTruckId: action.payload,
        getTruckFitnessByTruckIdLoading: false,
        getTruckFitnessByTruckIdError: "",
      };

    case GET_TRUCK_FITNESS_BYTRUCKID_ERROR:
      return {
        ...state,
        getTruckFitnessByTruckIdLoading: false,
        getTruckFitnessByTruckIdError: action.payload,
      };
    case GET_TRUCK_PERMIT_BYTRUCKID:
      return {
        ...state,
        getTruckPermitByTruckId: {},
        getTruckPermitByTruckIdLoading: true,
      };
    case GET_TRUCK_PERMIT_BYTRUCKID_SUCCESS:
      return {
        ...state,
        getTruckPermitByTruckId: action.payload,
        getTruckPermitByTruckIdLoading: false,
        getTruckPermitByTruckIdError: "",
      };

    case GET_TRUCK_PERMIT_BYTRUCKID_ERROR:
      return {
        ...state,
        getTruckPermitByTruckIdLoading: false,
        getTruckPermitByTruckIdError: action.payload,
      };

    case GET_TRUCK_INSURANCE:
      return {
        ...state,
        getTruckInsurance: {},
        getTruckInsuranceLoading: true,
      };
    case GET_TRUCK_INSURANCE_SUCCESS:
      return {
        ...state,
        getTruckInsurance: action.payload,
        getTruckInsuranceLoading: false,
        getTruckInsuranceError: "",
      };

    case GET_TRUCK_INSURANCE_ERROR:
      return {
        ...state,
        getTruckInsuranceLoading: false,
        getTruckInsuranceError: action.payload,
      };
    case GET_TRUCK_FITNESS:
      return {
        ...state,
        getTruckFitness: {},
        getTruckFitnessLoading: true,
      };
    case GET_TRUCK_FITNESS_SUCCESS:
      return {
        ...state,
        getTruckFitness: action.payload,
        getTruckFitnessLoading: false,
        getTruckFitnessError: "",
      };

    case GET_TRUCK_FITNESS_ERROR:
      return {
        ...state,
        getTruckFitnessLoading: false,
        getTruckFitnessError: action.payload,
      };
    case GET_TRUCK_PERMIT:
      return {
        ...state,
        getTruckPermit: {},
        getTruckPermitLoading: true,
      };
    case GET_TRUCK_PERMIT_SUCCESS:
      return {
        ...state,
        getTruckPermit: action.payload,
        getTruckPermitLoading: false,
        getTruckPermitError: "",
      };

    case GET_TRUCK_PERMIT_ERROR:
      return {
        ...state,
        getTruckPermitLoading: false,
        getTruckPermitError: action.payload,
      };
    case PATCH_COMPANY_TRUCK_DETAILS:
      return {
        ...state,
        patchCompanyTruckDetails: {},
        patchCompanyTruckDetailsLoading: true,
      };
    case PATCH_COMPANY_TRUCK_DETAILS_SUCCESS:
      return {
        ...state,
        patchCompanyTruckDetails: action.payload,
        patchCompanyTruckDetailsLoading: false,
        patchCompanyTruckDetailsError: "",
      };

    case PATCH_COMPANY_TRUCK_DETAILS_ERROR:
      return {
        ...state,
        patchCompanyTruckDetailsLoading: false,
        patchCompanyTruckDetailsError: action.payload,
      };
    case DELETE_COMPANY_TRUCK:
      return {
        ...state,
        deleteCompanyTruck: {},
        deleteCompanyTruckLoading: true,
      };
    case DELETE_COMPANY_TRUCK_SUCCESS:
      return {
        ...state,
        deleteCompanyTruck: action.payload,
        deleteCompanyTruckLoading: false,
        deleteCompanyTruckError: "",
      };

    case DELETE_COMPANY_TRUCK_ERROR:
      return {
        ...state,
        deleteCompanyTruckLoading: false,
        deleteCompanyTruckError: action.payload,
      };
    case PATCH_TRUCK_INSURANCE:
      return {
        ...state,
        patchTruckInsurance: {},
        patchTruckInsuranceLoading: true,
      };
    case PATCH_TRUCK_INSURANCE_SUCCESS:
      return {
        ...state,
        patchTruckInsurance: action.payload,
        patchTruckInsuranceLoading: false,
        patchTruckInsuranceError: "",
      };

    case PATCH_TRUCK_INSURANCE_ERROR:
      return {
        ...state,
        patchTruckInsuranceLoading: false,
        patchTruckInsuranceError: action.payload,
      };
    case PATCH_TRUCK_FITNESS:
      return {
        ...state,
        patchTruckFitness: {},
        patchTruckFitnessLoading: true,
      };
    case PATCH_TRUCK_FITNESS_SUCCESS:
      return {
        ...state,
        patchTruckFitness: action.payload,
        patchTruckFitnessLoading: false,
        patchTruckFitnessError: "",
      };

    case PATCH_TRUCK_FITNESS_ERROR:
      return {
        ...state,
        patchTruckFitnessLoading: false,
        patchTruckFitnessError: action.payload,
      };
    case PATCH_TRUCK_PERMIT:
      return {
        ...state,
        patchTruckPermit: {},
        patchTruckPermitLoading: true,
      };
    case PATCH_TRUCK_PERMIT_SUCCESS:
      return {
        ...state,
        patchTruckPermit: action.payload,
        patchTruckPermitLoading: false,
        patchTruckPermitError: "",
      };

    case PATCH_TRUCK_PERMIT_ERROR:
      return {
        ...state,
        patchTruckPermitLoading: false,
        patchTruckPermitError: action.payload,
      };
    default:
      return { ...state };
  }
};

export default Companytrucksection;
