import {
  getCompanyListAction,
  postBlockedAction,
} from "../../../redux/admin/company/action";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

function BlockModel({ id, setShowModal, blocked }) {
  const dispatch = useDispatch();
  const { postBlocked, postBlockedLoading, postBlockedError } = useSelector(
    (state) => state.AdminCompanysection
  );
  useEffect(() => {
    if (postBlocked?.status === 200) {
      toast.success(postBlocked?.message, { id: "block" });
      setShowModal(false);
      dispatch(getCompanyListAction());
    }
  }, [postBlocked]);
  useEffect(() => {
    if (postBlockedError?.status === 400) {
      toast.error(postBlockedError?.message, { id: "block" });
    } else if (postBlockedError?.status === 404) {
      toast.error(postBlockedError?.message, { id: "block" });
    } else if (postBlockedError?.status === 500) {
      toast.error(postBlockedError?.error, { id: "block" });
    }
  }, [postBlockedError]);
  const handleSubmit = (value) => {
    const payload = {
      id: id,
      body: { blocked: value },
    };
    dispatch(postBlockedAction(payload));
    toast.loading("Loading...", { id: "block" });
  };

  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle>Block Company</DialogTitle>
      </DialogHeader>
      <div className="text-xl mt-5 font-semibold">
        Are you sure you want to{" "}
        <span className="text-red-500 font-bold">
          {blocked ? "UnBlock" : "Block"}
        </span>{" "}
        this User?
      </div>
      <DialogFooter>
        <button
          className="secondaryButton"
          onClick={() => setShowModal(false)}
          type="button"
          //   disabled={deleteCompanyServiceLoading}
        >
          Close
        </button>
        <button
          className="primaryButton bg-red-600 hover:bg-red-800 w-fit"
          type="button"
          onClick={() => handleSubmit(!blocked)}
          //   disabled={deleteCompanyServiceLoading}
        >
          Yes
        </button>
      </DialogFooter>
    </DialogContent>
  );
}

export default BlockModel;
