import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Image from "../../../assets/images/semi.png";
import moment from "moment";
import { Badge } from "../../../ui/badge";
import { Dialog, DialogTrigger } from "../../../ui/dialog";
import UpdateInsurance from "./updateInsurance";

function TruckExpireCard({ data, reroute = false, section }) {
  const navigate = useNavigate();
  const [showImageModal, setShowImageModal] = useState(false);
  const [showInsuranceModal, setshowInsuranceModal] = useState(false);
  const [showPermitModal, setshowPermitModal] = useState(false);
  const [showFitnessModal, setshowFitnessModal] = useState(false);
  return (
    <div key={data?._id}>
      <div className="grid grid-cols-12 gap-x-7 my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl">
        <div className="lg:col-span-2 col-span-6 row-span-1 justify-self-center my-auto lg:row-span-3">
          <button type="button" className="rounded-full bg-gray-400">
            <img
              src={data?.truckImage ? data?.truckImage?.url : Image}
              alt=""
              className={` ${
                data?.truckImage ? "rounded-full" : ""
              } h-[100px] w-[100px]`}
            />
          </button>
        </div>

        <div className="mb-5 text-center md:text-left lg:col-span-10 col-span-6 row-span-1 self-center lg:self-auto">
          <button
            type="button"
            className="font-bold text-blue-600 text-xl uppercase"
            //   onClick={reroute ? () => navigate(`details/${data._id}`) : null}
          >
            {data?.truckNumber}
          </button>
          <p className="textgrey text-base">
            {data?.truckCompanyName}, {data?.truckModelName},{" "}
            {data?.typeOfTruck}
          </p>
        </div>
        <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center">
          {section === "insurance" && (
            <>
              <div>
                <h3 className="textgrey text-xs md:text-left">Insurance</h3>
                <p className="flex md:justify-left">
                  {data?.isInsurance ? (
                    <Badge className="bg-yellow-700 hover:bg-yellow-900">
                      Expired in{" "}
                      {moment(data?.insuranceTill).diff(
                        moment(new Date()),
                        "days"
                      )}
                    </Badge>
                  ) : (
                    <Badge variant="destructive">No Insurance</Badge>
                  )}
                </p>
              </div>
              <div>
                <h3 className="textgrey text-xs md:text-left">
                  Insurance Till
                </h3>
                <p className="md:text-left">
                  {moment(data?.insuranceTill).format("DD/MM/YYYY")}
                </p>
              </div>
              <button
                onClick={() =>
                  navigate(
                    `/company/truck/insurance/${data._id}?truckNumber=${data?.truckNumber}`
                  )
                }
                className="ghostButton "
              >
                View Insurance
              </button>
              <Dialog open={showInsuranceModal}>
                <button
                  type="button"
                  className="primaryButton  h-full"
                  onClick={() => setshowInsuranceModal(true)}
                >
                  Update Insurance
                </button>
                <UpdateInsurance
                  truckId={data?._id}
                  setShowModal={setshowInsuranceModal}
                  truckNumber={data?.truckNumber}
                />
              </Dialog>
            </>
          )}
          {section === "fitness" && (
            <>
              <div>
                <h3 className="textgrey text-xs md:text-left">Fitness</h3>
                <p className="flex md:justify-left">
                  {data?.isFitness ? (
                    <Badge className="bg-yellow-700 hover:bg-yellow-900">
                      Expired in{" "}
                      {moment(data?.fitnessTill).diff(
                        moment(new Date()),
                        "days"
                      )}
                    </Badge>
                  ) : (
                    <Badge variant="destructive">No Permit</Badge>
                  )}
                </p>
              </div>
              <div>
                <h3 className="textgrey text-xs md:text-center">
                  Fitness Till
                </h3>
                <p className="md:text-center">
                  {moment(data?.fitnessTill).format("DD/MM/YYYY")}
                </p>
              </div>
              <button
                onClick={() =>
                  navigate(
                    `/company/truck/fitness/${data._id}?truckNumber=${data?.truckNumber}`
                  )
                }
                className="ghostButton "
              >
                View Fitness
              </button>
              <Dialog open={showFitnessModal}>
                <button
                  type="button"
                  className="primaryButton  h-full"
                  onClick={() => setshowFitnessModal(true)}
                >
                  Update Permit
                </button>
                <UpdateInsurance
                  truckId={data?._id}
                  setShowModal={setshowFitnessModal}
                  truckNumber={data?.truckNumber}
                />
              </Dialog>
            </>
          )}
          {section === "permit" && (
            <>
              <div>
                <h3 className="textgrey text-xs md:text-center">EMI</h3>
                <p className="flex md:justify-center">
                  {data?.isPermit ? (
                    <Badge className="bg-yellow-700 hover:bg-yellow-900">
                      Expired{" "}
                      {moment(data?.permitTill).diff(
                        moment(new Date()),
                        "days"
                      )}
                    </Badge>
                  ) : (
                    <Badge variant="destructive">No Insurance</Badge>
                  )}
                </p>
              </div>

              <div>
                <h3 className="textgrey text-xs md:text-left">Permit Till</h3>
                <p className="md:text-left">
                  {moment(data?.permitTill).format("DD/MM/YYYY")}
                </p>
              </div>
              <button
                onClick={() =>
                  navigate(
                    `/company/truck/permit/${data._id}?truckNumber=${data?.truckNumber}`
                  )
                }
                className="ghostButton "
              >
                View Permit
              </button>
              <Dialog open={showPermitModal}>
                <button
                  type="button"
                  className="primaryButton  h-full"
                  onClick={() => setshowPermitModal(true)}
                >
                  Update Permit
                </button>
                <UpdateInsurance
                  truckId={data?._id}
                  setShowModal={setshowPermitModal}
                  truckNumber={data?.truckNumber}
                />
              </Dialog>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TruckExpireCard;
