import React, { useEffect, useState } from "react";
import TruckListCard from "./truckListCard";
import { getCompanyTruckByCompanyIdAction, getCompanyTruckByCompanyTruckIdAction } from "../../../../redux/admin/company/action";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import TruckDetailSection from "./truckDetailSection";

function TruckDetailsCompany() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const {
      getCompanyTruckByCompanyTruckId,
      getCompanyTruckByCompanyTruckIdLoading,
      getCompanyTruckByCompanyTruckIdError,
    } = useSelector((state) => state.AdminCompanysection);
    const [data, setData] = useState();
    useEffect(() => {
      dispatch(getCompanyTruckByCompanyTruckIdAction(id));
    }, []);
  console.log(getCompanyTruckByCompanyTruckId)
    useEffect(() => {
      if (getCompanyTruckByCompanyTruckId?.status === 200) {
        setData(getCompanyTruckByCompanyTruckId?.data);
      }
    }, [getCompanyTruckByCompanyTruckId]);
    useEffect(() => {
      if (getCompanyTruckByCompanyTruckIdError?.status === 400) {
        toast.error(getCompanyTruckByCompanyTruckId?.message);
      } else if (getCompanyTruckByCompanyTruckIdError?.status === 404) {
        toast.error(getCompanyTruckByCompanyTruckId?.message);
      } else if (getCompanyTruckByCompanyTruckIdError?.status === 500) {
        toast.error(getCompanyTruckByCompanyTruckId?.error);
      }
    }, [getCompanyTruckByCompanyTruckId]);
  
  return (
    <div>
      <TruckListCard data={data} />
      <TruckDetailSection data={data} />
    </div>
  );
}

export default TruckDetailsCompany;
