const {
  GET_EXPENSE_TRIP,
  GET_EXPENSE_TRIP_SUCCESS,
  GET_EXPENSE_TRIP_ERROR,
  GET_EXPENSE_TRIP_BY_TRIP,
  GET_EXPENSE_TRIP_BY_TRIP_SUCCESS,
  GET_EXPENSE_TRIP_BY_TRIP_ERROR,
  GET_EXPENSE_TRIP_BY_ID,
  GET_EXPENSE_TRIP_BY_ID_SUCCESS,
  GET_EXPENSE_TRIP_BY_ID_ERROR,
  POST_EXPENSE_TRIP,
  POST_EXPENSE_TRIP_SUCCESS,
  POST_EXPENSE_TRIP_ERROR,
  PATCH_EXPENSE_TRIP,
  PATCH_EXPENSE_TRIP_SUCCESS,
  PATCH_EXPENSE_TRIP_ERROR,
  DELETE_EXPENSE_TRIP,
  DELETE_EXPENSE_TRIP_SUCCESS,
  DELETE_EXPENSE_TRIP_ERROR,
  GET_EXPENSE_TRUCK_FILTER,
  GET_EXPENSE_TRUCK_FILTER_SUCCESS,
  GET_EXPENSE_TRUCK_FILTER_ERROR,
  GET_EXPENSE_TRIP_FILTER,
  GET_EXPENSE_TRIP_FILTER_SUCCESS,
  GET_EXPENSE_TRIP_FILTER_ERROR,
} = require("../../../actions");

const INIT_STATE = {
  expenseTripDetails: {},
  expenseTripDetailsLoading: false,
  expenseTripDetailsError: "",
  expenseTripByTrip: {},
  expenseTripByTripLoading: false,
  expenseTripByTripError: "",
  expenseTripById: {},
  expenseTripByIdLoading: false,
  expenseTripByIdError: "",
  postExpenseTrip: {},
  postExpenseTripLoading: false,
  postExpenseTripError: "",
  patchExpenseTrip: {},
  patchExpenseTripLoading: false,
  patchExpenseTripError: "",
  deleteExpenseTrip: {},
  deleteExpenseTripLoading: false,
  deleteExpenseTripError: "",
  getExtraExpenseTripFilter: {},
  getExtraExpenseTripFilterLoading: false,
  getExtraExpenseTripFilterError: "",
};

const ExpenseTripSection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EXPENSE_TRIP:
      return {
        ...state,
        expenseTripDetails: {},
        expenseTripDetailsLoading: true,
      };
    case GET_EXPENSE_TRIP_SUCCESS:
      return {
        ...state,
        expenseTripDetails: action.payload,
        expenseTripDetailsLoading: false,
        expenseTripDetailsError: "",
      };
    case GET_EXPENSE_TRIP_ERROR:
      return {
        ...state,
        expenseTripDetailsLoading: false,
        expenseTripDetailsError: action.payload,
      };
    case GET_EXPENSE_TRIP_BY_TRIP:
      return {
        ...state,
        expenseTripByTrip: {},
        expenseTripByTripLoading: true,
      };
    case GET_EXPENSE_TRIP_BY_TRIP_SUCCESS:
      return {
        ...state,
        expenseTripByTrip: action.payload,
        expenseTripByTripLoading: false,
        expenseTripByTripError: "",
      };
    case GET_EXPENSE_TRIP_BY_TRIP_ERROR:
      return {
        ...state,
        expenseTripByTripLoading: false,
        expenseTripByTripError: action.payload,
      };
    case GET_EXPENSE_TRIP_BY_ID:
      return {
        ...state,
        expenseTripById: {},
        expenseTripByIdLoading: true,
      };
    case GET_EXPENSE_TRIP_BY_ID_SUCCESS:
      return {
        ...state,
        expenseTripById: action.payload,
        expenseTripByIdLoading: false,
        expenseTripByIdError: "",
      };
    case GET_EXPENSE_TRIP_BY_ID_ERROR:
      return {
        ...state,
        expenseTripByIdLoading: false,
        expenseTripByIdError: action.payload,
      };
    case POST_EXPENSE_TRIP:
      return {
        ...state,
        postExpenseTrip: {},
        postExpenseTripLoading: true,
      };
    case POST_EXPENSE_TRIP_SUCCESS:
      return {
        ...state,
        postExpenseTrip: action.payload,
        postExpenseTripLoading: false,
        postExpenseTripError: "",
      };
    case POST_EXPENSE_TRIP_ERROR:
      return {
        ...state,
        postExpenseTripLoading: false,
        postExpenseTripError: action.payload,
      };
    case PATCH_EXPENSE_TRIP:
      return {
        ...state,
        patchExpenseTrip: {},
        patchExpenseTripLoading: true,
      };
    case PATCH_EXPENSE_TRIP_SUCCESS:
      return {
        ...state,
        patchExpenseTrip: action.payload,
        patchExpenseTripLoading: false,
        patchExpenseTripError: "",
      };
    case PATCH_EXPENSE_TRIP_ERROR:
      return {
        ...state,
        patchExpenseTripLoading: false,
        patchExpenseTripError: action.payload,
      };
    case DELETE_EXPENSE_TRIP:
      return {
        ...state,
        deleteExpenseTrip: {},
        deleteExpenseTripLoading: true,
      };
    case DELETE_EXPENSE_TRIP_SUCCESS:
      return {
        ...state,
        deleteExpenseTrip: action.payload,
        deleteExpenseTripLoading: false,
        deleteExpenseTripError: "",
      };
    case DELETE_EXPENSE_TRIP_ERROR:
      return {
        ...state,
        deleteExpenseTripLoading: false,
        deleteExpenseTripError: action.payload,
      };
    case GET_EXPENSE_TRIP_FILTER:
      return {
        ...state,
        getExtraExpenseTripFilter: {},
        getExtraExpenseTripFilterLoading: true,
      };
    case GET_EXPENSE_TRIP_FILTER_SUCCESS:
      return {
        ...state,
        getExtraExpenseTripFilter: action.payload,
        getExtraExpenseTripFilterLoading: false,
        getExtraExpenseTripFilterError: "",
      };
    case GET_EXPENSE_TRIP_FILTER_ERROR:
      return {
        ...state,
        getExtraExpenseTripFilterLoading: false,
        getExtraExpenseTripFilterError: action.payload,
      };
    default:
      return { ...state };
  }
};

export default ExpenseTripSection;
