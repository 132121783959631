import { GET_COMPANY_BY_COMPANYID, GET_COMPANY_BY_COMPANYID_ERROR, GET_COMPANY_BY_COMPANYID_SUCCESS, GET_COMPANY_LIST, GET_COMPANY_LIST_ERROR, GET_COMPANY_LIST_SUCCESS, GET_COMPANY_LOGIN_STAMPS, GET_COMPANY_LOGIN_STAMPS_ERROR, GET_COMPANY_LOGIN_STAMPS_SUCCESS, GET_COMPANY_TRUCK_BY_COMPANYTRUCKID, GET_COMPANY_TRUCK_BY_COMPANYTRUCKID_ERROR, GET_COMPANY_TRUCK_BY_COMPANYTRUCKID_SUCCESS, POST_ADMIN_FORGET_PASSWORD, POST_ADMIN_FORGET_PASSWORD_ERROR, POST_ADMIN_FORGET_PASSWORD_SUCCESS, POST_BLOCKED, POST_BLOCKED_ERROR, POST_BLOCKED_SUCCESS } from "../../actions";

const INIT_STATE = {

    getCompanyList: {},
    getCompanyListLoading: false,
    getCompanyListError: "",
    getCompanyTruckByCompanyTruckId: {},
    getCompanyTruckByCompanyTruckIdLoading: false,
    getCompanyTruckByCompanyTruckIdError: "",
    getCompanyByCompanyId: {},
    getCompanyByCompanyIdLoading: false,
    getCompanyByCompanyIdError: "",
    getCompanyLoginStamp: {},
    getCompanyLoginStampLoading: false,
    getCompanyLoginStampError: "",
    postBlocked: {},
    postBlockedLoading: false,
    postBlockedError: "",
    postAdminForgetPassword: {},
    postAdminForgetPasswordLoading: false,
    postAdminForgetPasswordError: "",
  };

const AdminCompanysection = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COMPANY_LIST:
          return {
            ...state,
            getCompanyList: {},
            getCompanyListLoading: true,
          };
        case GET_COMPANY_LIST_SUCCESS:
          return {
            ...state,
            getCompanyList: action.payload,
            getCompanyListLoading: false,
            getCompanyListError: "",
          };
    
        case GET_COMPANY_LIST_ERROR:
          return {
            ...state,
            getCompanyListLoading: false,
            getCompanyListError: action.payload,
          };
        case GET_COMPANY_TRUCK_BY_COMPANYTRUCKID:
          return {
            ...state,
            getCompanyTruckByCompanyTruckId: {},
            getCompanyTruckByCompanyTruckIdLoading: true,
          };
        case GET_COMPANY_TRUCK_BY_COMPANYTRUCKID_SUCCESS:
          return {
            ...state,
            getCompanyTruckByCompanyTruckId: action.payload,
            getCompanyTruckByCompanyTruckIdLoading: false,
            getCompanyTruckByCompanyTruckIdError: "",
          };
    
        case GET_COMPANY_TRUCK_BY_COMPANYTRUCKID_ERROR:
          return {
            ...state,
            getCompanyTruckByCompanyTruckIdLoading: false,
            getCompanyTruckByCompanyTruckIdError: action.payload,
          };
        case GET_COMPANY_BY_COMPANYID:
          return {
            ...state,
            getCompanyByCompanyId: {},
            getCompanyByCompanyIdLoading: true,
          };
        case GET_COMPANY_BY_COMPANYID_SUCCESS:
          return {
            ...state,
            getCompanyByCompanyId: action.payload,
            getCompanyByCompanyIdLoading: false,
            getCompanyByCompanyIdError: "",
          };
    
        case GET_COMPANY_BY_COMPANYID_ERROR:
          return {
            ...state,
            getCompanyByCompanyIdLoading: false,
            getCompanyByCompanyIdError: action.payload,
          };
        case GET_COMPANY_LOGIN_STAMPS:
          return {
            ...state,
            getCompanyLoginStamp: {},
            getCompanyLoginStampLoading: true,
          };
        case GET_COMPANY_LOGIN_STAMPS_SUCCESS:
          return {
            ...state,
            getCompanyLoginStamp: action.payload,
            getCompanyLoginStampLoading: false,
            getCompanyLoginStampError: "",
          };
    
        case GET_COMPANY_LOGIN_STAMPS_ERROR:
          return {
            ...state,
            getCompanyLoginStampLoading: false,
            getCompanyLoginStampError: action.payload,
          };
        case POST_BLOCKED:
          return {
            ...state,
            postBlocked: {},
            postBlockedLoading: true,
          };
        case POST_BLOCKED_SUCCESS:
          return {
            ...state,
            postBlocked: action.payload,
            postBlockedLoading: false,
            postBlockedError: "",
          };
    
        case POST_BLOCKED_ERROR:
          return {
            ...state,
            postBlockedLoading: false,
            postBlockedError: action.payload,
          };
        case POST_ADMIN_FORGET_PASSWORD:
          return {
            ...state,
            postAdminForgetPassword: {},
            postAdminForgetPasswordLoading: true,
          };
        case POST_ADMIN_FORGET_PASSWORD_SUCCESS:
          return {
            ...state,
            postAdminForgetPassword: action.payload,
            postAdminForgetPasswordLoading: false,
            postAdminForgetPasswordError: "",
          };
    
        case POST_ADMIN_FORGET_PASSWORD_ERROR:
          return {
            ...state,
            postAdminForgetPasswordLoading: false,
            postAdminForgetPasswordError: action.payload,
          };
        default:
          return { ...state };
      }
};

export default AdminCompanysection;