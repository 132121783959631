// src/utils/api.js
import axios from "axios";
import { deleteCookies, getCookies } from "./auth";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const api = axios.create({
  // baseURL: 'https://trucking-backend.vercel.app/api', // Replace with your API base URL
  baseURL: 'https://api.thetrucking.in/api', // Replace with your API base URL
  // baseURL: "http://localhost:5000/api", // Replace with your API base URL
});

// Add a request interceptor
api.interceptors.request.use(
  async (config) => {
    const token = await getCookies("token"); // Get the token from cookies
    if (token) {
      config.headers["auth-token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    // If the response is successful, just return the response
    return response;
  },
  (error) => {
    if (error.response) {
      const status = error.response.status;

      if (status === 401) {
        // Handle 401 Unauthorized errors
        toast.error("Please login again", { id: "logout" });
        setTimeout(() => {
          deleteCookies("token");
          deleteCookies("role");
          window.location.href = "/"; // Redirect to /page
        }, 1000); // Adjust delay as needed
      } else if (status === 403) {
        // Handle 403 Forbidden errors
        toast.error(error.response?.data?.error);
        deleteCookies("token");
        deleteCookies("role");
      }
    }
    return Promise.reject(error);
  }
);

export default api;
