import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import FilterSection from "./filterSection";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyStatsAction,
  getTruckByTruckIdStatsAction,
} from "../../../redux/company/finance/action";
import GraphSection from "./graphSection";
import FuelStats from "./fuelStats";
import TollStats from "./tollStats";
import ServiceSection from "./serviceSection";
import DEFSection from "./defSection";
import InsuranceSection from "./insuranceSection";
import FitnessSection from "./fitnessSection";
import PermitSection from "./permitSection";
import EmiSection from "./emiSection";
import ExtraExpensesSection from "./extraExpensesSection";
import SalarySection from "./salarySection";

// Function to manually convert filter object to query string
const convertFilterToQueryString = (filter) => {
  return Object.keys(filter)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(filter[key])}`
    )
    .join("&");
};

function Stats() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams(); // Search params hook

  const { companyStats, truckByTruckIdStats } = useSelector(
    (state) => state.StatsReducer
  );
  const [data, setData] = useState();
  const [show, setShow] = useState("");

  useEffect(() => {
    const filter = {
      startDate: "",
      endDate: "",
    };
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    const truckId = searchParams.get("truckId");
    const isTruck = searchParams.get("allTruck") === "true";

    // Add date filters if they are present
    if (startDate) {
      filter.startDate = new Date(startDate).toISOString(); // Convert date to ISO string
    }
    if (endDate) {
      filter.endDate = new Date(endDate).toISOString(); // Convert date to ISO string
    }

    // Add truckId if it's not 'allTruck'
    if (!isTruck && truckId) {
      filter.truckId = truckId;
    }
    // Convert filter object to query string
    const queryString = convertFilterToQueryString(filter);

    // Dispatch actions with query string
    if (isTruck) {
      dispatch(getCompanyStatsAction(queryString));
    } else {
      dispatch(getTruckByTruckIdStatsAction(queryString));
    }
  }, [searchParams, dispatch]);

  useEffect(() => {
    const isTruck = searchParams.get("allTruck") === "true";
    if (isTruck) {
      if (companyStats?.status === 200) {
        setData(companyStats?.data);
      }
    }
  }, [companyStats, searchParams]);
  console.log(truckByTruckIdStats);
  useEffect(() => {
    const isTruck = searchParams.get("allTruck") === "true";
    if (!isTruck) {
      if (truckByTruckIdStats?.status === 200) {
        setData(truckByTruckIdStats?.data);
      }
    }
  }, [truckByTruckIdStats, searchParams]);
  return (
    <div className="h-full">
      <div className="lg:flex hidden flex-col">
        <button onClick={() => navigate(-1)}>
          <label className="flex gap-0.5 cursor-pointer w-fit text-sm">
            <IoMdArrowRoundBack className="text-lg my-auto" />
            Back
          </label>
        </button>
        <FilterSection data={data} />
        <div className="grid grid-cols-4 gap-6">
          <GraphSection data={data} setShow={setShow} show={show} />
          </div>
        <div className="grid grid-cols-4 gap-6">
          <FuelStats setShow={setShow} show={show} />
          <TollStats setShow={setShow} show={show} />
          <ServiceSection setShow={setShow} show={show} />
          <DEFSection setShow={setShow} show={show} />
        </div>
        <div className="grid grid-cols-4 gap-6">
          <InsuranceSection setShow={setShow} show={show} />
          <FitnessSection setShow={setShow} show={show} />
          <PermitSection setShow={setShow} show={show} />
          <EmiSection setShow={setShow} show={show} />
        </div>
        <div className="grid grid-cols-4 gap-6">
          <ExtraExpensesSection setShow={setShow} show={show} />
          <SalarySection setShow={setShow} show={show} />
        </div>
      </div>
      <div className="lg:hidden flex h-full w-full m-auto justify-center items-center text-2xl font-black">
          Open This Page In bigger screen 

      </div>
    </div>
  );
}

export default Stats;
