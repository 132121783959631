import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyTripDetailsFilterAction, getCompanyTripDetailsIdAction, patchCompanyTripDetailsAction } from '../../../redux/company/trip/action';
import {  DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../../ui/dialog';
import DatePicker from '../../datepicker/datePicker';
import { useSearchParams } from 'react-router-dom';
import { getCompanyTruckDetailsIdAction } from '../../../redux/company/truck/action';

function EndTrip({ data, setShowModal }) {
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const {
    patchCompanyTripDetails,
    patchCompanyTripDetailsLoading,
    patchCompanyTripDetailsError,
  } = useSelector((state) => state.CompanyTripsection);

  const [loading, setLoading] = useState();




  const initialValues = {
    endLocation: data?.endLocation || "",
    endingLoad: data?.endingLoad || null,
    distanceTravelled: data?.distanceTravelled || "",
    endDate: data?.endDate ? new Date(data?.endDate) : "",
  };

  const validationSchema = Yup.object({
    endLocation: Yup.string().required('End location is required'),
    endingLoad: Yup.number().required('Ending load is required'),
    distanceTravelled: Yup.number().required('Distance travelled is required'),
    endDate: Yup.date().required('End date is required').nullable(),
  });
  
  useEffect(() => {
    if(loading){

      if (patchCompanyTripDetails?.status === 200) {
        toast.success(patchCompanyTripDetails.message, { id: 'updateDetails-toast' });
        const id = searchParams.get("truckId");
        const tripid = searchParams.get("tripId");
        const payload = {
          truckId: id,
        };
        if(tripid !== null){
          dispatch(getCompanyTripDetailsIdAction(tripid))
          dispatch(getCompanyTruckDetailsIdAction(id))
        }else{

          dispatch(getCompanyTripDetailsFilterAction(payload));
        }
        setLoading(false)
        setShowModal(false)
      } else if (patchCompanyTripDetailsError?.status === 404) {
        toast.error(patchCompanyTripDetailsError.message, { id: 'updateDetails-toast' });
        setLoading(false)
      } else if (patchCompanyTripDetailsError?.status === 400) {
        toast.error(patchCompanyTripDetailsError.message, { id: 'updateDetails-toast' });
        setLoading(false)
      } else if (patchCompanyTripDetailsError?.status === 500) {
        toast.error(patchCompanyTripDetailsError.error, { id: 'updateDetails-toast' });
        setLoading(false)
      }
    }
  }, [patchCompanyTripDetails, patchCompanyTripDetailsError])
  

  const onSubmit = async (values, { setSubmitting }) => {
    const payload = {
      id: data._id,
      body: values,
    };
    dispatch(patchCompanyTripDetailsAction(payload));
    toast.loading('Updating Trip Details...', { id: 'updateDetails-toast' });
    setLoading(true)

   
    // setSubmitting(false);
  };

  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle >End Trip</DialogTitle>
      </DialogHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, isSubmitting }) => (
          <Form className=''>
            <div className="mb-4 ">
              <label htmlFor="endLocation">End Location *</label>
              <Field
                type="text"
                id="endLocation"
                name="endLocation"
                className=""
                placeholder="End Location"
              />
              <ErrorMessage name="endLocation" component="div" className="error" />
            </div>
            <div className="mb-4">
              <label htmlFor="endingLoad">Ending Load *</label>
              <Field
                type="number"
                id="endingLoad"
                name="endingLoad"
                className=""
                placeholder="Ending Load"
              />
              <ErrorMessage name="endingLoad" component="div" className="error" />
            </div>
            <div className="mb-4">
              <label htmlFor="distanceTravelled">Distance Travelled *</label>
              <Field
                type="number"
                id="distanceTravelled"
                name="distanceTravelled"
                className=""
                placeholder="Distance Travelled"
              />
              <ErrorMessage name="distanceTravelled" component="div" className="error" />
            </div>
            <div className="mb-4">
              <label htmlFor="endDate">End Date *</label>
              <DatePicker
                id="endDate"
                name="endDate"
              />
              <ErrorMessage name="endDate" component="div" className="error" />
            </div>
            <DialogFooter className="md:gap-5 ">
                <button className='ghostButton md:w-fit capitalize' type="button" onClick={()=>setShowModal(false)} disabled={patchCompanyTripDetailsLoading} >
                  Close
                </button>
                
                <button type="submit" className='primaryButton md:w-fit capitalize' disabled={patchCompanyTripDetailsLoading} >
                  Save
                </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default EndTrip;
