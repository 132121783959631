import React, { useEffect, useState } from "react";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "../../../ui/input-otp";
import { postTruckPurchaseAction } from "../../../redux/company/truck/action";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp";
import { useNavigate } from "react-router-dom";


function OtpModalTruck({data, setShowModal, setPurchaseLoader}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const validationSchema = Yup.object({ });
  const onSubmit = (values) => {
    const payload = {
      id: data?.truckId,
      body: {...data.body, otp: values.otp},
    };
    dispatch(postTruckPurchaseAction(payload));
    setPurchaseLoader(true);
    toast.loading("Saving Details...", { id: "addpurchaseDetails-toast" });
  };
  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle>Truck Purchase Verification</DialogTitle>
      </DialogHeader>
      <div className="text-sm mt-5 font-semibold">
        You need to provide OTP to verify the truck purchase. Ask The company person so who sell you this Truck for OTP.
      </div>
      <Formik
        initialValues={{ otp: "" }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="">
            <div className="mb-4">
            <div className="items-center justify-center flex">
                <InputOTP
                  maxLength={6}
                  value={values.otp}
                  autoFocus
                  pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
                  onChange={(value) => setFieldValue("otp", value)}
                >
                  <InputOTPGroup>
                    <InputOTPSlot index={0} />
                    <InputOTPSlot index={1} />
                    <InputOTPSlot index={2} />
                    <InputOTPSlot index={3} />
                    <InputOTPSlot index={4} />
                    <InputOTPSlot index={5} />
                  </InputOTPGroup>
                </InputOTP>
              </div>
            </div>
            <DialogFooter>
            <button
                className="ghostButton text-xs"
                onClick={() => navigate(`/company/contactUs?reason=truckPurchase&truckId=${data?.truckId}`)}
                type="button"
              >
                Contact Truckiing
              </button>
              <button
                className="secondaryButton w-fit"
                onClick={() => setShowModal(false)}
                type="button"
              >
                Close
              </button>
             
              <button
                className="primaryButton  w-fit"
                type="submit"
              >
                Verify
              </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default OtpModalTruck;
