import React from "react";
import { Field, Form } from "formik";
import { IoClose } from "react-icons/io5";
import DatePicker from "../../../../components/datepicker/datePicker";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../ui/dialog";

function ServiceFilter({
  handleClearFilters,
  values,
  handleSubmit,
  setShowFilters,
  resetForm,
}) {
  const handleClear = () => {
    resetForm();
    handleClearFilters();
  };
  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle>
          <div className="flex justify-between">
            <div>Trip Filter</div>
            <button onClick={() => setShowFilters(false)}>
              <IoClose />
            </button>
          </div>
        </DialogTitle>
      </DialogHeader>
      <div className="grid grid-cols-2 gap-4 overflow-y-auto max-h-[60vh] py-4">
        <div>
          <label htmlFor="startDate">Date From</label>
          <DatePicker
            name="startDate"
            id="startDate"
            startDate={new Date()}
            endDate={null}
            selectsStart
            placeHolder="Select Date Range"
          />
        </div>
        <div>
          <label htmlFor="endDate">Date To</label>
          <DatePicker
            name="endDate"
            id="endDate"
            startDate={new Date()}
            endDate={null}
            selectsEnd
            minDate={values.startDate}
            placeHolder="Select Date Range"
          />
        </div>
        <div>
          <label htmlFor="serviceType">
            Service Type<span>*</span>
          </label>
          <Field as="select" id="serviceType" name="serviceType" className="">
            <option value="">Service Type</option>
            <option value="Major">Major</option>
            <option value="Minor">Minor</option>
            <option value="Regular Check">Regular Check</option>
          </Field>
        </div>
      </div>
      <DialogFooter>
        <button type="submit" className="primaryButton" onClick={handleSubmit}>
          Apply Filters
        </button>
        <button type="button" className="ghostButton" onClick={handleClear}>
          Clear Filters
        </button>
      </DialogFooter>
    </DialogContent>
  );
}

export default ServiceFilter;
