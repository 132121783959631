import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_LOCATION, PATCH_RESET_PASSWORD, POST_FORGET_PASSWORD, POST_LOGIN, POST_OTP, POST_REGISTER, POST_RESEND_OTP } from "../actions";
import { getLocationError, getLocationSuccess, patchResetPasswordError, patchResetPasswordSuccess, postForgetPasswordError, postForgetPasswordSuccess, postLoginError, postLoginSuccess, postOtpError, postOtpSuccess, postRegisterError, postRegisterSuccess, postResendOtpError, postResendOtpSuccess } from "./action";

// const ApiLinks = "http://localhost:5000/api"
const ApiLinks = "https://api.thetrucking.in/api"
// const ApiLinks = 'https://trucking-backend.vercel.app/api' // Replace with your API base URL

//login api 
function* postLoginCall(paylaod) {
  try {
    const getApiRes = yield call(postLoginAsync, paylaod);
    yield put(postLoginSuccess(getApiRes));
  } catch (error) {
    yield put(postLoginError(error));
  }
}
const postLoginAsync = (payload) =>
  fetch(`${ApiLinks}/auth/login`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchPostLoginData() {
  yield takeEvery(POST_LOGIN, postLoginCall);
}

//register api 
function* postRegisterCall(paylaod) {
  try {
    const getApiRes = yield call(postRegisterAsync, paylaod);

    yield put(postRegisterSuccess(getApiRes));
  } catch (error) {
    yield put(postRegisterError(error));
  }
}
const postRegisterAsync = (payload) =>
  fetch(`${ApiLinks}/auth/register`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchPostRegisterData() {
  yield takeEvery(POST_REGISTER, postRegisterCall);
}
//Otp api 
function* postOtpCall(paylaod) {
  try {
    const getApiRes = yield call(postOtpAsync, paylaod);

    yield put(postOtpSuccess(getApiRes));
  } catch (error) {
    yield put(postOtpError(error));
  }
}
const postOtpAsync = (payload) =>
  fetch(`${ApiLinks}/auth/verify-otp`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch(err => console.log("err", err));


export function* watchPostOtpData() {
  yield takeEvery(POST_OTP, postOtpCall);
}
//resend Otp api 
function* postResendOtpCall(paylaod) {
  try {
    const getApiRes = yield call(postResendOtpAsync, paylaod);

    yield put(postResendOtpSuccess(getApiRes));
  } catch (error) {
    console.log("error", error)
    yield put(postResendOtpError(error));
  }
}
const postResendOtpAsync = (payload) =>
  // console.log(payload)
  fetch(`${ApiLinks}/auth/resend-otp/${payload.payload}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch(err => console.log("err", err));

export function* watchPostResendOtpData() {
  yield takeEvery(POST_RESEND_OTP, postResendOtpCall);
}
//forget password api 
function* postForgetPasswordCall(paylaod) {
  try {
    const getApiRes = yield call(postForgetPasswordAsync, paylaod);

    yield put(postForgetPasswordSuccess(getApiRes));
  } catch (error) {
    yield put(postForgetPasswordError());
  }
}
const postForgetPasswordAsync = (payload) =>
  fetch(`${ApiLinks}/auth/forget-password`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchPostForgetPasswordData() {
  yield takeEvery(POST_FORGET_PASSWORD, postForgetPasswordCall);
}
//forget password api 
function* patchResetPasswordCall(paylaod) {
  try {
    const getApiRes = yield call(patchResetPasswordAsync, paylaod);

    yield put(patchResetPasswordSuccess(getApiRes));
  } catch (error) {
    yield put(patchResetPasswordError());
  }
}
const patchResetPasswordAsync = ({payload}) =>
  fetch(`${ApiLinks}/auth/reset-password/${payload.token}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchPatchResetPasswordData() {
  yield takeEvery(PATCH_RESET_PASSWORD, patchResetPasswordCall);
}




export default function* rootSaga() {
  yield all([
    fork(watchPostLoginData),
    fork(watchPostRegisterData),
    fork(watchPostOtpData),
    fork(watchPostResendOtpData),
    fork(watchPostForgetPasswordData),
    fork(watchPatchResetPasswordData),
  ]);
}