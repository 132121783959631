import React, { useState } from "react";
import Image from "../../../../assets/images/spare-parts.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogTrigger } from "../../../../ui/dialog";
import DeleteService from "./deleteService";
import ImagePopUpDownload from "../../../../components/imagePopUpDownload";
import { FaRegEdit } from "react-icons/fa";
import { TfiTrash } from "react-icons/tfi";
import { FcImageFile } from "react-icons/fc";

function ServiceListCard({ data }) {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(amount)
      : "₹0.00";
  return (
    <div className="bg-white p-4 rounded-md shadow-lg mt-4  w-full text-xs" key={data?.id}>
      <Dialog open={showDeleteModal}>
        <DeleteService setShowModal={setShowDeleteModal} id={data?._id} />
      </Dialog>
      <div className=" grid xl:grid-cols-6 lg:grid-cols-4 grid-cols-2 xl:grid-rows-2 lg:grid-rows-4  lg:gap-x-20 gap-x-9  lg:gap-y-6  gap-y-4">
        <div className="lg:border-r lg:border-r-[#F1F2F6] lg:border-b-0 border-b border-b-[#F1F2F6] row-span-full flex flex-col gap-2 lg:col-span-1 col-span-2 pb-5 lg:text-left text-center  relative">
          <label htmlFor="" className="relative">
            Service Center
          </label>
          <p className=" textPrimary uppercase ">{data?.serviceCentreName}</p>
          <div
            className={`flex w-full md:justify-start justify-center gap-2  ${
              data.serviceAt === "Company"
                ? "text-[#15803D] "
                : "text-[#DC1919]"
            }`}
          >
            <p className="">
              <div
                className={`px-1.5 py-0.5 rounded-full border  text-[10px] font-medium ${
                  data.serviceAt === "Company"
                    ? "border-[#15803D] "
                    : "border-[#DC1919]"
                }`}
              >
                {data.serviceAt === "Company"
                  ? "Company Service Center"
                  : "Outside"}
              </div>
            </p>
          </div>
          {data?.serviceBillImage?.length > 0 && (
            <div className="">
              <Dialog open={showImageModal}>
                <button
                  type="button"
                  onClick={() => setShowImageModal(true)}
                  className="ghostButton font-semibold flex gap-1 items-center md:mx-0 mx-auto"
                >
                  <FcImageFile className="text-2xl" /> Image
                </button>
                <ImagePopUpDownload
                  isArray={true}
                  image={data?.serviceBillImage}
                  setShowModal={setShowImageModal}
                  imagename={`fuel_${data?._id}`}
                />
              </Dialog>
            </div>
          )}

          <div className="flex md:hidden ml-auto absolute z-30 left-0">
            <button
              type="button"
              onClick={() => setShowDeleteModal(true)}
              className="ghostButton text-[#DC1919] font-semibold  flex gap-1 hover:text-red-700 disabled:text-red-200"
            >
              <TfiTrash className="text-xl" />
            </button>
          </div>
          <div className=" flex md:hidden ml-auto absolute z-30 right-0">
            <button
              type="button"
              className="ghostButton font-semibold  flex gap-1"
              onClick={() =>
                navigate(`/company/service/addService?id=${data?._id}`)
              }
            >
              <FaRegEdit className="text-xl" />
            </button>
          </div>
        </div>
        <div className="">
          <label htmlFor="">Service Type</label>
          <p className="textSecondary">{data?.serviceType}</p>
        </div>
        <div className="">
          <label htmlFor="">Truck ODO Meter Reading</label>
          <p className="textSecondary">{data?.odoMeterReading} KM</p>
        </div>
        <div className="">
          <label htmlFor="">Extra Charges</label>
          <p className="textSecondary">{formatAmount(data?.extraCost)}</p>
        </div>
        <div className="">
          <label htmlFor="">Tax</label>
          <p className="textSecondary">{data?.extraCost}%</p>
        </div>
        <div className="flex gap-6 flex-wrap">
          <div className=" lg:flex hidden ">
            <button
              type="button"
              className="ghostButton font-semibold  flex gap-1"
              onClick={() =>
                navigate(`/company/service/addService?id=${data?._id}`)
              }
            >
              <FaRegEdit className="text-xl" />
              Edit
            </button>
          </div>
          <div className="lg:flex hidden">
            <button
              type="button"
              onClick={() => setShowDeleteModal(true)}
              className="ghostButton text-[#DC1919] font-semibold  flex gap-1 hover:text-red-700 disabled:text-red-200"
            >
              <TfiTrash className="text-xl" /> Delete
            </button>
          </div>
        </div>
        <div className="">
          <label htmlFor="">Total Service Cost</label>
          <p className="textSecondary">{formatAmount(data?.serviceCost)}</p>
        </div>

        <div className="">
          <label htmlFor="">Service Bill No</label>
          <p className="textSecondary">{data?.serviceBillNo}</p>
        </div>
        <div className="">
          <label htmlFor="">Service Date</label>
          <p className="textSecondary">
            {moment(data?.serviceDate).format("DD / MMM / YYYY")}
          </p>
        </div>
      </div>
      <div className="grid xl:grid-cols-6 lg:grid-cols-4 grid-cols-2  lg:gap-x-20 gap-x-9  lg:gap-y-2 gap-y-4">
        <h3 className="lg:col-span-6 col-span-2 lg:col-start-2 font-bold">
          Parts Changed
        </h3>
        <label htmlFor="" className="col-start-2 lg:flex hidden">
          Part Name
        </label>
        <label htmlFor="" className=" lg:flex hidden">
          Cost X Quantity
        </label>
        <label htmlFor="" className=" lg:flex hidden">
          Total Cost
        </label>
        <label htmlFor="" className=" col-span-2 lg:flex hidden">
          Description
        </label>
        {data?.partsChange.map((item) => (
          <>
            <label
              htmlFor=""
              className=" flex lg:hidden h-0.5 bg-[#F1F2F6] w-full col-span-2"
            ></label>

            <div className="lg:col-start-2">
              <label htmlFor="" className=" flex lg:hidden">
                Part Name
              </label>
              <p className="textSecondary capitalize">{item.partName}</p>
            </div>
            <div className="">
              <label htmlFor="" className=" flex lg:hidden">
                Cost X Quantity
              </label>
              <p className="textSecondary">
                Rs.{item.partCost} X {item.quantity} {item.typeOfQuantity}
              </p>
            </div>
            <div className="">
              <label htmlFor="" className=" flex lg:hidden">
                Total Cost
              </label>
              <p className="textSecondary"> Rs.{item.totalCost}</p>
            </div>
            <div className="">
              <label htmlFor="" className=" flex lg:hidden">
                Description
              </label>
              <p className="textSecondary">{item.description || "-"}</p>
            </div>
          </>
        ))}
      </div>
    </div>
  );
}

export default ServiceListCard;
