import {
  CLEAR_ROLE, CLEAR_TOKEN, GET_LOCATION,
  GET_LOCATION_ERROR, GET_LOCATION_SUCCESS, GET_ROLE,
  GET_TOKEN, PATCH_RESET_PASSWORD, PATCH_RESET_PASSWORD_ERROR,
  PATCH_RESET_PASSWORD_SUCCESS, POST_FORGET_PASSWORD, POST_FORGET_PASSWORD_ERROR,
  POST_FORGET_PASSWORD_SUCCESS, POST_LOGIN, POST_LOGIN_ERROR, POST_LOGIN_SUCCESS,
  POST_REGISTER, POST_REGISTER_ERROR, POST_REGISTER_SUCCESS, SET_ROLE, SET_TOKEN,
  LOGOUT,
  POST_OTP,
  POST_OTP_SUCCESS,
  POST_OTP_ERROR,
  POST_RESEND_OTP,
  POST_RESEND_OTP_SUCCESS,
  POST_RESEND_OTP_ERROR
} from "../actions";

export const postLoginAction = (info) => ({
  type: POST_LOGIN,
  payload: info,
});
export const postLoginSuccess = (info) => ({
  type: POST_LOGIN_SUCCESS,
  payload: info,
});
export const postLoginError = (error) => ({
  type: POST_LOGIN_ERROR,
  payload: error,
});
export const postRegisterAction = (info) => ({
  type: POST_REGISTER,
  payload: info,
});
export const postRegisterSuccess = (info) => ({
  type: POST_REGISTER_SUCCESS,
  payload: info,
});
export const postRegisterError = (error) => ({
  type: POST_REGISTER_ERROR,
  payload: error,
});
export const postOtpAction = (info) => ({
  type: POST_OTP,
  payload: info,
});
export const postOtpSuccess = (info) => ({
  type: POST_OTP_SUCCESS,
  payload: info,
});
export const postOtpError = (error) => ({
  type: POST_OTP_ERROR,
  payload: error,
});
export const postResendOtpAction = (info) => ({
  type: POST_RESEND_OTP,
  payload: info,
});
export const postResendOtpSuccess = (info) => ({
  type: POST_RESEND_OTP_SUCCESS,
  payload: info,
});
export const postResendOtpError = (error) => ({
  type: POST_RESEND_OTP_ERROR,
  payload: error,
});
export const postForgetPasswordAction = (info) => ({
  type: POST_FORGET_PASSWORD,
  payload: info,
});
export const postForgetPasswordSuccess = (info) => ({
  type: POST_FORGET_PASSWORD_SUCCESS,
  payload: info,
});
export const postForgetPasswordError = (error) => ({
  type: POST_FORGET_PASSWORD_ERROR,
  payload: error,
});
export const patchResetPasswordAction = (info) => ({
  type: PATCH_RESET_PASSWORD,
  payload: info,
});
export const patchResetPasswordSuccess = (info) => ({
  type: PATCH_RESET_PASSWORD_SUCCESS,
  payload: info,
});
export const patchResetPasswordError = (error) => ({
  type: PATCH_RESET_PASSWORD_ERROR,
  payload: error,
});
export const setToken = (data) => ({
  type: SET_TOKEN,
  payload: data,
});
export const getToken = (data) => ({
  type: GET_TOKEN,
  payload: data,
});
export const clearToken = (data) => ({
  type: CLEAR_TOKEN,
  payload: data,
});
export const setRole = (data) => ({
  type: SET_ROLE,
  payload: data,
});
export const getRole = (data) => ({
  type: GET_ROLE,
  payload: data,
});
export const clearRole = (data) => ({
  type: CLEAR_ROLE,
  payload: data,
});

export const logout = (data) => ({
  type: LOGOUT,
  payload: data,
});