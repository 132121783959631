import api from "../../auth/api.jsx";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_CITY, GET_STATE } from "../actions.js";
import { getCityError, getCitySuccess, getStateError, getStateSuccess } from "./action.js";


//Get state api
function* getStateCall() {
  try {
    const getApiRes = yield call(api.get, `/states`);
    yield put(getStateSuccess(getApiRes.data));
  } catch (error) {
    yield put(getStateError(error));
  }
}

export function* watchGetStateData() {
  yield takeEvery(GET_STATE, getStateCall);
}

//Get city api
function* getCityCall(payload) {
  try {
    const getApiRes = yield call(api.get, `/city/${payload.payload}`);
    yield put(getCitySuccess(getApiRes.data));
  } catch (error) {
    yield put(getCityError(error));
  }
}

export function* watchGetCityData() {
  yield takeEvery(GET_CITY, getCityCall);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetStateData),
    fork(watchGetCityData),
  ]);
}
