import React from "react";
import TripGraph from "./tripgraph";
import DashboardTruck from "./dashboardTruck";
import TruckPiechart from "./truckPiechart";
import Tripgraph from "./tripgraph";
import TripPiechart from "./tripPiechart";
import TripDashboardList from "./tripDashboardList";
import PendingAmount from "./pendingAmount";
import EmployeeGraph from "./employeeGraph";
import ExpenseGraph from "./expenseGraph";
import ExpensePieChart from "./expensePieChart";
import Backdrop from "../../backdrop";
import { useSelector } from "react-redux";

function CompanyDashboard() {
  const {
    getCompanyDashboardExpenseGraphLoading,
    getCompanyDashboardTripGraphLoading,
    getCompanyDashboardTripPendingAmountLoading,
    getCompanyDashboardExpensePiechartLoading,
    getCompanyDashboardTruckPiechartLoading,
    getCompanyDashboardTripPiechartLoading,
    getCompanyDashboardTruckGraphLoading,
    getCompanyDashboardEmployeeGraphLoading,
    getCompanyDashboardTripListLoading,
  } = useSelector((state) => state.CompanyDashboardSection);
  return (
    <div className="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-5">
      {(getCompanyDashboardExpenseGraphLoading ||
        getCompanyDashboardTripGraphLoading ||
        getCompanyDashboardTripPendingAmountLoading ||
        getCompanyDashboardExpensePiechartLoading ||
        getCompanyDashboardTruckPiechartLoading ||
        getCompanyDashboardTripPiechartLoading ||
        getCompanyDashboardTruckGraphLoading ||
        getCompanyDashboardEmployeeGraphLoading ||
        getCompanyDashboardTripListLoading) && <Backdrop />}
      <ExpenseGraph />
      <Tripgraph />
      <PendingAmount />
      <ExpensePieChart />
      <TruckPiechart />
      <TripPiechart />
      <DashboardTruck />
      <EmployeeGraph />
      <TripDashboardList />
    </div>
  );
}

export default CompanyDashboard;
