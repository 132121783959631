import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { Checkbox } from "../../../ui/checkbox";
import Backdrop from "../../backdrop";
import { getCompanyDashboardTripGraphAction } from "../../../redux/company/dashboard/action";
import { getCompanyTruckListDropdownAction } from "../../../redux/company/truck/action";
import { getClientAction } from "../../../redux/company/client/action";

function Tripgraph() {
  const dispatch = useDispatch();
  const {
    getCompanyDashboardTripGraph,
  } = useSelector((state) => state.CompanyDashboardSection);
  const { getCompanyTruckListDropdown, getCompanyTruckListDropdownLoading } = useSelector(
    (state) => state.Companytrucksection
  );
  const { getClient, getClientLoading } = useSelector(
    (state) => state.Clientsection
  );
  const [startDate, setStartDate] = useState("");
  const [granularity, setGranularity] = useState("month");
  const [endDate, setEndDate] = useState("");
  const [truckData, setTruckData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [truck, setTruck] = useState("");
  const [client, setClient] = useState("");
  const [year, setYear] = useState("2024");
  const [data, setData] = useState([]);

  useEffect(() => {
    if (getClient?.status === 200) {
      setClientData(getClient?.data);
    }
  }, [getClient]);
  useEffect(() => {
    dispatch(getClientAction());
    dispatch(getCompanyTruckListDropdownAction(""));
  }, [dispatch]);
  console.log(getCompanyTruckListDropdown)
  useEffect(() => {
    if (getCompanyTruckListDropdown?.status === 200) {
      setTruckData(getCompanyTruckListDropdown?.trucks);
    }
  }, [getCompanyTruckListDropdown]);
  useEffect(() => {
    if (getCompanyDashboardTripGraph?.status === 200) {
      setData(getCompanyDashboardTripGraph.data);
    }
  }, [getCompanyDashboardTripGraph]);

  // Load truck data when component mounts or state changes
  useEffect(() => {
    initialLoad(granularity, startDate, endDate, year);
  }, [granularity, startDate, endDate, year, truck, client]); // Add dependencies to re-run on changes

  const initialLoad = (
    granularityValue,
    startDateValue,
    endDateValue,
    yearValue
  ) => {
    const queryParams = {
      granularity: granularityValue,
      clientId: client,
      truckId: truck,
      startDate: startDateValue,
      endDate: endDateValue,
      year: yearValue,
    };

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    // Dispatch action to get truck count
    dispatch(getCompanyDashboardTripGraphAction(queryString));
  };

  const handleGranularityChange = (e) => {
    setGranularity(e.target.value); // Update granularity based on selected radio button
  };

  const chartData = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: data?.map((item) => item.period),
      },
      stroke: {
        curve: "smooth",
      },
      colors: ["#FF9800", "#2E93fA"],
    },

    series: [
      {
        name: "Total Cost",
        data: data?.map((item) => item.trips),
      },
    ],
  };

  return (
    <div className="bg-white p-4 h-fit col-span-2 xl:order-2 order-3 ">
      <div className="flex flex-col xl:flex-row justify-between">
        <div className="lg:text-2xl text-xl font-bold mb-4">Trips</div>
        <div className="flex flex-wrap xl:gap-4 gap-2">
          <div className="mydict flex h-10 justify-center">
            <label className="relative flex items-center">
              <input
                type="radio"
                name="granularity"
                value="day"
                checked={granularity === "day"}
                onChange={handleGranularityChange}
                className="absolute h-1 w-0 invisible opacity-0 cursor-pointer"
              />
              <span
                className={`block cursor-pointer bg-white px-3 py-1 text-center rounded-l-md transition duration-500 ease-in-out 
      ${
        granularity === "day"
          ? "bg-[#dee7ff] text-[#0043ed] shadow-md"
          : "shadow-sm text-[#3e4963]"
      } 
      hover:bg-[#f0f4ff]`}
              >
                Day
              </span>
            </label>
            <label className="relative flex items-center">
              <input
                type="radio"
                name="granularity"
                value="month"
                checked={granularity === "month"}
                onChange={handleGranularityChange}
                className="absolute h-1 w-1 invisible opacity-0 cursor-pointer"
              />
              <span
                className={`block cursor-pointer bg-white px-3 py-1 text-center 
      ${
        granularity === "month"
          ? "bg-[#dee7ff] text-[#0043ed] shadow-md"
          : "shadow-sm text-[#3e4963]"
      } 
      hover:bg-[#f0f4ff]`}
              >
                Month
              </span>
            </label>
            <label className="relative flex items-center">
              <input
                type="radio"
                name="granularity"
                value="year"
                disabled={year !== ""}
                checked={granularity === "year"}
                onChange={handleGranularityChange}
                className="absolute h-1 w-1 invisible opacity-0 cursor-pointer"
              />
              <span
                className={`block cursor-pointer bg-white px-3 py-1 text-center rounded-r-md transition duration-500 ease-in-out 
      ${
        granularity === "year"
          ? "bg-[#dee7ff] text-[#0043ed] shadow-md"
          : "shadow-sm text-[#3e4963]"
      } 
      hover:bg-[#f0f4ff]`}
              >
                Year
              </span>
            </label>
          </div>

          <select
            id="truckId"
            name="truckId"
            value={truck}
            onChange={(e) => setTruck(e.target.value)}
            className="border border-gray-200 h-fit text-xs min-h-fit w-fit"
            placeholder="Truck Number"
          >
            <option value="">Select Truck</option>
            {truckData?.map((item, index) => (
              <option key={index} value={item._id}>
                {item.truckNumber}
              </option>
            ))}
          </select>
          <select
            id="truckId"
            name="truckId"
            value={client}
            onChange={(e) => setClient(e.target.value)}
            className="border border-gray-200 h-fit text-xs min-h-fit w-fit"
            placeholder="Truck Number"
          >
            <option value="">Select Client</option>
            {clientData?.map((item, index) => (
              <option key={index} value={item._id}>
                {item?.name} ({item?.ownerName})
              </option>
            ))}
          </select>
          <div>
            <select
              name=""
              id=""
              value={year}
              className="border border-gray-200 w-fit text-xs min-h-fit"
              onChange={(e) => setYear(e.target.value)}
            >
              <option value="">All</option>
              <option value="2024">2024</option>
              <option value="2023">2023</option>
              <option value="2022">2022</option>
            </select>
          </div>
        </div>
      </div>
      <div className="text-3xl font-bold mb-4">
        {getCompanyDashboardTripGraph.tripCount}
        <label> Total Trips</label>
      </div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={250}
      />
    </div>
  );
}

export default Tripgraph;
