import { getCompanyEmployeeDetailsIdAction } from "../../../redux/company/employee/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import EmployeeCard from "./employeeCard";
import PersonalDetails from "./personalDetails";
import Backdrop from "../../backdrop";
import FamilyDetails from "./familyDetails";
import toast from "react-hot-toast";
import EmployeeListCard from "./employeeListCard";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../ui/tabs";

function EmployeeDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();

  const {
    getCompanyEmployeeDetailsId,
    getCompanyEmployeeDetailsIdLoading,
    getCompanyEmployeeDetailsIdError,
  } = useSelector((state) => state.CompanyEmployeesection);
  const [data, setData] = useState();
  const [dropdown, setDropdown] = useState("details");

  useEffect(() => {
    dispatch(getCompanyEmployeeDetailsIdAction(id));
  }, [id]);

  useEffect(() => {
    if (getCompanyEmployeeDetailsId.status === 200) {
      setData(getCompanyEmployeeDetailsId.data);
    }
  }, [getCompanyEmployeeDetailsId]);
  useEffect(() => {
    if (
      getCompanyEmployeeDetailsIdError.status === 400 ||
      getCompanyEmployeeDetailsIdError.status === 404
    ) {
      toast.error(getCompanyEmployeeDetailsIdError.message);
    } else if (getCompanyEmployeeDetailsIdError.status === 500) {
      toast.error(getCompanyEmployeeDetailsIdError.error);
    }
  }, [getCompanyEmployeeDetailsIdError]);

  const handleTab = (value) => {
    setDropdown(value);
    searchParams.set("tab", value);
    setSearchParams(searchParams);
  };

  // Dropdown data for small screens
  const dropdownOptions = [
    { value: "details", label: "Details" },
    { value: "family details", label: "Family Details" },
    // { value: "salary", label: "Salary" },
    // { value: "attendence", label: "Attendence" },
  ].filter(Boolean); // Filter out any false values

  const handleDropdownChange = (event) => {
    handleTab(event.target.value);
  };
  return (
    <div>
      {getCompanyEmployeeDetailsIdLoading && <Backdrop />}

      <EmployeeListCard data={data?.companyEmployees} isDetails={true} />
      <div className="block md:hidden mb-5">
        <select
          onChange={handleDropdownChange}
          value={searchParams.get("tab") || "All"}
          className="primarySelect w-full"
        >
          {dropdownOptions.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      {/* Tabs for larger screens */}
      <Tabs
        defaultValue={searchParams.get("tab") || dropdown}
        value={searchParams.get("tab") || dropdown}
        className="mt-4"
      >
        <TabsList className="hidden w-fit md:block">
          <TabsTrigger onClick={() => handleTab("details")} value="details">
            Details
          </TabsTrigger>
          <TabsTrigger
            onClick={() => handleTab("family details")}
            value="family details"
          >
            Family Details
          </TabsTrigger>
          {/* <TabsTrigger onClick={() => handleTab("salary")} value="salary">
            Salary
          </TabsTrigger> */}
          {/* <TabsTrigger onClick={() => handleTab("attendence")} value="attendence">
          Attendence
          </TabsTrigger> */}
        </TabsList>
        <TabsContent value="details">
          <PersonalDetails data={data?.companyEmployees} />
        </TabsContent>

        <TabsContent value="family details">
          <FamilyDetails data={data?.family} />
        </TabsContent>
        <TabsContent value="salary"></TabsContent>
      </Tabs>
    </div>
  );
}

export default EmployeeDetails;
