import { getClientAction } from "../../../redux/company/client/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Backdrop from "../../backdrop";
import ClientListCard from "./clientListCard";
import ClientList from "./clientList";

function Client() {
    const dispatch = useDispatch();
    const { getClient, getClientLoading } = useSelector(
      (state) => state.Clientsection
    );
    const [data, setData] = useState([]);
    useEffect(() => {
      dispatch(getClientAction());
    }, [dispatch]);
    useEffect(() => {
      if (getClient?.status === 200) {
        setData(getClient?.data);
      }
    }, [getClient]);
  return (
    <div >
      {getClientLoading && <Backdrop />}
      <div className="flex justify-end">
        <Link to={"addClient"} className="primaryButton w-fit">
          Add Client
        </Link>
      </div>
      {data?.length > 0 && <ClientList data={data} />}
    </div>
  );
}

export default Client;
