import {
    GET_SALARY,
    GET_SALARY_SUCCESS,
    GET_SALARY_ERROR,
    GET_SALARY_BY_USER,
    GET_SALARY_BY_USER_SUCCESS,
    GET_SALARY_BY_USER_ERROR,
    GET_SALARY_BY_ID,
    GET_SALARY_BY_ID_SUCCESS,
    GET_SALARY_BY_ID_ERROR,
    POST_SALARY,
    POST_SALARY_SUCCESS,
    POST_SALARY_ERROR,
    PATCH_SALARY,
    PATCH_SALARY_SUCCESS,
    PATCH_SALARY_ERROR,
    DELETE_SALARY,
    DELETE_SALARY_SUCCESS,
    DELETE_SALARY_ERROR,
  } from "../../../actions";
  
  export const getSalaryAction = () => ({
    type: GET_SALARY,
  });
  
  export const getSalarySuccess = (data) => ({
    type: GET_SALARY_SUCCESS,
    payload: data,
  });
  
  export const getSalaryError = (error) => ({
    type: GET_SALARY_ERROR,
    payload: error,
  });
  
  export const getSalaryByUserAction = (userId) => ({
    type: GET_SALARY_BY_USER,
    payload: userId,
  });
  
  export const getSalaryByUserSuccess = (data) => ({
    type: GET_SALARY_BY_USER_SUCCESS,
    payload: data,
  });
  
  export const getSalaryByUserError = (error) => ({
    type: GET_SALARY_BY_USER_ERROR,
    payload: error,
  });
  export const getSalaryByIdAction = (userId) => ({
    type: GET_SALARY_BY_ID,
    payload: userId,
  });
  
  export const getSalaryByIdSuccess = (data) => ({
    type: GET_SALARY_BY_ID_SUCCESS,
    payload: data,
  });
  
  export const getSalaryByIdError = (error) => ({
    type: GET_SALARY_BY_ID_ERROR,
    payload: error,
  });
  
  export const postSalaryAction = (data) => ({
    type: POST_SALARY,
    payload: data,
  });
  
  export const postSalarySuccess = (data) => ({
    type: POST_SALARY_SUCCESS,
    payload: data,
  });
  
  export const postSalaryError = (error) => ({
    type: POST_SALARY_ERROR,
    payload: error,
  });
  
  export const patchSalaryAction = (data) => ({
    type: PATCH_SALARY,
    payload:  data,
  });
  
  export const patchSalarySuccess = (data) => ({
    type: PATCH_SALARY_SUCCESS,
    payload: data,
  });
  
  export const patchSalaryError = (error) => ({
    type: PATCH_SALARY_ERROR,
    payload: error,
  });
  
  export const deleteSalaryAction = (id) => ({
    type: DELETE_SALARY,
    payload: id,
  });
  
  export const deleteSalarySuccess = (data) => ({
    type: DELETE_SALARY_SUCCESS,
    payload: data,
  });
  
  export const deleteSalaryError = (error) => ({
    type: DELETE_SALARY_ERROR,
    payload: error,
  });
  