import { getCompanyDashboardExpenseGraphAction } from "../../../redux/company/dashboard/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "react-apexcharts";

function ExpenseGraph() {
  const dispatch = useDispatch();
  const {
    getCompanyDashboardExpenseGraph,
  } = useSelector((state) => state.CompanyDashboardSection);
  const [startDate, setStartDate] = useState("");
  const [granularity, setGranularity] = useState("month");
  const [endDate, setEndDate] = useState("");
  const [year, setYear] = useState("2024");
  const [data, setData] = useState([]);
  useEffect(() => {
    if (getCompanyDashboardExpenseGraph?.status === 200) {
      // Set and sort data based on period
      const sortedData = [...getCompanyDashboardExpenseGraph.data].sort(
        (a, b) => {
          // For day-wise sorting
          if (granularity === "day") {
            return new Date(a.period) - new Date(b.period);
          }
          // For month-wise sorting (assuming period format is 'YYYY-MM')
          else if (granularity === "month") {
            return new Date(a.period + "-01") - new Date(b.period + "-01");
          }
          // For year-wise sorting
          else if (granularity === "year") {
            return a.period - b.period;
          }
          return 0; // Fallback
        }
      );

      setData(sortedData);
    }
  }, [getCompanyDashboardExpenseGraph]);

  // Load truck data when component mounts or state changes
  useEffect(() => {
    initialLoad(granularity, startDate, endDate, year);
  }, [granularity, startDate, endDate, year]); // Add dependencies to re-run on changes

  const initialLoad = (
    granularityValue,
    startDateValue,
    endDateValue,
    yearValue
  ) => {
    const queryParams = {
      granularity: granularityValue,
      startDate: startDateValue,
      endDate: endDateValue,
      year: yearValue,
    };

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    // Dispatch action to get truck count
    dispatch(getCompanyDashboardExpenseGraphAction(queryString));
  };

  const handleGranularityChange = (e) => {
    setGranularity(e.target.value); // Update granularity based on selected radio button
  };
  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 2,
        }).format(amount)
      : "₹0.00";

  const chartData = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: data?.map((item) => item.period),
      },
      stroke: {
        curve: "smooth",
      },
      colors: ["#FF9800", "#2E93fA"],
    },

    series: [
      {
        name: "Total Cost",
        data: data?.map((item) => item.total.toFixed(0)),
      },
    ],
  };
  console.log(granularity === "month");
  return (
    <div className="bg-white p-4 h-fit col-span-2 order-1 ">
      <div className="flex flex-col xl:flex-row justify-between">
        <div className="lg:text-2xl text-xl font-bold mb-4">Expenses</div>
        <div className="flex flex-wrap xl:gap-4 gap-2">
          <div className="mydict flex h-10 ml-auto justify-center ">
            <label className="relative flex items-center">
              <input
                type="radio"
                name="granularity"
                value="day"
                checked={granularity === "day"}
                onChange={handleGranularityChange}
                className="absolute h-1 w-1 opacity-0 cursor-pointer"
              />
              <span
                className={`block cursor-pointer bg-white px-3 py-1 text-center rounded-l-md transition duration-500 ease-in-out 
      ${
        granularity === "day"
          ? "bg-[#dee7ff] text-[#0043ed] shadow-md"
          : "shadow-sm text-[#3e4963]"
      } 
      hover:bg-[#f0f4ff]`}
              >
                Day
              </span>
            </label>
            <label className="relative flex items-center">
              <input
                type="radio"
                name="granularity"
                value="month"
                checked={granularity === "month"}
                onChange={handleGranularityChange}
                className="absolute h-1 w-1 opacity-0 cursor-pointer"
              />
              <span
                className={`block cursor-pointer bg-white px-3 py-1 text-center 
      ${
        granularity === "month"
          ? "bg-[#dee7ff] text-[#0043ed] shadow-md"
          : "shadow-sm text-[#3e4963]"
      } 
      hover:bg-[#f0f4ff]`}
              >
                Month
              </span>
            </label>
            <label className="relative flex items-center">
              <input
                type="radio"
                name="granularity"
                value="year"
                disabled={year !== ""}
                checked={granularity === "year"}
                onChange={handleGranularityChange}
                className="absolute h-1 w-1 opacity-0 cursor-pointer"
              />
              <span
                className={`block cursor-pointer bg-white px-3 py-1 text-center rounded-r-md transition duration-500 ease-in-out 
      ${
        granularity === "year"
          ? "bg-[#dee7ff] text-[#0043ed] shadow-md"
          : "shadow-sm text-[#3e4963]"
      } 
      hover:bg-[#f0f4ff]`}
              >
                Year
              </span>
            </label>
          </div>

          <div className="ml-auto">
            <select
              name=""
              id=""
              value={year}
              className="border border-gray-200 h-fit w-fit text-xs min-h-fit"
              onChange={(e) => setYear(e.target.value)}
            >
              <option value="">All</option>
              <option value="2024">2024</option>
              <option value="2023">2023</option>
              <option value="2022">2022</option>
            </select>
          </div>
        </div>
      </div>
      <div className="text-3xl font-bold mb-4">
        {formatAmount(getCompanyDashboardExpenseGraph.totalExpenses)}
        <label> Total Expense</label>
      </div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={250}
      />
    </div>
  );
}

export default ExpenseGraph;
