import { useNavigate } from "react-router-dom";
import { DialogContent, DialogFooter } from "../../../ui/dialog";
import React from "react";
import image from "../../../assets/images/reminder.png"

function AlertInsuranceModal({ data, setShowModal }) {
  // Extract truck numbers from data (assuming `data` is an array of truck objects)
  const truckNumbers = data?.map((truck) => truck.truckNumber); // Adjust the field according to your data structure

  // Create a display for truck numbers, showing up to 3, and adding "..." if there are more
  const displayTruckNumbers =
    truckNumbers?.length > 3
      ? `${truckNumbers?.slice(0, 3)?.join(", ")}...` // Show first 3 and append "..."
      : truckNumbers?.join(", "); // Show all if 3 or fewer
      const navigate = useNavigate()
      const naviagteHandler = () => {
        navigate("/company/truck?tab=insurance")
        setShowModal(false)
      }
  return (
    <DialogContent className="bg-gray-100">
      <div className="flex flex-col text-center text-[#303750] gap-4">
      <img src={image} alt="" className="h-20 w-20 mx-auto" />
      <div className="text-xl font-black ">Renew your Insurance!!</div>
        <div className="font-normal">
          Your Truck insurance is getting expired in 7 days.
        </div>
        <div className="font-medium w-1/2 mx-auto text-[#303750]">
          Please renew your insurance before the last date.
        </div>

        <div className="font-medium w-1/2 mx-auto text-[#303750]">
          {/* Display the truck numbers */}
          <span className="font-bold">Truck(s):</span> <div className="uppercase text-red-400">
             {displayTruckNumbers}
            </div>
        </div>

      </div>
      <DialogFooter>
        <button
          className="ghostButton"
          onClick={() => setShowModal(false)}
          type="button"
        >
          Ignore
        </button>
        <button className="primaryButton w-fit" type="submit" onClick={()=>naviagteHandler()}>
          Renew Now
        </button>
      </DialogFooter>
    </DialogContent>
  );
}

export default AlertInsuranceModal;
