import React, { useState } from "react";
import { Link } from "react-router-dom";
import SubscriptionDelete from "./subscriptionDelete";
import { Dialog } from "../../../ui/dialog";

function SubscriptionListCard({ data }) {
    const [showModal, setShowModal] = useState(false)
  return (
    <div
      key={data?._id}
      className="my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl"
    >
      <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between self-center">
        <div className="col-span-2 md:col-span-4 text-xl text-blue-600 font-semibold">
          {data?.name}
        </div>
        <div className="md:text-left">
          <h3 className="textgrey text-xs">Subscription Months</h3>
          <p>{data?.months}</p>
        </div>
        <div className="md:text-left">
          <h3 className="textgrey text-xs">Cost Per Month</h3>
          <p>{data?.costPerMonth}</p>
        </div>
        <div className="md:text-left">
          <h3 className="textgrey text-xs">Default Cost</h3>
          <p>{data?.defaultCost}</p>
        </div>
        {data?.isDiscount && (
          <>
            <div className="md:text-left">
              <h3 className="textgrey text-xs">Discount Name</h3>
              <p>{data.discountName}</p>
            </div>
            <div className="md:text-left">
              <h3 className="textgrey text-xs">Discount Percent</h3>
              <p>{data?.discountPercent}%</p>
            </div>
          </>
        )}
        <div className="md:text-left">
          <h3 className="textgrey text-xs">Final Cost</h3>
          <p>{data?.finalCost}</p>
        </div>
        {/* <div> */}
          <Link
            to={`addSubscription?id=${data?._id}`}
            className="primaryButton bg-yellow-600 hover:bg-yellow-800 text-center"
          >
            Edit Subscription
          </Link>
        {/* </div> */}
        <div>
        <Dialog open={showModal}>
            <button
              onClick={() => setShowModal(true)}
              className={`primaryButton bg-red-500 hover:bg-red-800 `}
            >
              Delete
            </button>
            <SubscriptionDelete id={data._id} setShowModal={setShowModal} />
            </Dialog>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionListCard;
