import moment from "moment/moment";
import React, { useState } from "react";
import { FcImageFile } from "react-icons/fc";
import ImagePopUpDownload from "../../imagePopUpDownload";
import { Dialog } from "../../../ui/dialog";

function TruckTabDetails({ data }) {
  const [showImageInsuranceModal, setShowImageInsuranceModal] = useState(false);
  const [showImageFitnessModal, setShowImageFitnessModal] = useState(false);
  const [showImagePermitModal, setShowImagePermitModal] = useState(false);
  const date = moment(data?.subscribeTill).diff(moment(new Date()), "days");
  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(amount)
      : "₹0.00";
  return (
    <div className="bg-white p-4 rounded-md shadow-lg grid xl:grid-cols-6 lg:grid-cols-4 grid-cols-2 xl:grid-rows-3 lg:grid-rows-10 lg:gap-x-20 gap-x-9  lg:gap-y-10 gap-y-4 w-full text-xs">
      <div className="lg:border-r lg:border-r-[#F1F2F6]  lg:border-b-0 border-b border-b-[#F1F2F6] row-span-full flex flex-col gap-2 lg:col-span-1 col-span-2 pb-5 lg:text-left text-center  ">
        <label htmlFor="" className="">
          Truck Number
        </label>
        <p className=" textPrimary uppercase ">{data?.truckNumber}</p>
        <div
          className={`flex w-full md:justify-start justify-center gap-2  ${
            data.isSubscribe ? "text-[#15803D] " : "text-[#DC1919]"
          }`}
        >
          <p className="">
            <div
              className={`px-1.5 py-0.5 rounded-full border  text-[10px] font-medium ${
                data.isSubscribe ? "border-[#15803D] " : "border-[#DC1919]"
              }`}
            >
              {data?.isSubscribe ? "Subscribed" : "UnSubscribed"}
            </div>
          </p>
          <p className="text-[10px] my-auto font-medium">
            {moment(data?.subscribeTill).diff(moment(new Date()), "days")} Days
            Left
          </p>
        </div>
        {data.isSell && (
          <div
            className={`flex gap-2 w-full md:justify-start justify-center ${
              !data.isSell ? "text-[#15803D] " : "text-[#DC1919]"
            }`}
          >
            <p>
              <div
                className={`px-1.5 py-0.5 rounded-full border  text-[10px] font-medium ${
                  !data.isSell
                    ? "border-[#15803D] text-[#15803D] "
                    : "border-[#DC1919] text-[#DC1919]"
                }`}
              >
                Sold
              </div>
            </p>
            <p className="text-[10px] my-auto font-medium">
              {moment(data?.sellDate).format("DD / MMM / YYYY")}{" "}
            </p>
          </div>
        )}
      </div>
      <div className="">
        <label htmlFor="">Truck Company Name</label>
        <p className="textSecondary">{data?.truckCompanyName}</p>
      </div>
      <div className="">
        <label htmlFor="">Truck Owner</label>
        <p className="textSecondary">{data?.truckOwnByName}</p>
      </div>
      <div className=" ">
        <label htmlFor="">Truck Chassis Number</label>
        <p className="textSecondary">{data?.chasisNumber}</p>
      </div>
      <div className=" ">
        <label htmlFor="">Truck Model</label>
        <p className="textSecondary">{data?.truckModelName}</p>
      </div>
      <div className=" ">
        <label htmlFor="">Truck Type</label>
        <p className="textSecondary">{data?.typeOfTruck}</p>
      </div>
      <div className=" ">
        <label htmlFor="">Truck Used For</label>
        <p className="textSecondary">{data?.usedfor}</p>
      </div>
      <div className=" ">
        <label htmlFor="">Truck Cost</label>
        <p className="textSecondary">{formatAmount(data?.totalCost)}</p>
      </div>
      <div className=" ">
        <label htmlFor="">Purchase Date</label>
        <p className="textSecondary">
          {moment(data?.purchaseDate).format("DD / MMM / YYYY")}
        </p>
      </div>
   
      <div className=" lg:col-start-2 col-start-1 ">
        <label htmlFor="">Subscritption End Date</label>
        <p className="textSecondary">
          {moment(data?.subscribeTill).format("DD / MMM / YYYY")}
        </p>
      </div>
      <div className=" ">
        <label htmlFor="">Subscritption Expired In</label>
        <p className="textGreen">{date} Days Left</p>
      </div>
      {data?.isSell !== true && (
        <>
          <div className="lg:col-start-2 col-start-1">
            <label htmlFor="">Insurance Number</label>
            <p className="textSecondary">
              {data?.truckId?.isInsurance
                ? data?.truckId?.insuranceId?.insuranceNumber
                : "--"}
            </p>
          </div>
          <div>
            <label htmlFor="">Insurance Till</label>
            <p className="textSecondary">
              {data?.truckId?.isInsurance
                ? moment(data?.truckId?.insuranceId?.insuranceTill).format(
                    "DD / MMM / YYYY"
                  )
                : "--"}
            </p>
          </div>
          <div>
            <label htmlFor="">Insurance Amount</label>
            <p className="textSecondary">
              {data?.truckId?.isInsurance
                ? formatAmount(data?.truckId?.insuranceId?.insuranceCost)
                : "--"}
            </p>
          </div>
          <div>
            <label htmlFor="">Insurance Company Name</label>
            <p className="textSecondary">
              {data?.truckId?.isInsurance
                ? data?.truckId?.insuranceId?.insuranceCompanyName
                : "--"}
            </p>
          </div>
          <button
            type="button"
            onClick={() => setShowImageInsuranceModal(true)}
            className="ghostButton font-semibold flex gap-1 my-auto items-center "
          >
            <FcImageFile className="text-2xl" /> Insurance Image
          </button>
          {data?.truckId?.insuranceId?.insuranceImage && (
            <Dialog open={showImageInsuranceModal}>
              <ImagePopUpDownload
                isArray={true}
                image={data?.truckId?.insuranceId?.insuranceImage}
                setShowModal={setShowImageInsuranceModal}
                imagename={`insurance_${data?.truckId?.insuranceId?._id}`}
              />
            </Dialog>
          )}
          <div className="lg:col-start-2 col-start-1">
            <label htmlFor="">Fitness Number</label>
            <p className="textSecondary">
              {data?.truckId?.isFitness
                ? data?.truckId?.fitnessId?.fitnessNumber
                : "--"}
            </p>
          </div>
          <div>
            <label htmlFor="">Fitness Till</label>
            <p className="textSecondary">
              {data?.truckId?.isFitness
                ? moment(data?.truckId?.fitnessId?.fitnessTill).format(
                    "DD / MMM / YYYY"
                  )
                : "--"}
            </p>
          </div>
          <div>
            <label htmlFor="">Fitness Amount</label>
            <p className="textSecondary">
              {data?.truckId?.isFitness
                ? formatAmount(data?.truckId?.fitnessId?.fitnessCost)
                : "--"}
            </p>
          </div>
          <button
            type="button"
            onClick={() => setShowImageFitnessModal(true)}
            className="ghostButton font-semibold flex gap-1 my-auto items-center xl:col-start-6 lg:col-start-4 col-start-2 "
          >
            <FcImageFile className="text-2xl" /> Fitness Image
          </button>
          {data?.truckId?.fitnessId?.fitnessImage && (
            <Dialog open={showImageFitnessModal}>
              <ImagePopUpDownload
                isArray={true}
                image={data?.truckId?.fitnessId?.fitnessImage}
                setShowModal={setShowImageFitnessModal}
                imagename={`insurance_${data?.truckId?.fitnessId?._id}`}
              />
            </Dialog>
          )}
          <div className="lg:col-start-2 col-start-1">
            <label htmlFor="">Permit Number</label>
            <p className="textSecondary">
              {data?.truckId?.isPermit
                ? data?.truckId?.permitId?.permitNumber
                : "--"}
            </p>
          </div>
          <div>
            <label htmlFor="">Permit Till</label>
            <p className="textSecondary">
              {data?.truckId?.isPermit
                ? moment(data?.truckId?.permitId?.permitTill).format(
                    "DD / MMM / YYYY"
                  )
                : "--"}
            </p>
          </div>
          <div>
            <label htmlFor="">Permit Amount</label>
            <p className="textSecondary">
              {data?.truckId?.isPermit
                ? formatAmount(data?.truckId?.permitId?.permitCost)
                : "--"}
            </p>
          </div>
          <button
            type="button"
            onClick={() => setShowImagePermitModal(true)}
            className="ghostButton font-semibold flex gap-1 my-auto items-center xl:col-start-6 lg:col-start-4 col-start-2 "
          >
            <FcImageFile className="text-2xl" /> Permit Image
          </button>
          {data?.truckId?.permitId?.permitImage && (
            <Dialog open={showImagePermitModal}>
              <ImagePopUpDownload
                isArray={true}
                image={data?.truckId?.permitId?.permitImage}
                setShowModal={setShowImagePermitModal}
                imagename={`insurance_${data?.truckId?.permitId?._id}`}
              />
            </Dialog>
          )}
        </>
      )}
    </div>
  );
}

export default TruckTabDetails;
