import {
  getCompanyEmployeeDetailsAction,
  getEmployeeExpireLicenseAction,
} from "../../../redux/company/employee/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "../../backdrop";
import { useNavigate, useSearchParams } from "react-router-dom";
import EmployeeListCard from "./employeeListCard";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../ui/tabs";

function EmployeeList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    getCompanyEmployeeDetails,
    getCompanyEmployeeDetailsLoading,
    getCompanyEmployeeDetailsError,
    getEmployeeExpireLicense,
  } = useSelector((state) => state.CompanyEmployeesection);

  const [data, setData] = useState([]);
  const [licenseData, setLicenseData] = useState([]);
  const [dropdown, setDropdown] = useState("All");

  useEffect(() => {
    dispatch(getCompanyEmployeeDetailsAction());
    dispatch(getEmployeeExpireLicenseAction());
  }, []);

  useEffect(() => {
    if (getCompanyEmployeeDetails.status === 200) {
      const sortData = getCompanyEmployeeDetails?.data?.sort(
        (a, b) => b.isWorking - a.isWorking
      );
      setData(sortData);
    }
  }, [getCompanyEmployeeDetails]);
  useEffect(() => {
    if (
      getEmployeeExpireLicense?.status === 200 &&
      getEmployeeExpireLicense?.data?.length > 0
    ) {
      setLicenseData(getEmployeeExpireLicense?.data);
    }
  }, [getEmployeeExpireLicense]);

  const handleTab = (value) => {
    setDropdown(value);
    searchParams.set("tab", value);
    setSearchParams(searchParams);
  };

  // Dropdown data for small screens
  const dropdownOptions = [
    { value: "All", label: "All Employees" },
    { value: "working", label: "Working" },
    { value: "terminated", label: "Terminated" },
    licenseData?.length > 0 && { value: "expire", label: "License Expire" },
  ].filter(Boolean); // Filter out any false values

  const handleDropdownChange = (event) => {
    handleTab(event.target.value);
  };

  return (
    <div>
      {getCompanyEmployeeDetailsLoading && <Backdrop />}
      <div className="flex justify-end gap-10">
        <button
          type="button"
          className="primaryButton w-fit flex gap-2 mb-5 "
          onClick={() => navigate("/company/employee/addEmployee")}
        >
          Add New Employee
        </button>
      </div>
      {/* Dropdown for small screens */}
      <div className="block md:hidden mb-5">
        <select
          onChange={handleDropdownChange}
          value={searchParams.get("tab") || "All"}
          className="primarySelect w-full"
        >
          {dropdownOptions.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      {/* Tabs for larger screens */}
      <Tabs
        defaultValue={searchParams.get("tab") || dropdown}
        value={searchParams.get("tab") || dropdown}
      >
        <TabsList className="hidden w-fit md:block">
          <TabsTrigger onClick={() => handleTab("All")} value="All">
            All Employees
          </TabsTrigger>
          <TabsTrigger onClick={() => handleTab("working")} value="working">
            Working
          </TabsTrigger>
          <TabsTrigger
            onClick={() => handleTab("terminated")}
            value="terminated"
          >
            Terminated
          </TabsTrigger>
          {licenseData?.length > 0 && (
            <TabsTrigger onClick={() => handleTab("expire")} value="expire">
              <div className="alert">
                License Expire
                <div className="point"></div>
              </div>
            </TabsTrigger>
          )}
        </TabsList>
        <TabsContent value="All">
          {data?.length > 0 &&
            data.map((item) => <EmployeeListCard data={item} reroute={true} />)}
        </TabsContent>
        <TabsContent value="working">
          {data?.length > 0 &&
            data
              .filter((item) => item.isWorking === true)
              .map((item) => <EmployeeListCard data={item} reroute={true} />)}
        </TabsContent>
        <TabsContent value="terminated">
          {data?.length > 0 &&
            data
              .filter((item) => item.isWorking === false)
              .map((item) => <EmployeeListCard data={item} reroute={true} />)}
        </TabsContent>
        <TabsContent value="expire">
          {licenseData?.length > 0 &&
            licenseData.map((item) => (
              <EmployeeListCard data={item} reroute={true} />
            ))}
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default EmployeeList;
