import { Badge } from "../../../ui/badge";
import moment from "moment";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Image from "../../../assets/images/business-man.svg";
import ImagePopUpDownload from "../../imagePopUpDownload";
import { Dialog } from "../../../ui/dialog";
import PaySalary from "./salary/paySalary";
import Terminate from "./terminate";
import LicenseUpdateModal from "./licenseUpdateModal";
import { RiIndeterminateCircleFill } from "react-icons/ri";
import { FcImageFile } from "react-icons/fc";
import { FaRegEdit } from "react-icons/fa";

function EmployeeListCard({ data, reroute = false }) {
  const navigate = useNavigate();
  console.log(data)
  const [showImageModal, setShowImageModal] = useState(false);
  const [showTerminateModal, setShowTerminateModal] = useState(false);
  const [showLicenseModal, setShowLicenseModal] = useState(false);
  return (
    <div key={data?._id}>
      <Dialog open={showTerminateModal}>
        <Terminate id={data?._id} setShowModal={setShowTerminateModal} />
      </Dialog>
      <div className="grid grid-cols-12 lg:grid-rows-2 gap-x-6 gap-y-3 mb-2  lg:p-4 p-2 bg-white rounded-lg shadow-md">
        <div className="row-span-2 lg:col-span-1 justify-center col-span-12 flex relative">
          <button
            type="button"
            className="w-fit  mx-auto flex justify-center relative"
            onClick={() =>
              reroute
                ? navigate(
                    `details/${data?._id}`
                  )
                : ""
            }
          >
            <div className="p-5 bg-[#C95050] rounded-md w-fit">
              <img src={Image} alt="" className={` h-10 w-10 `} />
            </div>
          </button>
          <div className="flex flex-col gap-5 lg:hidden ml-auto absolute z-30 left-0">
            {data?.employeeImage?.url !== "" && (
              <button
                type="button"
                onClick={() => setShowImageModal(true)}
                className="ghostButton font-semibold flex gap-1 "
              >
                <FcImageFile className="text-2xl" />
              </button>
            )}
          </div>
          <div className=" flex flex-col gap-5 lg:hidden ml-auto absolute z-30 right-0">
            <button
              type="button"
              className="ghostButton font-semibold ml-auto flex gap-1"
              onClick={() =>
                navigate(
                  `/company/employee/addEmployee?employeeId=${data?._id}`
                )
              }
            >
              <FaRegEdit className="text-xl" />
            </button>
          </div>
        </div>
        {data?.employeeImage?.url !== "" && (
          <Dialog open={showImageModal}>
            <ImagePopUpDownload
              image={data?.employeeImage}
              setShowModal={setShowImageModal}
              imagename={`token_${data?._id}`}
            />
          </Dialog>
        )}
        <div className="lg:col-span-2 col-span-12 flex flex-col gap-1 row-span-2">
          <button
            type="button"
            className="textPrimary lg:text-left text-center w-fit lg:mx-0 mx-auto "
            onClick={
              reroute ? () => navigate(`details?truckId=${data?._id}`) : null
            }
          >
            {data?.firstName} {data?.lastName}
          </button>
          <p className="textSecondary mt-0 lg:text-left text-center capitalize">
            {data?.designation} , (
            {moment(data?.joiningDate).format("DD/MMM/YYYY")} -{" "}
            {data?.leavingDate
              ? moment(data?.leavingDate).format("DD/MMM/YYYY")
              : "Present"}
            )
          </p>
          <div
            className={`flex w-full lg:justify-start justify-center gap-2  ${
              data?.isWorking ? "text-[#15803D] " : "text-[#DC1919]"
            }`}
          >
            <p className="">
              <div
                className={`px-1.5 py-0.5 rounded-full border  text-[10px] font-medium ${
                  data?.isWorking ? "border-[#15803D] " : "border-[#DC1919]"
                }`}
              >
                {data?.isWorking ? "Working" : "Not Working"}
              </div>
            </p>
          </div>
        </div>
        <div className="text-xs text-[#64709B] lg:col-span-2 col-span-6">
          <h3 className=" ">User Name</h3>
          <p className="textSecondary">{data?.username}</p>
        </div>
        <div className="text-xs text-[#64709B] lg:col-span-2 col-span-6">
          <h3 className=" ">Phone Number</h3>
          <p className="textSecondary">{data?.phoneNumber}</p>
        </div>
        <div className="text-xs text-[#64709B] lg:col-span-1 col-span-6">
          <h3 className=" ">Blood Group</h3>
          <p className="textSecondary">{data?.bloodGroup}</p>
        </div>
        <div className="text-xs text-[#64709B] lg:col-span-2 col-span-12">
          <h3 className=" ">Salary</h3>
          <p className="textSecondary">{data?.salary}</p>
        </div>
        <div className="lg:flex gap-5 hidden ml-auto ">
            {data?.employeeImage?.url !== "" && (
              <button
                type="button"
                onClick={() => setShowImageModal(true)}
                className="ghostButton font-semibold flex gap-1 "
              >
                <FcImageFile className="text-2xl" /> Image
              </button>
            )}
            <button
              type="button"
              className="ghostButton font-semibold ml-auto flex gap-1"
              onClick={() =>
                navigate(
                   `/company/employee/addEmployee?employeeId=${data?._id}`
                )
              }
            >
              <FaRegEdit className="text-xl" /> Edit
            </button>
          </div>
        
        <div className="text-xs text-[#64709B] lg:col-span-2 col-span-6">
          <h3 className=" ">Employee Type</h3>
          <p className="textSecondary">{data?.employeeType}</p>
        </div>
        {!data?.isWorking &&
        <div className="text-xs text-[#64709B] lg:col-span-2 col-span-6">
          <h3 className=" ">Last Working Date</h3>
          <p className="textSecondary">{moment(data?.leavingDate).format("DD/MMM/YYYY")}</p>
        </div>
        }

        <div className=" mt-auto lg:col-start-11 col-start-1 lg:col-span-2 col-span-12 ml-auto w-full lg:row-start-2">
          <button
            type="button"
            className="primaryButton text-sm w-full"
            onClick={() =>
              navigate(
                `salary?employeeId=${data?._id}&isWorking=${data?.isWorking}`
              )
            }
          >
            Salary
          </button>
        </div>
        {data?.isWorking && (
          <>
            <div className="mt-auto lg:col-start-7 col-start-1 lg:col-span-2 col-span-12 ml-auto w-full lg:row-start-2">
              <Dialog open={showTerminateModal}>
                <button
                  onClick={() => setShowTerminateModal(true)}
                  className="secondaryButton text-sm w-full hover:bg-red-600 hover:text-white"
                >
                  Terminate Employee
                </button>
              </Dialog>
            </div>
            <div className="mt-auto lg:col-start-9 col-start-1 lg:col-span-2 col-span-12 ml-auto w-full lg:row-start-2">
              <Dialog open={showLicenseModal}>
                <button
                  onClick={() => setShowLicenseModal(true)}
                  className="secondaryButton text-sm w-full"
                >
                  Update License
                </button>
                <LicenseUpdateModal
                  id={data?._id}
                  setShowModal={setShowLicenseModal}
                />
              </Dialog>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default EmployeeListCard;
