const {
  GET_COMPANY_TOLLTAX,
  GET_COMPANY_TOLLTAX_SUCCESS,
  GET_COMPANY_TOLLTAX_ERROR,
  GET_COMPANY_TOLLTAX_ID,
  GET_COMPANY_TOLLTAX_ID_SUCCESS,
  GET_COMPANY_TOLLTAX_ID_ERROR,
  POST_COMPANY_TOLLTAX,
  POST_COMPANY_TOLLTAX_SUCCESS,
  POST_COMPANY_TOLLTAX_ERROR,
  PATCH_COMPANY_TOLLTAX,
  PATCH_COMPANY_TOLLTAX_SUCCESS,
  PATCH_COMPANY_TOLLTAX_ERROR,
  DELETE_COMPANY_TOLLTAX,
  DELETE_COMPANY_TOLLTAX_SUCCESS,
  DELETE_COMPANY_TOLLTAX_ERROR,
  GET_COMPANY_TOLLTAX_FILTER,
  GET_COMPANY_TOLLTAX_FILTER_SUCCESS,
  GET_COMPANY_TOLLTAX_FILTER_ERROR,
} = require("../../actions");

const INIT_STATE = {
  getCompanyTollTax: {},
  getCompanyTollTaxLoading: false,
  getCompanyTollTaxError: "",
  getCompanyTollTaxId: {},
  getCompanyTollTaxIdLoading: false,
  getCompanyTollTaxIdError: "",
  getCompanyTollTaxFilter: {},
  getCompanyTollTaxFilterLoading: false,
  getCompanyTollTaxFilterError: "",
  postCompanyTollTax: {},
  postCompanyTollTaxLoading: false,
  postCompanyTollTaxError: "",
  patchCompanyTollTax: {},
  patchCompanyTollTaxLoading: false,
  patchCompanyTollTaxError: "",
  deleteCompanyTollTax: {},
  deleteCompanyTollTaxLoading: false,
  deleteCompanyTollTaxError: "",
};

const TollTaxsection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_TOLLTAX:
      return {
        ...state,
        getCompanyTollTax: {},
        getCompanyTollTaxLoading: true,
      };
    case GET_COMPANY_TOLLTAX_SUCCESS:
      return {
        ...state,
        getCompanyTollTax: action.payload,
        getCompanyTollTaxLoading: false,
        getCompanyTollTaxError: "",
      };

    case GET_COMPANY_TOLLTAX_ERROR:
      return {
        ...state,
        getCompanyTollTaxLoading: false,
        getCompanyTollTaxError: action.payload,
      };
    case GET_COMPANY_TOLLTAX_ID:
      return {
        ...state,
        getCompanyTollTaxId: {},
        getCompanyTollTaxIdLoading: true,
      };
    case GET_COMPANY_TOLLTAX_ID_SUCCESS:
      return {
        ...state,
        getCompanyTollTaxId: action.payload,
        getCompanyTollTaxIdLoading: false,
        getCompanyTollTaxIdError: "",
      };

    case GET_COMPANY_TOLLTAX_ID_ERROR:
      return {
        ...state,
        getCompanyTollTaxIdLoading: false,
        getCompanyTollTaxIdError: action.payload,
      };
      case GET_COMPANY_TOLLTAX_FILTER:
      return {
        ...state,
        getCompanyTollTaxFilter: {},
        getCompanyTollTaxFilterLoading: true,
      };
    case GET_COMPANY_TOLLTAX_FILTER_SUCCESS:
      return {
        ...state,
        getCompanyTollTaxFilter: action.payload,
        getCompanyTollTaxFilterLoading: false,
        getCompanyTollTaxFilterError: "",
      };

    case GET_COMPANY_TOLLTAX_FILTER_ERROR:
      return {
        ...state,
        getCompanyTollTaxFilterLoading: false,
        getCompanyTollTaxFilterError: action.payload,
      };
      case POST_COMPANY_TOLLTAX:
      return {
        ...state,
        postCompanyTollTax: {},
        postCompanyTollTaxLoading: true,
      };
    case POST_COMPANY_TOLLTAX_SUCCESS:
      return {
        ...state,
        postCompanyTollTax: action.payload,
        postCompanyTollTaxLoading: false,
        postCompanyTollTaxError: "",
      };

    case POST_COMPANY_TOLLTAX_ERROR:
      return {
        ...state,
        postCompanyTollTaxLoading: false,
        postCompanyTollTaxError: action.payload,
      };
    case PATCH_COMPANY_TOLLTAX:
      return {
        ...state,
        patchCompanyTollTax: {},
        patchCompanyTollTaxLoading: true,
      };
    case PATCH_COMPANY_TOLLTAX_SUCCESS:
      return {
        ...state,
        patchCompanyTollTax: action.payload,
        patchCompanyTollTaxLoading: false,
        patchCompanyTollTaxError: "",
      };

    case PATCH_COMPANY_TOLLTAX_ERROR:
      return {
        ...state,
        patchCompanyTollTaxLoading: false,
        patchCompanyTollTaxError: action.payload,
      };
    case DELETE_COMPANY_TOLLTAX:
      return {
        ...state,
        deleteCompanyTollTax: {},
        deleteCompanyTollTaxLoading: true,
      };
    case DELETE_COMPANY_TOLLTAX_SUCCESS:
      return {
        ...state,
        deleteCompanyTollTax: action.payload,
        deleteCompanyTollTaxLoading: false,
        deleteCompanyTollTaxError: "",
      };

    case DELETE_COMPANY_TOLLTAX_ERROR:
      return {
        ...state,
        deleteCompanyTollTaxLoading: false,
        deleteCompanyTollTaxError: action.payload,
      };
    default:
      return { ...state };
  }
};

export default TollTaxsection;
