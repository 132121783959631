import { DELETE_ADMIN_DISCOUNT, DELETE_ADMIN_DISCOUNT_ERROR, DELETE_ADMIN_DISCOUNT_SUCCESS, DELETE_ADMIN_SUBSCRIPTION, DELETE_ADMIN_SUBSCRIPTION_ERROR, DELETE_ADMIN_SUBSCRIPTION_SUCCESS, GET_ADMIN_DISCOUNT_BY_ID, GET_ADMIN_DISCOUNT_BY_ID_ERROR, GET_ADMIN_DISCOUNT_BY_ID_SUCCESS, GET_ADMIN_DISCOUNT_LIST, GET_ADMIN_DISCOUNT_LIST_ERROR, GET_ADMIN_DISCOUNT_LIST_SUCCESS, GET_ADMIN_SUBSCRIPTION_BY_ID, GET_ADMIN_SUBSCRIPTION_BY_ID_ERROR, GET_ADMIN_SUBSCRIPTION_BY_ID_SUCCESS, GET_ADMIN_SUBSCRIPTION_LIST, GET_ADMIN_SUBSCRIPTION_LIST_ERROR, GET_ADMIN_SUBSCRIPTION_LIST_SUCCESS, PATCH_ADMIN_DISCOUNT, PATCH_ADMIN_DISCOUNT_ERROR, PATCH_ADMIN_DISCOUNT_SUCCESS, PATCH_ADMIN_SUBSCRIPTION, PATCH_ADMIN_SUBSCRIPTION_ERROR, PATCH_ADMIN_SUBSCRIPTION_SUCCESS, POST_ADMIN_DISCOUNT, POST_ADMIN_DISCOUNT_ERROR, POST_ADMIN_DISCOUNT_SUCCESS, POST_ADMIN_SUBSCRIPTION, POST_ADMIN_SUBSCRIPTION_ERROR, POST_ADMIN_SUBSCRIPTION_SUCCESS } from "../../actions";

export const getAdminSubscriptionListAction = (info) => ({
    type: GET_ADMIN_SUBSCRIPTION_LIST,
    payload: info,
  });
  export const getAdminSubscriptionListSuccess = (info) => ({
    type: GET_ADMIN_SUBSCRIPTION_LIST_SUCCESS,
    payload: info,
  });
  export const getAdminSubscriptionListError = (error) => ({
    type: GET_ADMIN_SUBSCRIPTION_LIST_ERROR,
    payload: error,
  });
  export const getAdminDiscountListAction = (info) => ({
    type: GET_ADMIN_DISCOUNT_LIST,
    payload: info,
  });
  export const getAdminDiscountListSuccess = (info) => ({
    type: GET_ADMIN_DISCOUNT_LIST_SUCCESS,
    payload: info,
  });
  export const getAdminDiscountListError = (error) => ({
    type: GET_ADMIN_DISCOUNT_LIST_ERROR,
    payload: error,
  });
  export const getAdminSubscriptionByIdAction = (info) => ({
    type: GET_ADMIN_SUBSCRIPTION_BY_ID,
    payload: info,
  });
  export const getAdminSubscriptionByIdSuccess = (info) => ({
    type: GET_ADMIN_SUBSCRIPTION_BY_ID_SUCCESS,
    payload: info,
  });
  export const getAdminSubscriptionByIdError = (error) => ({
    type: GET_ADMIN_SUBSCRIPTION_BY_ID_ERROR,
    payload: error,
  });
  export const getAdminDiscountByIdAction = (info) => ({
    type: GET_ADMIN_DISCOUNT_BY_ID,
    payload: info,
  });
  export const getAdminDiscountByIdSuccess = (info) => ({
    type: GET_ADMIN_DISCOUNT_BY_ID_SUCCESS,
    payload: info,
  });
  export const getAdminDiscountByIdError = (error) => ({
    type: GET_ADMIN_DISCOUNT_BY_ID_ERROR,
    payload: error,
  });
  export const postAdminSubscriptionAction = (info) => ({
    type: POST_ADMIN_SUBSCRIPTION,
    payload: info,
  });
  export const postAdminSubscriptionSuccess = (info) => ({
    type: POST_ADMIN_SUBSCRIPTION_SUCCESS,
    payload: info,
  });
  export const postAdminSubscriptionError = (error) => ({
    type: POST_ADMIN_SUBSCRIPTION_ERROR,
    payload: error,
  });
  export const postAdminDiscountAction = (info) => ({
    type: POST_ADMIN_DISCOUNT,
    payload: info,
  });
  export const postAdminDiscountSuccess = (info) => ({
    type: POST_ADMIN_DISCOUNT_SUCCESS,
    payload: info,
  });
  export const postAdminDiscountError = (error) => ({
    type: POST_ADMIN_DISCOUNT_ERROR,
    payload: error,
  });
  export const patchAdminSubscriptionAction = (info) => ({
    type: PATCH_ADMIN_SUBSCRIPTION,
    payload: info,
  });
  export const patchAdminSubscriptionSuccess = (info) => ({
    type: PATCH_ADMIN_SUBSCRIPTION_SUCCESS,
    payload: info,
  });
  export const patchAdminSubscriptionError = (error) => ({
    type: PATCH_ADMIN_SUBSCRIPTION_ERROR,
    payload: error,
  });
  export const patchAdminDiscountAction = (info) => ({
    type: PATCH_ADMIN_DISCOUNT,
    payload: info,
  });
  export const patchAdminDiscountSuccess = (info) => ({
    type: PATCH_ADMIN_DISCOUNT_SUCCESS,
    payload: info,
  });
  export const patchAdminDiscountError = (error) => ({
    type: PATCH_ADMIN_DISCOUNT_ERROR,
    payload: error,
  });
  export const deleteAdminSubscriptionAction = (info) => ({
    type: DELETE_ADMIN_SUBSCRIPTION,
    payload: info,
  });
  export const deleteAdminSubscriptionSuccess = (info) => ({
    type: DELETE_ADMIN_SUBSCRIPTION_SUCCESS,
    payload: info,
  });
  export const deleteAdminSubscriptionError = (error) => ({
    type: DELETE_ADMIN_SUBSCRIPTION_ERROR,
    payload: error,
  });
  export const deleteAdminDiscountAction = (info) => ({
    type: DELETE_ADMIN_DISCOUNT,
    payload: info,
  });
  export const deleteAdminDiscountSuccess = (info) => ({
    type: DELETE_ADMIN_DISCOUNT_SUCCESS,
    payload: info,
  });
  export const deleteAdminDiscountError = (error) => ({
    type: DELETE_ADMIN_DISCOUNT_ERROR,
    payload: error,
  });