import { getAdminExpensesListAction } from "../../../redux/admin/expenses/action";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ExtraExpenseListCard from "./extraExpenseListCard";

function Expenses() {
  const dispatch = useDispatch();
  const {
    getAdminExpensesList,
    getAdminExpensesListLoading,
    getAdminExpensesListError,
  } = useSelector((state) => state.AdminExpenseSection);
  const [data, setData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(getAdminExpensesListAction());
  }, []);
  useEffect(() => {
    if (getAdminExpensesList?.status === 200) {
      setData(getAdminExpensesList?.data);
    }
  }, [getAdminExpensesList]);

  useEffect(() => {
    if (getAdminExpensesListError === 400) {
      toast.error(getAdminExpensesListError?.message);
    } else if (getAdminExpensesListError === 404) {
      toast.error(getAdminExpensesListError.message);
    } else if (getAdminExpensesListError === 500) {
      toast.error(getAdminExpensesListError.error);
    }
  }, [getAdminExpensesListError]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredData = data?.filter(
    (item) =>
      item.cost.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.billNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.expenseName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div>
      <div className="flex justify-end">
        <Link to={"addExpenses"} className="primaryButton w-fit ">
          Add Expenses
        </Link>
      </div>
      <input
        placeholder="Search"
        value={searchQuery}
        onChange={handleSearch}
        className="max-w-sm shadow-md mb-2"
      />
      {filteredData?.length > 0 ? (
        filteredData?.map((item) => (
          <ExtraExpenseListCard key={item._id} data={item} reroute={true} />
        ))
      ) : (
        <p>No Extra Expense found.</p>
      )}
    </div>
  );
}

export default Expenses;
