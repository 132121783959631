import React, { useEffect, useState } from "react";
import PaginationPackage from "../../paginationPackage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import usePagination from "headless-pagination-react";
import {
  getEmiStatsAction,
} from "../../../redux/company/finance/action";
import { FiDownload } from "react-icons/fi";
import { AiOutlineCloseSquare } from "react-icons/ai";
import moment from "moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const convertFilterToQueryString = (filter) => {
  return Object.keys(filter)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(filter[key])}`
    )
    .join("&");
};

function EmiSection({ show, setShow }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams(); // Search params hook

  const { emiStats } = useSelector((state) => state.StatsReducer);

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(1000);
  const [maxLinks, setMaxLinks] = useState(10);
  const [perPage, setperPage] = useState(5);
  const { links, from, to, setPage, onNext, onPrevious, page } = usePagination({
    totalItems,
    perPage,
    maxLinks,
  });

  useEffect(() => {
    if (emiStats?.status === 200) {
      setData(emiStats?.data);
      setTotalItems(emiStats?.data?.emi?.length);
    }
  }, [emiStats]);

  useEffect(() => {
    const filter = {
      startDate: "",
      endDate: "",
    };
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    const truckId = searchParams.get("truckId");
    const isTruck = searchParams.get("allTruck") === "true";

    // Add date filters if they are present
    if (startDate) {
      filter.startDate = new Date(startDate).toISOString(); // Convert date to ISO string
    }
    if (endDate) {
      filter.endDate = new Date(endDate).toISOString(); // Convert date to ISO string
    }

    // Add truckId if it's not 'allTruck'
    if (!isTruck && truckId) {
      filter.truckId = truckId;
    }
    // Convert filter object to query string
    const queryString = convertFilterToQueryString(filter);

    dispatch(getEmiStatsAction(queryString));
  }, [searchParams]);

  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 2,
        }).format(amount)
      : "₹0.00";

  const handleExport = () => {
    if (!data || !data?.emi) {
      console.error("Data or emi is undefined");
      return;
    }

    // Flatten EMI data
    const combinedData = [
      ...(data?.emi?.map((item) => ({
        "EMI Month": item?.emiPayMonth
          ? moment(item?.emiPayMonth).format("MMM / YYYY")
          : "-",
        "EMI Transaction Number": item?.emiTransationNumber
          ? item?.paymentType + "-" + item?.emiTransationNumber
          : "-",
        "Truck Number": item?.truckId ? item?.truckId.truckNumber : "-",
        "EMI Amount + Addition Charges": item?.emiAmount
          ? formatAmount(item?.emiAmount) + "+" + formatAmount(item?.additionCharges)
          : "-",
        "Additional Charges Reason": item?.additionalChargesReason
          ? item?.additionalChargesReason
          : "-",
        "Total Amount Pay": item?.totalAmountPay
          ? formatAmount(item?.totalAmountPay)
          : "-",
      })) || []),
    ];

     // Find last `insuranceTill` if startDate is not provided
     const lastInsuranceTillDate = data?.emi?.length
     ? moment(
         data.emi[data.emi.length - 1]?.emiPayMonth || new Date()
       ).format("MMM/YYYY")
     : "";
 
   // Determine the start and end date for the file name
   const startDate = searchParams.get("startDate")
     ? moment(searchParams.get("startDate")).format("DD/MMM/YYYY")
     : lastInsuranceTillDate;
   const endDate = searchParams.get("endDate")
     ? moment(searchParams.get("endDate")).format("DD/MMM/YYYY")
     : "";
    // Convert to worksheet
    const ws = XLSX.utils.json_to_sheet(combinedData);

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "EMI Data");

    // Generate Excel file and trigger download
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const file = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(
      file,
      `emi_${startDate}-${
        endDate ? endDate : moment(new Date()).format("MMM/YYYY")
      }.xlsx`
    );
  };

  return (
    <>
      <div className=" bg-white mt-6 shadow-lg rounded-sm relative">
        <div className="p-4 text-[#64709B] font-semibold flex justify-between">
          <div className="cursor-pointer" onClick={() => setShow("emi")}>
            EMI
          </div>
        </div>
        <div className="w-full h-0.5 bg-[#F1F2F6]" />
        <div className="p-4 text-[#303750] text-2xl font-bold flex gap-2">
          {formatAmount(data?.totalemiCost)}{" "}
          <div className="text-base font-medium mt-auto">INR</div>
        </div>
        {show === "emi" && <div className="arrow-down"></div>}
      </div>
      {show === "emi" && (
        <div className="col-span-4 order-last">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
              <thead className="ltr:text-left rtl:text-right">
                <tr>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-left">
                    EMI Months
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    EMI Transaction Number
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Truck Number
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    EMI Amount + Addition Charges
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Additional Charges Reason
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Total Amount Pay
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium justify-end flex gap-3">
                    <FiDownload
                      className="text-lg ghostButton cursor-pointer"
                      onClick={handleExport}
                    />
                    <AiOutlineCloseSquare
                      onClick={() => setShow("")}
                      className="text-red-500 text-xl cursor-pointer"
                    />
                  </th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200">
                {data?.emi?.slice(from - 1, to)?.map((item) => (
                  <tr key={item.emiTransationNumber}>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 ">
                      {item?.emiPayMonth
                        ? moment(item?.emiPayMonth).format("MMM / YYYY")
                        : "-"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center">
                      {item?.emiTransationNumber
                        ? item?.paymentType + "-" + item?.emiTransationNumber
                        : "-"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center uppercase">
                      {item?.truckId ? item?.truckId.truckNumber : "-"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center">
                      {item?.emiAmount
                        ? formatAmount(item?.emiAmount) +
                          "+" +
                          formatAmount(item?.additionCharges)
                        : "-"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center">
                      {item?.additionalChargesReason
                        ? item?.additionalChargesReason
                        : "-"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center">
                      {item?.totalAmountPay
                        ? formatAmount(item?.totalAmountPay)
                        : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <PaginationPackage
            links={links}
            from={from}
            to={to}
            setPage={setPage}
            onNext={onNext}
            onPrevious={onPrevious}
            page={page}
          />
        </div>
      )}
    </>
  );
}

export default EmiSection;
