
import { deleteCookies, getCookies, setCookies } from "../../auth/auth";
import {
  CLEAR_ROLE,
  CLEAR_TOKEN,
  GET_ROLE,
  GET_TOKEN,
  PATCH_RESET_PASSWORD,
  PATCH_RESET_PASSWORD_ERROR,
  PATCH_RESET_PASSWORD_SUCCESS,
  POST_FORGET_PASSWORD,
  POST_FORGET_PASSWORD_ERROR,
  POST_FORGET_PASSWORD_SUCCESS,
  POST_LOGIN,
  POST_LOGIN_ERROR,
  POST_LOGIN_SUCCESS,
  POST_OTP,
  POST_OTP_ERROR,
  POST_OTP_SUCCESS,
  POST_REGISTER,
  POST_REGISTER_ERROR,
  POST_REGISTER_SUCCESS,
  SET_ROLE,
  SET_TOKEN,
  LOGOUT,
  POST_RESEND_OTP,
  POST_RESEND_OTP_SUCCESS,
  POST_RESEND_OTP_ERROR,
} from "../actions";
const INIT_STATE = {
  postLogin: {},
  postLoginLoading: false,
  postLoginError: "",
  postOtp: {},
  postOtpLoading: false,
  postOtpError: "",
  postResendOtp: {},
  postResendOtpLoading: false,
  postResendOtpError: "",
  getLocation: [],
  getLocationLoading: false,
  getLocationError: "",
  postRegister: [],
  postRegisterLoading: false,
  postRegisterError: "",
  postForgetPassword: [],
  postForgetPasswordLoading: false,
  postForgetPasswordError: "",
  patchResetPassword: [],
  patchResetPasswordLoading: false,
  patchResetPasswordError: "",
  token:  undefined,
  role:  undefined,
};

const Authsection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_LOGIN:
      return {
        ...state,
        postLogin: {},
        postLoginLoading: true,
      };
    case POST_LOGIN_SUCCESS:
      return {
        ...state,
        postLogin: action.payload,
        postLoginLoading: false,
        postLoginError: "",
      };

    case POST_LOGIN_ERROR:
      return {
        ...state,
        postLoginLoading: false,
        postLoginError: action.payload.message,
      };
    case POST_REGISTER:
      return {
        ...state,
        postRegister: [],
        postRegisterLoading: true,
      };
    case POST_REGISTER_SUCCESS:
      return {
        ...state,
        postRegister: action.payload,
        postRegisterLoading: false,
        postRegisterError: "",
      };

    case POST_REGISTER_ERROR:
      return {
        ...state,
        postRegisterLoading: false,
        postRegisterError: action.payload.message,
      };
    case POST_OTP:
      return {
        ...state,
        postOtp: [],
        postOtpLoading: true,
      };
    case POST_OTP_SUCCESS:
      return {
        ...state,
        postOtp: action.payload,
        postOtpLoading: false,
        postOtpError: "",
      };

    case POST_OTP_ERROR:
      return {
        ...state,
        postOtpLoading: false,
        postOtpError: action.payload.message,
      };
    case POST_RESEND_OTP:
      return {
        ...state,
        postResendOtp: [],
        postResendOtpLoading: true,
      };
    case POST_RESEND_OTP_SUCCESS:
      return {
        ...state,
        postResendOtp: action.payload,
        postResendOtpLoading: false,
        postResendOtpError: "",
      };

    case POST_RESEND_OTP_ERROR:
      return {
        ...state,
        postResendOtpLoading: false,
        postResendOtpError: action.payload.message,
      };
    case POST_FORGET_PASSWORD:
      return {
        ...state,
        postForgetPassword: [],
        postForgetPasswordLoading: true,
      };
    case POST_FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        postForgetPassword: action.payload,
        postForgetPasswordLoading: false,
        postForgetPasswordError: "",
      };

    case POST_FORGET_PASSWORD_ERROR:
      return {
        ...state,
        postForgetPasswordLoading: false,
        postForgetPasswordError: action.payload.message,
      };
    case PATCH_RESET_PASSWORD:
      return {
        ...state,
        patchResetPassword: [],
        patchResetPasswordLoading: true,
      };
    case PATCH_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        patchResetPassword: action.payload,
        patchResetPasswordLoading: false,
        patchResetPasswordError: "",
      };

    case PATCH_RESET_PASSWORD_ERROR:
      return {
        ...state,
        patchResetPasswordLoading: false,
        patchResetPasswordError: action.payload.message,
      };
    // case SET_TOKEN:
    //   setCookies("token", action.payload);
    //   return {
    //     ...state,
    //     token: action.payload,
    //   };
    // case GET_TOKEN:
    //   const tokenFromCookie = getCookies("token");
    //   return {
    //     ...state,
    //     token: tokenFromCookie,
    //   };
    // case CLEAR_TOKEN:
    //   deleteCookies("token");
    //   return {
    //     ...state,
    //     token: null,
    //   };
    // case SET_ROLE:
    //   setCookies("role", action.payload);
    //   return {
    //     ...state,
    //     role: action.payload,
    //   };
    // case GET_ROLE:
    //   const roleFromCookie = getCookies("role");
    //   return {
    //     ...state,
    //     role: roleFromCookie,
    //   };
    // case CLEAR_ROLE:
    //   deleteCookies("role");
    //   return {
    //     ...state,
    //     role: null,
    //   };

    // case LOGOUT:
    //   localStorage.removeItem('role')
    //   localStorage.removeItem('name')
    //   localStorage.removeItem('token')
    //   return {
    //     ...state,
    //     postLogin: {},
    //     postLoginLoading: false,
    //   }

    default:
      return { ...state };
  }
};
export default Authsection;