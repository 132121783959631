import {
  DELETE_COMPANY_FUEL,
  DELETE_COMPANY_FUEL_ERROR,
  DELETE_COMPANY_FUEL_SUCCESS,
  GET_COMPANY_FUEL,
  GET_COMPANY_FUEL_ERROR,
  GET_COMPANY_FUEL_FILTER,
  GET_COMPANY_FUEL_FILTER_ERROR,
  GET_COMPANY_FUEL_FILTER_SUCCESS,
  GET_COMPANY_FUEL_ID,
  GET_COMPANY_FUEL_ID_ERROR,
  GET_COMPANY_FUEL_ID_SUCCESS,
  GET_COMPANY_FUEL_NAME_LIST,
  GET_COMPANY_FUEL_NAME_LIST_ERROR,
  GET_COMPANY_FUEL_NAME_LIST_SUCCESS,
  GET_COMPANY_FUEL_SUCCESS,
  PATCH_COMPANY_FUEL,
  PATCH_COMPANY_FUEL_ERROR,
  PATCH_COMPANY_FUEL_SUCCESS,
  POST_COMPANY_FUEL,
  POST_COMPANY_FUEL_ERROR,
  POST_COMPANY_FUEL_SUCCESS,
} from "../../actions";

//truck
export const getCompanyFuelAction = (info) => ({
  type: GET_COMPANY_FUEL,
  payload: info,
});
export const getCompanyFuelSuccess = (info) => ({
  type: GET_COMPANY_FUEL_SUCCESS,
  payload: info,
});
export const getCompanyFuelError = (error) => ({
  type: GET_COMPANY_FUEL_ERROR,
  payload: error,
});
export const getCompanyFuelIdAction = (info) => ({
  type: GET_COMPANY_FUEL_ID,
  payload: info,
});
export const getCompanyFuelIdSuccess = (info) => ({
  type: GET_COMPANY_FUEL_ID_SUCCESS,
  payload: info,
});
export const getCompanyFuelIdError = (error) => ({
  type: GET_COMPANY_FUEL_ID_ERROR,
  payload: error,
});
export const getCompanyFuelFilterAction = (info) => ({
  type: GET_COMPANY_FUEL_FILTER,
  payload: info,
});
export const getCompanyFuelFilterSuccess = (info) => ({
  type: GET_COMPANY_FUEL_FILTER_SUCCESS,
  payload: info,
});
export const getCompanyFuelFilterError = (error) => ({
  type: GET_COMPANY_FUEL_FILTER_ERROR,
  payload: error,
});
export const getCompanyFuelNameListAction = (info) => ({
  type: GET_COMPANY_FUEL_NAME_LIST,
  payload: info,
});
export const getCompanyFuelNameListSuccess = (info) => ({
  type: GET_COMPANY_FUEL_NAME_LIST_SUCCESS,
  payload: info,
});
export const getCompanyFuelNameListError = (error) => ({
  type: GET_COMPANY_FUEL_NAME_LIST_ERROR,
  payload: error,
});
export const postCompanyFuelAction = (info) => ({
  type: POST_COMPANY_FUEL,
  payload: info,
});
export const postCompanyFuelSuccess = (info) => ({
  type: POST_COMPANY_FUEL_SUCCESS,
  payload: info,
});
export const postCompanyFuelError = (error) => ({
  type: POST_COMPANY_FUEL_ERROR,
  payload: error,
});
export const patchCompanyFuelAction = (info) => ({
  type: PATCH_COMPANY_FUEL,
  payload: info,
});
export const patchCompanyFuelSuccess = (info) => ({
  type: PATCH_COMPANY_FUEL_SUCCESS,
  payload: info,
});
export const patchCompanyFuelError = (error) => ({
  type: PATCH_COMPANY_FUEL_ERROR,
  payload: error,
});
export const deleteCompanyFuelAction = (info) => ({
  type: DELETE_COMPANY_FUEL,
  payload: info,
});
export const deleteCompanyFuelSuccess = (info) => ({
  type: DELETE_COMPANY_FUEL_SUCCESS,
  payload: info,
});
export const deleteCompanyFuelError = (error) => ({
  type: DELETE_COMPANY_FUEL_ERROR,
  payload: error,
});
