import { GET_ADMIN_DASHBOARD_COMPANY_FILTER, GET_ADMIN_DASHBOARD_COMPANY_FILTER_ERROR, GET_ADMIN_DASHBOARD_COMPANY_FILTER_SUCCESS, GET_ADMIN_DASHBOARD_COMPANY_GRAPH, GET_ADMIN_DASHBOARD_COMPANY_GRAPH_ERROR, GET_ADMIN_DASHBOARD_COMPANY_GRAPH_SUCCESS, GET_ADMIN_DASHBOARD_EMPLOYEE_FILTER, GET_ADMIN_DASHBOARD_EMPLOYEE_FILTER_ERROR, GET_ADMIN_DASHBOARD_EMPLOYEE_FILTER_SUCCESS, GET_ADMIN_DASHBOARD_EMPLOYEE_GRAPH, GET_ADMIN_DASHBOARD_EMPLOYEE_GRAPH_ERROR, GET_ADMIN_DASHBOARD_EMPLOYEE_GRAPH_SUCCESS, GET_ADMIN_DASHBOARD_EXPENSES_FILTER, GET_ADMIN_DASHBOARD_EXPENSES_FILTER_ERROR, GET_ADMIN_DASHBOARD_EXPENSES_FILTER_SUCCESS, GET_ADMIN_DASHBOARD_EXPENSES_GRAPH, GET_ADMIN_DASHBOARD_EXPENSES_GRAPH_ERROR, GET_ADMIN_DASHBOARD_EXPENSES_GRAPH_SUCCESS, GET_ADMIN_DASHBOARD_PROFIT_LOSS, GET_ADMIN_DASHBOARD_PROFIT_LOSS_ERROR, GET_ADMIN_DASHBOARD_PROFIT_LOSS_SUCCESS, GET_ADMIN_DASHBOARD_SUBSCRIPTION_GRAPH, GET_ADMIN_DASHBOARD_SUBSCRIPTION_GRAPH_ERROR, GET_ADMIN_DASHBOARD_SUBSCRIPTION_GRAPH_SUCCESS, GET_ADMIN_DASHBOARD_SUBSCRIPTION_PF, GET_ADMIN_DASHBOARD_SUBSCRIPTION_PF_ERROR, GET_ADMIN_DASHBOARD_SUBSCRIPTION_PF_SUCCESS, GET_ADMIN_DASHBOARD_TRUCK_GRAPH, GET_ADMIN_DASHBOARD_TRUCK_GRAPH_ERROR, GET_ADMIN_DASHBOARD_TRUCK_GRAPH_SUCCESS } from "../../actions";

export const getAdminDashboardSubscriptionGraphPFAction = (info) => ({
    type: GET_ADMIN_DASHBOARD_SUBSCRIPTION_PF,
    payload: info,
  });
  export const getAdminDashboardSubscriptionGraphPFSuccess = (info) => ({
    type: GET_ADMIN_DASHBOARD_SUBSCRIPTION_PF_SUCCESS,
    payload: info,
  });
  export const getAdminDashboardSubscriptionGraphPFError = (error) => ({
    type: GET_ADMIN_DASHBOARD_SUBSCRIPTION_PF_ERROR,
    payload: error,
  });
  export const getAdminDashboardTruckGraphAction = (info) => ({
    type: GET_ADMIN_DASHBOARD_TRUCK_GRAPH,
    payload: info,
  });
  export const getAdminDashboardTruckGraphSuccess = (info) => ({
    type: GET_ADMIN_DASHBOARD_TRUCK_GRAPH_SUCCESS,
    payload: info,
  });
  export const getAdminDashboardTruckGraphError = (error) => ({
    type: GET_ADMIN_DASHBOARD_TRUCK_GRAPH_ERROR,
    payload: error,
  });
  export const getAdminDasboardCompanyFilterAction = (info) => ({
    type: GET_ADMIN_DASHBOARD_COMPANY_FILTER,
    payload: info,
  });
  export const getAdminDasboardCompanyFilterSuccess = (info) => ({
    type: GET_ADMIN_DASHBOARD_COMPANY_FILTER_SUCCESS,
    payload: info,
  });
  export const getAdminDasboardCompanyFilterError = (error) => ({
    type: GET_ADMIN_DASHBOARD_COMPANY_FILTER_ERROR,
    payload: error,
  });
  export const getAdminDasboardCompanyGraphAction = (info) => ({
    type: GET_ADMIN_DASHBOARD_COMPANY_GRAPH,
    payload: info,
  });
  export const getAdminDasboardCompanyGraphSuccess = (info) => ({
    type: GET_ADMIN_DASHBOARD_COMPANY_GRAPH_SUCCESS,
    payload: info,
  });
  export const getAdminDasboardCompanyGraphError = (error) => ({
    type: GET_ADMIN_DASHBOARD_COMPANY_GRAPH_ERROR,
    payload: error,
  });
  export const getAdminDashboardEmployeeFilterAction = (info) => ({
    type: GET_ADMIN_DASHBOARD_EMPLOYEE_FILTER,
    payload: info,
  });
  export const getAdminDashboardEmployeeFilterSuccess = (info) => ({
    type: GET_ADMIN_DASHBOARD_EMPLOYEE_FILTER_SUCCESS,
    payload: info,
  });
  export const getAdminDashboardEmployeeFilterError = (error) => ({
    type: GET_ADMIN_DASHBOARD_EMPLOYEE_FILTER_ERROR,
    payload: error,
  });
  export const getAdminDashboardEmployeeGraphAction = (info) => ({
    type: GET_ADMIN_DASHBOARD_EMPLOYEE_GRAPH,
    payload: info,
  });
  export const getAdminDashboardEmployeeGraphSuccess = (info) => ({
    type: GET_ADMIN_DASHBOARD_EMPLOYEE_GRAPH_SUCCESS,
    payload: info,
  });
  export const getAdminDashboardEmployeeGraphError = (error) => ({
    type: GET_ADMIN_DASHBOARD_EMPLOYEE_GRAPH_ERROR,
    payload: error,
  });
  export const getAdminDashboardExpensesFilterAction = (info) => ({
    type: GET_ADMIN_DASHBOARD_EXPENSES_FILTER,
    payload: info,
  });
  export const getAdminDashboardExpensesFilterSuccess = (info) => ({
    type: GET_ADMIN_DASHBOARD_EXPENSES_FILTER_SUCCESS,
    payload: info,
  });
  export const getAdminDashboardExpensesFilterError = (error) => ({
    type: GET_ADMIN_DASHBOARD_EXPENSES_FILTER_ERROR,
    payload: error,
  });
  export const getAdminDashboardExpensesGraphAction = (info) => ({
    type: GET_ADMIN_DASHBOARD_EXPENSES_GRAPH,
    payload: info,
  });
  export const getAdminDashboardExpensesGraphSuccess = (info) => ({
    type: GET_ADMIN_DASHBOARD_EXPENSES_GRAPH_SUCCESS,
    payload: info,
  });
  export const getAdminDashboardExpensesGraphError = (error) => ({
    type: GET_ADMIN_DASHBOARD_EXPENSES_GRAPH_ERROR,
    payload: error,
  });
  export const getAdminDashboardProfiltLossAction = (info) => ({
    type: GET_ADMIN_DASHBOARD_PROFIT_LOSS,
    payload: info,
  });
  export const getAdminDashboardProfiltLossSuccess = (info) => ({
    type: GET_ADMIN_DASHBOARD_PROFIT_LOSS_SUCCESS,
    payload: info,
  });
  export const getAdminDashboardProfiltLossError = (error) => ({
    type: GET_ADMIN_DASHBOARD_PROFIT_LOSS_ERROR,
    payload: error,
  });
  export const getAdminDashboardSubscriptionGraphAction = (info) => ({
    type: GET_ADMIN_DASHBOARD_SUBSCRIPTION_GRAPH,
    payload: info,
  });
  export const getAdminDashboardSubscriptionGraphSuccess = (info) => ({
    type: GET_ADMIN_DASHBOARD_SUBSCRIPTION_GRAPH_SUCCESS,
    payload: info,
  });
  export const getAdminDashboardSubscriptionGraphError = (error) => ({
    type: GET_ADMIN_DASHBOARD_SUBSCRIPTION_GRAPH_ERROR,
    payload: error,
  });