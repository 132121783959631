import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import api from "../../../auth/api";
import { deleteAdminDiscountError, deleteAdminDiscountSuccess, deleteAdminSubscriptionError, deleteAdminSubscriptionSuccess, getAdminDiscountByIdError, getAdminDiscountByIdSuccess, getAdminDiscountListError, getAdminDiscountListSuccess, getAdminSubscriptionByIdError, getAdminSubscriptionByIdSuccess, getAdminSubscriptionListError, getAdminSubscriptionListSuccess, patchAdminDiscountError, patchAdminDiscountSuccess, patchAdminSubscriptionError, patchAdminSubscriptionSuccess, postAdminDiscountError, postAdminDiscountSuccess, postAdminSubscriptionError, postAdminSubscriptionSuccess } from "./action";
import { DELETE_ADMIN_DISCOUNT, DELETE_ADMIN_SUBSCRIPTION, GET_ADMIN_DISCOUNT_BY_ID, GET_ADMIN_DISCOUNT_LIST, GET_ADMIN_SUBSCRIPTION_BY_ID, GET_ADMIN_SUBSCRIPTION_LIST, PATCH_ADMIN_DISCOUNT, PATCH_ADMIN_SUBSCRIPTION, POST_ADMIN_DISCOUNT, POST_ADMIN_SUBSCRIPTION } from "../../actions";

// Async function to handle API call
const getAdminSubscriptionListAsync = async (payload) => {
    try {
      const response = await api.get(`/admin/subscription/get`);
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* getAdminSubscriptionListCall(action) {
    try {
      const data = yield call(getAdminSubscriptionListAsync, action); // Call async function with action payload
      yield put(getAdminSubscriptionListSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(getAdminSubscriptionListError(error)); // Dispatch error action with error object
    }
  }
  export function* watchGetAdminSubscriptionListData() {
    yield takeEvery(GET_ADMIN_SUBSCRIPTION_LIST, getAdminSubscriptionListCall);
  }


// Async function to handle API call
const getAdminDiscountListAsync = async (payload) => {
    try {
      const response = await api.get(`/admin/subscription/discount/get`);
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* getAdminDiscountListCall(action) {
    try {
      const data = yield call(getAdminDiscountListAsync, action); // Call async function with action payload
      yield put(getAdminDiscountListSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(getAdminDiscountListError(error)); // Dispatch error action with error object
    }
  }
  export function* watchGetAdminDiscountListData() {
    yield takeEvery(GET_ADMIN_DISCOUNT_LIST, getAdminDiscountListCall);
  }
  
// Async function to handle API call
const getAdminSubscriptionByIdAsync = async (payload) => {
    try {
      const response = await api.get(`/admin/subscription/${payload}`);
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* getAdminSubscriptionByIdCall(action) {
    try {
      const data = yield call(getAdminSubscriptionByIdAsync, action.payload); // Call async function with action payload
      yield put(getAdminSubscriptionByIdSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(getAdminSubscriptionByIdError(error)); // Dispatch error action with error object
    }
  }
  export function* watchGetAdminSubscriptionByIdData() {
    yield takeEvery(GET_ADMIN_SUBSCRIPTION_BY_ID, getAdminSubscriptionByIdCall);
  }
  
  
// Async function to handle API call
const getAdminDiscountByIdAsync = async (payload) => {
    try {
      const response = await api.get(`/admin/subscription/discount/${payload}`);
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* getAdminDiscountByIdCall(action) {
    try {
      const data = yield call(getAdminDiscountByIdAsync, action.payload); // Call async function with action payload
      yield put(getAdminDiscountByIdSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(getAdminDiscountByIdError(error)); // Dispatch error action with error object
    }
  }
  export function* watchGetAdminDiscountByIdData() {
    yield takeEvery(GET_ADMIN_DISCOUNT_BY_ID, getAdminDiscountByIdCall);
  }

  
// Async function to handle API call
const postAdminSubscriptionAsync = async (payload) => {
    try {
      const response = await api.post(
        `/admin/subscription/addSubscription`,
        payload
      );
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* postAdminSubscriptionCall(action) {
    try {
      const data = yield call(postAdminSubscriptionAsync, action.payload); // Call async function with action payload
      yield put(postAdminSubscriptionSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(postAdminSubscriptionError(error)); // Dispatch error action with error object
    }
  }
  export function* watchPostAdminSubscriptionData() {
    yield takeEvery(
      POST_ADMIN_SUBSCRIPTION,
      postAdminSubscriptionCall
    );
  }
  
// Async function to handle API call
const postAdminDiscountAsync = async (payload) => {
    try {
      const response = await api.post(
        `/admin/subscription/addDiscount`,
        payload
      );
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* postAdminDiscountCall(action) {
    try {
      const data = yield call(postAdminDiscountAsync, action.payload); // Call async function with action payload
      yield put(postAdminDiscountSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(postAdminDiscountError(error)); // Dispatch error action with error object
    }
  }
  export function* watchPostAdminDiscountData() {
    yield takeEvery(
      POST_ADMIN_DISCOUNT,
      postAdminDiscountCall
    );
  }

// Async function to handle API call
const patchAdminSubscriptionAsync = async (payload) => {
    try {
      const response = await api.patch(`/admin/subscription/updateSubscription/${payload.id}`, payload.body);
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* patchAdminSubscriptionCall(action) {
    try {
      const data = yield call(patchAdminSubscriptionAsync, action.payload); // Call async function with action payload
      yield put(patchAdminSubscriptionSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(patchAdminSubscriptionError(error)); // Dispatch error action with error object
    }
  }
  export function* watchPatchAdminSubscriptionData() {
    yield takeEvery(
      PATCH_ADMIN_SUBSCRIPTION,
      patchAdminSubscriptionCall
    );
  }
    // Async function to handle API call
const patchAdminDiscountAsync = async (payload) => {
    try {
      const response = await api.patch(`/admin/subscription/updateDiscount/${payload.id}`, payload.body);
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* patchAdminDiscountCall(action) {
    try {
      const data = yield call(patchAdminDiscountAsync, action.payload); // Call async function with action payload
      yield put(patchAdminDiscountSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(patchAdminDiscountError(error)); // Dispatch error action with error object
    }
  }
  export function* watchPatchAdminDiscountData() {
    yield takeEvery(
      PATCH_ADMIN_DISCOUNT,
      patchAdminDiscountCall
    );
  }

  // Async function to handle API call
const deleteAdminSubscriptionAsync = async (payload) => {
    try {
      const response = await api.delete(`/admin/subscription/${payload}`);
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* deleteAdminSubscriptionCall(action) {
    try {
      const data = yield call(deleteAdminSubscriptionAsync, action.payload); // Call async function with action payload
      yield put(deleteAdminSubscriptionSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(deleteAdminSubscriptionError(error)); // Dispatch error action with error object
    }
  }
  export function* watchDeleteAdminSubscriptionData() {
    yield takeEvery(DELETE_ADMIN_SUBSCRIPTION, deleteAdminSubscriptionCall);
  }
  
  // Async function to handle API call
const deleteAdminDiscountAsync = async (payload) => {
    try {
      const response = await api.delete(`/admin/subscription/discount/${payload}`);
      return response.data; // Return the data from the API response
    } catch (error) {
      throw error.response.data; // Throw the error to be caught by the Saga error handler
    }
  };
  
  // Saga function to handle POST_COMPANY_DETAILS action
  function* deleteAdminDiscountCall(action) {
    try {
      const data = yield call(deleteAdminDiscountAsync, action.payload); // Call async function with action payload
      yield put(deleteAdminDiscountSuccess(data)); // Dispatch success action with data
    } catch (error) {
      yield put(deleteAdminDiscountError(error)); // Dispatch error action with error object
    }
  }
  export function* watchDeleteAdminDiscountData() {
    yield takeEvery(DELETE_ADMIN_DISCOUNT, deleteAdminDiscountCall);
  }
export default function* rootSaga() {
    yield all([
      fork(watchGetAdminSubscriptionListData),
        fork(watchGetAdminDiscountListData),
        fork(watchGetAdminSubscriptionByIdData),
        fork(watchGetAdminDiscountByIdData),
        fork(watchPostAdminSubscriptionData),
        fork(watchPostAdminDiscountData),
        fork(watchPatchAdminSubscriptionData),
        fork(watchPatchAdminDiscountData),
        fork(watchDeleteAdminSubscriptionData),
        fork(watchDeleteAdminDiscountData),

    ]);
  }
  