import React from "react";
import TruckCard from "./truckCard";
import CompanyCard from "./companyCard";
import { StateMapChart } from "./stateMapChart";
import EmployeeCard from "./employeesCard";
import SubscriptionCard from "./subscriptionCard";
import ExpensesCard from "./expenseCard";
import Profitloss from "./profitloss";

function Dashboard() {
  return (
    <div>

      <div className="grid grid-cols-2 gap-10">
        <Profitloss />
        <TruckCard />
        <CompanyCard />
        <div className="grid grid-cols-3 gap-10 col-span-2">
          <EmployeeCard />
          <SubscriptionCard />
          <StateMapChart />
          <ExpensesCard />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
