import React from "react";
import  DateView  from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Field} from "formik";
import "../../assets/css/reactdatepixker.css"


function DatePicker(props) {
  const {  placeHolder, name , errorName , ...rest } = props;

  return (
    <>
        <Field name={name}>
          {({form, field}) => {
            const { setFieldValue } = form
            const { value } = field
            return (
                <>
              <DateView
                {...field}
                id={name}
                calendarStartDay={1}
                formatWeekDay={nameOfDay => nameOfDay.substr(0,3)}
                dateFormatCalendar={"MMM yyyy"}
                dateFormat="dd / MMM / yyyy"
                placeholderText={placeHolder || "dd / mm / yyyy"}                
                selected={value}
                onChange={(val) => setFieldValue(name, val)}
                scrollableYearDropdown={true}
                yearDropdownItemNumber={100}
                showYearDropdown
                showMonthDropdown
                {...rest}
                />
                
            </>
            );
        }}
        </Field>
        {/* <ErrorMessage name={errorName} /> */}
    </>
  );
}

export default DatePicker;
