const {
  GET_DEF,
  GET_DEF_SUCCESS,
  GET_DEF_ERROR,
  GET_DEF_BY_TRUCK,
  GET_DEF_BY_TRUCK_SUCCESS,
  GET_DEF_BY_TRUCK_ERROR,
  GET_DEF_BY_ID,
  GET_DEF_BY_ID_SUCCESS,
  GET_DEF_BY_ID_ERROR,
  POST_DEF,
  POST_DEF_SUCCESS,
  POST_DEF_ERROR,
  PATCH_DEF,
  PATCH_DEF_SUCCESS,
  PATCH_DEF_ERROR,
  DELETE_DEF,
  DELETE_DEF_SUCCESS,
  DELETE_DEF_ERROR,
  GET_DEF_FILTER,
  GET_DEF_FILTER_SUCCESS,
  GET_DEF_FILTER_ERROR,
} = require("../../../actions");

const INIT_STATE = {
  getDEFDetails: {},
  getDEFDetailsLoading: false,
  getDEFDetailsError: "",
  getDEFByTruck: {},
  getDEFByTruckLoading: false,
  getDEFByTruckError: "",
  getDEFById: {},
  getDEFByIdLoading: false,
  getDEFByIdError: "",
  postDEF: {},
  postDEFLoading: false,
  postDEFError: "",
  patchDEF: {},
  patchDEFLoading: false,
  patchDEFError: "",
  deleteDEF: {},
  deleteDEFLoading: false,
  deleteDEFError: "",
  getDEFFilter: {},
  getDEFFilterLoading: false,
  getDEFFilterError: "",
};

const DEFSection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEF:
      return {
        ...state,
        getDEFDetails: {},
        getDEFDetailsLoading: true,
      };
    case GET_DEF_SUCCESS:
      return {
        ...state,
        getDEFDetails: action.payload,
        getDEFDetailsLoading: false,
        getDEFDetailsError: "",
      };
    case GET_DEF_ERROR:
      return {
        ...state,
        getDEFDetailsLoading: false,
        getDEFDetailsError: action.payload,
      };
    case GET_DEF_BY_TRUCK:
      return {
        ...state,
        getDEFByTruck: {},
        getDEFByTruckLoading: true,
      };
    case GET_DEF_BY_TRUCK_SUCCESS:
      return {
        ...state,
        getDEFByTruck: action.payload,
        getDEFByTruckLoading: false,
        getDEFByTruckError: "",
      };
    case GET_DEF_BY_TRUCK_ERROR:
      return {
        ...state,
        getDEFByTruckLoading: false,
        getDEFByTruckError: action.payload,
      };
    case GET_DEF_BY_ID:
      return {
        ...state,
        getDEFById: {},
        getDEFByIdLoading: true,
      };
    case GET_DEF_BY_ID_SUCCESS:
      return {
        ...state,
        getDEFById: action.payload,
        getDEFByIdLoading: false,
        getDEFByIdError: "",
      };
    case GET_DEF_BY_ID_ERROR:
      return {
        ...state,
        getDEFByIdLoading: false,
        getDEFByIdError: action.payload,
      };
    case POST_DEF:
      return {
        ...state,
        postDEF: {},
        postDEFLoading: true,
      };
    case POST_DEF_SUCCESS:
      return {
        ...state,
        postDEF: action.payload,
        postDEFLoading: false,
        postDEFError: "",
      };
    case POST_DEF_ERROR:
      return {
        ...state,
        postDEFLoading: false,
        postDEFError: action.payload,
      };
    case PATCH_DEF:
      return {
        ...state,
        patchDEF: {},
        patchDEFLoading: true,
      };
    case PATCH_DEF_SUCCESS:
      return {
        ...state,
        patchDEF: action.payload,
        patchDEFLoading: false,
        patchDEFError: "",
      };
    case PATCH_DEF_ERROR:
      return {
        ...state,
        patchDEFLoading: false,
        patchDEFError: action.payload,
      };
    case DELETE_DEF:
      return {
        ...state,
        deleteDEF: {},
        deleteDEFLoading: true,
      };
    case DELETE_DEF_SUCCESS:
      return {
        ...state,
        deleteDEF: action.payload,
        deleteDEFLoading: false,
        deleteDEFError: "",
      };
    case DELETE_DEF_ERROR:
      return {
        ...state,
        deleteDEFLoading: false,
        deleteDEFError: action.payload,
      };
    case GET_DEF_FILTER:
      return {
        ...state,
        getDEFFilter: {},
        getDEFFilterLoading: true,
      };
    case GET_DEF_FILTER_SUCCESS:
      return {
        ...state,
        getDEFFilter: action.payload,
        getDEFFilterLoading: false,
        getDEFFilterError: "",
      };
    case GET_DEF_FILTER_ERROR:
      return {
        ...state,
        getDEFFilterLoading: false,
        getDEFFilterError: action.payload,
      };
    default:
      return { ...state };
  }
};

export default DEFSection;
