import {
  getAdminDiscountListAction,
  getAdminSubscriptionListAction,
} from "../../../redux/admin/subscription/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "../../backdrop";
import toast from "react-hot-toast";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../ui/tabs";
import SubscriptionList from "./subscriptionList";
import DiscountList from "./discountList";

function SubscriptionComponent() {
  const dispatch = useDispatch();
  const {
    getAdminSubscriptionList,
    getAdminSubscriptionListLoading,
    getAdminSubscriptionListError,
    getAdminDiscountList,
    getAdminDiscountListLoading,
    getAdminDiscountListError,
  } = useSelector((state) => state.AdminSubscriptionSection);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [discountList, setDiscountList] = useState([]);

  useEffect(() => {
    dispatch(getAdminSubscriptionListAction());
    dispatch(getAdminDiscountListAction());
  }, []);
  useEffect(() => {
    if (getAdminSubscriptionList.status === 200) {
      setSubscriptionList(getAdminSubscriptionList.data);
    }
  }, [getAdminSubscriptionList]);
  useEffect(() => {
    if (getAdminSubscriptionListError.status === 400) {
      toast.error(getAdminSubscriptionListError.message);
    } else if (getAdminSubscriptionListError.status === 404) {
      toast.error(getAdminSubscriptionListError.message);
    } else if (getAdminSubscriptionListError.status === 500) {
      toast.error(getAdminSubscriptionListError.error);
    }
  }, [getAdminSubscriptionListError]);
  useEffect(() => {
    if (getAdminDiscountList.status === 200) {
    
      setDiscountList(getAdminDiscountList.data);
    }
  }, [getAdminDiscountList]);
  useEffect(() => {
    if (getAdminDiscountListError.status === 400) {
      toast.error(getAdminDiscountListError.message);
    } else if (getAdminDiscountListError.status === 404) {
      toast.error(getAdminDiscountListError.message);
    } else if (getAdminDiscountListError.status === 500) {
      toast.error(getAdminDiscountListError.error);
    }
  }, [getAdminDiscountListError]);

  return (
    <div>
      {(getAdminSubscriptionListLoading || getAdminDiscountListLoading) && (
        <Backdrop />
      )}
      <Tabs
        defaultValue="Subscription"
        color="primary"
        className="min-w-[400px]"
      >
        <TabsList>
          <TabsTrigger value="Subscription">Subscription</TabsTrigger>
          <TabsTrigger value="Discount">Discount</TabsTrigger>
        </TabsList>
        <TabsContent value="Subscription">
          <SubscriptionList data={subscriptionList} />
        </TabsContent>
        <TabsContent value="Discount">
          <DiscountList data={discountList} />
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default SubscriptionComponent;
