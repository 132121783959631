import { ErrorMessage, Field, Form, Formik } from "formik";
import { postAdminForgetPasswordAction } from "../../../redux/admin/company/action";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function ForgetPasswordModel({ email, setShowModal, userId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const {
    postAdminForgetPassword,
    postAdminForgetPasswordLoading,
    postAdminForgetPasswordError,
  } = useSelector((state) => state.AdminCompanysection);
  useEffect(() => {
    if (loader) {
      if (postAdminForgetPassword?.status === 200) {
        toast.success(postAdminForgetPassword?.message, {
          id: "postAdminForgetPassword",
        });
        setShowModal(false);
        setLoader(false);
      }
    }
  }, [postAdminForgetPassword]);
  useEffect(() => {
    if (loader) {
      if (postAdminForgetPasswordError?.status === 400) {
        toast.error(postAdminForgetPasswordError?.message, {
          id: "postAdminForgetPassword",
        });
        setLoader(false);
      } else if (postAdminForgetPasswordError?.status === 404) {
        toast.error(postAdminForgetPasswordError?.message, {
          id: "postAdminForgetPassword",
        });
        setLoader(false);
      } else if (postAdminForgetPasswordError?.status === 500) {
        toast.error(postAdminForgetPasswordError?.error, {
          id: "postAdminForgetPassword",
        });
        setLoader(false);
      }
    }
  }, [postAdminForgetPasswordError]);
  const initialValues = {
    email: email,
    userId: userId,
  };
  const validationSchema = Yup.object({
    email: Yup.string().email(t("invalidEmail")).required(t("required")),
  });
  const onSubmit = (values) => {
    dispatch(postAdminForgetPasswordAction(values));
    toast.loading("Loading...", { id: "postAdminForgetPassword" });
    setLoader(true);
  };

  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle>Forget Password Service</DialogTitle>
      </DialogHeader>
      <div className="text-xl mt-5 font-semibold">
        Are you sure you want to raise forget for this User?
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => (
          <Form className="space-y-8 row-span-2 flex flex-col justify-between  ">
            <div>
              <label htmlFor="email">
                {t("email")} <span>*</span>
              </label>
              <Field
                type="email"
                id="email"
                name="email"
                autoFocus
                placeholder={t("email")}
              />
              <ErrorMessage name="email" component="div" className="error" />
            </div>
            <DialogFooter>
              <button
                className="secondaryButton"
                onClick={() => setShowModal(false)}
                type="button"
                disabled={postAdminForgetPasswordLoading}
              >
                Close
              </button>
              <button
                className="primaryButton bg-red-600 hover:bg-red-800 w-fit"
                type="submit"
                disabled={postAdminForgetPasswordLoading}
              >
                Yes
              </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default ForgetPasswordModel;
