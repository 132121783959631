import ImagePopUpDownload from "../../../../components/imagePopUpDownload";
import { Dialog } from "../../../../ui/dialog";
import React, { useState } from "react";
import Image from "../../../../assets/images/user.png";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { Badge } from "../../../../ui/badge";
import Terminate from "../../employee/terminate";

function EmployeeListCard({ data }) {
  const navigate = useNavigate();
  const [showImageModal, setShowImageModal] = useState(false);
  const [showTerminateModal, setShowTerminateModal] = useState(false);

  return (
    <div key={data?._id}>
      <div className="grid grid-cols-12 gap-x-7 my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl">
        <div className="lg:col-span-2 col-span-6 row-span-1 justify-self-center my-auto lg:row-span-3">
          <button
            type="button"
            className="rounded-full "
            onClick={() => navigate(`/admin/company/employee/details/${data?._id}`)}
          >
            <img
              src={data?.employeeId?.employeeImage || Image}
              alt=""
              className={`${
                data?.employeeImage ? "rounded-full" : ""
              } h-[100px] w-[100px]`}
            />
          </button>
        </div>
        {data?.employeeId?.employeeImage && (
          <Dialog open={showImageModal}>
            <ImagePopUpDownload
              image={data?.employeeId?.employeeImage}
              setShowModal={setShowImageModal}
              imagename={`employee_${data?.employeeId?._id}`}
            />
          </Dialog>
        )}
        <div className="mb-5 text-center md:text-left lg:col-span-10 col-span-6 row-span-1 self-center lg:self-auto">
          <button
            type="button"
            className="font-bold text-blue-600 text-xl"
            onClick={() => navigate(`/admin/company/employee/details/${data?._id}`)}
          >
            {data?.employeeId?.firstName} {data?.employeeId?.lastName}
          </button>
          <p className="textgrey text-base uppercase">
            {data?.designation} (
            {moment(data?.joiningDate).format("DD/MMM/YYYY")} -{" "}
            {data?.leavingDate
              ? moment(data?.leavingDate).format("DD/MMM/YYYY")
              : "Present"}
            )
          </p>
        </div>
        <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center">
          <div className="md:text-left">
            <h3 className="textgrey text-xs">Phone Number</h3>
            <p>{data?.employeeId?.phoneNumber}</p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-center">Blood Group</h3>
            <p className="md:text-center">{data?.employeeId?.bloodGroup}</p>
          </div>
          {/* <div>
            <h3 className="textgrey text-xs md:text-center">Address</h3>
            <p className="md:text-center">
              {data?.employeeId?.address}, {data?.employeeId?.city},{" "}
              {data?.employeeId?.state}, {data?.employeeId?.country},{" "}
              {data?.employeeId?.zipCode}
            </p>
          </div> */}
          <div>
            <h3 className="textgrey text-xs md:text-center">
              Blocked By Company
            </h3>
            <p className="md:text-center">
              {data?.blockedByCompany ? (
                <Badge variant="destructive">Blocked</Badge>
              ) : (
                <Badge className="bg-green-700 hover:bg-green-900">
                  Active
                </Badge>
              )}
            </p>
          </div>
          <div className="md:text-right">
            <h3 className="textgrey text-xs">Working Currently</h3>
            <p>{data?.isWorking ? "YES" : "No"}</p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-left">Employee Type</h3>
            <p className="md:text-left">{data?.employeeType}</p>
          </div>
          {data?.isWorking ? (
            <>
              <Dialog open={showTerminateModal}>
              <button onClick={() => setShowTerminateModal(true)} className="primaryButton bg-red-600 hover:bg-red-800">
                Terminate Employee
              </button>
              <Terminate employeeId={data?.employeeId?._id} setShowModal={setShowTerminateModal} />
            </Dialog>
            </>
          ) : (
            <>
            <div>
              <h3 className="textgrey text-xs md:text-center">
                Last Working Day
              </h3>
              <p className="md:text-center">
                {moment(data?.leavingDate).format("DD/MMM/YYYY")}
              </p>
            </div>
            {/* <Link to={`/admin/employee/addToCompany/${data?.employeeId?._id}`} className="primaryButton">Add To Company</Link> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmployeeListCard;
