import axios from 'axios';

const getAddressFromCoordinates = async (latitude, longitude) => {
  const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
  try {
    const response = await axios.get(url);
    if (response.data && response.data.address) {
      const { road, suburb, city, state, postcode, country } = response.data.address;
      return {
        success: true,
        address: `${road || ''}, ${suburb || ''}, ${city || ''}, ${state || ''}, ${postcode || ''}, ${country || ''}`.replace(/, ,/g, ','),
      };
    } else {
      return { success: false, error: 'No address found' };
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export default getAddressFromCoordinates;
