import { GET_COMPANY_DASHBOARD_EMPLOYEE_GRAPH, GET_COMPANY_DASHBOARD_EMPLOYEE_GRAPH_ERROR, GET_COMPANY_DASHBOARD_EMPLOYEE_GRAPH_SUCCESS, GET_COMPANY_DASHBOARD_EXPENSE_GRAPH, GET_COMPANY_DASHBOARD_EXPENSE_GRAPH_ERROR, GET_COMPANY_DASHBOARD_EXPENSE_GRAPH_SUCCESS, GET_COMPANY_DASHBOARD_EXPENSE_PIECHART, GET_COMPANY_DASHBOARD_EXPENSE_PIECHART_ERROR, GET_COMPANY_DASHBOARD_EXPENSE_PIECHART_SUCCESS, GET_COMPANY_DASHBOARD_TRIP_GRAPH, GET_COMPANY_DASHBOARD_TRIP_GRAPH_ERROR, GET_COMPANY_DASHBOARD_TRIP_GRAPH_SUCCESS, GET_COMPANY_DASHBOARD_TRIP_LIST, GET_COMPANY_DASHBOARD_TRIP_LIST_ERROR, GET_COMPANY_DASHBOARD_TRIP_LIST_SUCCESS, GET_COMPANY_DASHBOARD_TRIP_PENDING_AMOUNT, GET_COMPANY_DASHBOARD_TRIP_PENDING_AMOUNT_ERROR, GET_COMPANY_DASHBOARD_TRIP_PENDING_AMOUNT_SUCCESS, GET_COMPANY_DASHBOARD_TRIP_PIECHART, GET_COMPANY_DASHBOARD_TRIP_PIECHART_ERROR, GET_COMPANY_DASHBOARD_TRIP_PIECHART_SUCCESS, GET_COMPANY_DASHBOARD_TRUCK_GRAPH, GET_COMPANY_DASHBOARD_TRUCK_GRAPH_ERROR, GET_COMPANY_DASHBOARD_TRUCK_GRAPH_SUCCESS, GET_COMPANY_DASHBOARD_TRUCK_PIECHART, GET_COMPANY_DASHBOARD_TRUCK_PIECHART_ERROR, GET_COMPANY_DASHBOARD_TRUCK_PIECHART_SUCCESS } from "../../actions";

export const getCompanyDashboardTruckGraphAction = (info) => ({
    type: GET_COMPANY_DASHBOARD_TRUCK_GRAPH,
    payload: info,
  });
  export const getCompanyDashboardTruckGraphSuccess = (info) => ({
    type: GET_COMPANY_DASHBOARD_TRUCK_GRAPH_SUCCESS,
    payload: info,
  });
  export const getCompanyDashboardTruckGraphError = (error) => ({
    type: GET_COMPANY_DASHBOARD_TRUCK_GRAPH_ERROR,
    payload: error,
  });
  export const getCompanyDashboardTruckPiechartAction = (info) => ({
    type: GET_COMPANY_DASHBOARD_TRUCK_PIECHART,
    payload: info,
  });
  export const getCompanyDashboardTruckPiechartSuccess = (info) => ({
    type: GET_COMPANY_DASHBOARD_TRUCK_PIECHART_SUCCESS,
    payload: info,
  });
  export const getCompanyDashboardTruckPiechartError = (error) => ({
    type: GET_COMPANY_DASHBOARD_TRUCK_PIECHART_ERROR,
    payload: error,
  });
  export const getCompanyDashboardTripGraphAction = (info) => ({
    type: GET_COMPANY_DASHBOARD_TRIP_GRAPH,
    payload: info,
  });
  export const getCompanyDashboardTripGraphSuccess = (info) => ({
    type: GET_COMPANY_DASHBOARD_TRIP_GRAPH_SUCCESS,
    payload: info,
  });
  export const getCompanyDashboardTripGraphError = (error) => ({
    type: GET_COMPANY_DASHBOARD_TRIP_GRAPH_ERROR,
    payload: error,
  });
  export const getCompanyDashboardTripListAction = (info) => ({
    type: GET_COMPANY_DASHBOARD_TRIP_LIST,
    payload: info,
  });
  export const getCompanyDashboardTripListSuccess = (info) => ({
    type: GET_COMPANY_DASHBOARD_TRIP_LIST_SUCCESS,
    payload: info,
  });
  export const getCompanyDashboardTripListError = (error) => ({
    type: GET_COMPANY_DASHBOARD_TRIP_LIST_ERROR,
    payload: error,
  });
  export const getCompanyDashboardTripPiechartAction = (info) => ({
    type: GET_COMPANY_DASHBOARD_TRIP_PIECHART,
    payload: info,
  });
  export const getCompanyDashboardTripPiechartSuccess = (info) => ({
    type: GET_COMPANY_DASHBOARD_TRIP_PIECHART_SUCCESS,
    payload: info,
  });
  export const getCompanyDashboardTripPiechartError = (error) => ({
    type: GET_COMPANY_DASHBOARD_TRIP_PIECHART_ERROR,
    payload: error,
  });
  export const getCompanyDashboardTripPendingAmountAction = (info) => ({
    type: GET_COMPANY_DASHBOARD_TRIP_PENDING_AMOUNT,
    payload: info,
  });
  export const getCompanyDashboardTripPendingAmountSuccess = (info) => ({
    type: GET_COMPANY_DASHBOARD_TRIP_PENDING_AMOUNT_SUCCESS,
    payload: info,
  });
  export const getCompanyDashboardTripPendingAmountError = (error) => ({
    type: GET_COMPANY_DASHBOARD_TRIP_PENDING_AMOUNT_ERROR,
    payload: error,
  });
  export const getCompanyDashboardEmployeeGraphAction = (info) => ({
    type: GET_COMPANY_DASHBOARD_EMPLOYEE_GRAPH,
    payload: info,
  });
  export const getCompanyDashboardEmployeeGraphSuccess = (info) => ({
    type: GET_COMPANY_DASHBOARD_EMPLOYEE_GRAPH_SUCCESS,
    payload: info,
  });
  export const getCompanyDashboardEmployeeGraphError = (error) => ({
    type: GET_COMPANY_DASHBOARD_EMPLOYEE_GRAPH_ERROR,
    payload: error,
  });
  export const getCompanyDashboardExpenseGraphAction = (info) => ({
    type: GET_COMPANY_DASHBOARD_EXPENSE_GRAPH,
    payload: info,
  });
  export const getCompanyDashboardExpenseGraphSuccess = (info) => ({
    type: GET_COMPANY_DASHBOARD_EXPENSE_GRAPH_SUCCESS,
    payload: info,
  });
  export const getCompanyDashboardExpenseGraphError = (error) => ({
    type: GET_COMPANY_DASHBOARD_EXPENSE_GRAPH_ERROR,
    payload: error,
  });
  export const getCompanyDashboardExpensePiechartAction = (info) => ({
    type: GET_COMPANY_DASHBOARD_EXPENSE_PIECHART,
    payload: info,
  });
  export const getCompanyDashboardExpensePiechartSuccess = (info) => ({
    type: GET_COMPANY_DASHBOARD_EXPENSE_PIECHART_SUCCESS,
    payload: info,
  });
  export const getCompanyDashboardExpensePiechartError = (error) => ({
    type: GET_COMPANY_DASHBOARD_EXPENSE_PIECHART_ERROR,
    payload: error,
  });