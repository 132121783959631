import {
    DELETE_ADMIN_TRUCK_SUBSCRIPTION,
    DELETE_ADMIN_TRUCK_SUBSCRIPTION_ERROR,
    DELETE_ADMIN_TRUCK_SUBSCRIPTION_SUCCESS,
    DELETE_ADMIN_TRUCK,
    DELETE_ADMIN_TRUCK_ERROR,
    DELETE_ADMIN_TRUCK_SUCCESS,
    GET_ADMIN_TRUCK_BY_TRUCKID,
  GET_ADMIN_TRUCK_BY_TRUCKID_ERROR,
  GET_ADMIN_TRUCK_BY_TRUCKID_SUCCESS,
  GET_ADMIN_TRUCK_LIST,
  GET_ADMIN_TRUCK_LIST_COMPANYID,
  GET_ADMIN_TRUCK_LIST_COMPANYID_ERROR,
  GET_ADMIN_TRUCK_LIST_COMPANYID_SUCCESS,
  GET_ADMIN_TRUCK_LIST_ERROR,
  GET_ADMIN_TRUCK_LIST_SUCCESS,
  GET_ADMIN_TRUCK_SUBSCRIPTION_LIST,
  GET_ADMIN_TRUCK_SUBSCRIPTION_LIST_ERROR,
  GET_ADMIN_TRUCK_SUBSCRIPTION_LIST_SUCCESS,
  PATCH_ADMIN_BLOCK_COMPANY_TRUCK,
  PATCH_ADMIN_BLOCK_COMPANY_TRUCK_ERROR,
  PATCH_ADMIN_BLOCK_COMPANY_TRUCK_SUCCESS,
  POST_ADMIN_TRUCK_PURCHASE,
  POST_ADMIN_TRUCK_PURCHASE_ERROR,
  POST_ADMIN_TRUCK_PURCHASE_SUCCESS,
  POST_ADMIN_TRUCK_SELL,
  POST_ADMIN_TRUCK_SELL_ERROR,
  POST_ADMIN_TRUCK_SELL_SUCCESS,
  POST_ADMIN_TRUCK_SUBSCRIPTION,
  POST_ADMIN_TRUCK_SUBSCRIPTION_ERROR,
  POST_ADMIN_TRUCK_SUBSCRIPTION_SUCCESS,
  GET_ADMIN_TRUCK_SUBSCRIPTION_DETAILS,
  GET_ADMIN_TRUCK_SUBSCRIPTION_DETAILS_SUCCESS,
  GET_ADMIN_TRUCK_SUBSCRIPTION_DETAILS_ERROR,
} from "../../actions";

const INIT_STATE = {
  getAdminTruckList: {},
  getAdminTruckListLoading: false,
  getAdminTruckListError: "",
  getAdminTruckSubscriptionDetails: {},
  getAdminTruckSubscriptionDetailsLoading: false,
  getAdminTruckSubscriptionDetailsError: "",
  getAdminTruckbyId: {},
  getAdminTruckbyIdLoading: false,
  getAdminTruckbyIdError: "",
  getAdminTruckbyCompanyId: {},
  getAdminTruckbyCompanyIdLoading: false,
  getAdminTruckbyCompanyIdError: "",
  getAdminTruckSubscriptioList: {},
  getAdminTruckSubscriptioListLoading: false,
  getAdminTruckSubscriptioListError: "",
  postAdminTruckSubscription: {},
  postAdminTruckSubscriptionLoading: false,
  postAdminTruckSubscriptionError: "",
  postAdminTruckSell: {},
  postAdminTruckSellLoading: false,
  postAdminTruckSellError: "",
  postAdminTruckPurchase: {},
  postAdminTruckPurchaseLoading: false,
  postAdminTruckPurchaseError: "",
  deleteAdminTruck: {},
  deleteAdminTruckLoading: false,
  deleteAdminTruckError: "",
  deleteAdminTruckSubscription: {},
  deleteAdminTruckSubscriptionLoading: false,
  deleteAdminTruckSubscriptionError: "",
  patchAdminBlockCompanyTruck: {},
  patchAdminBlockCompanyTruckLoading: false,
  patchAdminBlockCompanyTruckError: "",
};
const AdminTruckSection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ADMIN_TRUCK_LIST:
      return {
        ...state,
        getAdminTruckList: {},
        getAdminTruckListLoading: true,
      };
    case GET_ADMIN_TRUCK_LIST_SUCCESS:
      return {
        ...state,
        getAdminTruckList: action.payload,
        getAdminTruckListLoading: false,
        getAdminTruckListError: "",
      };

    case GET_ADMIN_TRUCK_LIST_ERROR:
      return {
        ...state,
        getAdminTruckListLoading: false,
        getAdminTruckListError: action.payload,
      };
    case GET_ADMIN_TRUCK_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        getAdminTruckSubscriptionDetails: {},
        getAdminTruckSubscriptionDetailsLoading: true,
      };
    case GET_ADMIN_TRUCK_SUBSCRIPTION_DETAILS_SUCCESS:
      return {
        ...state,
        getAdminTruckSubscriptionDetails: action.payload,
        getAdminTruckSubscriptionDetailsLoading: false,
        getAdminTruckSubscriptionDetailsError: "",
      };

    case GET_ADMIN_TRUCK_SUBSCRIPTION_DETAILS_ERROR:
      return {
        ...state,
        getAdminTruckSubscriptionDetailsLoading: false,
        getAdminTruckSubscriptionDetailsError: action.payload,
      };
    case GET_ADMIN_TRUCK_BY_TRUCKID:
      return {
        ...state,
        getAdminTruckbyId: {},
        getAdminTruckbyIdLoading: true,
      };
    case GET_ADMIN_TRUCK_BY_TRUCKID_SUCCESS:
      return {
        ...state,
        getAdminTruckbyId: action.payload,
        getAdminTruckbyIdLoading: false,
        getAdminTruckbyIdError: "",
      };

    case GET_ADMIN_TRUCK_BY_TRUCKID_ERROR:
      return {
        ...state,
        getAdminTruckbyIdLoading: false,
        getAdminTruckbyIdError: action.payload,
      };
    case GET_ADMIN_TRUCK_LIST_COMPANYID:
      return {
        ...state,
        getAdminTruckbyCompanyId: {},
        getAdminTruckbyCompanyIdLoading: true,
      };
    case GET_ADMIN_TRUCK_LIST_COMPANYID_SUCCESS:
      return {
        ...state,
        getAdminTruckbyCompanyId: action.payload,
        getAdminTruckbyCompanyIdLoading: false,
        getAdminTruckbyCompanyIdError: "",
      };

    case GET_ADMIN_TRUCK_LIST_COMPANYID_ERROR:
      return {
        ...state,
        getAdminTruckbyCompanyIdLoading: false,
        getAdminTruckbyCompanyIdError: action.payload,
      };
    case GET_ADMIN_TRUCK_SUBSCRIPTION_LIST:
      return {
        ...state,
        getAdminTruckSubscriptioList: {},
        getAdminTruckSubscriptioListLoading: true,
      };
    case GET_ADMIN_TRUCK_SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        getAdminTruckSubscriptioList: action.payload,
        getAdminTruckSubscriptioListLoading: false,
        getAdminTruckSubscriptioListError: "",
      };

    case GET_ADMIN_TRUCK_SUBSCRIPTION_LIST_ERROR:
      return {
        ...state,
        getAdminTruckSubscriptioListLoading: false,
        getAdminTruckSubscriptioListError: action.payload,
      };

    case POST_ADMIN_TRUCK_SUBSCRIPTION:
      return {
        ...state,
        postAdminTruckSubscription: {},
        postAdminTruckSubscriptionLoading: true,
      };
    case POST_ADMIN_TRUCK_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        postAdminTruckSubscription: action.payload,
        postAdminTruckSubscriptionLoading: false,
        postAdminTruckSubscriptionError: "",
      };

    case POST_ADMIN_TRUCK_SUBSCRIPTION_ERROR:
      return {
        ...state,
        postAdminTruckSubscriptionLoading: false,
        postAdminTruckSubscriptionError: action.payload,
      };
    case POST_ADMIN_TRUCK_SELL:
      return {
        ...state,
        postAdminTruckSell: {},
        postAdminTruckSellLoading: true,
      };
    case POST_ADMIN_TRUCK_SELL_SUCCESS:
      return {
        ...state,
        postAdminTruckSell: action.payload,
        postAdminTruckSellLoading: false,
        postAdminTruckSellError: "",
      };

    case POST_ADMIN_TRUCK_SELL_ERROR:
      return {
        ...state,
        postAdminTruckSellLoading: false,
        postAdminTruckSellError: action.payload,
      };
    case POST_ADMIN_TRUCK_PURCHASE:
      return {
        ...state,
        postAdminTruckPurchase: {},
        postAdminTruckPurchaseLoading: true,
      };
    case POST_ADMIN_TRUCK_PURCHASE_SUCCESS:
      return {
        ...state,
        postAdminTruckPurchase: action.payload,
        postAdminTruckPurchaseLoading: false,
        postAdminTruckPurchaseError: "",
      };

    case POST_ADMIN_TRUCK_PURCHASE_ERROR:
      return {
        ...state,
        postAdminTruckPurchaseLoading: false,
        postAdminTruckPurchaseError: action.payload,
      };
    case DELETE_ADMIN_TRUCK:
      return {
        ...state,
        deleteAdminTruck: {},
        deleteAdminTruckLoading: true,
      };
    case DELETE_ADMIN_TRUCK_SUCCESS:
      return {
        ...state,
        deleteAdminTruck: action.payload,
        deleteAdminTruckLoading: false,
        deleteAdminTruckError: "",
      };
    case DELETE_ADMIN_TRUCK_ERROR:
      return {
        ...state,
        deleteAdminTruckLoading: false,
        deleteAdminTruckError: action.payload,
      };
    case DELETE_ADMIN_TRUCK_SUBSCRIPTION:
      return {
        ...state,
        deleteAdminTruckSubscription: {},
        deleteAdminTruckSubscriptionLoading: true,
      };
    case DELETE_ADMIN_TRUCK_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        deleteAdminTruckSubscription: action.payload,
        deleteAdminTruckSubscriptionLoading: false,
        deleteAdminTruckSubscriptionError: "",
      };

    case DELETE_ADMIN_TRUCK_SUBSCRIPTION_ERROR:
      return {
        ...state,
        deleteAdminTruckSubscriptionLoading: false,
        deleteAdminTruckSubscriptionError: action.payload,
      };
    case PATCH_ADMIN_BLOCK_COMPANY_TRUCK:
      return {
        ...state,
        patchAdminBlockCompanyTruck: {},
        patchAdminBlockCompanyTruckLoading: true,
      };
    case PATCH_ADMIN_BLOCK_COMPANY_TRUCK_SUCCESS:
      return {
        ...state,
        patchAdminBlockCompanyTruck: action.payload,
        patchAdminBlockCompanyTruckLoading: false,
        patchAdminBlockCompanyTruckError: "",
      };

    case PATCH_ADMIN_BLOCK_COMPANY_TRUCK_ERROR:
      return {
        ...state,
        patchAdminBlockCompanyTruckLoading: false,
        patchAdminBlockCompanyTruckError: action.payload,
      };
    default:
      return { ...state };
  }
};

export default AdminTruckSection;
