import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { getAdminDashboardExpensesFilterAction } from "../../../redux/admin/dashboard/action";
import { getCompanyListAction } from "../../../redux/admin/company/action";

function ExpensesCard() {
  const dispatch = useDispatch();
  const {
    getAdminDashboardExpensesFilter,
    getAdminDashboardExpensesFilterLoading,
    getAdminDashboardExpensesFilterError,
  } = useSelector((state) => state.AdminDashboardSection);
  const { getCompanyList, getCompanyListLoading, getCompanyListError } =
    useSelector((state) => state.AdminCompanysection);

  const [companyList, setCompanyList] = useState([]);
  const [company, setCompany] = useState("");
  const [year, setYear] = useState("2024");
  const [data, setData] = useState({});

  useEffect(() => {
    dispatch(getCompanyListAction());
  }, [dispatch]);

  useEffect(() => {
    if (getAdminDashboardExpensesFilter?.status === 200) {
      setData(getAdminDashboardExpensesFilter.data);
    }
  }, [getAdminDashboardExpensesFilter]);

  useEffect(() => {
    if (getCompanyList?.status === 200) {
      setCompanyList(getCompanyList.data);
    }
  }, [getCompanyList]);

  useEffect(() => {
    initialLoad(year);
  }, [year]);

  const initialLoad = (yearValue) => {
    const queryParams = {
      year: yearValue,
    };

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    dispatch(getAdminDashboardExpensesFilterAction(queryString));
  };

  // Transform graphData for the chart
  const graphData = data.graphData || {};
  const periods = new Set();

  // Collect all periods
  Object.values(graphData).forEach(expenses => {
    expenses.forEach(expense => periods.add(expense.period));
  });

  // Convert the Set to an Array and sort it
  const sortedPeriods = Array.from(periods).sort();

  // Create chart series data
  const chartData = Object.keys(graphData).map(expenseName => ({
    name: expenseName,
    data: sortedPeriods.map(period => {
      const expense = (graphData[expenseName] || []).find(e => e.period === period);
      return { x: period, y: expense ? expense.totalExpense : 0 };
    }),
  }));

  return (
    <div className="bg-white p-4 h-full">
      <div className="flex justify-between">
        <div className="text-4xl font-bold mb-4">Total Expense</div>
        <div className="flex gap-4">
          <select
            value={year}
            className="border border-gray-200"
            onChange={(e) => setYear(e.target.value)}
          >
            <option value="">All</option>
            <option value="2024">2024</option>
            <option value="2023">2023</option>
            <option value="2022">2022</option>
          </select>
        </div>
      </div>
      <div className="text-3xl font-bold mb-4">
        {data.overallCost}
        <label> INR</label>
      </div>
      <div className="mt-8">
        <Chart
          options={{
            chart: {
              id: "expenses-chart",
              toolbar: {
                show: false,
              },
            },
            xaxis: {
              categories: sortedPeriods,
              title: {
                text: "Period",
              },
            },
            stroke: {
              curve: "smooth",
            },
            colors: ["#2E93fA", "#66DA26", "#546E7A", "#E91E63", "#FF9800"],
            yaxis: {
              title: {
                text: "Cost",
              },
            },
            legend: {
              position: "top",
            },
          }}
          series={chartData}
          type="line"
          height={350}
        />
      </div>
      <div className="flex flex-col border-t border-t-gray-300">
        <div className="flex justify-between border-b py-2 border-b-gray-300">
          <div className="text-xl capitalize font-semibold">Expense Name</div>
          <div className="text-xl capitalize font-semibold">Cost</div>
        </div>
        {data?.expenseBreakdown?.map((item) => (
          <div key={item.expenseName} className="flex justify-between border-b py-2 border-b-gray-300">
            <div className="text-lg capitalize">{item.expenseName}</div>
            <div className="text-gray-600">{item.totalCost}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ExpensesCard;
