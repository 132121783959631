import React, { useEffect, useState } from "react";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import {
  getCompanyEmployeeDetailsAction,
  patchCompanyEmployeeTerminateAction,
} from "../../../redux/company/employee/action";
import DatePicker from "../../datepicker/datePicker";

function Terminate({ id, setShowModal }) {
  const dispatch = useDispatch();
  const {
    patchCompanyEmployeeTerminate,
    patchCompanyEmployeeTerminateLoading,
    patchCompanyEmployeeTerminateError,
  } = useSelector((state) => state.CompanyEmployeesection);
  let [searchParams, setSearchParams] = useSearchParams();

  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (loader) {
      if (patchCompanyEmployeeTerminate?.status === 200) {
        toast.success(patchCompanyEmployeeTerminate.message, {
          id: "terminate-toast",
        });
        setLoader(false);

        dispatch(getCompanyEmployeeDetailsAction());
        setShowModal(false);
      }
    }
  }, [patchCompanyEmployeeTerminate]);
  useEffect(() => {
    if (loader) {
      if (
        patchCompanyEmployeeTerminateError?.status === 400 ||
        patchCompanyEmployeeTerminateError?.status === 404
      ) {
        toast.error(patchCompanyEmployeeTerminateError.message, {
          id: "terminate-toast",
        });
        setLoader(false);
      } else if (patchCompanyEmployeeTerminateError?.status === 500) {
        toast.error(patchCompanyEmployeeTerminateError?.error, {
          id: "terminate-toast",
        });
        setLoader(false);
      }
    }
  }, [patchCompanyEmployeeTerminateError]);
  const validationSchema = Yup.object({
    deleteValue: Yup.string()
      .required("This field is required")
      .oneOf(["TERMINATE"], 'You must type "TERMINATE" to confirm'),
    leavingDate: Yup.string().required("This field is required"),
  });
  const onSubmit = (values) => {
    if (values.deleteValue === "TERMINATE") {
      dispatch(patchCompanyEmployeeTerminateAction({ id: id, body: values }));
      toast.loading("Terminate Employee...", { id: "terminate-toast" });
      setLoader(true);
    }
  };
  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle>Terminate Employee</DialogTitle>
      </DialogHeader>
      <div className="text-lg mt-5 font-semibold">
        Are you sure you want to{" "}
        <span className="text-red-500 font-bold">TERMINATE</span> this employee?
      </div>
      <Formik
        initialValues={{ deleteValue: "" }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="">
            <div className="mb-4">
              <label htmlFor="deleteValue" className="text-gray-700">
                To confirm, type <span className="font-bold">"TERMINATE"</span>{" "}
                in Capital Letter the box below*
              </label>
              <Field
                type="text"
                id="deleteValue"
                name="deleteValue"
                placeholder="TERMINATE"
              />
              <ErrorMessage
                name="deleteValue"
                component="div"
                className="text-red-600"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="leavingDate">Termination Date *</label>
              <DatePicker id="leavingDate" name="leavingDate" />
              <ErrorMessage
                name="leavingDate"
                component="div"
                className="error"
              />
            </div>
            <DialogFooter>
              <button
                className="ghostButton"
                onClick={() => setShowModal(false)}
                // disabled={deleteCompanyFuelLoading}
                type="button"
              >
                Cancel
              </button>
              <button
                className="primaryButton bg-red-600 hover:bg-red-800 border-red-600 hover:border-red-800 w-fit"
                type="submit"
                // disabled={deleteCompanyFuelLoading}
              >
                TERMINATE
              </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default Terminate;
