import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import api from "../../../../auth/api.jsx";
import {
  GET_EXPENSE_TRIP,
  GET_EXPENSE_TRIP_BY_TRIP,
  GET_EXPENSE_TRIP_BY_ID,
  POST_EXPENSE_TRIP,
  PATCH_EXPENSE_TRIP,
  DELETE_EXPENSE_TRIP,
  GET_EXPENSE_TRIP_FILTER,
} from "../../../actions.js";
import {
  getExpenseTripSuccess,
  getExpenseTripError,
  getExpenseTripByTripSuccess,
  getExpenseTripByTripError,
  getExpenseTripByIdSuccess,
  getExpenseTripByIdError,
  postExpenseTripSuccess,
  postExpenseTripError,
  patchExpenseTripSuccess,
  patchExpenseTripError,
  deleteExpenseTripSuccess,
  deleteExpenseTripError,
  getExtraExpenseTripFilterSuccess,
  getExtraExpenseTripFilterError,
} from "./action.js";

// Async function to handle API call
const getExpenseTripAsync = async () => {
  try {
    const response = await api.get("/company/expenseTrip/getAll");
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle GET_EXPENSE_TRIP action
function* getExpenseTripCall() {
  try {
    const data = yield call(getExpenseTripAsync); // Call async function without action payload
    yield put(getExpenseTripSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getExpenseTripError(error)); // Dispatch error action with error object
  }
}

export function* watchGetExpenseTripData() {
  yield takeEvery(GET_EXPENSE_TRIP, getExpenseTripCall);
}

// Async function to handle API call
const getExpenseTripByTripAsync = async (payload) => {
  try {
    const response = await api.get(`/company/expenseTrip/get/${payload.payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle GET_EXPENSE_TRIP_BY_TRIP action
function* getExpenseTripByTripCall(action) {
  try {
    const data = yield call(getExpenseTripByTripAsync, action); // Call async function with action payload
    yield put(getExpenseTripByTripSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getExpenseTripByTripError(error)); // Dispatch error action with error object
  }
}

export function* watchGetExpenseTripByTripData() {
  yield takeEvery(GET_EXPENSE_TRIP_BY_TRIP, getExpenseTripByTripCall);
}

// Async function to handle API call
const getExpenseTripByIdAsync = async (payload) => {
  try {
    const response = await api.get(`/company/expenseTrip/${payload.payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle GET_EXPENSE_TRIP_BY_ID action
function* getExpenseTripByIdCall(action) {
  try {
    const data = yield call(getExpenseTripByIdAsync, action); // Call async function with action payload
    yield put(getExpenseTripByIdSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getExpenseTripByIdError(error)); // Dispatch error action with error object
  }
}

export function* watchGetExpenseTripByIdData() {
  yield takeEvery(GET_EXPENSE_TRIP_BY_ID, getExpenseTripByIdCall);
}

// Async function to handle API call
const postExpenseTripAsync = async (payload) => {
  try {
    const response = await api.post("/company/expenseTrip/add", payload.payload);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_EXPENSE_TRIP action
function* postExpenseTripCall(action) {
  try {
    const data = yield call(postExpenseTripAsync, action); // Call async function with action payload
    yield put(postExpenseTripSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postExpenseTripError(error)); // Dispatch error action with error object
  }
}

export function* watchPostExpenseTripData() {
  yield takeEvery(POST_EXPENSE_TRIP, postExpenseTripCall);
}

// Async function to handle API call
const patchExpenseTripAsync = async (payload) => {
  try {
    const response = await api.patch(`/company/expenseTrip/update/${payload.payload.id}`, payload.payload.body);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle PATCH_EXPENSE_TRIP action
function* patchExpenseTripCall(action) {
  try {
    const data = yield call(patchExpenseTripAsync, action); // Call async function with action payload
    yield put(patchExpenseTripSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(patchExpenseTripError(error)); // Dispatch error action with error object
  }
}

export function* watchPatchExpenseTripData() {
  yield takeEvery(PATCH_EXPENSE_TRIP, patchExpenseTripCall);
}

// Async function to handle API call
const deleteExpenseTripAsync = async (payload) => {
  try {
    const response = await api.delete(`/company/expenseTrip/${payload.payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle DELETE_EXPENSE_TRIP action
function* deleteExpenseTripCall(action) {
  try {
    const data = yield call(deleteExpenseTripAsync, action); // Call async function with action payload
    yield put(deleteExpenseTripSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(deleteExpenseTripError(error)); // Dispatch error action with error object
  }
}

export function* watchDeleteExpenseTripData() {
  yield takeEvery(DELETE_EXPENSE_TRIP, deleteExpenseTripCall);
}

// Async function to handle API call
const filterExpenseTripAsync = async (payload) => {
  try {
    const response = await api.post("/company/expenseTrip/filter", payload.payload);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle GET_EXPENSE_TRUCK_FILTER action
function* filterExpenseTripCall(action) {
  try {
    const data = yield call(filterExpenseTripAsync, action); // Call async function with action payload
    console.log(data)
    yield put(getExtraExpenseTripFilterSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getExtraExpenseTripFilterError(error)); // Dispatch error action with error object
  }
}

export function* watchFilterExpenseTripData() {
  yield takeEvery(GET_EXPENSE_TRIP_FILTER, filterExpenseTripCall);
}

// Root Saga
export default function* expenseTripSaga() {
  yield all([
    fork(watchGetExpenseTripData),
    fork(watchGetExpenseTripByTripData),
    fork(watchGetExpenseTripByIdData),
    fork(watchPostExpenseTripData),
    fork(watchPatchExpenseTripData),
    fork(watchDeleteExpenseTripData),
    fork(watchFilterExpenseTripData),
  ]);
}
