const {
  GET_COMPANY_TRIP_DETAILS,
  GET_COMPANY_TRIP_DETAILS_SUCCESS,
  GET_COMPANY_TRIP_DETAILS_ERROR,
  GET_COMPANY_TRIP_DETAILS_ID,
  GET_COMPANY_TRIP_DETAILS_ID_SUCCESS,
  GET_COMPANY_TRIP_DETAILS_ID_ERROR,
  POST_COMPANY_TRIP_DETAILS,
  POST_COMPANY_TRIP_DETAILS_SUCCESS,
  POST_COMPANY_TRIP_DETAILS_ERROR,
  PATCH_COMPANY_TRIP_DETAILS,
  PATCH_COMPANY_TRIP_DETAILS_SUCCESS,
  PATCH_COMPANY_TRIP_DETAILS_ERROR,
  DELETE_COMPANY_TRIP,
  DELETE_COMPANY_TRIP_SUCCESS,
  DELETE_COMPANY_TRIP_ERROR,
  GET_COMPANY_TRIP_DETAILS_FILTER,
  GET_COMPANY_TRIP_DETAILS_FILTER_SUCCESS,
  GET_COMPANY_TRIP_DETAILS_FILTER_ERROR,
  PATCH_COMPANY_TRIP_PAYMENT,
  PATCH_COMPANY_TRIP_PAYMENT_SUCCESS,
  PATCH_COMPANY_TRIP_PAYMENT_ERROR,
  GET_COMPANY_TRIP_PLANNED_DETAILS_FILTER,
  GET_COMPANY_TRIP_PLANNED_DETAILS_FILTER_SUCCESS,
  GET_COMPANY_TRIP_PLANNED_DETAILS_FILTER_ERROR,
  GET_COMPANY_TRIP_RUNNING_DETAILS_FILTER,
  GET_COMPANY_TRIP_RUNNING_DETAILS_FILTER_SUCCESS,
  GET_COMPANY_TRIP_RUNNING_DETAILS_FILTER_ERROR,
  GET_COMPANY_TRIP_END_DETAILS_FILTER,
  GET_COMPANY_TRIP_END_DETAILS_FILTER_SUCCESS,
  GET_COMPANY_TRIP_END_DETAILS_FILTER_ERROR,
  GET_COMPANY_TRIP_COMPLETE_DETAILS_FILTER,
  GET_COMPANY_TRIP_COMPLETE_DETAILS_FILTER_SUCCESS,
  GET_COMPANY_TRIP_COMPLETE_DETAILS_FILTER_ERROR,
} = require("../../actions");

const INIT_STATE = {
  getCompanyTripDetails: {},
  getCompanyTripDetailsLoading: false,
  getCompanyTripDetailsError: "",
  getCompanyTripDetailsId: {},
  getCompanyTripDetailsIdLoading: false,
  getCompanyTripDetailsIdError: "",
  getCompanyTripDetailsFilter: {},
  getCompanyTripDetailsFilterLoading: false,
  getCompanyTripDetailsFilterError: "",
  getCompanyTripPlannedDetailsFilter: {},
  getCompanyTripPlannedDetailsFilterLoading: false,
  getCompanyTripPlannedDetailsFilterError: "",
  getCompanyTripRunningDetailsFilter: {},
  getCompanyTripRunningDetailsFilterLoading: false,
  getCompanyTripRunningDetailsFilterError: "",
  getCompanyTripEndDetailsFilter: {},
  getCompanyTripEndDetailsFilterLoading: false,
  getCompanyTripEndDetailsFilterError: "",
  getCompanyTripCompletedDetailsFilter: {},
  getCompanyTripCompletedDetailsFilterLoading: false,
  getCompanyTripCompletedDetailsFilterError: "",
  postCompanyTripDetails: {},
  postCompanyTripDetailsLoading: false,
  postCompanyTripDetailsError: "",
  patchCompanyTripDetails: {},
  patchCompanyTripDetailsLoading: false,
  patchCompanyTripDetailsError: "",
  patchCompanyTripPayment: {},
  patchCompanyTripPaymentLoading: false,
  patchCompanyTripPaymentError: "",
  deleteCompanyTrip: {},
  deleteCompanyTripLoading: false,
  deleteCompanyTripError: "",
};

const CompanyTripsection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_TRIP_DETAILS:
      return {
        ...state,
        getCompanyTripDetails: {},
        getCompanyTripDetailsLoading: true,
      };
    case GET_COMPANY_TRIP_DETAILS_SUCCESS:
      return {
        ...state,
        getCompanyTripDetails: action.payload,
        getCompanyTripDetailsLoading: false,
        getCompanyTripDetailsError: "",
      };

    case GET_COMPANY_TRIP_DETAILS_ERROR:
      return {
        ...state,
        getCompanyTripDetailsLoading: false,
        getCompanyTripDetailsError: action.payload,
      };
    case GET_COMPANY_TRIP_DETAILS_ID:
      return {
        ...state,
        getCompanyTripDetailsId: {},
        getCompanyTripDetailsIdLoading: true,
      };
    case GET_COMPANY_TRIP_DETAILS_ID_SUCCESS:
      return {
        ...state,
        getCompanyTripDetailsId: action.payload,
        getCompanyTripDetailsIdLoading: false,
        getCompanyTripDetailsIdError: "",
      };

    case GET_COMPANY_TRIP_DETAILS_ID_ERROR:
      return {
        ...state,
        getCompanyTripDetailsIdLoading: false,
        getCompanyTripDetailsIdError: action.payload,
      };
    case GET_COMPANY_TRIP_DETAILS_FILTER:
      return {
        ...state,
        getCompanyTripDetailsFilter: {},
        getCompanyTripDetailsFilterLoading: true,
      };
    case GET_COMPANY_TRIP_DETAILS_FILTER_SUCCESS:
      return {
        ...state,
        getCompanyTripDetailsFilter: action.payload,
        getCompanyTripDetailsFilterLoading: false,
        getCompanyTripDetailsFilterError: "",
      };

    case GET_COMPANY_TRIP_DETAILS_FILTER_ERROR:
      return {
        ...state,
        getCompanyTripDetailsFilterLoading: false,
        getCompanyTripDetailsFilterError: action.payload,
      };
    case GET_COMPANY_TRIP_PLANNED_DETAILS_FILTER:
      return {
        ...state,
        getCompanyTripPlannedDetailsFilter: {},
        getCompanyTripPlannedDetailsFilterLoading: true,
      };
    case GET_COMPANY_TRIP_PLANNED_DETAILS_FILTER_SUCCESS:
      return {
        ...state,
        getCompanyTripPlannedDetailsFilter: action.payload,
        getCompanyTripPlannedDetailsFilterLoading: false,
        getCompanyTripPlannedDetailsFilterError: "",
      };

    case GET_COMPANY_TRIP_PLANNED_DETAILS_FILTER_ERROR:
      return {
        ...state,
        getCompanyTripPlannedDetailsFilterLoading: false,
        getCompanyTripPlannedDetailsFilterError: action.payload,
      };
      case GET_COMPANY_TRIP_RUNNING_DETAILS_FILTER:
      return {
        ...state,
        getCompanyTripRunningDetailsFilter: {},
        getCompanyTripRunningDetailsFilterLoading: true,
      };
    case GET_COMPANY_TRIP_RUNNING_DETAILS_FILTER_SUCCESS:
      return {
        ...state,
        getCompanyTripRunningDetailsFilter: action.payload,
        getCompanyTripRunningDetailsFilterLoading: false,
        getCompanyTripRunningDetailsFilterError: "",
      };

    case GET_COMPANY_TRIP_RUNNING_DETAILS_FILTER_ERROR:
      return {
        ...state,
        getCompanyTripRunningDetailsFilterLoading: false,
        getCompanyTripRunningDetailsFilterError: action.payload,
      };
      case GET_COMPANY_TRIP_END_DETAILS_FILTER:
      return {
        ...state,
        getCompanyTripEndDetailsFilter: {},
        getCompanyTripEndDetailsFilterLoading: true,
      };
    case GET_COMPANY_TRIP_END_DETAILS_FILTER_SUCCESS:
      return {
        ...state,
        getCompanyTripEndDetailsFilter: action.payload,
        getCompanyTripEndDetailsFilterLoading: false,
        getCompanyTripEndDetailsFilterError: "",
      };

    case GET_COMPANY_TRIP_END_DETAILS_FILTER_ERROR:
      return {
        ...state,
        getCompanyTripEndDetailsFilterLoading: false,
        getCompanyTripEndDetailsFilterError: action.payload,
      };
      case GET_COMPANY_TRIP_COMPLETE_DETAILS_FILTER:
      return {
        ...state,
        getCompanyTripCompletedDetailsFilter: {},
        getCompanyTripCompletedDetailsFilterLoading: true,
      };
    case GET_COMPANY_TRIP_COMPLETE_DETAILS_FILTER_SUCCESS:
      return {
        ...state,
        getCompanyTripCompletedDetailsFilter: action.payload,
        getCompanyTripCompletedDetailsFilterLoading: false,
        getCompanyTripCompletedDetailsFilterError: "",
      };

    case GET_COMPANY_TRIP_COMPLETE_DETAILS_FILTER_ERROR:
      return {
        ...state,
        getCompanyTripCompletedDetailsFilterLoading: false,
        getCompanyTripCompletedDetailsFilterError: action.payload,
      };
      case POST_COMPANY_TRIP_DETAILS:
      return {
        ...state,
        postCompanyTripDetails: {},
        postCompanyTripDetailsLoading: true,
      };
    case POST_COMPANY_TRIP_DETAILS_SUCCESS:
      return {
        ...state,
        postCompanyTripDetails: action.payload,
        postCompanyTripDetailsLoading: false,
        postCompanyTripDetailsError: "",
      };

    case POST_COMPANY_TRIP_DETAILS_ERROR:
      return {
        ...state,
        postCompanyTripDetailsLoading: false,
        postCompanyTripDetailsError: action.payload,
      };
    case PATCH_COMPANY_TRIP_DETAILS:
      return {
        ...state,
        patchCompanyTripDetails: {},
        patchCompanyTripDetailsLoading: true,
      };
    case PATCH_COMPANY_TRIP_DETAILS_SUCCESS:
      return {
        ...state,
        patchCompanyTripDetails: action.payload,
        patchCompanyTripDetailsLoading: false,
        patchCompanyTripDetailsError: "",
      };

    case PATCH_COMPANY_TRIP_DETAILS_ERROR:
      return {
        ...state,
        patchCompanyTripDetailsLoading: false,
        patchCompanyTripDetailsError: action.payload,
      };
    case PATCH_COMPANY_TRIP_PAYMENT:
      return {
        ...state,
        patchCompanyTripPayment: {},
        patchCompanyTripPaymentLoading: true,
      };
    case PATCH_COMPANY_TRIP_PAYMENT_SUCCESS:
      return {
        ...state,
        patchCompanyTripPayment: action.payload,
        patchCompanyTripPaymentLoading: false,
        patchCompanyTripPaymentError: "",
      };

    case PATCH_COMPANY_TRIP_PAYMENT_ERROR:
      return {
        ...state,
        patchCompanyTripPaymentLoading: false,
        patchCompanyTripPaymentError: action.payload,
      };
    case DELETE_COMPANY_TRIP:
      return {
        ...state,
        deleteCompanyTrip: {},
        deleteCompanyTripLoading: true,
      };
    case DELETE_COMPANY_TRIP_SUCCESS:
      return {
        ...state,
        deleteCompanyTrip: action.payload,
        deleteCompanyTripLoading: false,
        deleteCompanyTripError: "",
      };

    case DELETE_COMPANY_TRIP_ERROR:
      return {
        ...state,
        deleteCompanyTripLoading: false,
        deleteCompanyTripError: action.payload,
      };
    default:
      return { ...state };
  }
};

export default CompanyTripsection;
