import { Badge } from "../../../ui/badge";
import moment from "moment";
import React, { useState } from "react";
import ImagePopUpDownload from "../../imagePopUpDownload";
import { Dialog } from "../../../ui/dialog";

function PersonalDetails({ data, state, city }) {
  console.log(data);
  const sortedPreviousSalaries = data?.previousSalaries.sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
  );
  const sortedPreviousDesignation = data?.previousDesignation.sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
  );
  const [showImageModal, setShowImageModal] = useState(false);

  return (
    <>
      <div className="bg-white p-4 rounded-md shadow-lg grid xl:grid-cols-6 lg:grid-cols-4 grid-cols-2 xl:grid-rows-3 grid-rows-9 lg:gap-x-20 gap-x-9  lg:gap-y-10 gap-y-4 w-full text-xs">
        <div className="lg:border-r lg:border-r-[#F1F2F6]  lg:border-b-0 border-b border-b-[#F1F2F6] row-span-full flex flex-col gap-2 lg:col-span-1 col-span-2 pb-5 lg:text-left text-center  ">
          <div>
            <label htmlFor="" className="">
              Name
            </label>
            <p className=" mt-2 textPrimary uppercase ">
              {data?.firstName} {data?.lastName}
            </p>
          </div>

          <div
            className={`flex w-full md:justify-start justify-center gap-2  ${
              data?.isWorking ? "text-[#15803D] " : "text-[#DC1919]"
            }`}
          >
            <p className="">
              <div
                className={`px-1.5 py-0.5 rounded-full border  text-[10px] font-medium ${
                  data?.isWorking ? "border-[#15803D] " : "border-[#DC1919]"
                }`}
              >
                {data?.isWorking ? "Working" : "Not Working"}
              </div>
            </p>
          </div>
          {!data?.isWorking && (
            <div className="">
              <h3 className=" ">Last Working Date</h3>
              <p className="textSecondary">
                {moment(data?.leavingDate).format("DD/MMM/YYYY")}
              </p>
            </div>
          )}
        </div>
        <div className="">
          <h3 className=" ">User Name</h3>
          <p className="textSecondary">{data?.username}</p>
        </div>
        <div className="">
          <h3 className=" ">Phone Number</h3>
          <p className="textSecondary">{data?.phoneNumber}</p>
        </div>
        <div className="">
          <h3 className=" ">Blood Group</h3>
          <p className="textSecondary">{data?.bloodGroup}</p>
        </div>
        <div className="">
          <h3 className=" ">Salary</h3>
          <p className="textSecondary">{data?.salary}</p>
        </div>
        <div className="">
          <h3 className=" ">Employee Type</h3>
          <p className="textSecondary">{data?.employeeType}</p>
        </div>
        <div className="">
          <h3 className=" ">Address</h3>
          <p className="textSecondary">{data?.address}</p>
        </div>
        <div className="">
          <h3 className=" ">City</h3>
          <p className="textSecondary">{data?.cityName}</p>
        </div>
        <div className="">
          <h3 className=" ">State</h3>
          <p className="textSecondary">{data?.stateName}</p>
        </div>
        <div className="">
          <h3 className=" ">Country</h3>
          <p className="textSecondary">{data?.country}</p>
        </div>
        <div className="">
          <h3 className=" ">Post Code</h3>
          <p className="textSecondary">{data?.zipCode}</p>
        </div>
        <div>
          <label htmlFor="">Designation</label>
          <p className="textSecondary">{data?.designation.toUpperCase()}</p>
        </div>
        <div>
          <label htmlFor="">Joining Date</label>
          <p className="textSecondary">
            {moment(data?.joiningDate).format("DD/MMM/YYYY")}
          </p>
        </div>
      </div>
      <div className="grid md:grid-cols-2 gap-6 mt-6">
        <div className="grid grid-cols-2 lg:gap-x-20 gap-x-9 bg-white p-4 rounded-md shadow-lg  gap-y-4">
          <div className="col-span-2 text-base font-bold">
            Salary Increment Details
          </div>
          {sortedPreviousSalaries?.map((item) => (
            <>
              <div>
                <label htmlFor="">Salary</label>
                <p className="textSecondary">{item?.salary}</p>
              </div>
              <div>
                <label htmlFor="">Salary Update Date</label>
                <p className="textSecondary">
                  {moment(item?.updatedAt).format("DD / MMM / YYYY")}
                </p>
              </div>
            </>
          ))}
        </div>
        <div className="grid grid-cols-2 lg:gap-x-20 gap-x-9 bg-white p-4 rounded-md shadow-lg  gap-y-4">
          <div className="col-span-2 text-base font-bold">
            Designation Increment Details
          </div>
          {sortedPreviousDesignation?.map((item) => (
            <>
              <div>
                <label htmlFor="">Designation</label>
                <p className="textSecondary">{item?.designation}</p>
              </div>
              <div>
                <label htmlFor="">Designation Update Date</label>
                <p className="textSecondary">
                  {moment(item?.updatedAt).format("DD / MMM / YYYY")}
                </p>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
}

export default PersonalDetails;
// <div className="bg-white p-4 rounded-md shadow-lg grid grid-cols-4 gap-5 w-full">
//   <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
//     <label htmlFor="">Name</label>
//     <p className="">
//       {data?.firstName} {data?.lastName}
//     </p>
//   </div>
//   <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
//     <label htmlFor="">Gender</label>
//     <p className="">{data?.gender}</p>
//   </div>
//   <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
//     <label htmlFor="">Date of Birth</label>
//     <p className="">
//       {moment(data?.dateOfBirth).format("DD/MM/YYYY")}
//     </p>
//   </div>
//   <div className="lg:col-span-1 md:col-span-2 col-span-4">
//     <label>Phone Number</label>
//     <p>{data?.phoneNumber}</p>
//   </div>
//   <div className="lg:col-span-1 md:col-span-2 col-span-4">
//     <label>Blood Group</label>
//     <p className="">{data?.bloodGroup}</p>
//   </div>
//   <div className="lg:col-span-1 md:col-span-2 col-span-4">
//     <label>Address</label>
//     <p>{data?.address}</p>
//   </div>
//   <div className="lg:col-span-1 md:col-span-2 col-span-4">
//     <label>City</label>
//     <p className="">{data?.city}</p>
//   </div>
//   <div className="lg:col-span-1 md:col-span-2 col-span-4">
//     <label>State</label>
//     <p className="">{data?.state}</p>
//   </div>
//   <div className="lg:col-span-1 md:col-span-2 col-span-4">
//     <label>Pin Code</label>
//     <p className="">{data?.zipCode}</p>
//   </div>
//   <div className="lg:col-span-1 md:col-span-2 col-span-4">
//     <label className="">Blocked by Company</label>
//     <p className="">
//       {data?.blockedByCompany ? (
//         <Badge variant="destructive">Blocked</Badge>
//       ) : (
//         <Badge className="bg-green-700 hover:bg-green-900">Active</Badge>
//       )}
//     </p>
//   </div>
//   <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
//     <label htmlFor="">Designation</label>
//     <p className="">{data?.designation.toUpperCase()}</p>
//   </div>
//   <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
//     <label htmlFor="">Joining Date</label>
//     <p className="">
//       {moment(data?.joiningDate).format("DD/MMM/YYYY")}
//     </p>
//   </div>
//   {data?.leavingDate && (
//     <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
//       <label htmlFor="">Leaving Date</label>
//       <p className="">
//         {moment(data?.leavingDate).format("DD/MMM/YYYY")}
//       </p>
//     </div>
//   )}
//   <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
//     <label htmlFor="">Working Type</label>
//     <p className="">{data?.employeeType}</p>
//   </div>
//   <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
//     <label htmlFor="">Current Salary</label>
//     <p className="">{data?.salary}</p>
//   </div>
//   <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
//     <label htmlFor="">Current Working Status</label>
//     <p className="">
//       {data?.isWorking ? "Working" : "Leave"}
//     </p>
//   </div>
//   <div className="col-span-4 text-xl font-bold">
//     Salary Increment Details
//   </div>
//   <div className="col-span-4 grid grid-cols-2">
//     {sortedPreviousSalaries?.map((item) => (
//       <>
//         <div className="lg:col-span-1 col-span-2  ">
//           <label htmlFor="">Salary</label>
//           <p className="">{item?.salary}</p>
//         </div>
//         <div className="lg:col-span-1 col-span-2  ">
//           <label htmlFor="">Salary Update Date</label>
//           <p className="">
//             {moment(item?.updatedAt).format("DD/MMM/YYYY")}
//           </p>
//         </div>
//       </>
//     ))}
//   </div>
//   <div className="col-span-4 text-xl font-bold">
//     Designation Details
//   </div>
//   <div className="col-span-4 grid grid-cols-2">
//     {sortedPreviousDesignation?.map((item) => (
//       <>
//         <div className="lg:col-span-1 col-span-2  ">
//           <label htmlFor="">Salary</label>
//           <p className="">{item?.designation}</p>
//         </div>
//         <div className="lg:col-span-1 col-span-2  ">
//           <label htmlFor="">Salary Update Date</label>
//           <p className="">
//             {moment(item?.updatedAt).format("DD/MMM/YYYY")}
//           </p>
//         </div>
//       </>
//     ))}
//   </div>
// </div>
