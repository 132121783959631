import Backdrop from "../../../../components/backdrop";
import { getAdminEmployeeByEmployeeIdAction } from "../../../../redux/admin/employee/action";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import EmployeeListCard from "../employeeListCard";
import EmployeeDetailsSection from "./employeeDetailsSection";

function AdminEmployeeDetails() {
  const dispatch = useDispatch();
  const { employee } = useParams();
  const [data, setData] = useState([]);
  const {
    getAdminEmployeeByEmployeeId,
    getAdminEmployeeByEmployeeIdLoading,
    getAdminEmployeeByEmployeeIdError,
  } = useSelector((state) => state.AdminEmployeeSection);

  useEffect(() => {
    dispatch(getAdminEmployeeByEmployeeIdAction(employee));
  }, []);
  useEffect(() => {
    if (getAdminEmployeeByEmployeeId?.status === 200) {
      setData(getAdminEmployeeByEmployeeId?.data);
    }
  }, [getAdminEmployeeByEmployeeId]);
  useEffect(() => {
    if (
      getAdminEmployeeByEmployeeIdError?.status === 400 ||
      getAdminEmployeeByEmployeeIdError?.status === 404
    ) {
      toast.error(getAdminEmployeeByEmployeeIdError.message);
    } else if (getAdminEmployeeByEmployeeIdError?.status === 500) {
      toast.error(getAdminEmployeeByEmployeeIdError?.error);
    }
  }, [getAdminEmployeeByEmployeeIdError]);

  return (
    <div>
      {getAdminEmployeeByEmployeeIdLoading && <Backdrop />}
      <EmployeeListCard data={data?.employee} reroute={false} />
      <EmployeeDetailsSection data={data} />
    </div>
  );
}

export default AdminEmployeeDetails;
