import Backdrop from "../../../../components/backdrop";
import { getTruckInsuranceByTruckIdAction } from "../../../../redux/company/truck/action";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { Dialog } from "../../../../ui/dialog";
import UpdateInsurance from "../updateInsurance";
import InsuraceListCard from "./insuraceListCard";

function Insurance() {
  const { truckId } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const {
    getTruckInsuranceByTruckId,
    getTruckInsuranceByTruckIdLoading,
    getTruckInsuranceByTruckIdError,
  } = useSelector((state) => state.Companytrucksection);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getTruckInsuranceByTruckIdAction(truckId));
  }, [truckId]);

  useEffect(() => {
    if (getTruckInsuranceByTruckId?.status === 200) {
      setData(getTruckInsuranceByTruckId?.data);
    }
  }, [getTruckInsuranceByTruckId]);
  useEffect(() => {
    if (getTruckInsuranceByTruckIdError?.status === 400) {
      toast.error(getTruckInsuranceByTruckIdError?.message);
    }
    if (getTruckInsuranceByTruckIdError?.status === 404) {
      toast.error(getTruckInsuranceByTruckIdError?.message);
    }
    if (getTruckInsuranceByTruckIdError?.status === 500) {
      toast.error(getTruckInsuranceByTruckIdError?.error);
    }
  }, [getTruckInsuranceByTruckId]);

  const [showInsuranceModal, setshowInsuranceModal] = useState(false);

  return (
    <div>
      {getTruckInsuranceByTruckIdLoading && <Backdrop />}
      <div className="flex justify-end gap-5">
        <Dialog open={showInsuranceModal}>
          <button
            type="button"
            className="primaryButton w-fit  h-full"
            onClick={() => setshowInsuranceModal(true)}
          >
            Add Insurance
          </button>
          <UpdateInsurance
            truckId={truckId}
            setShowModal={setshowInsuranceModal}
            truckNumber={searchParams.get("truckNumber")}
          />
        </Dialog>
      </div>
      {data.length > 0 &&
        data.map((item) => (
          <InsuraceListCard
            data={item}
          />
        ))}
    </div>
  );
}

export default Insurance;
