import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TruckListCard from "./truckListCard";
import { getCompanyTruckSellDetailsAction } from "../../../redux/company/truck/action";
import Pagination from "../../pagination";

function TruckSellList({data}) {
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [pageNo, setPageNo] = useState(data.currentPage || 1);
  const [perPage, setPerPage] = useState(data?.limit || 10);
  const [totalPages, setTotalPages] = useState(data?.totalPages || 0);
  const [total, setTotal] = useState(data?.totalTrucks || 0);
  useEffect(() => {
    const payload = `page=${pageNo}&limit=${perPage}&searchTerm=${searchQuery}`;
    dispatch(getCompanyTruckSellDetailsAction(payload));
  }, [pageNo, perPage, searchQuery]);

  useEffect(() => {
    setTotalPages(data?.totalPages)
  }, [data?.totalPages])
  useEffect(() => {
    setTotal(data?.totalTrucks)
  }, [data?.totalTrucks])
  

  // Handle search input change
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle pagination controls
  const onNext = () => {
    if (pageNo < totalPages) {
      setPageNo(pageNo + 1);
    }
  };

  const onPrevious = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };

  const handleSetPage = (page) => {
    setPageNo(page);
  };

  const handlePerPage = (value) => {
    setPerPage(parseInt(value));
    setPageNo(1); // Reset page number to 1 when changing perPage
  };


  return (
    <div>
      <div className="mb-4 flex ">
        <input
          type="search"
          placeholder="Search by Truck Number"
          value={searchQuery}
          onChange={handleSearch}
          className="max-w-sm shadow-md "
        />
       
      </div>
      <div className="flex flex-col gap-2 ">
        {data?.trucks?.length > 0 ? (
          data?.trucks?.map((item, index) => (
            <TruckListCard data={item} reroute={true} />
          ))
        ) : (
          <p>No trucks found.</p>
        )}
      </div>
       {data?.totalPages > 1 && 
       <Pagination
       currentPage={pageNo}
       onNext={onNext}
       onPrevious={onPrevious}
       from={pageNo * perPage - perPage + 1}
       to={pageNo * perPage}
       totalItems={total}
       perPage={perPage}
       totalPages={totalPages}
       setPage={handleSetPage}
       setperPage={handlePerPage}
       />
      }
    </div>
  );
}

export default TruckSellList;
