import { Badge } from "../../../../ui/badge";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

function TruckDetailSection({ data }) {
  const date = moment(data?.subscribeTill).diff(moment(new Date()), "days");

  return (
    <div className="bg-white p-4 rounded-md shadow-lg grid grid-cols-4 gap-5 w-full">
      <div className="col-span-4">
        <label htmlFor="" className="font-bold">
          Truck Number
        </label>
        <p className="font-bold">{data?.truckNumber}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck Company Name</label>
        <p className="">{data?.truckId?.truckCompanyName}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck Model Name</label>
        <p className="">{data?.truckId?.truckModelName}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck Chassis Number</label>
        <p className="">{data?.truckId?.chasisNumber}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck New Purchase Date</label>
        <p className="">
          {moment(data?.truckId?.purchaseDate).format("DD/MMM/YYYY")}
        </p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck Purchase Date</label>
        <p className="">{moment(data?.purchaseDate).format("DD/MMM/YYYY")}</p>
      </div>
      {data?.isSell ? (
        <>
          <div>
            <label htmlFor="">Truck Selled</label>
            <p className="flex ">
              <Badge variant="destructive">Sell</Badge>
            </p>
          </div>
          <div className="">
            <label htmlFor="">Truck Sell Date</label>
            <p className="">{moment(data?.sellDate).format("DD/MMM/YYYY")}</p>
          </div>
        </>
      ) : (
        <>
          <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
            <label htmlFor="">Truck Owned By Name</label>
            <p className="">{data?.truckOwnByName}</p>
          </div>
          <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
            <label htmlFor="">Truck Used For</label>
            <p className="">{data?.usedfor}</p>
          </div>
        </>
      )}
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck Type</label>
        <p className="">{data?.truckId?.typeOfTruck}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Subscription End Date</label>
        <p className="">
          {data?.isSubscribe
            ? moment(data?.subscribeTill).format("DD/MMM/YYYY")
            : "Please Subscribe"}
        </p>
      </div>
      {data?.isSubscribe && (
        <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
          <label htmlFor="">Subscription Expired In</label>
          <div>
            <Badge
              className={` ${date > 120 ? "bg-green-800 " : ""} ${
                date < 120 && date > 30 ? "bg-yellow-500 " : ""
              } ${date < 30 ? "bg-red-500 " : ""}`}
            >
              {date} Days Left
            </Badge>
          </div>
        </div>
      )}
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Fitness Valid Till</label>
        <p className="">
          {moment(data?.truckId?.fitnessTill).format("DD/MMM/YYYY")}
        </p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Insurance Valid Till</label>
        <p className="">
          {moment(data?.truckId?.insuranceTill).format("DD/MMM/YYYY")}
        </p>
      </div>
      {/* New Sections for Company Details */}
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Current Company Name</label>
        <div>

        <Link to={`/admin/company/${data?.companyId?._id}`} className="">
          {data?.companyId?.companyName}
        </Link>
        </div>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Company Owner</label>
        <p className="">{data?.companyId?.companyOwnerBy}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Company Phone Number</label>
        <p className="">{data?.companyId?.phoneNumber}</p>
      </div>
    </div>
  );
}

export default TruckDetailSection;
