import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { Checkbox } from "../../../ui/checkbox";
import { getCompanyDashboardEmployeeGraphAction } from "../../../redux/company/dashboard/action";

function EmployeeGraph() {
  const dispatch = useDispatch();
  const {
    getCompanyDashboardEmployeeGraph,
  } = useSelector((state) => state.CompanyDashboardSection);
  const [isRunningTruck, setIsRunningTruck] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [year, setYear] = useState("2024");
  const [data, setData] = useState(0);

  useEffect(() => {
    if (getCompanyDashboardEmployeeGraph?.status === 200) {
      setData(getCompanyDashboardEmployeeGraph.data);
    }
  }, [getCompanyDashboardEmployeeGraph]);

  // Load truck data when component mounts or state changes
  useEffect(() => {
    initialLoad(isRunningTruck, startDate, endDate, year);
  }, [isRunningTruck, startDate, endDate, year]); // Add dependencies to re-run on changes

  const initialLoad = (
    isRunningTruckValue,
    startDateValue,
    endDateValue,
    yearValue
  ) => {
    const queryParams = {
      isWorking: isRunningTruckValue,
      startDate: startDateValue,
      endDate: endDateValue,
      year: yearValue,
    };

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    // Dispatch action to get truck count
    dispatch(getCompanyDashboardEmployeeGraphAction(queryString));
  };

  const handleCheckboxChange = () => {
    // Toggle isRunningTruck state
    setIsRunningTruck((prev) => !prev);
  };

  const chartData = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: data?.graph?.map((item) => item.period),
      },
      stroke: {
        curve: "smooth",
      },
      colors: ["#2E93fA", "#66DA26", "#546E7A", "#E91E63", "#FF9800"],
    },

    series: [
      {
        name: "Total Cost",
        data: data?.graph?.map((item) => item.employeesAdded),
      },
    ],
  };

  return (
    <div className="bg-white p-4 h-fit col-span-2  order-8">
      <div className="flex flex-wrap justify-between">
        <div className="lg:text-2xl text-xl font-bold mb-4">Employees</div>
        <div className="flex gap-4 ml-auto">
          <div className="flex gap-1 mt-1">
            {/* Pass handleCheckboxChange as a function reference */}
            <Checkbox
              checked={isRunningTruck}
              onCheckedChange={handleCheckboxChange}
              id="running"
            />
            <label htmlFor="running">Working</label>
          </div>
          <div>
            {/* Pass handleCheckboxChange as a function reference */}
            <select
              name=""
              id=""
              value={year}
              className="border border-gray-200 text-xs min-h-fit"
              onChange={(e) => setYear(e.target.value)}
            >
              <option value="">All</option>
              <option value="2024">2024</option>
              <option value="2023">2025</option>
              <option value="2022">2026</option>
            </select>
          </div>
        </div>
      </div>
      <div className="text-3xl font-bold mb-4">
        {data.count}
        <label> Total Employees {isRunningTruck ? "Working" : ""}</label>
      </div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={250}
        // width="500"
      />
    </div>
  );
}

export default EmployeeGraph;
