import React, { useEffect, useState } from "react";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { deleteCompanyTripAction, getCompanyTripDetailsFilterAction } from "../../../redux/company/trip/action";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";

function DeleteTrip({ id, setShowModal }) {
  const dispatch = useDispatch()
  const {
    deleteCompanyTrip,
    deleteCompanyTripLoading,
    deleteCompanyTripError,
  } = useSelector((state) => state.CompanyTripsection);
  let [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object({
    deleteValue: Yup.string()
      .required("This field is required")
      .oneOf(["TRIP"], 'You must type "TRIP" to confirm'),
  });
  useEffect(() => {
    if (loading) {
      if (deleteCompanyTrip?.status === 200) {
        toast.success(deleteCompanyTrip.message, {
          id: "deleteTrip-toast",
        });
        const id = searchParams.get("id");
        const payload = {
          truckId: id,
        };
        dispatch(getCompanyTripDetailsFilterAction(payload));
        setLoading(false);
        setShowModal(false);
      } else if (deleteCompanyTripError?.status === 404) {
        toast.error(deleteCompanyTripError.message, {
          id: "deleteTrip-toast",
        });
        setLoading(false);
      } else if (deleteCompanyTripError?.status === 400) {
        toast.error(deleteCompanyTripError.message, {
          id: "deleteTrip-toast",
        });
        setShowModal(false);
        setLoading(false);
      } else if (deleteCompanyTripError?.status === 500) {
        toast.error(deleteCompanyTripError.error, {
          id: "deleteTrip-toast",
        });
        setLoading(false);
      }
    }
  }, [deleteCompanyTrip, deleteCompanyTripError, loading]);

  const onSubmit = (values) => {
    if (values.deleteValue === "TRIP") {
      dispatch(deleteCompanyTripAction(id))
      toast.loading("Deleting Trip...", { id: "deleteTrip-toast" });
      setLoading(true);
    }
  };

  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle>Delete Trip</DialogTitle>
        <DialogDescription>
          Are you sure you want to{" "}
          <span className="text-red-500 font-bold">Delete</span> this Trip?
          After deleting this trip, all related data will also be deleted.
        </DialogDescription>
      </DialogHeader>
      <Formik
        initialValues={{ deleteValue: "" }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form className="">
            <div className="mb-4">
              <label htmlFor="deleteValue">
                To confirm, type <span className="font-bold">"TRIP"</span> in Capital Letter
                the box below*
              </label>
              <Field
                type="text"
                id="deleteValue"
                name="deleteValue"
                placeholder="TRIP"
              />
              <ErrorMessage
                name="deleteValue"
                component="div"
                className="text-red-600"
                />
            </div>
            <DialogFooter>
              <button
                className="ghostButton"
                onClick={() => setShowModal(false)}
                type="button"
              >
                Close
              </button>
              <button
                className="primaryButton bg-red-600 hover:bg-red-800 w-fit"
                type="submit"
              >
                Delete
              </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default DeleteTrip;
