import {
  deleteAdminTruckSubscriptionAction,
  getAdminTruckSubscriptioListAction,
} from "../../../redux/admin/truck/action";
import * as Yup from "yup";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

function SubscriptionDelete({ id, setShowModal }) {
  const dispatch = useDispatch();
  const {
    deleteAdminTruckSubscription,
    deleteAdminTruckSubscriptionLoading,
    deleteAdminTruckSubscriptionError,
  } = useSelector((state) => state.AdminTruckSection);
  let [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    deleteValue: Yup.string()
      .required("This field is required")
      .oneOf(["SUBSCRIPTION"], 'You must type "SUBSCRIPTION" to confirm'),
  });
  useEffect(() => {
    if (loading) {
      if (deleteAdminTruckSubscription?.status === 200) {
        toast.success(deleteAdminTruckSubscription.message, {
          id: "deleteSub-toast",
        });
        const truckId = searchParams.get("truckId");

        dispatch(getAdminTruckSubscriptioListAction(truckId));
        setLoading(false);
        setShowModal(false);
      }
    }
  }, [deleteAdminTruckSubscription, loading]);
  useEffect(() => {
    if (loading) {
      if (deleteAdminTruckSubscriptionError?.status === 404) {
        toast.error(deleteAdminTruckSubscriptionError.message, {
          id: "deleteSub-toast",
        });
        setLoading(false);
      } else if (deleteAdminTruckSubscriptionError?.status === 400) {
        toast.error(deleteAdminTruckSubscriptionError.message, {
          id: "deleteSub-toast",
        });
        setShowModal(false);
        setLoading(false);
      } else if (deleteAdminTruckSubscriptionError?.status === 500) {
        toast.error(deleteAdminTruckSubscriptionError.error, {
          id: "deleteSub-toast",
        });
        setLoading(false);
      }
    }
  }, [deleteAdminTruckSubscriptionError, loading]);

  const onSubmit = (values) => {
    if (values.deleteValue === "SUBSCRIPTION") {
      dispatch(deleteAdminTruckSubscriptionAction(id));
      toast.loading("Deleting Subscription...", { id: "deleteSub-toast" });
      setLoading(true);
    }
  };
  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle>Delete Subscription</DialogTitle>
      </DialogHeader>
      <div className="text-xl mt-5 font-semibold">
        Are you sure you want to{" "}
        <span className="text-red-500 font-bold">Delete</span> this Subscription?
      </div>
      <Formik
        initialValues={{ deleteValue: "" }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="">
            <div className="mb-4">
              <label htmlFor="deleteValue" className="text-gray-700">
                To confirm, type{" "}
                <span className="font-bold">"SUBSCRIPTION"</span> in Capital
                Letter the box below*
              </label>
              <Field
                type="text"
                id="deleteValue"
                name="deleteValue"
                placeholder="SUBSCRIPTION"
              />
              <ErrorMessage
                name="deleteValue"
                component="div"
                className="text-red-600"
              />
            </div>
            <DialogFooter>
              <button
                className="secondaryButton"
                onClick={() => setShowModal(false)}
                disabled={deleteAdminTruckSubscriptionLoading}
                type="button"
              >
                Close
              </button>
              <button
                className="primaryButton bg-red-600 hover:bg-red-800 w-fit"
                type="submit"
                disabled={deleteAdminTruckSubscriptionLoading}
              >
                Delete
              </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default SubscriptionDelete;
