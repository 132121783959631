import {
  DELETE_CLIENT,
  DELETE_CLIENT_ERROR,
  DELETE_CLIENT_SUCCESS,
  GET_CLIENT,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_ERROR,
  GET_CLIENT_ID,
  GET_CLIENT_ID_ERROR,
  GET_CLIENT_ID_SUCCESS,
  PATCH_CLIENT,
  PATCH_CLIENT_ERROR,
  PATCH_CLIENT_SUCCESS,
  POST_CLIENT,
  POST_CLIENT_ERROR,
  POST_CLIENT_SUCCESS,
} from "../../actions";

//truck
export const getClientAction = (info) => ({
  type: GET_CLIENT,
  payload: info,
});
export const getClientSuccess = (info) => ({
  type: GET_CLIENT_SUCCESS,
  payload: info,
});
export const getClientError = (error) => ({
  type: GET_CLIENT_ERROR,
  payload: error,
});
export const getClientIdAction = (info) => ({
  type: GET_CLIENT_ID,
  payload: info,
});
export const getClientIdSuccess = (info) => ({
  type: GET_CLIENT_ID_SUCCESS,
  payload: info,
});
export const getClientIdError = (error) => ({
  type: GET_CLIENT_ID_ERROR,
  payload: error,
});
export const postClientAction = (info) => ({
  type: POST_CLIENT,
  payload: info,
});
export const postClientSuccess = (info) => ({
  type: POST_CLIENT_SUCCESS,
  payload: info,
});
export const postClientError = (error) => ({
  type: POST_CLIENT_ERROR,
  payload: error,
});
export const patchClientAction = (info) => ({
  type: PATCH_CLIENT,
  payload: info,
});
export const patchClientSuccess = (info) => ({
  type: PATCH_CLIENT_SUCCESS,
  payload: info,
});
export const patchClientError = (error) => ({
  type: PATCH_CLIENT_ERROR,
  payload: error,
});
export const deleteClientAction = (info) => ({
  type: DELETE_CLIENT,
  payload: info,
});
export const deleteClientSuccess = (info) => ({
  type: DELETE_CLIENT_SUCCESS,
  payload: info,
});
export const deleteClientError = (error) => ({
  type: DELETE_CLIENT_ERROR,
  payload: error,
});
