import { Dialog } from "../../../ui/dialog";
import moment from "moment";
import React, { useState } from "react";
import SubscriptionDelete from "./subscriptiondelete";

function SubscriptionListcard({ data }) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  return (
    <div
      key={data?._id}
      className="my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl"
    >
      <div className="flex justify-between mb-10">
        <div className="mb-5 text-center md:text-left self-center lg:self-auto">
          <p className="text-blue-700 font-semibold text-xl uppercase">
            {data?.transitionNo ? data?.transitionNo : "Cash"}
          </p>
        </div>
        <div>
          {/* {emiDelete === data.emiPayMonth && ( */}
          <div className="flex">
            <Dialog open={showDeleteModal}>
              <button
                onClick={() => setShowDeleteModal(true)}
                className="primaryButton bg-red-600 hover:bg-red-800"
              >
                Delete Susbscription
              </button>
              <SubscriptionDelete
                id={data?._id}
                setShowModal={setShowDeleteModal}
              />
            </Dialog>
          </div>
        </div>
        {/* )} */}
      </div>
      <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between  self-center">
        {/* Truck and Payment Details */}
        <div>
          <h3 className="textgrey text-xs">Truck Number</h3>
          <p>{data?.companyTruckId.truckNumber}</p>
        </div>
        <div>
          <h3 className="textgrey text-xs md:text-center">Payment Type</h3>
          <p className="md:text-center">{data?.paymentType}</p>
        </div>

        {/* Cost and Duration Details */}
        <div>
          <h3 className="textgrey text-xs md:text-center">Default Cost</h3>
          <p className="md:text-center">₹{data?.defaultCost}</p>
        </div>
        <div>
          <h3 className="textgrey text-xs md:text-center">Final Cost</h3>
          <p className="md:text-center">₹{data?.cost}</p>
        </div>
        <div>
          <h3 className="textgrey text-xs md:text-left">Months</h3>
          <p className="md:text-left">{data?.months}</p>
        </div>

        {/* Subscription Name and Discount Details */}
        <div>
          <h3 className="textgrey text-xs md:text-center">Subscription Name</h3>
          <p className="md:text-center">{data?.subscriptionId.name}</p>
        </div>
        <div>
          <h3 className="textgrey text-xs md:text-center">Discount Name</h3>
          <p className="md:text-center">{data?.discountName}</p>
        </div>
        <div>
          <h3 className="textgrey text-xs md:text-center">Discount Percent</h3>
          <p className="md:text-center">
            {data?.subscriptionId.discountPercent}%
          </p>
        </div>
        <div>
          <h3 className="textgrey text-xs md:text-left">Cost Per Month</h3>
          <p className="md:text-left">₹{data?.subscriptionId.costPerMonth}</p>
        </div>

        {/* Subscription Period */}
        <div>
          <h3 className="textgrey text-xs md:text-center">
            Subscribe Start From
          </h3>
          <p className="md:text-center">
            {moment(data?.subscribeStartFrom).format("DD / MMM / YYYY")}
          </p>
        </div>
        <div>
          <h3 className="textgrey text-xs md:text-center">Subscribe End</h3>
          <p className="md:text-center">
            {moment(data?.subscribeEnd).format("DD / MMM / YYYY")}
          </p>
        </div>
        <div>
          <h3 className="textgrey text-xs md:text-center">Subscription Date</h3>
          <p className="md:text-center">
            {moment(data?.subscriptionDate).format("DD / MMM / YYYY")}
          </p>
        </div>

        {/* Extra Discounts */}
        <h3 className="font-bold text-left col-span-4">Extra Discounts</h3>
        {data?.discountId?.map((discount) => (
          <>
            <div>
              <h3 className="textgrey text-xs md:text-left">
                Extra Discount Name
              </h3>
              <p className="font-semibold md:text-left">
                {discount.extraDiscountName}
              </p>
            </div>
            <div className="mt-auto">
              <h3 className="textgrey text-xs md:text-center"></h3>
              <p>
                Condition:{" "}
                <span className="font-semibold">{discount.condition}</span> |
                Discount:{" "}
                <span className="font-semibold">
                  {discount.extraDiscountPercent}%
                </span>
              </p>
            </div>
            {discount.validFrom && (
              <>
                <div>
                  <h3 className="textgrey text-xs md:text-center">
                    Valid From
                  </h3>
                  <p className="md:text-center">
                    {moment(discount.validFrom).format("DD / MMM / YYYY")}
                  </p>
                </div>
                <div>
                  <h3 className="textgrey text-xs md:text-center">Valid To</h3>
                  <p className="md:text-center">
                    {moment(discount.validTo).format("DD / MMM   / YYYY")}
                  </p>
                </div>
              </>
            )}
          </>
        ))}
      </div>
    </div>
  );
}

export default SubscriptionListcard;
