import api from "../../../auth/api.jsx";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  DELETE_CLIENT,
  GET_CLIENT,
  GET_CLIENT_ID,
  PATCH_CLIENT,
  POST_CLIENT,
} from "../../actions.js";
import {
  deleteClientError,
  deleteClientSuccess,
  getClientError,
  getClientIdError,
  getClientIdSuccess,
  getClientSuccess,
  patchClientError,
  patchClientSuccess,
  postClientError,
  postClientSuccess,
} from "./action.js";

// Async function to handle API call
const getClientAsync = async (payload) => {
  try {
    const response = await api.get(`/company/client/getAll`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getClientCall(action) {
  try {
    const data = yield call(getClientAsync, action); // Call async function with action payload
    yield put(getClientSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getClientError(error)); // Dispatch error action with error object
  }
}

export function* watchGetClientData() {
  yield takeEvery(GET_CLIENT, getClientCall);
}
// Async function to handle API call
const getClientIdAsync = async (payload) => {
  try {
    const response = await api.get(`/company/client/${payload.payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw other errors to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getClientIdCall(action) {
  try {
    const data = yield call(getClientIdAsync, action); // Call async function with action payload
    yield put(getClientIdSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getClientIdError(error)); // Dispatch error action with error object
  }
}

export function* watchGetClientIdData() {
  yield takeEvery(GET_CLIENT_ID, getClientIdCall);
}


// Async function to handle API call
const postClientAsync = async (payload) => {
  try {
    const response = await api.post(`/company/client/add`, payload.payload);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* postClientCall(action) {
  try {
    const data = yield call(postClientAsync, action); // Call async function with action payload
    yield put(postClientSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postClientError(error)); // Dispatch error action with error object
  }
}
export function* watchPostClientData() {
  yield takeEvery(POST_CLIENT, postClientCall);
}
// Async function to handle API call
const patchClientAsync = async (payload) => {
  try {
    const response = await api.patch(
      `/company/client/update/${payload.payload.id}`,
      payload.payload.body
    );
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* patchClientCall(action) {
  try {
    const data = yield call(patchClientAsync, action); // Call async function with action payload
    yield put(patchClientSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(patchClientError(error)); // Dispatch error action with error object
  }
}
export function* watchPatchClientData() {
  yield takeEvery(PATCH_CLIENT, patchClientCall);
}
// Async function to handle API call
const deleteClientAsync = async (payload) => {
  try {
    console.log(payload);
    const response = await api.delete(`/company/client/${payload.payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* deleteClientCall(action) {
  try {
    const data = yield call(deleteClientAsync, action); // Call async function with action payload
    yield put(deleteClientSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(deleteClientError(error)); // Dispatch error action with error object
  }
}
export function* watchDeleteClientData() {
  yield takeEvery(DELETE_CLIENT, deleteClientCall);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetClientData),
    fork(watchGetClientIdData),
    fork(watchPostClientData),
    fork(watchPatchClientData),
    fork(watchDeleteClientData),
  ]);
}
