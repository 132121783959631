import { all } from "redux-saga/effects";
import authSaga from "./auth/saga";
import comapnySaga from "./company/saga";
import CompanytruckSaga from "./company/truck/saga";
import CompanyAlertSaga from "./company/alert/saga";
import CompanyTripSaga from "./company/trip/saga";
import CompanyEmployeeSaga from "./company/employee/saga";
import FuelSaga from "./company/fuel/saga";
import TollTaxSaga from "./company/tollTax/saga";
import ServiceSaga from "./company/service/saga";
import AddressSaga from "./address/saga";
import SalarySaga from "./company/employee/salary/saga";
import EmiSaga from "./company/truck/emi/saga";
import defSaga from "./company/truck/def/saga";
import ClientSaga from "./company/client/saga";
import expenseTripSaga from "./company/trip/extraExpense/saga";
import SubscriptionSaga from "./company/subscription/saga";
import StatsSaga from "./company/finance/saga";
import AdminCompanysaga from "./admin/company/saga";
import AdminTruckSaga from "./admin/truck/saga";
import AdminSubscriptionSaga from "./admin/subscription/saga";
import AdminEmployeeSaga from "./admin/employee/saga";
import AdminExpenseSaga from "./admin/expenses/saga";
import AdminDashboardSaga from "./admin/dashboard/saga";
import CompanyDashboardSaga from "./company/dashboard/saga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    comapnySaga(),
    AddressSaga(),
    CompanytruckSaga(),
    CompanyAlertSaga(),
    CompanyTripSaga(),
    CompanyEmployeeSaga(),
    CompanyDashboardSaga(),
    FuelSaga(),
    TollTaxSaga(),
    ServiceSaga(),
    EmiSaga(),
    SalarySaga(),
    defSaga(),
    expenseTripSaga(),
    ClientSaga(),
    SubscriptionSaga(),
    StatsSaga(),
    AdminCompanysaga(),
    AdminTruckSaga(),
    AdminSubscriptionSaga(),
    AdminEmployeeSaga(),
    AdminExpenseSaga(),
    AdminDashboardSaga(),
  ]);
}
