import { getCompanyDashboardTripListAction } from "../../../redux/company/dashboard/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "react-apexcharts";

function TripDashboardList() {
  const dispatch = useDispatch();
  const { getCompanyDashboardTripList } =
    useSelector((state) => state.CompanyDashboardSection);

  const [data, setData] = useState([]);
  const [sortOrder, setSortOrder] = useState("desc"); // Default sort order

  useEffect(() => {
    // Dispatch action with sortOrder parameter
    const payload = "order=" + sortOrder;
    dispatch(getCompanyDashboardTripListAction(payload));
  }, [dispatch, sortOrder]); // Include sortOrder in dependency array

  useEffect(() => {
    if (getCompanyDashboardTripList?.status === 200) {
      setData(getCompanyDashboardTripList.data);
    }
  }, [getCompanyDashboardTripList]);

  // Get the top 10 trips from the fetched data
  const topTrips = data.slice(0, 10); // Adjust this if backend returns already sorted data

  const chartData = {
    options: {
      chart: {
        id: "trip-graph",
      },
      xaxis: {
        categories: topTrips.map(
          (item) => `${item.startLocation} to ${item.endLocation}`
        ),
        title: {
          text: "Routes",
        },
      },
      yaxis: {
        title: {
          text: "Count / Earnings",
        },
      },
      colors: ["#2E93fA", "#66DA26"],
    },
    series: [
      {
        name: "Trip Count",
        data: topTrips.map((item) => item.tripCount),
      },
      {
        name: "Total Earnings",
        data: topTrips.map((item) => item.totalEarnings),
      },
    ],
  };

  return (
    <div className="bg-white p-4 h-full xl:col-span-2 lg:col-span-3 col-span-2 order-9">
      <div className="flex justify-between mb-4">
        <div className="lg:text-2xl text-xl font-bold">Trip</div>
        <div className="flex gap-4">
          <div>
            <select
              value={sortOrder}
              className="border border-gray-200 text-xs min-h-fit ml-2"
              onChange={(e) => setSortOrder(e.target.value)}
            >
              <option value="desc">More Earning</option>
              <option value="asc">Less Earning</option>
            </select>
          </div>
        </div>
      </div>

      {/* Add the wrapper div for horizontal scrolling */}
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm mt-4">
          <thead className="ltr:text-left rtl:text-right">
            <tr>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-left">
                Start Location
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Trip Count
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-right">
                Total Earnings
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {topTrips.map((item, index) => (
              <tr key={index}>
                <td className="whitespace-nowrap px-4 py-2 text-gray-900">
                  {item.startLocation} - {item.endLocation}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-900 text-center">
                  {item.tripCount} Trips
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-900 text-right">
                  {item.totalEarnings}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TripDashboardList;
