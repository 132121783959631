import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyTruckDetailsAction,
  getCompanyTruckNotSellDetailsAction,
  getCompanyTruckSellDetailsAction,
  getTruckFitnessAction,
  getTruckInsuranceAction,
  getTruckPermitAction,
} from "../../../redux/company/truck/action";
import Backdrop from "../../backdrop";
import { CgAddR } from "react-icons/cg";
import { useNavigate, useSearchParams } from "react-router-dom";
import TruckList from "./truckList";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../ui/tabs";
import TruckListExpire from "./truckListExpire";
import TruckSellList from "./truckSellList";
import TruckNotSellList from "./truckNotSell";

function Truck() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    getCompanyTruckDetails,
    getCompanyTruckDetailsLoading,
    getCompanyTruckSellDetails,
    getCompanyTruckSellDetailsLoading,
    getCompanyTruckNotSellDetails,
    getCompanyTruckNotSellDetailsLoading,
    getTruckInsurance,
    getTruckInsuranceLoading,
    getTruckInsuranceError,
    getTruckFitness,
    getTruckFitnessLoading,
    getTruckPermit,
    getTruckPermitLoading,
  } = useSelector((state) => state.Companytrucksection);
  const [data, setData] = useState([]);
  const [sellData, setSellData] = useState([]);
  const [notSellData, setNotSellData] = useState([]);
  const [insuranceData, setInsuranceData] = useState([]);
  const [fitnessData, setFitnessData] = useState([]);
  const [permitData, setPermitData] = useState([]);
  const [dropdown, setDropdown] = useState("All");
  
  useEffect(() => {
    dispatch(getTruckInsuranceAction());
    dispatch(getTruckFitnessAction());
    dispatch(getTruckPermitAction());
  }, [dispatch]);
  
  useEffect(() => {
    if (getCompanyTruckDetails?.status === 200) {
      setData(getCompanyTruckDetails);
    }
  }, [getCompanyTruckDetails]);

  useEffect(() => {
    if (getCompanyTruckSellDetails?.status === 200) {
      setSellData(getCompanyTruckSellDetails);
    }
  }, [getCompanyTruckSellDetails]);

  useEffect(() => {
    if (getCompanyTruckNotSellDetails?.status === 200) {
      setNotSellData(getCompanyTruckNotSellDetails);
    }
  }, [getCompanyTruckNotSellDetails]);
  
  useEffect(() => {
    if (getTruckFitness?.status === 200 && getTruckFitness?.data?.length > 0) {
      setFitnessData(getTruckFitness?.data);
    }
  }, [getTruckFitness]);
  
  useEffect(() => {
    if (getTruckPermit?.status === 200 && getTruckPermit?.data?.length > 0) {
      setPermitData(getTruckPermit?.data);
    }
  }, [getTruckPermit]);

  useEffect(() => {
    if (getTruckInsurance?.status === 200 && getTruckInsurance?.data?.length > 0) {
      setInsuranceData(getTruckInsurance?.data);
    }
  }, [getTruckInsurance]);

  const handleTab = (value) => {
    setDropdown(value)
    searchParams.set("tab", value);
    setSearchParams(searchParams);
  };

  // Dropdown data for small screens
  const dropdownOptions = [
    { value: "All", label: "All Trucks" },
    { value: "notSell", label: "In Company" },
    { value: "sell", label: "Sold" },
    // { value: "subscribe", label: "Not Subscribe" },
    insuranceData.length > 0 && { value: "insurance", label: "Expire Insurance" },
    fitnessData.length > 0 && { value: "fitness", label: "Expire Fitness" },
    permitData.length > 0 && { value: "permit", label: "Expire Permit" },
  ].filter(Boolean); // Filter out any false values

  const handleDropdownChange = (event) => {
    handleTab(event.target.value);
  };

  return (
    <div className="">
      {(getCompanyTruckDetailsLoading || getCompanyTruckSellDetailsLoading ||getCompanyTruckNotSellDetailsLoading || getTruckInsuranceLoading || getTruckFitnessLoading || getTruckPermitLoading) && <Backdrop />}
      <div className="flex justify-end h-fit">
        <button
          type="button"
          className="primaryButton lg:w-fit w-full justify-center flex gap-2 lg:mb-0 mb-5 text-sm  "
          onClick={() => navigate("/company/truck/addTruck")}
        >
          <CgAddR className="text-xl" />
          Add New Truck
        </button>
      </div>

      {/* Dropdown for small screens */}
      <div className="block lg:hidden mb-5">
        <select
          onChange={handleDropdownChange}
          value={searchParams.get("tab") || "All"}
          className="primarySelect w-full"
        >
          {dropdownOptions.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      {/* Tabs for larger screens */}
        <Tabs
          defaultValue={searchParams.get("tab") || dropdown}
          value={searchParams.get("tab") || dropdown}
          color=""
          className=""
        >
          <TabsList className="hidden w-fit lg:block">
            <TabsTrigger onClick={() => handleTab("All")} value="All">
              All Trucks
            </TabsTrigger>
            <TabsTrigger onClick={() => handleTab("notSell")} value="notSell">
              In Company
            </TabsTrigger>
            <TabsTrigger onClick={() => handleTab("sell")} value="sell">
              Sold
            </TabsTrigger>
            {/* <TabsTrigger onClick={() => handleTab("subscribe")} value="subscribe">
              Not Subscribe
            </TabsTrigger> */}
            {insuranceData?.length > 0 && (
              <TabsTrigger onClick={() => handleTab("insurance")} value="insurance">
                <div className="alert">
                  Expire Insurance
                  <div className="point"></div>
                </div>
              </TabsTrigger>
            )}
            {fitnessData?.length > 0 && (
              <TabsTrigger onClick={() => handleTab("fitness")} value="fitness">
                <div className="alert">
                  Expire Fitness
                  <div className="point"></div>
                </div>
              </TabsTrigger>
            )}
            {permitData?.length > 0 && (
              <TabsTrigger onClick={() => handleTab("permit")} value="permit">
                <div className="alert">
                  Expire Permit
                  <div className="point"></div>
                </div>
              </TabsTrigger>
            )}
          </TabsList>

          {/* Tab content */}
          <TabsContent value="All">
            <TruckList data={data} />
          </TabsContent>
          <TabsContent value="notSell">
            <TruckNotSellList data={notSellData} />
          </TabsContent>
          <TabsContent value="sell">
            <TruckSellList data={sellData} />
          </TabsContent>
          {/* <TabsContent value="subscribe">
            <TruckList data={data.filter((item) => item.isSubscribe === false)} />
          </TabsContent> */}
          <TabsContent value="insurance">
            <TruckListExpire data={insuranceData} section="insurance" />
          </TabsContent>
          <TabsContent value="fitness">
            <TruckListExpire data={fitnessData} section="fitness" />
          </TabsContent>
          <TabsContent value="permit">
            <TruckListExpire data={permitData} section="permit" />
          </TabsContent>
        </Tabs>
    </div>
  );
}

export default Truck;
