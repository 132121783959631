import React from "react";
import IncomeTable from "./incomeTable";

function GraphSection({ data, setShow, show }) {
  // Function to format numbers as INR
  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', maximumFractionDigits: 2 }).format(amount)
      : '₹0.00';

  // Format income and expense
  const formattedTotalIncome = formatAmount(data?.income?.total);
  const formattedTotalExpense = formatAmount(data?.totalExpenses);

  return (
    <>
      {/* Total Revenue Section */}
      <div className="col-span-2 bg-white mt-6 shadow-lg rounded-sm">
        <div className="p-4 text-[#64709B] font-semibold cursor-pointer">
          <div onClick={()=>setShow("trip")}>Total Revenue</div>
        </div>
        <div className="w-full h-0.5 bg-[#F1F2F6]" />
        <div className="p-4 text-[#303750] text-2xl font-bold flex gap-2">
          {formattedTotalIncome} <div className="text-base font-medium mt-auto">INR</div>
        </div>
      </div>
    {show === "trip" &&  <IncomeTable data={data?.income?.details} setShow={setShow} show={show} />}

      {/* Total Expense Section */}
      <div className="col-span-2 bg-white mt-6 shadow-lg rounded-sm">
        <div className="p-4 text-[#64709B] font-semibold">
          <div>Total Expense</div>
        </div>
        <div className="w-full h-0.5 bg-[#F1F2F6]" />
        <div className="p-4 text-[#303750] text-2xl font-bold flex gap-2">
          {formattedTotalExpense} <div className="text-base font-medium mt-auto">INR</div>
        </div>
      </div>
    </>
  );
}

export default GraphSection;
