import { MdDeleteForever } from "react-icons/md";
import { Input } from "../../ui/input";
import React, { useEffect, useState } from "react";
import api from "../../auth/api";
import Backdrop from "../backdrop";

function FileMultiUpload({ setFieldValue, name, values, disabled, ...rest }) {
  const maxSize = 10 * 1024 * 1024;
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  // Initialize uploaded files (both URL and public_id)
  const [uploadedFiles, setUploadedFiles] = useState(values);
useEffect(() => {
 if(values.length > 0 && initialLoad) {
  setUploadedFiles(values)
  setInitialLoad(false)
 }
}, [values])

  const handleFileChange = (files) => {
    const selectedFile = files?.[0];
    if (selectedFile) {
      validateAndSetFile(selectedFile);
    }
  };

  const validateAndSetFile = async (file) => {
    if (file.size > maxSize) {
      setError(`File size should not exceed ${maxSize / (1024 * 1024)}MB`);
    } else {
      setError(null);
      const formData = new FormData();
      formData.append("image", file);
      setLoader(true);

      try {
        const res = await api.post("/upload", formData);
        if (res?.data?.status === 200) {
          setLoader(false);
          const newFile = {
            url: res.data.data.secure_url, // File URL
            public_id: res.data.data.public_id, // File public_id
          };
          
          // Add the new file to the uploaded files list
          const updatedFiles = [...uploadedFiles, newFile];
          console.log(updatedFiles)
          setUploadedFiles(updatedFiles);
          setFieldValue(name, updatedFiles); // Update form value
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        setLoader(false);
        setError("Error uploading file. Please try again.");
      }
    }
  };

  const deleteFile = async (fileToDelete) => {
    try {
      if (fileToDelete.public_id) {
        setLoader(true);
        const res = await api.delete("/remove", {
          data: { public_id: fileToDelete.public_id },
        }); // Use public_id to delete file
        if (res?.data?.status === 200) {
          setLoader(false);
          // Remove the deleted file from the list
          const updatedFiles = uploadedFiles.filter(
            (file) => file.public_id !== fileToDelete.public_id
          );
          setUploadedFiles(updatedFiles);
          setFieldValue(name, updatedFiles); // Update form value
        } else {
          setLoader(false);
        }
      }
    } catch (err) {
      setLoader(false);
      console.error("Error deleting file:", err);
      setError("Error deleting file. Please try again.");
    }
  };
console.log(uploadedFiles)
  return (
    <div className="">
      {loader && <Backdrop />}

      {/* Display uploaded files */}
      {uploadedFiles.length > 0 && (
        <div className="flex flex-wrap gap-4">
          {uploadedFiles.map((file, index) => (
            <div
              key={index}
              className="flex gap-10 relative mt-4  rounded-md items-center"
            >
              <img
                src={file.url}
                alt={`Uploaded File ${index + 1}`}
                className="w-20 h-auto"
              />
              <button
                type="button"
                onClick={() => deleteFile(file)}
                className="w-fit absolute -top-2 -right-2 bg-white rounded-full"
                disabled={disabled}
              >
                <MdDeleteForever className="cursor-pointer text-xl text-red-500" />
              </button>
            </div>
          ))}
        </div>
      )}

      {/* File upload input section */}
      <div className="mt-4">
        <label htmlFor={name} className="flex flex-col">
          <div className="flex flex-col items-center font-bold bg-white text-black gap-6 rounded-xl border-2 border-dashed border-[#dce0e5] px-6 py-14">
            Upload Image
          </div>
        </label>
        <Input
          id={name}
          type="file"
          className="invisible w-0"
          disabled={disabled}
          accept="image/*" // Accept only image files
          {...rest}
          onChange={(e) => handleFileChange(e.target.files)}
        />
        {error && (
          <div style={{ color: "red", marginTop: "10px" }}>{error}</div>
        )}
      </div>
    </div>
  );
}

export default FileMultiUpload;
