import {
    getAdminDashboardExpensesGraphAction,
    getAdminDashboardProfiltLossAction,
    getAdminDashboardSubscriptionGraphPFAction,
  } from "../../../redux/admin/dashboard/action";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import Chart from "react-apexcharts";
  import { getCompanyListAction } from "../../../redux/admin/company/action";
  
  function Profitloss() {
    const dispatch = useDispatch();
    const {
      getAdminDashboardProfiltLoss,
      getAdminDashboardProfiltLossLoading,
      getAdminDashboardProfiltLossError,
      getAdminDashboardSubscriptionGraphPF,
      getAdminDashboardSubscriptionGraphPFLoading,
      getAdminDashboardSubscriptionGraphPFError,
      getAdminDashboardExpensesGraph,
      getAdminDashboardExpensesGraphLoading,
      getAdminDashboardExpensesGraphError,
    } = useSelector((state) => state.AdminDashboardSection);
    const { getCompanyList, getCompanyListLoading, getCompanyListError } =
      useSelector((state) => state.AdminCompanysection);
  
    const [companyList, setCompanyList] = useState([]);
    const [year, setYear] = useState("2024");
    const [data, setData] = useState({});
    const [expenses, setExpenses] = useState({});
    const [subscription, setSubscription] = useState({});
  
    useEffect(() => {
      dispatch(getCompanyListAction());
    }, [dispatch]);
  
    useEffect(() => {
      if (getAdminDashboardProfiltLoss?.status === 200) {
        setData(getAdminDashboardProfiltLoss.data);
      }
    }, [getAdminDashboardProfiltLoss]);
  
    useEffect(() => {
      if (getAdminDashboardSubscriptionGraphPF?.status === 200) {
        setSubscription(getAdminDashboardSubscriptionGraphPF.data);
      }
    }, [getAdminDashboardSubscriptionGraphPF]);
  
    useEffect(() => {
      if (getAdminDashboardExpensesGraph?.status === 200) {
        setExpenses(getAdminDashboardExpensesGraph.data);
      }
    }, [getAdminDashboardExpensesGraph]);
  
    useEffect(() => {
      if (getCompanyList?.status === 200) {
        setCompanyList(getCompanyList.data);
      }
    }, [getCompanyList]);
  
    useEffect(() => {
      initialLoad(year);
    }, [year]);
  
    const initialLoad = (yearValue) => {
      const queryParams = {
        year: yearValue,
      };
  
      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");
  
      dispatch(getAdminDashboardProfiltLossAction(queryString));
      dispatch(getAdminDashboardExpensesGraphAction(queryString));
      dispatch(getAdminDashboardSubscriptionGraphPFAction(queryString));
    };
  
    // Helper function to parse date strings
    const parseDate = (dateStr) => {
      return new Date(dateStr);
    };
  
    // Collect all unique periods from data sources
    const periods = new Set([
      ...(subscription?.graph?.map((item) => item.period) || []),
      ...(expenses?.graphData?.map((item) => item.period) || []),
    ]);
  
    // Convert Set to Array, parse dates, and sort
    const sortedPeriods = Array.from(periods)
      .map(period => ({ period, date: parseDate(period) }))
      .sort((a, b) => a.date - b.date)
      .map(({ period }) => period);
  
    // Create chart series data
    const chartData = {
      options: {
        chart: {
          id: "profit-loss-bar",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: sortedPeriods,
        },
        stroke: {
          curve: "smooth",
        },
        colors: ["#2E93fA", "#E91E63"], // Blue for Profit, Red for Loss
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 5,
        },
      },
      series: [
        {
          name: "Profit",
          data: sortedPeriods.map((period) => {
            const item = (subscription?.graph || []).find(e => e.period === period);
            return item ? item.totalCost : 0;
          }),
        },
        {
          name: "Expenses",
          data: sortedPeriods.map((period) => {
            const item = (expenses?.graphData || []).find(e => e.period === period);
            return item ? item.totalExpense : 0;
          }),
        },
      ],
    };
  
    // Calculate total profit or loss and determine the text color
    const totalProfitOrLoss = data?.totalProfitOrLoss || 0;
    const profitOrLossClass =
      totalProfitOrLoss >= 0 ? "text-green-500" : "text-red-500";
  
    return (
      <div className="bg-white p-4 h-full col-span-2">
        <div className="flex justify-between">
          <div className="text-4xl font-bold mb-4">Profit & Loss</div>
          <div className="flex gap-4">
            <div>
              <select
                value={year}
                className="border border-gray-200"
                onChange={(e) => setYear(e.target.value)}
              >
                <option value="">All</option>
                <option value="2024">2024</option>
                <option value="2023">2023</option>
                <option value="2022">2022</option>
              </select>
            </div>
          </div>
        </div>
        <div className={`text-3xl font-bold mb-4 ${profitOrLossClass}`}>
          {Math.round(totalProfitOrLoss).toFixed(2)} <label>INR</label>
        </div>
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="area"
          height={300}
        />
      </div>
    );
  }
  
  export default Profitloss;
  