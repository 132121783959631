import React, { useEffect, useState } from "react";
import TripListCard from "./tripListCard";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getCompanyTripDetailsFilterAction, getCompanyTripPlannedDetailsFilterAction, getCompanyTripRunningDetailsFilterAction } from "../../../redux/company/trip/action";
import { FaFilter } from "react-icons/fa";
import {
  getCompanyEmployeeDriverAction,
  getCompanyEmployeeHelperAction,
} from "../../../redux/company/employee/action";
import { Formik, Form } from "formik";
import { getCompanyTruckListDropdownAction } from "../../../redux/company/truck/action";
import TripFilter from "./tripFilter";
import { Dialog } from "../../../ui/dialog";
import Pagination from "../../pagination";

function TripListRunning({ data }) {
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();
  const { getCompanyEmployeeDriver, getCompanyEmployeeHelper } = useSelector(
    (state) => state.CompanyEmployeesection
  );
  const { getCompanyTruckListDropdown } = useSelector(
    (state) => state.Companytrucksection
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [pageNo, setPageNo] = useState(data?.currentPage || 1);
  const [perPage, setPerPage] = useState(data?.limit || 10);
  const [totalPages, setTotalPages] = useState(data?.totalPages || 0);
  const [totalTrips, setTotalTrips] = useState(data?.totalTrips || 0);
  const [drivers, setDrivers] = useState([]);
  const [truck, setTruck] = useState([]);
  const [helpers, setHelpers] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    // Fetch truck and employee details when component mounts
    dispatch(getCompanyEmployeeDriverAction());
    dispatch(getCompanyEmployeeHelperAction());
    dispatch(getCompanyTruckListDropdownAction(""));
  }, [dispatch]);

  useEffect(() => {
    if (getCompanyTruckListDropdown?.status === 200) {
      setTruck(getCompanyTruckListDropdown?.trucks);
    }
  }, [getCompanyTruckListDropdown]);

  useEffect(() => {
    // Filter drivers and helpers based on the data list
    if (data && getCompanyEmployeeDriver?.status === 200) {
      const driverIds = data?.data?.map((item) => item.driverId?._id);
      const helperIds = data?.data?.map((item) => item.helperId?._id);
      console.log(getCompanyEmployeeDriver)
      const filteredDrivers = getCompanyEmployeeDriver?.data?.filter((driver) =>
        driverIds?.includes(driver._id)
      );
      const filteredHelpers = getCompanyEmployeeHelper?.data?.filter((helper) =>
        helperIds?.includes(helper._id)
      );

      setDrivers(filteredDrivers);
      setHelpers(filteredHelpers);
    }
  }, [data, getCompanyEmployeeDriver, getCompanyEmployeeHelper]);

  // Fetch trip data whenever filters, search query, or pagination changes
  useEffect(() => {
    const truckId = searchParams.get("truckId");
    const clientId = searchParams.get("clientId");
    const payload = {
      page: pageNo,
      limit: perPage,
      searchTerm: searchQuery,
      truckId,
      clientId,
    };
    dispatch(getCompanyTripRunningDetailsFilterAction(payload));
  }, [pageNo, perPage, searchQuery, dispatch]);
  
  useEffect(() => {
    setTotalPages(data?.totalPages);
    setTotalTrips(data?.totalTrips);
  }, [data?.totalPages, data?.totalTrips]);
  
  const handleSearch = (event) => {
    setPageNo(1)
    setSearchQuery(event.target.value);
  };

  const handleFilterSubmit = (values) => {
    const payload = {
      ...Object.fromEntries(
        Object.entries(values).filter(([_, v]) => v !== "")
      ), // Only send filled filters
      page: pageNo,
      limit: perPage,
    };
    dispatch(getCompanyTripRunningDetailsFilterAction(payload));
    setShowFilters(false);
  };

  const handleClearFilters = () => {
    const truckId = searchParams.get("truckId");
    const payload = { truckId, page: pageNo, limit: perPage };
    dispatch(getCompanyTripRunningDetailsFilterAction(payload));
    setShowFilters(false);
  };

  const onNext = () => {
    if (pageNo < totalPages) {
      setPageNo(pageNo + 1);
    }
  };

  const onPrevious = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };

  const handleSetPage = (page) => {
    setPageNo(page);
  };

  const handlePerPage = (value) => {
    setPerPage(parseInt(value));
    setPageNo(1); // Reset to page 1 when perPage changes
  };


  return (
    <div>
      <div className="mb-4 gap-8 flex justify-between items-center">
        <input
          placeholder="Search"
          value={searchQuery}
          onChange={handleSearch}
          className="max-w-sm shadow-md mb-2"
        />
        <button onClick={() => setShowFilters(!showFilters)}>
          <FaFilter className="text-gray-600" />
        </button>
      </div>
      
      {/* Filters */}
      <Formik
        initialValues={{
          startLocation: "",
          endLocation: "",
          truckId: "",
          status: "",
          driverId: "",
          helperId: "",
          startDate: "",
        }}
        onSubmit={handleFilterSubmit}
      >
        {({ values, setFieldValue, handleSubmit, resetForm }) => (
          <Form>
            <Dialog open={showFilters}>
              <TripFilter
                drivers={drivers}
                handleClearFilters={handleClearFilters}
                handleSubmit={handleSubmit}
                helpers={helpers}
                truck={truck}
                values={values}
                setShowFilters={setShowFilters}
                resetForm={resetForm}
              />
            </Dialog>
          </Form>
        )}
      </Formik>

      {/* Trip List */}
      {data?.data?.length > 0 ? (
        data?.data?.map((item) => (
          <TripListCard key={item._id} data={item} reroute={true} />
        ))
      ) : (
        <p>No Trips found.</p>
      )}

      {data?.totalPages > 1 &&
      <Pagination
      currentPage={pageNo}
      onNext={onNext}
      onPrevious={onPrevious}
      from={pageNo * perPage - perPage + 1}
      to={pageNo * perPage}
      totalItems={totalTrips}
      perPage={perPage}
      totalPages={totalPages}
      setPage={handleSetPage}
      setperPage={handlePerPage}
      />
    }
    </div>
  );
}

export default TripListRunning;
