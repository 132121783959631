import { DELETE_ADMIN_DISCOUNT, DELETE_ADMIN_DISCOUNT_ERROR, DELETE_ADMIN_DISCOUNT_SUCCESS, DELETE_ADMIN_SUBSCRIPTION, DELETE_ADMIN_SUBSCRIPTION_ERROR, DELETE_ADMIN_SUBSCRIPTION_SUCCESS, GET_ADMIN_DISCOUNT_BY_ID, GET_ADMIN_DISCOUNT_BY_ID_ERROR, GET_ADMIN_DISCOUNT_BY_ID_SUCCESS, GET_ADMIN_DISCOUNT_LIST, GET_ADMIN_DISCOUNT_LIST_ERROR, GET_ADMIN_DISCOUNT_LIST_SUCCESS, GET_ADMIN_SUBSCRIPTION_BY_ID, GET_ADMIN_SUBSCRIPTION_BY_ID_ERROR, GET_ADMIN_SUBSCRIPTION_BY_ID_SUCCESS, GET_ADMIN_SUBSCRIPTION_LIST, GET_ADMIN_SUBSCRIPTION_LIST_ERROR, GET_ADMIN_SUBSCRIPTION_LIST_SUCCESS, PATCH_ADMIN_DISCOUNT, PATCH_ADMIN_DISCOUNT_ERROR, PATCH_ADMIN_DISCOUNT_SUCCESS, PATCH_ADMIN_SUBSCRIPTION, PATCH_ADMIN_SUBSCRIPTION_ERROR, PATCH_ADMIN_SUBSCRIPTION_SUCCESS, POST_ADMIN_DISCOUNT, POST_ADMIN_DISCOUNT_ERROR, POST_ADMIN_DISCOUNT_SUCCESS, POST_ADMIN_SUBSCRIPTION, POST_ADMIN_SUBSCRIPTION_ERROR, POST_ADMIN_SUBSCRIPTION_SUCCESS } from "../../actions";

const INIT_STATE = {
  getAdminSubscriptionList: {},
  getAdminSubscriptionListLoading: false,
  getAdminSubscriptionListError: "",
  getAdminDiscountList: {},
  getAdminDiscountListLoading: false,
  getAdminDiscountListError: "",
  getAdminTruckbyId: {},
  getAdminSubscriptionById: {},
  getAdminSubscriptionByIdLoading: false,
  getAdminSubscriptionByIdError: "",
  getAdminDiscountById: {},
  getAdminDiscountByIdLoading: false,
  getAdminDiscountByIdError: "",
  postAdminSubscription: {},
  postAdminSubscriptionLoading: false,
  postAdminSubscriptionError: "",
  postAdminDiscount: {},
  postAdminDiscountLoading: false,
  postAdminDiscountError: "",
  patchAdminSubscription: {},
  patchAdminSubscriptionLoading: false,
  patchAdminSubscriptionError: "",
  patchAdminDiscount: {},
  patchAdminDiscountLoading: false,
  patchAdminDiscountError: "",
  deleteAdminSubscription: {},
  deleteAdminSubscriptionLoading: false,
  deleteAdminSubscriptionError: "",
  deleteAdminDiscount: {},
  deleteAdminDiscountLoading: false,
  deleteAdminDiscountError: "",
};

const AdminSubscriptionSection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ADMIN_SUBSCRIPTION_LIST:
      return {
        ...state,
        getAdminSubscriptionList: {},
        getAdminSubscriptionListLoading: true,
      };
    case GET_ADMIN_SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        getAdminSubscriptionList: action.payload,
        getAdminSubscriptionListLoading: false,
        getAdminSubscriptionListError: "",
      };

    case GET_ADMIN_SUBSCRIPTION_LIST_ERROR:
      return {
        ...state,
        getAdminSubscriptionListLoading: false,
        getAdminSubscriptionListError: action.payload,
      };
    case GET_ADMIN_DISCOUNT_LIST:
      return {
        ...state,
        getAdminDiscountList: {},
        getAdminDiscountListLoading: true,
      };
    case GET_ADMIN_DISCOUNT_LIST_SUCCESS:
      return {
        ...state,
        getAdminDiscountList: action.payload,
        getAdminDiscountListLoading: false,
        getAdminDiscountListError: "",
      };

    case GET_ADMIN_DISCOUNT_LIST_ERROR:
      return {
        ...state,
        getAdminDiscountListLoading: false,
        getAdminDiscountListError: action.payload,
      };
    case GET_ADMIN_SUBSCRIPTION_BY_ID:
      return {
        ...state,
        getAdminSubscriptionById: {},
        getAdminSubscriptionByIdLoading: true,
      };
    case GET_ADMIN_SUBSCRIPTION_BY_ID_SUCCESS:
      return {
        ...state,
        getAdminSubscriptionById: action.payload,
        getAdminSubscriptionByIdLoading: false,
        getAdminSubscriptionByIdError: "",
      };

    case GET_ADMIN_SUBSCRIPTION_BY_ID_ERROR:
      return {
        ...state,
        getAdminSubscriptionByIdLoading: false,
        getAdminSubscriptionByIdError: action.payload,
      };
    case GET_ADMIN_DISCOUNT_BY_ID:
      return {
        ...state,
        getAdminDiscountById: {},
        getAdminDiscountByIdLoading: true,
      };
    case GET_ADMIN_DISCOUNT_BY_ID_SUCCESS:
      return {
        ...state,
        getAdminDiscountById: action.payload,
        getAdminDiscountByIdLoading: false,
        getAdminDiscountByIdError: "",
      };

    case GET_ADMIN_DISCOUNT_BY_ID_ERROR:
      return {
        ...state,
        getAdminDiscountByIdLoading: false,
        getAdminDiscountByIdError: action.payload,
      };
    case POST_ADMIN_SUBSCRIPTION:
      return {
        ...state,
        postAdminSubscription: {},
        postAdminSubscriptionLoading: true,
      };
    case POST_ADMIN_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        postAdminSubscription: action.payload,
        postAdminSubscriptionLoading: false,
        postAdminSubscriptionError: "",
      };

    case POST_ADMIN_SUBSCRIPTION_ERROR:
      return {
        ...state,
        postAdminSubscriptionLoading: false,
        postAdminSubscriptionError: action.payload,
      };
    case POST_ADMIN_DISCOUNT:
      return {
        ...state,
        postAdminDiscount: {},
        postAdminDiscountLoading: true,
      };
    case POST_ADMIN_DISCOUNT_SUCCESS:
      return {
        ...state,
        postAdminDiscount: action.payload,
        postAdminDiscountLoading: false,
        postAdminDiscountError: "",
      };

    case POST_ADMIN_DISCOUNT_ERROR:
      return {
        ...state,
        postAdminDiscountLoading: false,
        postAdminDiscountError: action.payload,
      };
    case PATCH_ADMIN_SUBSCRIPTION:
      return {
        ...state,
        patchAdminSubscription: {},
        patchAdminSubscriptionLoading: true,
      };
    case PATCH_ADMIN_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        patchAdminSubscription: action.payload,
        patchAdminSubscriptionLoading: false,
        patchAdminSubscriptionError: "",
      };

    case PATCH_ADMIN_SUBSCRIPTION_ERROR:
      return {
        ...state,
        patchAdminSubscriptionLoading: false,
        patchAdminSubscriptionError: action.payload,
      };
    case PATCH_ADMIN_DISCOUNT:
      return {
        ...state,
        patchAdminDiscount: {},
        patchAdminDiscountLoading: true,
      };
    case PATCH_ADMIN_DISCOUNT_SUCCESS:
      return {
        ...state,
        patchAdminDiscount: action.payload,
        patchAdminDiscountLoading: false,
        patchAdminDiscountError: "",
      };

    case PATCH_ADMIN_DISCOUNT_ERROR:
      return {
        ...state,
        patchAdminDiscountLoading: false,
        patchAdminDiscountError: action.payload,
      };
    case DELETE_ADMIN_SUBSCRIPTION:
      return {
        ...state,
        deleteAdminSubscription: {},
        deleteAdminSubscriptionLoading: true,
      };
    case DELETE_ADMIN_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        deleteAdminSubscription: action.payload,
        deleteAdminSubscriptionLoading: false,
        deleteAdminSubscriptionError: "",
      };
    case DELETE_ADMIN_SUBSCRIPTION_ERROR:
      return {
        ...state,
        deleteAdminSubscriptionLoading: false,
        deleteAdminSubscriptionError: action.payload,
      };
      case DELETE_ADMIN_DISCOUNT:
        return {
          ...state,
          deleteAdminDiscount: {},
          deleteAdminDiscountLoading: true,
        };
      case DELETE_ADMIN_DISCOUNT_SUCCESS:
        return {
          ...state,
          deleteAdminDiscount: action.payload,
          deleteAdminDiscountLoading: false,
          deleteAdminDiscountError: "",
        };
      case DELETE_ADMIN_DISCOUNT_ERROR:
        return {
          ...state,
          deleteAdminDiscountLoading: false,
          deleteAdminDiscountError: action.payload,
        };
    default:
      return { ...state };
  }
};

export default AdminSubscriptionSection;
