import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Image from "../../../assets/images/truck.svg";
import { Badge } from "../../../ui/badge";
import TruckSell from "./truckSell";
import { Dialog, DialogTrigger } from "../../../ui/dialog";
import SubscriptionList from "./subscription/subscriptionList";
import ImagePopUpDownload from "../../imagePopUpDownload";
import { FaRegEdit } from "react-icons/fa";
import { FcImageFile } from "react-icons/fc";

function TruckListCard({ data, reroute = false }) {
  const navigate = useNavigate();

  const [showModal, setshowModal] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(amount)
      : "₹0.00";
  return (
    <div key={data._id}>
      <div className="grid grid-cols-12 lg:grid-rows-2 xl:gap-x-6 gap-x-3 gap-y-3  lg:p-4 p-2 bg-white rounded-lg shadow-md">
        <div className="row-span-2 lg:col-span-1 justify-center col-span-12 flex relative">
          <button
            type="button"
            className="lg:w-fit w-full mx-auto flex justify-center relative"
            onClick={() =>
              reroute ? navigate(`details?truckId=${data._id}`) : ""
            }
          >
            <div className="p-5 bg-[#506CC9] rounded-md w-fit ">
              <img src={Image} alt="" className={`h-10 w-10 `} />
            </div>
          </button>
          <div className=" flex lg:hidden ml-auto absolute z-30 right-0">
            {data?.truckId?.truckImage && (
              <button
                type="button"
                onClick={() => setShowImageModal(true)}
                className="ghostButton font-semibold flex gap-1 "
                disabled={!data?.truckId?.truckImage}
              >
                <FcImageFile className="text-2xl" />
              </button>
            )}
            <button
              type="button"
              className="ghostButton font-semibold  flex gap-1"
              onClick={() =>
                navigate(`/company/truck/addtruck?id=${data?._id}`)
              }
              disabled={data?.isSell}
            >
              <FaRegEdit className="text-xl" />
              Edit
            </button>
          </div>
        </div>
        {data?.truckId?.truckImage && (
          <Dialog open={showImageModal}>
            <ImagePopUpDownload
              image={data?.truckId?.truckImage}
              setShowModal={setShowImageModal}
              imagename={`truck_${data?.truckId?._id}`}
            />
          </Dialog>
        )}
        <div className="lg:col-span-2 col-span-12 flex flex-col gap-1 row-span-2">
          <button
            type="button"
            className="textPrimary lg:text-left text-center uppercase"
            onClick={
              reroute ? () => navigate(`details?truckId=${data._id}`) : null
            }
          >
            {data.truckNumber}
          </button>
          <p className="textSecondary mt-0 lg:text-left text-center">
            {data.truckId?.truckCompanyName}, {data.truckId?.truckModelName},{" "}
            {data.truckId?.typeOfTruck}{" "}
          </p>
          <div
            className={`flex w-full lg:justify-start justify-center gap-2  ${
              data.isSubscribe ? "text-[#15803D] " : "text-[#DC1919]"
            }`}
          >
            <p className="">
              <div
                className={`px-1.5 py-0.5 rounded-full border  text-[10px] font-medium ${
                  data.isSubscribe ? "border-[#15803D] " : "border-[#DC1919]"
                }`}
              >
                {data?.isSubscribe ? "Subscribed" : "UnSubscribed"}
              </div>
            </p>
            <p className="text-[10px] my-auto font-medium">
              {moment(data?.subscribeTill).diff(moment(new Date()), "days")}{" "}
              Days Left
            </p>
          </div>
          <p>
            <div
              className={`px-1.5 py-0.5 rounded-full border w-fit  text-[10px] font-medium ${
                data.runningStatus === "Available"
                  ? "border-[#15803D] text-[#15803D] "
                  : "border-[#DC1919] text-[#DC1919]"
              }`}
            >
              {data.runningStatus === "Available" ? "Availabe To Run" : data.runningStatus}
            </div>
          </p>
          {data.isSell && (
            <div
              className={`flex gap-2 w-full lg:justify-start justify-center ${
                !data.isSell ? "text-[#15803D] " : "text-[#DC1919]"
              }`}
            >
              <p>
                <div
                  className={`px-1.5 py-0.5 rounded-full border  text-[10px] font-medium ${
                    !data.isSell
                      ? "border-[#15803D] text-[#15803D] "
                      : "border-[#DC1919] text-[#DC1919]"
                  }`}
                >
                  Sold
                </div>
              </p>
              <p className="text-[10px] my-auto font-medium">
                {moment(data?.sellDate).format("DD / MMM / YYYY")}{" "}
              </p>
            </div>
          )}
        </div>
        <div className="text-xs text-[#64709B] lg:col-span-2 col-span-6">
          <h3 className=" ">Owner</h3>
          <p className="textSecondary">{data?.truckOwnByName}</p>
        </div>
        <div className="text-xs text-[#64709B] lg:col-span-2 col-span-6 lg:text-left text-right">
          <h3 className=" ">Running In</h3>
          <p className="textSecondary">{data?.usedfor}</p>
        </div>
        <div className="text-xs text-[#64709B] lg:col-span-1 col-span-6 ">
          <h3 className=" ">Insurance Till</h3>
          <p className="textSecondary">
            {data?.truckId?.isInsurance && !data.isSell
              ? moment(data?.truckId?.insuranceTill).format("DD / MMM / YYYY")
              : "-"}
          </p>
        </div>
        <div className="text-xs text-[#64709B]  lg:col-span-1 col-span-6 lg:text-left text-right">
          <h3 className=" ">Fitness Till</h3>
          <p className="textSecondary">
            {data?.truckId?.isFitness && !data.isSell
              ? moment(data?.truckId?.fitnessTill).format("DD / MMM / YYYY")
              : "-"}
          </p>
        </div>
        <div className="text-xs text-[#64709B] lg:col-span-1 col-span-6">
          <h3 className=" ">Permit Till</h3>
          <p className="textSecondary">
            {data?.truckId?.isPermit && !data.isSell
              ? moment(data?.truckId?.permitTill).format("DD / MMM / YYYY")
              : "-"}
          </p>
        </div>
        <div className="text-xs text-[#64709B]  lg:col-span-1 col-span-6 lg:text-left text-right">
          <h3 className=" ">In Emi</h3>
          <p className="textSecondary">
            {!data.isSell ? (data?.truckId?.isEmi ? "YES" : "NO") : "-"}
          </p>
          {data?.truckId?.isEmi && !data.isSell && (
            <>
              <p className="text-[#DC1919]">
                {formatAmount(data?.truckId?.emiLeft)} Left
              </p>
              <p>
                {moment(data?.truckId?.emiEndDate).diff(
                  moment(new Date()),
                  "months"
                )}{" "}
                Months Left
              </p>
            </>
          )}
        </div>
        <div className=" gap-3 flex-wrap lg:flex hidden col-span-2 justify-end">
          {data?.truckId?.truckImage && (
            <button
              type="button"
              onClick={() => setShowImageModal(true)}
              className="ghostButton font-semibold flex gap-1 "
            >
              <FcImageFile className="text-2xl" /> Image
            </button>
          )}
          <button
            type="button"
            className="ghostButton font-semibold  flex gap-1"
            onClick={() => navigate(`/company/truck/addtruck?id=${data?._id}`)}
            disabled={data?.isSell}
          >
            <FaRegEdit className="text-xl" />
            Edit
          </button>
        </div>
        <div className="lg:col-start-6 col-start-1 lg:col-span-2 col-span-12 ml-auto lg:row-start-2 w-full mt-auto">
          <button
            className="secondaryButton mt-auto w-full text-center  text-sm"
            onClick={() => setShowSubscribeModal(true)}
            disabled={data?.isSell}
          >
            {data?.isSubscribe ? "Extend Subscription" : "Subscribe"}
          </button>
          <Dialog open={showSubscribeModal}>
            <SubscriptionList
              setShowModal={setShowSubscribeModal}
              truckId={data?._id}
            />
          </Dialog>
        </div>
        <div className=" lg:col-start-8 col-start-1 lg:col-span-2 col-span-6 ml-auto lg:row-start-2 w-full mt-auto">
          <button
            onClick={() => setshowModal(true)}
            className="secondaryButton mt-auto w-full text-center  text-sm"
            disabled={data?.isSell}
          >
            Sell Truck
          </button>
        </div>
        <Dialog open={showModal}>
          <TruckSell id={data?._id} setshowModal={setshowModal} />
        </Dialog>
        <button
          onClick={() =>
            navigate(`/company/truck/emi?truckId=${data?.truckId?._id}`)
          }
          className="secondaryButton mt-auto w-full text-center text-sm lg:col-start-10 col-start-7 lg:col-span-2 col-span-6 ml-auto lg:row-start-2"
          disabled={!data?.truckId?.isEmi || data?.isSell}
        >
          Emi Details
        </button>
        <div className=" mt-auto lg:col-start-12 col-start-1 lg:col-span-2 col-span-12 ml-auto w-full lg:row-start-2">
          <button
            type="button"
            className="primaryButton text-sm w-full "
            disabled={data?.isSell}
            onClick={() =>
              navigate(`/company/trip/addtrip?truckId=${data._id}`)
            }
          >
            Add Trip
          </button>
        </div>
      </div>
    </div>
  );
}

export default TruckListCard;
