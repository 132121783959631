import {
  getAdminDiscountListAction,
  getAdminSubscriptionListAction,
} from "../../../redux/admin/subscription/action";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Backdrop from "../../backdrop";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  getAdminTruckbyIdAction,
  getAdminTruckSubscriptionDetailsAction,
  postAdminTruckSubscriptionAction,
} from "../../../redux/admin/truck/action";
import ReactSelectComponent from "../../reactSelect";

function AddTruckSubscription() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    getAdminSubscriptionList,
    getAdminSubscriptionListLoading,
    getAdminSubscriptionListError,
    getAdminDiscountList,
    getAdminDiscountListLoading,
    getAdminDiscountListError,
  } = useSelector((state) => state.AdminSubscriptionSection);
  const {
    getAdminTruckbyId,
    getAdminTruckbyIdLoading,
    getAdminTruckbyIdError,
    getAdminTruckSubscriptionDetails,
    getAdminTruckSubscriptionDetailsLoading,
    getAdminTruckSubscriptionDetailsError,
    postAdminTruckSubscription,
    postAdminTruckSubscriptionLoading,
    postAdminTruckSubscriptionError,
  } = useSelector((state) => state.AdminTruckSection);
  const truckId = searchParams.get("truckId");
  const companyTruckId = searchParams.get("companyTruckId");
  const [SubscriptionData, setSubscriptionData] = useState();
  const [SubscriptionList, setSubscriptionList] = useState([]);
  const [discountList, setDiscountList] = useState([]);
  const [truckNumber, setTruckNumber] = useState("");
  const [isSubscriptionSelected, setIsSubscriptionSelected] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getAdminSubscriptionListAction());
    dispatch(getAdminDiscountListAction());
    if (truckId !== null) {
      dispatch(getAdminTruckbyIdAction(truckId));
    }
  }, []);

  useEffect(() => {
    if (getAdminSubscriptionList.status === 200) {
      setSubscriptionList(getAdminSubscriptionList.data);
    }
  }, [getAdminSubscriptionList]);
  useEffect(() => {
    if (getAdminSubscriptionListError.status === 400) {
      toast.error(getAdminSubscriptionListError.message);
    } else if (getAdminSubscriptionListError.status === 404) {
      toast.error(getAdminSubscriptionListError.message);
    } else if (getAdminSubscriptionListError.status === 500) {
      toast.error(getAdminSubscriptionListError.error);
    }
  }, [getAdminSubscriptionListError]);

  useEffect(() => {
    if (getAdminTruckSubscriptionDetails.status === 200) {
      setSubscriptionData(getAdminTruckSubscriptionDetails.data);
      setIsSubscriptionSelected(true);
    }
  }, [getAdminTruckSubscriptionDetails]);

  useEffect(() => {
    if (getAdminTruckSubscriptionDetailsError.status === 400) {
      toast.error(getAdminTruckSubscriptionDetailsError.message);
    } else if (getAdminTruckSubscriptionDetailsError.status === 404) {
      toast.error(getAdminTruckSubscriptionDetailsError.message);
    } else if (getAdminTruckSubscriptionDetailsError.status === 500) {
      toast.error(getAdminTruckSubscriptionDetailsError.error);
    }
  }, [getAdminTruckSubscriptionDetailsError]);

  useEffect(() => {
    if (getAdminDiscountList.status === 200) {
      const discountOptions = getAdminDiscountList.data.map((discount) => ({
        value: discount._id,
        label: `${discount.extraDiscountName} (${discount.extraDiscountPercent}%)`,
      }));
      setDiscountList(discountOptions);
    }
  }, [getAdminDiscountList]);
  useEffect(() => {
    if (getAdminDiscountListError.status === 400) {
      toast.error(getAdminDiscountListError.message);
    } else if (getAdminDiscountListError.status === 404) {
      toast.error(getAdminDiscountListError.message);
    } else if (getAdminDiscountListError.status === 500) {
      toast.error(getAdminDiscountListError.error);
    }
  }, [getAdminDiscountListError]);
  useEffect(() => {
    if (getAdminTruckbyId.status === 200) {
      setTruckNumber(getAdminTruckbyId?.data?.truck?.truckNumber);
    }
  }, [getAdminTruckbyId]);
  useEffect(() => {
    if (getAdminTruckbyIdError.status === 400) {
      toast.error(getAdminTruckbyIdError.message);
    } else if (getAdminTruckbyIdError.status === 404) {
      toast.error(getAdminTruckbyIdError.message);
    } else if (getAdminTruckbyIdError.status === 500) {
      toast.error(getAdminTruckbyIdError.error);
    }
  }, [getAdminTruckbyIdError]);
  useEffect(() => {
    if (loading) {
      if (postAdminTruckSubscription.status === 200) {
        toast.success(postAdminTruckSubscription.message, {
          id: "post-Subscription",
        });
        navigate(-1);
      }
    }
  }, [postAdminTruckSubscription]);
  useEffect(() => {
    if (loading) {
      if (postAdminTruckSubscription.status === 400) {
        toast.error(postAdminTruckSubscription.message);
      } else if (postAdminTruckSubscription.status === 404) {
        toast.error(postAdminTruckSubscription.message);
      } else if (postAdminTruckSubscription.status === 500) {
        toast.error(postAdminTruckSubscription.error);
      }
    }
  }, [postAdminTruckSubscription]);

  const initialValues = {
    subscription: {
      _id: SubscriptionData?.subscriptionId || "",
      finalCost: 0 || SubscriptionData?.cost,
      extraDiscounts:
        SubscriptionData?.extraDiscounts.map((discount) => ({
          value: discount._id,
          label: `${discount.extraDiscountName} ${discount.extraDiscountPercent}%`,
        })) || [],
      months: 0 || SubscriptionData?.months,
      defaultCost: 0 || SubscriptionData?.defaultCost,
      isDiscount: SubscriptionData?.isDiscount || false,
      discountName: SubscriptionData?.discountName || "",
      discountPercent: SubscriptionData?.discountPercent || 0,
    },
    paymentType: "Cash",
    transitionNo: "",
    truckNumber: "" || truckNumber,
  };
  const validationSchema = Yup.object({
    truckNumber: Yup.string().required("This Field is Required"),
  });

  const handleSubscription = (value) => {
    const payload = {
      subscriptionId: value,
      truckId: companyTruckId,
    };
    dispatch(getAdminTruckSubscriptionDetailsAction(payload));
  };

  const onSubmit = (values) => {
    const payload = {
      id: companyTruckId,
      body: values,
    };
    dispatch(postAdminTruckSubscriptionAction(payload));
    toast.loading("Saving...", { id: "post-Subscription" });
    setLoading(true);
  };
  return (
    <div>
      {(getAdminSubscriptionListLoading ||
        getAdminTruckbyIdLoading ||
        getAdminDiscountListLoading ||
        getAdminTruckSubscriptionDetailsLoading) && <Backdrop />}
      <h2 className="text-2xl font-bold mb-4">Truck Subscription</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values, errors }) => (
          <Form>
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 ">
              <div>
                <label htmlFor="truckNumber">
                  Truck Number <span>*</span>
                </label>
                <Field
                  type="text"
                  id="truckNumber"
                  name="truckNumber"
                  disabled={true}
                  className=""
                  placeholder="Truck Number"
                />
                <ErrorMessage
                  name="truckNumber"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div>
                <label htmlFor="subscriptionList">
                  Subscription List <span>*</span>
                </label>
                <Field
                  as="select"
                  id="subscriptionList"
                  name="subscription._id"
                  className=""
                  placeholder="Subscription List"
                  onChange={(e) => {
                    setFieldValue("subscription._id", e.target.value);
                    handleSubscription(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  {SubscriptionList.map((item) => (
                    <option value={item._id}>{item.name}</option>
                  ))}
                </Field>
                <ErrorMessage
                  name="subscription._id"
                  component="div"
                  className="text-red-500"
                />
              </div>
              {isSubscriptionSelected && (
                <>
                  <div>
                    <label htmlFor="finalCost">Default Cost</label>
                    <Field
                      type="number"
                      id="finalCost"
                      name="subscription.finalCost"
                      placeholder="Final Cost"
                      value={values.subscription.defaultCost}
                      disabled
                    />
                  </div>
                  <div>
                    <label htmlFor="months">Months</label>
                    <Field
                      type="number"
                      id="months"
                      name="subscription.months"
                      placeholder="Months"
                      value={values.subscription.months}
                      disabled
                    />
                  </div>
                  {values.subscription.isDiscount && (
                    <>
                      <div>
                        <label htmlFor="discountName">
                          Default Discount Name
                        </label>
                        <Field
                          type="text"
                          id="discountName"
                          name="subscription.discountName"
                          placeholder="Discount Name"
                          value={values.subscription.discountName}
                          disabled
                        />
                      </div>
                      <div>
                        <label htmlFor="discountName">
                          Default Discount Percent
                        </label>
                        <Field
                          type="text"
                          id="discountName"
                          name="subscription.discountPercent"
                          placeholder="Discount Percent"
                          value={values.subscription.discountPercent}
                          disabled
                        />
                      </div>
                      {console.log(values.subscription.extraDiscounts)}
                      <div>
                        <label htmlFor="extraDiscounts">Extra Discounts</label>
                        <ReactSelectComponent
                          isMulti
                          options={discountList}
                          selected={discountList.filter((option) =>
                            values.subscription.extraDiscounts.includes(
                              option.value
                            )
                          )}
                          isDisabled={true}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          name="subscription.extraDiscounts"
                        />
                      </div>
                    </>
                  )}
                </>
              )}
              <div>
                <label htmlFor="paymentType">
                  Payment Type <span>*</span>
                </label>
                <Field
                  as="select"
                  id="paymentType"
                  name="paymentType"
                  className=""
                  placeholder="Subscription List"
                >
                  <option value="Cash">Cash</option>
                  <option value="Upi">Upi</option>
                  <option value="Bank Transfer">Bank Transfer</option>
                </Field>
                <ErrorMessage
                  name="paymentType"
                  component="div"
                  className="text-red-500"
                />
              </div>
              {values.paymentType !== "Cash" && (
                <div>
                  <label htmlFor="transitionNo">
                    Transition No <span>*</span>
                  </label>
                  <Field
                    type="text"
                    id="transitionNo"
                    name="transitionNo"
                    className=""
                    placeholder="Transition No"
                  />
                </div>
              )}
              <div>
                <label htmlFor="finalCost">
                  Final Cost <span>*</span>
                </label>
                <Field
                  type="number"
                  id="finalCost"
                  name="subscription.finalCost"
                  className=""
                  disabled
                  placeholder="Transition No"
                />
              </div>
              <button type="submit" className="primaryButton h-fit mt-auto">
                Save{" "}
                {values.paymentType === "Cash"
                  ? Math.round(values.subscription.finalCost * 0.98)
                  : values.subscription.finalCost}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddTruckSubscription;
