import React from "react";
import loginImage from "../../assets/images/login.jpeg";
import { Outlet } from "react-router-dom";
import LanguageSwitcher from "../../components/languageSwitcher";
import { Toaster } from "react-hot-toast";
import Header from "./header";

function Layout() {
  return (
    <>
    <div className="flex h-screen flex-col max-h-screen max-w-screen bg-gray-700">

      <div className="fixed top-0 left-0 right-0 z-50 ">
        <Header />
      </div>
      <div className="  flex flex-1 items-center justify-center mt-16 overflow-y-auto">
        <div className=" grid grid-cols-5   w-full max-w-5xl bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="w-full h-full md:col-span-3 col-span-5">
            <img
              src={loginImage}
              alt="Login Illustration"
              className="w-full h-full object-cover bg-red-300 "
              />
          </div>
          <Outlet />
        </div>
      </div>
              </div>
     
    </>
  );
}

export default Layout;
