import { DELETE_ADMIN_EXPENSE, DELETE_ADMIN_EXPENSE_ERROR, DELETE_ADMIN_EXPENSE_SUCCESS, GET_ADMIN_EXPENSE_BY_ID, GET_ADMIN_EXPENSE_BY_ID_ERROR, GET_ADMIN_EXPENSE_BY_ID_SUCCESS, GET_ADMIN_EXPENSES_LIST, GET_ADMIN_EXPENSES_LIST_ERROR, GET_ADMIN_EXPENSES_LIST_SUCCESS, PATCH_ADMIN_EXPENSE, PATCH_ADMIN_EXPENSE_ERROR, PATCH_ADMIN_EXPENSE_SUCCESS, POST_ADMIN_EXPENSE, POST_ADMIN_EXPENSE_ERROR, POST_ADMIN_EXPENSE_SUCCESS } from "../../actions";

export const getAdminExpensesListAction = (info) => ({
    type: GET_ADMIN_EXPENSES_LIST,
    payload: info,
  });
  export const getAdminExpensesListSuccess = (info) => ({
    type: GET_ADMIN_EXPENSES_LIST_SUCCESS,
    payload: info,
  });
  export const getAdminExpensesListError = (error) => ({
    type: GET_ADMIN_EXPENSES_LIST_ERROR,
    payload: error,
  });
  
export const getAdminExpenseByIdAction = (info) => ({
    type: GET_ADMIN_EXPENSE_BY_ID,
    payload: info,
  });
  export const getAdminExpenseByIdSuccess = (info) => ({
    type: GET_ADMIN_EXPENSE_BY_ID_SUCCESS,
    payload: info,
  });
  export const getAdminExpenseByIdError = (error) => ({
    type: GET_ADMIN_EXPENSE_BY_ID_ERROR,
    payload: error,
  });
  export const postAdminExpenseAction = (info) => ({
    type: POST_ADMIN_EXPENSE,
    payload: info,
  });
  export const postAdminExpenseSuccess = (info) => ({
    type: POST_ADMIN_EXPENSE_SUCCESS,
    payload: info,
  });
  export const postAdminExpenseError = (error) => ({
    type: POST_ADMIN_EXPENSE_ERROR,
    payload: error,
  });
  export const patchAdminExpenseAction = (info) => ({
    type: PATCH_ADMIN_EXPENSE,
    payload: info,
  });
  export const patchAdminExpenseSuccess = (info) => ({
    type: PATCH_ADMIN_EXPENSE_SUCCESS,
    payload: info,
  });
  export const patchAdminExpenseError = (error) => ({
    type: PATCH_ADMIN_EXPENSE_ERROR,
    payload: error,
  });
  export const deleteAdminExpenseAction = (info) => ({
    type: DELETE_ADMIN_EXPENSE,
    payload: info,
  });
  export const deleteAdminExpenseSuccess = (info) => ({
    type: DELETE_ADMIN_EXPENSE_SUCCESS,
    payload: info,
  });
  export const deleteAdminExpenseError = (error) => ({
    type: DELETE_ADMIN_EXPENSE_ERROR,
    payload: error,
  });