import { getAdminDasboardCompanyFilterAction } from "../../../redux/admin/dashboard/action";
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useDispatch, useSelector } from "react-redux";

// Example data structure

export const options = {
  region: "IN", // Focus on India
  resolution: "provinces", // Show states
  colorAxis: { colors: ["#39e75f", "#00796b"] }, // Customize the color range
  backgroundColor: "#81d4fa",
  datalessRegionColor: "#f5f5f5",
  defaultColor: "#f5f5f5",
};

export function StateMapChart() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const {
    getAdminDasboardCompanyFilter,
    getAdminDasboardCompanyFilterLoading,
    getAdminDasboardCompanyFilterError,
  } = useSelector((state) => state.AdminDashboardSection);
  useEffect(() => {
    dispatch(getAdminDasboardCompanyFilterAction(""));
  }, []);

  useEffect(() => {
    if (getAdminDasboardCompanyFilter?.status === 200) {
      const chartData = [["State", "Companies Added"]];

      getAdminDasboardCompanyFilter.data.forEach((item) => {
        chartData.push([item.state, item.companiesAdded]);
      });
      setData(chartData);
    }
  }, [getAdminDasboardCompanyFilter]);
  return (
    <div className=" bg-white col-span-2 h-full ">
      <div className="w-full h-full mx-auto  rounded-lg">
        <Chart chartType="GeoChart" data={data} options={options} className="h-full" graphID="state" />
      </div>
    </div>
  );
}
