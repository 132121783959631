const {
  GET_COMPANY_FUEL,
  GET_COMPANY_FUEL_SUCCESS,
  GET_COMPANY_FUEL_ERROR,
  GET_COMPANY_FUEL_ID,
  GET_COMPANY_FUEL_ID_SUCCESS,
  GET_COMPANY_FUEL_ID_ERROR,
  POST_COMPANY_FUEL,
  POST_COMPANY_FUEL_SUCCESS,
  POST_COMPANY_FUEL_ERROR,
  PATCH_COMPANY_FUEL,
  PATCH_COMPANY_FUEL_SUCCESS,
  PATCH_COMPANY_FUEL_ERROR,
  DELETE_COMPANY_FUEL,
  DELETE_COMPANY_FUEL_SUCCESS,
  DELETE_COMPANY_FUEL_ERROR,
  GET_COMPANY_FUEL_FILTER,
  GET_COMPANY_FUEL_FILTER_SUCCESS,
  GET_COMPANY_FUEL_FILTER_ERROR,
  GET_COMPANY_FUEL_NAME_LIST,
  GET_COMPANY_FUEL_NAME_LIST_SUCCESS,
  GET_COMPANY_FUEL_NAME_LIST_ERROR,
} = require("../../actions");

const INIT_STATE = {
  getCompanyFuel: {},
  getCompanyFuelLoading: false,
  getCompanyFuelError: "",
  getCompanyFuelId: {},
  getCompanyFuelIdLoading: false,
  getCompanyFuelIdError: "",
  getCompanyFuelFilter: {},
  getCompanyFuelFilterLoading: false,
  getCompanyFuelFilterError: "",
  getCompanyFuelNameList: {},
  getCompanyFuelNameListLoading: false,
  getCompanyFuelNameListError: "",
  postCompanyFuel: {},
  postCompanyFuelLoading: false,
  postCompanyFuelError: "",
  patchCompanyFuel: {},
  patchCompanyFuelLoading: false,
  patchCompanyFuelError: "",
  deleteCompanyFuel: {},
  deleteCompanyFuelLoading: false,
  deleteCompanyFuelError: "",
};

const Fuelsection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_FUEL:
      return {
        ...state,
        getCompanyFuel: {},
        getCompanyFuelLoading: true,
      };
    case GET_COMPANY_FUEL_SUCCESS:
      return {
        ...state,
        getCompanyFuel: action.payload,
        getCompanyFuelLoading: false,
        getCompanyFuelError: "",
      };

    case GET_COMPANY_FUEL_ERROR:
      return {
        ...state,
        getCompanyFuelLoading: false,
        getCompanyFuelError: action.payload,
      };
    case GET_COMPANY_FUEL_ID:
      return {
        ...state,
        getCompanyFuelId: {},
        getCompanyFuelIdLoading: true,
      };
    case GET_COMPANY_FUEL_ID_SUCCESS:
      return {
        ...state,
        getCompanyFuelId: action.payload,
        getCompanyFuelIdLoading: false,
        getCompanyFuelIdError: "",
      };

    case GET_COMPANY_FUEL_ID_ERROR:
      return {
        ...state,
        getCompanyFuelIdLoading: false,
        getCompanyFuelIdError: action.payload,
      };
      case GET_COMPANY_FUEL_FILTER:
      return {
        ...state,
        getCompanyFuelFilter: {},
        getCompanyFuelFilterLoading: true,
      };
    case GET_COMPANY_FUEL_FILTER_SUCCESS:
      return {
        ...state,
        getCompanyFuelFilter: action.payload,
        getCompanyFuelFilterLoading: false,
        getCompanyFuelFilterError: "",
      };

    case GET_COMPANY_FUEL_FILTER_ERROR:
      return {
        ...state,
        getCompanyFuelFilterLoading: false,
        getCompanyFuelFilterError: action.payload,
      };
      case GET_COMPANY_FUEL_NAME_LIST:
      return {
        ...state,
        getCompanyFuelNameList: {},
        getCompanyFuelNameListLoading: true,
      };
    case GET_COMPANY_FUEL_NAME_LIST_SUCCESS:
      return {
        ...state,
        getCompanyFuelNameList: action.payload,
        getCompanyFuelNameListLoading: false,
        getCompanyFuelNameListError: "",
      };

    case GET_COMPANY_FUEL_NAME_LIST_ERROR:
      return {
        ...state,
        getCompanyFuelNameListLoading: false,
        getCompanyFuelNameListError: action.payload,
      };
      case POST_COMPANY_FUEL:
      return {
        ...state,
        postCompanyFuel: {},
        postCompanyFuelLoading: true,
      };
    case POST_COMPANY_FUEL_SUCCESS:
      return {
        ...state,
        postCompanyFuel: action.payload,
        postCompanyFuelLoading: false,
        postCompanyFuelError: "",
      };

    case POST_COMPANY_FUEL_ERROR:
      return {
        ...state,
        postCompanyFuelLoading: false,
        postCompanyFuelError: action.payload,
      };
    case PATCH_COMPANY_FUEL:
      return {
        ...state,
        patchCompanyFuel: {},
        patchCompanyFuelLoading: true,
      };
    case PATCH_COMPANY_FUEL_SUCCESS:
      return {
        ...state,
        patchCompanyFuel: action.payload,
        patchCompanyFuelLoading: false,
        patchCompanyFuelError: "",
      };

    case PATCH_COMPANY_FUEL_ERROR:
      return {
        ...state,
        patchCompanyFuelLoading: false,
        patchCompanyFuelError: action.payload,
      };
    case DELETE_COMPANY_FUEL:
      return {
        ...state,
        deleteCompanyFuel: {},
        deleteCompanyFuelLoading: true,
      };
    case DELETE_COMPANY_FUEL_SUCCESS:
      return {
        ...state,
        deleteCompanyFuel: action.payload,
        deleteCompanyFuelLoading: false,
        deleteCompanyFuelError: "",
      };

    case DELETE_COMPANY_FUEL_ERROR:
      return {
        ...state,
        deleteCompanyFuelLoading: false,
        deleteCompanyFuelError: action.payload,
      };
    default:
      return { ...state };
  }
};

export default Fuelsection;
