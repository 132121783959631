import { getSalaryByUserAction } from "../../../../redux/company/employee/salary/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import EmployeeSalaryCard from "./employeeSalaryCard";
import Backdrop from "../../../../components/backdrop";
import { getCompanyEmployeeDetailsAction } from "../../../../redux/company/employee/action";
import toast from "react-hot-toast";
import { Dialog } from "@radix-ui/react-dialog";
import UpdateSalary from "./updateSalary";

function EmployeeSalaryList() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { getSalaryByUser, getSalaryByUserLoading, getSalaryByUserError } =
    useSelector((state) => state.SalarySection);
  const id = searchParams.get("employeeId");
  const {
    getCompanyEmployeeDetails,
    getCompanyEmployeeDetailsLoading,
    getCompanyEmployeeDetailsError,
  } = useSelector((state) => state.CompanyEmployeesection);
  const [payButton, setPayButton] = useState(true);
  const [datas, setData] = useState([]);
  const [showSalaryUpdateModal, setShowSalaryUpdateModal] = useState(false);
  useEffect(() => {
    dispatch(getSalaryByUserAction(id));
    const payload = {
      id: id,
    };
    dispatch(getCompanyEmployeeDetailsAction(payload));
  }, []);

  useEffect(() => {
    if (getSalaryByUser?.status == 200) {
      if (getSalaryByUser?.data?.length > 0) {
        const salaryData = getSalaryByUser?.data?.sort(
          (a, b) => new Date(b.salaryMonth) - new Date(a.salaryMonth)
        );
        setData(salaryData);
      }
    }
  }, [getSalaryByUser]);
  useEffect(() => {
    if (getCompanyEmployeeDetails.status === 200) {
      const working = getCompanyEmployeeDetails.data[0].isWorking;
      setPayButton(working);
    }
  }, [getCompanyEmployeeDetails]);
  useEffect(() => {
    if (
      getCompanyEmployeeDetailsError.status === 400 ||
      getCompanyEmployeeDetailsError.status === 404
    ) {
      toast.error(getCompanyEmployeeDetailsError.message);
    } else if (getCompanyEmployeeDetailsError.status === 500) {
      toast.error(getCompanyEmployeeDetailsError.error);
    }
  }, [getCompanyEmployeeDetailsError]);

  return (
    <div>
      {getSalaryByUserLoading && <Backdrop />}
      {searchParams.get("isWorking") === "true" && <div className="flex justify-end gap-5">
        {payButton && (
          <Link
            to={`addSalary?employeeId=${searchParams.get("employeeId")}`}
            className="primaryButton text-sm w-fit bg-green-600 hover:bg-green-800 border-green-600 hover:border-green-800"
          >
            Pay Salary
          </Link>
        )}
        {payButton && (
          <Dialog open={showSalaryUpdateModal}>
            <button
              className="primaryButton text-sm w-fit"
              onClick={() => setShowSalaryUpdateModal(true)}
            >
              Update Salary Amount
            </button>
            <UpdateSalary data={getCompanyEmployeeDetails?.status && getCompanyEmployeeDetails?.data?.length >0 ?getCompanyEmployeeDetails?.data[0]: null} setShowModal={setShowSalaryUpdateModal} />
          </Dialog>
        )}
      </div>}
      {datas?.map((data) => (
        <EmployeeSalaryCard data={data} payButton={payButton} />
      ))}
    </div>
  );
}

export default EmployeeSalaryList;
