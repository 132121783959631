import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import api from "../../../../auth/api";
import {
  GET_DEF,
  GET_DEF_BY_TRUCK,
  GET_DEF_BY_ID,
  POST_DEF,
  PATCH_DEF,
  DELETE_DEF,
  GET_DEF_FILTER,
} from "../../../actions";
import {
  getDEFSuccess,
  getDEFError,
  getDEFByTruckSuccess,
  getDEFByTruckError,
  getDEFByIdSuccess,
  getDEFByIdError,
  postDEFSuccess,
  postDEFError,
  patchDEFSuccess,
  patchDEFError,
  deleteDEFSuccess,
  deleteDEFError,
  getDEFFilterSuccess,
  getDEFFilterError,
} from "./action";

// Async function to handle API call
const getDEFAsync = async () => {
  try {
    const response = await api.get("/company/def/getAll");
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle GET_DEF action
function* getDEFCall() {
  try {
    const data = yield call(getDEFAsync); // Call async function without action payload
    yield put(getDEFSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getDEFError(error)); // Dispatch error action with error object
  }
}

export function* watchGetDEFData() {
  yield takeEvery(GET_DEF, getDEFCall);
}

// Async function to handle API call
const getDEFByTruckAsync = async (payload) => {
  try {
    const response = await api.get(`/company/def/get/${payload.payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle GET_DEF_BY_TRUCK action
function* getDEFByTruckCall(action) {
  try {
    const data = yield call(getDEFByTruckAsync, action); // Call async function with action payload
    yield put(getDEFByTruckSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getDEFByTruckError(error)); // Dispatch error action with error object
  }
}

export function* watchGetDEFByTruckData() {
  yield takeEvery(GET_DEF_BY_TRUCK, getDEFByTruckCall);
}

// Async function to handle API call
const getDEFByIdAsync = async (payload) => {
  try {
    const response = await api.get(`/company/def/get/${payload.payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle GET_DEF_BY_ID action
function* getDEFByIdCall(action) {
  try {
    const data = yield call(getDEFByIdAsync, action); // Call async function with action payload
    yield put(getDEFByIdSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getDEFByIdError(error)); // Dispatch error action with error object
  }
}

export function* watchGetDEFByIdData() {
  yield takeEvery(GET_DEF_BY_ID, getDEFByIdCall);
}

// Async function to handle API call
const postDEFAsync = async (payload) => {
  try {
    const response = await api.post("/company/def/add", payload.payload);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_DEF action
function* postDEFCall(action) {
  try {
    const data = yield call(postDEFAsync, action); // Call async function with action payload
    yield put(postDEFSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postDEFError(error)); // Dispatch error action with error object
  }
}

export function* watchPostDEFData() {
  yield takeEvery(POST_DEF, postDEFCall);
}

// Async function to handle API call
const patchDEFAsync = async (payload) => {
  try {
    const response = await api.patch(`/company/def/update/${payload.payload.id}`, payload.payload.body);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle PATCH_DEF action
function* patchDEFCall(action) {
  try {
    const data = yield call(patchDEFAsync, action); // Call async function with action payload
    yield put(patchDEFSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(patchDEFError(error)); // Dispatch error action with error object
  }
}

export function* watchPatchDEFData() {
  yield takeEvery(PATCH_DEF, patchDEFCall);
}

// Async function to handle API call
const deleteDEFAsync = async (payload) => {
  try {
    const response = await api.delete(`/company/def/${payload.payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle DELETE_DEF action
function* deleteDEFCall(action) {
  try {
    const data = yield call(deleteDEFAsync, action); // Call async function with action payload
    yield put(deleteDEFSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(deleteDEFError(error)); // Dispatch error action with error object
  }
}

export function* watchDeleteDEFData() {
  yield takeEvery(DELETE_DEF, deleteDEFCall);
}

// Async function to handle API call
const getDEFFilterAsync = async (payload) => {
  try {
    const response = await api.post("/company/def/filter", payload.payload);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle GET_DEF_FILTER action
function* getDEFFilterCall(action) {
  try {
    const data = yield call(getDEFFilterAsync, action); // Call async function with action payload
    yield put(getDEFFilterSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getDEFFilterError(error)); // Dispatch error action with error object
  }
}

export function* watchFilterDEFData() {
  yield takeEvery(GET_DEF_FILTER, getDEFFilterCall);
}

// Root Saga
export default function* defSaga() {
  yield all([
    fork(watchGetDEFData),
    fork(watchGetDEFByTruckData),
    fork(watchGetDEFByIdData),
    fork(watchPostDEFData),
    fork(watchPatchDEFData),
    fork(watchDeleteDEFData),
    fork(watchFilterDEFData),
  ]);
}
