import moment from "moment";
import React from "react";
import Image from "../../../../assets/images/bill.png"

function PermitListCard({data}) {
  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 2,
        }).format(amount)
      : "₹0.00";
  return (
    <div key={data?._id}>
      <div className="grid grid-cols-12 gap-x-7 my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl">
        <div className="lg:col-span-2 col-span-6 row-span-1 justify-self-center my-auto lg:row-span-3">
          <button type="button" className="">
            <img
              src={data?.permitImage?.length > 0 ? "https://res.cloudinary.com/dsn0wxtly/image/upload/v1725568165/pglt5s5fm2m1oi7lr7ek.png" : Image}
              alt=""
              className={` ${
                data?.permitImage?.length > 0 ? "" : ""
              } h-[100px] w-[100px]`}
            />
          </button>
        </div>

        <div className="mb-5 text-center md:text-left lg:col-span-10 col-span-6 row-span-1 self-center lg:self-auto">
          <div className="flex justify-between">
            <div className="">
              <button
                type="button"
                className="font-bold text-blue-600 text-xl uppercase"
                //   onClick={reroute ? () => navigate(`details/${data._id}`) : null}
              >
                {data?.permitNumber}
              </button>
              <p className="textgrey text-base">
                {moment(data?.permitTill).format("DD / MMM / YYYY")}
              </p>
            </div>
            <div className="my-auto font-black text-xl">
              {formatAmount(data?.permitCost)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PermitListCard;
