const {
  POST_COMPANY_DETAILS,
  POST_COMPANY_DETAILS_SUCCESS,
  POST_COMPANY_DETAILS_ERROR,
  GET_STATE,
  GET_STATE_SUCCESS,
  GET_STATE_ERROR,
  GET_CITY,
  GET_CITY_SUCCESS,
  GET_CITY_ERROR,
} = require("../actions");

const INIT_STATE = {
  getState: {},
  getStateLoading: false,
  getStateError: "",
  getCity: {},
  getCityLoading: false,
  getCityError: "",
};

const AddressSection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STATE:
      return {
        ...state,
        getState: {},
        getStateLoading: true,
      };
    case GET_STATE_SUCCESS:
      return {
        ...state,
        getState: action.payload,
        getStateLoading: false,
        getStateError: "",
      };

    case GET_STATE_ERROR:
      return {
        ...state,
        getStateLoading: false,
        getStateError: action.payload.message,
      };
    case GET_CITY:
      return {
        ...state,
        getCity: {},
        getCityLoading: true,
      };
    case GET_CITY_SUCCESS:
      return {
        ...state,
        getCity: action.payload,
        getCityLoading: false,
        getCityError: "",
      };

    case GET_CITY_ERROR:
      return {
        ...state,
        getCityLoading: false,
        getCityError: action.payload.message,
      };
    default:
      return { ...state };
  }
};

export default AddressSection;
