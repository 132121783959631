import { GET_COMPANY_STATS, GET_COMPANY_STATS_ERROR, GET_COMPANY_STATS_SUCCESS, GET_DEF_STATS, GET_DEF_STATS_ERROR, GET_DEF_STATS_SUCCESS, GET_EMI_STATS, GET_EMI_STATS_ERROR, GET_EMI_STATS_SUCCESS, GET_EXTRA_EXPENSE_STATS, GET_EXTRA_EXPENSE_STATS_ERROR, GET_EXTRA_EXPENSE_STATS_SUCCESS, GET_FITNESS_STATS, GET_FITNESS_STATS_ERROR, GET_FITNESS_STATS_SUCCESS, GET_FUEL_STATS, GET_FUEL_STATS_ERROR, GET_FUEL_STATS_SUCCESS, GET_INSURANCE_STATS, GET_INSURANCE_STATS_ERROR, GET_INSURANCE_STATS_SUCCESS, GET_PERMIT_STATS, GET_PERMIT_STATS_ERROR, GET_PERMIT_STATS_SUCCESS, GET_SALARY_STATS, GET_SALARY_STATS_ERROR, GET_SALARY_STATS_SUCCESS, GET_SERVICE_STATS, GET_SERVICE_STATS_ERROR, GET_SERVICE_STATS_SUCCESS, GET_TOLL_STATS, GET_TOLL_STATS_ERROR, GET_TOLL_STATS_SUCCESS, GET_TRIP_BY_TRIPID_STATS, GET_TRIP_BY_TRIPID_STATS_ERROR, GET_TRIP_BY_TRIPID_STATS_SUCCESS, GET_TRIP_STATS, GET_TRIP_STATS_ERROR, GET_TRIP_STATS_SUCCESS, GET_TRUCK_BY_TRUCKID_STATS, GET_TRUCK_BY_TRUCKID_STATS_ERROR, GET_TRUCK_BY_TRUCKID_STATS_SUCCESS, GET_TRUCK_STATS, GET_TRUCK_STATS_ERROR, GET_TRUCK_STATS_SUCCESS } from "../../actions";

export const getCompanyStatsAction = (info) => ({
    type: GET_COMPANY_STATS,
    payload: info,
  });
  export const getCompanyStatsSuccess = (info) => ({
    type: GET_COMPANY_STATS_SUCCESS,
    payload: info,
  });
  export const getCompanyStatsError = (error) => ({
    type: GET_COMPANY_STATS_ERROR,
    payload: error,
  });
  
  // Truck Stats
  export const getTruckStatsAction = (info) => ({
    type: GET_TRUCK_STATS,
    payload: info,
  });
  export const getTruckStatsSuccess = (info) => ({
    type: GET_TRUCK_STATS_SUCCESS,
    payload: info,
  });
  export const getTruckStatsError = (error) => ({
    type: GET_TRUCK_STATS_ERROR,
    payload: error,
  });
  
  // Truck by Truck ID Stats
  export const getTruckByTruckIdStatsAction = (info) => ({
    type: GET_TRUCK_BY_TRUCKID_STATS,
    payload: info,
  });
  export const getTruckByTruckIdStatsSuccess = (info) => ({
    type: GET_TRUCK_BY_TRUCKID_STATS_SUCCESS,
    payload: info,
  });
  export const getTruckByTruckIdStatsError = (error) => ({
    type: GET_TRUCK_BY_TRUCKID_STATS_ERROR,
    payload: error,
  });
  
  // Trip Stats
  export const getTripStatsAction = (info) => ({
    type: GET_TRIP_STATS,
    payload: info,
  });
  export const getTripStatsSuccess = (info) => ({
    type: GET_TRIP_STATS_SUCCESS,
    payload: info,
  });
  export const getTripStatsError = (error) => ({
    type: GET_TRIP_STATS_ERROR,
    payload: error,
  });
  
  // Trip by Truck ID Stats
  export const getTripByTripIdStatsAction = (info) => ({
    type: GET_TRIP_BY_TRIPID_STATS,
    payload: info,
  });
  export const getTripByTripIdStatsSuccess = (info) => ({
    type: GET_TRIP_BY_TRIPID_STATS_SUCCESS,
    payload: info,
  });
  export const getTripByTripIdStatsError = (error) => ({
    type: GET_TRIP_BY_TRIPID_STATS_ERROR,
    payload: error,
  });
  
  // Fuel Stats
  export const getInsuranceStatsAction = (info) => ({
    type: GET_INSURANCE_STATS,
    payload: info,
  });
  export const getInsuranceStatsSuccess = (info) => ({
    type: GET_INSURANCE_STATS_SUCCESS,
    payload: info,
  });
  export const getInsuranceStatsError = (error) => ({
    type: GET_INSURANCE_STATS_ERROR,
    payload: error,
  });
  
  // Fuel Stats
  export const getFitnessStatsAction = (info) => ({
    type: GET_FITNESS_STATS,
    payload: info,
  });
  export const getFitnessStatsSuccess = (info) => ({
    type: GET_FITNESS_STATS_SUCCESS,
    payload: info,
  });
  export const getFitnessStatsError = (error) => ({
    type: GET_FITNESS_STATS_ERROR,
    payload: error,
  });
  
  // Fuel Stats
  export const getPermitStatsAction = (info) => ({
    type: GET_PERMIT_STATS,
    payload: info,
  });
  export const getPermitStatsSuccess = (info) => ({
    type: GET_PERMIT_STATS_SUCCESS,
    payload: info,
  });
  export const getPermitStatsError = (error) => ({
    type: GET_PERMIT_STATS_ERROR,
    payload: error,
  });
  
  // Fuel Stats
  export const getFuelStatsAction = (info) => ({
    type: GET_FUEL_STATS,
    payload: info,
  });
  export const getFuelStatsSuccess = (info) => ({
    type: GET_FUEL_STATS_SUCCESS,
    payload: info,
  });
  export const getFuelStatsError = (error) => ({
    type: GET_FUEL_STATS_ERROR,
    payload: error,
  });
  
  // Toll Stats
  export const getTollStatsAction = (info) => ({
    type: GET_TOLL_STATS,
    payload: info,
  });
  export const getTollStatsSuccess = (info) => ({
    type: GET_TOLL_STATS_SUCCESS,
    payload: info,
  });
  export const getTollStatsError = (error) => ({
    type: GET_TOLL_STATS_ERROR,
    payload: error,
  });
  
  // Extra Expense Stats
  export const getExtraExpenseStatsAction = (info) => ({
    type: GET_EXTRA_EXPENSE_STATS,
    payload: info,
  });
  export const getExtraExpenseStatsSuccess = (info) => ({
    type: GET_EXTRA_EXPENSE_STATS_SUCCESS,
    payload: info,
  });
  export const getExtraExpenseStatsError = (error) => ({
    type: GET_EXTRA_EXPENSE_STATS_ERROR,
    payload: error,
  });
  
  // Service Stats
  export const getServiceStatsAction = (info) => ({
    type: GET_SERVICE_STATS,
    payload: info,
  });
  export const getServiceStatsSuccess = (info) => ({
    type: GET_SERVICE_STATS_SUCCESS,
    payload: info,
  });
  export const getServiceStatsError = (error) => ({
    type: GET_SERVICE_STATS_ERROR,
    payload: error,
  });
  
  // DEF Stats
  export const getDefStatsAction = (info) => ({
    type: GET_DEF_STATS,
    payload: info,
  });
  export const getDefStatsSuccess = (info) => ({
    type: GET_DEF_STATS_SUCCESS,
    payload: info,
  });
  export const getDefStatsError = (error) => ({
    type: GET_DEF_STATS_ERROR,
    payload: error,
  });
  
  // EMI Stats
  export const getEmiStatsAction = (info) => ({
    type: GET_EMI_STATS,
    payload: info,
  });
  export const getEmiStatsSuccess = (info) => ({
    type: GET_EMI_STATS_SUCCESS,
    payload: info,
  });
  export const getEmiStatsError = (error) => ({
    type: GET_EMI_STATS_ERROR,
    payload: error,
  });
  
  // Salary Stats
  export const getSalaryStatsAction = (info) => ({
    type: GET_SALARY_STATS,
    payload: info,
  });
  export const getSalaryStatsSuccess = (info) => ({
    type: GET_SALARY_STATS_SUCCESS,
    payload: info,
  });
  export const getSalaryStatsError = (error) => ({
    type: GET_SALARY_STATS_ERROR,
    payload: error,
  });