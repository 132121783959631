import {
  GET_SUBSCRIPTION_DISCOUNT,
  GET_SUBSCRIPTION_DISCOUNT_ERROR,
  GET_SUBSCRIPTION_DISCOUNT_SUCCESS,
  GET_SUBSCRIPTION_KEY,
  GET_SUBSCRIPTION_KEY_ERROR,
  GET_SUBSCRIPTION_KEY_SUCCESS,
  GET_SUBSCRIPTION_LIST,
  GET_SUBSCRIPTION_LIST_ERROR,
  GET_SUBSCRIPTION_LIST_SUCCESS,
  POST_SUBSCRIPTION_CREATE_ORDER,
  POST_SUBSCRIPTION_CREATE_ORDER_ERROR,
  POST_SUBSCRIPTION_CREATE_ORDER_SUCCESS,
  POST_SUBSCRIPTION_PAYMENT_VERIFY,
  POST_SUBSCRIPTION_PAYMENT_VERIFY_ERROR,
  POST_SUBSCRIPTION_PAYMENT_VERIFY_SUCCESS,
} from "../../actions";

export const getSubscriptionListAction = (info) => ({
  type: GET_SUBSCRIPTION_LIST,
  payload: info,
});
export const getSubscriptionListSuccess = (info) => ({
  type: GET_SUBSCRIPTION_LIST_SUCCESS,
  payload: info,
});
export const getSubscriptionListError = (error) => ({
  type: GET_SUBSCRIPTION_LIST_ERROR,
  payload: error,
});
export const getSubscriptionDiscountAction = (info) => ({
  type: GET_SUBSCRIPTION_DISCOUNT,
  payload: info,
});
export const getSubscriptionDiscountSuccess = (info) => ({
  type: GET_SUBSCRIPTION_DISCOUNT_SUCCESS,
  payload: info,
});
export const getSubscriptionDiscountError = (error) => ({
  type: GET_SUBSCRIPTION_DISCOUNT_ERROR,
  payload: error,
});
export const getSubscriptionKeyAction = (info) => ({
  type: GET_SUBSCRIPTION_KEY,
  payload: info,
});
export const getSubscriptionKeySuccess = (info) => ({
  type: GET_SUBSCRIPTION_KEY_SUCCESS,
  payload: info,
});
export const getSubscriptionKeyError = (error) => ({
  type: GET_SUBSCRIPTION_KEY_ERROR,
  payload: error,
});
export const postSubscriptionCreateOrderAction = (info) => ({
  type: POST_SUBSCRIPTION_CREATE_ORDER,
  payload: info,
});
export const postSubscriptionCreateOrderSuccess = (info) => ({
  type: POST_SUBSCRIPTION_CREATE_ORDER_SUCCESS,
  payload: info,
});
export const postSubscriptionCreateOrderError = (error) => ({
  type: POST_SUBSCRIPTION_CREATE_ORDER_ERROR,
  payload: error,
});
export const postSubscriptionPaymentVerificationAction = (info) => ({
  type: POST_SUBSCRIPTION_PAYMENT_VERIFY,
  payload: info,
});
export const postSubscriptionPaymentVerificationSuccess = (info) => ({
  type: POST_SUBSCRIPTION_PAYMENT_VERIFY_SUCCESS,
  payload: info,
});
export const postSubscriptionPaymentVerificationError = (error) => ({
  type: POST_SUBSCRIPTION_PAYMENT_VERIFY_ERROR,
  payload: error,
});
