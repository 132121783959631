import React, { useEffect, useState } from "react";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { getCompanyEmployeeDetailsAction, getEmployeeExpireLicenseAction, postCompanyEmployeeLicenseDetailsAction } from "../../../redux/company/employee/action";
import FileMultiUpload from "../../fileupload/fileMuiltipleUpload";
import DatePicker from "../../datepicker/datePicker";

function LicenseUpdateModal({id, setShowModal}) {
  const dispatch = useDispatch()
  const initialValues = {
    employeeId: id,
    licenseNumber:  "",
    expiryDate: "",
    licenseImageUrl: [],
  };
  const validationSchema = Yup.object({
   
  });
  const {
    postCompanyEmployeeLicenseDetails,
    postCompanyEmployeeLicenseDetailsLoading,
    postCompanyEmployeeLicenseDetailsError,
  } = useSelector((state) => state.CompanyEmployeesection);

  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (loader) {
      if (postCompanyEmployeeLicenseDetails?.status === 200) {
        toast.success(postCompanyEmployeeLicenseDetails.message, {
          id: "terminate-toast",
        });
        setLoader(false);

        dispatch(getCompanyEmployeeDetailsAction());
        dispatch(getEmployeeExpireLicenseAction());
        setShowModal(false);
      }
    }
  }, [postCompanyEmployeeLicenseDetails]);
  useEffect(() => {
    if (loader) {
      if (
        postCompanyEmployeeLicenseDetailsError?.status === 400 ||
        postCompanyEmployeeLicenseDetailsError?.status === 404
      ) {
        toast.error(postCompanyEmployeeLicenseDetailsError.message, {
          id: "terminate-toast",
        });
        setLoader(false);
      } else if (postCompanyEmployeeLicenseDetailsError?.status === 500) {
        toast.error(postCompanyEmployeeLicenseDetailsError?.error, {
          id: "terminate-toast",
        });
        setLoader(false);
      }
    }
  }, [postCompanyEmployeeLicenseDetailsError]);

  const onSubmit = (values) => {

    dispatch(postCompanyEmployeeLicenseDetailsAction(values))
    setLoader(true);
    toast.loading("Employee License details Saving....", {
      id: "postDetails",
    });
  }
  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle>Update Employee License</DialogTitle>
      </DialogHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="">
            <div className="grid grid-cols-2 gap-4 overflow-y-auto max-h-[60vh] py-4 ">
            
            
              <div>
                <label htmlFor="licenseNumber">
                  License Number <span>*</span>
                </label>
                <Field
                  type="text"
                  id="licenseNumber"
                  name="licenseNumber"
                  className="w-full px-4 py-2 border rounded-lg"
                />
                <ErrorMessage
                  name="licenseNumber"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="expiryDate">
                  License Expiry Date <span>*</span>
                </label>
                <DatePicker
                  id="expiryDate"
                  name="expiryDate"
                  placeHolder="License Expiry Date"
                  minDate={new Date()}
                />
                <ErrorMessage
                  name="expiryDate"
                  component="div"
                  className="error"
                />
              </div>
              <div className="col-span-3">
                <label htmlFor="licenseImageUrl">License Image</label>
                <FileMultiUpload
                  setFieldValue={setFieldValue}
                  name="licenseImageUrl"
                  values={values.licenseImageUrl}
                />
              </div>
            </div>
            <DialogFooter>
              <button
                className="ghostButton"
                onClick={() => setShowModal(false)}
                disabled={postCompanyEmployeeLicenseDetailsLoading}
                type="button"
              >
                Close
              </button>
              <button
                className="primaryButton  w-fit"
                type="submit"
                disabled={postCompanyEmployeeLicenseDetailsLoading}
              >
                Update License
              </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default LicenseUpdateModal;
