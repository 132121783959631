import { GET_SUBSCRIPTION_DISCOUNT, GET_SUBSCRIPTION_DISCOUNT_ERROR, GET_SUBSCRIPTION_DISCOUNT_SUCCESS, GET_SUBSCRIPTION_KEY, GET_SUBSCRIPTION_KEY_ERROR, GET_SUBSCRIPTION_KEY_SUCCESS, GET_SUBSCRIPTION_LIST, GET_SUBSCRIPTION_LIST_ERROR, GET_SUBSCRIPTION_LIST_SUCCESS, POST_SUBSCRIPTION_CREATE_ORDER, POST_SUBSCRIPTION_CREATE_ORDER_ERROR, POST_SUBSCRIPTION_CREATE_ORDER_SUCCESS, POST_SUBSCRIPTION_PAYMENT_VERIFY, POST_SUBSCRIPTION_PAYMENT_VERIFY_ERROR, POST_SUBSCRIPTION_PAYMENT_VERIFY_SUCCESS } from "../../actions";

const INIT_STATE = {
  getSubscriptionList: {},
  getSubscriptionListLoading: false,
  getSubscriptionListError: "",
  getSubscriptionDiscount: {},
  getSubscriptionDiscountLoading: false,
  getSubscriptionDiscountError: "",
  getSubscriptionKey: {},
  getSubscriptionKeyLoading: false,
  getSubscriptionKeyError: "",
  postSubscriptionCreateOrder: {},
  postSubscriptionCreateOrderLoading: false,
  postSubscriptionCreateOrderError: "",
  postSubscriptionPaymentVerification: {},
  postSubscriptionPaymentVerificationLoading: false,
  postSubscriptionPaymentVerificationError: "",
};

const SubscriptionSection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_LIST:
      return {
        ...state,
        getSubscriptionList: {},
        getSubscriptionListLoading: true,
      };
    case GET_SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        getSubscriptionList: action.payload,
        getSubscriptionListLoading: false,
        getSubscriptionListError: "",
      };

    case GET_SUBSCRIPTION_LIST_ERROR:
      return {
        ...state,
        getSubscriptionListLoading: false,
        getSubscriptionListError: action.payload,
      };
    case GET_SUBSCRIPTION_DISCOUNT:
      return {
        ...state,
        getSubscriptionDiscount: {},
        getSubscriptionDiscountLoading: true,
      };
    case GET_SUBSCRIPTION_DISCOUNT_SUCCESS:
      return {
        ...state,
        getSubscriptionDiscount: action.payload,
        getSubscriptionDiscountLoading: false,
        getSubscriptionDiscountError: "",
      };

    case GET_SUBSCRIPTION_DISCOUNT_ERROR:
      return {
        ...state,
        getSubscriptionDiscountLoading: false,
        getSubscriptionDiscountError: action.payload,
      };
    case GET_SUBSCRIPTION_KEY:
      return {
        ...state,
        getSubscriptionKey: {},
        getSubscriptionKeyLoading: true,
      };
    case GET_SUBSCRIPTION_KEY_SUCCESS:
      return {
        ...state,
        getSubscriptionKey: action.payload,
        getSubscriptionKeyLoading: false,
        getSubscriptionKeyError: "",
      };

    case GET_SUBSCRIPTION_KEY_ERROR:
      return {
        ...state,
        getSubscriptionKeyLoading: false,
        getSubscriptionKeyError: action.payload,
      };
    case POST_SUBSCRIPTION_CREATE_ORDER:
      return {
        ...state,
        postSubscriptionCreateOrder: {},
        postSubscriptionCreateOrderLoading: true,
      };
    case POST_SUBSCRIPTION_CREATE_ORDER_SUCCESS:
      return {
        ...state,
        postSubscriptionCreateOrder: action.payload,
        postSubscriptionCreateOrderLoading: false,
        postSubscriptionCreateOrderError: "",
      };

    case POST_SUBSCRIPTION_CREATE_ORDER_ERROR:
      return {
        ...state,
        postSubscriptionCreateOrderLoading: false,
        postSubscriptionCreateOrderError: action.payload,
      };
    case POST_SUBSCRIPTION_PAYMENT_VERIFY:
      return {
        ...state,
        postSubscriptionPaymentVerification: {},
        postSubscriptionPaymentVerificationLoading: true,
      };
    case POST_SUBSCRIPTION_PAYMENT_VERIFY_SUCCESS:
      return {
        ...state,
        postSubscriptionPaymentVerification: action.payload,
        postSubscriptionPaymentVerificationLoading: false,
        postSubscriptionPaymentVerificationError: "",
      };

    case POST_SUBSCRIPTION_PAYMENT_VERIFY_ERROR:
      return {
        ...state,
        postSubscriptionPaymentVerificationLoading: false,
        postSubscriptionPaymentVerificationError: action.payload,
      };
    default:
      return { ...state };
  }
};
export default SubscriptionSection;
