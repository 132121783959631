import React, { useState } from "react";
import Image from "../../../../assets/images/budget.png";
import { Dialog, DialogTrigger } from "../../../../ui/dialog";
import DeleteExpenseTrip from "./extraExpenseTripDelete";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ImagePopUpDownload from "../../../../components/imagePopUpDownload";
import { FcImageFile } from "react-icons/fc";
import { TfiTrash } from "react-icons/tfi";
import { FaRegEdit } from "react-icons/fa";

function ExtraExpenseTripListCard({ data }) {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(amount)
      : "₹0.00";
  return (
    <div
      key={data._id}
      className="bg-white p-4 rounded-md shadow-lg mt-4  w-full text-xs"
    >
      <Dialog open={showDeleteModal}>
        <DeleteExpenseTrip setShowModal={setShowDeleteModal} id={data?._id} />
      </Dialog>
      {data?.billImage?.length > 0 && (
        <Dialog open={showImageModal}>
          <ImagePopUpDownload
            isArray={true}
            image={data?.billImage}
            setShowModal={setShowImageModal}
            imagename={`toll_${data?._id}`}
          />
        </Dialog>
      )}
      <div className=" grid xl:grid-cols-6 lg:grid-cols-4 grid-cols-2   lg:gap-x-20 gap-x-9  lg:gap-y-6  gap-y-4">
        <div className="lg:border-r lg:border-r-[#F1F2F6] lg:border-b-0 border-b border-b-[#F1F2F6] row-span-full flex flex-col gap-2 lg:col-span-1 col-span-2 pb-5 lg:text-left text-center  relative">
          <label htmlFor="" className="relative">
            Expense Name
          </label>
          <p className=" textPrimary uppercase ">
            {data?.expenseName} {data?.billNumber}
          </p>

          <div className="flex md:hidden ml-auto absolute z-30 left-0">
            <button
              type="button"
              onClick={() => setShowDeleteModal(true)}
              className="ghostButton text-[#DC1919] font-semibold  flex gap-1 hover:text-red-700 disabled:text-red-200"
            >
              <TfiTrash className="text-xl" />
            </button>
          </div>
          <div className=" flex flex-col gap-3 md:hidden ml-auto absolute z-30 right-0">
            <button
              type="button"
              className="ghostButton font-semibold  flex ml-auto"
              onClick={() =>
                navigate(`/company/truck/addExtraExpense?id=${data?._id}`)
              }
            >
              <FaRegEdit className="text-xl" />
            </button>
            {data?.billImage?.length > 0 && (
              <div className="">
                <button
                  type="button"
                  onClick={() => setShowImageModal(true)}
                  className="ghostButton font-semibold flex gap-1 items-center md:mx-0 mx-auto"
                >
                  <FcImageFile className="text-2xl" />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="">
          <label htmlFor="">Expense Date</label>
          <p className="textSecondary">
            {moment(data?.expenseDate).format("DD / MMM / YYYY")}
          </p>
        </div>

        <div className="">
          <label htmlFor="">Total Cost</label>
          <p className="textSecondary">{formatAmount(data?.cost)}</p>
        </div>
        <div className="col-span-2">
          <label htmlFor="">Description</label>
          <p className="textSecondary">
            {data?.description ? data?.description : "--"}
          </p>
        </div>
        <div className=" gap-6 flex-wrap lg:flex hidden">
          <div className="  ">
            <button
              type="button"
              className="ghostButton font-semibold  flex gap-1"
              onClick={() => navigate(`/company/truck/addDef?id=${data?._id}`)}
            >
              <FaRegEdit className="text-xl" />
              Edit
            </button>
          </div>
          {data?.billImage?.length > 0 && (
            <div className="">
              <Dialog open={showImageModal}>
                <button
                  type="button"
                  onClick={() => setShowImageModal(true)}
                  className="ghostButton font-semibold flex gap-1"
                >
                  <FcImageFile className="text-2xl" /> Bill
                </button>
              </Dialog>
            </div>
          )}
          <div className="lg:flex hidden">
            <button
              type="button"
              onClick={() => setShowDeleteModal(true)}
              className="ghostButton text-[#DC1919] font-semibold  flex gap-1 hover:text-red-700 disabled:text-red-200"
            >
              <TfiTrash className="text-xl" /> Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExtraExpenseTripListCard;
