import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import TruckExpireCard from './truckExpireCard';

function TruckListExpire({data, section}) {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState("");
  
    const handleSearch = (event) => {
      setSearchQuery(event.target.value);
    };
  
    const filteredData = data?.filter(
      (item) =>
        item.truckNumber.toLowerCase().includes(searchQuery.toLowerCase())
    );
  
  return (
    <div>
      <div className="mb-4">
        <input
          placeholder="Search by Truck Number or Type"
          value={searchQuery}
          onChange={handleSearch}
          className="max-w-sm shadow-md "
        />
      </div>
      {filteredData?.length > 0 ? (
        filteredData?.map((item, index) => <TruckExpireCard data={item} reroute={false} section={section} />)
      ) : (
        <p>No trucks found.</p>
      )}
    </div>
  )
}

export default TruckListExpire
