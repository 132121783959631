import React, { useEffect, useState } from "react";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useParams, useSearchParams } from "react-router-dom";
import DatePicker from "../../datepicker/datePicker";
import {
  getAdminEmployeeByCompanyEmployeeIdAction,
  getAdminEmployeeByEmployeeIdAction,
  getAdminEmployeeListAction,
  getAdminEmployeeListByCompanyIdAction,
  patchAdminEmployeeTerminateAction,
} from "../../../redux/admin/employee/action";

function Terminate({ employeeId, setShowModal }) {
  const dispatch = useDispatch();
  const {
    patchAdminEmployeeTerminate,
    patchAdminEmployeeTerminateLoading,
    patchAdminEmployeeTerminateError,
  } = useSelector((state) => state.AdminEmployeeSection);
  const { id, employee, companyEmployee } = useParams();

  const [loader, setLoader] = useState(false);
  console.log(patchAdminEmployeeTerminate)
  console.log(id === undefined, employee, companyEmployee)
  useEffect(() => {
    if (loader) {
      if (patchAdminEmployeeTerminate?.status === 200) {
        toast.success(patchAdminEmployeeTerminate.message, {
          id: "terminate-toast",
        });
        setLoader(false);
        setShowModal(false);
        if (id !== undefined) {
          dispatch(getAdminEmployeeListByCompanyIdAction(id));
          setShowModal(false);
        }
        if (employee !== undefined) {
          dispatch(getAdminEmployeeByEmployeeIdAction(employee));
          setShowModal(false);
        }
        if (companyEmployee !== undefined) {
          dispatch(getAdminEmployeeByCompanyEmployeeIdAction(companyEmployee));
          setShowModal(false);
        }
        if(id === undefined && employee === undefined && companyEmployee === undefined){
          dispatch(getAdminEmployeeListAction())
        }
      }
    }
  }, [patchAdminEmployeeTerminate]);
  useEffect(() => {
    if (loader) {
      if (
        patchAdminEmployeeTerminateError?.status === 400 ||
        patchAdminEmployeeTerminateError?.status === 404
      ) {
        toast.error(patchAdminEmployeeTerminateError.message, {
          id: "terminate-toast",
        });
        setLoader(false);
      } else if (patchAdminEmployeeTerminateError?.status === 500) {
        toast.error(patchAdminEmployeeTerminateError?.error, {
          id: "terminate-toast",
        });
        setLoader(false);
      }
    }
  }, [patchAdminEmployeeTerminateError]);
  const validationSchema = Yup.object({
    deleteValue: Yup.string()
      .required("This field is required")
      .oneOf(["TERMINATE"], 'You must type "TERMINATE" to confirm'),
    leavingDate: Yup.string().required("This field is required"),
  });
  const onSubmit = (values) => {
    if (values.deleteValue === "TERMINATE") {
      dispatch(
        patchAdminEmployeeTerminateAction(employeeId)
      );
      toast.loading("Terminate Employee...", { id: "terminate-toast" });
      setLoader(true);
    }
  };
  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle>Terminate Employee</DialogTitle>
      </DialogHeader>
      <div className="text-xl mt-5 font-semibold">
        Are you sure you want to{" "}
        <span className="text-red-500 font-bold">TERMINATE</span> this employee?
      </div>
      <Formik
        initialValues={{ deleteValue: "" }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="">
            <div className="mb-4">
              <label htmlFor="deleteValue" className="text-gray-700">
                To confirm, type <span className="font-bold">"TERMINATE"</span>{" "}
                in Capital Letter the box below*
              </label>
              <Field
                type="text"
                id="deleteValue"
                name="deleteValue"
                placeholder="TERMINATE"
              />
              <ErrorMessage
                name="deleteValue"
                component="div"
                className="text-red-600"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="leavingDate">Termination Date *</label>
              <DatePicker id="leavingDate" name="leavingDate" />
              <ErrorMessage
                name="leavingDate"
                component="div"
                className="error"
              />
            </div>
            <DialogFooter>
              <button
                className="secondaryButton"
                onClick={() => setShowModal(false)}
                disabled={patchAdminEmployeeTerminateLoading}
                type="button"
              >
                Close
              </button>
              <button
                className="primaryButton bg-red-600 hover:bg-red-800 w-fit"
                type="submit"
                disabled={patchAdminEmployeeTerminateLoading}
              >
                TERMINATE
              </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default Terminate;
